import { useEffect, useContext } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getOrganizationSettings } from '@core/api/getOrganizationSettings';
import { AuthContext } from '@core/providers/AuthProvider';
import { OrganizationSettings } from '@core/types/OrganizationSettings';

export type UseOrganizationSettingsResult = Omit<
  UseQueryResult<OrganizationSettings, any>,
  'data'
> & { organizationSettings: OrganizationSettings };

export const ORGANIZATION_SETTINGS_QUERY_KEY = 'OrganizationSettings';

const useOrganizationSettings = (orgId?: string): UseOrganizationSettingsResult => {
  const { organization } = useContext(AuthContext);

  useEffect(() => {
    const newOrganizationId = organization?.organizationId || orgId;

    if (newOrganizationId !== undefined) {
      sessionStorage.setItem('organizationId', newOrganizationId);
    }
  }, [organization?.organizationId, orgId]);

  const organizationId =
    organization?.organizationId || orgId || sessionStorage.getItem('organizationId') || undefined;

  const { data: organizationSettings = {}, ...rest } = useQuery({
    queryFn: () => getOrganizationSettings(organizationId),
    queryKey: [ORGANIZATION_SETTINGS_QUERY_KEY, organizationId],
    enabled: organizationId !== undefined,
    staleTime: 1000 * 60 * 60 * 12,
    cacheTime: 1000 * 60 * 60 * 12.5,
    retry: 3,
    retryDelay: 1000,
    refetchOnWindowFocus: false,
  });

  return { organizationSettings, ...rest };
};

export default useOrganizationSettings;

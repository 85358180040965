import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { ThemeContext } from '@core/providers/ThemeProvider';
import Actions from './Actions';
import ThemeOptions from './ThemeOptions';
import { ThemeProps } from './ThemeProps';
import useThemeState from './useThemeState';

const Theme: React.FC<ThemeProps> = ({ enabled }) => {
  const navigate = useNavigate();
  const { sx, showSnackbar, currentThemeDetails, setCurrentThemeDetails, updateSettings } =
    useThemeState();
  const { currentTheme, switchTheme, initialTheme } = useContext(ThemeContext);

  const handleSelectTheme = async () => {
    try {
      if (!enabled) {
        throw new Error('Debes tener el paquete "Business" para usar este feature.');
      }

      await updateSettings.mutateAsync({ SelectedThemeKey: currentTheme });

      showSnackbar({
        type: 'success',
        title: 'Tema guardado',
        message: 'El tema se ha guardado correctamente',
      });
    } catch (error) {
      if (!enabled) {
        showSnackbar({
          title: 'No se pudo guardar',
          message: 'Debes tener el paquete "Business" para usar este feature.',
        });
      } else {
        showSnackbar({
          type: 'error',
          title: 'Ocurrió un error',
          message: 'Error al guardar el tema.',
        });
      }
    }
  };

  const showPackageSnackbar = () => {
    showSnackbar({
      title: 'No se pudo guardar',
      message: 'Debes tener el paquete "Business" para usar este feature.',
    });
  };

  const onCancel = () => {
    switchTheme(initialTheme);
    navigate('/');
  };

  return (
    <Box sx={sx.root}>
      <ThemeOptions
        currentThemeDetails={currentThemeDetails}
        setCurrentThemeDetails={setCurrentThemeDetails}
        enabled={enabled}
        showPackageSnackbar={showPackageSnackbar}
      />
      <Actions reset={onCancel} submit={handleSelectTheme} isLoading={updateSettings.isLoading} />
    </Box>
  );
};

export default Theme;

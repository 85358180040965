import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function NotFoundIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 258.94 255.15" {...props}>
      <g opacity=".64">
        <path
          fill="#aaa"
          d="M3.65,230.59c.13-.15.22-.31.29-.49.7-1.59.9-3.43,2.36-4.62.27-.31.45-.69.66-1.04.26-.48.5-.97.8-1.43.11-.25.23-.5.13-.79-.26-.21-.46-.24-.62-.18.88-.8,1.68-1.7,2.5-2.58-.75.62-1.51,1.21-2.2,1.88-.18.34-.37.66-.58.98-.22.77-.69,1.53-1.47,2.22-2.28,1.99-4.95,4.75-5.43,7.7.17.07.34.15.52.14.17.03.34.02.5-.06.62-.92,1.56-1.35,2.55-1.74Z"
        />
        <path
          fill="#787878"
          d="M25.48,254.84s.05-.02.07-.03c.11-.06.32-.19.51-.29.22-.2.45-.4.68-.58.25-.23.52-.43.8-.61,1.18-.84,2.45-1.56,3.68-2.37.3-.2.58-.41.87-.61-.66,0-1.2.48-1.86.45-.78.22-1.5.44-2.36.17-.53-.17-1.31-.22-1.8.29-1.12,1.15-2.65,2.04-2.66,3.88.45.03.9,0,1.35-.05.24-.08.48-.17.72-.25Z"
        />
        <path
          fill="#6a6a6a"
          d="M36.03,246.76c.13-.17.27-.33.41-.5-.88.09-1.33.93-1.78,1.63-.43.67-.76.96-1.63.82-1.49-.24-1.8.26-1.32,1.78.03.02.06.04.09.06,1.09-.75,2.14-1.57,3.15-2.43.36-.46.71-.91,1.07-1.36Z"
        />
        <path
          fill="#898989"
          d="M22.25,251.12c-.22.5-.15,1.02-.16,1.54-.07.75.08,1.55.08,2.31.37.07.74.12,1.11.15.35.03.7.02,1.05,0,.56-1.34,2.03-3.04,2.86-3.29.15-.04.35-.04.48.03,1.02.49,1.8-.17,2.65-.52.1-.14.13-.3.11-.48-.95-1.11-1.72-2.4-3.01-3.2-.95-.58-1.41-.36-1.38.76.02.93-.38.95-1.07.78-1.04-.26-1.89-.2-2.1,1.15-.04.27-.4.51-.62.76Z"
        />
        <path
          fill="#a5a5a5"
          d="M3.97,239.28c.03.52.6.94.4,1.54,1.25,1.35,2.6,2.61,3.88,3.82,2.6,2.44,5.22,5.06,8.11,7.15.98.71,2.24,1.61,3.6,2.34-.45-1.52-.55-3.2-1.96-4.29-.7-.46-1.41-.85-2.26-.97-.55-.08-1.24.26-1.61-.15-1.54-1.68-3.54-2.94-4.63-5.04-1.21-2.34-2.87-4.37-4.52-6.41-.2-.03-.38,0-.55.09-.72.5-.5,1.25-.45,1.91Z"
        />
        <path
          fill="#828282"
          d="M3.02,229.59c-.23,1-1.03,1.47-1.8,1.98-.53,1.32-.06,2.84-.54,4.17.38.75.81,1.48,1.3,2.17.37.29.65.7.98,1.04.3.31.62.59,1.05.39.4-.18.35-.57.34-.98-.02-1.26-.23-2.49-.4-3.73-.05-.52-.03-1.03.07-1.55.18-.6.53-1.14.99-1.49,1.25-.95,1.38-2.32,1.59-3.69.12-.8-.3-1.15-.9-1.53-.75-.49-1.02.15-1.5.43-1.1.66-.59,1.95-1.18,2.79h0Z"
        />
        <path
          fill="#b9b9b9"
          d="M19.23,253.71c.71.42,1.45.81,2.21,1.11.34.08.68.15,1.02.2.03-.29.03-.6-.03-.93-.09-.53.29-.93.29-1.42l-.16-1.22c-.1-.12-.23-.18-.38-.19-.26.1-.49.27-.77.35-.96.19-1.19-.92-1.94-1.1-.73-.16-1.36-1.01-2.23-.34-.08.17-.12.36-.12.55,1.08.8,1.91,1.72,2.1,2.99Z"
        />
        <path
          fill="#797979"
          d="M.3,231.32c-.42,1.35-.44,2.75.23,4.15.15.31.32.62.5.92,1.12-.38,1.32-.99.96-2.46-.18-.75-.42-1.48-.64-2.22-.2-.17-.46-.21-.7-.27h0c-.13-.01-.24-.06-.35-.11Z"
        />
        <path
          fill="#919191"
          d="M16.37,251.79c.48.35,1.03.75,1.63,1.15-1.01-1.12-2.27-1.93-3.59-2.67.64.53,1.29,1.04,1.96,1.52Z"
        />
        <path
          fill="#909090"
          d="M3.69,238.49c-.34.19-.59-.18-.82-.4-.35-.35-.73-.58-1.17-.61.79,1.19,1.71,2.31,2.71,3.38,1.13-.93-.03-2,.15-2.99.47-1.9.04-2.94-1.35-3.32-.02,1.01.4,1.93.6,2.9.07.36.32.8-.11,1.05Z"
        />
        <path
          fill="#a3a3a3"
          d="M22.73,255.06c.14-.77.17-1.55,0-2.39-.29.74-.52,1.5-.76,2.26.25.05.51.09.76.13Z"
        />
        <path
          fill="#696969"
          d="M43.05,233.5c.68-1.06,1.35-2.13,2.04-3.19.35-.54.72-1.03,1.12-1.55.03-.03.06-.07.09-.12-.84-.05-1.21.62-1.64,1.16-1.57,1.97-2.86,4.12-3.85,6.44-.31.66-.77,1.26-.7,2.04.08.09.18.14.28.18.7-1.75,1.69-3.44,2.65-4.96Z"
        />
        <path
          fill="#a3a3a3"
          d="M212.75.25c3.22.31,5.9,1.75,8.1,4.03,1.5,1.55,3.21,2.86,4.75,4.35.08.21.05.4-.08.58-.22.23-.48.39-.82.31-.37-.17-.55-.55-.84-.79-.83-.59-1.31-1.68-2.48-1.8-.15-.08-.28-.18-.4-.29-1.12-1.39-2.52-2.16-4.27-2.72-2.27-.72-4.36-.85-6.54.04-.38.01-.74.1-1.05.33-1.11.55-1.99,1.23-2.05,2.64-.03.64-2.51,2.51-3.17,2.52-.56,0-1.01-.38-1.03-.84-.05-.9-.68-1.17-1.29-1.51-.43-.7.12-1.25.49-1.58,1.39-1.22,2.56-2.82,4.66-2.98.55-.04,1.13-.27,1.5-.67,1.24-1.33,2.79-1.7,4.51-1.64Z"
        />
        <path
          fill="#919191"
          d="M254.09,31.16c-.3-.34-.59-.69-.85-1.07-.17-.25-.36-.5-.54-.75-.74-.77-1.37-1.58-1.91-2.43-3.94-4.7-9.31-9.4-14.61-12.56.06.07.13.14.19.22,1.26,1.92,3.72,2.75,4.56,5.04.82.46,1.75.72,2.54,1.21.87.54,2.14.53,2.65,1.45.74,1.32,1.8,2.44,2.34,3.89.85,2.28,2.91,3.65,4.23,5.58.24.35.64.67,1.12.42.4-.21.34-.62.27-.99Z"
        />
        <path
          fill="#979797"
          d="M212.75.25c-1.25.27-2.29.74-3.18,1.81-.92,1.09-2.26,1.58-3.85,1.54-2.05-.05-2.91,1.69-3.91,3.13.28.76-.22.98-.8,1.14-.25-.05-.38-.2-.41-.45,2.73-3.84,5.67-7.36,10.96-7.42.39.08.78.17,1.17.25Z"
        />
        <path
          fill="#a6a6a6"
          d="M241.5,18.72c-.66-.67-1.29-1.37-1.99-1.99-.22-.19-.42-.41-.62-.63-2.43-1.68-4.9-3.09-7.25-4.07-.01,0-.03-.01-.04-.02-.12-.01-.24-.03-.37-.04-.14.13-.22.29-.23.49.26,1.1,1.42,1.28,2.08,1.98,2.13,2.25,4.64,4.01,7.57,5.1.56.21,1.08.42,1.21-.46-.03-.21-.15-.32-.36-.34Z"
        />
        <path
          fill="#a9a9a9"
          d="M225.37,8.87c.08-.08.16-.16.24-.24,1.87,1.11,3.57,2.51,5.62,3.33,0,0-.02.06-.02.06-.22,1.02-.94.92-1.58.6-1.03-.5-2.08-.98-2.96-1.74-.27-.39-.51-.81-.98-.99-.56-.21-.54-.58-.32-1.03Z"
        />
        <path
          fill="#888"
          d="M235.82,58.73c-.34.19-.7.35-.7.82.05.12.12.2.21.26.3-.01.6,0,.91.02.16-.05.32-.1.48-.15.48-.09.97-.23,1.45-.25.62-.03.83-.23.81-.9-.06-2.39.45-2.78,2.84-2.26,1.09.24,2.25.63,3.24.04,2.45-1.47,5.26-2.36,7.32-4.47-.58-1.44-1.38-.48-2.03-.12-2.62,1.43-5.19,2.96-8.3,3.09-.43-.07-.9.23-1.29-.02-.69-.45-1.4-1.03-2.09-.19-.49.6-1.25,1.08-1.29,1.96-.05,1.09-.71,1.69-1.56,2.16Z"
        />
        <path
          fill="#919191"
          d="M202.86,78.06c-.16-.25-.4-.52-.66-.38-1.32.74-2.98,1.04-3.63,2.65-.31.75-.81,1.32-1.37,1.86-1.7,1.68-3.41,3.34-5.13,5.01-.6.58-1.12,1.33-1.91,1.59-1.72.57-2.74,1.9-3.47,3.35-.89,1.76-2.21,3.04-3.73,4.16-.86.64-1.57,1.36-2.21,2.21-1.05,1.41-1.93,2.96-3.75,3.65-.67.25-1.28.91-1.36,1.78-.06.61-.43,1.03-.83,1.4-2.26,2.13-4.09,4.66-6.36,6.79-1.74,1.63-3.23,3.51-4.79,5.32-3.1,3.61-6.21,7.23-9.41,10.75-2.6,2.87-5.15,5.79-7.73,8.67-3.24,3.61-6.78,6.93-9.82,10.74-1.84,2.31-3.89,4.51-6.06,6.52-1.67,1.54-2.88,3.6-5.01,4.63-.68.33-1.39.62-2.03-.03-.57-.56-.61-1.33-.29-1.97.54-1.08.37-2.14.2-3.23-.14-.16-.3-.3-.47-.43-.29-.39-.67-.69-1.02-1.02-.13-.13-.29-.23-.46-.3-.06.02-.13.02-.19.02,0,.11,0,.22,0,.33.41.87.68,1.8.82,2.75.15,1.05.12,2.1.2,3.16.06.84.18,1.79.59,2.56.24.21.49.42.76.59.47.06.93.12,1.4.14.28-.12.57-.22.86-.31.25-.21.51-.41.77-.61,1.46-1.63,2.91-3.27,4.38-4.89,2.13-2.35,4.65-4.61,6.71-7.05.19-.28.38-.56.6-.83,3.57-4.42,7.19-8.8,11.17-12.85.95-1.45,2.09-2.7,3.44-4.01.91-.89,1.86-1.76,2.76-2.68.52-.77,1.15-1.45,1.86-2.05,1.8-2.11,3.62-4.39,5.66-6.29,2.62-3.06,5.32-6.14,8.21-8.83,2.42-2.98,4.91-5.97,7.66-8.56,1.13-1.32,2.31-2.61,3.57-3.82.02-.02.04-.03.06-.05.28-.4.6-.79.97-1.18,4.33-4.43,8.39-9.58,13.28-13.49,1.1-1.1,2.22-2.19,3.36-3.25.82-.81,1.63-1.62,2.46-2.41-.01-.05-.02-.09-.04-.14Z"
        />
        <path
          fill="#919191"
          d="M87.27,185.51c.06-.01.11-.03.17-.04,1.31-1.33,2.8-2.49,4.21-3.72,5.47-4.76,11.12-9.31,16.91-13.67.86-.78,1.7-1.58,2.5-2.42,2.78-2.78,6.1-4.93,8.97-7.66.41-.78.92-1.53.98-2.44.08-1.13-.45-2.13-.83-3.17-.14-.22-.3-.42-.43-.65-.12.06-.24.16-.35.33-.41.42-.11.91.07,1.2.63.98.26,1.82-.46,2.38-.84.66-1.52,1.44-2.23,2.19-3,3.15-6.89,5.33-9.6,8.79-.2.26-.52.41-.8.59-1.69,1.03-3.58,1.78-4.58,3.68-.25.48-.74.93-1.24,1.07-2.32.69-3.36,2.89-5.08,4.28-.66.53-1.45.86-2.08,1.49-1.83,1.81-3.49,3.8-5.6,5.3-.15.14-.3.28-.45.42-.51.65-1.16,1.17-1.7,1.78-1.21,1.38-2.74,2.49-3.58,4.18.62.72.91-.02,1.26-.32,1.2-1,2.11-2.32,3.47-3.15.15-.15.31-.3.46-.45Z"
        />
        <path
          fill="#6f6f6f"
          d="M109.02,167.73c-.35-.39-.84-.41-1.35-.01-1.63,1.3-3.38,2.51-4.88,3.93-3.24,3.07-6.78,5.81-9.97,8.93-.14.12-.23.27-.3.43,5.34-4.61,10.85-9.04,16.5-13.28Z"
        />
        <path
          fill="#6f6f6f"
          d="M84.06,188.66c.92-.9,1.84-1.79,2.75-2.69-1.8.81-2.75,2.72-4.54,3.54-.68-.26-1.02.29-1.39.65-2.28,2.15-4.56,4.3-6.71,6.58-.57.61-1.54.95-1.45,2.04,1.13-.42,1.84-1.37,2.7-2.13.55-.49,1.06-1.04,1.58-1.56.11-.14.23-.28.34-.41,0,0-.01,0-.02-.01.09-.1.19-.19.28-.29.16-.17.32-.33.49-.5.61-.61,1.24-1.19,1.89-1.75.78-.94,1.72-1.77,2.77-2.33.38-.43.82-.81,1.31-1.13Z"
        />
        <path
          fill="#a1a1a1"
          d="M255.41,41.25c-.52-1.06-.54-2.03.38-2.87.59-.53.91-1.11.69-1.79.21.23.44.45.67.68-.41-1.03-.86-2.03-1.36-3.03-.72-.9-1.32-1.89-1.69-3.06-.02-.02-.03-.04-.05-.05-.21.02-.52.12-.59.03-1.73-2.18-4.31-3.8-5.03-6.65-.03-.11-.06-.22-.1-.33-1.66-1.74-3.48-3.42-5.38-5-.48-.17-.97-.32-1.46-.47.08.08.16.17.23.25-.31.67.05,1.02.6,1.31.88.47,1.72,1.02,2.6,1.48,1.22.63,2.02,1.5,2.04,2.93.06.32.18.63.19.96.17,1.69.82,3.35.24,5.11-.14.44.47.96.7,1.46.53,1.14,1.67,2,1.73,3.33.04.83.37,1.31,1,1.71.68.44,1.34.92,1.32,1.83-.01.83.16,1.6.36,2.4.37,1.47.02,1.81-1.44,2.31-.61.21-1.22.34-1.82.37-1.22.07-1.81.95-2.29,1.78-.37.64-.4,1.53.54,1.97.63.27.79-.54,1.38-.54,1.85,0,3.5-.74,5.09-1.64,1.95-1.1,2.43-2.49,1.44-4.5Z"
        />
        <path
          fill="#818181"
          d="M199.21,10.36c-.02.07-.04.13-.05.18,0,.19-.01.54-.01.6,0,.42.03.84.04,1.27,0,.06,0,.38-.01.63.02-.2.05.2.07.54.06.22.09.44.11.65,0,.02.02.05.02.07,0,.01,0,.03.01.04.04.08.07.17.1.25.37-.54.53-1.2.41-1.93,0-.05.02-.11.04-.16.06-.57.55-.92.73-1.43-.14-.77.38-1.34.61-1.99.16-.56-.12-.99-.42-1.41-.08-.08-.16-.16-.24-.24-.02,0-.03,0-.05,0-.41.98-.85,1.95-1.34,2.89,0,.01-.01.02-.02.03Z"
        />
        <path
          fill="#8d8d8d"
          d="M257.67,47.94c.4-.73.5-.85.31-.38-.06.17-.09.25-.09.27,0,0,0,0,0,0,0-.01.09-.24.24-.68.15-.37.28-.75.4-1.13.13-.38.23-.77.32-1.16-.02.68-.01.54.03-.41.02-.36.02-.72.04-1.08,0-.13,0-.26,0-.39-1.86.68-3.48,1.76-4.82,3.25-.74.82-1.57,1.37-2.76,1.21-3.13-.43-3.54-.07-3.69,3.12,0,.11,0,.22-.01.33-.19,1.01-.7,1.54-1.8,1.25-.46-.12-.81.1-1.12.41-.12.11-.25.22-.37.32-.27.16-.42.51-.81.49-1.59-.09-1.77.88-1.58,2.12,2.06-.23,3.94-1,5.87-1.7,1.57-.57,2.95-1.51,4.55-1.95,1.9-.98,3.58-2.25,5.14-3.68.05-.07.09-.15.14-.22Z"
        />
        <path
          fill="#8d8d8d"
          d="M17.96,218.16c.42-.7,1.06-1.35,2.05-1.54.39-.07.97-.6.99-1.12.09-2.42,2.25-3,3.75-3.86,1.38-.79,2.13-1.56,2.18-3.07-.1-.15-.24-.26-.4-.34-.64-.24-1.03.2-1.47.52-.66.49-1.35.94-2.03,1.39-3.55,3.07-7.86,5.14-11.69,7.85-.02.02-.04.04-.07.06-.25.27-.51.53-.8.78-.23.21-.47.41-.71.61-1.02,1.03-1.83,2.21-2.66,3.38-.24.38-.47.76-.71,1.14.41.28.4.73.49,1.14.17.8.66.89,1.31.55.26-.19.46-.65.79-.54,1.87.66,3.09-.61,3.95-1.73,1.5-1.93,3.73-3.1,5-5.24Z"
        />
        <path
          fill="#a5a5a5"
          d="M15.15,215.52c-1.31.8-2.61,1.63-3.86,2.51-.26.27-.52.54-.81.8-.24.22-.49.43-.74.64-.5.51-.99,1.03-1.48,1.58-.35.39-.73.68-.66,1.26-.17.17-.33.35-.5.52.33-.2.72-.34,1-.6,2.39-2.19,4.78-4.39,7.04-6.71Z"
        />
        <path
          fill="#d6d6d6"
          d="M10.48,218.84c-.96.86-2.01,1.61-2.93,2.51-.18.34-.37.67-.58.98-.04.12-.07.25-.12.37.23-.17.47-.31.76-.39,1.72-1.91,3.78-3.51,5.45-5.47-.6.39-1.18.79-1.76,1.2-.26.28-.53.55-.81.8Z"
        />
        <path
          fill="#8b8b8b"
          d="M2.98,233.36c-.37-1.15.3-1.79,1.2-2.21,1.22-.57,1.6-1.59,1.68-2.8.04-.63-.06-1.27-.8-1.43-.65-.14-.99.35-1.24.87-.28.59-.54,1.2-.81,1.79-.18-2.08,1.55-3.16,2.47-4.67,1.12-.31,1.54,1.08,2.55,1.05.3.2.4.52.51.83.06.15.1.3.12.46-.18.73-.81,1.12-1.26,1.65-.27.23-.34.56-.45.88-.41,1.28-.78,2.54-2.51,2.64-.53.03-.82.85-1.47.94Z"
        />
        <path
          fill="#7a7a7a"
          d="M7.77,226.34c-1.13.12-1.77-.56-2.29-1.42.31-.32.61-.64.92-.95,1.87-.88,1.04.83,1.38,1.43.4.32.43.63,0,.94Z"
        />
        <path
          fill="#8e8e8e"
          d="M1.35,231.7c-.23-.09-.47-.18-.7-.27.96-.39,1.66-1.12,2.37-1.84-.02,1.13-.77,1.68-1.67,2.12Z"
        />
        <path
          fill="#898989"
          d="M40.45,238.33s-.04-.05-.06-.07c-.27-.3-.53-.27-.79.03-.83-1.38-1.64-.56-2.37.07-1.56,1.33-2.8,3.01-4.6,4.07-.33.2-.68.56-.66.92.07,1.53-1.2,2.15-1.99,3.1-.2.2-.48.26-.7.41-.88.85-.97,1.64,0,2.57.42.41.98.91.82,1.67.08.09.16.17.24.26.14-.02.27-.04.4-.08.17-.11.34-.21.5-.32.23-.15.45-.32.67-.47-.61-1.5-.49-1.62,1.22-1.5.75.05,1.15-.17,1.33-.89.2-.79.55-1.54.78-2.28.32.73.66.92,1.1.58.23-.28.45-.56.69-.83.58-.98.77-2.15,1.44-3.08.5-.93,1.01-1.87,1.51-2.8.14-.45.3-.9.48-1.34Z"
        />
        <path
          fill="#787878"
          d="M36.03,246.76c.47-.59.96-1.17,1.46-1.74.23-.45.46-.91.69-1.36.09-.39.19-.78.28-1.18-.95.85-1.11,2.26-2.06,3.18-.55-.66.36-1.26-.34-2.03-.71,1.53-2.81,1.98-2.52,4.13.08.55-.33.8-.81.87-1.41.22-1.6.88-.87,1.88.04-.03.08-.06.12-.08.01-.51.03-1.11.67-1.05.29.03.55.02.79-.02.5-.39.99-.78,1.48-1.19.37-.47.73-.94,1.1-1.4Z"
        />
        <path
          fill="#9a9a9a"
          d="M30.13,247.83c-.89.26-.56.77-.25,1.26.41.64.62,1.29.2,2.01-1.23-.64-1.74-2.1-3.26-2.84.48,1.74-.32,2.01-1.57,1.67-1.42-.39-1.91.81-2.68,1.53,0,0-.07.09-.07.09-.74-.39-.89-1.22-1.22-1.84-.43-.82.65-1.75,2.13-1.96.11-.71-1.19-1.01-.57-1.87.91-1.03,2.13-.85,3.29-.84,1.14.67,1.96,2,3.58,1.79.12-.02.8.45.42,1.02Z"
        />
        <path
          fill="#b9b9b9"
          d="M17.37,250.49c-.08.08-.16.16-.24.23-3.83-1.09-6.12-3.98-8.23-7.06-1.38-2-2.94-3.88-4.08-6.05-.14-.4-.25-.8.28-1.03.54,0,.83.44,1.26.64,1.91,2.85,3.97,5.59,6.22,8.2,1.21,1.41,2.54,2.61,4.12,3.58.53.32,1.27.61.68,1.47Z"
        />
        <path
          fill="#a2a2a2"
          d="M5.03,236.92c-.07.23-.14.47-.21.7-.09.08-.18.16-.27.24-.52-1.08-.49-2.38-1.35-3.32-.07-.39-.14-.79-.22-1.18.33-.74.86-1.3,1.59-1.63,1.14-.5,1.65-1.4,1.79-2.58.49-.05.84.11,1,.6.11.22.23.44.35.66,0,1.83.15,3.73-2.16,4.43-.36.65.06,1.49-.52,2.08Z"
        />
        <path
          fill="#d1d1d1"
          d="M17.37,250.49c-5.35-2.81-8.26-7.81-11.38-12.63-.02-.11-.04-.23-.11-.31-.38-.42-.82-.93-.35-1.44.55-.6,1.22-.06,1.5.32,1.46,2.02,4.07,2.84,5.27,5.16.4.78,1.6.49,2.33.96.7.28.65.95.77,1.52.31,1.56,1.19,2.58,2.76,2.91,1.6.33,2.67,1.14,2.88,2.87.07.59.76.9.9,1.51-.03.29-.19.52-.3.78-.31.64-.69.82-1.26.24-.34-.35-.59-.82-1.13-.93-.62-.32-1.25-.65-1.87-.97Z"
        />
        <path
          fill="#bdbdbd"
          d="M21.58,251.96c0-.16,0-.32.01-.47-.57-.93-.54-2.04-.93-3.02-.02-.04-.13-.07-.13-.09.03-.27.06-.73.24-.74,1.35-.05.8-2.03,2.13-2.05.23.2.19.49.21.75.21.18.43.34.63.53.55.5,1.64.88,1.38,1.66-.3.92-1.23.13-1.87.1-.88-.04-1.26.32-1.15,1.16.08.6.25,1.18.38,1.77-.23.22-.45.43-.68.65-.23.08-.31,0-.24-.24Z"
        />
        <path
          fill="#cbcbcb"
          d="M21.58,251.96c.08.08.16.16.24.24-.11.52-.1,1.17-.8,1.25-.52.06-.64-.5-.89-.83-.29-.39-.59-.77-.89-1.15.76.28,1.45.86,2.34.5Z"
        />
        <path
          fill="#9e9e9e"
          d="M82.27,189.5c-3.19,3.09-6.37,6.18-9.56,9.27-.08.07-.16.15-.23.23-.53,1.07-1.53,1.72-2.35,2.49-3.87,3.62-7.24,7.71-11.08,11.34-.2.19-.41.38-.47.67-1.18,1.16-2.35,2.32-3.53,3.48-.38,0-.67-.14-.77-.54,1.2-2.05,2.9-3.7,4.41-5.49,1.43-1.18,2.33-2.87,3.67-4.14.88-.91,1.91-1.7,2.63-2.76.34-.51.85-.67,1.37-.85,1.6-1.12,2.65-2.68,3.57-4.36,1.38-1.55,2.6-3.26,4.8-3.86,1.14-.31,1.92-1.61,2.21-2.46.51-1.5,1.53-1.79,2.69-2.17,1.16-.88,2.08-2.02,3.03-3.11.1-.15.22-.28.34-.41,1.27-1.17,2.95-1.8,4.09-3.16.28-.33.7-.31.9.21-1.91,1.88-3.83,3.75-5.74,5.63Z"
        />
        <path
          fill="#a6a6a6"
          d="M39.8,237.75c.34.17.65-.01.85-.3.19-.26.41-.51.5-.84.15-.23.3-.45.45-.68.46-.84.96-1.66,1.45-2.42.12-.19.25-.39.37-.58.68-1.15,1.38-2.29,2.19-3.36.19-.27.39-.53.6-.8.06-.08.13-.18.21-.29.17-.41.35-.82.52-1.23-.31-.32-.57-.14-.82.08-.24.16-.35.49-.7.53,0-.6-.05-1.21-.65-1.58-1.8.36-2.1,2.23-3.25,3.24.89,1.43.81,1.93-.5,3.1-.21.18-.36.4-.45.66-1.25.95-1.02,2.35-1.16,3.66-.04.39.09.66.39.81Z"
        />
        <path
          fill="#6f6f6f"
          d="M58.59,213.5c1.32-2.08,3.19-3.69,4.84-5.47,2.9-3.13,6.03-6.03,9.07-9.03-.35.5-.63,1.05-1.05,1.48-4.27,4.35-8.56,8.68-12.85,13.02Z"
        />
        <path
          fill="#b9b9b9"
          d="M46.95,227.25l-.77.69c-.96-1.16.54-1.57.76-2.36.7-.85,1.18-1.85,1.85-2.72,1.73-1.82,2.86-4.12,4.8-5.81.36-.32.62-.5,1.05-.54.14.16.28.31.42.47-2.89,3.28-5.45,6.82-8.12,10.27Z"
        />
        <path
          fill="#989898"
          d="M40.68,237.78c.1-.23.2-.45.31-.68.06-.16.13-.32.17-.49-.37.22-.84.67-.72-.29.13-1.03.21-2.06.73-3-.06-.66-.72-.77-1.08-1.13-1.18-1.16-2.38-.73-2.84.91-.12.44-.29.89-.39,1.32-.52,2.11-.82,2.39-3,2.26-1.11-.06-1.72.18-1.39,1.54.15.59.05,1.55-1.11,1.24-.75-.2-1.27.23-1.81.63-.13.11-.21.24-.24.41.94,1.97.23,3.99.19,5.98.4.34.67.96,1.38.63,1.54-3.72,4.76-5.87,7.54-8.58.47.79-.18,1.35.34,1.91.58-.78,1.11-1.48,1.63-2.18.12-.15.2-.32.3-.48Z"
        />
        <path
          fill="#b0b0b0"
          d="M225.37,8.87c.62.48,1.24.96,1.87,1.44-.07.71-.5,1.21-1.16,1.19-1.99-.08-4.03.16-5.67-1.54-1.55-1.6-3.12-3.27-5.57-3.57-1.35-.16-2.31.19-2.73,1.54-.27.86-.97,1.49-1.24,2.35-.18.56-.18,1.11.3,1.54.43.38.83.1,1.21-.14.78-.51,1.41-.16,1.9.43,1.8,2.22,4.12,2.52,6.8,2.22,2.19-.25,4.42.28,6.64.02.38-.05.79.09,1.16-.01,3.12-.85,5.25.88,7.28,2.81.73.69,1.59,1.14,2.44,1.64.69.41,1.57.69,1.56,1.73-.08.64-.43.98-1.08,1.03-1.9.15-2.69,1.26-2.69,3.07,0,.16-.11.39-.11.47,0,.89-.43,1.49-1.25,1.65-.89.18-1.63-.48-1.76-1.19-.31-1.65-1.52-1.85-2.76-2.11-1.11-.23-1.92-.79-2.75-1.64-1.79-1.85-4.16-2.87-6.56-3.71-.86-.3-1.25.32-1.04,1.14.17.66.44,1.28-.35,1.74-1.56-.28-2.78-1.41-4.32-1.74-.69-.31-1.3-.6-1.45-1.56-.16-.99-1.29-1.05-2.05-1.31-.89-.3-1.89-.29-2.81-.56-.63.09-1.08.59-1.69.74-.33-.07-.66-.15-.98-.24.04-1.14-1.45-.83-1.63-1.76.39-1,1.05-1.35,2.06-.88,1.04.49,1.41-.06,1.53-.99.07-.5.19-1.09-.08-1.46-.9-1.21-.28-2.13.58-2.82.91-.73.74-1.4.38-2.28-.63-1.57-.39-2.2.92-2.81,2.22-.09,4.35.62,6.53.8,1.15.3,1.6,1.77,2.94,1.76.17.01.32.05.48.11,1.34,1.34,3,2.17,4.72,2.89.16,0,.31,0,.47,0Z"
        />
        <path
          fill="#a9a9a9"
          d="M200.6,8.84c.08-.39.17-.79.25-1.18.32-.31.65-.62.97-.93.47-.36.83-1.13,1.47-.93.83.27.43,1.13.46,1.75.04,1.02.49,1.19,1.24.59.61-.48,1.18-1.03,1.5-1.75.53-1.21,1.41-2.1,2.45-2.87.41.06.31.31.28.6-.05.41-.29.91-.12,1.22,1.06,1.99.39,3.48-1.22,4.76-.61.49.1.46.25.62.77.77.55,1.59.2,2.45-.33.82-.83.74-1.57.54-1.06-.29-1.48.59-1.87,1.39-.49.16-1,.35-1.54.28-.44-1.29-1.54-2.04-2.34-3.03-.58-.74-.47-1.66-.19-2.38.22-.56.12-.79-.21-1.12Z"
        />
        <path
          fill="#aeaeae"
          d="M204.79,14.54c.66-.71,1.48-1.14,2.4-1.41.5-.15.9-.5.86-1.09-.05-.62-.56-.76-1.07-.84-.38-.06-.78-.06-1.15-.16-.7-.18-1.04-.66-.82-1.37.21-.68.74-.76,1.34-.51.86.37,1.65.25,2.28-.43.77-.83,0-1.38-.44-1.99-.38-.53-.18-1.04.07-1.56.26-.54.47-1.12.7-1.68.32-.15.63-.31.95-.46.27.05.53.11.46.48-.73.52-.74,1.17-.38,1.9.62,1.23.48,2.37-.52,3.34-.62.61-.79,1.3-.59,2.12.18.76.19,1.54-.01,2.3-.26.95-.83,1.43-1.85,1.15-.62-.17-1.2-.16-1.75.21-.16.32-.32.29-.47,0Z"
        />
        <path
          fill="#a9a9a9"
          d="M210.35,3.52c-.15-.16-.31-.32-.46-.48.95-.02,1.88-.08,2.65-.78.56-.51,1.25-.52,1.86-.14,1.11.68,2.32,1.03,3.58,1.3,1.62.35,2.84,1.19,3.42,2.81-.34.91-1.22.71-1.9.92h0c-.63-.05-1.18-.48-1.32-.93-.29-.93-.98-1.05-1.71-1.23-2.12-.15-4.09-.9-6.11-1.48Z"
        />
        <path
          fill="#aeaeae"
          d="M219.5,7.16c.63-.31,1.27-.61,1.9-.92,0,0,0,0,0,0,.48.2.84.44,1.08.99.29.67.9,1.09,1.72.93.23.23.47.47.7.7-1.27.77-2.39.71-3.28-.58-.52-.75-1.32-.93-2.12-1.13Z"
        />
        <path
          fill="#a9a9a9"
          d="M224.2,8.16c-1.03.92-1.74.74-2.18-.58-.15-.46-.41-.89-.62-1.34,1.25.18,1.99,1.11,2.8,1.92Z"
        />
        <path
          fill="#b8b8b8"
          d="M247.56,24.45c-.03-.52-.14-.98-.33-1.4-.83-.82-1.68-1.63-2.57-2.41-.97-.57-1.95-1.11-2.92-1.66-2.25-1.43-5.06-1.98-6.88-4.08-1.05-1.21-2.04-2.4-3.65-2.86-1.57,0-2.67-1.08-3.98-1.71-.9.31-1.8.32-2.76.28-2.88-.1-4.49-2.35-6.55-3.74-1.42-.96-2.85-1.39-4.45-1.54-.94-.09-1.62.4-1.9,1.3-.33,1.08-.89,2.01-1.49,2.95-.96,1.5-.08,3.84,1.58,4.4.47.16.97.15,1.07-.42.24-1.41.84-.84,1.36-.26,1.41,1.57,3.3,2.23,5.23,2.05,3.75-.34,7.51.43,11.24-.3.73-.14,1.43.13,2.04.56,2.33,1.66,4.66,3.33,6.99,5,.84,2.04,1.94,2.74,4.06,2.58,1.45-.11,2.07.4,2.12,1.87.02.57.16.96.71,1.14,1.24-.03,1.11-.91,1.07-1.73Z"
        />
        <path
          fill="#949494"
          d="M244.83,53.17c-.42,1.43-2.29,1.07-2.87,2.32-3.04-.57-3.08-.58-4.28,2.23-.47,1.11-1.38,1.53-2.37,1.92-.08-.07-.16-.14-.24-.21-.8-1.62-.78-1.59.88-2.4.85-.41,1.5-1.02,1.68-2.09.34-1.99.61-2.06,2.66-1.54,1.52.39,3.02-.26,4.54-.24Z"
        />
        <path
          fill="#bebebe"
          d="M122.33,153.61c-.07-.07-.14-.15-.21-.23-.06-.49-.22-.97-.09-1.46.16-.23.36-.42.55-.62.61-.64,1.41-1.01.91-2.33-.36-.96.54-2.19,1.07-3.16.67-1.24.98-2.51,1.34-3.84.68-2.45,1.51-4.89,3.61-6.6.44-.12.96.18,1.3-.44.94-1.73,2.19-3.18,3.85-4.29.58-.39.39-1.33.92-1.92,1.01-1.13,1.24-2.73,2.39-3.85.87-.84.1-2.14.23-3.23-.16-1.59,1.14-2.37,1.91-3.45.32-.43.6-.9,1.07-1.19,2.77.12,3.02-.53,4.59-3.19,1.08-1.83,2.45-3.82,4.54-4.91.27.03.52.11.66.14-.21-.05-.54-.11-.87-.21-.49-.92.06-1.57.58-2.22.38-.48.74-.97,1.1-1.46.18-.2.38-.39.57-.58.32-.3.61-.62.96-.87,1.56-.14,2.09,1.28,2.61,2.16.88,1.49,1.19,3.11.22,5-1.32,2.57-3.43,3.77-5.92,4.63-.27.09-.32.23-.39.44-.3.88-.99,1.25-1.82,1.53-1.81.62-2.51,1.73-2.5,3.7,0,1.15-.99,1.79-2.24,1.42-2.08-.61-1.96-.59-2.48,1.43-.41,1.61-.52,3.38-2.15,4.52-.86.6-1.25,1.77-1.18,2.84.13,1.92-.76,2.86-2.44,3.66-2.06.97-3.71,2.48-3.75,5.07,0,.73.03,1.43.53,2.03.55-.06.83-.48,1.02-.82.94-1.61,2.04-2.86,3.97-3.45,1.19-.37,1.42-1.73,1.31-2.98-.23-2.59.77-3.51,3.39-3.18,1.77.22,3.23-.38,4.09-1.95.89-1.61,2.25-2.55,3.86-3.25.89-.39,1.37-.2,1.41.82-2.31,1.8-3.46,4.66-5.89,6.35-.68.7-1.16,1.35-1.25,2.51-.11,1.49-1.59,2.45-2.51,3.62-.11.15-.23.3-.36.45-.27.28-.53.57-.96.54-.95-.3-1.55.31-2.15.87-.77.73-1.59,1.33-2.94,1.11.6,1.12.73,2.04-.52,2.53-.14.16-.37.08-.54.28-2.03,2.5-5.11,4.03-6.56,7.09-.19.41-.65.79-1.22.85-1.13.11-2.06.94-3.22.93-.26-.21-.34-.5-.42-.81Z"
        />
        <path
          fill="#bbb"
          d="M144.04,140.35c3.11-3.4,6.18-6.84,9.28-10.25,5.86-6.46,11.38-13.22,17.4-19.54,3.18-3.34,6.3-6.73,9.4-10.15,6.72-7.42,13.84-14.44,21.07-21.36.39-.38.91-.63,1.37-.93.98-.23,1.55-1.33,2.23-1.68.49-.45.99-.89,1.5-1.33,1.19-1.02,2.29-2.12,3.48-3.14.42-.34.85-.68,1.29-1.01.7-.92,1.56-1.74,2.56-2.41.09-.06.18-.12.27-.18,0-.01,0-.03,0-.04-.3-.9-.82-.82-1.44-.32-.45.37-.94.68-1.39,1.04-4.51,3.62-9.08,7.19-13.23,11.22-3.7,3.6-7.74,6.89-10.73,11.19-.17.25-.52.43-.78.64-1.23.96-2.64,1.72-3.35,3.26-.62,1.38-1.46,2.59-3.08,3.07-.61.18-1.23.63-1.52,1.24-.99,2.11-2.77,3.55-4.36,5.13-4.02,4-7.74,8.27-11.51,12.5-5.13,5.76-10.19,11.58-15.5,17.17-2.18,2.29-4.63,4.36-6.47,6.93-1.79,2.51-4.06,4.55-6.18,6.72-2.08,2.14-4.28,4.16-6.02,6.62-.55,1.19-.68,2.82-2.65,2.49-.58.19-.89-.17-1.19-.56-.79-.71-.52-2.11-1.67-2.59l-.49-.49c.12,1.6.23,3.2.37,4.8.07.8.59,1.1,1.34,1.22,1.86.31,2.99-.73,4.12-1.98,5.27-5.79,10.6-11.53,15.88-17.3Z"
        />
        <path
          fill="#858585"
          d="M163.34,119.79c2.62-3.06,5.32-6.14,8.21-8.83,0,0,0,0,0,0,.33-.41.66-.81.99-1.22-.03-.11-.06-.23-.11-.34-1.16,1.26-2.34,2.5-3.48,3.79-2.32,2.63-4.64,5.27-6.95,7.91.43-.45.88-.89,1.33-1.31Z"
        />
        <path
          fill="#b9b9b9"
          d="M88.01,183.87c-1.9.85-2.86,2.89-4.76,3.74.14-1.24.67-2.29,1.64-3.07.27-.22.76-.27.84-.63.43-1.91,2.1-2.85,3.22-4.22,1.07-1.73,3.11-2.22,4.51-3.56.51-.5.89-.79.82-1.58-.05-.53.25-1.08.84-1.44,4.13-2.5,7.69-5.79,11.59-8.61,3.92-2.83,7.32-6.21,10.84-9.47.52-.48.31-.98.46-1.47.1-.16.23-.29.39-.39.26-.1.51-.22.8-.09.31.26.54.61.8.92.62.73.72,1.37-.11,2.02-1.48,1.16-2.76,2.55-4.2,3.76-2.82,2.36-5.68,4.67-8.39,7.16-2.04,1.87-4.34,3.4-6.38,5.27-3.83,3.52-7.85,6.84-11.52,10.55-.34.34-.62.8-1.15.9-.08.07-.16.15-.24.23Z"
        />
        <path
          fill="#9e9e9e"
          d="M118.88,150.51c.37.56.75,1.11,1.12,1.67.01.17-.04.37.04.49,1.46,2.35.06,3.51-1.66,5.01-7.44,6.5-15.11,12.74-22.49,19.31-2.52,2.24-4.79,4.8-7.66,6.64,7.42-7.49,15.49-14.26,23.65-20.92,2.4-1.96,4.65-4.11,6.97-6.17,1.04-.93,1.3-1.91.19-2.97-.72-.13-1.44-.83-2.11.23-.22.35-.92.2-1.31-.2-.2-.6-.69-.73-1.25-.86-.81-.18-1.44-.58-1.06-1.61,1.85-.3,3.63-1.16,5.57-.63Z"
        />
        <path
          fill="#a9a9a9"
          d="M122.36,152.21c-.08.39-.16.78-.24,1.17-.15-.32-.3-.64-.44-.95-.42-3.56-.26-7.07.79-10.51,2.5-2.51,4.41-5.49,6.59-8.25.12-.15.23-.18.36-.27.33-.02.65.04.97.17.56.42.63.89.15,1.41-.72.39-1.24.92-1.76,1.61-1.48,1.96-2.75,3.98-3.1,6.44-.2,1.38-1.02,2.47-1.51,3.7-.72,1.8-.44,3.91-1.81,5.48Z"
        />
        <path
          fill="#8b8b8b"
          d="M197.12,83.83c1.09-1.08,2.18-2.16,3.3-3.21,1.9-1.9,3.8-3.75,5.85-5.51,1.19-1.02,2.29-2.12,3.48-3.14.78-.64,1.58-1.24,2.4-1.83h0c-.44.31-.35.17.3-.44.21-.22.43-.43.65-.62l.48-.54c1.63-1.09,3.33-2.21,5.09-3.27-.88-1.08-1.72-.6-2.6.05-1.29.95-1.85,2.51-3.06,3.52-3.31,3.3-7.3,5.83-10.48,9.27-3.28,3.21-6.56,6.42-9.83,9.62-.44.43-.89.86-1.24,1.36,1.8-1.88,3.65-3.68,5.65-5.28Z"
        />
        <path
          fill="#787878"
          d="M91.65,181.75c1.25-1.09,2.52-2.17,3.8-3.24,4.05-3.79,8.31-7.36,12.58-10.9,1.72-.18,2.42-.86,2.62-2.56,0,0,0-.02,0-.02-1.61.74-2.62,2.21-3.95,3.29-5.94,4.82-11.72,9.83-17.27,15.1.02.06.05.1.08.15.71-.61,1.44-1.2,2.15-1.82Z"
        />
        <path
          fill="#6c6c6c"
          d="M90.11,183.06c-.21.14-.43.27-.68.36-.32.31-.64.62-.96.93,0,.04.02.07.03.1.53-.47,1.06-.93,1.61-1.39Z"
        />
        <path
          fill="#9d9d9d"
          d="M121.36,151.81s0,.02,0,.03c.02.12.04.23.06.35.08.08.17.16.25.23.28-3.37.56-6.75.84-10.12-.03-.03-.08-.03-.16,0-.09.03-.13.11-.12.24-1.79,2.92-1.4,6.02-.89,9.14.02.04.03.09.03.13Z"
        />
        <path
          fill="#858585"
          d="M142.16,143.13c-.76.74-1.49,1.51-2.02,2.44,0,0,.01,0,.02-.01.66-.81,1.33-1.62,2-2.43Z"
        />
        <path
          fill="#a8a8a8"
          d="M234.41,59.23c-.72.21-1.44.43-2.17.64-.16,0-.31-.01-.47-.03-1.69-1.93-4.14-1.73-6.85.25-2.27-.34-3.14.11-4.42,2.11-.58.9-1.55,1.35-2.49,1.83-.17.08-.34.14-.51.21-.33.16-.65.38-1.04.22-1.95-.64-3.06-.3-4.17,1.33-.51.76-.9,1.6-1.72,2.1-.13.08-.24.18-.38.25-.66.92-1.92.86-2.69,1.6-1.34.8-2.62,1.58-3.54,2.98-.63.97-2.1,1.33-2.3,2.75-.32.34-.76.49-1.16.72-3.29,1.44-5.82,3.76-7.85,6.68-.53.49-1.13.88-1.68,1.35-1.18.62-2.36,1.22-3.56,1.8-1.16.56-3.33.61-3.95-.09-.56-.64-.42-2.95.28-3.87,1.41-1.88,2.57-3.88,3.23-6.15.35-1.19,1.14-2.15,1.67-3.25.57-1.2.55-2.44.61-3.69,1.27-1.59,2.57-3.15,3.39-5.04.48-.4.92-.74,1.05-1.49.23-1.37,1.28-2.12,2.56-2.39,1.42-.3,2.33.72,2,2.15-.35,1.51-.77,2.98-.24,4.55.13.38.04.81.56.89.39.06.64-.21.74-.53.38-1.16,1.45-1.54,2.32-2.2,1.49-1.13,2.95-2.3,3.67-4.23.7-1.89,2.68-2.39,4.32-3.15,1.81-.84,3.38-1.83,4.02-3.86.24-.34.48-.69.74-1.02,1.46-.03,2.84-.17,3.72-1.62.14-.23.71-.08,1.06,0,1.51.37,2.5-.72,3.66-1.31.19-.1.25-.34.19-.66-.28-1.47.59-2.6,1.4-3.61.75-.93,1.81-.56,2.69.09.1.22.19.44.28.66.1.91.47,1.41,1.52,1.22.4-.08.94,0,1.29.2,1.29.79,2.41.92,3.76-.09.68-.51,1.6.27,2.33.73.04.15.07.31.09.47.03.33.09.65.04.99-.12.76.07,1.05.95.89.85-.15,1.75-.08,2.61.2,1.25.42,1.32-.42,1.34-1.28.02-.66-.27-1.3-.23-1.96,0-.16.02-.32.07-.47.17-.16.35-.32.51-.49.82-.94,1.76-1.62,3.11-1.42.7.1,1.03-.11.86-.92-.39-1.9.25-2.44,2.34-2.09,2.22.81,2.78.3,2.49-2.32.72-.75,1.01-1.31-.4-1.56-1.25-.22-1.51-1.3-1.5-2.35.01-1.08-.25-1.8-1.44-1.95-.73-.09-1.22-.49-1.26-1.28-.14-2.72.46-5.26,1.93-7.58.78.81.8,1.79.57,2.8-.33,1.41-.14,2.66.59,3.97,1.1,1.95,1.81,4.07,3.22,5.92,1.03,1.35,1.08,3.32.88,5.12-.06.52-.38.99-.9,1.09-1.55.31-2.77,1.27-4.13,1.95-.85.43-.43.99-.28,1.55.98.82.04,1.31-.38,1.78-.4.45-1.11.48-1.61.83-.59.42-1.17.8-1,1.71.25,1.27-.43,1.67-1.57,1.46-.22-.04-.53-.11-.62-.02-1.11,1.11-2.02.56-3.12-.12-.9-.56-1.53.31-1.59,1.1-.11,1.55-.64,2.57-2.27,2.94-.97.22-.84,1.3-1.16,2.01Z"
        />
        <path
          fill="#989898"
          d="M258.89,44.23c.01-.28.02-.56.03-.85,0-.29,0-.58.01-.88-.06-.19-.1-.39-.16-.59-.03-.07-.04-.14-.07-.21-.08-.27-.16-.54-.25-.8-.64-1.96-1.32-3.77-2.13-5.55l-.82-.84c-.34,1.27.57,2.12-.29,2.95-1.44,1.39-1.63,2.79-.6,4.49.52.85.53,1.62-.11,2.27-.78.8-1.79,1.59-2.87,1.51-1.74-.13-2.62,1.22-3.91,1.81-.63,1.45-2.13,1.67-3.32,1.87-1.26.22-1.28.81-.93,1.54.53,1.11.85,1.73-.9,1.65-.74-.03-1.31,1.05-2.4.55-1.77-.81-2.34-.46-2.78,1.46-.22.94-.59,1.61-1.54,1.99-1.16.46-1.77,1.31-1.45,2.63.22.07.45.14.67.2.1-.93.42-1.68,1.36-2.11.97-.44,1.49-1.28,1.71-2.33.21-.99.73-1.41,1.8-1.03,1.75.63,3.37.16,4.88-.8.08-.08.15-.16.23-.23.13-.96.87-.76,1.4-.61,1.17.33,1.79-.04,1.93-1.24.04-.38.09-.77.12-1.15.17-2.29.7-2.58,2.58-1.29.63.43,1,.46,1.71,0,2.06-1.33,3.22-3.86,5.91-4.33.07-.01.13-.04.18-.08Z"
        />
        <path
          fill="#ababab"
          d="M247.78,25.16c.41,1.86-.76,3.41-1,5.14-.21,1.55-.41,3,1.59,3.61.83.25.8,1.11.81,1.84,0,.83.21,1.52,1.09,1.85,1.33.49,1.51,1.48,1.09,2.69-1.5-.38-3.22-.39-3.78-2.32-.32-1.12-.92-1.91-2.21-2.12-1.14-.18-1.41-1.8-2.71-1.87-.23-.42,0-.73.26-1.03.97-1.11,1.53-2.21,1.04-3.83-.4-1.32,1.11-3.23,2.41-3.51.58-.2.94-.63,1.2-1.17.08.24.15.47.23.71Z"
        />
        <path
          fill="#7b7b7b"
          d="M218.5,65.35s-.06.07-.1.1c4.19-2.53,8.73-4.71,13.51-5.25.1-.12.21-.23.32-.33-.07-.07-.14-.14-.21-.21-.85-.38-1.68-1.03-2.61-.17-.98.4-2.1.34-3.03.9-.95.41-1.93.79-2.89,1.16-1.67.65-3.14,1.76-4.64,2.77-.33.22-.51.58-.36,1.01Z"
        />
        <path
          fill="#b8b8b8"
          d="M200.6,8.84c.9-.62,1.36-.41,1.17.69-.14.79-.64,1.52-.49,2.37,0,.37.05.76-.33,1.01-.88.73-1.19.32-1.22-.61-.02-.53.21-1.05,0-1.58.29-.63.57-1.26.86-1.89Z"
        />
        <path
          fill="#a9a9a9"
          d="M199.74,10.72c.6.48.08,1.46.83,1.88,1.52.12,1.21.95.71,1.85-.21.58-.64.85-1.24.89-.24-.03-.38-.15-.44-.38-.38-1-.34-2.03-.19-3.07.11-.39.22-.77.33-1.16Z"
        />
        <path
          fill="#a5a5a5"
          d="M201.77,34.88c1.39-2.44,2.58-4.99,3.77-7.53.95-2.02.84-2.43-.86-4.15-.53-.53-1.13-1.11-.9-1.9.3-1.04-.03-1.9-.49-2.76-.3-.95-1.06-1.61-1.5-2.47-.35-.69-1.09-1.04-1.94-.89-.08-.08-.16-.16-.24-.24.06.12.12.25.17.37.75,1.09.94,2.26.75,3.35-.02.25-.05.49-.11.74-.1.45-.21.9-.33,1.34.46.94.6,2.02.38,3.21-.31,1.73-.54,1.99-2.13,1.81-.29,1.05-.59,2.07-.97,3.06-.02.48-.12.94-.47,1.35-.56,1.03-.65,2.2-.96,3.3-.14.5-.23,1.1.42,1.42.84.46.37,1,.18,1.61-.34,1.07.46,2,1.24,1.47,1.39-.93,3.08-1.52,3.98-3.11Z"
        />
        <path
          fill="#b0b0b0"
          d="M198.22,26.2c.08.03.17.05.29.05.15.5-.04,1.24.62,1.42.63.17.96-.46,1.32-.85.29-.31.55-.72.65-1.13.58-2.29.5-4.39-.7-6.2-.41,1.8-.93,3.59-1.77,5.22-.14.5-.27,1-.41,1.49Z"
        />
        <path
          fill="#949494"
          d="M245.06,52.94c.23-1.2.92-1.71,2.11-1.32.61.2.92-.12,1.22-.53-.24,2.18-1.8,1.99-3.33,1.85Z"
        />
        <path
          fill="#9a9a9a"
          d="M7.77,226.34c0-.31,0-.63,0-.94.36-.86.66-1.31,1.92-1.17,1.22.14,2.34-.96,3.3-1.95,2.06-2.13,3.77-4.63,6.3-6.3.28-.19.71-.45.51-.75-.85-1.26.31-1.48.95-1.95,1.97-1.44,4.17-2.57,5.86-4.39.53-1.05,2.18-2.04,3.13-1.86.25.77-.24,1.19-.76,1.59-.45.15-.81.44-1.15.75-.98.99-1.68,2.07-1.96,3.52-.3,1.51-1.61,2.38-2.86,3.14-.67.41-1.39.77-1.65,1.51-.26.75-.77.98-1.44,1.12-.69.15-1.2.42-1.19,1.3,0,.71-.65,1.11-1.09,1.6-.24.23-.5.63-.76.61-1.81-.13-2.35,1.4-3.11,2.47-1.26,1.79-2.63,3.06-5,2.4-.24.05-.49.03-.73.02-.09-.24-.19-.48-.28-.72Z"
        />
        <path
          fill="#6f6f6f"
          d="M29.49,207.02c-1.07.45-1.95,1.18-2.86,1.88,0,0-.11-.12-.11-.12,0,0-.11-.12-.11-.12.16-.41.27-.88.69-1.1,5.45-2.8,10-6.85,14.74-10.64.3-.24.67-.38,1-.57-.09,1.45-1.52,1.85-2.21,2.84-2,1.74-4.06,3.41-6.22,4.93-1,.7-1.29,1.94-2.49,2.5-.84.39-1.54.67-2.42.41Z"
        />
        <path
          fill="#b8b8b8"
          d="M8.05,227.06c.14-.16.28-.32.42-.48.17-.38.46-.46.81-.27,1.34.73,2.44.31,3.33-.76,1.37-1.65,2.91-3.13,4.36-4.69.63-.53,1.12-1.19,1.6-1.85.32-.22.68-.34,1.07-.41,1.74-.29,2.21.09,2.3,1.82.02.48-.05.94-.07,1.42-.39.88-.95,1.53-1.85,2-1.09.57-1.63,1.58-1.23,2.88.08.44.29.82.5,1.21.44.83,1.18.67,1.88.61.93-.08.58-.77.54-1.26-.06-.75.3-1.13.98-1.28.91-.2,3.24.88,3.62,1.76.3.71.85,1.32.93,2.11.12,1.17.09,2.22-.9,3.19-1.22,1.19-1.09,2.22.16,3.4.46.43.44.92.36,1.4-.12.77-.09,1.54-.22,2.31-.08.13-.17.25-.28.35-1.59.21-3.32.8-4.05-1.45-.02-.07-.25-.47-.6-.36-1.41.43-1.69-.52-1.77-1.55-.03-.38-.39-.59-.44-.6-1.1-.25-1.35-1.17-1.52-2.04-.35-1.8-1.44-2.79-3.16-3.19-.45-.1-.91-.29-1.08-.7-.78-1.97-2.44-2.29-4.25-2.45-.41-.04-.89-.06-1.23-.41-.03-.16-.05-.31-.07-.47-.05-.08-.09-.15-.14-.23Z"
        />
        <path
          fill="#c6c6c6"
          d="M6.83,230.11c-.16-.32-.31-.63-.47-.95.08-.23.16-.46.23-.68.09-.35.34-.32.6-.27.77.27,1.38.86,1.69,1.51.64,1.32,1.53,1.98,3.01,1.77,1.65,1.45,2.72,3.3,3.69,5.22.28.55.27,1.08-.16,1.5-.46.45-.92.1-1.34-.16-1.28-.81-2.25-1.96-3.24-3.08-.91-1.6-2.51-2.65-3.27-4.35-.15-.35-.4-.48-.75-.5Z"
        />
        <path
          fill="#a2a2a2"
          d="M7.05,228.45c-.15,0-.3.01-.45.02.34-.65,1.11-.73,1.6-1.18.1.08.19.16.29.24.73.21.64.81.64,1.36-.17.66-.51.8-1.06.36-.34-.27-.53-.72-1.02-.8Z"
        />
        <path
          fill="#aeaeae"
          d="M30.15,245.92c.24.39.49.79.73,1.18.08.58-.25.74-.75.72-.41-.15-.86-.49-1.22-.41-1.46.3-2.24-.56-2.99-1.55-.07-.52.11-.81.68-.8.61.01.66-.4.64-.87.11-.27.29-.47.46-.7.59-.51,1.27-.61,1.81-.06.66.67,1.5,1.39.64,2.5Z"
        />
        <path
          fill="#bababa"
          d="M28.52,244.26c.41.15.9.25.86.82-.03.52-.46.7-.89.84-.86.28-1.72.2-2.58-.04-.94.1-1.96-.25-2.8.46-.17-.16-.34-.33-.51-.49-1.44-.08-2.33-.75-2.44-2.27.34-.83.95-.82,1.64-.55.73.51,1.37.33,1.95-.28.16,0,.3.04.43.13,1.07.45,2.15.32,3.23.07.56.27.32,1.22,1.1,1.32Z"
        />
        <path
          fill="#c9c9c9"
          d="M13.92,243.62c-1.26-.46-2.74-.66-2.75-2.52,0-.2-.32-.46-.55-.58-1.36-.71-2.3-1.84-3.26-2.98-.4-.48-1.32-1.48-1.37.32-.32-.32-.64-.63-.95-.95-.48-.99-.97-1.98-1.15-3.09,1.27-.41,2.25.37,3.3.81.34.14.53.55,1.12.9-.79-1.38-.64-2.25.93-2.37.43-.02.51.45.84.59.98,1.6,2.25,2.94,3.69,4.13.13.1.38.15.41.26.35,1.61,2.12,2.02,2.8,3.35-.25,1.14-.95,1.4-1.95.96-.58-.26-1.21-.41-1.63-.78.17.63,1.54.7,1,1.84-.15.07-.3.11-.47.1Z"
        />
        <path
          fill="#b9b9b9"
          d="M8.83,233.66c-.05.72.38,1.24.74,1.79.38.58.69,1.28-.03,1.76-.81.55-1.53.12-1.95-.61-.86-1.47-2.01-2.47-3.71-2.77,0-.51.27-.77.74-.55,1.88.88,2.23-.22,2.32-1.73.02-.32.18-.64.27-.96.51-.16.51.42.78.58.03.92,1.2,1.44.84,2.49Z"
        />
        <path
          fill="#e4e4e4"
          d="M7.47,231.29c-.08-.23-.17-.47-.26-.7-.13-.16-.26-.32-.38-.48.69-.05,1.17.29,1.53.82.8,1.21,1.86,2.19,2.85,3.23.68,1.23,1.89,1.99,2.74,3.06.45.57,1.09,1.31-.44,1.2-1.5-1.18-2.71-2.63-3.95-4.05-.5-1.16-1.7-1.85-2.09-3.09Z"
        />
        <path
          fill="#cbcbcb"
          d="M13.92,243.62c.08-.08.16-.16.24-.24-.1-.9.47-.81,1.05-.73.8.11,1.44-.03,1.48-1.03.05-.14.13-.27.24-.38,1.79.07,2.13,1.32,2.25,2.76.02.29.21.57.41.03.29-.19.52-.51.93-.35.47.93,1.34,1.48,2.08,2.14.27.53.54,1.06.91,1.78-2.12-.29-2.24.92-2.02,2.39.07.49.07.98.1,1.47-.39-.6-.81-1.17-1.15-1.79-.48-.87-1.12-1.49-2.11-1.69-2.11-.42-3.91-1.14-3.88-3.78,0-.2-.34-.4-.53-.6Z"
        />
        <path
          fill="#c4c4c4"
          d="M89.71,180.36c-1.2,1.45-2.64,2.67-3.87,4.11-.87,1.03-1.55,2.24-2.59,3.13,0,0,0,0,0,0-.21,0-.56-.06-.6.12-.3,1.6-1.83,2.1-2.75,3.14-1.5.73-2.34,2.17-3.49,3.27-1.64,1.56-3.49,2.91-4.84,4.77-.21.29-.64.41-.97.61-.06-.07-.11-.14-.17-.21-.16-.8.26-1.41.73-1.95,1.6-1.84,3.11-3.76,5.05-5.28,1.45-1.14,2.58-2.59,3.83-3.92.96-1.02,1.84-2.1,2.74-2.74-.34.3-.9,1.2-1.25-.37,2.56-2.9,5.27-5.65,8.07-8.31.84-.04,2.03-.73,2.41.22.41,1.02-.65,1.86-1.42,2.53-.32.28-.8.37-.89.88Z"
        />
        <path
          fill="#e0e0e0"
          d="M54.64,216.51c-1.86,2.24-3.48,4.68-5.54,6.76-.14.56-.77.79-.91,1.36-.1.42-.42.66-.87.57-.38-.08-.43-.44-.53-.76-.36-1.09.23-2.83,1.54-3.54.95-.51,1.35-1.37,1.64-2.14.41-1.08,1.04-1.74,2-2.26.74-.4,1.35-.89,1.5-1.85.15-.98.9-1.65,1.55-2.36.43-.32.87-.64,1.27-.98.96-1.2,2.2-2.06,3.49-2.83.93-.55,1.48-.24,1.11.94-.33.79-1.19,1.14-1.54,1.91-1.57,1.72-3.13,3.45-4.7,5.17Z"
        />
        <path
          fill="#c4c4c4"
          d="M70.6,199.51c-1.16,1.49-2.4,2.9-3.81,4.16-.24.52-.57.95-1.19,1.04-.73-.4-.42-.9-.17-1.4,1.2-1.32,2.19-2.87,3.8-3.81.45-.26.89-.81,1.36-.04v.05Z"
        />
        <path
          fill="#b9b9b9"
          d="M79.9,190.86c.67-1.23,2.09-1.87,2.6-3.25.09-.24.49-.11.75-.01-.95,1.25-1.84,2.57-3.34,3.26Z"
        />
        <path
          fill="#c4c4c4"
          d="M59.34,211.34c.41-.54.82-1.08,1.23-1.61.53-1.06.63-2.49,2.24-2.63.22.11.34.29.36.53-1.28,1.24-2.55,2.47-3.83,3.71Z"
        />
        <path
          fill="#b9b9b9"
          d="M63.16,207.63c-.07-.08-.14-.15-.2-.23-.12-1.48,1.17-2.75,2.84-2.8.33-.31.66-.62.99-.93-.88,1.62-2.39,2.66-3.63,3.96Z"
        />
        <path
          fill="#adadad"
          d="M44.78,227c-.88.85-1.75,1.71-2.63,2.56.46.36.37.76.03,1.14-1.69,1.26-2.64,1.21-3.08-.19-.23-.73.28-1.22.6-1.82-1.38.84-2.6,1.78-3.49,3.2-.71,1.15-1.77,2.06-2.85,2.9-1.04,1.06-2,2.22-3.02,2.83.23-.23.8-.42,1.42-.33.66.1.69.76.74,1.21.06.57-.28,1.06-.92,1.13-.66.07-1.35.03-1.58.87h0c-.07.33-.17.67.05.97.28.39,1.14.55.53,1.26-.54.63-1.24.51-1.93.25-.27-.1-.47-.29-.65-.49-.39-.36.09-.9-.36-1.34-1.58-1.51-.83-3.39-.42-5.03.32-1.28,1-2.43.46-3.81-.22-.54.1-1.08.18-1.62.12-.85.06-1.63-.51-2.34-1.67-2.06-1.61-4.02.14-6.17.74-.44.41-1.18.5-1.81.71-.37.89-1.73,1.97-1.3,1.11.45.7,1.6.79,2.49.13,1.35-.54,2.56-.83,4.23.89-.81.89-1.63,1.16-2.3.47-1.17,1.21-1.96,2.53-2.09,1.1-.11,2.13-.52,3.25-.62.87-.08,1.25-1.13,1.93-1.66.33-.42.97-.56,1.06-1.19.96-.57,1.19-1.78,2.09-2.4.78-.18,1.57-.42,2.37-.15.16.11.26.26.29.45-.32,1.35.25,2.74-.21,4.08-2.41.24-3.83,2.31-5.86,3.27-.73.35-1.39.82-2.22,1-1.77.39-2.25,1.02-2.39,2.86q-.07.93-.89,1.5c-.21.06-.03-.28,0-.06.01.07.07.12.13.07.25-.17.51-.22.81-.22.97,0,1.84-.02,1.1-1.45-.35-.69.11-1.28.76-1.81,1.58-1.29,3.06-.59,4.59,0,.94,1.07,1.34-.36,2.03-.45.98.59,1.88,1.26,2.37,2.36Z"
        />
        <path
          fill="#b4b4b4"
          d="M40.08,226c-.48.19-1.01.4-1.38-.09-.93-1.24-1.66.02-2.46.19-.28.06-.32.39-.1.68.86,1.1.21,2.47-1.34,2.6-.99.09-1.98-.05-2.93.38-.25.11-.58.06-.7-.26-.13-.37.07-.71.36-.86,1.18-.58,1.28-1.53,1.37-2.73.13-1.59,1.53-2.19,2.91-2.6,1.81-.55,3.4-1.58,4.8-2.76.96-.81,1.86-1.33,3.08-1.36.56-.05.8-.54.71-.9-.24-.93-.03-1.24.96-1.2.97.04,1.36.59,1.49,1.4.08.47-.29.94-.18,1.45-.13,2.31-.6,4.6-.59,6.93-.52.59-.34,1.84-1.59,1.75.09-.55.19-1.1.28-1.64-.69.1-1.21,1.03-2.07.28-.8-.7-.61-1.41-.26-2.2-.43-.46-.79.03-.96.21-.4.44-.8.74-1.41.71Z"
        />
        <path
          fill="#9f9f9f"
          d="M41.67,230.49c.16-.31.32-.62.49-.94.92.09.88.63.63,1.29-.36.94-.86,1.78-1.61,2.47-.11-.02-.22-.04-.33-.06q-2.65-.57-2.94,2.21c-.13,1.27-1.56,2.42-3.34,2.41-.95,0-1.4-.02-1.28,1.15.11,1.13-.62,1.62-1.73,1.26-.58-.19-1.08-.15-1.57.2.5-.62,1.01-1.25,1.65-2.04-1.17-.14-1.59.76-2.29,1.05-.3.12-.65.36-.88,0-.11-.18-.02-.53.06-.77.08-.26.22-.52.4-.72,1.28-1.42,2.65-2.75,3.74-4.32.5-.07.98-.17,1.33-.6.14-.17.31-.45.47-.45,1.63-.01,2.09-1.52,3.07-2.35.57-.49,1.02-.67,1.55.03,1.29,1.7,1.3,1.69,2.58.17Z"
        />
        <path
          fill="#c1c1c1"
          d="M44.5,228.64c.6-.59.97-1.32,1.23-2.11.02-.17.08-.31.2-.43.49-.08.9-.68,1.48-.25-.41.69-.82,1.39-1.23,2.08-.52.61-1.03,1.23-1.55,1.84-.06.07-.19.07-.32.11-.49-.5.03-.85.2-1.24Z"
        />
        <path
          fill="#d5d5d5"
          d="M47.4,225.85c-.42.32-.88.53-1.42.44.04-3.46,1.91-6.09,4.16-8.38,1.43-1.46,2.9-2.8,3.98-4.57.22-.36.81-1.79,1.69-.37-.78,1.55-1.92,2.81-3.14,4.02-2.12,2.11-3.6,4.67-4.79,7.46.41-.4.81-.79,1.22-1.19-.36.99-1,1.81-1.69,2.58Z"
        />
        <path
          fill="#9f9f9f"
          d="M28.32,242.14c.52.11,1.03.22,1.71.36-.06-.73-.52-1.39-.04-2,1.24,1.77.96,3.59.16,5.42.19-1.58-.74-2.06-2.09-2.15-.62-.23-.82-.73-.67-1.3.11-.44.51-.52.93-.34Z"
        />
        <path
          fill="#b4b4b4"
          d="M202.61,18.99c-.95-.44-1.23-1.32-1.58-2.22-.23-.59-.77-1.06-1.18-1.58.16-.31.31-.62.47-.94,1.2-.51,2.4-.95,3.29.5.39-.07.79-.15,1.18-.22.16,0,.31,0,.47,0,.89-.07,1.55.17,1.63,1.2-.05.34-.11.61-.59.55-.88-.12-1.47.34-1.87,1.09-1.36.51-.55,1.44-.08,1.71,1.63.97,3.25,1.88,4.54,3.32.41.46,1.23.75,1.82.72,1.99-.07,3.77.95,5.72.9,1.03-.02,1.58.7,2.27,1.24,1.34,1.05,2.78,1.99,3.65,3.52.09.38.07.77.09,1.16.13,2.02-.39,2.7-2.34,3.05-.72.13-4.01,1.9-4.5,2.42-.75.79-1.34,1.79-2.71,1.55-.32-.05-.5.36-.54.71-.03.16-.05.34-.15.46q-1.13,1.33.44,1.94c.21.08.33.28.48.44.1.14.17.29.21.46.16,1.3-.87,1.96-1.57,2.74-.77.86-1.65,1.64-2.47,2.46-1.06,1.06-1.8,2.19-1.58,3.82.15,1.17-.58,1.71-1.74,1.63-.55-.04-1.15-.03-1.6-.32-.59-.38-.99-.46-1.28.28-.13.32-.36.57-.61.77-.51.41-1.09.77-1.75.42-.84-.45-.41-1.13-.17-1.71.39-.93.42-1.93.49-2.9.04-.59.17-1.07.49-1.54.09-.14.18-.28.28-.41,1.01-1.14,2.02.28,2.94-.04-1.16-.65-1.4-1.63-.45-2.58.61-.61.7-1.01.06-1.73-1.01-1.14-.83-1.97.47-2.71.76-.43,1.03-1.03,1-1.81-.06-1.43.49-2.31,1.87-2.94,2.05-.94,2.39-5.34.61-6.73-.57-.44-.8-.92-.93-1.61-.19-1.05-.47-2.04-1.56-2.7-.95-.57-1.14-1.87-1.96-2.66-.07-.84-.48-1.42-1.25-1.76Z"
        />
        <path
          fill="#a8a8a8"
          d="M246.36,25.61c-.48,1.92-1.43,3.68-1.67,5.7-.1.88-.98,1.68-1.5,2.52.46.28.77.64.76,1.21-.54.95-2.02.35-2.45,1.49-1.1-.04-1.09-.75-.98-1.57.25-1.83,1.46-3.19,2.3-4.73.53-.97,1.12-1.92,1.52-2.95.64-1.61.15-2.67-1.51-3.51-1.41-.71-2.11-.47-3.11,1.1-.15.23-.22.59-.44.68-3.1,1.29-2.13,3.96-1.58,5.95.54,1.95-.15,3.05-1.18,4.31-.74-.04-1.08-.64-1.54-1.08-.8-1.58-2.46-2.37-3.47-3.8-.58-.82-1.44-1.44-1.81-2.44-.13-.36-.35-.53-.73-.55-.22,0-.5,0-.63-.15-2.21-2.72-5.87-3.47-8.18-6.05-.17-.19-.16-.53-.45-.61-.08-.27.03-.52.09-.76-.26-.42-.59-.82-.77-1.27-.27-.7-.32-1.44.29-2.01.55-.51,1.3-.48,1.88-.16,2.73,1.5,5.84,2.31,7.96,4.89.78.94,2.19,1.37,3.47.58,1.07-.66,1.07.02,1.16.76.11.92-.33,2.4,1.08,2.34,1.15-.05.63-1.42.67-2.24.06-1.28.63-2.09,1.93-2.32.71-.12,1.42-.24,2.13-.36,1.48.86,3.04,1.55,4.68,2.01,1.56.44,2.32,1.37,2.09,3.02Z"
        />
        <path
          fill="#c8c8c8"
          d="M219.8,20.37c.15.16.31.31.46.47,2.42,1.25,4.28,3.26,6.49,4.79.45.31.58.77.77,1.22.48,1.33,1.37,2.33,2.53,3.11.23.15.48.3.63.54.52.81.79,1.83.24,2.55-.56.73-1.41.18-2.07-.27-.14-.09-.27-.21-.42-.26-3.2-1.02-5.65-3.04-7.73-5.63-2.37-2.95-5.91-3.25-9.3-3.72-1.39-.19-2.63-.54-3.47-1.68-.71-.96-1.9-1.12-2.75-1.81-.65-.53-1.74-.96-1.26-1.95.46-.96,1.43-.79,2.32-.28.74.42,1.59.25,2.4.18.93-.15,1.68.74,2.62.48.05,0,.14-.03.16-.01,1.31,2.27,4.01,1.94,5.63,3.07-1.19-.28-2.4-1.24-3.96-1.38-1.12-.1-1.17-1.26-.2-2.58,1.28-.42,1.72,1,2.69,1.25,1.41.64,2.81,1.29,4.22,1.93Z"
        />
        <path
          fill="#b8b8b8"
          d="M215.58,18.44c-.78-.2-1.51-.58-2.35-.51-.84-.16-1.39.54-2.13.69-.83.05-1.59-.24-2.35-.5-.36-.07-.7-.2-.99-.43.12-1.16,0-2.44,1.51-2.86.6.25,1.2.73,1.8.72,2.19-.03,3.72.82,4.51,2.9Z"
        />
        <path
          fill="#aeaeae"
          d="M204.28,16.88c.31-1.66,1.69-.89,2.61-1.15.24.08.47.16.71.24.87.12.94.55.47,1.19-.28.67-.89.65-1.37.45-.79-.32-1.65-.35-2.41-.75Z"
        />
        <path
          fill="#b4b4b4"
          d="M207.82,17.17c-.07-.4-.14-.79-.22-1.19.33-.71.82-1.18,1.66-1.16-.81.67-.16,2.02-1.21,2.58-.16.01-.25-.06-.23-.23Z"
        />
        <path
          fill="#aeaeae"
          d="M216.46,5c1.7-.25,2.07,1.37,3.04,2.16-1.48-.06-1.95-1.55-3.04-2.16Z"
        />
        <path
          fill="#aeaeae"
          d="M203.61,14.76c-1.1-.17-2.2-.34-3.29-.5.41-.5.56-1.04.26-1.65.24-.23.47-.47.71-.7.98.79,2.05,1.5,2.33,2.85Z"
        />
        <path
          fill="#c1c1c1"
          d="M217.94,8.56c.57,1.35,1.64,2.55,3.11,3.47.73.46.29,1.05-.4,1.34-1.84.76-4.06-.04-5-1.8-.23-.44-.47-.88-.67-1.34-.31-.71-1.21-1.33-.5-2.21.41-.5,2.71-.18,3.46.54Z"
        />
        <path
          fill="#bbb"
          d="M150.51,127.07c-1.9.64-3.47,1.6-4.33,3.59-.61,1.43-3.36,2.38-4.94,1.95-1.52-.41-2.32.28-2.2,1.89.21,2.79-.55,3.77-3.14,4.78-1.65.65-2.06,2.41-2.72,3.87-.12.26.12.71-.25.89-.45.22-.7-.19-.98-.43-1.85-1.59-2.17-5.08-.48-7,.94-1.06,1.91-2.18,3.38-2.61,1.24-.36,1.7-1.15,1.7-2.48-.01-1.69.51-3.19,1.98-4.34,1.2-.95,1.31-2.51,1.33-3.95.02-2.02,1.54-2.94,3.33-1.95.33.18.65.58,1.02.24.4-.36-.05-.7-.21-1.02-.22-.43-.33-.98.05-1.24,1.26-.85,1.9-2.43,3.62-2.78,1.46-.29,1.56-.82.66-1.99-.17-.22-.47-.41-.25-.69.27-.33.64-.16.88.06,1.22,1.12,2.16.78,3.01-.47.17-.25.53-.39.83-.51,2.07-.81,3.4-3.31,2.9-5.42-.19-.79-.51-1.47-1.28-1.84-.71-.34-.93-.9-.78-1.64-.76-.36-.89-.91-.44-1.6,1.09-1.68,2.5-3.1,3.78-4.62,1.09-1.3,2.16-2.48,3.92-2.8.27-.05.57-.37.58-.62.03-1.32.45-2.36,1.78-2.86.17-.06.18-.33.13-.44-.75-1.81.65-2.85,1.46-4.09.97-1.27,2.13-2.4,2.94-3.81,1.38-1.79,2.68-3.63,4.24-5.27.37-.39.71-.65,1.18-.8.52-.25,1.01-.18,1.3.32.27.46-.07.85-.34,1.19-.48.61-.94,1.24-1.37,1.88-1.17,1.73-2.54,3.39-2.45,5.68.03.79-.47,1.34-1.06,1.79-.93.69-1.07,1.55-.8,2.65.54,2.18.33,4.25-1.28,6-.43.47-.66,1.02-.74,1.64-.07.57-.19,1.06.73,1.08,1.32.04,1.47.99,1.16,2.02-.32,1.08-.93,2.01-1.75,2.79-.65.61-1.24,1.27-1.61,2.09,2.19-1.17,3.4-2.67,4.19-5.19.05-.16.02-.39.13-.47,1.9-1.39,2.7-3.89,4.94-4.93.45-.21.98-.35,1.3-.72,1.37-1.62,3.52-2.02,5.1-3.34.49-.41,1.01-.75,1.31-1.32.86-1.6,1.48-1.7,2.8-.41.05.22.11.49-.05.64-2.06,1.85-3.89,4.01-6.19,5.52-.91.6-1.74.86-2.16,2.02-.26.74-1.13,1.23-1.82,1.78-2.11,1.7-4.66,3.18-4.78,6.41-.01.42-.27.72-.6.96-1.93,1.42-3.73,2.97-5.52,4.56-1.07.95-2.38,1.56-2.91,3.01-1.22.54-1.92,1.5-2.08,2.79-.17,1.34-1.48,2.45-.73,4,.14.28-.42.72-.81.96-.2.14-.41.27-.62.41-.53.39-1,.62-1.78.49-.97-.17-2.16-.21-1.9,1.54.13.88-1.31,2.04-2.32,2.14Z"
        />
        <path
          fill="#cfcfcf"
          d="M156,122.18c.16-.15.32-.31.48-.46.37-.05.64-.29.75-.59,1.33-3.6,4.27-5.92,6.89-8.45,2.5-2.41,4.83-4.98,6.82-7.78,1.9-2.67,4.17-4.98,6.29-7.43,1.5-1.73,3.08-3.51,5.1-4.75,2.95-2.98,6.02-5.85,8.98-8.8,2.36-2.35,5.22-4.03,7.47-6.47,1.48-1.6,3.5-2.62,4.91-4.34.7-.85,1.14-1.97,2.32-2.37,1.83-.62,3.2-1.98,4.8-2.96.34-.21.72-.41.87-.75,1.08-2.33,3.39-2.76,5.45-3.58.08-.07.16-.13.24-.2,1.18-.74,2.54-1.16,3.61-2.11,1.43-1.26,3.21-1.47,5-1.46,1.09,0,1.05.56.39,1.23-4.56,1.58-8.5,4.22-12.28,7.14-.18.14-.33.3-.57.28-.8.14-1.43.57-2.02,1.1-6.45,5.77-13.24,11.16-19.25,17.4-5.04,5.23-10.02,10.53-14.97,15.85-2.34,2.52-4.79,4.93-7.15,7.44-5.13,5.46-9.79,11.34-14.98,16.75-2.45,2.55-4.77,5.24-7.11,7.88-2.59,2.92-5.37,5.68-7.91,8.66-2.56,3-5.18,6.05-8.3,8.56-1.23.99-2.09,2.29-2.91,3.61-1.28,1.08-2.27,2.45-3.58,3.49-.79-.46-1.5-1-1.84-1.9.13-1.69,1.47-2.57,2.55-3.56.69-.63,1.29-1.25,1.8-2.02.86-1.3,1.62-2.73,3.26-3.26.49-.16.65-.65.87-.97.75-1.15,1.83-1.92,2.89-2.71.13-.15.25-.3.34-.46.14-.54.32-1,.86-1.31,1.58-.9,2.54-2.56,4.14-3.44l.24-.23c1.68-2.05,3.12-4.29,4.85-6.3,1.12-.39,2.06-.97,2.14-2.3.03-.43.45-.66.59-.73,1.78-.92,2.61-2.85,4.25-3.9.06-.04.29-.45.39-.72.57-1.55,1.82-2.48,3.32-3.08Z"
        />
        <path
          fill="#cdcdcd"
          d="M150.23,102.97s-.09.06-.13.09c-1.27,1.02-2.13,2.42-3.22,3.6-.66.94-1.37,1.84-2.08,2.73-1.66,2.21-3.45,4.42-5.45,6.17-.7.67-1.39,1.35-1.98,2.09-1.28,1.61-3.14,2.8-3.88,4.79-.46,1.25-1.72,1.96-1.97,3.29.1.54.32,1.05.51,1.57.32.89,1.18.94,1.65.59,1.12-.86,2.67-1.54,3.22-2.72,1.09-2.36,2.33-4.54,4.07-6.46.24-.39.48-.78.72-1.17,2.01-.26,3.19-1.55,3.86-3.3.37-.96.93-1.71,1.75-2.23.34-.22.62-.44.82-.77,1.24-2.04,2.49-4.06,4.1-5.83l.47-.47c.06-.5.78-.75.49-1.38-1.04.09-1.97-.4-2.95-.59Z"
        />
        <path
          fill="#c3c3c3"
          d="M129.68,134.86c.16-.32.33-.64.49-.95-.28-1.05.88-1.53.97-2.43.22-.68.31-1.2-.05-2.01-.42-.94-.84-2.33.25-3.36.7-.16.77.47.75.81-.09,1.21.81,1.14,1.53,1.27.97.16,1.04-.57,1.24-1.27.3-1.02.53-2.12,1.91-2.36.6-.1.58-.84.84-1.28.27-.46.41-1.02.95-1.29.6,1.78.22,3.3-.93,4.81-1.31,1.71-2.42,3.58-3.58,5.4-.89,1.41-2.27,2.4-3.1,3.88-.49.88-1.04.68-1.29-.28-.26-.32.1-.62.03-.93Z"
        />
        <path
          fill="#bbb"
          d="M129.68,134.86c0,.31-.02.62-.03.93-1.8,2.26-2.94,4.75-3.18,7.7-.13,1.59-1.62,2.73-2.03,4.32-.16.64-.47,1.19.51,1.35.54.09,1.59-.57,1.42.62-.12.9-.73,1.82-1.89,1.77-.82-.04-1.54.07-2.14.67,1.05-6.37,2.91-12.4,7.32-17.35Z"
        />
        <path
          fill="#c5c5c5"
          d="M135.19,144.81c-1.16,1.02-1.98,2.59-3.09,3.33-2.16,1.44-3.18,3.61-4.74,5.42-1.14,1.32-2.56,2.41-3.85,3.61-.19-1.03-.79-1.97-.69-3.07.12-1.12.87-1.5,1.85-1.42,1.01.08,1.52-.45,2.17-1.15,2.28-2.44,4.62-4.85,7.42-6.73.31.14.64-.69.94.01Z"
        />
        <path
          fill="#c4c4c4"
          d="M141.68,117.54c3.21-2.94,5.38-6.74,8.27-9.94.71-.79,1.53-1.44,2.26-2.2-.49,1.21-1.85,2-1.7,3.52h0c-.57.81-1.32,1.51-1.42,2.58-.04.4-.3.78-.7.86-1.59.32-1.78,1.62-2.39,2.83-1.52,3.03-2.05,3.64-4.33,2.35Z"
        />
        <path
          fill="#c4c4c4"
          d="M156,122.18c-2.04,2.18-3.71,4.69-5.93,6.71-1.5,1.37-2.59,3.16-4.35,4.25-.42-.08-.72.5-1.18.22,2.23-1.88,3.5-4.65,5.98-6.3.83-.93,1.66-1.72.85-3.23-.43-.82.26-1.85,1.41-1.53,1.14.32,2.15-.04,3.22-.13Z"
        />
        <path
          fill="#c4c4c4"
          d="M138.56,121.98c-.44,1.21-.88,2.4-2.15,3.04-.61.3-.78.91-.74,1.56,0,.17.03.33.06.5.2,1.34-.43,2.55-1.56,2.79-1.3.27-1.73-1.25-2.31-2.18-.21-.34-.13-.87-.19-1.31-.22,0-.35-.12-.38-.34.1-.33.3-.48.64-.36.99,1.84,1.17,1.73,2.68.38,2.44-2.19,3.77-5.31,6.36-7.35-.62,1.22-1.5,2.25-2.4,3.27Z"
        />
        <path
          fill="#c5c5c5"
          d="M152.69,104.93c.09-.39.17-.78.26-1.18-.61-.68.03-1.15.34-1.58,1.33-1.84,2.71-3.65,4.16-5.4,2.09-2.52,3.94-5.21,5.88-7.84.52-.7,1.02-1.49,2.07-1.45.09.05.17.1.26.15-.92,1.68-1.51,3.55-2.93,4.93-.42.41-.52.96-.63,1.51-.12.56-.33,1.09-.83,1.39-3.44,2.05-5.27,5.53-7.62,8.54-.32.31-.64.62-.95.93Z"
        />
        <path
          fill="#bbb"
          d="M150.51,108.92c.09-.14.17-.28.27-.41.42-.58.83-1.26,1.7-.97.59.2.77.75.69,1.34-.1.68-.56,1.05-1.2,1.18-.93.2-1.29-.37-1.46-1.15,0,0,0,0,0,0Z"
        />
        <path
          fill="#c4c4c4"
          d="M135.19,144.81c-.31,0-.63,0-.94-.01.04-.1.15-.23.12-.3-.98-2.17.6-2.51,2.08-2.93,1.19-.35,1.92-1.46,3.06-1.89-.62,1.03-1.28,2.19-2.36,2.6-1.21.46-1.59,1.2-1.71,2.3-.08.08-.16.16-.24.24Z"
        />
        <path
          fill="#c5c5c5"
          d="M135.43,144.57c-.31-1.25.09-2.15,1.32-2.61,1.18-.44,1.89-1.46,2.75-2.29.24-.08.47-.15.71-.23-.98,1.32-2.08,2.53-3.4,3.52-.58.44-1,1.01-1.38,1.61Z"
        />
        <path
          fill="#c5c5c5"
          d="M144.54,133.36c.39-.07.79-.15,1.18-.22-1.92,1.88-3.25,4.27-5.27,6.07,1.16-2.09,2.61-3.98,4.08-5.85Z"
        />
        <path
          fill="#b1b1b1"
          d="M213.6,68.55c.71-.47,1.43-.95,2.16-1.43.87-.66,1.74-1.32,2.74-1.77,2.47-2.03,5.46-3.05,8.3-4.37-.07-.54-.44-.22-.7-.24-4.77,1.59-8.8,4.39-12.59,7.6-.16.17-.32.34-.48.51.17-.07.33-.16.49-.24.03-.02.05-.04.08-.06Z"
        />
        <path
          fill="#c5c5c5"
          d="M125.34,159.07c1.06-1.3,1.83-2.89,3.58-3.49-.85,1.51-2.04,2.69-3.58,3.49Z"
        />
        <path
          fill="#c3c3c3"
          d="M89.71,180.36c.36-.48.73-.96,1.08-1.44.31-.43.89-.84.56-1.42-.29-.52-.9-.18-1.37-.2-.2-.92.64-1.19,1.07-1.71.2-.22.7-.11.66-.61,1.83-1.84,3.71-3.6,5.76-5.18.34-.27.9-.25,1.05-.78.48-1.73,1.8-2.36,3.42-2.57.44-.05.62-.34.82-.69.47-.81,1.08-1.54,1.91-1.97.23-.12.09-.54.45-.41.42-2.43,2.64-3.15,4.26-4.4,1.48-1.15,3.01-2.18,4.21-3.69,1.18-1.48,2.94-1.69,4.75-1.46.51.64.36,1.14-.2,1.73-3.19,3.4-6.59,6.53-10.41,9.25-2.15,1.53-3.99,3.47-6.1,5.06-1.67,1.26-3.51,2.27-5.3,3.36-.47.29-1.14.41-1.27.99-.69,3.03-3.52,4.15-5.36,6.16Z"
        />
        <path
          fill="#b8b8b8"
          d="M118.34,153.82c-1.99.52-3.78,1.12-4.93,3.18-.79,1.4-2.48,2.18-3.96,2.94-2.54,1.31-3.81,3.9-5.91,5.64-1.86,1.54-3.79,3.01-5.68,4.51-1.3.96-2.5,2.05-3.88,2.91-1.14-.36-.59-.96-.16-1.46,1.26-1.48,2.78-2.67,4.51-3.56.72-.37,1.33-.79,1.66-1.48.11-.23.37-.5.41-.51,2.09-.68,2.69-2.72,3.91-4.2.73-.89,1.25-1.95,2.17-2.7,1.34-1.1,1.43-2.96,2.48-4.25.25-.24.49-.47.73-.71.8-.35,1.67-.34,2.52-.41.6-.05,1.21-.06,1.6-.63.53-.3,1.06-.22,1.59,0,.49.73,1.11.81,1.77.28.59-.47,1.03-.38,1.42.22-.08.08-.16.16-.24.24Z"
        />
        <path
          fill="#adadad"
          d="M118.58,153.58c-1.1.15-2.2.3-3.31.45-.11-.18-.13-.36-.05-.55.21-.26.46-.34.77-.16,1.06-.35,2.14-.99,3.06.26-.16,0-.32,0-.48,0Z"
        />
        <path
          fill="#919191"
          d="M130.6,118.97c-.37-1.59-1.03-1.9-2.45-1.19-4.25,3.25-7.6,7.53-11.09,11.54-4.17,4.8-8.69,9.26-13.24,13.7-4.51,4.4-8.65,9.23-13.26,13.51-2,1.86-4.11,3.6-6.21,5.33-2.32,2.12-4.69,4.19-7.12,6.18-.09.08-.18.16-.26.25-.45.44-.92.8-1.39,1.09-.97.78-1.95,1.55-2.94,2.3-2.32,2.08-4.76,4-7.27,5.83-.22.19-.45.38-.7.55-.89.63-1.79,1.27-2.68,1.9-.18.2-.36.4-.53.56-1.7,1.57-3.61,2.9-5.45,4.31-1.49,1.14-3.05,2.27-4.6,3.43-.73.61-1.46,1.22-2.2,1.83-1,.95-1.98,1.93-2.91,2.96-.22.24-.45.46-.68.67-.03.03-.06.07-.09.1-.26.27-.56.52-.88.74-.53.68-1.12,1.25-1.82,1.76,0,0,0,0,0,0-.88.85-1.75,1.7-2.63,2.55,0,.23.12.38.34.45.77-.12,1.15-.81,1.76-1.17,3.5-2.63,6.77-5.54,10.18-8.27,3.26-2.6,6.66-5.04,9.76-7.81,2.73-2.43,5.42-4.88,8.35-7.06,3.56-2.71,7.21-5.35,10.43-8.43,3.2-3.07,7.4-4.82,10.29-8.29.68-.82,1.94-1.1,2.09-2.49.04-.39.67-.69,1.04-1.02,1.22-1.08,2.62-1.92,3.58-3.35,2.75-4.07,6.54-7.18,10.12-10.47,1.44-1.33,3.23-2.34,3.73-4.49.16-.7.86-1.08,1.57-1.38,1.21-.51,2.12-1.4,2.44-2.72.13-.53.36-.94.72-1.27,1.73-1.58,3.11-3.47,4.73-5.16,1.7-2.3,3.79-4.16,6.28-5.56.47-.12.76-.39.87-.89.05-.24.17-.53.55-.45.33.07.45.42.44.57-.06.72.38,1.71-.09,2.11-1.39,1.2-.91,3.71-3.13,4.27-.28.07-.22.6-.37.89-.98,1.09-1,2.86-2.5,3.59-.13.12-.25.24-.36.38-.41,1.32-1.05,2.46-2.13,3.4-.67.58-1.07,1.4-1.31,2.32-.65,2.45-1.84,4.64-3.59,6.51-.22.12-.46.22-.67.37-.57.44-1.56.56-1.46,1.52.1.93.67,1.79,1.44,2.18.72.37,1.55.54,2.21,1.06.55.64.24,1.95,1.56,2.01.26-1.45-.24-2.83-.39-4.24-.08-.62,0-1.21.17-1.81,1.03-3.59,2.76-6.88,4.45-10.18,1.67-3.27,3.96-6.16,6.15-9.09,1.37-1.83,1.66-3.83,1.16-5.99Z"
        />
        <path
          fill="#959595"
          d="M128.04,133.18c.11-.48.36-.98.29-1.44-.3-2.02.63-3.52,1.93-4.9.16,0,.31.05.45.14.29.57-.16.99-.42,1.31-.92,1.12-.35,2.15.41,2.74.96.75.68,1.19.07,1.84-.43.45-.84.93-1.1,1.51-1.72,1.71-3.26,3.56-4.66,5.55-.65.92-1.65,1.59-2.5,2.38-.09.08-.19.16-.28.24-.07-.08-.13-.16-.2-.24,1.49-1.68,2.64-3.61,4.15-5.28.94-1.05,1.5-2.43,1.86-3.84Z"
        />
        <path
          fill="#ababab"
          d="M118.18,147.92c.14.84.47,1.62.88,2.37-.06.08-.12.16-.18.23-1.69.58-3.47.69-5.23.89-.23.43-.58.63-1.06.65-1.16.06-2.28.34-3.39.68-.68.2-1.36.32-1.39-.74.16-.42.15-.88.25-1.32,1.16-.83,2.56-.78,3.89-.93.6-.06,1.2-.02,1.81-.1q2.34-.31,2.79-2.68c.06-.3.2-.5.49-.59.87.15,1.02.83,1.13,1.53Z"
        />
        <path
          fill="#8a8a8a"
          d="M128.04,133.18c.05,1.24-.49,2.31-1.21,3.2-1.59,1.98-2.95,4.16-4.8,5.92.96-1.4,1.92-2.81,2.88-4.21,1.59-1.29,2.08-3.29,3.13-4.92Z"
        />
        <path
          fill="#999"
          d="M108.23,152.04c1.77-.5,3.54-1.08,5.42-.64.49.3,1.02.41,1.59.38,1.48-.09,1.78.4.75,1.53-.16.15-.32.31-.48.46-.6.14-1.22.2-1.82.11-1.66-.25-3.12.24-4.49,1.11-.43.14-.79.35-.96.8-.19.2-.35.51-.63.5-2.19-.04-3.92,1.19-5.77,2.08-1.16.42-2.19,1.07-3.15,1.82-.49.38-1.02.69-1.64.75-.86.08-1.3-.54-.97-1.34.08-.2.24-.37.37-.55,1.58-2.18,3.38-4.14,5.47-5.85.26-.21.56-.38.9-.38,1.54-.03,3.03-.23,4.36-1.13.32-.22.88-.31,1.06.34Z"
        />
        <path
          fill="#a3a3a3"
          d="M129.66,134.37c.22-.5.44-1.01.67-1.51.16-.36.42-.81-.12-.97-2.2-.67-1.19-2.07-.73-3.28.21-.55.65-1.02.98-1.53.76.07.38.64.46,1.02.16.76-.11,1.62.9,2.13.55.28.15,1.04,0,1.58-.35.85-1.02,1.45-1.64,2.1-.17.16-.34.32-.51.47Z"
        />
        <path
          fill="#acacac"
          d="M208.92,55.47c-.4.82-.99,1.51-1.7,2.02-2.82,2-4.85,4.81-7.39,7.09-.07.06-.11.07-.13.02,1.8-1.7,1.86-1.7,2.06-3.56.18-1.67.77-3.06,1.92-4.31,1.2-1.32,1.41-2.95,1.05-4.68-.15-.71-.67-1.02-1.22-1.17-.57-.16-.62.45-.77.84-.23.59-.69.88-1.27,1.1-.4.15-.71.2-.97-.12-.21-.25-.32-.46-.13-.91.65-1.61.65-3.5,2.12-4.75,1.3-.68,1.63-1.92,1.93-3.22.04-.19.28-.43.36-.64.1-.27.33-.62-.15-.92-.75-.46-.47-1.05-.09-1.65.54-.85,1.36-1.73,1.14-2.76-.35-1.66.23-2.59,1.68-3.3,1.52-.75,1.62-2.79.27-3.65-1.51-.95-1.56-1.14-.74-2.72.94-1.81.7-3.02-.66-4.61-.78-.91-1.54-1.89-1.73-3.15-.55-.57-1.11-1.13-1.87-1.44.51.58.65,1.24.53,2.01-.21,1.3.05,2.4,1.34,3.11.86.47.91,1.24.71,2.16-.51,2.34-1.98,4.19-3.11,6.2-.77,1.36-1.35,2.96-3.04,3.6-.54.2-.91,1.13-1.52.73-.53-.35.1-1,.15-1.53.07-.74.25-1.57-.94-.78-.96-.42-1.13.34-1.36.94-.99,2.64-2.23,5.16-3.49,7.69-1.56,3.12-3.34,6.09-5.24,8.99-1,2-2.05,3.97-3.34,5.8-1.27,2.44-2.93,4.63-4.75,6.7-1.61,3.46-3.87,6.4-6.37,9.17-.27.41-.59.79-.99,1.13-.12.15-.24.29-.36.44-.11.12-.22.23-.34.34-3.53,5.77-7.63,11.16-12.04,16.29-3.52,4.53-7.23,8.9-10.71,13.46-.58.76-1.17,1.57-1.78,2.39.08.07.16.13.26.19.49-.21.86-.52,1.03-1.04,1.11-1.25,2.22-2.49,3.33-3.74.75.36,1.09-.18,1.49-.65,1.75-2.01,3.37-4.12,5.05-6.18,3.54-4.34,6.83-8.87,10.42-13.17,1.4-1.79,2.74-3.66,4.15-5.41,1.45-1.79,2.62-3.79,4.18-5.49.83-.9,1.01-2.17,1.8-3.14,3.35-4.09,6.43-8.34,8.92-13.04.85-1.6,1.63-3.35,2.96-4.68,1.39-2.39,2.6-4.89,3.86-7.04-.09.23-.21.77-.59,1.18.22.91.35,1.78-.07,2.71-.97,2.16-2.38,4.02-3.91,5.79-.07.16-.09.32-.06.5.41.74.89,1.45.88,2.35-.02,2.2-.02,2.19,2.1,2.56.96.17,1.46.61,1.04,1.7-.25.64-.41,1.35-.42,2.05,0,.89.33,1.47,1.37,1.1.14-.54.26-1.08.43-1.6.43-1.37,1.64-2.29,2.53-1.8,1.4.77.4,1.82.12,2.74-.16.51-.79,1-.44,1.51.7,1.04.83,2.08.64,3.29-.13.88.52,1.15,1.3,1,.81-.15,1.18-.74,1.4-1.48.2-.69.34-1.46,1.18-1.68,2.45-.63,3.66-2.72,4.59-4.63.98-2.02,2.4-2.98,4.31-3.45,2.26-.55,3.61-1.95,4.36-4.05-2.29-1.7-4.01-1.3-5.24,1.23Z"
        />
        <path
          fill="#b0b0b0"
          d="M214.17,54.24c-1.71.3-3.63,1.65-5.16,2.84-2.68,2.08-4.83,4.73-7.47,6.83-.76.6-1.33,1.32-1.8,2.16-.16.29-.39.61-.77.46-.38-.15-.33-.53-.29-.87.06-.57.27-1.11.67-1.49,1.21-1.16,1.58-2.62,1.69-4.21.09-1.34.73-2.41,1.63-3.37,1.05-1.12,1.52-2.44,1.23-3.98-.15-.78-.5-.98-1.09-.3-.54.63-1.09,1.26-1.66,1.86-.36.37-.81.63-1.35.46-.52-.17-.58-.66-.55-1.1.05-.77-.39-1.34-.94-2.17,1.64.16,2.04-.82,2.25-1.9.23-1.16,1.15-1.72,1.91-2.43-.7,1.22-1.14,2.51-1.28,3.91-.04.38-.23.84.24,1.02.54.2.8-.25.98-.66.16-.35.24-.74.36-1.1.25-.72.61-.93,1.15-.24.37.47.86.67,1.43.74.85.1,1.49-.03,1.24-1.13-.3-1.32.41-2.22,1.23-3.09,1.7-1.82,3.4-3.64,5.1-5.46.05-.08.1-.16.15-.25,1.22-.65,2.45-.72,3.38.38,1,1.19,2.52,1.31,3.73,2.08.1.13.16.29.2.45.57,1.99,0,3.86-1.76,5.14-.81.59-.63,1.29-.64,2.03-.7,1.11-1.75,1.83-2.87,2.45-.32.31-.64.62-.96.93Z"
        />
        <path
          fill="#adadad"
          d="M242.08,47.01c-.16.16-.32.31-.48.47-.1-.01-.19-.05-.27-.11.08.06.17.09.27.11.24.99,1.51,1.8.66,2.98-.84,1.18-2.06,1.04-3.29.75-.21-.05-.46-.11-.65-.04-2.03.74-2.61-.62-3.11-2.11.61,0,1.2.04,1.8.15,1.17.21,1.56-.18,1.1-1.21-.5-1.09-.84-2.1-.36-3.32-.67,1.04-1.36,2.01-1.41,3.29-.02.51.05,1.25-.89.86-.93-.73-1.64-.2-2.36.39-.87.71-1.75,1.03-2.54-.13-.46-.67-1.22-.71-1.92-.86-1.01-.21-1.94-.53-2.34-1.62,1.1-.32,1-1.1.39-1.64-.93-.83-1.14-1.99-1.7-2.98-.02-1.13.57-1.66,1.65-1.89.86-.18,1.79-.29,2.39-1.08.88-1.16,2.08-1.49,3.46-1.37,1.77.76,3.54,1.52,5.27,2.36.58.29,1.15.57,1.81.61.49.32,1.2.13,1.58.69.74,1.83,1.44,3.66.95,5.7Z"
        />
        <path
          fill="#bbb"
          d="M217.11,63.45c-3.67,3.59-8.25,5.98-12.12,9.32-4.31,3.72-8.64,7.43-12.91,11.21-3.28,2.91-6.35,6.04-9.54,9.03-2.19.96-3.77,2.78-5.29,4.45-2.39,2.62-5.01,5.08-6.79,8.23-.99,1.75-2.23,3.31-3.88,4.54-1.34,1-1.99,2.63-3.24,3.74-.77.68-1.55,1.1-2.62.92-.71-.12-.93-.49-.83-1.15.99-2.45,3.58-2.84,5.39-4.24,1.18-.91,2.31-1.89,3.46-2.86.16-.14.34-.33.38-.53.6-3.02,3.08-4.68,4.78-6.94,2.02-2.69,4.91-4.08,7.29-6.19,1.07-.95,2.04-2.01,3.06-3.02-.08-.31-.25-.76.08-.89,1.73-.71,2.07-3,3.99-3.46.48-.31,1.04-.5,1.4-.98,2.07-1.3,3.72-3.03,5.23-4.93.37-.47,1.02-.59,1.52-.88,1.14-1.15,2.35-2.22,3.75-3.05.32-.15.64-.31.95-.46,1.8-1.77,3.5-3.64,5.46-5.24.38-.31.8-.47,1.15-.76.73-.56,1.26-1.41,2.21-1.67.08-.08.16-.15.24-.23,1.01-.37,1.54-1.29,2.31-1.93,1.14-.95,2.5-1.65,3.88-2.24.23.07.47.15.7.22Z"
        />
        <path
          fill="#b0b0b0"
          d="M189.18,54.43c.63.72,1.08,1.52,1.07,2.52-.01,1.58.86,2.15,2.33,2.05.38-.03.77-.08,1.15-.07.5,0,1.05.05,1.26.59.24.6-.26.87-.65,1.19-1.49,1.23-1.58,1.85-.59,3.67-.72,1.98-2.69,3.06-3.6,4.91-.07.03-.14.07-.22.1-1.46-.47-2.77-1.08-3.37-2.68-.25-.66-.97-.84-1.62-1.05-2-.67-2.19-1.48-.61-2.99.79-.76,1.16-2.03,2.46-2.19,1.15-.14,1.75,1.19,2.92,1.05-.81.13-1.31-.48-1.92-.8-.8-.42-.87-1.1-.43-1.87.56-.99.91-2.01.72-3.2-.11-.71-.06-1.51,1.09-1.23Z"
        />
        <path
          fill="#b0b0b0"
          d="M189.96,84.85l-1.19,1.17c-.87.3-1.35,1.11-2.05,1.64-.82.61-1.64,1.06-2.65,1.18-1.02.12-1.84.51-2.21,1.55-.25.69-.77,1.15-1.47,1.06-.83-.11-1.34-.73-1.48-1.57-.21-1.26.02-2.56.73-3.52,1.55-2.1,1.76-4.82,3.29-6.86,2.06-2.74,3.11-5.97,4.73-8.92.53-.97,1.28-1.63,2.51-1.28,0,0,0,0,0,0-.21,1.98-.61,3.86-1.72,5.62-1.02,1.62-1.7,3.46-2.38,5.27-.37.99-.58,1.96-1.55,2.62-.65.44-.63,1.27-.44,1.98.19.72.88.75,1.47.87,1.3.25,2.36-.41,3.43-.97.59-.31,1.14-.67,1.71-1.01,0,.54-.72.64-.72,1.17Z"
        />
        <path
          fill="#b3b3b3"
          d="M243.18,34.77c0-.32,0-.63.01-.95.57-.19,1.1-.14,1.51.33.52.59,1.08.89,1.89.59.68-.25,1.05.11,1.2.74.41,1.76.64,3.63,2.93,4.06.25.05.43.48.64.73.14.36.29.71.4,1.08.44,1.41-.08,2.13-1.58,2.03-.81-.06-1.6-.36-2.39-.56-.27,0-.41-.18-.56-.37.41-.93.34-1.68-.43-2.49-.87-.91-1.58-1.13-2.47-.13-.19.07-.37.11-.57.07-1.05-.24-1.6-1.2-2.45-1.73-.6-.38-.4-1.23.16-1.85.52-.58,1.42-.74,1.71-1.57Z"
        />
        <path
          fill="#b3b3b3"
          d="M244.06,39.49l.24-.23c.37-.23.6-.09.76.27,0,.09-.04.18-.03.28.04-.3.24-.41.51-.46,1.89,1.06,2.29,1.73,1.79,3.01.16.16.31.32.47.48-.86-.08-1.73-.2-1.03,1.15.37.73.22,1.53.08,2.29-.15.78-.76,1.12-1.33.57-1.26-1.21-2.32-.53-3.43.16-.8-1.64-1.16-3.4-1.35-5.2.07-1.79-1.62-2.58-2.3-3.95-.29-.59-.68-1.01.07-1.48.32-.16.63-.2.94.02.96,1.17,2.21,2.01,3.36,2.96.38.32.78.7,1.26.13Z"
        />
        <path
          fill="#b3b3b3"
          d="M235.45,48.82c-1.06-1.92.6-3.37.97-5.03.2-.92,1.47-.86,2.31-.43.71.36,1.16,1.13.58,1.79-1.28,1.45-.21,2.56.35,3.75.2.42.8.76.44,1.3-.34.5-.92.24-1.4.22-1.31-.07-2.51-.43-3.48-1.36.08-.08.15-.16.23-.23Z"
        />
        <path
          fill="#adadad"
          d="M215.12,53.31c.52-1.18,1.55-1.91,2.39-2.8-.12-.4-.18-.79.27-1.04.63-.39,1.2-1.05,2.02-.25.53.52.94.12,1.15-.38.7-1.64,2.33-2.76,2.54-4.67.04-.34.33-.58.7-.68.83-.21,2.8,1.28,2.76,2.12-.02.36-.24.61-.66.51-1.28-.38-2.52.17-2.51,1.65.01,2.38-1.93,3.06-3.1,4.4-.37.42-.83.53-1.35.22-1.55-.92-1.4.11-1.25,1.1.1.63.2,1.28-.63,1.47-.69.16-1.2-.14-1.44-.81-.16-.44-.34-.83-.89-.85Z"
        />
        <path
          fill="#a1a1a1"
          d="M206.41,63.84c.01,1.65-.95,2.69-1.28,3.94-.56,2.12-1.42,4.33-3.86,5.21-.31.11-.6.39-.93.2-.47-.26-.17-.67-.14-1.04.25-2.95,2.21-4.82,4.28-6.56.58-.49,1.02-1.14,1.94-1.76Z"
        />
        <path
          fill="#b0b0b0"
          d="M226.29,46.13c-.09-.78-.53-1.32-1.21-1.67-.37-.19-.93-.27-.99.17-.19,1.41-1.58,2.3-1.54,3.77.05,1.44-.8,1.82-2.02,1.73-.78-.06-1.54-.04-2.3.15-.34-.9.17-1.32.9-1.63.51-.21,1.22-.46,1.16-1.04-.19-1.92,1.44-2.71,2.24-4.02.76-1.24,2.08-.99,3.11-1.48.73.81.95,1.97,1.85,2.68.49.39.88.92.51,1.56-.43.76-1.08.57-1.71.24,0-.16,0-.31,0-.47Z"
        />
        <path
          fill="#b3b3b3"
          d="M226.8,60.98c-.23-.08-.46-.16-.7-.24-.78-1.12-1.71-.49-2.59-.19-2.13.73-3.99,2.08-6.17,2.7,1.48-1.22,3.24-2.07,4.61-3.46.86-.88,1.97-.39,2.99-.25,1.19-1.03,2.77-.56,4.11-1.04.23-.08.76-.1,1,.28.37.58-.15.88-.41,1.27-.95.31-1.89.62-2.84.93Z"
        />
        <path
          fill="#b1b1b1"
          d="M229.64,60.06c-.2-1-.96-.65-1.58-.68-1.04-.04-2.09-.04-3.12.17,1.2-1.44,3.15-1.42,4.61-2.37.57-.37,1.15-.03,1.47.61.32.63.67,1.25,1.01,1.88-.8.13-1.59.26-2.39.38Z"
        />
        <path
          fill="#b1b1b1"
          d="M216.41,63.23c-1.75,1.85-3.69,3.43-6.18,4.17.7-.69,1.45-1.36,1.68-2.37.32-1.39,1.27-1.83,2.55-1.71.66.06,1.3.05,1.95-.09Z"
        />
        <path
          fill="#b2b2b2"
          d="M208.07,69.49c-1.76,1.56-3.53,3.12-5.29,4.7-.49.44-.99.87-1.62,1.11,1.6-2.5,3.46-4.73,6.21-6.05.26.02.63-.29.7.24Z"
        />
        <path
          fill="#b0b0b0"
          d="M200.21,75.76c-1.19,1.17-2.38,2.33-3.58,3.5-.72.08-1.26.33-1.78.96-.71.86-1.45,1.78-2.51,2.3,1.68-3.35,4.62-5.23,7.86-6.75Z"
        />
        <path
          fill="#b5b5b5"
          d="M192.34,82.52c.77-.79,1.56-1.55,2.29-2.38.55-.63,1.14-1.02,2-.87-2.53,1.49-4.13,4.1-6.67,5.58.24-.39.48-.78.72-1.17.55-.39,1.11-.77,1.66-1.16Z"
        />
        <path
          fill="#b1b1b1"
          d="M208.07,69.49c-.23-.08-.47-.16-.7-.24.76-.73,1.49-1.49,2.61-1.62-.64.62-1.27,1.24-1.91,1.87Z"
        />
        <path
          fill="#a7a7a7"
          d="M39.22,219.39c-1.06,1.96-2.96,2.54-4.94,2.8-1.83.25-2.54,1.34-2.76,2.99-.09.66-.18,1.33-.78,1.77-.62.45-1.3.7-1.97.13-.66-.56-.34-1.19.01-1.79.78-1.32,1.17-2.74,1.08-4.28-.06-1-.5-1.15-1.27-.57-.88.53-.98-.09-1.1-.73-.1-.55-.14-1.15-.75-1.43-1.19-1.49-1.96-1.53-3.24-.16-.52.55-1.09.88-1.86.71-1.13-.62-2.04-.11-2.93.56-.56.54-1.12,1.08-1.67,1.62-.25.1-.33.02-.23-.23,1.45-.53.39-3.01,2.24-3.08,1.66-.06,2.21-1.53,3.37-2.19.52-.3.97-.74,1.41-1.15.7-.65,1.14-1.33,1.06-2.44-.1-1.56,1.31-2.14,2.44-2.8,1.45-.22,2.56-1.05,3.68-1.9,1.24.06,2,.56,2.11,1.65,1.53-1.43,2.91-3.41,5.02-4.5,1.12-.58,2.41-.79,3.6-1.35,2.06-.97,3.66-2.25,4.61-4.32.88-.87,1.75-1.74,2.66-2.58.57-.32,1.21-.47,1.85-.58.76.24.7.71.34,1.26-.49.77-.87,1.63-1.62,2.21-.94,2.3-2.33,4.22-4.57,5.43-.44.24-.83.6-1.03,1.06-1.26,2.94-3.91,4.2-6.56,5.45-.68.32-1.24.71-1.61,1.37-.64,1.16-1.46,1.38-2.59.62-.79-.53-1.42-.19-1.31.73.2,1.65,1.4,2.84,2.15,4.23.22.42.79.27,1.23.16.7-.18,1.31-.04,1.8.51.33.38.63.2,1.03.05.6-.22,1.26-.24,1.08.76Z"
        />
        <path
          fill="#bcbcbc"
          d="M30.89,207.99c-.82.73-1.8,1.16-2.84,1.46-.32.1-.65.17-.71-.33.32-.31.64-.61.96-.92,3.24-2.33,6.47-4.68,9.65-7.09.46.09.7.29.4.78-.07.12-.08.2-.12.17.26-.06.44-.29.65-.43,1.98-1.31,3.94-2.66,5.93-3.96.16-.11.3-.22.45-.34.18-.15-.07-.02.13-.11,3.09-1.45,4.86-4.53,7.72-6.29.41-.25.86-.32,1.25-.53.23-.02.42.02.61.15.57.82-.18,1.2-.56,1.69-.97,1-2.01,1.94-3.01,2.9-2.04,1.03-3.72,2.53-5.28,4.17-.26.28-.55.49-.89.66-1.49.76-2.97,1.55-4.12,2.79-.78.85-2.15.24-2.82,1.25-.09.13-.24.22-.37.33-1.23.83-2.19,1.99-3.6,2.62-1.12.5-2.24.84-3.43,1Z"
        />
        <path
          fill="#a7a7a7"
          d="M38.04,201.49c-2.45,1.76-4.89,3.53-7.36,5.27-.76.53-1.59.97-2.39,1.45.4-.4.8-.79,1.19-1.19,3.57-2.71,7.14-5.41,10.71-8.12.07.07.15.13.22.2-.02,1.5-.87,2.36-2.38,2.39Z"
        />
        <path
          fill="#aeaeae"
          d="M16.81,220.79c.08.08.15.15.23.23-1.03,1.45-2.24,2.73-3.52,3.98-1.47,1.45-2.99,2.27-5.05,1.59.03-.64.26-.97.94-.6,1.41.77,2.41.13,3.27-.96,1.22-1.56,2.46-3.1,4.13-4.23Z"
        />
        <path
          fill="#b3b3b3"
          d="M28.32,242.14c-.37.5-.49,1.04-.26,1.64.16.16.31.32.47.48l-1.41-.96c-.64,0-1.29.03-1.87-.32-.71-1.02-.61-1.85.4-2.26.42-.17.68-.24.58-.73-.31-.47-.55-.98-.42-1.56.18-.81.1-1.29-.95-1.11-.98.17-1-.46-.72-1.13.65-1.51.36-3.36,1.99-4.56.86-.64-.34-3.59-1.5-4.04-.41-.16-.86-.23-1.23-.44-.71-.4-1.06-.24-1.02.57.04.82.03,1.6-.98,1.81-.98.2-1.89.12-2.45-.87-.25-.45-.59-.79-1.05-1.02-.25-.97.38-1.07,1.08-1.1,2.08-.1,2.51-.57,2.2-2.6-.11-.73.06-1.31.48-1.86.66-1.56.95-3.35,2.64-4.26.28-.15.57-.27.88-.36,2.32.46,2.32,2.51,2.89,4.18.1.31.2.61.26.93-.38,1.13-1.7,1.91-1.23,3.39.15.47.11,1.03.61,1.28,1.35.68.92,1.92,1.12,3.03.5,2.87-.66,5.47-1.12,8.18-.15.9-.27,1.72.67,2.35.53.36.2.89-.07,1.33Z"
        />
        <path
          fill="#c6c6c6"
          d="M25.48,242.57c.65,0,1.17.3,1.63.73-.97.89-2,.72-3.06.2.26-.66-.26-1-.63-1.3-1.17-.95-2.4-1.83-3.65-2.69-.76-.53-1.05-.29-1.57.02-.39-.11-.79-.13-1.19-.22-.96-.69-.92-1.9-1.44-2.84-1.27-2.28-2.97-4.24-4.23-6.21.58.64.79,1.32-.68,1.51-.87-.88-2.02-1.59-1.98-3.05-.07-.39-.14-.79-.2-1.18,1.24-.31,2.61.83,3.81-.37.28-.28.54.23.71.49.42.65.84,1.3,1.23,1.97.28.47.64.86,1.2.53.84-.49.98.26,1.49.58,2.19,1.35,1.63,4.06,3.18,5.8,1.67,1.88,3.31,3.41,5.87,3.68.41.04.8.21.7.67-.1.46-.52.56-.99.65-.93.17-.91.22-.21,1.03Z"
        />
        <path
          fill="#adadad"
          d="M21.74,222.49c.41,1.27,1.4,2.36.28,3.93-.73,1.02-1.29,1.54-2.49,1.21-.54-.15-1.08-.2-1.62,0-.41.11-.89.47-1.19-.02-.25-.41.3-.64.5-.94.12-.19.33-.36.35-.55.21-2.31,1.81-3.35,3.72-4.11.38-.06.46.17.47.48Z"
        />
        <path
          fill="#aeaeae"
          d="M21.74,222.49l-.47-.48c-.05-.37-.13-.75-.16-1.12-.11-1.61-.92-2.08-2.4-1.5.64-.92,1.52-1.44,2.61-1.62.72.24,1.24.03,1.7-.6.42-.58,1.14-.45,1.63-.17.58.32.21.86-.03,1.29-1,1.38-1.69,2.97-2.88,4.21Z"
        />
        <path
          fill="#bababa"
          d="M25.48,242.57c-.78-.38-1.37-.8-.07-1.26.58-.21.81-.5.57-1.09.08-.08.16-.15.24-.23.96.54,1.77,1.09.02,1.67-.44.15-.79.37-.76.91Z"
        />
        <path
          fill="#dbdbdb"
          d="M11.04,231.57c.05-1.22-1.23-1.79-1.5-2.9,2.31.25,7.26,6.9,7.73,10.28.43.21.75.48.7,1.02-.32.45-1.18.6-.82,1.45-.05.05-.1.1-.14.15-.08.07-.16.14-.24.2-.18.03-.25-.05-.24-.22-.31-.21-.62-.59-.94-.6-1.65-.08-2.12-1.06-2.08-2.5.64-.7.13-1.2-.3-1.7-.7-.83-1.59-1.51-2-2.57,1.12,1.03,2,2.31,3.49,3.01-.86-1.98-1.94-3.82-3.47-5.37-.17,0-.23-.08-.2-.24Z"
        />
        <path
          fill="#b6b6b6"
          d="M11.04,231.57c.07.08.13.16.2.24-.57,1.16-1.08.31-1.45-.11-.95-1.04-1.83-2.16-2.74-3.25.54.12,1.01.69,1.64.26.61,1.1,1.45,2,2.35,2.86Z"
        />
        <path
          fill="#d4d4d4"
          d="M18.24,238.95c.33-1.36.73-2.26,1.81-.4.19.32.72.47,1.12.63,1.58.64,2.44,1.95,3.28,3.32.32.53.15.82-.39.99-.08-.07-.15-.15-.23-.22-.69.16-.95,1.26-1.9.88-.42-.31-.88-.48-1.41-.47-.08.24-.16.47-.24.71-.69.39-.92.02-1.01-.6-.08-.58-.11-1.16-.12-1.74.15-.7.27-1.35-.56-1.81-.45-.25-.96-.68-.35-1.31Z"
        />
        <path fill="#cbcbcb" d="M21.93,244.16c.65-.26.84-1.5,1.9-.88-.1,1.45-.81,1.58-1.9.88Z" />
        <path
          fill="#bebebe"
          d="M16.54,241.53c.08.07.16.15.24.22.36.69.49,1.39-.13,1.98-.57.54-1.13.02-1.69-.15-.26-.08-.53-.14-.8-.21-.23-.14-.51-.25-.69-.44-.71-.74-2-1.71-1.52-2.47.57-.9,1.54.45,2.38.69.73.21,1.41.67,2.22.37Z"
        />
        <path
          fill="#d0d0d0"
          d="M7.47,231.29c.9.89,1.7,1.85,2.09,3.09-.24-.24-.49-.48-.73-.72-.6-.71-1.36-1.32-1.36-2.37Z"
        />
        <path
          fill="#dbdbdb"
          d="M19.6,241.79c.23.87.45,1.74.68,2.61.08.08.17.15.23.24.37.55,1.43,1.11.74,1.73-.75.68-1.49-.23-2.07-.81-.64-.63-.59-1.53-.87-2.31-.25-.7-.81-1.19-1.3-1.71,0,0,0-.03,0-.03.4-.91,1.31-.61,2.01-.87.38.27.81.54.57,1.13Z"
        />
        <path
          fill="#cecece"
          d="M70.59,199.46c-1.75,1.07-2.78,2.88-4.29,4.21-.96-.13-.71-.58-.3-1.09.46-.58.99-1.1,1.47-1.66.38-.45.35-.67.04-1.04-.1-.13-.17-.28-.23-.43.15-1.06.28-2.07,1.53-2.56.66-.25.91-1.07,1.26-1.7,1.08-1.5,2.78-2.21,4.17-3.32,2.22-1.78,4.04-3.97,6.13-5.88.41-.38.71-1.07,1.49-.77,1.16-.31,1.7-1.7,3.17-1.86-4.26,6.04-10.1,10.4-14.44,16.1Z"
        />
        <path
          fill="#b9b9b9"
          d="M81.86,185.22c-3.57,3.69-6.99,7.53-11.44,10.24-.1,0-.19.01-.29.02-.18-.1-.28-.25-.3-.46.65-1.5,1.04-3.18,2.81-3.85.57-.22.49-.8.7-1.22.15-.15.32-.26.51-.34,2.13-.4,3.88-1.34,4.93-3.37.26-.51.92-.77,1.36-1.17,1.61-1.45,3.21-2.92,4.1-4.98.36-.83,1.06-1.55,1.83-1.87,2.56-1.05,4.25-3.15,6.2-4.92.59-.53,1.07-.7,1.76-.43,1.19-1.04,2.4-2.05,3.81-2.79-1.99,1.78-3.98,3.57-5.96,5.35.03.14,0,.22-.08.24-.08.02-.13.02-.16,0-.56.54-1.12,1.09-1.67,1.63-2.71,2.64-5.41,5.29-8.12,7.93Z"
        />
        <path
          fill="#bebebe"
          d="M67.78,199.44c.07.08.15.16.22.24.17.67-.32,1-.69,1.42-1.95,2.23-3.49,4.91-5.95,6.57-1.84,1.23-3.2,2.79-4.61,4.38-.32.31-.63.62-.95.93-3.17,3.42-6.64,6.61-8.65,10.95-.37.8-.79,1.58-1.18,2.37-.08.08-.17.16-.25.24-.41-2.15-.86-4.29.32-6.36,1.26-1.65,1.28-4.3,4-4.78,1.85-.33,2.08-2.19,2.78-3.47.63-1.14,1.32-1.79,2.51-2.35,1.92-.9,3.91-1.91,4.88-4.05,1.47-1.23,2.71-2.67,4.05-4.02.92-.93,2.09-1.81,3.52-2.05Z"
        />
        <path
          fill="#d5d5d5"
          d="M56.75,212.05c.67-1.29,1.65-2.36,2.63-3.4,2.83-3.03,5.74-5.98,8.62-8.96.29-.29.48-.96.97-.65.49.32.03.79-.21,1.13-.81,1.18-1.82,2.21-2.46,3.51-.17.31-.33.62-.5.93-.95.93-1.89,1.87-2.84,2.8-.8.78-1.6,1.55-2.4,2.33-.26-.96-.78-.55-1.2-.17-.89.8-1.74,1.66-2.61,2.49Z"
        />
        <path
          fill="#b1b1b1"
          d="M60.6,206.43c-1.08,1.66-2.96,2.48-4.16,4.01-.09.12-.28.24-.42.23-2.45,0-2.58,2.17-3.13,3.66-.41,1.11-.82,1.75-1.96,1.83-1.59.1-2.6.93-2.95,2.45-.25,1.1-1.13,1.25-1.94,1.56-.53-.31-.58-.75-.35-1.28.14-.32.17-.69-.21-.86-.41-.18-.6.15-.74.46-.21.44-.57.65-1.04.71.46-.89-1.1-2.14.51-2.82,1.06-1.38,2.25-2.66,3.09-4.21.23-.43.68-.76.75-1.27.12-.84.89-.77,1.33-1.12,2.37-1.93,5.83-.69,8.06-3,.98-1.02,2.03-1.99,2.37-3.34.29-1.18.44-2.41.75-3.6.13-.22.31-.39.51-.55.29-.21.65-.23.95-.13.94.31,1.82.62,2.81.07.31-.17.8.05.92.55-.24,1.03-1.05,1.81-1.68,2.47-1.27,1.32-1.85,3.18-3.45,4.19Z"
        />
        <path
          fill="#a5a5a5"
          d="M39.22,219.39c-.86-.12-1.89,1.4-2.59-.27,0-2.69,1.8-5.11,4.71-6.29.82-.34,1.67-.61,2.5-.93,1.28-.48,2.28-1.16,2.39-2.67.06-.81.62-1.04,1.31-1.05,1.38-.02,2.5-.87,3.8-1.17.27-.06.57-.24.67-.48,1.12-2.74,4.47-3.2,5.86-5.72.32-.58,1.2-1.44,2.26-.76.53,2.61-.92,5.81-3.44,7.4-.8.51-1.78.72-2.66,1.06-2.65,1-5.36,2.04-7.14,4.48-.47.65-.93,1.28-1.12,2.08-.23.92-.75,1.43-1.77,1.08-.55.07-1.1.15-1.65.22-.69.08-1.04.76-1.73.95-.87.23-.22.9-.19,1.39-.29.41-.72.59-1.19.69Z"
        />
        <path
          fill="#a6a6a6"
          d="M41.67,230.49c-.8,2.09-1.35,2.27-2.96.93-1.01-.84-1.34.13-1.74.71-.47.67-.44,1.62-1.1,2.21-.07.07-.2.07-.31.11-.45-.22-.18-.57-.14-.87.13-.88-.26-1.03-.94-.63-.57.34-1.11.75-1.81.74,1.19-1.02,2.96-1.37,3.13-3.54.04-.53,1.59-1.67,2.69-2.26.7-.37,1.14-1.24,2.15-.91.53.18,1.1.3,1.22.96.11.59-.3.9-.71,1.2-.42.31-1.19.52-.93,1.18.28.72.95.21,1.46.16Z"
        />
        <path
          fill="#a7a7a7"
          d="M43.98,216.14c.49-.13,1.07-.25,1.1-.85.16-2.76,2.56-3.55,4.32-4.82.82-.59,1.86-.95,2.71-1.59.81-.61,1.67-1,2.7-1.18,2.27-.39,4.02-2.45,4.08-4.75.03-1,.49-1.8.85-2.67-.07-.19-.04-.36.09-.52.45-.2.9-.41,1.1.3-.17,5.22-2.58,7.84-7.66,9.12-2.71.68-5.29,1.98-6.39,4.99-.44,1.19-1.27,2.08-2.66,2.2-.08-.08-.15-.16-.23-.24Z"
        />
        <path
          fill="#aeaeae"
          d="M28.34,222.57c-.77-.75-1.41-1.56-1.58-2.67-.14-.95-.76-1.43-1.67-1.61.31-1.24.54-1.29,1.89-.45,1.31.39,1.16,1.68,1.61,2.61.81.82-.02,1.44-.26,2.13Z"
        />
        <path
          fill="#a7a7a7"
          d="M40.41,218.7c-.33-.19-.67-.37-1-.57-.29-.18-.52-.43-.42-.8.09-.37.41-.5.74-.56.86-.15,1.73-.27,2.6-.41-.64.78-1.28,1.56-1.92,2.34Z"
        />
        <path
          fill="#a6a6a6"
          d="M40.08,226c.52-.49,1.01-1.03,1.57-1.46.69-.52.86-.12.8.55-.67.62-1.31,1.3-2.37.92Z"
        />
        <path
          fill="#bababa"
          d="M219.96,43.93c-.08-.08-.16-.16-.23-.24-.99-.42-1.61-1.29-2.41-1.93-1.09-.87-.94-1.63.16-2.32.85-.54,1.37-1.28,1.47-2.28.11-1.11-.51-1.55-1.53-1.1-.76.34-1.31.95-1.42,1.73-.25,1.86-1.2,2.95-3.06,3.23,0,0,0,0,0,0-.08-.08-.16-.16-.23-.24-.27-1.22-.93-2.31-1.13-3.55.51-.61,1.14-.96,1.94-1.17,1.64-.42,2.2-1.36,1.99-3.07-.07-.57-.16-1.14.39-1.5.56-.38,1.04,0,1.54.23,2.67,1.27,4.42.21,4.37-2.63.62-.71,1.1-.38,1.44.25.2.39.5.72.68,1.06.76,1.46,1.57,2.68,3.49,2.53.35-.03.6.45.85.75.39.48.88.66,1.48.45,1.34-.45,2.6-.17,3.69.34-.89-.26-1.67-.92-2.13-1.87-.29-.6-.67-1.06-1.18-1.42-.52-.37-.78-.77-.88-1.46-.17-1.19-1.59-1.59-1.99-2.7-1.32-1.95-3.25-3.24-5.02-4.7-.63-.53-1.31-1.01-1.96-1.51,2.65,1.12,4.85,2.94,6.84,4.9,1.28,1.27,2.84,2.15,4.06,3.53,1.42,1.59,2.54,3.41,4,4.94.1.54.54.84.92,1.12.43.31.7.52.6,1.16-.12.78-.68.73-1.15.86-1.16.32-2.37.35-3.56.3-1.01-.04-1.75.19-2.39,1.06-.44.61-1.09,1.07-1.99,1.1-1.12.04-2.02-.12-2.42-1.32-.16-.46-.27-1.08-.84-1.12-.61-.05-.5.62-.67.99-.23.49-.66.72-1.1.98-.51.3-1.13.51-1.25,1.35.83.2,1.28-.57,1.94-.71.77-.17,1.74-.46,2.01.45.24.8.31,1.85-1.02,2.25-.98.29-1.88.9-2.76,1.46-.6.37-1.1.44-1.54-.19Z"
        />
        <path
          fill="silver"
          d="M227.25,27.05c1.16.9,2.37,1.6,2.75,3.33.27,1.25,1.89,1.78,2.81,2.72.31.31.73.5,1.07.79.62.52,1.67,1.12,1.17,1.93-.47.76-1.22-.24-1.84-.47-1.27-.48-2.51-1.1-3.51.49-.32.5-.86.42-1.11,0-.95-1.64-2.37-2.28-4.21-2.32-.6-.01-.94-.58-.87-1.22.18-1.48-.66-2.39-1.72-3.18-1.91-2.36-3.93-4.59-7.29-4.57-5.1.03-8.25-3.57-11.9-6.26-.27-.2-.53-.45-.45-.82.09-.41.47-.41.8-.45.44-.05.88-.08,1.33-.12,1.21-.28,2.34.33,3.53.28.08.08.16.15.23.23.23.24.46.48.7.72-1.06.18-2.09.05-3.1-.31-.31-.11-.69-.16-.88.2-.19.35.02.66.27.88.24.22.51.46.81.57,1.27.45,2.4,1.05,3.33,2.07.34.38.98.7,1.47.69,2.32-.06,4.37,1.08,6.62,1.37,1.73.23,2.68,1.84,3.84,2.98,1.79,1.75,3.39,3.73,6.04,4.24,1.14.22,2.02.98,3.32,1.69-.91-2.2-2.87-3.37-3.2-5.46Z"
        />
        <path
          fill="#b0b0b0"
          d="M202.48,47.04c.99-1.67,2.16-3.27,1.37-5.41-.1-.27.19-.74.4-1.05.63-.94.73-1.87.4-2.99-.6-2.03-.01-2.99,1.92-3.54.78-.22,1.07-.78,1.01-1.51-.06-.76-.63-.98-1.29-1.02q-1.97-.12-1.07-1.94c.34-.7.6-1.43,1.01-2.09.76-1.24.7-2.35-.1-3.56-.72-1.07-1.89-1.96-1.65-3.5.95,1.99,2.95,3.3,3.56,5.52.09.33.44.74.75.83,1.73.49,1.66,1.76,1.59,3.17-.07,1.44-.64,2.81-.49,4.28.07.76-.45,1.11-1.17,1.06-1.47-.1-2,.71-2,2.02,0,1.19-.96,1.82-1.48,2.71-.38.66-.99,1.49.56,1.49.78,0,.59.52.32.9-.38.54-.85,1.02-1.24,1.56-.23.32-.39.72-.15,1.09.31.48.67.19,1-.02.19-.12.41-.25.6-.05.22.23.14.52.01.77-.39.78-1.05,1.12-1.9,1.07-.67-.04-1.33-.09-1.96.21Z"
        />
        <path
          fill="#bdbdbd"
          d="M211.56,37.24c2.46.91,2.63,1.41,1.13,3.55-.89.55-1.73.44-2.38-.34-.68-.81-.15-1.58.32-2.27.24-.36.62-.63.93-.94Z"
        />
        <path
          fill="#9c9c9c"
          d="M239.38,37.08c-.24-.08-.47-.15-.71-.23-.23.04-.46.13-.71.1-.55-.52-1.28-.91-1.39-1.78-.12-.3-.44-.74-.33-.88,1.23-1.74.27-3.38-.17-5.04-.34-1.28.77-3.73,2.05-4.23.72-.28.98-.87,1.25-1.46.57-1.23,1.53-1.58,2.73-1.12,3.69,1.39,4.33,2.82,2.69,6.11-1.25,2.51-3.04,4.77-3.52,7.62-.08,1.45-1.01,1.14-1.9.92Z"
        />
        <path
          fill="#b4b4b4"
          d="M236.57,35.17c.67.24,1.35.45,1.65,1.2-.58.6-.04,1.08.23,1.5.31.48.75.86.03,1.33-2.02-.11-3.9-1.07-5.95-1.02-1.33.03-2.47.46-3.43,1.43-.67.67-1.49,1.15-2.42,1.4-.58.16-1.03.42-1.04,1.1-1.33.99-2.53,2.07-3.5,3.48-.48.69-.89,1.14-.56,2,.41,1.05.13,1.75-1.12,1.76-.88.01-1.59.37-2.23.93-.24.08-.47.15-.71.23-1.03-.8-.41-1.46.25-1.99,1.49-1.18,2.13-2.74,2.19-4.58,1.08-.72,2.36-.92,3.55-1.37.6-.22,1.61-.59,1.07-1.49-.43-.72-1.2-.17-1.75.18-.42.27-.78.63-1.2.89-.63.39-1.29.44-1.8-.16-.51-.6-.34-1.26.11-1.83.24-.3.54-.58.86-.77,1.22-.72,2.41-1.41,2.1-3.17-.08-.43.25-.85.75-.98.5-.13.85.16,1.14.52.52.65.88,1.39,1.23,2.16.56,1.24,2.12,1.71,2.99.7,1.17-1.36,2.51-1.68,4.12-1.54.16.01.33-.04.49-.06,2.62-.37,2.67-.47,1.56-2.8.47.32.94.64,1.4.96Z"
        />
        <path
          fill="#a8a8a8"
          d="M239.38,37.08c.63-.31,1.26-.61,1.9-.92.43-.74,1.16-1.08,1.9-1.39-.01.05-.03.11-.03.16,0,1.02-1.74,1.42-1.06,2.57.46.78,1.3,1.34,1.98,1.99-.1,1.21-.98,1.24-1.6.71-1.11-.94-2.45-1.7-3.08-3.13Z"
        />
        <path
          fill="#bebebe"
          d="M211.1,18.62c-.02-.62-1.14-1.07-.49-1.65.51-.45,1.19.23,1.77.48.3.13.57.32.85.48-.45.89.13,1.16.82,1.43,1.34.53,2.67,1.08,3.98,1.68.53.24,1.13.49,1.22,1.39-2.64-1.46-6.07-1.08-8.15-3.81Z"
        />
        <path
          fill="#b5b5b5"
          d="M190.16,69.29c-1.96,1.26-2.78,3.36-3.43,5.36-.83,2.53-2.65,4.4-3.75,6.72-.29.6-.59,1.18-.73,1.84-.24,1.15-.63,2.19-1.49,3.08-.83.86-1.12,2.02-1,3.23.04.4.23.75.65.89.46.15.77-.13.95-.48.74-1.39,1.98-1.82,3.45-1.91,1.59-.1,2.58-1.44,3.96-2-1.37,1.47-2.88,2.8-4.53,3.96-.8-.21-1.36.08-1.85.74-1.09,1.47-2.25,2.71-4.13,3.41-1.64.62-2.78,2.34-4.6,2.9-.37.12-.43.62-.53.99-.12.49-.23,1.1-.7,1.28-2.82,1.05-3.01,3.83-4.06,6.04-.2.42-.38.87-.83.98-1.11.25-1.86.8-2.27,1.91-.19.52-.81.82-1.41.51-.58-.3-.44-.85-.25-1.32.57-1.44.96-2.99,2.35-3.94.05-.03.09-.07.12-.11.67-.8,1.73-1.63,1.26-2.7-.42-.96-1.62-.16-2.65-.36.72-.99,1.04-2.05.71-3.23-.13-.49.13-.79.58-1.06,1.59-.99,2.38-3.21,1.64-5.01-.68-1.64-.58-2.89,1.07-3.81.4-.22.74-.61.72-1.07-.1-3,1.82-5.01,3.42-7.19.36-.49.64-1.04.96-1.56-.54-.85-.23-1.57.28-2.32,1.1-1.63,2.26-3.21,3.7-4.54.81-.75,1.13-1.74,1.61-2.65,1.6-3.06,3.82-5.7,5.66-8.59,1-1.57,1.51-3.41,2.74-4.83.3-.35.4-.99,1.11-.76.29.18.29.48.29.78h0c-.38.53-.46,1.12-.31,1.74.19.8.03,1.53-.42,2.22-.34.52-.66,1.09-.2,1.69.48.63,1.16.53,1.83.36.27-.07.53-.17.8-.21.84-.14,1.27.26,1.33,1.07.06.83-.2,1.47-1.16,1.44-1-.03-2.02-.09-2.8-.84-.54-.51-1.13-.75-1.8-.34-.91.55-1.81,1.16-2.11,2.25-.17.63.38.93.89.95,1.38.07,2.32.55,2.53,2.06.08.58.61.91,1.19,1.04.77.17,1.08.71,1.21,1.41Z"
        />
        <path
          fill="#c6c6c6"
          d="M188.72,53.96c-3.79,8.56-9.91,15.63-14.93,23.4-2.03,1.96-3.44,4.42-5.26,6.54-.1-.01-.2-.02-.3-.03-.55-.45-.24-.87.04-1.29,1.54-2.35,3.45-4.41,5.13-6.65.96-1.29,1.67-2.77,2.6-3.62-2.09,3.16-4.74,6.37-7.3,9.64-.44.57-.88,1.34-1.83.76,1.95-2.62,3.88-5.26,5.86-7.85,5.39-7.04,10.41-14.34,15.12-21.85.41-.65.91-1.25,1.37-1.87.31-.68.74-.8,1.3-.27.12,1.45-.65,2.39-1.8,3.1Z"
        />
        <path
          fill="#c3c3c3"
          d="M159.88,113.74c2.46.81,3.17.56,4.55-1.6.24-.37.41-.85.74-1.09,3.03-2.21,4.63-5.49,6.72-8.46,2.46-3.49,5.52-6.57,9.13-8.99.47-.32.95-.57,1.52-.59-2.47,2.14-4.62,4.59-6.8,7.03-4.13,4.62-7.71,9.71-12.16,14.07-1.93,1.89-3.59,4.07-5.34,6.14-.51.6-1.01,1.18-1.77,1.47.82-1.35.97-2.9,1.28-4.39.31-1.46.9-2.69,2.12-3.58Z"
        />
        <path
          fill="#c5c5c5"
          d="M168.53,83.9c-.34,1.72-1.83,2.56-2.87,3.74,0,0-.01,0-.01,0-.34-.1-.57-.27-.55-.67.05-1.3,1.99-3.04,3.43-3.07h0Z"
        />
        <path
          fill="#d8d8d8"
          d="M185.01,93.41c-1.29,1.88-3.69,3.09-4.94,5.41-.42.78-1.4,2.11-2.22,1.41-1.01-.85.26-1.86.94-2.57,1.67-1.77,3.54-3.34,5-5.31.1-.13.22-.26.35-.35.61-.42,1.36-1.38,1.95-.72.69.76-.21,1.45-1.08,2.13Z"
        />
        <path
          fill="#d3d3d3"
          d="M168.52,83.9c-1.09,1.15-2.44,2.09-2.88,3.74-.64.76-1.35,1.47-1.92,2.28-3.35,4.8-7.01,9.37-10.78,13.84-.58.65-1.14,1.33-1.76,1.94-.27.26-.69.43-1.05.15-.38-.3-.21-.7-.03-1.04.25-.49.48-.98.49-1.55,3.66-4.65,7.31-9.31,10.98-13.95,1.75-2.21,3.53-4.4,5.3-6.6,3.65-3.53,6.42-7.76,9.51-11.74.36-.47.66-1.03,1.29-1.2.5.43.11.75-.07,1.04-2.15,3.43-4.53,6.69-7.09,9.83-.8.97-1.33,2.16-1.98,3.26Z"
        />
        <path
          fill="#b4b4b4"
          d="M145.86,107.97c-2.07,2.8-4.31,5.77-6.91,7.92-.2.37-.38.76-.54,1.15-2.4,2.96-4.79,5.92-7.19,8.87-.02.17.06.25.23.23.16-.16.32-.31.48-.47,3.17-3.76,6.39-7.47,9.5-11.28,1.99-2.44,4.3-4.62,5.83-7.41-.33.31-.66.63-.99.94-.13.02-.27.03-.41.04Z"
        />
        <path
          fill="#b4b4b4"
          d="M131.22,125.92c.08.08.15.15.23.23.07.08.14.15.21.23-.53,1.11-.33,2.15.29,3.15.49.8.51,1.55-.13,2.28-.13-.56-.33-1.06-1.05-1.02-1.46.07-1.24-.66-.69-1.51.44-.69.53-1.42.39-2.2-.07-.08-.14-.16-.21-.24.32-.3.64-.61.96-.91Z"
        />
        <path
          fill="#c4c4c4"
          d="M91.65,175.67c.08-.08.16-.16.24-.23.55-.06,1.1-.66,1.63-.06.2.22,0,.53-.24.69-.68.45-1.22.28-1.64-.39Z"
        />
        <path
          fill="#adadad"
          d="M94.04,172.87c-1.37.87-2.54,1.97-3.67,3.12-1.09,1.11-2.21,2.18-3.76,2.66-.7.22-1.24.75-1.61,1.39-2.19,3.8-5.52,6.59-8.5,9.69-.64.67-1.61.69-2.49.82-.53-1,.48-1.62.61-2.46.42-.87,1.37-1.28,1.83-2.12.07-.15.14-.29.2-.44.16-.43.33-.81.86-.9,1.25-.21,2.14-1.07,3.18-1.69,1.33-.8,1.93-2.21,3.09-3.1,1.31-1.51,2.04-3.64,3.66-4.69,2.66-1.72,3.38-4.88,5.7-6.76.48-.39.64-.98.89-1.51.68-1.42,1.21-1.69,2.61-1.11.55.22,1.06.37,1.57,0,2.37-1.74,4.4-3.8,5.97-6.32-1.53.43-2.72,1.25-3.9,2.38-1.38,1.32-3.28,2.05-4.63,3.57-.33.37-1.16.61-1.55,1.16-.28.4-.56.63-1.05.51-.15-.05-.28-.12-.4-.23-.03-.05-.06-.09-.08-.14-.45-1.13,1.27-1.58.92-2.4-.31-.73-1.67-.65-2.03-1.64-.08-.21-.11-.43-.16-.64-.03-.25-.08-.51-.05-.76.08-.38.32-.63.65-.81,1.85-.04,3.19-.7,4.18-2.46.69-1.22,2.22-1.84,3.32-2.81,1.3-1.15,2.57-2.37,3.94-3.39,1.48-1.1,3.51-1.18,5.37-1.42.4-.05.41.44.48.76-.32.31-.63.62-.95.93-4.86,1.44-10.38,5.26-11.25,8,1.85-.68,2.98-2.35,4.81-2.64,1.98-1.23,4.46-1.18,6.4-2.52.31,0,.63,0,.94,0,.25-.08.33,0,.23.26-.29,2.06-1.22,3.67-2.99,4.9-1.14.79-1.54,2.27-2.36,3.38-2.01,2.74-4.59,4.84-7.38,6.72-1.06.72-2.05,1.52-2.6,2.73Z"
        />
        <path
          fill="#a7a7a7"
          d="M109.36,155.12c-.08-.09-.16-.17-.23-.26,1.89-1.77,4.11-1.59,6.38-1.09-.08.08-.16.17-.24.25-.39.07-.79.14-1.18.21-1.41-.23-2.81-.4-4.02.66-.24.08-.47.15-.71.23Z"
        />
        <path
          fill="#adadad"
          d="M110.08,154.9c1.18-1.18,2.54-1.27,4.02-.66-1.35.14-2.74.05-4.02.66Z"
        />
        <path
          fill="#b9b9b9"
          d="M92.08,160.83c-.08.23-.16.47-.25.7-.75,1.31-.52,3.06-1.99,4.09-.7.49-1.22.63-1.89.11-.15-.13-.26-.29-.33-.48-.15-.36-.34-.82-.04-1.1,1.53-1.47,2.2-3.66,4.19-4.78.96-.54,2.12-1.04,2.43-2.34.03-.11.1-.24.2-.25,3.23-.43,2.93-3.73,4.51-5.51.07-.08.18-.13.25-.22,1.57-.75,2.67-2.1,3.9-3.25,2.67-2.5,5.04-5.3,8.02-7.51,1.62-1.2,2.5-3.21,3.83-4.77,2.55-2.99,5.24-5.86,7.69-8.93.24-.3.54-.56.6-1.02-.66.64-1.36,1.26-1.98,1.94-3.67,4.01-7.46,7.9-11.1,11.93-1.57,1.74-3.16,3.55-4.38,5.62-.24.36-.61.4-.99.52-1.3.39-2.34,1.1-2.81,2.48-.44,1.29-1.38,2.18-2.51,2.93-1.18.78-2.53,1.42-3.1,2.87-.46,1.48-1.87,2.18-2.73,3.33-2.21,2.94-5.91,4.18-8.16,7.09-.2.26-.47.48-.7.72-1.18,1.3-2.32,2.63-4.11,3.13-.56.16-.8.74-1.17,1.13-.71.74-1.25,1.49-2.45,1.78-.95.23-1.42,1.63-2.42,2.27-.46.3-.99.68-1.44,1.08-1.32.84-2.48,1.92-3.96,2.53-.45.19-.83.66-1.22-.04.59-.9,1.32-1.64,2.25-2.18,4.38-3.58,8.77-7.14,13.14-10.74,5.61-4.62,11.06-9.39,15.77-14.97,2.54-3.01,5.6-5.52,8.22-8.49,4.47-5.07,9.14-9.98,13.73-14.95,1.09-1.56,2.59-2.72,3.94-4.02.75-.72,1.7-.69,2.59-.2.82.45,1.02,1.61.29,2.3-1.96,1.85-3.44,4.11-5.21,6.11-.12.16-.23.33-.33.5-1.11,1.4-2.04,2.96-3.38,4.19-.42.38-.64.88-.83,1.39.35,2.26-1.1,4.25-.97,6.51.04.73-.84,1.14-1.56,1.4-1.87,1.02-4.14.8-5.96,1.95-.5.18-.97.54-1.52.43-1.11-.22-1.57.39-1.8,1.33-.54.36-1,.82-1.4,1.32-2.68,2.17-5.08,4.53-6.59,7.78-.77,1.67-2.53,2.76-3.92,4.02-.65.59-1.57.04-2.34.26Z"
        />
        <path
          fill="#6f6f6f"
          d="M76.96,168.3c-.45.43-.91.79-1.39,1.09-.94.75-1.89,1.49-2.85,2.22-.91.81-1.83,1.61-2.76,2.38,3.02-2.29,5.97-4.67,8.83-7.17.13-.13.25-.25.38-.37-.63.54-1.28,1.06-1.93,1.59-.1.09-.19.18-.29.27Z"
        />
        <path
          fill="#cbcbcb"
          d="M38.04,201.49c.79-.8,1.59-1.59,2.38-2.39.49-.44.97-.88,1.46-1.31,2.26-.87,3.73-2.8,5.58-4.22,2.57-1.97,4.94-4.19,7.57-6.09,2.34-1.69,4.39-3.75,6.63-5.57.63-.51,1.3-.99,1.89-1.57.39-.38.88-1.07,1.67-.63.15.26.12.56.16.84-1.32,2.23-3.6,3.46-5.38,5.2-1.34,1.31-3.21,1.94-4.36,3.43-.96,1.24-2.42,1.79-3.6,2.71-1.93,1.52-3.75,3.2-5.8,4.57-.83.56-1.08,1.83-2.4,1.74.49.03.84-.94,1.33.06-2.47,2.24-5.46,3.64-8.62,5.39.23-1.2,1.03-1.56,1.51-2.14Z"
        />
        <path
          fill="#9d9d9d"
          d="M109.5,144.75c1.81-1.19,3.9-.97,5.91-1.13.19,0,.26.09.23.27-.43.4-1.03.74-.8,1.46.23.71.88.72,1.48.75,1.16.06,1.81.63,1.86,1.82-.29-.49-.61-.95-1.17-1.19-.28.59-.83.76-1.39.67-3.13-.49-6,.34-8.78,1.66-.35.17-.71.33-1.1.07-1.17-.47-1.2-1.15-.36-1.99,1.3-.91,2.36-2.25,4.11-2.39Z"
        />
        <path
          fill="#9e9e9e"
          d="M123.35,131.24c-.1.67-.2,1.41-.69,1.85-1.77,1.58-2,3.9-3,5.84-.92,1.79-1.42,3.99-3.56,4.95,1.01-1.47.84-3.07.88-4.76.05-1.96.15-4.26,2.64-5.18.37-.14.52-.72.83-1.06.84-.78,1.56-1.75,2.89-1.64Z"
        />
        <path
          fill="#aaa"
          d="M121.94,129.81c1.54-1.96,3.08-3.91,4.61-5.88.42-.54,1.19-1.11.42-1.83-.68-.64-1.58-.59-2.28.03-1.24,1.1-2.42,2.28-3.63,3.43,1.69-2.41,3.78-4.41,6.21-6.06.21-.05.42-.11.62-.2,1.28-.57,1.16-.36,1.62.98,1.06,3.12-1.21,4.68-2.84,6.43-.84.9-1.63,2-2.83,2.56-.54.5-1.11.9-1.9.55Z"
        />
        <path
          fill="#b0b0b0"
          d="M64.96,179.84c-3.14,1.83-5.58,4.53-8.42,6.73-4.29,3.33-8.55,6.69-12.56,10.36-.58.53-1.28.89-2.11.84,7.46-6.11,14.92-12.21,22.37-18.32,1.97-1.61,3.72-3.49,5.97-4.75-.72.71-1.43,1.41-2.15,2.12-.44,1.61-1.53,2.56-3.1,3.01Z"
        />
        <path
          fill="#9f9f9f"
          d="M123.39,128.41c.7-.72,1.52-1.24,2.46-1.65,1.98-.86,3.42-3.75,3.18-5.75-.14-1.18-.76-1.49-1.75-1.51.59-.46,1.06-1.31,1.95-.94.87.36.63,1.3.78,2.04.62,3.18-1.71,4.79-3.53,6.67-1.53.81-1.91,2.5-2.89,3.74-.08.08-.16.15-.23.23-.88.7-1.75,1.4-2.63,2.09-.83-.35-.33-.92-.03-1.14,1.33-.97,1.75-2.56,2.69-3.78Z"
        />
        <path
          fill="#a9a9a9"
          d="M123.39,128.41c-.4,1.9-1.6,3.38-2.66,4.92-.24.45-.52.85-.98,1.1-2.19,1.17-2.68,3.18-2.4,5.36.21,1.64-.11,2.94-1.24,4.09-.16,0-.31,0-.47,0-.08-.09-.15-.18-.23-.27.91-.76,1.49-1.71,1.3-2.91-.28-1.77.16-3.41.69-5.06,1.36-1.93,2.88-3.74,4.32-5.61.07-.08.15-.15.22-.23.48-.47.97-.94,1.45-1.4Z"
        />
        <path
          fill="#9e9e9e"
          d="M123.59,131.01c.25-1.8.71-3.43,2.89-3.74-.93,1.27-1.29,2.98-2.89,3.74Z"
        />
        <path
          fill="#7a7a7a"
          d="M89.05,158.2c2.23-2.17,4.45-4.35,6.68-6.52-1.87,2.54-4.07,4.74-6.68,6.52Z"
        />
        <path
          fill="#b6b6b6"
          d="M106.15,148.48c3.38-2.11,7.04-2.43,10.87-1.75.14.47.34.93.42,1.41.28,1.59-.15,2.02-1.74,2.24-2.17.3-4.4.04-6.52.73-2.91.32-5.31,1.44-7.61,3.43-2.24,1.94-4.54,3.83-6.72,5.86-.83.78-1.77.8-2.77.43,2.19-.25,3.73-1.57,5.09-3.15.29-.33.7-.71.73-1.09.19-2.99,2.37-4.64,4.24-6.51.71-.71,1.43-1.4,2.14-2.09.71.74.73.77.03,1.3-.45.34-.85.72-1.28,1.07.94-.79,1.83-1.67,3.13-1.88Z"
        />
        <path
          fill="#a7a7a7"
          d="M108.19,154.87c-1.77,1.77-4.23,1.77-6.4,2.52,1.86-1.55,4.24-1.75,6.4-2.52Z"
        />
        <path
          fill="#b0b0b0"
          d="M188.72,53.96c.48-.94.96-1.88,1.44-2.82-.05-.74.37-1.3.69-1.9.51-.97,1.11-1.89,1.44-2.96.15-.49.56-.94,1.24-.76-.04,2.88-.31,5.65-2.97,7.51-.53.37-.93.93-1.39,1.4-.15-.16-.31-.32-.46-.47Z"
        />
        <path
          fill="#8b8b8b"
          d="M177.82,66.06c-1.7,3.16-3.94,5.89-6.35,8.5,0,.3,0,.6,0,.9,4.67-5.83,8.83-12.02,12.96-18.24-.48.34-.95.69-1.34,1.12-.98,1.8-2.17,3.46-3.47,5.04-.57.92-1.16,1.81-1.78,2.69Z"
        />
        <path
          fill="#bcbcbc"
          d="M193.54,45.52c-1.4,1.71-1.79,4.02-3.37,5.62-.31,0-.63,0-.94-.01,1.62-3.03,3.24-6.06,4.85-9.1.64,1.42-.06,2.43-.54,3.49Z"
        />
        <path
          fill="#8b8b8b"
          d="M168.11,79.66c.83-1.5,1.93-2.78,3.13-3.99-.77,1.54-1.88,2.82-3.13,3.99Z"
        />
        <path
          fill="#b4b4b4"
          d="M212.93,41.03c1.15-.78,1.82-1.82,2.11-3.22.38-1.88,2-3.18,3.84-3.39.84-.09,1.2.42,1.13,1.06-.19,1.67.29,3.62-1.75,4.62-.75.37-.59.97-.16,1.52.54.7,1.09,1.38,1.64,2.07-1.44-.31-2.66-1.12-3.84-1.91-.94-.63-1.86-.92-2.96-.76Z"
        />
        <path
          fill="#b3b3b3"
          d="M232.53,38.18c2.01-1.15,3.85-.52,5.65.53.91.83,1.81,1.66,1.14,3.07-.59,0-1.03-.31-1.42-.72-.39-.42-1-.45-1.43-.74-.91-.61-1.97.36-2.58-.09-.57-.43-.43-1.65-1.37-2.06Z"
        />
        <path
          fill="#bababa"
          d="M239.32,41.79c-.65-.93-1.55-1.76-1.14-3.07.11-.78-1.83-1.6.04-2.36.15.16.3.33.45.49.47,1.17,1.49,1.84,2.34,2.67.85.83,1.35,1.72-.28,2.29-.47,0-.94-.01-1.41-.02Z"
        />
        <path
          fill="#a8a8a8"
          d="M241.6,47.48c-.66.02-1.37.13-1.66-.69-.03-.1.12-.36.21-.38.86-.17,1.11.51,1.44,1.08,0,0,0,0,0,0Z"
        />
        <path
          fill="#bdbdbd"
          d="M245,39.97c-.23-.24-.47-.48-.7-.71-.27-.45-.55-.95.15-1.22.66-.26,1.43-.6,2.02.05.81.89,1.47,1.89,2.71,2.34.58.21.42.83-.02,1.23-.52.47-1.15.64-1.83.7-.51-1.05-1.23-1.9-2.33-2.38h0Z"
        />
        <path
          fill="#a8a8a8"
          d="M245,39.97s.08.07.11.12c.31.41.89.69.82,1.31-.03.26-.33.25-.52.14-.64-.38-.6-.96-.41-1.56h0Z"
        />
        <path
          fill="#9e9e9e"
          d="M59.98,200.06c-.08.08-.16.16-.24.23-1.36.83-1.76,2.55-3.14,3.36-1.61.95-2.88,2.12-3.95,3.78-.68,1.06-2.6,1.46-4.1,1.6-1.04.1-1.63.23-1.31,1.43.16.59-.19.93-.68,1.17-.55.27-1.07.64-1.64.79-3.76,1.01-6.72,3-8.3,6.69-.38-.13-.73-.14-1.12.04-1.6.76-2.07.6-2.64-1.05-.48-1.42-1.58-2.51-2.01-3.96-.4-1.31-.4-2.31,1.21-2.63.41-.08.69-.3.97-.59.68-.69.93-.37.91.44,0,.32-.14.73.24.88.49.19.68-.26.86-.58.49-.87,1.3-1.26,2.17-1.68,2.42-1.18,4.93-2.3,5.95-5.14.26-.73.97-1.07,1.62-1.35,1.54-.65,2.12-2.2,3.17-3.31.36-.38.13-.96,0-1.44.35-.73.84-1.29,1.73-1.29.35,0,.65-.1.83-.44.11-.2.28-.45.47-.43,1.95.22,2.6-1.31,3.63-2.49,1.26-1.45,3.11-2.02,4.93-2.55.64-.18,1.19-.44,1.57-1.15.44-.82,1.34-1.14,2.23-.71.98.48,1.98.64,3.05.6.86-.03,1.52.31,1.76,1.17.21.76-.12,1.3-.8,1.7-.79.46-1.47,1.16-1.61,2.03-.26,1.56-1.23,2.58-2.27,3.6-1.15.47-2.5.42-3.52,1.26Z"
        />
        <path
          fill="#a5a5a5"
          d="M62.84,197.73c1.12-.34,1.67-1.06,1.92-2.24.27-1.27.54-2.66,2.06-3.2.27-.1.46-.34.34-.66-.11-.29-.39-.41-.67-.4-1.34.06-2.67.09-3.82-.79-.18-.14-.53-.09-.59.12-.44,1.65-1.97,1.37-3.1,1.78-2.07.75-4.05,1.57-4.94,3.84-.25.65-.88.81-1.53.79-1.76-.05-3.18.78-4.53,1.78.41-1.5,2.38-1.38,2.86-2.8-.22-.46.17-.62.42-.86,1.45-.99,3.13-1.61,4.54-2.71.52-.41.54-1.14,1.16-1.41.4-.18.78-.4,1.15-.63,1.1-.68,1.91-1.78,3.22-2.15.27-.05.53-.02.8.02.16-.03.39,0,.48-.09,2.26-2.24,4.76-.9,7.18-.48.32.06.63.21.96.26.89.13,1.52-.17,1.75-1.1.09-.38.25-.79.26-1.15.12-3.98,2.56-6.1,5.93-7.49.57-.24,1.11-.53,1.55-.95.74-.71,1.47-1.35,2.61-1.11.5.11.79-.31,1.12-.61.71-.64,1.51-.77,2.2-.1.7.67.32,1.5-.2,2.04-.8.82-1.41,1.78-2.15,2.63-.11.13-.24.22-.37.3-1.23.71-2.71,1.04-3.14,2.79-.18.71-1.27.95-1.98,1.34-.52.6-.55,1.37-.72,2.1h0c-.2.66-.67.86-1.33.89-.58.02-.94.39-1.06.97-.35.73-.63,1.5-1.2,2.11,0,0,0,0,0,0-.73.15-1.29.69-2.06.8-.95.14-1.34.75-1.39,1.76-.05,1.13-.2,2.15-1.96,2.01-1.11-.09-1.82,1.13-2.07,2.26-.16.75-.08,1.71-1.1,1.73-.97.02-2.14.27-2.76-.92,0-.17.05-.32.15-.46Z"
        />
        <path
          fill="#afafaf"
          d="M30.89,207.99c2.22-1.39,4.43-2.78,6.65-4.17,2.1-.27,3.86-1.15,5.34-2.71.98-1.04,2.24-1.69,3.68-1.91-.39,2.03-1.92,3.02-3.55,3.91-.48.26-1.03.42-1.52.67-2.3,1.18-4.68,2.17-6.36,4.36-.93,1.22-2.36,2.1-4.01,2.34-.46-.9,2.22-1.91-.23-2.5Z"
        />
        <path
          fill="#adadad"
          d="M58.45,191.32c-.32.15-.63.31-.95.46-1.93.3-3.16,1.76-4.52,2.92-.48.41-.87.56-1.42.55-.24.23-.48.47-.72.7-.42.33-.88.53-1.42.46.29-.66.77-1.12,1.43-1.4l3.11-3.02c2.14-1.82,4.09-3.84,6.17-5.73.59-.24,1.16-.65,1.81-.17,1.52,1.32,1.58,1.9.31,2.93-.16,0-.31,0-.47,0-1.26.56-2.03,1.83-3.33,2.32Z"
        />
        <path
          fill="#b6b6b6"
          d="M50.86,195.01c-.48.47-.95.93-1.43,1.4-.96.93-1.91,1.86-2.87,2.79-2.71.98-4.29,3.62-6.96,4.71-.84.34-1.39.52-2.05-.1,2.97-1.02,5.14-3.38,7.91-4.72,2.08-1.01,3.38-3.03,5.4-4.09Z"
        />
        <path
          fill="#adadad"
          d="M26.99,217.84c-.68-.05-1.32.04-1.89.45-.16,0-.31,0-.47,0-.36-.76-.89-1.1-1.71-.66-.51.27-1.03.35-1.58.15.54-.11,1-.39,1.46-.69,1.72-1.11,2.88-.89,4.2.75Z"
        />
        <path
          fill="#d4d4d4"
          d="M45.16,198.25c-.89.45-1.78.9-2.67,1.33-.29.14-.66.36-.89.01-.23-.36.1-.64.38-.79,4.1-2.18,6.85-6.05,10.73-8.51,3.54-2.24,6.86-4.76,9.83-7.72.71-.71,1.42-1.39,1.94-2.25,1.73-.87,2.86-2.5,4.48-3.49.47-.29.94-.85,1.49-.25.51.55.25,1.12-.22,1.6-1.25,1.26-2.54,2.48-3.8,3.73-.48.54-1.03,1.02-1.56,1.51-1.36,1.03-2.7,2.08-4.2,2.91-.17.1-.35.18-.53.24-1.38,1.07-2.59,2.34-3.94,3.44-.47.35-.86.84-1.52.82-.08.07-.16.14-.24.21-2.1,1.67-4.08,3.47-6.22,5.09-1,.75-2.01,1.47-3.07,2.12h0Z"
        />
        <path
          fill="#cbcbcb"
          d="M45.16,198.25c3.1-2.4,6.19-4.8,9.29-7.2-2.26,1.99-4.51,4-6.8,5.96-.71.6-1.56,1.02-2.49,1.24Z"
        />
        <path
          fill="#b6b6b6"
          d="M54.69,190.83c.4-.39.79-.78,1.19-1.17,1.79-.62,2.73-2.36,4.29-3.28.08-.07.16-.14.23-.21.18-.03.29.05.35.21-.06.16-.17.25-.35.25-2.06,1.88-3.81,4.14-6.44,5.35.24-.38.48-.77.72-1.15Z"
        />
        <path
          fill="#bdbdbd"
          d="M19.6,241.79c-.31-.16-.62-.33-.94-.49-1.58-.1-1.7-.22-1.37-1.41,0-.31-.01-.63-.02-.94.32,0,.64,0,.96,0,.52.55,1.06,1.09,1.55,1.66.37.43.76.93-.19,1.17Z"
        />
        <path
          fill="#eaeaea"
          d="M17.29,239.89c.24.68.8,1.05,1.37,1.41.16.27.48.54.24.87-.2.27-.49.1-.73-.03-.39-.2-.77-.41-1.15-.62-.18-.19-.38-.37-.54-.58-.25-.32-.7-.65-.35-1.09.33-.42.78-.09,1.17.03Z"
        />
        <path
          fill="#b9b9b9"
          d="M60.6,206.43c1.6-2.18,3.2-4.36,4.8-6.55.06-.06.13-.12.19-.19.55-1.57,2.23-2.1,3.12-3.38.31-.44.89-1.04,1.68-.86,0,0,.02,0,.02,0-.19,1.79-2.07,2.44-2.65,3.97-2.07,1.74-4.18,3.43-5.6,5.8-.35.58-.96.9-1.57,1.19Z"
        />
        <path
          fill="#acacac"
          d="M63.07,197.97c.49.09.98.15,1.46.28.8.22,1.32-.02,1.44-.86.26-1.81,1.38-2.67,3.11-2.91.67-.09.78-.38.53-1.03-.65-1.68.06-2.81,1.93-3.03.81-.09,1.65.07,2.47.12-1.18,1.64-2.96,2.78-3.83,4.67-.72,1.05-1.88,1.67-2.8,2.5-.74.67-.73,2.02-1.97,2.16h0c-.1.05-.2.08-.3.14q-2.13,1.28-3.71-.41c.23-.88.27-1.95,1.67-1.64Z"
        />
        <path
          fill="#b1b1b1"
          d="M65.41,199.88c.34-.51.72-1,1-1.53.83-1.57,1.92-2.8,3.77-3.12.07.08.15.16.22.24-1.32,1.35-2.65,2.7-3.96,4.06-.3.31-.64.39-1.03.36Z"
        />
        <path
          fill="#a5a5a5"
          d="M63.07,197.97c-.77.33-1.26.94-1.67,1.64-.16.15-.32.31-.47.46-.32,0-.63,0-.95-.01.94-.79,1.43-2.13,2.86-2.33.08.08.16.16.23.24Z"
        />
        <path
          fill="#a7a7a7"
          d="M62.25,189.01c.41-1.03-.23-1.79-.67-2.6-.21-.95.57-1.06,1.15-1.34.61-.15,1.24-.17,1.84-.32.6-.13,1.24-.31,1.77.11,1.43,1.13,3.01.74,4.55.47.78-.14,1.41-.65.99-1.58-.35-.77.05-1.1.46-1.69,2.04-2.98,5.53-4.01,8.08-6.29.43-.38.99-.44,1.52-.54,1.92-.38,3.45-1.21,3.95-3.28.07-.28.2-.5.48-.63,2.44-1.12,4.22-3.22,6.58-4.47.08-.04.16-.09.23-.13.96-1.4,2.68-2.15,3.3-3.86.07-.19.28-.4.47-.46,2.83-.79,4.57-3.25,7.06-4.56.62-.32,1.34-.49,1.86.06.59.63.1,1.23-.32,1.76-1.5,1.86-2.91,3.81-4.55,5.53-.88.92-2.17,1.47-3.31,2.13-.47.27-.96.13-1.35-.29-1.16-1.23-1.63-.42-1.79.7-.13.91-.45,1.47-1.32,1.9-1.07.53-1.57,1.54-1.46,2.76.08.87-.22,1.38-1.13,1.5-.26.03-.63.1-.76.29-1.56,2.25-4.36,3.31-5.58,5.88-.31-.14-.64.69-.94,0,.69-.87,1.4-1.73,2.07-2.61.32-.41.51-.91.13-1.38-.4-.5-.87-.17-1.36-.07-2.59.55-4.56,2.35-6.86,3.47-2.51,1.22-5.07,4.28-3.56,7.65.36.81-.02,1.41-.81,1.72-.97.37-1.94.41-2.9-.1-2.4-1.27-4.79-1.68-7.17.1-.16.12-.42.11-.64.16Z"
        />
        <path
          fill="#b3b3b3"
          d="M63,185.48c-.48.31-.95.62-1.43.92-.39.08-.79.15-1.18.23.08-.08.16-.17.24-.25.77-1.29,2.35-1.66,3.21-2.85,1.41-.64,2.45-1.78,3.58-2.78,2.36-2.08,4.36-4.51,6.64-6.69,1.27-1.21,3.03-1.63,4.18-2.96.13-.17.32-.44.49-.4,2.01.48,3.09-1.17,4.53-1.96.12-.07.16-.2.36-.23,1.82-.3,2.7-1.42,2.75-3.24.45-.51.91-1,1.65-.48.08.08.15.16.23.24.48.13.97.16,1.46.17,1.21.01,1.64-.55,1.51-1.8-.05-.54-.22-1.2.61-1.39.27.56.61,1.1,1.27,1.18,1.78.22,1.44,1.05.66,2.08-.3.39-.54.82-.81,1.24-.45,1.67-1.88,1.85-3.22,1.99-.82.08-1.49-.01-1.75,1.06-.17.71-.88,1.03-1.66,1.09-1.31.1-2.47.57-3.31,1.61-.68.84-1.54,1.08-2.59,1.1-1.76.03-2.91,1.2-3.16,2.9-.2,1.33-.42,2.66-2.12,3.05-1.21.28-1.55,1.56-2.31,2.36-.77.82-1.59,1.41-2.77,1.3-.84-.08-1.55.16-2.2.73-.82.73-1.98.6-2.95,1.03-.6.26-1.23.64-1.92.77Z"
        />
        <path
          fill="#adadad"
          d="M63,185.48c.28-.41.59-.78,1.02-1.07,1.31-.9,3.36-.65,3.97-2.69.16-.54.87-.12,1.33.07,1.45.59,2.61.33,3.28-1.21.41-.94,1.16-1.5,2.08-1.78,1.3-.4,1.82-1.27,1.97-2.56.26-2.3,1.08-3.1,3.45-3.44.93-.13,1.69-.51,2.27-1.24.57-.72,1.24-1.33,2.13-1.51,1.43-.28,2.95-.26,3.74-1.88.18-.37.81-.28,1.25-.29,1.33-.01,2.5-.42,3.45-1.39l.23.24s0,0,0,0c-.4.84-1.07,1.38-1.92,1.72-1.4.55-2.53,1.53-3.72,2.4-.11.08-.33.2-.33.27-.08,2.22-2.47,2.2-3.47,3.54-.42.57-1.25.63-1.97.49-1.47-.3-2.42.23-3.09,1.58-.6,1.21-1.69,2.26-2.87,2.58-2.26.62-2.75,2.49-3.42,4.17-.51,1.27-1,2.03-2.49,1.76-1.31-.23-2.69.37-4-.27-.59-.29-.96.43-1.49.53-.47,0-.94,0-1.41-.01Z"
        />
        <path
          fill="#b6b6b6"
          d="M91.83,162c-.08.72.35,1.25.69,1.82.33.54.55,1.13.12,1.7-.39.53-.98.46-1.52.32-.96-.25-1.91-.54-2.87-.82,1.03-.05,1.78-.46,2.13-1.51.27-.8.86-1.4,1.45-1.99,0,.16,0,.31,0,.47Z"
        />
        <path
          fill="#acacac"
          d="M83.34,180.05c.31,0,.63,0,.94,0-1.68,1.83-3.11,3.92-5.55,4.92-.63.26-.79,1.04-1.14,1.61-.11-.73.02-1.43.26-2.12.04-.16.06-.39.22-.42,2.39-.5,3.17-3.11,5.26-3.99Z"
        />
        <path
          fill="#acacac"
          d="M75.22,188.45c.14-1.46,1.62-1.19,2.38-1.86-.39,1.13-1.57,1.27-2.38,1.86Z"
        />
        <path
          fill="#cacaca"
          d="M105.03,144c3.25-3.63,6.42-7.34,9.77-10.86,2.72-2.86,5.38-5.77,8.01-8.71.61-.68,1.32-2.07,2.33-1.29,1,.77-.02,1.93-.66,2.57-2.2,2.21-3.84,4.88-6.02,7.12-2.07,2.13-4.09,4.37-5.43,7.1-.16.33-.46.65-.77.86-4.61,3.07-8.3,7.17-12.28,10.94-.74,0-1.53,1.21-2.08.34-.61-.98.67-1.42,1.23-2,1.67-1.75,3.57-3.27,5.26-5,.31-.32.56-.62.64-1.06Z"
        />
        <path
          fill="#c2c2c2"
          d="M105.03,144c-.32,1.78-1.71,2.82-2.95,3.82-.94.76-1.89,1.48-2.74,2.34-.33.34-.98.61-.81,1.14.21.69.96.27,1.45.42-1.08,1.18-2.21,2.34-2.6,3.98-.11.45-.36.8-.81.99-.2.08-.51.13-.59.28-1.84,3.43-6.13,4.37-7.96,7.82-.48.31-.95.62-1.43.92-.46,1.51-1.43,2.36-2.97,2.88-.98.33-1.81,1.22-2.67,1.9-.69.54-1.37.92-2.22,1.03-4.06,3.86-8.63,7.13-12.82,10.85-.57.51-1.3.85-1.95,1.26,0-.32.17-.46.47-.49.48-.46.96-.93,1.44-1.39,1.75-1.56,3.67-2.91,4.84-5.08.52-.97,1.74-1.48,2.6-2.28.52-.48,1.1-.91,1.64-1.38-1.61,1.48-1.71,1.5-2.36.33,3.7-2.56,7.34-5.19,10.58-8.34.31-.3.72-.49,1.09-.73.65,1.53-.83,1.86-1.26,2.5-.42.27-.25-.08-.21.14.01.07.06.08.14,0,2.97-2.59,5.96-5.17,9-7.68,1.38-1.14,2.15-2.7,3.42-3.86.57-.52.63-1.14.37-1.82.45-.91,1.15-1.62,1.88-2.29,2.56-2.35,4.75-5.06,7.44-7.27Z"
        />
        <path
          fill="#cacaca"
          d="M95.71,153.56c.46.11,1.09-.08,1.28.53.17.54-.23.96-.61,1.28-.84.7-1.4,1.54-1.66,2.61-.14.57-.51,1-1.06,1.2-1.53.53-2.61,1.65-3.67,2.78-2.18,2.32-4.82,4.05-7.37,5.9-.4.29-.92.69-1.33.26-.52-.55.14-.9.5-1.16,1.02-.72,1.6-1.83,2.47-2.68,1.51-1.45,2.8-3.3,4.57-4.26,2.21-1.2,3.48-3.29,5.44-4.65.66-.46.95-1.21,1.44-1.8Z"
        />
        <path
          fill="#ccc"
          d="M72.59,173.34c.77-.02,1.35-.44,1.9-.91.74-.63,1.57-.96,2.85-.76-1.24,1.82-2.83,3.06-4.33,4.21-2.08,1.58-3.97,3.32-5.88,5.07-.36.33-.83.54-1.24.81,1.32-1.43,2.94-2.58,3.89-4.36-2.15.38-3.21,2.54-5.28,2.93.16-.16.32-.32.48-.47,1.03-1,2.07-2.01,3.1-3.01,1.79-.81,3.16-2.14,4.53-3.49Z"
        />
        <path
          fill="#aaa"
          d="M106.15,148.48c-1.21,1.63-3.05,2.38-4.7,3.46.48-1.68,2.21-2.41,2.82-3.94.48-.46.95-.92,1.43-1.38.36.16.32.31,0,.46-.36.63-.16,1.08.45,1.4Z"
        />
        <path
          fill="#aaa"
          d="M121.72,130.04c-1.16,2.08-2.65,3.91-4.32,5.61.93-2.26,2.66-3.91,4.32-5.61Z"
        />
        <path
          fill="#a9a9a9"
          d="M105.7,147.08c0-.15,0-.31,0-.46,1.06-1.03,2.11-2.1,3.79-1.86-1.27.78-2.53,1.55-3.8,2.33Z"
        />
        <path
          fill="#a7a7a7"
          d="M51.56,195.25c.84-.61,1.78-1.12,2.38-1.99.88-1.28,2.08-1.7,3.56-1.48-1.44,1.3-3.28,2.07-4.59,3.54-.44.49-.91.2-1.35-.07Z"
        />
        <path
          fill="#a7a7a7"
          d="M83.34,180.05c-1.43,1.97-3.08,3.65-5.48,4.42,1.86-1.43,2.72-4.1,5.48-4.42Z"
        />
        <path
          fill="#a7a7a7"
          d="M58.45,191.32c1.03-.89,1.92-1.98,3.33-2.32-.99.94-1.9,2-3.33,2.32Z"
        />
        <path
          fill="#ccc"
          d="M60.16,186.39c-1.37,1.17-2.35,2.85-4.29,3.28,1.44-1.08,2.69-2.4,4.29-3.28Z"
        />
        <path
          fill="#ccc"
          d="M64.44,183.14c-.16.16-.32.33-.47.49-1.11.92-2.22,1.84-3.33,2.76-.08-.07-.16-.14-.24-.21,1.28-1.11,2.56-2.22,4.05-3.04Z"
        />
        <path
          fill="#acacac"
          d="M64.42,185.5c1.53-1.06,3.31-1.28,5.07-1.17,1.42.08,2.37-.29,2.89-1.63.72-1.86,1.68-3.41,3.81-3.99.95-.26,1.35-1.22,1.61-2.18.6-2.17,1.84-2.94,3.91-2.34,1.11.32,1.83.14,2.16-1.05.11-.38.4-.88.76-.75,1.68.59,2.02-.72,2.69-1.7.57-.84,1.44-1.35,2.36-1.79,1.24-.6,2.54-1.12,3.51-2.17-.94,2.01-3.19,2.5-4.62,3.95-.42.43-1.06.68-1.29,1.26-.94,2.37-3.13,3.11-5.17,4.12-2.57,1.27-5.12,2.62-7.41,4.36-1.17.89-2.06,2.16-2.16,3.8-.1,1.52-.91,2.27-2.5,2.25-1.41-.02-2.87.45-4.03-.87-.39-.45-1.05-.18-1.59-.09Z"
        />
        <path
          fill="#a5a5a5"
          d="M88.75,174.79c-.5.46-1,.56-1.42.2-.62-.53-.32-1.09.15-1.53.34-.32.83-.82,1.26-.41.54.52.14,1.21,0,1.74Z"
        />
        <path
          fill="#b6b6b6"
          d="M78.74,171.51c2.62-1.94,5.24-3.87,7.85-5.81.35,1.96.06,2.55-1.73,3.07-1.41.41-2.59,1.2-3.7,2.08-.76.6-1.51.85-2.43.65Z"
        />
      </g>
    </SvgIcon>
  );
}

function formatToSpanishDate(isoDateString: string, options?: { full: true }): string {
  const date = new Date(isoDateString);

  const optionsDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  };
  if (options?.full) {
    optionsDate.hour = 'numeric';
    optionsDate.minute = 'numeric';
  }

  return new Intl.DateTimeFormat('es-ES', optionsDate).format(date);
}

export default formatToSpanishDate;

import React, { useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import If from '@components/If';
import useChangeOrganizations from '@core/hooks/useChangeOrganizations';
import useProfile from '@core/hooks/useProfile';
import { UserOrganizationModel } from '@core/types';
import MenuItemIcon from '../../MenuItemIcon';

const OrganizationsMenu = () => {
  const { organizations, userUid } = useProfile();
  const changeOrganization = useChangeOrganizations();
  const [open, setOpen] = useState(false);

  const onOrganizationChange = (organizationItem: UserOrganizationModel) => {
    changeOrganization({
      userUid,
      organizationProductId: organizationItem.organizationProductId,
      organizations,
    });
    localStorage.removeItem('savedSelectedFilters');
  };

  return (
    <If condition={(organizations || []).length > 1}>
      <ListItemButton
        onClick={() => {
          setOpen(!open);
        }}
      >
        <MenuItemIcon>autorenew</MenuItemIcon>
        <ListItemText primary="Cambiar de oganización" />
        <If condition={open} fallback={<ExpandMore />}>
          <ExpandLess />
        </If>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {organizations?.map((organizationItem) => (
            <ListItemButton
              onClick={() => {
                onOrganizationChange(organizationItem);
              }}
              key={organizationItem.organizationProductId}
              sx={{ pl: 8 }}
            >
              <ListItemText primary={organizationItem.organizationName} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </If>
  );
};

export default OrganizationsMenu;

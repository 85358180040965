import React from 'react';
import { Box } from '@mui/material';
import SoonBlurrer from '@components/SoonBlurrer/SoonBlurrer';
import Title from '@components/Title';
import { SubscriptionGeneralProps } from '../SubscriptionGeneralProps';
import BarChartComponent from './BarChart/BarChart';
import PieChartComponent from './PieChart/PieChart';
import UserChartComponent from './UserChart/UserChart';
import useSx from './sx';

const Consumption: React.FC<SubscriptionGeneralProps> = ({ subscriptionDetails }) => {
  const styles = useSx();
  return (
    <Box sx={styles.consumptionContainer}>
      <Box sx={styles.titleContainer}>
          <Title text="Consumo" />
      </Box>
      <Box sx={styles.chartContainer}>
        <PieChartComponent styles={styles} subscriptionDetails={subscriptionDetails} />
        <BarChartComponent styles={styles} subscriptionDetails={subscriptionDetails} />
        <UserChartComponent styles={styles} subscriptionDetails={subscriptionDetails} />
      </Box>
    </Box>
  )
};

export default Consumption;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    '.icon-seen-col': { fontSize: '0.8rem' },
    '.header-text': { minWidth: '200px' },
    '.table-row': {
      '& td': {
        textDecoration: 'none',
        textTransform: 'none',
      },
      cursor: 'pointer',
      ':hover': {
        '& td': {
          background: (theme) => theme.palette.shade?.[100],
        },
      },
    },
    '.table-status-unseen': {
      '& td': {
        background: (theme) => theme.palette.shade?.[300],
        fontWeight: 'bold',
      },
    },
  },
});

export default useSx;

import React, { useState } from 'react';
import { Crop } from 'react-image-crop';

const useSelectedFileDisplay = () => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const [rotation, setRotation] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(0.5);
  const [dragging, setDragging] = useState<boolean>(false);
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [startDragPosition, setStartDragPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [cropMode, setCropMode] = useState<boolean>(false);
  const [crop, setCrop] = useState<Crop | undefined>();

  return {
    imagePreviewUrl, setImagePreviewUrl,
    rotation, setRotation,
    zoom, setZoom,
    dragging, setDragging,
    position, setPosition,
    startDragPosition, setStartDragPosition,
    cropMode, setCropMode,
    crop, setCrop,
  };
};

export default useSelectedFileDisplay;

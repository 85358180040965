import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
    root: (theme) => ({ 
        ...expandDisplayFlex({j: 'space-between', a: 'center'}),
        marginTop: '1.5rem',
        marginBottom: '1.5rem',
        [theme.breakpoints.down('md')]: {
            ...expandDisplayFlex({d: 'column'}),
            gap: '10px'
        } 
    })
});

export default useSx;

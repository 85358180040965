import React, { useState } from 'react';
import ActionButton from '@components/Button/ActionButton';
import useOrganizationTemplates from '@core/hooks/useOrganizationTemplates';
import useSetOrganizationTemplates from '@core/hooks/useSetOrganizationTemplates';
import AddPdfTemplateForm from '../AddPdfTemplateForm';

const AddTemplateAction = () => {
  const [open, setOpen] = useState(false);
  const { postOrganizationTemplates, isUploading } = useSetOrganizationTemplates();
  const { organizationTemplates } = useOrganizationTemplates();

  const onClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (values: { file: File | null; title: string }) => {
    const formData = new FormData();
    formData.append('file', values.file!, values.file?.name || '');
    formData.append('title', values.title);

    await postOrganizationTemplates(formData);
    onClose();
  };

  const hasReachedLimitOfTemplates = (organizationTemplates?.items.length || 0) >= 5;

  return (
    <>
      <ActionButton
        onClick={() => {
          setOpen(true);
        }}
        disabled={hasReachedLimitOfTemplates}
        sx={{ alignSelf: 'flex-end' }}
        text="Agregar plantilla"
        variant="contained"
      />
      <AddPdfTemplateForm
        handleSubmit={handleSubmit}
        open={open}
        onClose={onClose}
        disabledActions={isUploading}
      />
    </>
  );
};

export default AddTemplateAction;

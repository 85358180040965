import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import If from '@components/If'
import Input from './Input'
import InputWithLabelProps from './InputWithLabelProps'
import Label from './Label'
import useSx from './sx'

const InputWithLabel = ({label, required, helperText, ...inputProps}: InputWithLabelProps) => {
    const sx = useSx()

    
    return (
        <>
            <Box sx={sx.root}>
                <Label label={label} required={required} />
                <Input {...inputProps} />
            </Box>
            <If condition={inputProps.error}>
                <Typography marginLeft={{xs: "0", md: "auto"}} color="red" lineHeight="0">
                    {helperText}
                </Typography>
            </If>
        </>
    )
}

export default InputWithLabel
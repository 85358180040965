import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    label: {
        marginLeft: 0,
        '.MuiTypography-root': {
          marginRight: '15px'
        }
    }
});

export default useSx;

import React from 'react';
import useViewportSize from '@core/hooks/useViewportSize';
import LargeViewMenu from './LargeViewMenu';
import SettingsMenuProps from './SettingsMenuProps';
import SmallViewMenu from './SmallViewMenu';

const SettingsMenu = ({ anchorEl, onMenuItemClick, onClose, settingsItems }: SettingsMenuProps) => {
  const { isSmallViewport } = useViewportSize();
  const MenuComponent = isSmallViewport ? SmallViewMenu : LargeViewMenu;

  return <MenuComponent anchorEl={anchorEl} onMenuItemClick={onMenuItemClick} onClose={onClose} settingsItems={settingsItems} />;
};

export default SettingsMenu;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useProfile from '@core/hooks/useProfile';
import useWidth from '@core/hooks/useWidth';
import { RoleName } from '@core/types';
import useFilters from '../hooks/useFilters';
import useGetFolders from '../hooks/useGetFolders';
export type Tsections = 'recientes' | 'organización' | 'privado';

const useTemplatesState = () => {
  const navigate = useNavigate();
  const { role, hasAdminPermissions } = useProfile();
  const [filters, setFilters, removeFilter] = useFilters();
  const { folders } = useGetFolders(filters);
  const width = useWidth();
  const deviceWidth = width === 'xs' ? 'sm' : width;

  useEffect(() => {
    if (role !== RoleName.Radiologist && role !== RoleName.System && !hasAdminPermissions) {
      navigate('/');
    }
  }, [role, navigate, hasAdminPermissions]);

  return {
    folders,
    filters,
    setFilters,
    removeFilter,
    deviceWidth,
  };
};

export default useTemplatesState;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    display: 'flex',
    flexDirection: { xs: 'column-reverse', md: 'row' },
    justifyContent: 'space-between',
    gap: '10px',
  },
});

export default useSx;

import React from 'react';
import Box from '@mui/material/Box';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import ActionsProps from './ActionsProps';
import useSx from './sx';

const Actions = ({ isLoading, isDeleting, resetForm, isDisabled, isDisabledDelete, onDelete, onSubmit}: ActionsProps) => {
  const sx = useSx();

  return (
    <Box sx={sx.root}>
      <If condition={!isDisabled && isDisabledDelete}>
        <ActionButton
          color="secondary"
          variant="outlined"
          onClick={() => resetForm()}
          text="Cancelar"
        />
      </If>
      <ActionButton
        color="error"
        variant="contained"
        onClick={onDelete}
        disabled={isDisabledDelete}
        isLoading={isDeleting}
        text="Eliminar"
      />
      <ActionButton
        color="primary"
        variant="contained"
        isLoading={isLoading}
        text="Guardar"
        disabled={isDisabled}
        onClick={onSubmit}
      />
    </Box>
  );
};

export default Actions;

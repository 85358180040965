import React, { useEffect } from 'react';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import { CustomSnackbarProps } from './CustomSnackbarProps';
import useSx from './sx';

const icons = {
  success: <CheckCircleOutlineOutlinedIcon />,
  info: <InfoOutlinedIcon />,
  warning: <ReportProblemOutlinedIcon />,
  error: <ErrorOutlineOutlinedIcon />,
};

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({
  open,
  message,
  onClose,
  type,
  title,
  autoHideDuration,
}) => {
  const sx = useSx(type);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (open && autoHideDuration) {
      timer = setTimeout(() => {
        onClose();
      }, autoHideDuration);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [open, autoHideDuration, onClose]);

  return (
    <Dialog
      maxWidth="xs"
      sx={sx.root}
      open={open}
      onClose={onClose}
      aria-labelledby="warning-dialog-title"
    >
      <DialogTitle id="warning-dialog-title" sx={sx.dialogHeader}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={sx.closeButton}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={sx.iconContainer}>
          <Box sx={sx.iconBox}>{icons[type]}</Box>
        </Box>
      </DialogTitle>

      <DialogContent className="content">
        <Typography textAlign={'center'} sx={sx.title}>
          {title}
        </Typography>
        {message}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box sx={sx.actionsButtonContainer}>
          <ActionButton color="primary" variant="contained" text={'Continuar'} onClick={onClose} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CustomSnackbar;

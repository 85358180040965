import React from 'react';
import If from '@components/If';
import { CustomPieLabelProps } from './CustomPieLabelProps';

const radian = Math.PI / 180;

const CustomPieLabel: React.FC<CustomPieLabelProps> = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * radian);
  const y = cy + radius * Math.sin(-midAngle * radian);

  return (
    <If condition={percent * 100 !== 0}>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </If>
  );
};

export default CustomPieLabel;

import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CloseButton from '@components/CloseButton';
import getMenuItems from '@components/Layout/MainLayout/api/getMenuItems';
import useProfile from '@core/hooks/useProfile';
import { RoleName } from '@core/types';
import MenuItemIcon from '../MenuItemIcon';
import ProfileMenuInfoCard from '../ProfileMenuCard';
import ProfileMenuProps from '../ProfileMenuProps';
import OrganizationsMenu from './OrganizationsMenu';
import useSx from './sx';

const SmallViewMenu = ({ anchorEl, onMenuItemClick, onClose }: ProfileMenuProps) => {
  const { hasMoreOrganizations, role, hasAdminPermissions, currentUserOrganization } = useProfile();
  const handleItemClick = (item: string) => onMenuItemClick?.(item);
  const menuItems = getMenuItems();
  const sx = useSx();

  return (
    <Drawer anchor="right" open={!!anchorEl} onClose={onClose} sx={sx.root}>
      <CloseButton onClick={onClose} />
      <Box sx={sx.menuContainer}>
        <ProfileMenuInfoCard selectedOrganization={currentUserOrganization} />
        <List disablePadding>
          <Divider className="EvoDividerTop-root" />
          {menuItems
            .filter(
              (m) => m.enabled && (m.roles[role || RoleName.Anonymous] || hasAdminPermissions),
            )
            .filter((m) => m.key !== 'switch' || (hasMoreOrganizations && m.key === 'switch'))
            .map((m) => (
              <React.Fragment key={m.key}>
                {m.divider && <Divider className="EvoMenuItemDivider-root" />}
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleItemClick(m.key)}>
                    {m.icon && <MenuItemIcon>{m.icon}</MenuItemIcon>}
                    <ListItemText primary={m.label} />
                  </ListItemButton>
                </ListItem>
              </React.Fragment>
            ))}
          <Divider />
          <OrganizationsMenu />
          <ListItem onClick={() => handleItemClick('exit')}>
            <MenuItemIcon>power_settings_new</MenuItemIcon>
            <ListItemText
              primaryTypographyProps={{
                style: {
                  fontWeight: 'bold',
                },
              }}
            >
              Salir
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default SmallViewMenu;

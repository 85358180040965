import { useMutation, useQueryClient } from '@tanstack/react-query';
import setOrganizationLogo from '@core/api/setOrganizationLogo';
import { ORGANIZATION_SETTINGS_QUERY_KEY } from './useOrganizationSettings';

const useSetOrganizationLogo = () => {
  const queryClient = useQueryClient()
  const { isLoading: isUploading, mutateAsync: postOrganizationLogo } = useMutation({
    mutationFn: (payload: FormData) => setOrganizationLogo(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION_SETTINGS_QUERY_KEY])
    }
  });

  return { isUploading, postOrganizationLogo };
};

export default useSetOrganizationLogo;

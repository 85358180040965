const createDoctorNameParagraph = (fullname: string) => {
  const professionalInfo = fullname ? `Dr. ${fullname}` : '';
  const professionalNameParagraph = document.createElement('p');
  professionalNameParagraph.innerText = professionalInfo;
  professionalNameParagraph.style.fontSize = '1rem';
  professionalNameParagraph.style.margin = '0';

  return professionalNameParagraph;
};

export default createDoctorNameParagraph;

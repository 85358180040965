import React from 'react';
import { PartialTheme, ThemeProvider } from '@fluentui/react/lib/Theme';
import { Editor } from 'roosterjs-editor-core';
import { ContentEdit, ImageEdit } from 'roosterjs-editor-plugins';
import { Rooster, Ribbon, createRibbonPlugin } from 'roosterjs-react';
import { darken, lighten, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import If from '@components/If';
import useOrganizationTemplates from '@core/hooks/useOrganizationTemplates';
import useGetFilteredButtons from 'app/features/templates/add/hooks/useGetFilteredButtons';
import { pageConfig } from '../Preview/Preview';
import TextEditorProps from './TextEditorProps';
import UpdateStatePlugin from './UpdateStatePlugin';
import useSx from './sx';

const LightTheme: PartialTheme = {
  palette: {
    themePrimary: '#0078d4', // Color principal del tema (ícono del botón inactivo)
    neutralLighter: '#f4f4f4', // Color de fondo cuando el botón inactivo tiene hover
    themeDarkAlt: lighten('#0078d4', 0.1), // Color del texto cuando el botón tiene hover
    themeDark: lighten('#0078d4', 0.1), // Color del ícono cuando el botón está activo
    neutralLight: '#f4f4f4', // Color de fondo del botón activo
    neutralQuaternaryAlt: darken('#f4f4f4', 0.2), // Color de fondo cuando el botón activo tiene hover
    neutralPrimary: '#080405', // Color del texto de los elementos del dropdown
    white: '#ffff', // Color de fondo de la interfaz general
    themeLighterAlt: '#080405', // Color de fondo para el hover del botón inactivo (se usa en ciertas zonas específicas)
    neutralTertiary: '#595959', // Color del borde y del ícono del botón activo
    neutralDark: '#0078d4', // Color del texto cuando se hace hover sobre los items del menú dropdown
    neutralTertiaryAlt: lighten('#0078d4', 0.8), // Color de los íconos de los botones deshabilitados
    neutralSecondary: '#0078d4', // Color de las flechas en el dropdown
    black: '#0078d4', // Color del texto en los items colapsados ("...") del dropdown
    themeLighter: '#dee016', // (Por definir uso)
    themeLight: '#fee016', // (Por definir uso)
    themeTertiary: '#fee016', // (Por definir uso)
    themeSecondary: '#fee016', // (Por definir uso)
    themeDarker: '#fee016', // (Por definir uso)
    neutralLighterAlt: '#fee016', // (Por definir uso)
    neutralQuaternary: '#fee016', // (Por definir uso)
    neutralPrimaryAlt: '#fee016', // (Por definir uso)
  },
};

const DarkTheme: PartialTheme = {
  defaultFontStyle: { fontFamily: pageConfig.fontFamily, fontSize: pageConfig.fontSize },
  palette: {
    themePrimary: '#0078d4', // Color principal del tema (ícono del botón inactivo)
    neutralLighter: '#3c3c3c', // Color de fondo cuando el botón inactivo tiene hover
    themeDarkAlt: lighten('#0078d4', 0.1), // Color del texto cuando el botón tiene hover
    themeDark: darken('#0078d4', 0.1), // Color del ícono cuando el botón está activo
    neutralLight: '#3c3c3c', // Color de fondo del botón activo
    neutralQuaternaryAlt: darken('#3c3c3c', 0.1), // Color de fondo cuando el botón activo tiene hover
    neutralPrimary: '#969595', // Color del texto principal
    white: '#162436', // Color de fondo general (equivalente a shade[400] en modo oscuro)
    themeLighterAlt: '#fdf8fa', // Color de fondo para el hover del botón inactivo (zonas específicas)
    neutralTertiary: '#d9c8c8', // Color del borde y del ícono del botón activo
    neutralDark: '#0078d4', // Color del texto en el menú dropdown cuando se hace hover sobre los items
    neutralTertiaryAlt: lighten('#0078d4', 0.8), // Color de los íconos de los botones deshabilitados
    neutralSecondary: '#0078d4', // Color de las flechas en el dropdown
    black: '#0078d4', // Color del texto en los items colapsados ("...") del dropdown
    themeLighter: '#dee016', // (Por definir uso)
    themeLight: '#ffe016', // (Por definir uso)
    themeTertiary: '#dee016', // (Por definir uso)
    themeSecondary: '#fee016', // (Por definir uso)
    themeDarker: '#fee016', // (Por definir uso)
    neutralLighterAlt: '#fee016', // (Por definir uso)
    neutralQuaternary: '#fee016', // (Por definir uso)
    neutralPrimaryAlt: '#fee016', // (Por definir uso)
  },
};

const ribbonPlugin = createRibbonPlugin();
const contentEdit = new ContentEdit();
const imageEdit = new ImageEdit();

const TextEditor = ({
  onChange,
  textEditorRef,
  helperText,
  error,
  onTextEditorBlur,
}: TextEditorProps) => {
  const sx = useSx();
  const theme = useTheme();
  const { organizationTemplates } = useOrganizationTemplates();
  const { filteredButtons } = useGetFilteredButtons(
    theme.palette.mode,
    organizationTemplates?.items || [],
  );

  const updateStatePlugin = new UpdateStatePlugin((newContent) => {
    onChange(newContent);
  });

  const selectedTheme = theme.palette.mode === 'dark' ? DarkTheme : LightTheme;

  const roosterClassNames = `rooster ${error ? 'rooster-error' : ''}`;

  return (
    <Box sx={sx.root} className="texteditor-container">
      <ThemeProvider applyTo="element" theme={selectedTheme}>
        <Ribbon buttons={filteredButtons} plugin={ribbonPlugin} />
        <Rooster
          onBlur={onTextEditorBlur}
          contentEditable
          doNotAdjustEditorColor
          inDarkMode={theme.palette.mode === 'dark'}
          className={roosterClassNames}
          plugins={[ribbonPlugin, updateStatePlugin, contentEdit, imageEdit]}
          editorCreator={(div, options) => {
            textEditorRef.current = new Editor(div, options);
            div.id = 'editor-div';
            return textEditorRef.current;
          }}
        />
      </ThemeProvider>
      <If condition={error}>
        <Typography component="p" className="error-text">
          {helperText}
        </Typography>
      </If>
    </Box>
  );
};

export default TextEditor;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef, useEffect, useState, useMemo } from 'react';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import If from '@components/If';
import getTemplateTags from '@core/utils/getTemplateTags';
import { initialTemplate } from '../../../constants/initialTemplate';
import useGetDicomTemplateTagsInfo from '../../../hooks/useGetDicomTemplateTagsInfo';
import replaceTemplateTags from '../../../utils/replaceTemplateTags';
import Actions from './Actions';
import Fab from './Fab';
import TagsList from './TagsList';
import TemplateTagVariableForm from './TemplateTagVariableForm';
import TemplateTagsDialogForm from './TemplateTagsDialogForm';
import useSx from './sx';

const insertTextAtPosition = (
  originalText: string,
  textToInsert: string,
  position: number | null,
) => {
  if (position !== null) {
    return (
      originalText.slice(0, position) + ' ' + textToInsert + ' ' + originalText.slice(position)
    );
  } else {
    return originalText + ' ' + textToInsert;
  }
};

const SPEECH_KEY = process.env.REACT_APP_SPEECH_KEY || '';
const SPEECH_REGION = process.env.REACT_APP_SPEECH_REGION || '';

const TemplateTagsDialog = ({
  isOpen,
  setIsOpen,
  template,
  study,
  isSingleTemplateLoading,
  onHTMLReady,
}: TemplateTagsDialogForm) => {
  const sx = useSx();

  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState('');
  const templateText = useMemo(() => template?.content, [template]);

  //Recording logic
  const [isLoading, setIsLoading] = useState(false);
  const selectedVariableRef = useRef('');
  const speechRecognizer = useRef<SpeechSDK.SpeechRecognizer | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isRecording, setIsRecording] = useState(false);

  const [variables, setVariables] = useState<Record<string, string>>({});
  const getDicomValue = useGetDicomTemplateTagsInfo({ study });

  useEffect(() => {
    if (templateText) {
      const newVariables = getTemplateTags(templateText);
      const variableKeys = Object.keys(newVariables);
      if (variableKeys.length > 0) {
        Object.keys(newVariables).forEach((key) => {
          if (getDicomValue(key)) {
            newVariables[key] = getDicomValue(key);
          }
        });
        setVariables(newVariables);
      } else {
        // There are no template tags, set content
        onHTMLReady(templateText);
      }
    }
  }, [templateText, getDicomValue]);

  const handleConfirm = () => {
    if (!Object.values(variables).every((value) => value !== '')) {
      setWarningDialogOpen(true);
      return;
    }

    if (templateText) {
      const newHtml = replaceTemplateTags(templateText, variables, getDicomValue);
      setIsOpen(null);
      onHTMLReady(newHtml);
    }
  };

  const onWarningDialogConfirm = () => {
    if (templateText) {
      const newHtml = replaceTemplateTags(templateText, variables, getDicomValue);
      setWarningDialogOpen(false);
      setIsOpen(null);
      onHTMLReady(newHtml);
    }
  };

  const onWarningDialogClose = () => {
    setWarningDialogOpen(false);
  };

  const [showVariables, setShowVariables] = useState(false);

  const toggleShowVariables = () => {
    setShowVariables(!showVariables);
  };

  const onSelectedVariable = (variable: string) => {
    setSelectedVariable(variable);
    selectedVariableRef.current = variable;
  };

  const stopRecording = async () => {
    if (!speechRecognizer.current) return;

    try {
      await new Promise<void>((resolve, reject) => {
        speechRecognizer.current?.stopContinuousRecognitionAsync(resolve, reject);
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsRecording(false);
    }
  };

  const handleRecognized = (
    _: SpeechSDK.Recognizer,
    event: SpeechSDK.SpeechRecognitionEventArgs,
  ) => {
    if (event.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
      const finalText = event.result.text.slice(0, -1);
      if (inputRef.current) {
        const oldValue = inputRef.current.value;
        const startPosition = inputRef.current.selectionStart;
        const newValue = insertTextAtPosition(oldValue, finalText, startPosition);
        setVariables((prev) => ({ ...prev, [selectedVariableRef.current]: newValue }));
      }
    }

    setIsLoading(false);
  };
  const startRecording = () => {
    setIsRecording(true);

    if (speechRecognizer.current) {
      speechRecognizer.current.startContinuousRecognitionAsync();

      speechRecognizer.current.recognizing = () => {
        setIsLoading(true);
      };

      speechRecognizer.current.recognized = handleRecognized;
    } else {
      throw new Error('No está disponible el speech recognizer');
    }
  };

  const onStartRecordSpeech = async () => {
    if (isRecording) {
      await stopRecording();
    } else {
      try {
        startRecording();
      } catch (error) {
        console.error(error);
        setIsRecording(false);
      }
    }
  };

  useEffect(() => {
    if (!speechRecognizer.current) {
      const speechAudioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
      const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
      speechConfig.speechRecognitionLanguage = 'es-ES';
      const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, speechAudioConfig);
      speechRecognizer.current = recognizer;
    }
  }, []);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Dialog sx={sx.root} maxWidth="md" open={isOpen && Object.keys(variables).length > 0}>
        <DialogContent className="dialog-content">
          <If
            condition={!isSingleTemplateLoading}
            fallback={<CircularProgress className="circular-progress" />}
          >
            <Box>
              <DialogContentText fontSize="1.5rem" textAlign="center">
                Completa la información en la plantilla seleccionada
              </DialogContentText>
            </Box>
            <Box className="dialog-form">
              <If condition={showVariables || !isXs}>
                <TagsList
                  variables={variables}
                  onSelectedVariable={onSelectedVariable}
                  isActive={(variable) => variable === selectedVariable}
                />
              </If>

              <Box onClick={toggleShowVariables} className="variable-toggler">
                <Typography>Mostrar etiquetas</Typography>
                <If condition={showVariables} fallback={<KeyboardArrowDownIcon />}>
                  <KeyboardArrowUpIcon />
                </If>
              </Box>
              <TemplateTagVariableForm
                variables={variables}
                selectedVariable={selectedVariable}
                setVariables={setVariables}
                inputRef={inputRef}
                onStartRecording={onStartRecordSpeech}
                isRecording={isRecording}
                isLoading={isLoading}
              />
            </Box>
            <Actions
              onCancel={() => {
                setIsOpen(null);
              }}
              onConfirm={handleConfirm}
            />
          </If>
        </DialogContent>
      </Dialog>
      <ActionDialog
        open={warningDialogOpen}
        message="No has completado todas las variables en el informe. Algunas etiquetas aún están sin contenido. Si decides continuar, las variables no modificadas permanecerán sin cambios en la plantilla."
        title="Actualizar Reporte"
        onClose={onWarningDialogClose}
        onCancel={onWarningDialogClose}
        onConfirm={onWarningDialogConfirm}
        type="warning"
        sx={{ zIndex: 30000 }}
      />
      <Fab isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default TemplateTagsDialog;

import React from 'react'
import MuiSearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import useSx from './sx';



const SearchIcon = () => {
    const sx = useSx()

    return (
        <InputAdornment position="start">
            <MuiSearchIcon 
                sx={sx.root} 
            />
        </InputAdornment>
    )
}


export default SearchIcon
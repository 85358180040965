import { post } from '@core/api/baseApi';

export interface ResetPasswordRequest {
  userUid: string;
}

const resetPassword = ({
  userUid
}: ResetPasswordRequest): Promise<{
    success: boolean,
    error: string,
    temporaryPassword: string
  }> =>
  post(`/Users/${userUid}/reset-password`);

export default resetPassword;

import React from 'react';
import useSx from './sx';

const useReportsCustomizationState = () => {
  const sx = useSx();

  return {
    sx
  };
};

export default useReportsCustomizationState;

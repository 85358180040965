import { useMutation, useQueryClient } from '@tanstack/react-query';
import updateDefaultOrganizationTemplate from '@core/api/updateDefaultOrganizationTemplate';
import { ORGANIZATION_TEMPLATE_QUERY_KEY } from './useOrganizationTemplate';
import { ORGANIZATION_TEMPLATE_IMAGE_QUERY_KEY } from './useOrganizationTemplateImage';
import { ORGANIZATION_TEMPLATES_QUERY_KEY } from './useOrganizationTemplates';

const useUpdateDefaultOrganizationTemplate = (templateId: string) => {
  const queryClient = useQueryClient();
  const { isLoading: isUpdating, mutateAsync: updateDefaultOrganizationTemplateAsync } =
    useMutation({
      mutationFn: () => updateDefaultOrganizationTemplate(templateId),
      onSuccess: () => {
        queryClient.invalidateQueries([ORGANIZATION_TEMPLATES_QUERY_KEY]);
        queryClient.invalidateQueries([ORGANIZATION_TEMPLATE_IMAGE_QUERY_KEY, templateId]);
        queryClient.invalidateQueries([ORGANIZATION_TEMPLATE_QUERY_KEY, templateId]);
      },
    });

  return { isUpdating, updateDefaultOrganizationTemplateAsync };
};

export default useUpdateDefaultOrganizationTemplate;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    padding: { xs: '1rem', sm: '2rem' },
    marginTop: '2rem',
    backgroundColor: (theme) =>
        theme.palette.mode === 'dark' ? theme.palette.shade?.[200] : theme.palette.shade?.[50],
    backgroundImage: 'none'
  },
});

export default useSx;

import jwtDecode from 'jwt-decode';
import getApiAccessToken from '@core/utils/getApiAccessToken';
import requestProductAccess from '@core/utils/requestProductAccess';
import getUserUid from './getUserUid';

const ORGANIZATIONID_KEY = 'uop'; // as in (U)ser (O)rganization (P)roduct
const PRODUCT_ACCESS_KEY = 'pat'; // as in (P)roduct (A)ccess (T)oken
const FIVE_MINUTES = 300000;

export type OrganizationInfo = {
  organizationId?: string;
  hasMoreOrganizations?: boolean;
};

function isValidToken(token: string) {
  const jwt = jwtDecode(token) as any;
  const maxExp = (Date.now() + FIVE_MINUTES) / 1000;

  return jwt.exp > maxExp;
}

function organizationKey(userUid?: string) {
  return `${userUid}_${ORGANIZATIONID_KEY}`;
}

function productAccessKey(userUid?: string) {
  return `${userUid}_${PRODUCT_ACCESS_KEY}`;
}

function getProductAccessToken(userUid?: string): { accessToken: string; tokenClaims: any } | null {
  const token = sessionStorage.getItem(productAccessKey(userUid));
  return JSON.parse(token!);
}

function setProductAccessToken(token: { accessToken: string; tokenClaims: any }, userUid?: string) {
  const item = JSON.stringify(token);
  sessionStorage.setItem(productAccessKey(userUid), item);
}

const getOrganization = (userUid?: string): OrganizationInfo | null => {
  const item = localStorage.getItem(organizationKey(userUid));
  return JSON.parse(item!);
};

const setOrganization = (orgInfo: OrganizationInfo, userUid?: string) =>
  localStorage.setItem(organizationKey(userUid), JSON.stringify(orgInfo));

async function requestNewToken(userUid?: string) {
  const apiToken = await getApiAccessToken();
  const { organizationId } = getOrganization(userUid) ?? {};
  const orgId = organizationId || sessionStorage.getItem('organizationId')
  if (orgId && apiToken) {
    const response = await requestProductAccess(orgId, apiToken);
    return response?.accessToken;
  }

  return '';
}

const removeOrganization = (userUid?: string) => localStorage.removeItem(organizationKey(userUid));

const acquireProductTokenSilent = async () => {
  const userUid = getUserUid();

  const { accessToken: existingToken } = getProductAccessToken(userUid) ?? {};

  const organizationInfo = getOrganization(userUid);

  const decodedExistingToken = existingToken ? jwtDecode(existingToken || '') as { orgId?: string } : null;

  const hasNotChangedOrganizationId =
    decodedExistingToken?.orgId === organizationInfo?.organizationId;

  if (existingToken && isValidToken(existingToken) && hasNotChangedOrganizationId) {
    return existingToken;
  }

  const accessToken = await requestNewToken(userUid);

  if (accessToken) {
    const claims = jwtDecode(accessToken);
    setProductAccessToken({ accessToken, tokenClaims: claims }, userUid);

    return accessToken;
  }
};

const removeProductToken = (userUid?: string) => {
  localStorage.setItem('logout-event', 'started');
  localStorage.removeItem('logout-event');
  sessionStorage.removeItem(productAccessKey(userUid));
};

const getProductClaims = (userUid?: string) => {
  const accessToken = getProductAccessToken(userUid);
  return accessToken?.tokenClaims;
};

const clearUserSession = (userUid?: string) => {
  removeOrganization(userUid);
  removeProductToken(userUid);
};

const productAccessManager = {
  getOrganization,
  setOrganization,
  removeOrganization,
  acquireProductTokenSilent,
  removeProductToken,
  getProductClaims,
  clearUserSession,
};

export default productAccessManager;

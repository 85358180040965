import { useContext } from 'react';
import { LandingContext } from '../providers/LandingProvider';

const useLandingContext = () => {
  const context = useContext(LandingContext);

  if (context === null) {
    throw Error(
      'Context useLandingContext is not available. useLandingContext must be used within a child component of StudyProvider',
    );
  }

  return context;
};

export default useLandingContext;

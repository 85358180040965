import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        flex: 1,
        borderRadius: 25,
        padding: '0.2rem 0.5rem',
        width: '100%',
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: 'none'
        },
        '::before': {
            borderBottom: 'none'
        },
        '::after': {
            borderBottom: 'none'
        }
    },
    inputContainer: {
        width: {xs: '100%', md: '80%'}
    },
    passwordIconButton: {
        width: '12px',
        height: '12px'
    }
});

export default useSx;

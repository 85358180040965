import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import If from '@components/If';
import Comment from './Comment';
import CommentListProps from './CommentListProps';

const CommentList = ({setComment, studyComments, userUid, onDelete, disabledActions, isLoading}: CommentListProps) => {
    return (
        <If condition={!isLoading} fallback={<CircularProgress sx={{margin: 'auto'}} />}>
            <List sx={{ width: '100%' }}>
                {
                    (studyComments || [])?.map((comment) => (
                        <Comment
                            key={comment.commentId}
                            setComment={setComment}
                            userUid={userUid}
                            onDelete={onDelete}
                            comment={comment}
                            disabledActions={disabledActions}
                        />
                    ))
                }
            </List>
        </If>
      );
}

export default CommentList
import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: (theme) => ({
    background: theme.palette.shade?.[50],
    '.user-container': { cursor: 'pointer', marginTop: '1rem' },
  }),
});

export default useSx;

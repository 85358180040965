import React from 'react';
import { DropzoneInputProps } from 'react-dropzone';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import HoverableView from '@components/Views/HoverableView';
import TouchscreenView from '@components/Views/TouchscreenView';
import useSx from './sx';

export interface RejectStateProps {
  inputProps: DropzoneInputProps;
  message: string;
  onRetry?: VoidFunction;
}
const RejectState = ({ inputProps, message }: RejectStateProps) => {
  const sx = useSx();

  return (
    <Paper id="ErrorState-root" sx={sx.root}>
      <ErrorOutlineIcon />
      <Typography>
        {message}
        <HoverableView>
          <br />
          <Typography component="span" variant="body1">
            Seleccionar nuevamente.
          </Typography>
        </HoverableView>
        <TouchscreenView>
          <br />
          <Typography component="span" variant="body2">
            Toque aqu&iacute; para continuar.
          </Typography>
        </TouchscreenView>
      </Typography>
      <input {...inputProps} />
    </Paper>
  );
};

export default RejectState;

import getCurrentUserAccount from './getCurrentUserAccount';

const getUserUid = () => {
  const account = getCurrentUserAccount();
  if (account) {
    const { idTokenClaims } = account;
    return idTokenClaims?.sub;
  }
};

export default getUserUid;

import React from 'react';
import useSx from './sx';

const useModeState = () => {
  const sx = useSx();

  return {
    sx,
  };
};

export default useModeState;

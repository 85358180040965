function setCaretAtEnd(editorDiv: Node) {
  const range = document.createRange();
  const selection = window.getSelection();
  if (selection === null) {
    return;
  }

  range.selectNodeContents(editorDiv);
  range.collapse(false);

  selection.removeAllRanges();
  selection.addRange(range);
}

export default setCaretAtEnd;

/* eslint-disable @typescript-eslint/naming-convention */
import { post, get, put, remove } from '@core/api/baseApi';

export interface GetSignatureRequest {
  signatureId: string;
}

const postSignature = (requestData: FormData): Promise<any> =>
  post('/Users/signature', requestData);

const getSignature = (): Promise<{
  professionalInfo: string;
  signature: string;
  file?: any;
}> => get('/Users/signature');

const putSignature = (requestData: FormData): Promise<any> => put('/Users/signature', requestData);

const removeSignature = (deleteSignature: boolean, deleteProfessionalInfo: boolean): Promise<any> =>
  remove('/Users/signature', {
    data: { deleteSignature, deleteProfessionalInfo },
  });

export { postSignature, getSignature, putSignature, removeSignature };

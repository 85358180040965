import { useMutation, useQueryClient } from '@tanstack/react-query';
import deleteSolicitation from '../api/deleteSolicitation';
import { GET_SOLICITATION_QUERY_KEY } from './useSolicitations';

const useDeleteSolicitation = (studyInstanceUid: string) => {
  const queryClient = useQueryClient();
  const { isLoading: isDeleting, mutateAsync: deleteSolicitationAsync } = useMutation({
    mutationFn: (attachmentKey: number | null | undefined) =>
      deleteSolicitation(studyInstanceUid, attachmentKey),
    onSuccess: () => {
      queryClient.invalidateQueries([GET_SOLICITATION_QUERY_KEY, studyInstanceUid]);
    },
  });

  return { isDeleting, deleteSolicitationAsync };
};

export default useDeleteSolicitation;

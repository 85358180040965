import { useQuery } from '@tanstack/react-query';
import { getTemplateTags } from '../api/getTemplateTags';

export const QUERY_KEY = 'templateList';

const useGetTemplateTags = (filters: { Page: string; Search: string }, enabled = true) => {
  const { data: templateTags, ...rest } = useQuery({
    queryKey: [QUERY_KEY, filters],
    queryFn: () => getTemplateTags(filters),
    enabled,
  });

  return {
    templateTags,
    ...rest,
  };
};

export default useGetTemplateTags;

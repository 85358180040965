import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  Typography,
} from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import useSnackbar from '@core/hooks/useSnackbar';
import useUpdateProfilePhoto from '../../../hooks/useUpdateProfilePhoto';
import ProfilePhotoDialogFormProps from './ProfilePhotoDialogFormProps';
import useSx from './sx';

const ProfilePhotoDialogForm = ({
  setModalState,
  modalState,
  onClose,
  setImageSrc,
  imageSrc,
}: ProfilePhotoDialogFormProps) => {
  const sx = useSx();
  const showSnackbar = useSnackbar();
  const { userProfileInfo, userUid, organization } = useProfile();
  const [file, setFile] = useState<null | File>(null);
  const { updateProfilePhotoAsync, isLoading: isUpdating } = useUpdateProfilePhoto(
    userUid || '',
    organization?.organizationId || '',
  );

  const confirmButtonText =
    !userProfileInfo?.profilePhoto && !imageSrc ? 'Agregar' : imageSrc ? 'Confirmar' : 'Editar';

  const avatarSrc = (imageSrc as string) || userProfileInfo?.profilePhoto;

  const onInputFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputFile = event?.target?.files?.[0];

    if (inputFile) {
      setFile(inputFile);
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageSrc(reader.result as string);
      };

      reader.readAsDataURL(inputFile);
    }
  };

  const onConfirmOrEdit = async () => {
    if (!imageSrc) {
      document.getElementById('input-file')?.click();
      return;
    }
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      const response = await updateProfilePhotoAsync(formData);

      if (!response.success) {
        showSnackbar({
          title: 'Error',
          message: 'No ha sido posible actualizar la foto de perfil.',
          type: 'error',
        });
        return;
      }
      setImageSrc(null);
      setFile(null);
      setModalState(null);
    }
  };

  const onCancel = () => {
    setModalState(null);
    setImageSrc(null);
  };

  const onDelete = () => {
    setModalState('delete-photo');
  };

  return (
    <Dialog
      sx={sx.root}
      onClose={onClose}
      maxWidth="sm"
      open={modalState === 'edit-photo'}
      title="Actualizar foto de perfil"
    >
      <DialogTitle id="warning-dialog-title" className="dialog-title">
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          className="close-icon"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className="form-container">
          <Typography fontWeight="600" textAlign="center">
            Actualización de foto de perfil
          </Typography>
          <Typography>Actualiza tu imagen con una nueva fotografía de perfil.</Typography>
          <Avatar className="avatar" src={avatarSrc} />
          <Box width="100%" gap="10px" display="flex" alignItems="center" justifyContent="flex-end">
            <If condition={!!userProfileInfo?.profilePhoto}>
              <ActionButton
                isLoading={isUpdating}
                className="delete-button"
                variant="contained"
                color="error"
                text="Eliminar"
                onClick={onDelete}
              />
            </If>
            <ActionButton
              isLoading={isUpdating}
              onClick={onCancel}
              color="secondary"
              variant="outlined"
              text="Cancelar"
            />
            <ActionButton
              isLoading={isUpdating}
              onClick={onConfirmOrEdit}
              variant="contained"
              text={confirmButtonText}
            />
          </Box>
        </Box>
      </DialogContent>
      <input onChange={onInputFileChange} type="file" style={{ display: 'none' }} id="input-file" />
    </Dialog>
  );
};

export default ProfilePhotoDialogForm;

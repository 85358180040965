import SxDef from '@core/types/SxDef';
import { ThemeVariant } from '@core/types/Theme';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (previewMode: ThemeVariant | undefined): SxDef => ({
  root: {
    ...expandDisplayFlex({j: 'center', a: 'center', d: 'column'}),
  },
  iconButton: {
    marginBottom: '1rem',
    ...expandDisplayFlex({j: 'center', a: 'center'}),
    color: (theme) =>
    (previewMode === 'OS')
        ? 'white'
        : theme.palette.primary.main,
  }
});

export default useSx;

import { publish } from '@core/constants/customEvents';
import { ButtonFactory } from './buttonRegistryFactory';

const recordButton: ButtonFactory = () => {
  return {
    key: 'recordButton',
    unlocalizedText: 'Record Audio',
    iconName: 'Microphone',
    onClick: async () => {
      publish('evodicom.report.record', undefined);
    },
  };
};

export default recordButton;

import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CloseButton from '@components/CloseButton/CloseButton';
import If from '@components/If';
import { NotificationMessage } from '@core/types';
import calculateElapsedTime from '@core/utils/calculateElapsedTime';
import getTitleByNotificationCategory from '@core/utils/getTitleByNotificationCategory';
import NotificationsMenuProps from '../NotificationsMenuProps';
import useSx from './sx';

const SmallViewMenu = ({
  anchorEl,
  onMenuItemClick,
  onClose,
  notifications,
  handleCancelNotification,
}: NotificationsMenuProps) => {
  const handleItemClick = (notificationMessage?: NotificationMessage) =>
    onMenuItemClick?.(notificationMessage);
  const sx = useSx();

  const existsUnseenNotifications = notifications.length > 0;

  return (
    <Drawer anchor="right" open={!!anchorEl} onClose={onClose} sx={sx.root}>
      <CloseButton onClick={onClose} />
      <Box sx={sx.menuContainer}>
        <List disablePadding>
          <If
            condition={existsUnseenNotifications}
            fallback={
              <ListItem sx={sx.listItem} disablePadding>
                <Box>
                  <ListItemText className="ListItemSubtitle">
                    No hay notificaciones pendientes.
                  </ListItemText>
                </Box>
              </ListItem>
            }
          >
            {notifications.map((notificationMessage) => {
              const notificationMetadata: any = JSON.parse(notificationMessage.metadata);
              return (
                <React.Fragment key={notificationMessage.notificationMessageId}>
                  <ListItem
                    onClick={(e) => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      handleItemClick(notificationMessage);
                    }}
                    sx={sx.listItem}
                    disablePadding
                  >
                    <CloseIcon
                      className="ListItemCloseIcon"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        handleCancelNotification?.(notificationMessage);
                      }}
                    />
                    <Box>
                      <ListItemText className="ListItemTitle">
                        {getTitleByNotificationCategory(
                          notificationMessage.notificationMessageCategoryId,
                        )}
                      </ListItemText>
                      <ListItemText className="ListItemSubtitle">
                        {notificationMetadata.description}
                      </ListItemText>
                      <ListItemText className="ListItemDate">
                        {calculateElapsedTime(notificationMessage.createdDate)}
                      </ListItemText>
                    </Box>
                  </ListItem>
                </React.Fragment>
              );
            })}
          </If>
          <ListItem
            onClick={() => {
              handleItemClick(undefined);
            }}
            sx={sx.listItem}
            disablePadding
          >
            <Box>
              <ListItemText className="ListItemTitle">Más notificaciones</ListItemText>
            </Box>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default SmallViewMenu;

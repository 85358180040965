import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: (theme) => ({
    margin: {
      xs: '1rem',
      sm: 'none'
    },
    bgcolor: 'transparent',

    '& .MuiInputBase-root': {
      pl: 6,

      '& .MuiInputAdornment-root': {
        pr: 2,
      },
    },

    '& .ShareByEmailButtonContainer-root': {
      p: theme.spacing(4, 6),
    },

    [theme.breakpoints.up('sm')]: {

      '& .ShareByEmailButtonContainer-root': {
        ...expandDisplayFlex({ j: 'flex-end' }),

        '& .MuiButton-root': {
          maxWidth: 0.2,
        },
      },
    },
  }),
  textFieldBox: {
    width: '100%',
    margin: '1rem 0',
  },
  textarea: { 
    '& textarea': {
      width: '100%',
      height: '150px',
      padding: '16.5px 14px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '32px', 
      fontSize: '0.875rem',
      fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      backgroundColor: (theme) => theme.palette.shade?.[400],
      borderColor: (theme) => theme.palette.shade?.[300],
      color: (theme) => theme.palette.shade?.[900],
      '&:focus': {
        borderColor: (theme) => theme.palette.primary.main,
        outline: 'none',
      },
      '&::placeholder': {
        color: (theme) => theme.palette.grey[500],
        fontWeight: 600
      }
    } 
  },
  label: {
    marginBottom: '1rem'
  },
  whatsappButton: {
    background: '#25D366',
    ':hover': {
      background: '#128C7E',
    }
  },
  selectRoot: {
    width: '40%', 
    marginRight: '8px',
    '& .MuiInputBase-root': {
      padding: 0,
      paddingLeft: 4,
    },
    '& .MuiSelect-select': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      padding: 0,
    }
  },
  selectLanguageRoot: {
    '& .MuiInputBase-root': {
      padding: 0,
      paddingLeft: 4,
    },
    '& .MuiSelect-select': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      padding: 0,
    }
  },
});

export default useSx;

import React, { useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { TextField, Typography } from '@mui/material';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import useSnackbar from '@core/hooks/useSnackbar';
import PDFPreview from '../PDFPreview';
import AddTemplateFormProps, { Form } from './AddTemplateFormProps';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(1, 'Por favor introduzca un titulo')
    .required('Por favor introduzca un titulo'),
  file: Yup.mixed().required('El membrete es requerido'),
});

const AddPdfTemplateForm = ({
  open,
  onClose,
  initialValues = { title: '', file: null },
  handleSubmit,
  disabledActions = false,
}: AddTemplateFormProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const formikRef = useRef<FormikProps<Form>>(null);
  const [file, setFile] = useState<File | null>(initialValues.file);

  const showSnackbar = useSnackbar();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files && event.target.files[0];
    if (newFile) {
      setFile(newFile);
    }
  };

  const onFileLoadedError = (error: number) => {
    if (error === 400) {
      setFile(null);
      showSnackbar({
        title: 'Error',
        message: 'No es posible agregar documentos con mas de una pagina.',
        type: 'error',
      });
    }
  };

  const onFileLoadedSuccess = () => {
    formikRef.current?.setFieldValue('file', file);
  };

  const onCloseMiddleware = () => {
    setFile(null);
    onClose();
  };

  const onFileClickAction = () => {
    inputFileRef.current?.click();
  };

  const onConfirm = () => {
    formikRef.current?.submitForm();
  };

  const onSubmitResetHandler = async (values: Form) => {
    await handleSubmit(values);
    setFile(null);
    formikRef.current?.resetForm();
  };

  const title = !initialValues.file ? 'Agrega una plantilla' : 'Editar plantilla';

  return (
    <ActionDialog
      open={open}
      title={title}
      type="info"
      onClose={onCloseMiddleware}
      onConfirm={onConfirm}
      isLoading={disabledActions}
    >
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={onSubmitResetHandler}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ values, handleChange, errors }) => (
          <>
            <TextField
              name="title"
              value={values.title}
              onChange={handleChange}
              placeholder="Titulo de la plantilla"
              error={!!errors.title}
              helperText={errors.title}
            />
            <ActionButton onClick={onFileClickAction} variant="outlined" text="Subir Plantilla" />
            <If condition={!!errors.file}>
              <Typography color="error.main">{errors.file}</Typography>
            </If>
            <PDFPreview file={file} onError={onFileLoadedError} onSuccess={onFileLoadedSuccess} />
            <input
              accept="application/pdf"
              type="file"
              ref={inputFileRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </>
        )}
      </Formik>
    </ActionDialog>
  );
};

export default AddPdfTemplateForm;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import assignRadiologist from '../api/assignRadiologist';
import { QUERY_KEY as ASSIGNED_RADIOLOGISTS_LIST } from './useGetAssignedRadiologists';

const useAssignRadiologist = () => {

  const queryClient = useQueryClient()

  const { isLoading: isUploading, mutateAsync: assignRadiologistAsync } = useMutation({
    mutationFn: ({studyInstanceUid, userUid}:{ studyInstanceUid: string, userUid: string}) => assignRadiologist(studyInstanceUid, userUid),
    onSuccess: () => {
        queryClient.invalidateQueries([ASSIGNED_RADIOLOGISTS_LIST])
      }
    }
  );

  return { isUploading, assignRadiologistAsync };
};

export default useAssignRadiologist;
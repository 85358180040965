const getTitleByNotificationCategory = (notificationCategory: number) => {
  switch (notificationCategory) {
    case 1:
      return 'Reportes';
    case 2:
      return 'Comentarios';
    default:
      return 'Estudios';
  }
};

export default getTitleByNotificationCategory;

import { useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import { GetStudiesResponse, StudyListItemModel } from '@core/types';
import getStudies from '../api/getStudies';
import Filters from '../types/Filters';

export const QUERY_KEY = 'studies';

export type UseStudiesResult = Omit<UseInfiniteQueryResult<GetStudiesResponse, any>, 'data'> & {
  studyList: StudyListItemModel[];
};
export interface UseStudiesOptions {
  filters?: Filters;
  enabled?: boolean;
}

function getStudiesFromPages(pages: GetStudiesResponse[] = []) {
  if (pages.length === 0) {
    return [];
  }

  const studies = pages.reduce(
    (prev, curr) => [...prev, ...curr.items],
    [] as StudyListItemModel[],
  );

  return studies;
}

const useStudies = ({ filters, enabled }: UseStudiesOptions): UseStudiesResult => {
  const { data: { pages } = {}, ...rest } = useInfiniteQuery({
    queryKey: [QUERY_KEY, filters],
    queryFn: ({ pageParam = 1 }) => getStudies({ ...filters, page: pageParam }),
    getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    staleTime: 1000 * 60 * 2,
    enabled,
  });

  const studyList = getStudiesFromPages(pages);
  return { ...rest, studyList };
};

export default useStudies;

/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Alert, Box, Tabs, Tab, Container } from '@mui/material';
import ContainerCard from '@components/ContainerCard/ContainerCard';
import If from '@components/If';
import Title from '@components/Title';
import useSx from './sx';

interface CustomizationLayoutProps {
  children: [React.ReactNode, React.ReactNode];
  enabled: boolean;
}

const CustomizationLayout: React.FC<CustomizationLayoutProps> = ({ children, enabled }) => {
  const sx = useSx();
  const [tabValue, setTabValue] = useState(0);

  return (
    <Box sx={sx.root}>
      <If condition={!enabled && tabValue === 1}>
        <Alert severity="info" sx={sx.alert}>
          Maximiza tu experiencia con nuestras características mejoradas al actualizar tu paquete a "Business" y aprovecha de este y otros beneficios exclusivos.
        </Alert>
      </If>
      <Title text="Personalizar" />
      <Container maxWidth={false} disableGutters>
          <Tabs
            sx={sx.tabs}
            value={tabValue}
            textColor="primary"
            indicatorColor="primary"
            onChange={(event, newValue) => setTabValue(newValue)}
          >
            <Tab sx={sx.tab} label="Reportes" />
            <Tab sx={sx.tab} label="Sistema" />
          </Tabs>
        <ContainerCard>
          <If condition={tabValue === 0}>
            <>
              {children[0]}
            </>
          </If>
          <If condition={tabValue === 1}>
            <>
              {children[1]}     
            </>
          </If>
        </ContainerCard>
      </Container>
    </Box>
  );
};

export default CustomizationLayout;

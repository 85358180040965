import { useMsal } from '@azure/msal-react';
import accessManager from '@core/utils/productAccessManager';
import useProfile from './useProfile';

const useLogout = () => {
  const { instance } = useMsal();
  const { userUid } = useProfile();

  return () => {
    accessManager.removeProductToken(userUid);
    instance.logoutRedirect();
  };
};

export default useLogout;

import React from 'react';
import Subtitle from '@components/Subtitle';
import Title from '@components/Title';

const Header = () => {
  return (
    <>
      <Title text="Usuarios" />
      <Subtitle
        firstParagraph="Administre quién puede acceder a las aplicaciones y servicios incluidos en sus suscripciones de Evodicom."
        secondParagraph="Edite, elimine o cambie la contraseña de un usuario."
      />
    </>
  );
};

export default Header;

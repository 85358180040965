import { grey } from '@mui/material/colors';
import { createTheme, Theme } from '@mui/material/styles';
import darkTheme from './evodicom.dark.theme'

const fontFamily =
  'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"';

// these colors need to be in sync with whatever is in :root from ohif-viewer
const activeColor = '#20A5D6';
const uiGrayDark = '#16202B';
const uiGrayDarker = '#151A1F';
const uiGrayLight = '#516873';
const uiGrayLightChannel = '81 104 115';
const uiBorderColor = '#314354';
const tag = '#93b4b2';
const tagChannel = '147 180 178';
const tagText = '#000000';
const tagTextChannel = '0 0 0';
const info = '#5FA2FF';
const inputPlaceholderColorHover = '#4d5a63';

const brandPrimary = '#0E70FF';
const brandSecondary = '#1A4488';

const error = '#A70D0D';
const errorDark = '#870505';

export const shades = {
  50: '#01070C',
  100: '#060C11',
  200: '#091016',
  300: '#0C1822',
  400: '#162436',
  500: '#1c364d',
  600: '#2e4a63',
  700: '#415263',
  800: '#5b6875',
  900: '#ffffff',
};

const viewerTheme = (theme: Theme) => {
  const newTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      ...darkTheme.palette,
      mode: 'light',
      viewer: {
        uiBorderColor,
        uiGrayDark,
        uiGrayDarker,
        uiGrayLight,
        uiGrayLightChannel,
        tag,
        tagChannel,
        tagText,
        tagTextChannel,
        inputPlaceholderColorHover,
      },
      error: {
        main: '#d53737',
      },
      info: {
        main: '#337ab7',
      },
      divider: uiBorderColor,
    },
    typography: {
      fontFamily,
      fontSize: 12.25,
      button: {
        fontSize: 14,
        fontWeight: 400,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          lineHeight: 1,
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          popper: {
            backgroundColor: uiGrayDark,
            borderBottomRightRadius: theme.spacing(4),
            borderBottomLeftRadius: theme.spacing(4),
          },
          option: {
            ...theme.typography.body2,
            textTransform: 'capitalize',
            minHeight: theme.spacing(9),

            '&.Mui-focused': {
              backgroundColor: `rgba(${tagChannel} / 0.8) !important`,
              color: tagText,
            },
          },
          tag: {
            backgroundColor: tag,
            color: tagText,
            textTransform: 'capitalize',
            fontWeight: 'bold',

            '& .MuiAvatar-root': {
              backgroundColor: 'white',
              '& .MuiSvgIcon-root': {
                color: `rgba(${tagTextChannel} / 0.8)`,
              },
            },

            '& .MuiSvgIcon-root': {
              color: `rgba(${tagTextChannel} / 0.5)`,

              '&:hover': {
                color: `rgba(${tagTextChannel} / 0.8)`,
              },
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            border: 'none',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            border: 'none',
            height: '36px',
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: shades[400],
            borderColor: 'transparent',
            '&.Mui-focused .MuiInput-notchedOutline, &:hover .MuiInput-notchedOutline': {
              borderColor: 'transparent',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            border: 'none',
            height: '36px',
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: shades[400],
            borderColor: 'transparent',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
              {
                borderColor: 'transparent',
              },
            '& .MuiOutlinedInput-notchedOutline': 
              {
                border: 'none',
              },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none'
          },
          containedPrimary: {
            background: `linear-gradient(165deg, ${brandPrimary}, ${brandSecondary})`,
            color: 'white',
            '&:hover': {
              background: `linear-gradient(300deg, ${brandPrimary}, ${brandSecondary})`,
            },
            '.iconWithSpacing': {
              marginRight: '5px',
            },
            '&.Mui-disabled': {
              background: shades[300],
            },
          },
          containedSecondary: {
            background: `linear-gradient(165deg, ${shades[700]}, ${shades[600]})`,
            color: 'white',
            '&:hover': {
              background: `linear-gradient(300deg, ${shades[700]}, ${shades[600]})`,
            },
            '.iconWithSpacing': {
              marginRight: '5px',
            },
            '&.Mui-disabled': {
              background: grey[200],
              color: grey[500],
            },
          },
          containedError: {
            background: `linear-gradient(165deg, ${error}, ${errorDark})`,
            color: 'white',
            '&:hover': {
              background: `linear-gradient(300deg, ${error}, ${errorDark})`,
            },
            '.iconWithSpacing': {
              marginRight: '5px',
            },
            '&.Mui-disabled': {
              background: grey[200],
              color: grey[500],
            },
          },
          outlinedSecondary: {
            border: `1px solid ${shades[900]}`,
            color: shades[900],
            '&:hover': {
              border: `1px solid ${info}`,
              color: info,
              opacity: 0.9,
            },
            '.iconWithSpacing': {
              marginRight: '5px',
            },
            '&.Mui-disabled': {
              background: grey[200],
              color: grey[500],
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            boxShadow: 'none',

            '&:hover': {
              color: activeColor,
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
  });

  return newTheme;
};

export default viewerTheme;

/* :root

--ui-border-color: #314354; // this one is not the original, it is custom

--default-color: #9CCEF9;
--hover-color: #ffffff;
--active-color: #20A5D6;
--ui-border-color: #44626F;
--ui-border-color-dark: #3C5D80;
--ui-border-color-active: #00a4d9;
--primary-background-color: #000000;
--box-background-color: #3E5975;
--ui-yellow: #E29E4A;
--ui-sky-blue: #6FBDE2;
--ui-state-error: #FFCCCC;
--ui-state-error-border: #993333;
--ui-state-error-text: #661111;
--ui-gray-light: #516873;
--ui-gray: #263340;
--ui-gray-dark: #16202B;
--ui-gray-darker: #151A1F;
--ui-gray-darkest: #14202A;
--text-color-active: #000000;
--text-primary-color: #ffffff;
--text-secondary-color: #91b9cd;
--large-numbers-color: #6fbde2;
--text-disabled-color: #878787;
--input-background-color: #2c363f;
--input-placeholder-color--hover: #4d5a63;
--input-placeholder-color: #d3d3d3;
--table-hover-color: #2c363f;
--table-text-primary-color: #ffffff;
--table-text-secondary-color: #91b9cd;
--large-numbers-color: #6FBDE2;
--state-error: #FFCCCC;
--state-error-border: #FFCCCC;
--state-error-text: #FFCCCC;

--snackbar-success: rgba(94, 164, 0, 0.9);
--snackbar-error: rgba(236, 61, 61, 0.9);
--snackbar-warning: rgba(235, 173, 23, 0.9);
--snackbar-info: rgba(54, 156, 199, 0.9);

--datePicker-input-background: #2c363f;
--datePicker-picker-background: #9ccef9;
--datePicker-picker-disabled: #d3d3d3;
--datePicker-picker-text: #484848;
--datePicker-picker-text-disabled: #aaa;
--datePicker-picker-text-input: #fff;

--font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
*/

import useFetchUsage from '../../hooks/useFetchUsage';
import useFetchUserPlan from '../../hooks/useFetchUserPlan';
import { UserPlanWithParsedSettings } from './SubscriptionGeneralProps';

const useSubscriptionState = () => {
  const { userPlan } = useFetchUserPlan(0);
  const settings = userPlan && userPlan.plan ? JSON.parse(userPlan.plan.settingsJson) : null;
  const userPlanWithParsedSettings: UserPlanWithParsedSettings = {
    ...userPlan,
    settings,
  };
  const { usage } = useFetchUsage();

  return {
    userPlan: userPlanWithParsedSettings,
    usage,
  };
};
export default useSubscriptionState;

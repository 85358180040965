import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import If from '@components/If';
import useLogout from '@core/hooks/useLogout';
import useProfile from '@core/hooks/useProfile';
import useRestart from '@core/hooks/useRestart';
import ProfileMenu from './ProfileMenu';
import ProfileProps from './ProfileProps';
import useSx from './sx';

const Profile = ({ userFullName, profilePhotoSrc }: ProfileProps) => {
  const navigate = useNavigate();
  const { currentUserOrganization } = useProfile();
  const [anchor, setAnchor] = useState<Element | undefined>(undefined);
  const appLogout = useLogout();
  const appRestart = useRestart();
  const sx = useSx();

  const handleProfileClick = (e: SyntheticEvent) => {
    setAnchor(e.currentTarget);
  };

  const handleMenuItemClick = async (itemKey: string) => {
    switch (itemKey) {
      case 'exit':
        await appLogout();
        localStorage.removeItem("savedSelectedFilters");
        break;
      case 'switch':
        appRestart();
        break;
      case 'profile':
        navigate('/perfil');
        break;
      case 'templates':
        navigate('/templates');
        break;
      case 'preferences':
        navigate('/preferences');
        break;
      default:
        break;
    }

    setAnchor(undefined);
  };

  return (
    <>
      <Box sx={sx.root} onClick={handleProfileClick}>
        <Avatar sx={{ width: '40px', height: '40px' }} src={profilePhotoSrc || ''} />
        <If condition={!!userFullName}>
          <Box display={{ xs: 'none', md: 'flex' }} alignItems="center" gap="10px">
            <Box className="fullname-container">
              <Typography className="fullname">{userFullName}</Typography>
              <Typography
                sx={{
                  color: (theme) => theme.palette.shade?.[700],
                  display: { xs: 'none', md: 'inline-block' },
                }}
              >
                {currentUserOrganization?.organizationName}
              </Typography>
            </Box>
            <ArrowDropDownIcon
              sx={{ display: { xs: 'none', md: 'inline-block' } }}
              color="primary"
            />
          </Box>
        </If>
      </Box>
      <ProfileMenu
        anchorEl={anchor}
        onMenuItemClick={handleMenuItemClick}
        onClose={() => setAnchor(undefined)}
      />
    </>
  );
};

export default Profile;

import { AuthenticationResult, EventType, PublicClientApplication } from '@azure/msal-browser';
import authConfig from './authConfig';

const msalInstance = new PublicClientApplication(authConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.enableAccountStorageEvents();
msalInstance.addEventCallback((e) => {
  switch (e.eventType) {
    case EventType.LOGIN_SUCCESS:
    case EventType.ACQUIRE_TOKEN_SUCCESS: {
      const { account } = e.payload as AuthenticationResult;
      msalInstance.setActiveAccount(account);
      break;
    }
    case EventType.LOGIN_FAILURE:
      msalInstance.loginRedirect();
      break;
    case EventType.ACCOUNT_REMOVED:
      msalInstance.logoutRedirect({
        account: msalInstance.getActiveAccount(),
      });
      break;
    default:
      break;
  }
});

if (window) {
  const handleStorageEvent = (e: StorageEvent) => {
    if (e.key === 'logout-event' && e.newValue === 'started') {
      msalInstance.logoutRedirect({
        account: msalInstance.getActiveAccount(),
      });
    }
  };

  window.addEventListener('storage', handleStorageEvent);
}

export default msalInstance;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
});

export default useSx;

import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getReportMetrics } from '../api/getReportMetrics';

export const GET_REPORT_METRICS_QUERY_KEY = 'GetStudyReportMetrics';

const useGetReportMetrics = (
  { studyInstanceUid }: { studyInstanceUid: string },
  enabled: boolean,
) => {
  const { data: reportMetrics, ...rest } = useQuery({
    queryKey: [GET_REPORT_METRICS_QUERY_KEY],
    queryFn: () => getReportMetrics(studyInstanceUid),
    enabled,
  });

  return {
    reportMetrics,
    ...rest,
  };
};

export default useGetReportMetrics;

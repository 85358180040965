import { SubscriptionsDetails } from "../components/Subscription/SubscriptionGeneralProps";

const transformToBarChartData = ({ usage }: SubscriptionsDetails) => {
  const { studiesPerModality, totalStudiesCount} = usage;
  const data = studiesPerModality && totalStudiesCount ?  Object.keys(studiesPerModality).map(key => ({
    name: key,
    utilizado: studiesPerModality[key],
    amt: totalStudiesCount,
  })) : [];

  return data;
};

export default transformToBarChartData;
import { get } from '@core/api/baseApi';

type Response = {
  success: boolean;
  error: null | string;
  result: {
    studyReportMetricId: number;
    reportDate: string;
    studyDate: string;
    userFullName: string;
    modality: string;
  };
};

export const getReportMetrics = async (studyInstanceUid: string): Promise<Response> => {
  return await get(`/studies/${studyInstanceUid}/reports/metrics`);
};

import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import useSnackbar from '@core/hooks/useSnackbar';
import ShareCodeError from '../ShareCodeError';
import useSx from './sx';

export interface ShareCodeProps {
  shareUrl?: string;
}

const qrcodeId = 'qrcode';

const ShareCode = ({ shareUrl }: ShareCodeProps) => {
  const [hasError, setHasError] = useState(false);
  const showSnackbar = useSnackbar();
  const sx = useSx();

  const handleCopyQRCode = () => {
    const canvas = document.getElementById(qrcodeId) as HTMLCanvasElement;

    if (canvas) {
      canvas.toBlob((blob) => {
        if (blob) {
          const item = new ClipboardItem({ 'image/png': blob });

          try {
            navigator.clipboard.write([item]);
            showSnackbar({
              title: 'Código copiado',
              message: 'El código ha sido copiado',
              type: 'default',
            });
          } catch (error) {
            console.error(error);
            showSnackbar({
              title: 'Ocurrió un error',
              message: 'No se pudo copiar el código debido a un error. Intente mas tarde',
              type: 'error',
            });
          }
        }
      });
    }
  };

  useEffect(() => {
    const createCode = async () => {
      const canvas = document.getElementById('qrcode');
      if (canvas) {
        try {
          await QRCode.toCanvas(canvas, shareUrl!, { margin: 0.5, width: 160 });
        } catch (error) {
          setHasError(true);
        }
      }
    };

    setHasError(true);

    if (shareUrl?.length) {
      setHasError(false);
      createCode();
    }
  }, [shareUrl]);

  return hasError ? (
    <ShareCodeError />
  ) : (
    <Paper onClick={handleCopyQRCode} sx={sx.root}>
      <canvas id={qrcodeId} title="Click para copiar" />
      <Box className="ShareCode-overlay">
        <Tooltip placement="top" title="Click para copiar el código">
          <Box className="ShareCode-icon">
            <ContentCopyIcon />
          </Box>
        </Tooltip>
      </Box>
    </Paper>
  );
};

export default ShareCode;

import React from 'react';
import Box from '@mui/material/Box';
import ActionButton from '@components/Button/ActionButton';
import ActionsProps from './ActionsProps';

const Actions = ({ onCancel, onConfirm }: ActionsProps) => {
  return (
    <Box display="flex" justifyContent="flex-end" gap="10px" alignItems="center">
      <ActionButton onClick={onCancel} variant="outlined" color="secondary" text="Cancelar" />
      <ActionButton onClick={onConfirm} variant="contained" color="primary" text="Confirmar" />
    </Box>
  );
};

export default Actions;

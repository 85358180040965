import React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import If from '@components/If'
import Label from '@components/InputWithLabel/Label'
import Dropdown from './Dropdown'
import DropdownWithLabelProps from './DropdownWithLabelProps'
import useSx from './sx'

const DropdownWithLabel = ({label, required, options = [], helperText, ...dropdownProps}: DropdownWithLabelProps) => {
    const sx = useSx()
    
    return (
        <>
            <Box sx={sx.root}>
                <Label label={label} required={required} />
                <Dropdown {...dropdownProps}>
                    {options?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    ))}
                </Dropdown>
            </Box>
            <If condition={dropdownProps.error}>
                <Typography marginLeft={{xs: "0", md: "auto"}} color="red" lineHeight="0">
                    {helperText}
                </Typography>
            </If>
        </>
    )
}

export default DropdownWithLabel
import React from 'react';
import { Divider, Box, useMediaQuery, useTheme } from '@mui/material';
import If from '@components/If';
import SectionHeader from '@components/SectionHeader';
import Disclaimer from 'app/features/users/edit/components/Disclaimer';
import { SectionProps } from './SectionProps';
import useSx from './sx';

const Section = ({ children, title, IconComponent, description }: SectionProps) => {
  const sx = useSx();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={sx.root}>
      <If
        condition={isMobile}
        fallback={
          <>
            <SectionHeader title={title} IconComponent={IconComponent} />
            <Divider />
            <Box className="title-container">
              <Box flex="1">{children}</Box>
              <If condition={!!description}>
                <Box width="50%">
                  <Disclaimer>{description}</Disclaimer>
                </Box>
              </If>
            </Box>
          </>
        }
      >
        <>
          <SectionHeader title={title} IconComponent={IconComponent} />
          <Divider />
          <Disclaimer>{description}</Disclaimer>
          <Box sx={sx.children}>{children}</Box>
        </>
      </If>
    </Box>
  );
};

export default Section;

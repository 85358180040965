import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    ...expandDisplayFlex({ j: 'flex-start', a: 'center' }),
    width: '100%', 
    '@media (max-width:768px)': {
      px: '1rem'
    }
  },
});

export default useSx;

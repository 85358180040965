import { FunctionComponent } from 'react';
import ViewerInfoCommand from '@core/types/ViewerInfoCommand';
import RadiologistForm from 'app/features/studies/components/AddRadiologistForm/Form';
import AddTechnicianForm from 'app/features/studies/components/AddTechnicianForm/Form';
import ReferrerForm from 'app/features/studies/components/RefererForm/Form';
import TagsForm from 'app/features/studies/components/TagsForm/Form';
import UrgencyForm from 'app/features/studies/components/UrgentForm/Form';
import withSendAssignedNotification from 'app/features/studies/components/WithSendAssignedNotification/withSendAssignedNotification';
import ManagerComponentProps from '../../types/ManagerComponentProps';
import Comments from './Comments';
import HotkeysDisplay from './HotkeysDisplay';
import ReportManager from './ReportManager';
import ShareManager from './ShareManager';
import SolicitationManager from './SolicitationManager';
import StudyInformation from './StudyInformation';
import StudyReportMetrics from './StudyReportMetrics/StudyReportMetrics';

const AddRadiologistFormWithNotification = withSendAssignedNotification(RadiologistForm);
const ReferrerFormWithNotification = withSendAssignedNotification(ReferrerForm);
const AddTechnicianFormWithNotification = withSendAssignedNotification(AddTechnicianForm);
const UrgencyFormWithNotification = withSendAssignedNotification(UrgencyForm);

function buildCommandComponent(
  command?: ViewerInfoCommand,
  readOnly?: boolean,
): {
  title?: string;
  subtitle?: string;
  component: FunctionComponent<ManagerComponentProps>;
} {
  switch (command) {
    case 'upload':
      return {
        title: 'Adjuntar reporte',
        subtitle: 'Seleccione y adjunte reportes para este estudio.',
        component: ReportManager,
      };
    case 'upload-request':
      return {
        title: 'Solicitud del estudio',
        subtitle:
          'Seleccione y adjunte su solicitud para este estudio. Optativamente puede tomar una fotografía usando su cámara',
        component: SolicitationManager,
      };
    case 'referrer':
      return {
        title: 'Asignar estudio a médico referente',
        subtitle: '',
        component: ReferrerFormWithNotification,
      };
    case 'radiologist':
      return {
        title: 'Asignar estudio a médico radiólogo',
        subtitle: '',
        component: AddRadiologistFormWithNotification,
      };
    case 'share':
      return {
        title: 'Compartir estudio',
        subtitle: 'Comparta el estudio por correo o por Whatsapp®️',
        component: ShareManager,
      };
    case 'info':
      return {
        title: 'Ficha clínica',
        subtitle: 'Información del paciente y del estudio.',
        component: StudyInformation,
      };
    case 'urgency':
      return {
        title: 'Cambiar prioridad del estudio',
        subtitle: '',
        component: UrgencyFormWithNotification,
      };
    case 'tags':
      return {
        title: 'Agregar etiquetas al estudio',
        subtitle: '',
        component: TagsForm,
      };
    case 'comments':
      return {
        title: 'Comentarios',
        subtitle: '',
        component: Comments,
      };
    case 'technician':
      return {
        title: 'Asignar estudio a técnico radiólogo',
        subtitle: '',
        component: AddTechnicianFormWithNotification,
      };
    case 'metrics':
      return {
        title: 'Tiempo de reporte',
        subtitle: '',
        component: StudyReportMetrics,
      };
    case 'hotkeys':
      return {
        title: 'Atajos',
        subtitle: '',
        component: HotkeysDisplay,
      };
    case undefined:
      return { component: () => null };
    default:
      if (process.env.NODE_ENV !== 'production') {
        console.error('Invalid command:', command);
      }

      return { component: () => null };
  }
}

export default buildCommandComponent;

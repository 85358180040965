import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getOrganizationInfo } from '@core/api/getOrganizationInfo';
import { GetOrganizationInfoResponse } from '@core/types/ApiResponses';

export type UseOrganizationInfoResult = Omit<
  UseQueryResult<GetOrganizationInfoResponse, any>,
  'data'
> & {
  organizationInfo: GetOrganizationInfoResponse;
};

export const ORGANIZATION_INFO_QUERY_KEY = 'OrganizationInfo';

const useOrganizationInfo = (): UseOrganizationInfoResult => {
  const { data: organizationInfo = {}, ...rest } = useQuery({
    queryFn: getOrganizationInfo,
    queryKey: [ORGANIZATION_INFO_QUERY_KEY],
    staleTime: 1000 * 60 * 60 * 12,
    cacheTime: 1000 * 60 * 60 * 12.5 
  });

  return { organizationInfo, ...rest };
};

export default useOrganizationInfo;

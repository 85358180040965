import { useContext, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setDicomServiceAccessToken } from '@core/api/setDicomServiceAccessToken';
import { DICOM_SERVICE_SETTINGS_QUERY } from '@core/hooks/useDicomServiceToken';
import { AuthContext } from '@core/providers/AuthProvider';
import { AuthToken } from '@core/types/DicomServiceTokenResponse';
import isCustomErroHandlerResponse from '@core/utils/isCustomErrorHandlerResponse';
import { GetStudyAuthorizationPayload, getStudyAuthorization } from '../api/getStudyAuthorization';

const useGetStudyAuthorization = (orgId?: string) => {
  const queryClient = useQueryClient();

  const { organization } = useContext(AuthContext);

  useEffect(() => {
    const newOrganizationId = organization?.organizationId || orgId;

    if (newOrganizationId !== undefined) {
      sessionStorage.setItem('organizationId', newOrganizationId);
    }
  }, [organization?.organizationId, orgId]);

  const organizationId =
    organization?.organizationId || orgId || sessionStorage.getItem('organizationId') || undefined;

  const { isLoading: isAuthorizing, mutateAsync: getStudyAuthorizationAsync } = useMutation({
    mutationFn: (studyPayload: GetStudyAuthorizationPayload) => getStudyAuthorization(studyPayload),
    onSuccess: (data) => {
      if (!isCustomErroHandlerResponse(data)) {
        const token = { token: data.token };
        queryClient.setQueryData<{ success: boolean; token: AuthToken }>(
          [DICOM_SERVICE_SETTINGS_QUERY, organizationId],
          () => {
            return {
              success: true,
              token,
            };
          },
        );

        setDicomServiceAccessToken({ success: true, token });
      }
    },
  });

  return { isAuthorizing, getStudyAuthorizationAsync };
};

export default useGetStudyAuthorization;

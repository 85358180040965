import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useProfile from '@core/hooks/useProfile';
import useWidth from '@core/hooks/useWidth';
import { RoleName } from '@core/types';
import useAddTemplate from '../hooks/useAddTemplate';

const useAddTemplateState = () => {
  const navigate = useNavigate();
  const { role, hasAdminPermissions } = useProfile();
  const { addTemplate } = useAddTemplate();
  const submitButton = useRef<HTMLButtonElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const width = useWidth();
  const deviceWidth = width === 'xs' ? 'sm' : width;

  useEffect(() => {
    if (role !== RoleName.Radiologist && role !== RoleName.System) {
      navigate('/');
    }
  }, [role, navigate, hasAdminPermissions]);

  return {
    submitButton,
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    deviceWidth,
    addTemplate,
  };
};

export default useAddTemplateState;

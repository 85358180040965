import { useMutation } from '@tanstack/react-query';
import { NewUserNotificationPayload } from '@core/types/Notification';
import { sendNotification } from '../api/sendNotification';

const useSendNotification = () => {
  const { isLoading, mutateAsync: asyncSendNotification } = useMutation({
    mutationFn: (payload: NewUserNotificationPayload) => sendNotification({ ...payload}),
    mutationKey: ["SendNotification"],
 });

  return { isLoading, sendNotification: asyncSendNotification };
};

export default useSendNotification;

import React, { PropsWithChildren } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import Authenticating from './Authenticating';
import AuthenticationError from './AuthenticationError';
import OrganizationSetter from './OrganizationSetter';

const AppAuthenticationTemplate = ({ children }: PropsWithChildren<unknown>) => {
  // const sessionType = sessionStorage.getItem('sessionType');

  // const isSessionFromStudy = sessionType === 'study';

  // const interactionType = isSessionFromStudy ? InteractionType.None : InteractionType.Redirect;

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      loadingComponent={Authenticating}
      errorComponent={AuthenticationError}
    >
      <OrganizationSetter>{children}</OrganizationSetter>
    </MsalAuthenticationTemplate>
  );
};

export default AppAuthenticationTemplate;

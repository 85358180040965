import { patch } from '@core/api/baseApi';
import { UpdateStudyInformationResponse } from '@core/types';

export type Payload = {
  studyInstanceUid: string;
  patientsBirthDate: string | null;
  patientsAge: string;
  patientsEmail: string;
  patientsGivenName: string;
  patientsSurname: string;
  patientsPhoneNumber: string;
  studyDescription: string;
  referringPhysiciansName: string;
};

const updateStudyInformation = async (
  payload: Payload,
): Promise<UpdateStudyInformationResponse> => {
  const studyInstanceUid = payload.studyInstanceUid;
  return await patch(`/Studies/${studyInstanceUid}/information`, payload);
};

export default updateStudyInformation;

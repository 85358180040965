import React, { useState, useRef, useEffect } from 'react';
import useSnackbar from '@core/hooks/useSnackbar';
import { CustomError, Solicitation } from '@core/types';
import useDeleteSolicitation from 'app/features/study/hooks/useDeleteSolicitation';
import useSolicitations from 'app/features/study/hooks/useSolicitations';
import useUpdateStudySolicitation from 'app/features/study/hooks/useUpdateStudySolicitation';
import useUploadSolicitation from 'app/features/study/hooks/useUploadSolicitation';
import useSx from './sx';

const useSolicitationManagerState = (studyInstanceUid: string) => {
  const [selectedSolicitation, setSelectedSolicitation] = useState<Solicitation | null>(null)
  const [droppedFile, setDroppedFile] = useState<File | undefined>();
  const [editedImage, setEditedImage] = useState<RequestInfo | URL | null>(null);
  const [hasSavedFile, setHasSavedFile] = useState<boolean>(false);
  const [isAddingNewFile, setIsAddingNewFile] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [error, setError] = useState<CustomError | undefined>();
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const { isLoading, solicitations } = useSolicitations(studyInstanceUid);
  const { isUploading, uploadSolicitation } = useUploadSolicitation(studyInstanceUid);
  const { isDeleting, deleteSolicitationAsync } = useDeleteSolicitation(studyInstanceUid);
  const { isUpdating, updateSolicitationAsync } = useUpdateStudySolicitation(studyInstanceUid);
  const showSnackbar = useSnackbar();
  const sx = useSx();

  useEffect(() => {
    if (Array.isArray(solicitations) && solicitations.length > 0 && !droppedFile) {
      setSelectedSolicitation(selectedSolicitation || solicitations[solicitations.length - 1])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, solicitations, isAddingNewFile])

  return {
    selectedSolicitation, 
    setSelectedSolicitation,
    droppedFile,
    setDroppedFile,
    hasSavedFile,
    setHasSavedFile,
    videoRef,
    showVideo,
    setShowVideo,
    error,
    setError,
    isLoading,
    solicitations,
    isUploading,
    uploadSolicitation,
    isDeleting,
    deleteSolicitationAsync,
    showSnackbar,
    sx,
    isAddingNewFile,
    setIsAddingNewFile,
    isUpdating, 
    updateSolicitationAsync,
    editedImage, 
    setEditedImage,
    croppedImageUrl, 
    setCroppedImageUrl
  };
};

export default useSolicitationManagerState;

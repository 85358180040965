class RegexPatterns {
  // Patrón para números de teléfono (countryCode)PhoneNumber
  static PHONE_NUMBER = /\((.+)\)([0-9]+)/;

  // Constructor privado para evitar la instanciación de la clase
  constructor() {
    throw new Error('Esta es una clase de utilidades y no debe ser instanciada.');
  }
}

export default RegexPatterns;

function getLastTextNode(root: Node): Node | null {
  let lastTextNode: Node | null = null;
  const walker = document.createTreeWalker(
    root,
    NodeFilter.SHOW_TEXT,
    null, // No se necesita un filtro específico
  );

  let currentNode: Node | null = walker.nextNode();
  while (currentNode) {
    lastTextNode = currentNode;
    currentNode = walker.nextNode();
  }

  return lastTextNode;
}

export default getLastTextNode;

import { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import configureRestApi from './configureRestApi';

const BASE_URL = sessionStorage.getItem('serverUrl') || '';

if (!BASE_URL) {
  console.error('Dicom web server url not set in configuration. Please verify');
}

declare global {
  interface Window { store: any }
}

const setHeadersForDicomWebInterceptor = (value: InternalAxiosRequestConfig<any>) => {
  const updatedConfig: InternalAxiosRequestConfig<any> = { ...value };
  const reduxState = window.store.getState()
  const accessToken = reduxState?.oidc?.user?.access_token
  if (accessToken) {
    updatedConfig.headers.Authorization = `Bearer ${accessToken}`;
  }

  return updatedConfig;
};

export const { get, post, put, patch, remove } = configureRestApi(BASE_URL, setHeadersForDicomWebInterceptor);

import React from 'react';
import { Container, Paper, Stack } from '@mui/material';
import If from '@components/If';
import calculateElapsedTimeBetweenTwoDates from '@core/utils/calculateElapsedTimeBetweenTwoDates';
import formatToSpanishDate from '@core/utils/formatDateToSpanishDate';
import useGetReportMetrics from 'app/features/study/hooks/useGetReportMetrics';
import ManagerComponentProps from 'app/features/study/types/ManagerComponentProps';
import LoadingIndicator from '../StudyInformation/LoadingIndicator';
import InputWithLabel from './InputWithLabel';
import useSx from './sx';

const StudyReportMetrics = ({ studyInstanceUid }: ManagerComponentProps) => {
  const { reportMetrics, isLoading: isStudyReportMetricLoading } = useGetReportMetrics(
    { studyInstanceUid },
    true,
  );
  const sx = useSx();

  // The endpoint returns 0 as the id if there is no existing report in the database for the given studyKey.
  // The returned object will still include the study date and modality information.
  const isReportAlreadyUploaded = reportMetrics?.result?.studyReportMetricId !== 0;
  const studyDate = reportMetrics?.result?.studyDate
    ? formatToSpanishDate(reportMetrics?.result?.studyDate, { full: true })
    : 'Pendiente';
  const modality = reportMetrics?.result?.modality || '';
  const reportUploadDate =
    isReportAlreadyUploaded && reportMetrics?.result?.reportDate
      ? formatToSpanishDate(reportMetrics?.result?.reportDate, { full: true })
      : 'Pendiente';

  const elapsedTime = isReportAlreadyUploaded
    ? calculateElapsedTimeBetweenTwoDates(
        reportMetrics?.result?.studyDate || '',
        reportMetrics?.result?.reportDate || '',
      )
    : 'Pendiente';

  const userReport = isReportAlreadyUploaded
    ? reportMetrics?.result?.userFullName || ''
    : 'Pendiente';

  return (
    <Container maxWidth="md" disableGutters sx={sx.root}>
      <Paper elevation={0}>
        <If condition={!isStudyReportMetricLoading} fallback={<LoadingIndicator />}>
          <If
            condition={!!(studyInstanceUid || reportMetrics)}
            fallback={<>No hay información del reporte disponible</>}
          >
            <Stack gap="20px">
              <InputWithLabel label="Modalidad" name="modalidad" value={modality} />
              <InputWithLabel label="Fecha y hora del estudio" name="studyDate" value={studyDate} />
              <InputWithLabel
                label="Fecha y hora del primer reporte"
                name="uploadedReportDate"
                value={reportUploadDate}
              />
              <InputWithLabel label="Tiempo transcurrido" name="elapsedTime" value={elapsedTime} />
              <InputWithLabel
                label="Usuario del primer reporte"
                name="userUploadedReport"
                value={userReport}
              />
            </Stack>
          </If>
        </If>
      </Paper>
    </Container>
  );
};

export default StudyReportMetrics;

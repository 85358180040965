import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY as GET_USER_QUERY_KEY } from '@core/hooks/useGetUser';
import { User } from '@core/types/User';
import { updateProfilePhoto } from '../api/updateProfilePhoto';

export const UPDATE_PROFILE_PHOTO_QUERY_KEY = ['profilePhoto'];

const useUpdateProfilePhoto = (userUid: string, organization: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateProfilePhotoAsync, ...rest } = useMutation({
    mutationKey: UPDATE_PROFILE_PHOTO_QUERY_KEY,
    mutationFn: updateProfilePhoto,
    onSuccess: (updated) => {
      queryClient.setQueryData<User>([GET_USER_QUERY_KEY, userUid, organization], (data) => {
        if (data && updated.success) {
          data.profilePhoto = `${updated.blobUri}?timestamp=${Date.now()}`;
        }

        return data;
      });
    },
  });

  return { updateProfilePhotoAsync, ...rest };
};

export default useUpdateProfilePhoto;

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import getUser from '../api/getUser';
import { User } from '../types/User';

/* eslint-disable @typescript-eslint/indent */
export type UseGetUserResult = Omit<UseQueryResult<User, any>, 'data'> & { user: User };
/* eslint-enable @typescript-eslint/indent */

export const QUERY_KEY = 'GetUser';

const useGetUser = (
  userUid: string | undefined,
  organization?: string,
  enabled = true,
): UseGetUserResult => {
  const { data: user = {}, ...rest } = useQuery({
    queryFn: () => getUser(userUid),
    queryKey: [QUERY_KEY, userUid, organization],
    staleTime: 1000 * 60 * 60 * 12,
    cacheTime: 1000 * 60 * 60 * 12.5,
    enabled,
  });

  return { user, ...rest };
};

export default useGetUser;

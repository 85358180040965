import React, { useState } from 'react'
import useProfile from '@core/hooks/useProfile';
import getSettingsItems from '../../api/getSettingsItems';

const useSettingsState = () => {
    const { hasAdminPermissions, hasOwnerPermissions } = useProfile()
    const [anchor, setAnchor] = useState<Element | undefined>(undefined);
    const allSettingsItems = getSettingsItems()
    
    const settingsItems = allSettingsItems
      .filter((setting) => 
        setting.enabled && setting.sections.dropdown 
        && ((hasAdminPermissions && setting.adminOnly && !setting.ownerOnly) 
        || (!hasAdminPermissions && !setting.adminOnly)
        || (hasOwnerPermissions && setting.ownerOnly))
      )

    return  {
        anchor,
        setAnchor,
        settingsItems
    }
}

export default useSettingsState
import React, { useState } from 'react';
import useTranslator from '@core/hooks/useTranslator';

const useTranslatorButtonState = () => {
  const {postTranslateAsync, isLoading: isTranslating} = useTranslator();
  const [warningDialogOpen, setWarningDialogOpen] = useState<boolean>(false);

    return  {
      postTranslateAsync,
      isTranslating,
      warningDialogOpen, 
      setWarningDialogOpen,
    }
}

export default useTranslatorButtonState;

import { publish } from '@core/constants/customEvents';
import { ButtonFactory } from './buttonRegistryFactory';

const translateReport: ButtonFactory = () => {
  return {
    key: 'translateReport',
    unlocalizedText: 'Translate Report',
    iconName: 'MicrosoftTranslatorLogo',
    onClick: async () => {
      publish('evodicom.report.translate', undefined);
    },
  };
};

export default translateReport;

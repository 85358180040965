import SxDef from '@core/types/SxDef';
import curry from '@core/utils/curryExpandableProp';
import { expandBorder, expandBorderColor, expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    ...expandDisplayFlex({ a: 'center' }),
    pr: '1.5rem',
    cursor: 'pointer',
    '.icon-button': {
      color: (theme) =>
        theme.palette.mode === 'dark' ? theme.palette.shade?.[900] : theme.palette.shade?.[500],
      cursor: 'pointer',
      padding: '4px',
      ...expandBorder('1px solid'),
      ...expandBorderColor('shade.800'),
      '& svg': {
        fontSize: 24,
      },
    },
    '.fullname-container': {
      ...curry('margin')('0 0 0 12px'),
      color: (theme) =>
        theme.palette.mode === 'dark' ? theme.palette.shade?.[900] : theme.palette.shade?.[500],
    },
    '.fullname': {
      display: { xs: 'none', lg: 'flex' },
      alignItems: 'center',
    },
  },
});

export default useSx;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    '& .MuiTableCell-root, & th': {
      width: { xs: '200px', md: '33%' },
    },
  },
});

export default useSx;

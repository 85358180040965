import { darken } from '@mui/material';
import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    '.MuiBadge-badge': {
      backgroundColor: (theme) => theme.palette.primary.main,
      height: '50px',
      width: '50px',
      borderRadius: '50%',
      cursor: 'pointer',
      transition: 'all .3s ease',
      ':hover': {
        backgroundColor: (theme) => darken(theme.palette.primary.main, 0.7),
      },
    },
    '.avatar': { width: '200px', height: '200px' },
  },
});

export default useSx;

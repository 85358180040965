import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Title from '@components/Title';
import useGetNotifications from '@core/hooks/useGetNotifications';
import useNotificationClick from '@core/hooks/useNotificationClick';
import useWidth from '@core/hooks/useWidth';
import { NotificationMessage } from '@core/types';
import useFilters from '../hooks/useFilters';
import Actions from './Actions';
import Table from './Table';
import useSx from './sx';

const Notifications = () => {
  const onNotificationClick = useNotificationClick();
  const [filters] = useFilters();
  const width = useWidth();
  const sx = useSx();
  const deviceWidth = width === 'xs' ? 'sm' : width;
  const { notifications } = useGetNotifications(
    {
      filters: {
        Page: +(filters.Page || '0'),
        PageSize: filters.PageSize,
        IsSeen: filters.Status,
        SortOrder: 'DESC',
      },
    },
    true,
  );

  const [selectedNotificationsIds, setSelectedNotificationsIds] = useState<
    Map<string, NotificationMessage>
  >(new Map([]));

  const onNotificationChecked = (notification: NotificationMessage, checked: boolean) => {
    const tempNotifications = new Map(selectedNotificationsIds);
    if (checked) {
      if (!tempNotifications.get(notification.notificationMessageId)) {
        tempNotifications.set(notification.notificationMessageId, notification);
      }

      setSelectedNotificationsIds(tempNotifications);
    } else {
      tempNotifications.delete(notification.notificationMessageId);
      setSelectedNotificationsIds(tempNotifications);
    }
  };

  useEffect(() => {
    //Cleaning notifications selected when response change
    const selectedNotificationsCopy = new Map<string, NotificationMessage>();
    notifications?.items.map((notification) => {
      const notificationDisplayed = selectedNotificationsIds.get(
        notification.notificationMessageId,
      );

      if (notificationDisplayed) {
        selectedNotificationsCopy.set(notification.notificationMessageId, notification);
      }
    });

    setSelectedNotificationsIds(selectedNotificationsCopy);
  }, [notifications]);

  return (
    <Container sx={sx.root} maxWidth={deviceWidth} disableGutters>
      <Title text="Notificaciones" />
      <Actions
        notifications={notifications?.items || []}
        selectedNotificationsIds={selectedNotificationsIds}
        setSelectedNotificationsIds={setSelectedNotificationsIds}
      />
      <Table
        pageCount={notifications?.pageCount || 0}
        totalCount={notifications?.totalCount || 0}
        onTableCellCheck={onNotificationChecked}
        notifications={notifications?.items || []}
        selectedNotificationsIds={selectedNotificationsIds}
        onNotificationClick={onNotificationClick}
      />
    </Container>
  );
};

export default Notifications;

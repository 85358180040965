import { useMutation, useQueryClient } from '@tanstack/react-query';
import setUserPreferences from '@core/api/setUserPreferences';
import isErrorResponse from '@core/utils/isErrorResponse';
import { QUERY_KEY as USER_PREFERENCES_QUERY_KEY } from './useGetUserPreferences';

const useSetUserSettings = () => {
  const queryClient = useQueryClient();
  const { isLoading: isUploading, mutateAsync: setUserSettings } = useMutation({
    mutationFn: (payload: string) => setUserPreferences(payload),
    onMutate: (newSettings) => {
      queryClient.cancelQueries([USER_PREFERENCES_QUERY_KEY]);
      const previousSettings = queryClient.getQueryData([USER_PREFERENCES_QUERY_KEY]);
      queryClient.setQueryData([USER_PREFERENCES_QUERY_KEY], () => ({
        ...(previousSettings || {}),
        settings: newSettings,
      }));
      return { previousSettings };
    },
    onError: (err, newSetting, context) => {
      console.log({ err }, 'error', context);
      queryClient.setQueryData([USER_PREFERENCES_QUERY_KEY], context?.previousSettings);
    },
    // Always refetch after error or success:
    onSettled: (data, error, variables, context) => {
      if (isErrorResponse(data)) {
        queryClient.setQueryData([USER_PREFERENCES_QUERY_KEY], context?.previousSettings);
        return;
      }
      queryClient.invalidateQueries({ queryKey: [USER_PREFERENCES_QUERY_KEY] });
    },
  });

  return { isUploading, setUserSettings };
};

export default useSetUserSettings;

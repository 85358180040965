function getAbsoluteHeight(el: string | HTMLElement): number {
  // Obtener el nodo DOM si se pasa una cadena
  const element = typeof el === 'string' ? (document.querySelector(el) as HTMLElement) : el;

  if (!element) {
    throw new Error('Element not found');
  }

  const styles = window.getComputedStyle(element);
  const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);

  return Math.ceil(element.offsetHeight + margin);
}

export default getAbsoluteHeight;

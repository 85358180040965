import { put } from '@core/api/baseApi';

export const updateSolicitation = async (
  payload: FormData,
  studyInstanceUid: string,
  attachmentKey: number | undefined,
): Promise<void> => {
  await put(`/studies/${studyInstanceUid}/solicitation/${attachmentKey}`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

import React from 'react';
import Typography from '@mui/material/Typography';
import If from '@components/If';
import { SubtitleProps } from './SubtitleProps';
import useSx from './sx';

const Subtitle = ({ firstParagraph, secondParagraph }: SubtitleProps) => {
  const sx = useSx();

  return (
    <Typography sx={sx.root} fontWeight={200} component="p">
      {firstParagraph}
      <If condition={!!secondParagraph}>
        <br />
        {secondParagraph}
      </If>
    </Typography>
  );
};

export default Subtitle;

import React from 'react';
import { GetStudyResponse } from '@core/types';
import useAddTag from '../../../../hooks/useAddTag';
import useRemoveTag from '../../../../hooks/useRemoveTag';
import TagControl, { ADD_ITEM_TOKEN, TagControlChangeEvent } from './TagControl';

export interface TagHandlerProps {
  study: GetStudyResponse;
  readOnly?: boolean;
}

const TagHandler = ({ study, readOnly = false }: TagHandlerProps) => {
  const { studyInstanceUid, tags } = study;
  const { addTag } = useAddTag(studyInstanceUid);
  const { removeTag } = useRemoveTag(studyInstanceUid);

  const handleChange: TagControlChangeEvent = async (event, value, reason, details) => {
    if (!details?.option) {
      return;
    }

    switch (reason) {
      case 'removeOption': {
        await removeTag(details.option);
        break;
      }
      case 'createOption': {
        await addTag(details.option);
        break;
      }
      case 'selectOption':
      default:
        if (details?.option) {
          const tagTitle = details.option.startsWith(ADD_ITEM_TOKEN)
            ? details.option.replace(ADD_ITEM_TOKEN, '').replaceAll('"', '')
            : details.option;

          await addTag(tagTitle);
        }
        break;
    }
  };

  return <TagControl tags={tags} readOnly={readOnly} onChange={handleChange} />;
};

export default TagHandler;

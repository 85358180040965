import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  switchButton: (theme) => ({
    '& .MuiButtonBase-root': {
      background: theme.palette.shade?.[400],
    },
    '& .MuiButtonBase-root:disabled': {
      background: theme.palette.primary.main,
    },
  }),
  osSelector: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    marginBottom: '20px',
  },
  hotkeysContainer: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr',
    },
    justifyItems: {
      xs: 'center',
      md: 'unset',
    },
    gap: '20px 30px',
    maxHeight: '70vh',
  },
  hotkey: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
    width: '300px',
  },
  keyDisplay: {
    width: '125px',
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
});

export default useSx;

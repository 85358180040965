import { get } from '@core/api/baseApi';
import { GetStudiesResponse } from '@core/types';

export interface GetStudiesRequest {
  page?: number;
  search?: string | null;
  branchId?: number | null;
  modalities?: string[];
  period?: number;
  isUrgent?: 'true' | 'false';
  radiologistIds?: string[];
  isReported?: 'true' | 'false';
  isShared?: Array<'true' | 'false' | string>;
  branchIds?: string[];
  tags?: string[];
  dateRange?: string[];
}

function stringIsBool(str: string): boolean {
  return str === 'true' || str === 'false';
}

const getStudies = async (request: GetStudiesRequest): Promise<GetStudiesResponse> => {
  const queryString = Object.entries(request)
    .filter(([_, value]) => value !== undefined && value !== null)
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((item) =>
          key === 'isShared' && !stringIsBool(item) ? `doctorIds=${item}` : `${key}=${item}`,
        );
      }
      return key === 'isShared' && !stringIsBool(value) ? `doctorIds=${value}` : `${key}=${value}`;
    })
    .join('&');

  return await get(`/studies?${queryString}`);
};

export default getStudies;

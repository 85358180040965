import { RibbonButton } from 'roosterjs-react';
import { publish } from '@core/constants/customEvents';
import { ButtonFactory } from './buttonRegistryFactory';

const createTemplatesRibbonButton: ButtonFactory = ({ templates }): RibbonButton<string> => {
  return {
    key: 'buttonTemplates',
    unlocalizedText: 'Select Template',
    iconName: 'FieldChanged',
    dropDownMenu: {
      items: templates.reduce((prev, curr) => {
        return { ...prev, [curr.templateId]: curr.title };
      }, {}) as Record<string, string>,
      allowLivePreview: true,
    },
    onClick: async (editor, templateId) => {
      publish('evodicom.report.changeTemplate', { templateId });
    },
  };
};

export default createTemplatesRibbonButton;

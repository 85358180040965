import createDoctorNameParagraph from 'app/features/reports/add/utils/createDoctorNameParagraph';
import createProfessionalInfoParagraphs from 'app/features/reports/add/utils/createProfessionalInfoParagraphs';

export default function replaceProfessionalInfo(
  page: HTMLDivElement,
  professionalFullName?: string,
  professionalInfo?: string,
) {
  const profesionalInfoPlaceholders = page.querySelectorAll(
    '.ProfessionalInfoPlaceholder',
  ) as NodeListOf<HTMLElement>;

  profesionalInfoPlaceholders.forEach((elem: HTMLElement) => {
    if (professionalFullName || professionalInfo) {
      const tempDiv = document.createElement('div');
      if (professionalFullName) {
        tempDiv.appendChild(createDoctorNameParagraph(professionalFullName));
      }
      if (professionalInfo) {
        createProfessionalInfoParagraphs(professionalInfo).forEach((paragraph) => {
          tempDiv.appendChild(paragraph);
        });
      }
      elem.replaceWith(tempDiv);
    } else {
      elem.remove();
    }
  });
}

import React from 'react';
import { DropzoneInputProps } from 'react-dropzone';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import HoverableView from '@components/Views/HoverableView';
import TouchscreenView from '@components/Views/TouchscreenView';
import useSx from './sx';

export interface ReadyStateProps {
  inputProps: DropzoneInputProps;
  isDragActive: boolean;
  isDragReject: boolean;
}

const ReadyState = ({ inputProps, isDragActive, isDragReject }: ReadyStateProps) => {
  const sx = useSx(isDragActive, isDragReject);

  return (
    <Paper id="ReadyState-root" sx={sx.root}>
      <CloudQueueIcon />
      <Typography>
        {isDragReject ? (
          <>Solo archivos del tipo PDF pueden ser adjuntados.</>
        ) : (
          <>
            <HoverableView>
              <Box component="span">Seleccione</Box>&nbsp;un archivo o arrastre aqu&iacute; su foto.
            </HoverableView>
            <TouchscreenView>
              <Box component="span">Toque</Box>&nbsp;aqu&iacute; para seleccionar su foto.
            </TouchscreenView>
          </>
        )}
      </Typography>
      <input {...inputProps} />
    </Paper>
  );
};

export default ReadyState;

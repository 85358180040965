import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  buttonAction: {
    width: '20%',
    '@media (max-width: 768px)': {
      width: '100%',
      marginBottom: '1rem',
    },
  },
  buttonContainer: {
    width: {
      lg: '100%',
      xl: '70%'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
      width: '100%',
      marginTop: '2rem',
    },
  },
  subscriptionDetailContainer: {
    position: 'relative',
    marginTop: '2rem',
  },
  table: {
    width: '100%',
    overflow: 'hidden',
    borderRadius: '12px',
    '& th, & td': {
      padding: '1rem',
      width: '33.33%',
      textAlign: 'center',
    },
    '& tr:last-child td': {
      borderBottom: 'none',
    },
  },
  title: {
    display: 'inline-block',
    position: 'relative',
    paddingBottom: '1rem',
    marginBottom: '1rem',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      width: 'auto',
      height: '3px',
      background: (theme) => theme.palette.primary.dark,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
  },
});

export default useSx;

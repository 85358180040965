import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { GetUserPlanResponse } from '@core/types';
import { getUserPlan } from '../api/changeSuscription';

const QUERY_KEY = 'userPlan';

/* eslint-disable @typescript-eslint/indent */
export type UseFetchUserPlanResult = Omit<
  UseQueryResult<GetUserPlanResponse | undefined, any>,
  'data'
> & { userPlan: GetUserPlanResponse | undefined };
/* eslint-enable @typescript-eslint/indent */

const useFetchUserPlan = (triggerFetch: number): UseFetchUserPlanResult => {
  const { data: userPlan, ...rest } = useQuery(
    [QUERY_KEY, triggerFetch],
    () => getUserPlan(),
    {
      staleTime: 1000 * 60 * 60 * 12, // 12 hours
      cacheTime: 1000 * 60 * 60 * 12.5, // 12.5 hours
      onError: (error) => {
        console.error('Failed to fetch user plan:', error);
      }
    }
  );

  return {
    userPlan,
    ...rest,
  };
};

export default useFetchUserPlan;

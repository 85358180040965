import React from 'react';

/* props: MsalAuthenticationResult */

const AuthenticationError = (props: any) => {
  console.error('Authentication error:', props);
  return <div>Error happened</div>;
};

export default AuthenticationError;

import axios from 'axios';
import { GetUserProductAccessResponse } from '@core/types';

function newAxiosInstance(accessToken: string) {
  const BASE_URL = process.env.REACT_APP_BASE_API_URL ?? '';

  if (!BASE_URL) {
    console.error('BASE_URL not set in configuration file. Please verify');
  }

  return axios.create({ baseURL: BASE_URL, headers: { Authorization: `Bearer ${accessToken}` } });
}

const requestProductAccess = async (
  organizationProductId: string,
  accessToken: string,
): Promise<GetUserProductAccessResponse | null> => {
  try {
    const instance = newAxiosInstance(accessToken);
    const response = await instance.post<{ accessToken: string }>(
      `/user/organizations/${organizationProductId}/access`,
    );

    return response.data;
  } catch (err: unknown) {
    return null;
  }
};

export default requestProductAccess;

import { remove } from '@core/api/baseApi';
import { RemoveTagResponse } from '@core/types';

export interface RemoveStudyTagRequest {
  studyInstanceUid: string;
  tagTitle: string;
}

const deleteStudyTag = async ({
  studyInstanceUid,
  tagTitle,
}: RemoveStudyTagRequest): Promise<RemoveTagResponse> =>
  await remove(`/studies/${studyInstanceUid}/tags/${tagTitle}`);

export default deleteStudyTag;

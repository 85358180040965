/* eslint-disable @typescript-eslint/lines-between-class-members */
export const genders = ['Female', 'Male', 'Unknown'] as const

export type Gender = typeof genders[number]

export const genderNameMap = new Map<Gender, string>([
    ['Female', 'Femenino'],
    ['Male', 'Masculino'],
    ['Unknown', 'Sin especificar']
  ]);
  
  export const genderToDisplayName = (gender: Gender) =>
    genderNameMap.has(gender) ? genderNameMap.get(gender) : genderNameMap.get('Unknown');
  
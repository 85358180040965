import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    backgroundColor: 'transparent',
    width: '90%',
    margin: '1rem auto',
    borderRadius: '25px',
    height: '40px',
    '.MuiListItemButton-root': {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: (theme) => theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.shade?.[500],
      ':hover': { 
        backgroundColor: 'transparent',
        color: (theme) => theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.shade?.[300], 
      },
      '.MuiListItemIcon-root': {
        minWidth: 0,
        color: (theme) => theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.shade?.[500],
        ':hover': {
          color: (theme) => theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.shade?.[300],
        }
      },
      '.MuiSvgIcon-root': {
        fontSize: '1.8rem',
      },
      '.MuiListItemText-root': {
        flex: 0,
        '.MuiTypography-root': { fontWeight: 200, fontSize: '0.8rem' },
      },
    },
  },
  activeItem: {
    background: (theme) => `linear-gradient(180deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})` ?? '#375794',
    '.MuiListItemButton-root': {
      color: (theme) =>
        theme.palette.mode === 'light'
          ? theme.palette.secondary.main
          : theme.palette.secondary.contrastText,
      '.MuiListItemIcon-root': {
        color: (theme) => theme.palette.secondary.contrastText,
      },
      '.MuiListItemText-root': {
        flex: 0,
        '.MuiTypography-root': { 
          fontWeight: 200, 
          fontSize: '0.8rem',
          color: (theme) => theme.palette.secondary.contrastText,
        },
      },
    },
  },
  icon: {
    marginRight: '1rem',
    fontSize: '1.8rem'
  }
});

export default useSx;

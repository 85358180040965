import { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import getApiAccessToken from '../../utils/getApiAccessToken';
import getProductAccessToken from '../../utils/getProductAccessToken';

const ORG_HEADER = 'Organization-Authorization';

const setHeadersRequestInterceptor = async (value: InternalAxiosRequestConfig<any>) => {
  const updatedConfig: InternalAxiosRequestConfig<any> = { ...value };
  const accessToken = await getApiAccessToken();
  const productAccessToken = (await getProductAccessToken()) ?? '';

  updatedConfig.headers = updatedConfig.headers ?? new AxiosHeaders();
  updatedConfig.headers.Authorization = `Bearer ${accessToken}`;

  if (productAccessToken.length > 0) {
    updatedConfig.headers[ORG_HEADER] = `Bearer ${productAccessToken}`;
  }

  return updatedConfig;
};

export default setHeadersRequestInterceptor;

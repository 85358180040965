import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY as GET_USER_QUERY_KEY } from '@core/hooks/useGetUser';
import { deleteProfilePhoto } from '../api/deleteProfilePhoto';

export const DELETE_PROFILE_PHOTO_QUERY_KEY = ['deleteProfilePhoto'];

const useDeleteProfilePhoto = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: deleteProfilePhotoAsync, ...rest } = useMutation({
    mutationKey: DELETE_PROFILE_PHOTO_QUERY_KEY,
    mutationFn: deleteProfilePhoto,
    onSuccess: () => {
      queryClient.invalidateQueries([GET_USER_QUERY_KEY]);
    },
  });

  return { deleteProfilePhotoAsync, ...rest };
};

export default useDeleteProfilePhoto;

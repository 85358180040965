import React, { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import AppAuthenticationTemplate from '@components/AppAuthenticationTemplate';
import SettingsLayout from '@components/Layout/SettingsLayout';
import ProtectedRoute from '@components/ProtectedRoute';
import useIsAuthenticated from '@core/hooks/useIsAuthenticated';
import { hideStartLoader } from '@core/utils/startLoader';

const SettingsRoot = () => {
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    hideStartLoader({ isAuthenticated: isAuthenticated ?? false });
  }, [isAuthenticated]);

  return (
    <AppAuthenticationTemplate>
      <ProtectedRoute allowedRoles={[]} isAdminAllowed isOwnerAllowed>
        <SettingsLayout>
          <Suspense fallback={<Backdrop open>Cargando</Backdrop>}>
            <Outlet />
          </Suspense>
        </SettingsLayout>
      </ProtectedRoute>
    </AppAuthenticationTemplate>
  );
};

export default SettingsRoot;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        width: '100%'
    },
    alert: {
        marginBottom: '2rem'
    },
    tabs: {
        marginTop: '1rem',
    },
    tab: {
        textTransform: 'none',
    }
});

export default useSx;

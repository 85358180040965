import React, { useEffect, useMemo } from 'react'
import debounce from 'lodash.debounce'
import Input from '@mui/material/Input'
import SearchIcon from './SearchIcon'
import SearchInputProps from './SearchInputProps'
import useSx from './sx'

const SearchInput = <T extends object,>({ query, setFilter, removeFilter, filters, sx = {}, ...props }: SearchInputProps<T>) => {

    const customSx = useSx()
    const combinedStyles = {
      ...customSx.root,
      ...sx
    }

    const handleChange = (value: string): void => {
      return !!value ? setFilter(query, value) : removeFilter(query);
    };
    
    const handleChangeDebounced = useMemo(
      () => debounce(handleChange, 300),
      // we are creating a closure when using debounce thus we
      // need to make filters a dependency
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [filters],
    );
  
    useEffect(() => {
      return handleChangeDebounced.cancel;
    }, [handleChangeDebounced]);

    return (
        <Input
          sx={combinedStyles}
          onChange={($e) => handleChangeDebounced($e.target.value)}
          startAdornment={<SearchIcon />}
          {...props}
        />
    )
}

export default SearchInput
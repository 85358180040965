import { useMsal } from '@azure/msal-react';

const useUserUid = () => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  if (account == null) {
    console.error('Should not call useUserUid on an unauthenticated user');
  }

  return account?.idTokenClaims?.sub;
};

export default useUserUid;

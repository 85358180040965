import React, { useEffect, useRef } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import SaveIcon from '@mui/icons-material/Save';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Grid, Card, Box } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import IconButton from '@components/Button/IconButton';
import If from '@components/If';
import ViewerButton from '@components/ViewerButton';
import useProfile from '@core/hooks/useProfile';
import { RoleName } from '@core/types';
import rotateImageAsync from '@core/utils/rotateImage';
import FilesDisplay from '../FilesDisplay/FilesDisplay';
import { SelectedFileDisplayProps } from './SelectedFileDisplayProps';
import useSx from './sx';
import useSelectedFileDisplay from './useSelectedFileDisplay';
import 'react-image-crop/dist/ReactCrop.css';
// eslint-disable-next-line import/order
import loadImageAsync from '@core/utils/loadImageAsync';

const initialZoom = 0.5;
const initialRotation = 0;
const initialPosition = { x: 0, y: 0 };

const SelectedFileDisplay = ({
  fileInfo,
  isUploading,
  onClear,
  isAddingNewFile,
  setEditedImage,
  solicitations,
  onSelect,
  selectedSolicitation,
  onAddNewFile,
  onCancel,
  onAccept,
  onDelete,
  isLoading,
  isDeleting,
}: SelectedFileDisplayProps) => {
  const sx = useSx(!Array.isArray(solicitations) || solicitations?.length === 1);
  const {
    imagePreviewUrl,
    setImagePreviewUrl,
    rotation,
    setRotation,
    zoom,
    setZoom,
    dragging,
    setDragging,
    position,
    setPosition,
    startDragPosition,
    setStartDragPosition,
  } = useSelectedFileDisplay();
  const { role } = useProfile();
  const imageRef = useRef<HTMLImageElement>(null);

  const resetTransformations = () => {
    setZoom(initialZoom);
    setRotation(initialRotation);
    setPosition(initialPosition);
    setImagePreviewUrl(selectedSolicitation?.blobUri || '');
    setRotation(0);
  };

  const onSave = () => {
    setZoom(initialZoom);
    setPosition(initialPosition);
    setRotation(0);
    onAccept();
  };

  const startDrag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setDragging(true);
    setStartDragPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
    e.preventDefault(); // Prevent text selection during drag
  };

  const onDrag = (e: MouseEvent) => {
    if (!dragging) return;
    const newX = e.clientX - startDragPosition.x;
    const newY = e.clientY - startDragPosition.y;
    setPosition({ x: newX, y: newY });
    e.preventDefault();
  };

  const endDrag = () => {
    setDragging(false);
  };

  const zoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2));
  };

  // Función para disminuir el zoom
  const zoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5));
  };

  const rotateImage = async () => {
    const newImg = await loadImageAsync(imageRef.current!.src);
    const src = await rotateImageAsync(newImg, 90);
    setRotation(rotation + 1);
    setEditedImage(src);
    setImagePreviewUrl(src);
  };

  const selectedSolicitationIndex = solicitations?.findIndex(
    (solicitation) => solicitation.attachmentKey === selectedSolicitation?.attachmentKey,
  );

  const handlePrevious = () => {
    if (
      Array.isArray(solicitations) &&
      selectedSolicitationIndex &&
      selectedSolicitationIndex > 0
    ) {
      onSelect(solicitations[selectedSolicitationIndex - 1]);
    }
  };

  const handleNext = () => {
    if (
      Array.isArray(solicitations) &&
      selectedSolicitationIndex !== undefined &&
      selectedSolicitationIndex < solicitations.length - 1
    ) {
      onSelect(solicitations[selectedSolicitationIndex + 1]);
    }
  };

  const withResetTransformationsCallback =
    <T,>(cb: (args: T) => void) =>
    (args: T) => {
      resetTransformations();
      cb(args);
    };

  const handleOnDelete = () => {
    onDelete();
    resetTransformations();
    setImagePreviewUrl(solicitations?.[solicitations.length - 1].blobUri || null);
  };

  useEffect(() => {
    if (dragging) {
      window.addEventListener('mousemove', onDrag);
      window.addEventListener('mouseup', endDrag);
    } else {
      window.removeEventListener('mousemove', onDrag);
      window.removeEventListener('mouseup', endDrag);
    }
    return () => {
      window.removeEventListener('mousemove', onDrag);
      window.removeEventListener('mouseup', endDrag);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragging, onDrag]);

  useEffect(() => {
    if (fileInfo instanceof File) {
      const url = URL.createObjectURL(fileInfo);
      setImagePreviewUrl(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    } else if (Array.isArray(solicitations) && solicitations.length > 0) {
      setImagePreviewUrl(imagePreviewUrl || solicitations?.[0].blobUri || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileInfo]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        resetTransformations();
        event.preventDefault();
      }
    };

    // Add the event listener
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noSolicitations = !Array.isArray(solicitations) || solicitations?.length === 0;
  const isFirstSolicitation = selectedSolicitationIndex === 0;
  const isMaxSolicitationsReached = !Array.isArray(solicitations) || solicitations?.length >= 6;
  const allowedRoles = [RoleName.Radiologist, RoleName.Technician, RoleName.System];
  const canSeeButtons = allowedRoles.includes(role || RoleName.Anonymous);

  const isLastItem =
    Array.isArray(solicitations) &&
    !!selectedSolicitationIndex &&
    selectedSolicitationIndex >= solicitations?.length - 1;

  const isEditingUploadingItem = selectedSolicitationIndex === -1;
  const isEditingImage = rotation !== 0;

  return (
    <>
      <Box className="SelectedFileDisplay-root" sx={sx.root}>
        <Card variant="outlined">
          <CardContent sx={{ width: '100%', pt: 0 }}>
            <Box display="flex" flexDirection="column" width="100%">
              <Box display="flex" justifyContent="flex-end">
                <If condition={!isAddingNewFile}>
                  <ViewerButton icon variant="text" onClick={onClear}>
                    <CloseIcon />
                  </ViewerButton>
                </If>
              </Box>
              <Box
                sx={sx.imageContainer}
                onMouseDown={startDrag}
                style={{
                  cursor: dragging ? 'grabbing' : 'grab',
                }}
              >
                <If condition={!!imagePreviewUrl} fallback={<ImageIcon />}>
                  <img
                    ref={imageRef}
                    src={imagePreviewUrl || ''}
                    alt="Preview"
                    style={{
                      position: 'relative',
                      left: `${position.x}px`,
                      top: `${position.y}px`,
                      transform: `scale(${zoom})`,
                      transition: 'transform 0.2s ease',
                      width: '90%',
                      height: '90%',
                      objectFit: 'contain',
                    }}
                  />
                </If>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box display="flex" flexDirection="column">
        <Grid
          container
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="center"
          marginTop={'1rem'}
          width={'100%'}
          flexWrap="nowrap"
        >
          <Grid container direction="row" justifyContent="center" spacing={2} marginBottom="1rem">
            <Grid item>
              <IconButton
                color="primary"
                variant="outlined"
                tooltip="Anterior"
                onClick={withResetTransformationsCallback(handlePrevious)}
                disabled={isFirstSolicitation || isEditingUploadingItem}
              >
                <ArrowLeftIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="primary"
                variant="outlined"
                tooltip="Siguiente"
                onClick={withResetTransformationsCallback(handleNext)}
                disabled={isLastItem || isEditingUploadingItem}
              >
                <ArrowRightIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="primary"
                variant="outlined"
                tooltip="Añadir nuevo archivo"
                onClick={withResetTransformationsCallback(onAddNewFile)}
                disabled={isMaxSolicitationsReached || isEditingUploadingItem || isEditingImage}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Grid>
            <If condition={canSeeButtons && !!selectedSolicitation}>
              <>
                <Grid item>
                  <IconButton
                    color="primary"
                    variant="outlined"
                    tooltip="Rotar"
                    onClick={rotateImage}
                  >
                    <Rotate90DegreesCwIcon />
                  </IconButton>
                </Grid>
              </>
            </If>
            <Grid item>
              <IconButton color="primary" variant="outlined" tooltip="Zoom In" onClick={zoomIn}>
                <ZoomInIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="primary" variant="outlined" tooltip="Zoom Out" onClick={zoomOut}>
                <ZoomOutIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            width="45%"
            marginBottom="1rem"
            sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}
          >
            <Grid item>
              <IconButton
                color="secondary"
                variant="outlined"
                tooltip="Cancelar"
                onClick={withResetTransformationsCallback(onCancel)}
              >
                <CancelIcon />
              </IconButton>
            </Grid>
            <If condition={!!isAddingNewFile && !isEditingUploadingItem}>
              <Grid item>
                <IconButton
                  color="error"
                  variant="contained"
                  tooltip="Eliminar"
                  onClick={handleOnDelete}
                  isLoading={isDeleting}
                  disabled={isDeleting || noSolicitations}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </If>
            <Grid item>
              <IconButton
                color="primary"
                variant="contained"
                tooltip={'Guardar'}
                onClick={onSave}
                isLoading={isLoading}
              >
                <SaveIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <If condition={Array.isArray(solicitations) && solicitations?.length > 1}>
          <FilesDisplay
            solicitations={solicitations}
            onSelect={withResetTransformationsCallback(onSelect)}
            selectedSolicitation={selectedSolicitation}
          />
        </If>
      </Box>
    </>
  );
};

export default SelectedFileDisplay;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: { xs: '100%', md: '90%' },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    gap: '1rem',
  },
  textarea: { 
    margin: '2rem 0',
    '& textarea': {
      width: '100%',
      height: '150px',
      padding: '16.5px 14px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '32px', 
      fontSize: '0.875rem',
      fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette.shade?.[400] : theme.palette.shade?.[50],
      borderColor: (theme) => theme.palette.mode === 'dark' ? theme.palette.shade?.[300] : theme.palette.shade?.[300],
      color: (theme) => theme.palette.shade?.[900],
      '&:focus': {
        borderColor: (theme) => theme.palette.primary.main,
        outline: 'none',
      },
      '&::placeholder': {
        color: (theme) => theme.palette.grey[500],
        fontWeight: 600
      }
    } 
  },
});

export default useSx;


const allowedFonts = new Set([
    // installed from ttf-mscorefonts-installer linux package
    'Arial',
    'Arial Black',
    'Andalé Mono',
    'Comic Sans MS',
    'Courier New',
    'Georgia',
    'Impact',
    'Times New Roman',
    'Trebuchet MS',
    'Verdana',
    'Webdings'
])

export const getAllowedFonts = () => allowedFonts.keys()

export const isAllowedFont = (fontName: string) => {
    return allowedFonts.has(fontName)
}
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { v4 } from 'uuid';
import updateOrganizationTemplate from '@core/api/updateOrganizationTemplate';
import { GetOrganizationTemplateResponse } from '@core/types';
import { ORGANIZATION_TEMPLATE_QUERY_KEY } from './useOrganizationTemplate';
import { ORGANIZATION_TEMPLATE_IMAGE_QUERY_KEY } from './useOrganizationTemplateImage';
import { ORGANIZATION_TEMPLATES_QUERY_KEY } from './useOrganizationTemplates';

const useUpdateOrganizationTemplate = (templateId: string) => {
  const queryClient = useQueryClient();
  const { isLoading: isUpdating, mutateAsync: updateOrganizationTemplateAsync } = useMutation({
    mutationFn: (payload: FormData) => updateOrganizationTemplate(templateId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION_TEMPLATES_QUERY_KEY]);
      queryClient.invalidateQueries([ORGANIZATION_TEMPLATE_IMAGE_QUERY_KEY, templateId]);
      const templateUri = queryClient.getQueryData<GetOrganizationTemplateResponse>([
        ORGANIZATION_TEMPLATE_QUERY_KEY,
        templateId,
      ]);
      if (templateUri) {
        const parsedUrl = new URL(templateUri?.templateUri);
        parsedUrl.search = '';
        parsedUrl.searchParams.set('_', v4());
        queryClient.setQueryData<GetOrganizationTemplateResponse>(
          [ORGANIZATION_TEMPLATE_QUERY_KEY, templateId],
          { templateUri: parsedUrl.toString() },
        );
      }
    },
  });

  return { isUpdating, updateOrganizationTemplateAsync };
};

export default useUpdateOrganizationTemplate;

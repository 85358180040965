import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    mt: 4,
    py: 4,
    ...expandDisplayFlex({ j: 'space-between', d: 'column', a: 'center' }),

    '& .ShareLinks-linkButtons': {
      ...expandDisplayFlex({ a: 'flex-end' }),
    },
  },
  qr: {
    width: 'fit-content',
    marginBottom: '1rem'
  }
});

export default useSx;

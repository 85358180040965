import React from 'react';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CenterCenterStack from '@components/CenterCenterStack';
import useSx from './sx';

const ShareCodeError = () => {
  const sx = useSx();
  return (
    <Paper sx={sx.root}>
      <CenterCenterStack>
        <LinkOffIcon fontSize="large" />
        <Typography variant="body2">C&oacute;digo no disponible por el momento</Typography>
      </CenterCenterStack>
    </Paper>
  );
};

export default ShareCodeError;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    '& .MuiDialog-paper': {
      backgroundColor: (theme) => theme.palette.shade?.[50],
      backgroundImage: 'none',
      width: '100%',
    },
    textAlign: 'center',
    '.dialog-title': {
      display: 'flex',
      flexDirection: 'column',
    },
    '.close-icon': {
      width: 'fit-content',
      alignSelf: 'flex-end',
    },
    '.form-container': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
    },
    '.avatar': { width: '200px', height: '200px' },
    '.delete-button': { marginRight: 'auto' },
  },
});

export default useSx;

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import merge from 'lodash.merge';
import { Tooltip } from '@mui/material';
import Icon from '@mui/material/Icon';
import MuiListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import If from '@components/If';
import { MenuListItemProps } from './ListItemProps';
import useSx from './sx';

const MenuItemListItem = (props: MenuListItemProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const sx = useSx();

  const handleNavigate = () => {
    if (props.route) {
      navigate(props.route);
      if (props.handleDrawerToggle) {
        props.handleDrawerToggle();
      }
    }
  };

  const listItemSx =
    `/${location.pathname.split('/')[1]}` === props.route ? merge(sx.root, sx.activeItem) : sx.root;

  return (
    <MuiListItem onClick={handleNavigate} sx={listItemSx} disablePadding>
      <ListItemButton>
        <If
          condition={!props.collapsed}
          fallback={
            <Tooltip title={props.label}>
              <ListItemIcon>
                <Icon sx={sx.icon}>{props.icon}</Icon>
              </ListItemIcon>
            </Tooltip>
          }
        >
          <ListItemIcon>
            <Icon sx={sx.icon}>{props.icon}</Icon>
          </ListItemIcon>
          <ListItemText primary={props.label} />
        </If>
      </ListItemButton>
    </MuiListItem>
  );
};

export default MenuItemListItem;

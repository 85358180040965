import React from 'react';
import Box from '@mui/material/Box';
import ActionButton from '@components/Button/ActionButton';
import LargeView from '@components/Views/LargeView';
import useViewportSize from '@core/hooks/useViewportSize';
import useSx from './sx';

export interface ActionsProps {
  disableAccept: boolean;
  onCancel?: VoidFunction;
  onAccept?: VoidFunction;
}

const Actions = ({ onAccept, onCancel, disableAccept }: ActionsProps) => {
  const sx = useSx();
  const { isSmallViewport: isMobile } = useViewportSize();

  return (
    <Box sx={sx.root} className="ReportManagerActions-root">
      <LargeView>
        <ActionButton text="Cancelar" color="secondary" variant="outlined" onClick={onCancel} />
      </LargeView>

      <ActionButton
        text="Adjuntar"
        color="primary"
        variant="contained"
        onClick={onAccept}
        disabled={disableAccept}
      />
    </Box>
  );
};

export default Actions;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    width: '160px',
    height: '160px',
    p: 4,
  },
});

export default useSx;

import { put } from '@core/api/baseApi';

export const editFolder = (body: {
  templateFolderId: number;
  templateFolderName: string;
  isOrganizationWide: boolean;
}): Promise<{
  templateFolderId: string;
  templateFolderName: string;
  templateFolderParentId: string;
}> => {
  return put('/ReportTemplates/folder', body);
};

function findLastTextNode(node: Node): Node | null {
  if (node.nodeType === Node.TEXT_NODE) {
    return node;
  }

  const childNodes = node.childNodes;
  const numChildNodes = childNodes.length;

  for (let i = numChildNodes - 1; i >= 0; i--) {
    const lastTextNode = findLastTextNode(childNodes[i]);
    if (lastTextNode) {
      return lastTextNode;
    }
  }

  return null;
}

export default findLastTextNode;

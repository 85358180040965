import * as Yup from 'yup';

const forbiddenWords: string[] = ['password', 'user', 'name', 'admin'];

const passwordNotContainsFn = (words: string[]): ((value: string | undefined) => boolean) => {
  return (value: string | undefined): boolean => {
    return !words.some((word) => value && value.toLowerCase().includes(word));
  };
};

interface PasswordFormValues {
  newPassword: string;
  confirmPassword: string;
}

export const validationSchema: Yup.ObjectSchema<PasswordFormValues> = Yup.object().shape({
  newPassword: Yup.string()
    .required('La nueva contraseña es requerida')
    .min(8, 'La nueva contraseña debe tener al menos 8 caracteres')
    .matches(/[a-z]/, 'La contraseña debe contener al menos una letra minúscula')
    .matches(/[A-Z]/, 'La contraseña debe contener al menos una letra mayúscula')
    .matches(/[0-9]/, 'La contraseña debe contener al menos un número')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'La contraseña debe contener al menos un símbolo')
    .test('noContains', 'La contraseña contiene palabras o nombres comunes', passwordNotContainsFn(forbiddenWords)),
  confirmPassword: Yup.string()
    .required('La confirmación de la nueva contraseña es requerida')
    .oneOf([Yup.ref('newPassword')], 'Las contraseñas no coinciden'),
});

import React, { PropsWithChildren } from 'react';
import useViewportSize from '@core/hooks/useViewportSize';

const LargeView = ({ children }: PropsWithChildren<unknown>) => {
  const { isUpMedium: isDesktop } = useViewportSize();

  return <>{isDesktop && children}</>;
};

export default LargeView;

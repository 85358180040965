import React, { createContext, PropsWithChildren, useMemo } from 'react';
import useOrganization, { UseOrganizationId } from '../hooks/useOrganization';

export const AuthContext = createContext<UseOrganizationId>({
  error: '',
  isAcquiring: false,
  isError: false,
  organization: {
    hasMoreOrganizations: false,
    organizationId: '',
  },
  setOrganization: () => {},
});

const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const organizationValues = useOrganization();

  const organizationValuesMemo = useMemo(() => organizationValues, [organizationValues]);

  return <AuthContext.Provider value={organizationValuesMemo}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;

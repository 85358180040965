import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    '& .MuiDrawer-paper': {
      width: 1,
      backgroundColor: (theme) => theme.palette.shade?.[50],
    },

    '& .EvoDividerTop-root': {
      mt: 1,
    },

    '& .EvoMenuItemDivider-root': {
      mx: 1,
    },
  },
  menuContainer: { 
    height: '100%', 
    pt: '5%', 
    px: '5%',
  },
  icon: {
    fontSize: '1.8rem'
  }
});

export default useSx;

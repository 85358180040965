import { useMutation } from '@tanstack/react-query';
import { StudyAssignedNotificationPayload } from '@core/types/Notification';
import { sendAssignedNotification } from '../api/sendAssignedNotification';

const useSendAssignedNotification = () => {
  const { isLoading, mutateAsync: asyncSendAssignedNotification } = useMutation({
    mutationFn: (payload: StudyAssignedNotificationPayload) => sendAssignedNotification({ ...payload}),
    mutationKey: ["StudyAssignedNotification"],
 });

  return { isLoading, sendAssignedNotification: asyncSendAssignedNotification };
};

export default useSendAssignedNotification;

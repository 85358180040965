import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: (theme) => ({
    position: 'relative',
    p: 2,
    bgcolor: '#fff',

    '& .ShareCode-overlay': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 1,
      height: 1,
      borderRadius: 1,
      cursor: 'pointer',
      p: 6,
      ...expandDisplayFlex({ j: 'center', a: 'center' }),
      bgcolor: 'rgba(128 128 128 / 40%)',
      opacity: 0,
      transition: 'opacity 200ms',

      '&:hover': {
        opacity: 1,
      },
    },

    '& .ShareCode-icon': {
      p: '8px 8px 4px 8px',
      bgcolor: '#fff',
      color: '#000',
      borderRadius: '4px',
      border: '1px solid black',

      '& svg': {
        fontSize: theme.spacing(6),
      },
    },
  }),
});

export default useSx;

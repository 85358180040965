import useCurrentTheme from '@core/hooks/useCurrentTheme';
import useProfile from '@core/hooks/useProfile';
import useWidth from '@core/hooks/useWidth';
import productStatusBannerMap from './productStatusBannerMap';
import useSx from './sx';

const useHeaderState = () => {
  const { toggleThemeVariant, ...otherContextProps } = useCurrentTheme();
  const sx = useSx();
  const width = useWidth();
  const deviceWidth = width;
  const userProfile = useProfile();
  const userFullName = userProfile.fullName;
  const profilePhotoSrc = userProfile.userProfileInfo?.profilePhoto;

  const MessageBanner = productStatusBannerMap[userProfile.productStatus || 'None']!;

  return {
    toggleThemeVariant,
    sx,
    deviceWidth,
    MessageBanner,
    userFullName,
    profilePhotoSrc,
  };
};
export default useHeaderState;

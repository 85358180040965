import { get } from '@core/api/baseApi';
import { GetUsersResponse, RoleName } from '@core/types';

export interface GetUsersRequest {
  page?: number;
  Search?: string;
  Role?: string;
  SortOrder?: string;
  pageSize?: string;
  AdminUsersIncluded?: number;
  OwnerUserIncluded?: number;
}

const getUsers = ({
  page,
  Role = RoleName.Radiologist,
  Search,
  SortOrder = 'DESC',
  pageSize = '10',
  AdminUsersIncluded = 1,
  OwnerUserIncluded = 1,
}: GetUsersRequest): Promise<GetUsersResponse> =>
  get('/Users', {
    params: {
      page,
      Role,
      pageSize,
      Search,
      SortOrder,
      AdminUsersIncluded,
      OwnerUserIncluded,
    },
  });

export default getUsers;

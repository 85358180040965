export default function capitalizeText(text?: string) {
  if (!text?.length) {
    return '';
  }

  const words = text.split(' ');
  return words
    .map((word) => word.toLowerCase())
    .map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`)
    .join(' ');
}

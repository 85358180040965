import { useMutation } from '@tanstack/react-query';
import setPatientUser from '@core/api/setPatientUser';
import { PatientUser } from '@core/types/User';

const useAddPatientUser = () => {
  const mutationFn = (user: PatientUser) => setPatientUser(user);
  const { isLoading, mutateAsync: addPatientUser } = useMutation(mutationFn, {
    mutationKey: ["AddPatientUser"],
  });

  return { isLoading, addPatientUser };
};

export default useAddPatientUser;

import React from 'react';
import { Box } from '@mui/material';
import If from '@components/If';
import Consumption from './Consumption/Consumption';
import SubscriptionDetail from './SubscriptionDetail/SubscriptionDetail';
import useSubscriptionState from './useSubscriptionState';

const Subscription: React.FC = () => {
  const subscriptionDetails = useSubscriptionState();

  return (
    <Box>
      <If condition={!!subscriptionDetails}>
        <>
          <Consumption subscriptionDetails={subscriptionDetails} />
          <SubscriptionDetail subscriptionDetails={subscriptionDetails} />
        </>
      </If>
    </Box>
  );
};

export default Subscription;

import React from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import If from '@components/If';
import useFilters from 'app/features/users/hooks/useFilters';
import useSx from './sx';

const NameCell = () => {
    const sx = useSx()
    const [filters, setFilter] = useFilters()

    const onChangeOrder = () => {
        const order =  filters.SortOrder === 'ASC' ? 'DESC' : 'ASC'
        setFilter('SortOrder', order)
    }

    return (
        <TableCell sx={sx.root} onClick={onChangeOrder}>
            <Box className="container">
                <Typography>
                    Nombre
                </Typography>
                <If condition={filters.SortOrder === 'DESC'} fallback={<ArrowUpwardIcon className="order-icon" />}>
                    <ArrowDownwardIcon className="order-icon" />
                </If>
            </Box>
        </TableCell>
    )
}

export default NameCell
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Box from '@mui/material/Box';
import If from '@components/If';
import PDFPreviewProps from './PDFPreviewProps';
import useSx from './sx';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFPreview = ({ file, onSuccess, onError, height = 100 }: PDFPreviewProps) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const sx = useSx();

  useEffect(() => {
    const fetchNumPages = async () => {
      if (file) {
        const url = URL.createObjectURL(file);
        const pdf = await pdfjs.getDocument(url).promise;
        const totalPages = pdf.numPages;
        if (totalPages > 1) {
          onError(400);
        } else {
          setNumPages(totalPages);
          onSuccess();
        }
      }
    };

    fetchNumPages();
  }, [file]);

  const onLoadError = () => {
    onError(500);
  };
  return (
    <If condition={!!file}>
      <Box className="pdf-previewer-container" sx={sx.root}>
        <Document onLoadError={onLoadError} file={file}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              canvasBackground="transparent"
              height={height}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </Box>
    </If>
  );
};

export default PDFPreview;

import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'roosterjs';
import sanitizeHtml from 'sanitize-html';
import { Box } from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import UserAvatar from '@components/UserAvatar';
import useProfile from '@core/hooks/useProfile';
import useSnackbar from '@core/hooks/useSnackbar';
import useAddStudyComment from 'app/features/study/hooks/useAddStudyComment';
import useUpdateStudyComment from 'app/features/study/hooks/useUpdateStudyComment';
import { Comment, Tag } from 'app/features/study/types/Comment';
import CommentInput from './CommentInput';
import useSx from './sx';

export const initialState = {
  commentId: null,
  studyKey: null,
  commenter: '',
  content: '',
  createdBy: null,
} as Comment;

type CommentFormProps = {
  setComment: React.Dispatch<React.SetStateAction<Comment>>;
  onClose: () => void;
  comment: Comment;
  disabledActions: boolean;
  studyInstanceUid: string;
};

const CommentForm = ({
  setComment,
  onClose,
  comment,
  disabledActions,
  studyInstanceUid,
}: CommentFormProps) => {
  const sx = useSx();
  const { fullName } = useProfile();
  const showSnackbar = useSnackbar();
  const { addStudyCommentAsync } = useAddStudyComment();
  const { updateStudyCommentAsync } = useUpdateStudyComment();

  const [tags, setTags] = useState<Record<string, Tag>>({});

  const placeholder = comment.commentId ? 'Actualizar comentario' : 'Agregar un comentario';

  const editor = useRef<Editor | null>(null);

  useEffect(() => {
    editor.current?.setContent(comment.content);
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = comment.content;
    const elements = tempDiv.querySelectorAll('.tag');
    const newTags: Record<string, Tag> = {};
    elements.forEach((user) => {
      if (user instanceof HTMLElement) {
        //removing first letter (b) used to prevent ids with starting digit
        //Check method createTextSpan in CommentInput
        const id = user.id?.substring(1);
        newTags[id] = {
          userUid: id,
          fullName: user.innerHTML,
          profilePhoto: user.dataset.image,
        };
      }
    });
    setTags(newTags);
  }, [comment.commentId]);

  const checkIfCommentHasContent = (content: string) => {
    const sanitizedHTML = sanitizeHtml(content, {
      allowedTags: [],
    });

    return sanitizedHTML.length > 0;
  };

  const onCommentSubmit = async () => {
    const snackbarMessage = {
      success: false,
      title: 'Ocurrio un error',
      message: 'No fue posible actualizar el comentario. Inténtalo de nuevo más tarde.',
    };

    const content = editor.current?.getContent();
    const commentHasContent = checkIfCommentHasContent(content || '');

    if (!commentHasContent || !content) {
      showSnackbar({
        type: 'error',
        message: 'Por favor, agrega contenido al comentario',
        title: 'Error',
      });
      return;
    }

    if (!comment.commentId) {
      const addCommentResponse = await addStudyCommentAsync({
        commenter: fullName!,
        content: content || '',
        studyInstanceUid,
        taggedUsers: Object.keys(tags),
      });

      snackbarMessage.success = addCommentResponse.success;
      snackbarMessage.message = addCommentResponse.error
        ? 'No fue posible agregar el comentario.'
        : 'El comentario agregado con éxito.';

      snackbarMessage.title = addCommentResponse.success
        ? 'Comentario agregado'
        : snackbarMessage.title;
    } else {
      const updateCommentResponse = await updateStudyCommentAsync({
        commenter: fullName!,
        content: content || '',
        studyInstanceUid,
        commentId: comment.commentId,
      });

      snackbarMessage.success = updateCommentResponse.success;
      snackbarMessage.message = !updateCommentResponse.success
        ? 'No fue posible actualizar el comentario.'
        : 'El comentario ha sido actualizado con éxito.';

      snackbarMessage.title = updateCommentResponse.success
        ? 'Comentario actualizado'
        : snackbarMessage.title;
    }

    if (snackbarMessage.success) {
      setComment({ ...initialState });
      editor.current?.setContent('');
      setTags({});
    }

    showSnackbar({
      type: snackbarMessage.success ? 'success' : 'error',
      title: snackbarMessage.title,
      message: snackbarMessage.message,
    });
  };

  return (
    <Box sx={sx.root}>
      <CommentInput
        editor={editor}
        setComment={setComment}
        comment={comment}
        tags={tags}
        setTags={setTags}
      />
      <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="10px">
        <Box display="flex" alignItems="center" gap="5px">
          {Object.values(tags).map((tag) => (
            <Box
              key={`${tag.fullName} ${tag.userUid}`}
              display="flex"
              alignItems="center"
              gap="10px"
            >
              <UserAvatar userFullName={tag.fullName} userProfilePhoto={tag.profilePhoto} />
            </Box>
          ))}
        </Box>
        <Box
          marginLeft="auto"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="10px"
          marginTop="10px"
        >
          <If condition={!!onClose}>
            <ActionButton
              onClick={() => {
                setComment({ ...initialState });
                onClose();
              }}
              color="secondary"
              variant="outlined"
              text="Cancelar"
            />
          </If>
          <ActionButton
            color="primary"
            variant="contained"
            onClick={onCommentSubmit}
            isLoading={disabledActions}
            disabled={disabledActions}
            text={placeholder}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CommentForm;

import { post } from '@core/api/baseApi';
import { PostTransferAccountResponse } from '@core/types/ApiResponses';

const postTransferAccount = (userUid: string, targetUserUid: string): Promise<PostTransferAccountResponse> => {
  const payload = {
    targetUserUid
  }
  return post(`/Users/${userUid}/transfer-account`, payload);
}

export default postTransferAccount;

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import If from '@components/If';
import { subscribe, unsubscribe } from '@core/constants/customEvents';
import useOrganizationTemplateImage from '@core/hooks/useOrganizationTemplateImage';
import useOrganizationTemplates from '@core/hooks/useOrganizationTemplates';
import blobToBase64 from '@core/utils/createBase64FromBlob';
import Preview from './Preview';
import PreviewTextEditorProps from './PreviewTextEditorProps';
import TextEditor from './TextEditor';
import useSx from './sx';

const PreviewTextEditor = ({
  textEditorRef,
  error,
  initialContent,
  helperText,
  hidePreview = false,
  onTextEditorBlur,
}: PreviewTextEditorProps) => {
  const sx = useSx();
  const [editorContent, setEditorContent] = useState(initialContent);
  const [templateId, setTemplateId] = useState<string | undefined>(undefined);
  const [templateImageUrl, setTemplateImageUrl] = useState('');

  const { organizationTemplates, isFetched } = useOrganizationTemplates();

  const { isFetching, ...templateImageResponse } = useOrganizationTemplateImage(templateId);

  useEffect(() => {
    const defaultTemplateId = organizationTemplates?.items?.[0]?.templateId;
    if (defaultTemplateId) {
      setTemplateId(defaultTemplateId);
    }
  }, [isFetched, organizationTemplates?.items]);

  useEffect(() => {
    const ref = subscribe('evodicom.report.changeTemplate', (data) => {
      setTemplateId(data.templateId);
    });

    return () => {
      unsubscribe('evodicom.report.changeTemplate', ref);
    };
  }, []);

  useEffect(() => {
    const loadImage = async () => {
      if (
        templateImageResponse.status === 'success' &&
        (templateImageResponse.renderedImage?.size ?? 0) > 0
      ) {
        const imageB64 = await blobToBase64(templateImageResponse.renderedImage!);
        setTemplateImageUrl(imageB64);
      }
    };

    loadImage();
  }, [templateImageResponse.renderedImage, templateImageResponse.status]);

  const syncEditorContent = (newContent: string) => {
    setEditorContent(newContent);
    textEditorRef.current?.setContent(newContent);
  };

  useEffect(() => {
    syncEditorContent(initialContent);
    // It is not necessary to include the initialContent in the dependencies array because it is a reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialContent]);

  return (
    <Box sx={sx.container}>
      <Box flex="1" display="flex" gap="10px" sx={sx.root} margin="auto" justifyContent="center">
        <TextEditor
          error={error}
          helperText={helperText}
          textEditorRef={textEditorRef}
          onChange={(newContent) => {
            setEditorContent(newContent);
          }}
          onTextEditorBlur={onTextEditorBlur}
        />
        <If condition={!hidePreview}>
          <Preview
            isLoading={isFetching}
            customBackground={templateImageUrl}
            content={editorContent}
          />
        </If>
      </Box>
    </Box>
  );
};

export default PreviewTextEditor;

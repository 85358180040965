import React from 'react'
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box"
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import SearchInput from '@components/SearchInput';
import useProfile from '@core/hooks/useProfile';
import useFilters from '../../hooks/useFilters';
import Tabs from './Tabs';
import useSx from './sx';

const Actions = () => {
    const [filters, setFilter, removeFilter]  = useFilters()
    const navigate = useNavigate()
    const { hasAdminPermissions } = useProfile()
    const sx = useSx()

    return (
        <If condition={hasAdminPermissions}>
            <Tabs />
            <Box sx={sx.root}>
                <Box className="buttons-container">
                    <SearchInput 
                        filters={filters} 
                        setFilter={setFilter} 
                        removeFilter={removeFilter} 
                        query="Search"
                        placeholder="Buscar en la lista de usuarios"
                        sx={{marginRight: 'auto', width: {xs: '100%', md: '40%'}}}
                    />
                    <ActionButton 
                        color='primary'
                        variant='contained'
                        startIcon={<AddIcon />}
                        onClick={() => {navigate('/settings/users/add')}}
                        text='Crear usuario'
                    />
                </Box>
            </Box>
        </If>
    )
}

export default Actions
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  logoDark: Yup.mixed()
    .nullable()
    .notRequired()
    .test('fileSize', 'El archivo es demasiado grande', (value) => {
      const file = value as File | undefined;
      if(typeof file === 'undefined' || typeof file === 'string') {
        return true;
      }

      return file ? file.size <= 1024 * 1024 : false;
    })
    .test('fileFormat', 'Formato no admitido', (value) => {
      const file = value as File | string | undefined;
      if(typeof file === 'undefined' || typeof file === 'string') {
        return true;
      }
      return file
        ? ['image/jpg', 'image/jpeg', 'image/webp', 'image/png'].includes(file.type)
        : false;
    }),
  logoLight: Yup.mixed()
    .nullable()
    .notRequired()
    .test('fileSize', 'El archivo es demasiado grande', (value) => {
      const file = value as File | string | undefined;
      if(typeof file === 'undefined' || typeof file === 'string') {
        return true;
      }

      return file ? file.size <= 1024 * 1024 : false; // 1MB size limit
    })
    .test('fileFormat', 'Formato no admitido', (value) => {
      const file = value as File | undefined;
      if(typeof file === 'undefined' || typeof file === 'string') {
        return true;
      }
      return file
        ? ['image/jpg', 'image/jpeg', 'image/webp', 'image/png'].includes(file.type)
        : false;
    }),
});
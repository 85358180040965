import React from 'react';
import DataObjectIcon from '@mui/icons-material/DataObject';
import MuiFab from '@mui/material/Fab';
import If from '@components/If';
import FabProps from './FabProps';
import useSx from './sx';

const Fab = ({ isOpen, setIsOpen }: FabProps) => {
  const sx = useSx();
  return (
    <If condition={!isOpen}>
      <MuiFab
        onClick={() => {
          setIsOpen('TEMPLATES');
        }}
        sx={sx.root}
        color="primary"
        aria-label="add"
      >
        <DataObjectIcon className="style-icon" />
      </MuiFab>
    </If>
  );
};

export default Fab;

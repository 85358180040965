import React, { useEffect, useState } from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Box, Typography } from '@mui/material'
import If from '@components/If';
import useSx from './sx';

const CopyAction = ({password}: {password: string}) => {
    const sx = useSx()
    const [showCopy, setShowCopy] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowCopy(false)
        }, 5000);

        return () => {
            clearTimeout(timer)
        }

    }, [showCopy])

    const onCopy = () => {
        setShowCopy(true)
        navigator.clipboard.writeText(password)
    }

    return (
        <Box
            padding="1rem"
            justifyContent="space-between"
            borderRadius="10px"
            display="flex"
            alignItems="center"
            gap="10px"
            flex="1"
            width="100%"
            sx={sx.root}
        >
            <Typography>
            {password}
            </Typography>
            <If condition={!showCopy} fallback={<CheckCircleOutlineIcon sx={sx.checkIcon} />}>
                <ContentCopyOutlinedIcon 
                    sx={{cursor: 'pointer'}} 
                    onClick={onCopy} 
            /> 
            </If>
        </Box>
    )
}

export default CopyAction
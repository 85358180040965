import SxDef from '@core/types/SxDef';
import { expandBorder, expandBorderColor, expandDisplayFlex } from '@core/utils/expandShorthandCss';
import setCssSize from '@core/utils/setCssSize';

const useSx = (extend: boolean): SxDef => ({
  root: (theme) => ({
    mt: 4,
    borderWidth: '2px',

    '& .MuiAvatar-root': {
      bgcolor: 'transparent',
      color: 'text.primary',
      ...expandBorder('1px solid'),
      ...expandBorderColor('secondary.light'),
      ...setCssSize(theme.spacing(12)),
      '&.MuiAvatar-rounded': {
        borderRadius: 0.5,
      },
      width: 'auto',
      height: '100%',
      objectFit: 'cover',
    },

    '& .MuiCardContent-root': {
      ...expandDisplayFlex({ a: 'center' }),

      '& .SelectedFileDisplay-stack': {
        flexGrow: 1,

        '& .MuiTypography-root': {
          textTransform: 'capitalize',
        },
      },
    },

    '& .MuiButton-root': {
      mr: -4,
    },
  }),
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    maxHeight: { xs: '80vh', md: extend ? 'calc(50vh + 100px)' : '50vh'},
    overflow: 'hidden'
  },
});

export default useSx;

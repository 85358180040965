import React, { useContext } from 'react';
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Box, useTheme } from '@mui/material';
import { ThemeContext } from '@core/providers/ThemeProvider';
import { themeDetailsMap } from '@core/utils/generateThemeDetailsMap';
import transformToBarChartData from 'app/features/subscription/utils/transformToBarChartData';
import { BarChartComponentProps } from './BarChartComponentProps';

const BarChartComponent: React.FC<BarChartComponentProps> = ({ styles, subscriptionDetails }) => {
  const theme = useTheme();
  const { combinedThemeName } = useContext(ThemeContext);

  const barChartData = transformToBarChartData(subscriptionDetails);

  const maxUsedValue = Math.max(...barChartData.map((elem) => elem.utilizado));

  const maxValueToDisplay = maxUsedValue === -Infinity ? 10 : Math.floor(maxUsedValue * 1.2);
  const renderLegend = ({
    payload,
  }: {
    payload: {
      color: string;
      dataKey: string;
      value: string;
      type: string;
    }[];
  }) => {
    return (
      <ul style={{ listStyle: 'none' }}>
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '.5rem',
            }}
          >
            <div
              style={{
                backgroundColor: entry.color,
                height: '20px',
                width: '20px',
                borderRadius: '5px',
              }}
            />
            <p style={{ textTransform: 'capitalize' }}>Estudios</p>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Box sx={styles.chartBox}>
      <ResponsiveContainer width="100%" height={400}>
        <RechartsBarChart
          data={barChartData}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid stroke="transparent" />
          <XAxis dataKey="name" angle={25} />
          <YAxis domain={[0, maxValueToDisplay]} />
          <Tooltip
            contentStyle={{
              backgroundColor: theme.palette.grey[800],
              border: 0,
              borderRadius: '12px',
            }}
            itemStyle={{ color: 'white' }}
            cursor={{ fill: !!barChartData.length ? theme.palette.shade?.[400] : 'transparent' }}
          />
          {/* @ts-expect-error This is how the documentation implements the content override https://recharts.org/en-US/api/Legend#content */}
          <Legend content={renderLegend} />

          <Bar
            dataKey="utilizado"
            fill={themeDetailsMap[combinedThemeName as keyof typeof themeDetailsMap]?.primaryColor}
            radius={[10, 10, 0, 0]}
          />
        </RechartsBarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default BarChartComponent;

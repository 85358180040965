const errorMessages = {
  fileNameTooLarge: {
    code: 'file-name-too-large',
    title: 'Nombre de archivo muy largo',
    message: 'Solo archivos con nombres igual o menor a 50 letras pueden ser adjuntados.',
  },
  fileTooLarge: {
    code: 'file-too-large',
    title: 'El archivo adjunto excede el tamaño máximo',
    message: 'Verifique que el tamaño de la solicitud que desea adjuntar no sea mayor a 20mb.',
  },
  fileTooSmall: {
    code: 'file-too-small',
    title: 'El archivo está vacío',
    message: 'Verifique que la solicitud que desea adjuntar contenga datos.',
  },
  invalidDataType: {
    code: 'file-invalid-type',
    title: 'Solo archivos del tipo PDF pueden ser adjuntados',
    message: 'Verifique que la solicitud que desea adjuntar sea del tipo PDF.',
  },
  serviceUnavailable: {
    code: 'file-too-small',
    title: 'No pudimos adjuntar la solicitud en este momento.',
    message:
      'Por favor verifique que su equipo se encuentra conectado a internet, que el archivo aun está disponible y que el servicio se encuentra funcionando.',
  },
  duplicateFile: {
    code: 'file-too-small',
    title: 'El archivo que intenta adjuntar ya existe.',
    message: 'Por favor intente adjuntado un archivo diferente.',
  },
  emptyFile: {
    code: 'file-too-small',
    title: 'El archivo que intenta adjuntar está vacío.',
    message: 'Por favor intente adjuntado un archivo con contenido.',
  },
  generalError: {
    code: 'file-too-small',
    title: 'No pudimos adjuntar la solicitud solicitado.',
    message: 'Estamos teniendo problemas técnicos en este momento. Por favor intente mas tarde.',
  },
};

export default errorMessages;

import React, { useState } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import HistoryIcon from '@mui/icons-material/History';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import ViewerTextField from '@components/ViewerTextField';
import countryCodes from '@core/constants/countryCodes';
import useOrganizationInfo from '@core/hooks/useOrganizationInfo';
import useStudyInfo from '@core/hooks/useStudyInfo';
import { EmailOrPhoneFormRequest } from '@core/types';
import capitalizeText from '@core/utils/capitalizeText';
import useGetSharedStudyRecords from 'app/features/studies/hooks/useGetSharedStudyRecords';
import useShareByEmailOrPhone from '../../../../hooks/useShareByEmailOrPhone';
import ShareLinks from '../ShareLinks';
import History from './History';
import useShareByEmailState from './useShareByEmailState';

export interface ShareByEmailProps {
  studyInstanceUid: string;
}

type FieldName = keyof EmailOrPhoneFormRequest;

const initialValues = {
  name: '',
  emailOrPhone: '',
  body: '',
  language: 'es_MX',
};

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      maxWidth: 120,
    },
  },
};

const phoneRegex = /^\+?\d{7,15}$/;

const validationSchema = Yup.object().shape({
  name: Yup.string(),
  emailOrPhone: Yup.string()
    .required('Por favor introduzca una dirección de correo o número de teléfono')
    .test(
      'is-email-or-phone',
      'Por favor introduzca una dirección de correo válida o número de teléfono',
      (value) => {
        const isEmailValid = Yup.string().email().isValidSync(value);
        const isPhoneValid = phoneRegex.test(value);
        return isEmailValid || isPhoneValid;
      },
    ),
});

const ShareByEmail = ({ studyInstanceUid }: ShareByEmailProps) => {
  const { share, shareWhatsapp, isSharing } = useShareByEmailOrPhone(studyInstanceUid);
  const { isLoading, studyInfo } = useStudyInfo({ studyInstanceUidOrHash: studyInstanceUid });
  const { shareUrl } = studyInfo ?? {};
  const { organizationInfo } = useOrganizationInfo();
  const { shareMethod, setShareMethod, countryCode, setCountryCode, showSnackbar, sx } =
    useShareByEmailState();

  const isEmail = (value: string) => Yup.string().email().isValidSync(value);
  const isPhoneNumber = (value: string) => phoneRegex.test(value);

  const handleShareByEmail = async (
    request: EmailOrPhoneFormRequest,
    { resetForm }: FormikHelpers<EmailOrPhoneFormRequest>,
  ) => {
    try {
      const req = {
        ...request,
        name: capitalizeText(request.name),
        organizationName: organizationInfo.organizationName,
        organizationPhone: organizationInfo.organizationPhone,
        shareLink: shareUrl,
        emailOrPhone:
          shareMethod === 'email' ? request.emailOrPhone : `${countryCode}${request.emailOrPhone}`,
        language: request.language,
      };

      if (shareMethod === 'email') {
        if (isEmail(req.emailOrPhone)) {
          await share(req);
        } else {
          throw new Error('La dirección de correo no es válida');
        }
      } else {
        if (isPhoneNumber(req.emailOrPhone)) {
          await shareWhatsapp(req);
        } else {
          throw new Error('El número de teléfono no es válido');
        }
      }

      resetForm();
    } catch (error: any) {
      if (error.statusCode === 500) {
        showSnackbar({
          message: 'Lo sentimos, no pudimos enviar el estudio por correo. Intente mas tarde.',
          type: 'error',
          title: 'Ocurrió un error',
        });
      }
    }
  };

  const handleTextButton = (sharing: boolean): string => {
    if (shareMethod === 'email') {
      return sharing ? 'Enviando Correo' : 'Enviar Correo';
    } else {
      return sharing ? 'Enviando Whatsapp' : 'Enviar Whatsapp';
    }
  };

  const handleShareMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const method = event.target.value;
    if (method === 'email' || method === 'whatsapp') setShareMethod(method);
  };

  const handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
    setCountryCode(event.target.value as string);
  };

  const handleLanguageChange = (
    event: SelectChangeEvent<string>,
    setFieldValue: FormikProps<EmailOrPhoneFormRequest>['setFieldValue'],
  ) => {
    setFieldValue('language', event.target.value as string);
  };

  const [showableTab, setShowableTab] = useState('HISTORY');

  return (
    <Paper elevation={0} sx={sx.root}>
      <Stack>
        <Formik<EmailOrPhoneFormRequest>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleShareByEmail}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            submitCount,
            setFieldValue,
          }) => {
            const shouldShowError = (field: FieldName) => submitCount > 0 && !!errors[field];

            return (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid sx={{ display: 'flex', alignItems: 'center' }} item xs={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="share-method"
                        name="shareMethod"
                        value={shareMethod}
                        onChange={handleShareMethodChange}
                      >
                        <FormControlLabel value="email" control={<Radio />} label="Correo" />
                        <FormControlLabel value="whatsapp" control={<Radio />} label="WhatsApp" />
                      </RadioGroup>
                    </FormControl>
                    <Box
                      color="primary.main"
                      display="flex"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setShareMethod('');
                        setShowableTab('HISTORY');
                      }}
                      alignItems="center"
                      gap="5px"
                      marginLeft="auto"
                    >
                      <HistoryIcon />
                      <Typography>Historial</Typography>
                    </Box>
                  </Grid>
                  <If
                    condition={shareMethod === 'whatsapp' || shareMethod === 'email'}
                    fallback={<History studyInstanceUid={studyInstanceUid} />}
                  >
                    <Grid item xs={12} md={7}>
                      <Box sx={sx.textFieldBox}>
                        <Typography sx={sx.label}>Enviar a *</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          {shareMethod === 'whatsapp' && (
                            <FormControl sx={sx.selectRoot}>
                              <InputLabel id="country-code-label">Código</InputLabel>
                              <Select
                                labelId="country-code-label"
                                id="country-code-select"
                                value={countryCode}
                                label="Código"
                                onChange={handleCountryCodeChange}
                                size="small"
                                MenuProps={menuProps}
                              >
                                {countryCodes.map((country) => (
                                  <MenuItem key={country.code} value={country.dial_code}>
                                    <img
                                      src={`https://flagsapi.com/${country.code}/flat/16.png`}
                                      alt={country.name}
                                    />
                                    +{country.dial_code}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                          <ViewerTextField
                            name="emailOrPhone"
                            placeholder={
                              shareMethod === 'email' ? 'Dirección de email' : 'Número de teléfono'
                            }
                            error={shouldShowError('emailOrPhone')}
                            helperText={
                              (shouldShowError('emailOrPhone') && errors.emailOrPhone) || ''
                            }
                            required
                            value={values.emailOrPhone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={sx.textFieldBox}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid container spacing={2}>
                          {shareMethod === 'email' && (
                            <Grid item xs={12} md={shareMethod === 'email' ? 6 : 12}>
                              <Typography sx={sx.label}>Nombre</Typography>
                              <ViewerTextField
                                name="name"
                                placeholder="Nombre del contacto"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} md={6}>
                            <Typography sx={sx.label}>Idioma</Typography>
                            <FormControl sx={sx.selectLanguageRoot} fullWidth>
                              <Select
                                labelId="language-label"
                                id="language-select"
                                value={values.language}
                                label="Idioma"
                                onChange={(e) => handleLanguageChange(e, setFieldValue)}
                                size="small"
                                MenuProps={menuProps}
                              >
                                <MenuItem value="es_MX">Español</MenuItem>
                                <MenuItem value="en_US">Inglés</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <ShareLinks studyInfo={studyInfo} isLoading={isLoading} />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                      <Box className="ShareByEmailButtonContainer-root">
                        <ActionButton
                          color={'primary'}
                          sx={shareMethod === 'email' ? {} : sx.whatsappButton}
                          variant="contained"
                          type="submit"
                          disabled={
                            isSharing ||
                            !values.emailOrPhone ||
                            (shareMethod === 'email' && !isEmail(values.emailOrPhone)) ||
                            (shareMethod === 'whatsapp' && !isPhoneNumber(values.emailOrPhone))
                          }
                          text={handleTextButton(isSharing)}
                          icon={shareMethod === 'email' ? <MailOutlineIcon /> : <WhatsAppIcon />}
                        />
                      </Box>
                    </Grid>
                  </If>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Stack>
    </Paper>
  );
};

export default ShareByEmail;

import React, { PropsWithChildren } from 'react';
import useDeviceFeature from '@core/hooks/useDeviceFeature';

const TouchscreenView = ({ children }: PropsWithChildren<unknown>) => {
  const { isTouchscreen } = useDeviceFeature();

  return <>{isTouchscreen && children}</>;
};

export default TouchscreenView;

import React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useFilters from 'app/features/users/hooks/useFilters';
import Pagination from '../../../Pagination';
import PageSizeSelect from './PageSizeSelect';
import useSx from './sx';

const PaginationRow = ({ totalCount, pageCount }: { totalCount?: number; pageCount?: number }) => {
  const sx = useSx();
  const [filters] = useFilters();

  return (
    <TableRow sx={sx.root}>
      <TableCell colSpan={100}>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap="10px">
            <PageSizeSelect />
            <Typography className="page-size-title">Filas por página</Typography>
          </Box>
          <Pagination
            pageSize={+(filters.pageSize || 1)}
            totalCount={+(totalCount || 0)}
            pageCount={pageCount || 1}
            page={+(filters.page || 1)}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default PaginationRow;

import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    ...expandDisplayFlex({ d: 'column' }),
    flex: 1,
    gap: '10px',
    paddingX: { xs: '16px', md: '0' },
    '.ContainerCard-Root': {
      marginTop: 0,
    },
  },
  title: {
    paddingX: { xs: '16px', md: '0' },
    gap: { xs: '10px', md: '20px' },
    '.subtitle': {
      fontSize: { xs: '0.8rem', md: '1.3125rem' },
    },
  },
});

export default useSx;

const formatFileSize = (size: number) => {
  if (size <= 1024) {
    return `${size} b`;
  }

  const kb = size / 1024;
  if (kb <= 1024) {
    return `${kb.toFixed(2)} KB`;
  }

  const mb = kb / 1024;
  return `${mb.toFixed(2)} MB`;
};

export default formatFileSize;

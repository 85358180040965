import React from 'react';
import Container from '@mui/material/Container';
import ManagerComponentProps from '../../../types/ManagerComponentProps';
import ShareByEmail from './ShareByEmail';
import useSx from './sx';

export type ShareManagerProps = ManagerComponentProps;

const ShareManager = ({ studyInstanceUid }: ShareManagerProps) => {
  const sx = useSx();

  return (
    <Container maxWidth="md" disableGutters sx={sx.root}>
      <ShareByEmail studyInstanceUid={studyInstanceUid} />
    </Container>
  );
};

export default ShareManager;

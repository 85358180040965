import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: () => ({
    display: 'flex',
    ...expandDisplayFlex({ d: 'column' }),
    gap: '20px',
    bgcolor: 'transparent',
  }),
});

export default useSx;

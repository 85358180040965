function getCurrentCaretRange() {
  const selection = window.getSelection();

  if (selection === null || selection.rangeCount === 0) {
    return null;
  }
  const range = selection.getRangeAt(0);
  return range;
}

export default getCurrentCaretRange;

import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import { roleToDisplayName } from '@core/types';
import ProfileMenuCardProps from './ProfileMenuCardProps';
import useSx from './sx';

const ProfileMenuInfoCard = ({ selectedOrganization }: ProfileMenuCardProps) => {
  const sx = useSx();
  // eslint-disable-next-line prefer-const
  let { fullName, role } = useProfile();

  if (!fullName && process.env.NODE_ENV !== 'production') {
    fullName = 'Daniel Velazquez';
  }

  return (
    <Box sx={sx.root}>
      <Avatar variant="rounded">
        <PersonIcon />
      </Avatar>
      <If condition={!!fullName && !!role && !!selectedOrganization}>
        <Box>
          <Typography lineHeight="1.3" fontWeight="bold">
            {fullName}
          </Typography>
          <Typography lineHeight="1.3" variant="subtitle2">
            {roleToDisplayName(role)}
          </Typography>
          <Typography lineHeight="1.3" variant="subtitle2" className="organization-title">
            {selectedOrganization?.organizationName}
          </Typography>
        </Box>
      </If>
    </Box>
  );
};

export default ProfileMenuInfoCard;

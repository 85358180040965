import { ThemeType } from "@core/types/Theme";
import * as Themes from '@styles/themes';

export const getThemeNames = (): ThemeType[] => {

  const themeNamesWithPossibleDuplicates = Object.keys(Themes).filter(key => 
    key.endsWith('Light') || key.endsWith('Dark') || key.endsWith('OS')
  ).map(key => 
    key.replace('Light', '').replace('Dark', '')
  );

  const uniqueThemeNames = [...new Set(themeNamesWithPossibleDuplicates)];

  // Sort the themes to ensure "evodicom" is first
  const sortedUniqueThemeNames = uniqueThemeNames.sort((a, b) => {
    if (a.includes('evodicom')) return -1;
    if (b.includes('evodicom')) return 1;
    return a.localeCompare(b);
  });

  return sortedUniqueThemeNames as ThemeType[];
}

/* eslint-disable @typescript-eslint/lines-between-class-members */
export enum RoleName {
  None = 'None',
  Referrer = 'Referrer',
  Technician = 'Technician',
  Anonymous = 'Anonymous',
  Administrator = 'Administrator',
  Radiologist = 'Radiologist',
  AccountOwner = 'AccountOwner',
  Patient = 'Patient',
  System = 'System'
}

const roleNameMap = new Map<string, string>([
  ['Technician', 'Técnico Radiólogo'],
  ['Anonymous', 'Invitado'],
  ['Administrator', 'Administrador'],
  ['Radiologist', 'Médico Radiólogo'],
  ['Referrer', 'Médico Referente'],
  ['Patient', 'Paciente'],
  ['System', 'Usuario de Sistema'],
]);

export const AllRoles = [
  RoleName.None,
  RoleName.Referrer,
  RoleName.Technician,
  RoleName.Anonymous,
  RoleName.Administrator,
  RoleName.Radiologist,
  RoleName.AccountOwner,
  RoleName.Patient,
  RoleName.System,
];

export const MedicalRoles = [
  RoleName.Referrer,
  RoleName.Technician,
  RoleName.Administrator,
  RoleName.Radiologist,
  RoleName.AccountOwner,
  RoleName.System,
];

export const roleToDisplayName = (role = '') =>
  roleNameMap.has(role) ? roleNameMap.get(role) : roleNameMap.get('Anonymous');

import React, { PropsWithChildren } from 'react';
import useDeviceFeature from '@core/hooks/useDeviceFeature';

const HoverableView = ({ children }: PropsWithChildren<unknown>) => {
  const { isHoverable } = useDeviceFeature();

  return <>{isHoverable && children}</>;
};

export default HoverableView;

import React from 'react';
import { Typography } from '@mui/material';
import CenterCenterStack from '@components/CenterCenterStack';
import useSx from './sx';

const NoInfoAvailable = () => {
  const sx = useSx();

  return (
    <CenterCenterStack sx={sx.root}>
      <Typography>
        No pudimos cargar su estudio debido a que detectamos un estudio no v&aacute;lido
      </Typography>
    </CenterCenterStack>
  );
};

export default NoInfoAvailable;


import * as Yup from 'yup'
import { roleOptions } from './fields';

const validationSchema = Yup.object().shape({
    givenName: Yup.string()
        .min(1, "Por favor introduzca un nombre")
        .required("Por favor introduzca un nombre"),
    surname: Yup.string()
        .min(1, "Por favor introduzca un apellido")
        .required("Por favor introduzca un apellido"),
    emailAddress: Yup.string()
        .required('Por favor introduzca una dirección de correo')
        .email('Por favor introduzca una dirección válida'),
    role: Yup.string()
        .required('Por favor introduzca una rol válido')
        .oneOf(roleOptions.map(role => role.value), 'Por favor introduzca una rol válido'),
  });

export default validationSchema
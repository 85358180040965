import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import useProfile from '@core/hooks/useProfile';
import useRestart from '@core/hooks/useRestart';
import useSnackbar from '@core/hooks/useSnackbar';
import useTransferAccount from '@core/hooks/useTransferAccount';
import SelectUserContent from './SelectUserContent/SelectUserContent';
import { TransferAccountModalProps } from './TransferAccountModalProps';
import useSx from './sx';

const TransferAccountModal: React.FC<TransferAccountModalProps> = ({
  open,
  onConfirm,
  onCancel,
}) => {
  const sx = useSx();
  const showSnackbar = useSnackbar();
  const { userUid } = useProfile();
  const [selectedUserUid, setSelectedUserUid] = useState<string | null>(null);
  const { isUploading, postAsyncTransferAccount } = useTransferAccount();
  const appRestart = useRestart();

  const handleTransfer = async () => {
    if (selectedUserUid && userUid) {
      try {
        const response = await postAsyncTransferAccount({
          userUid,
          targetUserUid: selectedUserUid,
        });
        if (response.success) {
          showSnackbar({
            type: 'success',
            title: 'Cuenta transferida',
            message: 'La cuenta se transfirió correctamente. Será redirigido al inicio.'
          });
          setTimeout(() => {
            appRestart()
          }, 2000)
        }
      } catch (error) {
        showSnackbar({
          type: 'error',
          title: 'Ocurrió un error',
          message: 'Error al transferir la cuenta.'
        });
      }
    } else {
      showSnackbar({
        type: 'warning',
        title: 'Ocurrió un error',
        message: 'Debe seleccionar un usuario.'
      });
    }
  };

  return (
    <Dialog
      sx={sx.root}
      fullWidth={true}
      maxWidth={false}
      open={open}
      onClose={onCancel}
      aria-labelledby="warning-dialog-title"
    >
      <Box>
        <DialogTitle id="warning-dialog-title" sx={sx.dialogTitle}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onCancel}
            aria-label="close"
            sx={sx.closeButton}
          >
            <CloseIcon />
          </IconButton>
          Transferir cuenta a otro usuario
        </DialogTitle>

        <DialogContent dividers>
          <SelectUserContent onUserSelect={setSelectedUserUid} />
        </DialogContent>

        <DialogActions>
          <Box sx={sx.actionsButtonContainer}>
            <ActionButton
              color="secondary"
              variant="outlined"
              text={'Cancelar'}
              onClick={onCancel}
            />
            <ActionButton
              color="primary"
              variant="contained"
              text={'Transferir'}
              onClick={handleTransfer}
              disabled={isUploading || !selectedUserUid}
            />
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default TransferAccountModal;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import setOrganizationTheme from '@core/api/setOrganizationTheme';
import { NewThemePayload } from '@core/types/Theme';
import { ORGANIZATION_SETTINGS_QUERY_KEY } from './useOrganizationSettings';

const useSetOrganizationTheme = () => {
  const queryClient = useQueryClient()
  const { isLoading, mutateAsync: postOrganizationTheme } = useMutation({
    mutationFn: (payload: NewThemePayload) => setOrganizationTheme(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION_SETTINGS_QUERY_KEY])
    }
  });

  return { isLoading, postOrganizationTheme };
};

export default useSetOrganizationTheme;

import React from 'react';
import { Button, ButtonProps, SxProps, Theme, CircularProgress } from '@mui/material';
import If from '@components/If';
import useSx from './sx';

interface ActionButtonProps extends ButtonProps {
  sx?: SxProps<Theme>;
  text: string;
  icon?: any;
  isLoading?: boolean;
  disabled?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({ sx: customSx, text, icon, isLoading, disabled, ...props }) => {
  const sx = useSx();

  const combinedSx = {
    ...sx,
    ...customSx,
  } as SxProps<Theme>;

  const isDisabled = isLoading || disabled;

  return (
    <Button {...props} sx={combinedSx} disabled={isDisabled}>
      <If condition={!isLoading} fallback={<CircularProgress sx={sx.loader} size={24} />}>
        <>
          {icon && React.cloneElement(icon, { className: 'iconWithSpacing' })}
          {text}
        </>
      </If>
    </Button>
  );
};

export default ActionButton;

import { useState } from 'react';
import { EmailFormRequest } from '@core/types';
import shareByEmail from '../api/shareByEmail';

const useShareByEmail = (studyInstanceUid: string) => {
  const [isSharing, setIsSharing] = useState(false);

  const share = async (request: EmailFormRequest) => {
    setIsSharing(true);

    try {
      await shareByEmail(studyInstanceUid, request);
    } finally {
      setIsSharing(false);
    }
  };

  return { share, isSharing };
};

export default useShareByEmail;

import React, { useCallback, useEffect } from 'react';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import MicOffIcon from '@mui/icons-material/MicOff';
import Fab from '@mui/material/Fab';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import If from '@components/If';
import { subscribe, unsubscribe } from '@core/constants/customEvents';
import RecordAudioActionProps from './RecordAudioActionProps';
import useRecordAudioActionState from './useRecordAudioActionState';

const SPEECH_KEY = process.env.REACT_APP_SPEECH_KEY || '';
const SPEECH_REGION = process.env.REACT_APP_SPEECH_REGION || '';

const RecordAudioAction = ({
  onUpdateTextEditor,
  isTranscribing,
  setIsTranscribing,
}: RecordAudioActionProps) => {
  const {
    isRecording,
    setIsRecording,
    transcript,
    setTranscript,
    temporaryTranscript,
    setTemporaryTranscript,
    speechRecognizer,
    setSpeechRecognizer,
    warningDialogOpen,
    setWarningDialogOpen,
  } = useRecordAudioActionState();

  const createSpeechRecognizer = (audioConfig: SpeechSDK.AudioConfig, type: 'audio' | 'speech') => {
    try {
      const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
      speechConfig.speechRecognitionLanguage = 'es-ES';
      const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
      if (type === 'speech') setSpeechRecognizer(recognizer);
      return recognizer;
    } catch (error) {
      console.error('Error al crear el speech recognizer' + error);
    }
  };

  const startRecording = async () => {
    try {
      setIsRecording(true);
      setTemporaryTranscript('');

      if (speechRecognizer) {
        speechRecognizer.startContinuousRecognitionAsync();
        speechRecognizer.recognizing = (s, e) => {
          setIsTranscribing(true);
          setTemporaryTranscript(e.result.text);
        };
        speechRecognizer.recognized = (s, e) => {
          if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
            setTemporaryTranscript('');
            setTranscript((prevTranscript) => `${prevTranscript.trim()} ${e.result.text}`.trim());
            setIsTranscribing(false);
          }
        };
      } else {
        throw new Error('No esta disponible el speech recognizer');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const stopRecording = async () => {
    if (speechRecognizer) {
      await new Promise<void>((resolve, reject) => {
        speechRecognizer.stopContinuousRecognitionAsync(
          () => resolve(),
          (error) => reject(error),
        );
      }).catch(console.error);
      setIsTranscribing(false);
      setIsRecording(false);
    }
  };

  const handleStopRecording = async () => {
    if (isRecording) {
      await stopRecording();
    }
  };

  const handleRecordButtonClick = () => {
    if (!isRecording) {
      startRecording();
    }
  };

  const handleShowWarningModal = useCallback(() => {
    setWarningDialogOpen(true);
  }, []);

  const handleOnConfirmWarningModal = () => {
    handleRecordButtonClick();
    setWarningDialogOpen(false);
  };

  useEffect(() => {
    if (!speechRecognizer) {
      const speechAudioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
      createSpeechRecognizer(speechAudioConfig, 'speech');
    }
  }, []);

  useEffect(() => {
    if (isTranscribing) {
      onUpdateTextEditor(`${transcript} ${temporaryTranscript}`);
    } else {
      onUpdateTextEditor(`${transcript}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcript, temporaryTranscript, isTranscribing]);

  useEffect(() => {
    subscribe('evodicom.report.record', handleShowWarningModal);

    return () => {
      unsubscribe('evodicom.report.record', handleShowWarningModal);
    };
  }, [handleShowWarningModal]);

  return (
    <>
      <ActionDialog
        open={warningDialogOpen}
        message="Al grabar o subir un audio, en algunos casos se eliminará el contenido actual y se actualizará con la transcripción del audio."
        title="Actualizar Reporte"
        onClose={() => {
          setWarningDialogOpen(false);
        }}
        onConfirm={() => {
          handleOnConfirmWarningModal();
        }}
        type="warning"
      />
      <If condition={isRecording}>
        <Fab
          onClick={handleStopRecording}
          color="error"
          sx={{ position: 'fixed', bottom: 30, right: 30 }}
        >
          <MicOffIcon />
        </Fab>
      </If>
    </>
  );
};

export default RecordAudioAction;

import React, { useState } from 'react';
import DrawIcon from '@mui/icons-material/Edit';
import { Avatar, Box, Badge } from '@mui/material';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import useProfile from '@core/hooks/useProfile';
import useDeleteProfilePhoto from '../../hooks/useDeleteProfilePhoto';
import ProfilePhotoDialogForm from './ProfilePhotoDialogForm';
import useSx from './sx';

export type ProfilePhotoModalState = 'edit-photo' | 'delete-photo' | null;

const ProfilePhotoForm = () => {
  const sx = useSx();
  const { userProfileInfo } = useProfile();
  const { deleteProfilePhotoAsync, isLoading: isDeleting } = useDeleteProfilePhoto();
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>(null);
  const [modalState, setModalState] = useState<ProfilePhotoModalState>(null);

  const onModalClose = () => {
    setModalState(null);
    setImageSrc(null);
  };

  const onEditProfilePhoto = () => {
    setModalState('edit-photo');
  };

  return (
    <>
      <Box marginY="2rem">
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={<DrawIcon sx={{ fontSize: '1.7rem' }} />}
          onClick={onEditProfilePhoto}
          sx={sx.root}
        >
          <Avatar className="avatar" src={userProfileInfo?.profilePhoto} />
        </Badge>
      </Box>
      <ProfilePhotoDialogForm
        setModalState={setModalState}
        imageSrc={imageSrc}
        setImageSrc={setImageSrc}
        onClose={onModalClose}
        modalState={modalState}
      />
      <ActionDialog
        isLoading={isDeleting}
        type="delete"
        title="Confirmar eliminación"
        message="Tu foto de perfil actual será eliminada. Puedes actualizarla en cualquier momento accediendo a esta sección."
        open={modalState === 'delete-photo'}
        onConfirm={async () => {
          await deleteProfilePhotoAsync();
          setImageSrc(null);
          setModalState(null);
        }}
        onCancel={onModalClose}
      />
    </>
  );
};

export default ProfilePhotoForm;

export default function replaceQrCode(page: HTMLDivElement, shareStudyQrBase64?: string) {
  const qrCodePlaceholders = page.querySelectorAll(
    '.QrPlaceholder',
  ) as NodeListOf<HTMLImageElement>;

  qrCodePlaceholders.forEach((elem: HTMLImageElement) => {
    if (shareStudyQrBase64) {
      elem.src = shareStudyQrBase64;
    } else {
      elem.remove();
    }
  });
}

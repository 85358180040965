import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  inputs: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  },
  inputName: { width: '100%' },
  buttons: {
    marginTop: '2rem',
    marginBottom: '4rem',
    width: '100',
    justifyContent: 'flex-end',
    gap: '20px',
    display: 'flex',
  },
  topButtons: {
    justifyContent: 'flex-end',
    gap: 'inherit',
    display: 'flex',
  },
  loadingBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10000,
  },
});

export default useSx;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    '& .MuiPaper-root': {
      backgroundColor: (theme) => theme.palette.shade?.[50],
      backgroundImage: 'none'
    },
    '& .MuiMenu-list': {
      minWidth: '14rem',
      marginBottom: '1px',
    }
  },
});

export default useSx;

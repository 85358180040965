import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    '.MuiDialogTitle-root': { paddingBottom: 0 },
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& .MuiTextField-root': {
      marginBottom: 0,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    bottom: 0,
    right: 0,
    width: '100%',
    background: (theme) => theme.palette.shade?.[200],
    padding: {
      xs: '24px',
      md: 0,
    },
    position: {
      xs: 'absolute',
      md: 'unset',
    },
  },
  footerButtons: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: '15px 10px',
  },
  tabs: {
    borderBottom: { xs: 'none', md: 1 },
    borderColor: { md: 'divider' },
    width: '100%',
    marginY: '1rem',
    position: 'unset',
    marginBottom: '1rem',
    '& .MuiTabs-flexContainer': {
      height: '100%',
    },
  },
  tab: {
    display: 'flex',
    flexDirection: 'row',
    flex: {
      xs: 1,
      md: 'unset',
    },
    gap: '.5rem',
    textTransform: 'capitalize',
  },
});

export default useSx;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    position: 'fixed',
    inset: 0,
    display: 'flex',
    background: 'radial-gradient(circle, #0066ff, #002e7a)',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
    padding: '3rem',
    '& a': {
      color: '#eeeeee',
      border: '1px solid #eeeeee',
      padding: '.5rem 2rem',
      borderRadius: '2rem',
      letterSpacing: '4px',
    },
  },
  errorIcon: {
    height: 300,
    width: 300,
  },
  text: {
    textAlign: 'center',
    letterSpacing: '1px',
    whiteSpace: 'pre-line',
    margin: 0,
    fontWeight: '700',
  },
});

export default useSx;

function getTemplateTags(text: string) {
  const pattern = /{{(.*?)}}/g;

  const matches = text.match(pattern) || [];

  const variables = matches.map(function (match) {
    return match.replace(/{{|}}/g, '').trim();
  });

  const variablesMap: Record<string, string> = [...new Set(variables)].reduce(
    (prev, curr) => ({ ...prev, [curr]: '' }),
    {},
  );

  // Retornar la lista de variables
  return variablesMap;
}

export default getTemplateTags;

type InputValue = {
    label: string
    name: string
    placeholder: string
    required?: boolean
}


const inputs: Record<string, InputValue> = {
    'oldPassword': {
        label: 'Contraseña',
        name: 'oldPassword',
        placeholder: 'Ingresa tu contraseña actual',
        required: true
    },
    'newPassword': {
        label: 'Nueva contraseña',
        name: 'newPassword',
        placeholder: 'Ingresa tu nueva contraseña',
        required: true
    },
    'confirmPassword': {
        label: 'Confirmar contraseña',
        name: 'confirmPassord',
        placeholder: 'Ingresa nuevamente tu nueva contraseña',
        required: true
    },
};


export default inputs
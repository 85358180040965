import React from 'react';
import Box from '@mui/material/Box';
import getPageStyles from 'app/features/templates/add/utils/getPageStyles';
import HeightSandboxProps from './HeightSandboxProps';

const HeightSanbox = ({ heightSandboxRef, pageConfig, customBackground }: HeightSandboxProps) => {
  return (
    <Box
      sx={{
        ...getPageStyles(pageConfig, customBackground),
        position: 'fixed',
        top: '0',
        left: '-100%',
      }}
      ref={heightSandboxRef}
    ></Box>
  );
};

export default HeightSanbox;

import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box } from "@mui/material";
import If from '@components/If';
import useStudyInfo from '@core/hooks/useStudyInfo';
import LoadingIndicator from 'app/features/study/components/InfoManager/StudyInformation/LoadingIndicator';
import TagHandler from 'app/features/study/components/InfoManager/StudyInformation/TagHandler';
import ManagerComponentProps from 'app/features/study/types/ManagerComponentProps';
import useSx from '../sx';

const Form = ({ studyInstanceUid, readOnly }: ManagerComponentProps) => {
  const sx = useSx();
  const [searchParams] = useSearchParams();
  const studyId = readOnly ? searchParams.get('s') ?? '' : studyInstanceUid;
  const { isLoading, studyInfo } = useStudyInfo({
    studyInstanceUidOrHash: studyId,
    isSharedStudy: readOnly,
  });
  return (
    
    <Box sx={sx.container} display="flex" flexDirection="column" gap="15px">
      <If condition={!isLoading} fallback={<LoadingIndicator />}>
        <TagHandler 
          study={studyInfo!}
          readOnly={readOnly}
        />
      </If>
    </Box>
  )
}

export default Form;
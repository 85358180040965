import React, { useState } from 'react';
import useSnackbar from '@core/hooks/useSnackbar';
import { User } from '@core/types/User';
import useAssignRadiologist from '../../../hooks/useAssignRadiologist';
import useGetAssignedRadiologists from '../../../hooks/useGetAssignedRadiologists';
import useRevokeRadiologistStudyAccess from '../../../hooks/useRevokeRadiologistStudyAccess';

const useFormState = ({
  isOpen,
  studyInstanceUid,
}: {
  isOpen: boolean;
  studyInstanceUid: string;
}) => {
  const showSnackbar = useSnackbar();
  const { revokeRadiologistAccess } = useRevokeRadiologistStudyAccess();
  const { assignRadiologistAsync } = useAssignRadiologist();
  const { assignedRadiologists } = useGetAssignedRadiologists(studyInstanceUid || '', isOpen);
  const [selectedRadiologistInput, setSelectedRadiologistInput] = useState<null | User>(null);
  const [selectedRadiologist, setSelectedRadiologist] = useState<any>(null);

  return {
    assignRadiologistAsync,
    showSnackbar,
    revokeRadiologistAccess,
    selectedRadiologist,
    assignedRadiologists,
    setSelectedRadiologist,
    setSelectedRadiologistInput,
    selectedRadiologistInput,
  };
};

export default useFormState;

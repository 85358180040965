import React, { PropsWithChildren } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, DrawerProps, Typography } from '@mui/material';
import If from '@components/If';
import ViewerButton from '@components/ViewerButton';
import useSx from './sx';

export interface ViewerDrawerProps extends DrawerProps {
  title?: string;
  subtitle?: string;
}

export default function ViewerDrawer({
  open,
  onClose,
  title,
  subtitle,
  children,
}: PropsWithChildren<ViewerDrawerProps>) {
  const sx = useSx();
  return (
    <Drawer open={open} onClose={onClose} anchor="right" sx={sx.drawerOverrides}>
      <Box sx={sx.header}>
        <Box>
          <If condition={(title || '').length > 0}>
            <Typography variant="h5" className="title">
              {title}
            </Typography>
          </If>
          <If condition={(subtitle || '').length > 0}>
            <Typography variant="subtitle1" className="subtitle">
              {subtitle}
            </Typography>
          </If>
        </Box>
        <If condition={!!onClose}>
          <ViewerButton icon variant="text" onClick={(e) => onClose?.(e, 'escapeKeyDown')}>
            <CloseIcon />
          </ViewerButton>
        </If>
      </Box>
      {children}
    </Drawer>
  );
}

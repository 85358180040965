import React, { PropsWithChildren } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import If from '@components/If';
import ViewerButton from '@components/ViewerButton';
import useViewportSize from '@core/hooks/useViewportSize';
import useSx from './sx';

export interface ViewerDialogProps extends DialogProps {
  title?: string;
  subtitle?: string;
}

const ViewerDialog = ({
  title,
  subtitle,
  children,
  onClose,
  ...props
}: PropsWithChildren<ViewerDialogProps>) => {
  const { isUpMedium: isDesktop } = useViewportSize();
  const sx = useSx();
  const { sx: sxProps, ...restProps } = props;
  const combinedSx = {...sx.root, ...(sxProps || {})} as SxProps<Theme>

  return (
    <Dialog
      className="EvoViewerDialog-root"
      maxWidth="sm"
      fullWidth
      fullScreen={!isDesktop}
      TransitionComponent={Slide}
      sx={combinedSx}
      onClose={onClose}
      {...restProps}
    >
      <DialogTitle component="div">
        <Box>
          <If condition={(title || '').length > 0}>
            <Typography variant="h5" className="title">
              {title}
            </Typography>
          </If>
          <If condition={(subtitle || '').length > 0}>
            <Typography variant="subtitle1" className="subtitle">
              {subtitle}
            </Typography>
          </If>
        </Box>
        <If condition={!!onClose}>
          <ViewerButton icon variant="text" onClick={(e) => onClose?.(e, 'escapeKeyDown')}>
            <CloseIcon />
          </ViewerButton>
        </If>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default ViewerDialog;

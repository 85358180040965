import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& .MuiTextField-root': {
      marginBottom: 0,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    bottom: 0,
    right: 0,
    width: '100%',
    background: (theme) => theme.palette.shade?.[200],
    padding: {
      xs: '24px',
      md: 0,
    },
    position: {
      xs: 'absolute',
      md: 'unset',
    },
  },
  footerButtons: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: '15px 10px',
  },
});

export default useSx;

import { Theme } from '@mui/material/styles';
import SxDef from '@core/types/SxDef';

const autofillStyle = (theme: Theme) => ({
  WebkitBoxShadow: `0 0 0 1000px ${theme.palette.shade?.[400]} inset`,
  transition: 'background-color 5000s ease-in-out 0s',
});

const useSx = (): SxDef => ({
  root: {
    '& .MuiInputBase-input': {
      padding: 0,
      height: '100%',
      background: 'transparent',
      '&:-webkit-autofill': (theme) => autofillStyle(theme),
      '&:-moz-autofill': (theme) => autofillStyle(theme),
      '&:-o-autofill': (theme) => autofillStyle(theme),
      '&:-ms-autofill': (theme) => autofillStyle(theme),
    },
  },
});

export default useSx;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import assignReferrer from '../api/assignReferrer';
import { QUERY_KEY as ASSIGNED_REFERRALS_LIST } from './useGetAssignedReferrals';

const useAssignReferrer = () => {
  const queryClient = useQueryClient()
  const { isLoading: isUploading, mutateAsync: assignReferrerAsync } = useMutation({
    mutationFn: ({studyInstanceUid, userUid}:{ studyInstanceUid: string, userUid: string}) => assignReferrer(studyInstanceUid, userUid),
    onSuccess: () => {
      queryClient.invalidateQueries([ASSIGNED_REFERRALS_LIST])
    }
  });

  return { isUploading, assignReferrerAsync };
};

export default useAssignReferrer;
import React from 'react'
import ContrastOutlinedIcon from '@mui/icons-material/ContrastOutlined';
import { Box, Typography } from '@mui/material';
import IconButton from '@components/Button/IconButton';
import useCurrentTheme from '@core/hooks/useCurrentTheme';
import useSx from './sx';

const ModeCancel = () => {
  const { toggleThemeVariant, storedThemeVariant }  = useCurrentTheme()
  const sx = useSx(storedThemeVariant)

  const onClick = () => {
    toggleThemeVariant('OS')
  }

  return (
    <Box sx={sx.root}>
      <IconButton 
        tooltip=""
        variant={(storedThemeVariant === 'OS') ? "contained" : "outlined"}
        onClick={onClick}
        sx={sx.iconButton}
      >
          <ContrastOutlinedIcon className='icon' />
      </IconButton>
      <Typography>Sistema</Typography>
    </Box>
  )
}

export default ModeCancel
import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => {
  return {
    root: {
      position: 'fixed',
      bottom: { xs: '20px', md: '40px' },
      right: { xs: '20px', md: '40px' },
      height: { xs: '40px', md: '80px' },
      width: { xs: '40px', md: '80px' },
      '.style-icon': { fontSize: { xs: '1.5rem', md: '2.5rem' } },
    },
  };
};

export default useSx;

import React, { useState } from 'react';
import useSnackbar from '@core/hooks/useSnackbar';
import { GetStudyResponse } from '@core/types';
import useAssignTechnician from 'app/features/studies/hooks/useAssignTechnician';
import useGetStudyAssignedTechnician from 'app/features/studies/hooks/useGetStudyAssignedTechnician';

const useFormState = ({
  isOpen,
  studyInstanceUid,
}: {
  isOpen: boolean;
  studyInstanceUid: string;
  studyInfo: Partial<GetStudyResponse> | undefined;
}) => {
  const showSnackbar = useSnackbar();
  const { assignTechnicianAsync } = useAssignTechnician();
  const { technician, refetch } = useGetStudyAssignedTechnician(studyInstanceUid, isOpen);

  const [selectedTechnicianInput, setSelectedTechnicianInput] = useState<any>(null);
  const selectedTechnician = technician?.user;

  return {
    assignTechnicianAsync,
    selectedTechnicianInput,
    setSelectedTechnicianInput,
    selectedTechnician,
    refetch,
    showSnackbar,
  };
};

export default useFormState;

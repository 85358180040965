import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import If from '@components/If';
import TagsListProps from './TagsListProps';
import useSx from './sx';

const TagsList = ({ variables, isActive, onSelectedVariable }: TagsListProps) => {
  const sx = useSx();

  return (
    <List sx={sx.root}>
      {Object.keys(variables).map((variable) => (
        <ListItem key={variable}>
          <ListItemButton
            style={{
              backgroundColor: isActive(variable) ? 'rgba(255, 255, 255, 0.08)' : 'transparent',
            }}
            onClick={() => {
              onSelectedVariable(variable);
            }}
          >
            <ListItemText>{variable}</ListItemText>
            <If condition={!!variables[variable]}>
              <CheckCircleOutlineIcon className="check-icon" />
            </If>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default TagsList;

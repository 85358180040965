import SxDef from '@core/types/SxDef';
import { expandBorder, expandBorderColor, expandDisplayFlex } from '@core/utils/expandShorthandCss';
import setCssSize from '@core/utils/setCssSize';

const useSx = (): SxDef => ({
  root: (theme) => ({
    mt: 4,
    borderWidth: '2px',

    '& .MuiAvatar-root': {
      bgcolor: 'transparent',
      color: 'text.primary',
      ...expandBorder('1px solid'),
      ...expandBorderColor('secondary.light'),
      ...setCssSize(theme.spacing(12)),
      '&.MuiAvatar-rounded': {
        borderRadius: 0.5,
      },
    },

    '& .MuiCardContent-root': {
      ...expandDisplayFlex({ a: 'center' }),

      '& .SelectedFileDisplay-stack': {
        pl: 4,
        flexGrow: 1,

        '& .MuiTypography-root': {
          textTransform: 'capitalize',
        },
      },
    },

    '& .MuiButton-root': {
      mr: -4,
    },
  }),
});

export default useSx;

import { useQuery } from '@tanstack/react-query';
import getStudyAssignedTechnician from '../api/getStudyAssignedTechnician';

export const GET_TECHNICIAN_QUERY_KEY = 'assignedTechnician';

const useGetStudyAssignedTechnician = (studyInstanceUid: string, enabled: boolean) => {
  const { data: technician, ...rest } = useQuery({
    queryKey: [GET_TECHNICIAN_QUERY_KEY, studyInstanceUid],
    queryFn: () => getStudyAssignedTechnician(studyInstanceUid),
    staleTime: 1000 * 60 * 60, // 1 hour
    cacheTime: 1000 * 60 * 60, // 1 hour
    enabled,
  });

  return { ...rest, technician };
};

export default useGetStudyAssignedTechnician;

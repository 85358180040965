import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ContainerCard from '@components/ContainerCard/ContainerCard';
import If from '@components/If';
import Title from '@components/Title';
import useSx from './sx';

type Props = {
  title: string;
  subtitle?: string;
};

const FormLayout = ({ children, title, subtitle }: PropsWithChildren<Props>) => {
  const sx = useSx();

  return (
    <Container sx={sx.root} style={{ maxWidth: '100%' }} disableGutters>
      <Box sx={sx.title} display="flex" alignItems="center">
        <Title text={title} />
        <If condition={!!subtitle}>
          <Typography>&quot;{subtitle}&quot;</Typography>
        </If>
      </Box>
      <ContainerCard>{children}</ContainerCard>
    </Container>
  );
};

export default FormLayout;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetStudyResponse } from '@core/types';
import { STUDY_QUERY_KEY } from '../../../core/hooks/useStudyInfo';
import addStudyTag from '../api/addStudyTag';

const MUTATION_KEY = ['study', 'addTag'];

const useAddTag = (studyInstanceUid: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync: addTag, ...rest } = useMutation({
    mutationKey: [MUTATION_KEY, studyInstanceUid],
    mutationFn: (tagTitle: string) => addStudyTag({ studyInstanceUid, tagTitle }),
    onSuccess: (updated) => {
      queryClient.setQueryData<GetStudyResponse>(
        [STUDY_QUERY_KEY, studyInstanceUid],
        (existingStudy) => {
          if (existingStudy) {
            existingStudy.tags = updated.tags;
          }

          return existingStudy;
        },
      );
    },
  });

  return { addTag, ...rest };
};

export default useAddTag;

import React, { useContext, useState, useEffect } from 'react';
import {v4 as uuidv4} from 'uuid';
import logoColor from '@assets/img/logo-color.png';
import logoWhite from '@assets/img/logo-white.png';
import useOrganizationSettings from '@core/hooks/useOrganizationSettings';
import { ThemeContext } from '@core/providers/ThemeProvider';
import useSx from './sx';

const useLogoState = () => {
  const { organizationSettings, isLoading, error } = useOrganizationSettings();
  const { currentTheme, themeVariant } = useContext(ThemeContext);
  const sx = useSx();

  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => {
    const customLight = organizationSettings?.['custom-logo-light'] as string;
    const customDark = organizationSettings?.['custom-logo-dark'] as string;

    if (customLight || customDark) {
      // If only one custom logo is available, use it for both theme variants
      const customLogo = customLight || customDark;

      // If both custom logos are available, use them according to the theme variant
      if (customLight && customDark) {
        setLogo(themeVariant === 'Dark' ? `${customDark}?v=${uuidv4()}` : `${customLight}?v=${uuidv4()}`);
      } else {
        // If only one custom logo is available, use it for both variants
        setLogo(`${customLogo}?v=${uuidv4()}`);
      }
    } else if (!isLoading) {
      // If no custom logos are available, use the default logos
      setLogo(themeVariant === 'Dark' ? logoWhite : logoColor);
    }
  }, [currentTheme, organizationSettings, themeVariant, isLoading]);

  return {
    sx,
    organizationSettings,
    isLoading,
    error,
    logo,
  };
};

export default useLogoState;

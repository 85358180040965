import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    ':hover': { backgroundColor: (theme) => theme.palette.shade?.[400] },
    '.loading': { width: '20px !important', height: '20px !important' },
  },
});

export default useSx;

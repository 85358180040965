import React from 'react'
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import If from '@components/If';
import AssignedRadiologistsProps from './AssignedRadiologistsProps';
import useSx from './sx';

const AssignedRadiologists = ({assignedRadiologist, setSelectedRadiologist, selectedRadiologist}: AssignedRadiologistsProps) => {
    const sx = useSx()
    return (
        <Box display="flex" alignItems="center" gap="5px" flexWrap="wrap">
            <If condition={!!assignedRadiologist}>
                    <Chip 
                        onClick={() => {
                            if(selectedRadiologist?.userUid === assignedRadiologist?.userUid){
                                setSelectedRadiologist(null)
                                return
                            }
                            setSelectedRadiologist(assignedRadiologist)
                        }} 
                        key={assignedRadiologist?.userUid} 
                        label={assignedRadiologist?.fullName}
                        sx={selectedRadiologist?.userUid === assignedRadiologist?.userUid ? sx.chipActive : sx.chip}
                    />
            </If>
        </Box>
    )
}

export default AssignedRadiologists
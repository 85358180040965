import useGetTemplates from 'app/features/templates/hooks/useGetTemplates';
import { Filters } from './TemplateDropdownProps';

const useTemplateDropdownState = (filters: Filters) => {
  const { templates, isLoading } = useGetTemplates(filters);

  return { templates, isLoading };
};

export default useTemplateDropdownState;

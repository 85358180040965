import { useQuery } from "@tanstack/react-query";
import getUserPreferences from "@core/api/getUserPreference";

export const QUERY_KEY = "USER_PREFERENCES"

const useGetUserPreferences = () => {
    const { data: userPreferences, ...rest } = useQuery({
        queryFn: () => getUserPreferences(),
        queryKey: [QUERY_KEY],
        staleTime: 1000 * 60 * 60 * 12,
        cacheTime: 1000 * 60 * 60 * 12.5,
        retry: false, 
    });

    return { userPreferences, ...rest }
}

export default useGetUserPreferences
import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    '& .MuiDrawer-paper': {
      width: 1,
    }
  },
  menuContainer: { 
    height: '100%', 
    pt: '5%', 
    px: '5%',
    '@media (max-width: 768px)': {
      px: '20%'
    }, 
  },
  listItem: {
    backgroundColor: (theme) => theme.palette.secondary.main,
    width: '90%',
    margin: '1rem auto',
    borderRadius: '12px',
    height: '6rem',
    '.MuiListItemButton-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      color: (theme) => theme.palette.secondary.contrastText,
      justifyContent: 'center',
      ':hover': { backgroundColor: 'transparent' },
      '.MuiListItemIcon-root': {
        minWidth: 0,
        color: (theme) => theme.palette.secondary.contrastText,
      },
      '.MuiSvgIcon-root': {
        fontSize: '1.8rem',
      },
      '.MuiListItemText-root': {
        flex: 0,
        '.MuiTypography-root': { fontWeight: 200, fontSize: '0.8rem' },
      },
    },
  },
});


export default useSx;

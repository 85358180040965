import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addSolicitation } from '../api/addSolicitation';
import { GET_SOLICITATION_QUERY_KEY } from './useSolicitations';

const useUploadSolicitation = (studyInstanceUid: string) => {
  const queryClient = useQueryClient();

  const { isLoading: isUploading, mutateAsync: uploadSolicitation } = useMutation((formData: FormData) =>
    addSolicitation(formData, studyInstanceUid), {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SOLICITATION_QUERY_KEY]);
      },
    }
  );

  return { isUploading, uploadSolicitation };
};

export default useUploadSolicitation;

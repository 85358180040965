import { useContext } from 'react';
import { ThemeContext } from '@core/providers/ThemeProvider';
import { IThemeContext } from '@core/types/Theme';

export const useCurrentTheme = (): IThemeContext => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error(
      'useCurrentTheme must be used within a child component of ThemeProvider'
    );
  }

  return context;
};

export default useCurrentTheme;

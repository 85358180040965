import React, { PropsWithChildren } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import useSx from './sx';

export interface ViewerButtonProps extends ButtonProps {
  icon?: boolean;
}

const ViewerButton = ({
  children,
  icon,
  size = 'medium',
  sx: outerSx,
  ...buttonProps
}: PropsWithChildren<ViewerButtonProps>) => {
  const sx = useSx({ icon, size, extend: outerSx });

  return (
    <Button
      className="EvoViewerButton-root"
      variant="outlined"
      sx={sx.root}
      size={size}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default ViewerButton;

import React, { useState } from 'react';
import useSnackbar from '@core/hooks/useSnackbar';
import useGetAssignedReferrals from 'app/features/studies/hooks/useGetAssignedReferrals';
import useAssignReferrer from '../../../hooks/useAssignReferrer';
import useRevokeReferralStudyAccess from '../../../hooks/useRevokeReferralStudyAccess';

const useFormState = ({
  isOpen,
  studyInstanceUid,
}: {
  isOpen: boolean;
  studyInstanceUid: string;
}) => {
  const { assignReferrerAsync } = useAssignReferrer();
  const { revokeReferralAccess } = useRevokeReferralStudyAccess();
  const showSnackbar = useSnackbar();
  const [selectedReferrer, setSelectedReferrer] = useState<any>(null);
  const [selectedReferrerInput, setSelectedReferrerInput] = useState<any>(null);
  const { assignedReferrals } = useGetAssignedReferrals(studyInstanceUid || '', isOpen);

  return {
    assignReferrerAsync,
    revokeReferralAccess,
    showSnackbar,
    assignedReferrals,
    selectedReferrer,
    setSelectedReferrer,
    selectedReferrerInput,
    setSelectedReferrerInput,
  };
};

export default useFormState;

import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Skeleton from '@mui/material/Skeleton';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import useGetTemplateTags from 'app/features/templates/hooks/useGetTemplateTags';
import createTemplateTagElement from '../../../utils/createTemplateTagElement';
import {
  professionalInfoPlaceholder,
  qrPlaceholder,
  signaturePlaceholder,
} from '../imagePlaceholders';
import ToggleDrawerButton from './ToggleDrawerButton';
import useSx from './sx';

type TemplateTag = { templateTagId: number; name: string };

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  handleTemplateTagSelect?: (
    templateTag: TemplateTag,
    tagGenerator: (templateTag: TemplateTag) => HTMLElement,
  ) => void;
};

const LoadingSkeleton = () => (
  <>
    {[...Array(7)].map((_, index) => (
      <Skeleton key={index} variant="rounded" width="100%" height={40} />
    ))}
  </>
);

const customTemplateTags = [
  { templateTagId: 0, name: 'Firma', templateTagGenerator: signaturePlaceholder },
  {
    templateTagId: 0,
    name: 'Información profesional',
    templateTagGenerator: professionalInfoPlaceholder,
  },
  { templateTagId: 0, name: 'Código QR', templateTagGenerator: qrPlaceholder },
];

const TemplateTagDrawer = ({ isOpen, onClose, handleTemplateTagSelect }: Props) => {
  const { templateTags, isLoading } = useGetTemplateTags({ Page: '1', Search: '' }, isOpen);
  const sx = useSx();

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right" PaperProps={{ sx: sx.paper }}>
      <Box className="TemplateTag-Container">
        <If condition={!isLoading} fallback={<LoadingSkeleton />}>
          {templateTags?.items.map((tag) => (
            <ActionButton
              onClick={() => handleTemplateTagSelect?.(tag, createTemplateTagElement)}
              variant="contained"
              key={tag.templateTagId}
              text={tag.name}
            />
          ))}
          {customTemplateTags.map((tag, index) => (
            <ActionButton
              onClick={() => handleTemplateTagSelect?.(tag, tag.templateTagGenerator)}
              variant="contained"
              key={tag.templateTagId + tag.name + index}
              text={tag.name}
            />
          ))}
        </If>
      </Box>
      <ToggleDrawerButton onClick={onClose} isOpen={isOpen} />
    </Drawer>
  );
};

export default TemplateTagDrawer;

import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import DeleteCommentDialogProps from './DeleteDialogCommentProps';
import useSx from './sx';

const DeleteCommentDialog = ({open, onClose, onDelete}: DeleteCommentDialogProps) => {

    const sx = useSx()

    return (
        <Dialog maxWidth="xs" PaperProps={{sx: sx.paper}} open={open} onClose={onClose} aria-labelledby="warning-dialog-title">
        <DialogTitle id="warning-dialog-title" sx={sx.dialogHeader}>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close" sx={sx.closeButton}>
            <CloseIcon />
            </IconButton>
            <Box sx={sx.iconContainer}>
            <Box sx={sx.iconBox}>
                <ErrorOutlineOutlinedIcon />
            </Box>
            </Box>
        </DialogTitle>

        <DialogContent>
            <Typography sx={sx.title}>Confirmar eliminación</Typography>
            ¿Está seguro de que desea eliminar este comentario?
        </DialogContent>
        <Divider />
        <DialogActions>
            <Box sx={sx.actionsButtonContainer}>
                <ActionButton color="secondary" variant="contained" text={'Cancelar'} onClick={onClose} />
            <ActionButton color="primary" variant="contained" text={'Eliminar'} onClick={onDelete} />
            </Box>
        </DialogActions>
        </Dialog>
    )
}

export default DeleteCommentDialog
import { Theme, alpha } from '@mui/material';
import SxDef from '@core/types/SxDef';
import { ActionsColor } from './ActionDialogProps';

const useSx = (type: ActionsColor): SxDef => {
  const getColor = (theme: Theme) => {
    switch (type) {
      case 'delete':
        return theme.palette.error.main;
      case 'info':
        return theme.palette.info.main;
      case 'warning':
        return theme.palette.warning.main;
      case 'success':
        return theme.palette.success.main;
      default:
        return theme.palette.info.main;
    }
  };

  return {
    root: {
      '& .MuiDialog-paper': {
        backgroundColor: (theme) => theme.palette.shade?.[50],
        backgroundImage: 'none'
      },
      textAlign: 'center'
    },
    actionsButtonContainer: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
      width: '100%',
      padding: '1rem 0'
    },
    dialogHeader: {
      display: 'flex',
      flexDirection: 'column'
    },
    title: {
      fontWeight: 600,
      textAlign: 'center',
    },
    closeButton: {
      width: 'fit-content',
      alignSelf: 'flex-end'
    },
    iconContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    iconBox: {
      height: 60,
      width: 60,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      color: (theme) => getColor(theme),
      backgroundColor: (theme) => alpha(getColor(theme), 0.3),
      '.MuiSvgIcon-root': {
        fontSize: '3rem',
      },
    }
  };
};

export default useSx;

import useProfile from '@core/hooks/useProfile';
import useSnackbar from '@core/hooks/useSnackbar';
import useChangePassword from 'app/features/users/hooks/useChangePassword';
import useSx from './sx';

const usePasswordFormState = () => {
  const { userUid } = useProfile();
  const sx = useSx();
  const showSnackbar = useSnackbar();
  const { changeUserPassword, isLoading } = useChangePassword();

  return {
    sx,
    showSnackbar,
    changeUserPassword, 
    isLoading, 
    userUid
  };
};
export default usePasswordFormState;

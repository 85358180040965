import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { GetNotificationsRequest, getNotifications } from '@core/api/getNotifications';

export const GET_NOTIFICATIONS_QUERY_KEY = 'GetNotifications';

const useGetNotifications = (
  { filters }: { filters: GetNotificationsRequest },
  enabled: boolean,
) => {
  const { data: notifications, ...rest } = useQuery({
    queryKey: [GET_NOTIFICATIONS_QUERY_KEY, filters],
    queryFn: () => getNotifications(filters),
    enabled,
  });

  return {
    notifications,
    ...rest,
  };
};

export default useGetNotifications;

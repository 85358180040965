import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseButton from '@components/CloseButton/CloseButton';
import SettingsMenuProps from '../SettingsMenuProps';
import useSx from './sx';

const SmallViewMenu = ({ anchorEl, onMenuItemClick, onClose, settingsItems }: SettingsMenuProps) => {
  const handleItemClick = (route: string) => onMenuItemClick?.(route);
  const sx = useSx();

  return (
    <Drawer anchor="right" open={!!anchorEl} onClose={onClose} sx={sx.root}>
      <CloseButton onClick={onClose} />
      <Box sx={sx.menuContainer}>
        <List disablePadding>
          {settingsItems
            .map((m) => (
              <React.Fragment key={m.key}>
                <ListItem sx={sx.listItem} disablePadding>
                  <ListItemButton onClick={() => handleItemClick(m.route)}>
                    {m.icon && (
                      <ListItemIcon>
                        <m.icon />
                      </ListItemIcon>
                    )}
                    <ListItemText primaryTypographyProps={{sx: {fontSize: '1rem'}}} primary={m.label} />
                  </ListItemButton>
                </ListItem>
              </React.Fragment>
            ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default SmallViewMenu;

import productAccessManager from './productAccessManager';

async function getProductAccessToken() {
  try {
    const accessToken = await productAccessManager.acquireProductTokenSilent();
    return accessToken;
  } catch (error) {
    console.error(error);
  }
}

export default getProductAccessToken;

import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import useSnackbar from '@core/hooks/useSnackbar';
import { NewUserNotificationPayload } from '@core/types/Notification';
import UsersLayout from '../Layout/UsersLayout';
import Actions from '../components/Actions';
import CopyAction from '../components/CopyAction';
import Table from '../components/Table';
import useDeleteUser from '../edit-user/hooks/useDeleteUser';
import useResetPassword from '../hooks/useResetPassword';
import useUsersState from './useUsersState';

const Users = () => {
  const showSnackbar = useSnackbar();
  const {
    users,
    pageCount,
    selectedUser,
    setSelectedUser,
    modalState,
    setModalState,
    sendNotification,
    isSendingNotification,
    organizationId
  } = useUsersState();
  const { deleteUser } = useDeleteUser();
  const { resetPasswordAsync, isLoading } = useResetPassword();
  const [newPassword, setNewPassword] = useState<string | null>(null);

  const onDeleteConfirm = async () => {
    const user = selectedUser;
    if (user) {
      const response = await deleteUser(user?.userUid || '');
      if (response.statusCode) {
        showSnackbar({
          message: 'No ha sido posible eliminar este usuario.',
          title: 'Ocurrió un error',
          type: 'error',
        });
        return;
      }
      showSnackbar({
        message: 'El usuario ha sido removido con éxito.',
        title: 'Usuario eliminado',
        type: 'success',
      });
    }

    setModalState(null);
  };

  const onPasswordResetConfirm = async () => {
    if (selectedUser?.userUid) {
      const response = await resetPasswordAsync({ userUid: selectedUser?.userUid });
      if (response.success) {
        setNewPassword(response.temporaryPassword);
  
        const payload: NewUserNotificationPayload = {
          passwordChange: true,
          organizationId: organizationId,
          creatorName: undefined,
          userName: selectedUser?.fullName || '',
          address: selectedUser?.emailAddress || '',
          language: 'es_MX',
          temporalPassword: response.temporaryPassword
        }
        
        setModalState('PASSWORD_CONFIRM');
        await sendNotification(payload)
        return;
      }

      showSnackbar({
        type: 'error',
        title: 'Ocurrio un error',
        message:
          'Se ha producido un error inesperado y no fue posible restablecer la contraseña en este momento. Por favor, inténtalo nuevamente más tarde.',
      });
    }
  };

  const onClose = !isLoading
    ? () => {
        setModalState(null);
      }
    : () => {};

  return (
    <UsersLayout>
      <Actions />
      <Table
        totalCount={users?.totalCount}
        pageCount={pageCount}
        users={users?.items || []}
        setModalState={setModalState}
        setSelectedUser={setSelectedUser}
      />
      <ActionDialog
        open={modalState === 'DELETE'}
        message={`Estás a punto de remover al usuario ${selectedUser?.fullName} de la organización. Esta acción desvinculará el acceso y los privilegios asociados.`}
        title="Confirmar remoción del usuario"
        onClose={onClose}
        onCancel={onClose}
        onConfirm={onDeleteConfirm}
        type="delete"
      />
      <ActionDialog
        open={modalState === 'PASSWORD'}
        title="Restablecimiento de Contraseña"
        type="warning"
        onConfirm={onPasswordResetConfirm}
        onClose={onClose}
        isLoading={isLoading}
      >
        <div>
          Como administrador, puedes restablecer la contraseña del usuario{' '}
          <Typography component="span" sx={{ fontWeight: '700' }}>
            {selectedUser?.fullName}
          </Typography>
          . Esto proporcionará un acceso inmediato a la cuenta con la nueva contraseña asignada.
        </div>
      </ActionDialog>
      <ActionDialog
        open={modalState === 'PASSWORD_CONFIRM'}
        title="Confirmación de restablecimiento de contraseña"
        type="success"
        closeText="Cerrar"
        onClose={onClose}
        onCancel={onClose}
      >
        <div>
          La contraseña del usuario{' '}
          <Typography component="span" sx={{ fontWeight: '700' }}>
            {selectedUser?.fullName}{' '}
          </Typography>
          ha sido exitosamente restablecida. Asegúrate de informar al usuario sobre la nueva
          contraseña asignada para garantizar un acceso sin problemas.
        </div>
        <CopyAction password={newPassword || ''} />
      </ActionDialog>
    </UsersLayout>
  );
};

export default Users;

import React from 'react';
import Dropzone, { DropzoneState, FileRejection } from 'react-dropzone';
import Box from '@mui/material/Box';
import If from '@components/If';
import { CustomError } from '@core/types';
import ReadyState from './ReadyState';
import RejectState from './RejectState';
import useSx from './sx';
import validate from './validate';

export interface DragDropManagerProps {
  error?: CustomError;
  onFileSelecting?: VoidFunction;
  onFileRejected?: (reason: CustomError) => void;
  onFileSelected?: (file: File | undefined) => void;
}

const DragDropManager = ({
  error,
  onFileSelecting,
  onFileRejected,
  onFileSelected,
}: DragDropManagerProps) => {
  const sx = useSx();

  const handleDropAccepted = (files: File[]) => onFileSelected?.(files?.[0]);

  const handleDropRejected = (files: FileRejection[]) => {
    const [file] = files;
    const err = file.errors?.[1] || file.errors[0];

    onFileRejected?.(err as CustomError);
  };

  const handleDragEnter = () => {
    onFileSelecting?.();
  };

  return (
    <Dropzone
      validator={validate}
      accept={{ 'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png'] }}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
      onDragEnter={handleDragEnter}
    >
      {({ isDragActive, isDragReject, getRootProps, getInputProps }: DropzoneState) => {
        const rootProps = getRootProps();
        const { onClick } = rootProps;

        return (
          <>
            <Box
              className="DragDropManager-root"
              sx={sx.root}
              {...rootProps}
              onClick={(e) => {
                onFileSelecting?.();
                onClick?.(e);
              }}
            >
              <If
                condition={!error && !error}
                fallback={
                  <RejectState
                    inputProps={getInputProps()}
                    message={error?.message || error?.message || ''}
                  />
                }
              >
                <ReadyState
                  inputProps={getInputProps()}
                  isDragActive={isDragActive}
                  isDragReject={isDragReject}
                />
              </If>
            </Box>
          </>
        );
      }}
    </Dropzone>
  );
};

export default DragDropManager;

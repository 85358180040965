import { patch } from '@core/api/baseApi';
import { UpdatePriorityResponse } from '@core/types';

const updateStudyPriority = async (studyInstanceUid: string, priority: boolean): Promise<UpdatePriorityResponse> => 
    await patch(`/Studies/update-priority`, {
        studyInstanceUid,
        priority
    });

export default updateStudyPriority;

import { get } from '@core/api/baseApi';

const getEditableReport = async (
  studyInstanceUid: string,
  sopInstanceUid: string,
): Promise<{ encodedDocument: string; title: string }> =>
  await get<{ encodedDocument: string; title: string }>(
    `/studies/${studyInstanceUid}/reports/${sopInstanceUid}/editable`,
  );

export default getEditableReport;

import SxDef from '@core/types/SxDef';

const useSx = (sidebarCollapsed: boolean): SxDef => ({
  root: {
    display: { xs: 'none', sm: 'block' },
    '& .MuiDrawer-paper': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      width: sidebarCollapsed ? '70px' : '200px',
      height: '100%',
      backgroundColor: (theme) =>
        theme.palette.mode === 'dark' ? theme.palette.shade?.[200] : theme.palette.shade?.[50],
      transition: 'width 0.3s'
    },
  },
  iconContainer: {
    width: '100%',
    display: {xs: 'none', sm: 'flex'},
    justifyContent: 'center',
    padding: '1rem 0'
  },
  iconButton: {
    border: 'none',
    textAlign: 'center',
    color: (theme) => theme.palette.mode === 'dark' ? theme.palette.shade?.[900] : theme.palette.shade?.[500],
    '& .MuiSvgIcon-root': {
      fontSize: '2.8rem', 
    },
    '&:hover': {
      border: 'none',
    },
  }
});

export default useSx;

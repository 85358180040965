import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => {
  return {
    root: {
      zIndex: 20000,
      '& .MuiDialog-paper': {
        backgroundColor: (theme) => theme.palette.shade?.[50],
        backgroundImage: 'none',
        width: '100%',
      },
      textAlign: 'center',
      '.dialog-content': { display: 'flex', flexDirection: 'column', gap: '1rem' },
      '.circular-progress': { margin: 'auto' },
      '.dialog-form': {
        flexDirection: { xs: 'column-reverse', md: 'row' },
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
      },
      '.variable-toggler': {
        width: '100%',
        display: { xs: 'flex', md: 'none' },
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  };
};

export default useSx;

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import If from '@components/If';
import { TemplateFolder } from '@core/types';
import useSx from './sx';

const Breadcrumbs = ({
  breadcrumbs,
  onBreadcrumbClick,
}: {
  breadcrumbs: TemplateFolder[];
  onBreadcrumbClick: (folder: TemplateFolder, index: number) => void;
}) => {
  const sx = useSx();
  return (
    <Box sx={sx.breadcrumbsContainer}>
      {breadcrumbs.map((folder, index) => (
        <If
          condition={index !== breadcrumbs.length - 1}
          key={folder.name}
          fallback={<Typography key={folder.name}>{folder.name}</Typography>}
        >
          <Typography
            key={folder.name}
            onClick={() => onBreadcrumbClick(folder, index)}
            sx={sx.clickableBreadcrumb}
          >
            {folder.name}
          </Typography>
          <Typography>{`>`}</Typography>
        </If>
      ))}
    </Box>
  );
};

export default Breadcrumbs;

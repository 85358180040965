import React from 'react';
import { Formik } from 'formik';
import Box from '@mui/material/Box';
import DropdownWithLabel from '@components/DrodpdownWithLabel';
import InputWithLabel from '@components/InputWithLabel';
import useSnackbar from '@core/hooks/useSnackbar';
import Actions from './Actions';
import { inputs, dropdownOptions } from './InputValues';
import validationSchema from './formValidation';
import useSx from './sx';
import useFormState from './useFormState';

const Form = () => {
  const { initialValues, updateUser, isLoading } = useFormState();
  const showSnackbar = useSnackbar();
  const sx = useSx();

  const handleUpdateUser = async (values: typeof initialValues) => {
    await updateUser(values);

    showSnackbar({
      message: 'Perfil actualizado con éxito.',
      title: 'Información actualizada',
      type: 'success',
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleUpdateUser}
    >
      {({
        values: { gender, ...updatedValues },
        handleSubmit,
        handleChange,
        errors,
        resetForm,
      }) => (
        <Box onSubmit={handleSubmit} component="form" sx={sx.root}>
          {(Object.keys(updatedValues) as Array<keyof typeof updatedValues>).map((input) => (
            <InputWithLabel
              key={input}
              label={inputs[input].label}
              name={input}
              onChange={handleChange}
              value={updatedValues[input]}
              placeholder={inputs[input].placeholder}
              required={!!inputs[input].required}
              error={!!errors[input]}
              helperText={errors[input]}
              startAdornment={inputs[input].startAdornament}
              disabled={inputs[input].disabled}
              disableUnderline
            />
          ))}
          <DropdownWithLabel
            label={inputs.gender.label}
            name={inputs.gender.name}
            onChange={handleChange}
            value={gender}
            placeholder={inputs.gender.placeholder}
            required={!!inputs.gender.required}
            error={!!errors.gender}
            helperText={errors.gender}
            options={dropdownOptions}
            variant="filled"
          />
          <Actions resetForm={resetForm} isLoading={isLoading} />
        </Box>
      )}
    </Formik>
  );
};

export default Form;

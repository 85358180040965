import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  datePicker: {
    display: {
      xs: 'none',
      md: 'flex',
    },
  },
  datePickerMobile: {
    gap: '.5rem',
    display: {
      xs: 'flex',
      md: 'none',
    },
  },
  selectSmall: {
    textAlign: 'center',
    width: '30%',
  },
  selectBig: {
    textAlign: 'center',
    width: '40%',
  },
  selectSmallPlaceholder: {
    textAlign: 'center',
    width: '30%',
    '& .MuiInputBase-input ': {
      opacity: 0.38,
    },
  },
  selectBigPlaceholder: {
    textAlign: 'center',
    width: '40%',
    '& .MuiInputBase-input ': {
      opacity: 0.38,
    },
  },
});

export default useSx;

import React, { createContext, PropsWithChildren, useState, useContext, Dispatch, SetStateAction } from 'react';

interface LoadingContextType {
  loadingApp: boolean;
  setLoadingApp: Dispatch<SetStateAction<boolean>>;
}

const defaultContextValues: LoadingContextType = {
  loadingApp: true,
  setLoadingApp: () => {},
};

const LoadingContext = createContext<LoadingContextType>(defaultContextValues);

export const useLoading = () => useContext(LoadingContext);

const LoadingAppProvider = ({ children }: PropsWithChildren) => {
  const [loadingApp, setLoadingApp] = useState<boolean>(true);

  return (
    <LoadingContext.Provider value={{ loadingApp, setLoadingApp }}>
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingAppProvider;

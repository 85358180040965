import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import ViewerDialog from '@components/ViewerDialog';
import { ModalUploadAudioFileProps } from './ModalUploadAudioFileProps';
import useSx from './sx';

const ModalUploadAudioFile: React.FC<ModalUploadAudioFileProps> = ({
  open,
  onConfirm,
  onCancel,
  confirmText = 'Confirmar',
  cancelText = 'Cancelar',
  isLoadingFile,
}) => {
  const sx = useSx();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
  };

  const handleClose = () => {
    setSelectedFile(null);
    onCancel();
  };

  return (
    <ViewerDialog
      open={open}
      onClose={handleClose}
      title="Adjunta un archivo"
      subtitle="Sube tu archivo de audio con la grabación del reporte"
    >
      <Box sx={sx.containerBox}>
        <input
          accept="audio/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span" sx={{ mt: 2 }}>
            Seleccionar Archivo
          </Button>
        </label>
        <If condition={!!selectedFile}>
          <Typography variant="subtitle1">{selectedFile?.name}</Typography>
        </If>
      </Box>

      <Box sx={sx.actionsButtonContainer}>
        <ActionButton color="secondary" variant="outlined" text={cancelText} onClick={handleClose} />
        <ActionButton
          isLoading={isLoadingFile}
          color="primary"
          variant="contained"
          text={confirmText}
          onClick={() => onConfirm(selectedFile)}
        />
      </Box>
    </ViewerDialog>
  );
};

export default ModalUploadAudioFile;

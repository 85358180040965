import React from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import If from '@components/If';
import { OrganizationTemplateModel } from '@core/types/OrganizationTemplate';
import { formatDate } from '@core/utils/formatDate';
import TemplateCardProps from './TemplateCardProps';
import useSx from './sx.';

const TemplateCard = ({
  template,
  onChangeTemplateClick,
  onDeleteClick,
  onViewClick,
  onMarkDefault,
  disabledActions,
}: TemplateCardProps) => {
  const sx = useSx();
  const onClickCallback = (cb: (template: OrganizationTemplateModel) => void) => () => {
    cb(template);
  };

  return (
    <Box
      padding="10px 15px"
      borderRadius="10px"
      display="flex"
      alignItems="center"
      gap="10px"
      minHeight="90px"
      bgcolor={(theme) => theme.palette.shade?.[300]}
      key={template.templateId}
      sx={sx.root}
    >
      <InsertDriveFileIcon />
      <Box>
        <Typography fontSize="0.8rem">{template.title || 'Sin titulo'}</Typography>
        <Typography fontSize="0.8rem" fontWeight="100">
          {formatDate(template.createdDate)}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end" marginLeft="auto">
        <If condition={!disabledActions} fallback={<CircularProgress className="loading" />}>
          <Tooltip title="Visualizar">
            <IconButton onClick={onClickCallback(onViewClick)}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar">
            <IconButton onClick={onClickCallback(onDeleteClick)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Actualizar">
            <IconButton onClick={onClickCallback(onChangeTemplateClick)}>
              <AutorenewIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Marcar como predeterminado">
            <IconButton onClick={onClickCallback(onMarkDefault)}>
              <If condition={template.isDefault} fallback={<BookmarkBorderIcon />}>
                <BookmarkIcon />
              </If>
            </IconButton>
          </Tooltip>
        </If>
      </Box>
    </Box>
  );
};

export default TemplateCard;

import { RibbonButton } from 'roosterjs-react';
import applyListItemStyleWrap from '../utils/applyListItemStyleWrap';
import { ButtonFactory } from './buttonRegistryFactory';

const SPACING_OPTIONS = ['1', '1.1', '1.2', '1.3', '1.4', '1.6', '1.7'];
const NORMAL_SPACING = 1;

function findClosest(lineHeight?: string): string {
  if (typeof lineHeight === 'undefined' || Number.isNaN(+lineHeight)) {
    return '';
  }
  const query = +lineHeight / NORMAL_SPACING;
  return SPACING_OPTIONS.find((opt) => Math.abs(query - +opt) < 0.05) as string;
}

const lineHeightSelector: ButtonFactory = (): RibbonButton<string> => {
  return {
    key: 'buttonNameSpacing',
    unlocalizedText: 'Spacing',
    iconName: 'LineSpacing',
    dropDownMenu: {
      items: SPACING_OPTIONS.reduce((map, size) => {
        map[size] = size;
        return map;
      }, {} as Record<string, string>),
      getSelectedItemKey: (formatState) => findClosest(formatState.lineHeight),
      allowLivePreview: true,
    },
    onClick: (editor, size) => {
      applyListItemStyleWrap(
        editor,
        'line-height',
        (element, isInnerNode) => {
          element.style.lineHeight = isInnerNode ? '' : `${+size * NORMAL_SPACING}`;
        },
        'setLineHeight',
      );
    },
  };
};

export default lineHeightSelector;

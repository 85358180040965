import React, { SyntheticEvent, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { AutocompleteChangeReason, AutocompleteValue, Typography } from '@mui/material';
import Autocomplete, { AutocompleteChangeDetails } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import useTags from '../../../../../hooks/useTags';
import filterOptions from './filterOptions';
import { ADD_ITEM_TOKEN } from './filterOptions';
import useSx from './sx';

export { ADD_ITEM_TOKEN } from './filterOptions';

export type TagControlChangeEvent = (
  event: SyntheticEvent,
  value: AutocompleteValue<string, true, undefined, true>,
  reason: AutocompleteChangeReason,
  details?: AutocompleteChangeDetails<string>,
) => void;

export interface TagControlProps {
  tags: string[];
  readOnly?: boolean;
  onChange?: TagControlChangeEvent;
}

const TagControl = ({ tags, readOnly = false, onChange }: TagControlProps) => {
  const sx = useSx(readOnly);
  const [inputValue, setInputValue] = useState('');
  const { tagList } = useTags(inputValue);

  const setInputValueDebounced = useMemo(
    () => debounce((value: string) => setInputValue(value), 300, { leading: true }),
    [],
  );

  return (
    <Box className="EvoTagControl-root" sx={sx.root}>
      <Box className="EvoTagControl-input">
        <Box className="EvoTagControl-inputAdornment">
          <p>Etiquetas</p>
        </Box>
        <Autocomplete
          id="EvoTagControl-autocomplete"
          autoComplete
          clearOnBlur
          disableClearable
          disablePortal
          filterOptions={filterOptions}
          filterSelectedOptions
          freeSolo
          fullWidth
          readOnly={readOnly}
          includeInputInList
          multiple
          // options={inputValue === '' ? [] : tagList ?? []}
          options={tagList ?? []}
          selectOnFocus
          value={tags}
          onChange={onChange}
          onFocus={() => setInputValue('')}
          onInputChange={(event, newInputValue) => {
            setInputValueDebounced(newInputValue);
          }}
          sx={sx.autocomplete}
          renderInput={(params) => {
            return <TextField 
              {...params} 
              variant="outlined" 
            />;
          }}
          renderOption={(props, option) => {
            // if (typeof option !== 'string') {
            //   return;
            // }

            return option?.startsWith(ADD_ITEM_TOKEN) ? (
              <li {...props}>
                <Typography component="span" fontStyle="italic">
                  {option}
                </Typography>
              </li>
            ) : (
              <li {...props}>{option}</li>
            );
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                variant="outlined"
                label={option?.toLowerCase()}
                size="small"
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </Box>
    </Box>
  );
};

export default TagControl;

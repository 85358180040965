import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import useSx from './sx';

export interface SubtitleProps {
  subtitle: ReactNode;
}

const Subtitle = ({ subtitle }: SubtitleProps) => {
  const sx = useSx();

  return subtitle ? (
    <Grid item xs sx={sx.root}>
      {subtitle}
    </Grid>
  ) : null;
};

export default Subtitle;

import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import If from '@components/If';
import Pagination from '@components/Pagination';
import formatToSpanishDate from '@core/utils/formatDateToSpanishDate';
import useGetSharedStudyRecords from 'app/features/studies/hooks/useGetSharedStudyRecords';
import HistoryProps from './HistoryProps';
import useSx from './sx';

const History = ({ studyInstanceUid }: HistoryProps) => {
  const sx = useSx();

  const [page, setPage] = useState(1);
  const { sharedStudyRecords, isLoading } = useGetSharedStudyRecords(
    studyInstanceUid,
    { page, pageSize: 5 },
    true,
  );

  const hasRecords = (sharedStudyRecords?.items.length || 0) > 0;

  return (
    <If condition={!isLoading} fallback={<CircularProgress sx={sx.progress} />}>
      <If
        condition={hasRecords}
        fallback={<Typography sx={sx.emptyRecords}>El estudio aún no se ha compartido.</Typography>}
      >
        <Table sx={sx.root} component={Paper}>
          <TableHead>
            <TableRow>
              <TableCell>Contacto</TableCell>
              <TableCell>Fecha</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sharedStudyRecords?.items.map((studyRecord) => (
              <TableRow key={studyRecord.recipientContact + studyRecord.sharedStudyRecordId}>
                <TableCell>{studyRecord.recipientContact}</TableCell>
                <TableCell>
                  {formatToSpanishDate(studyRecord.createdDate, { full: true })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          sx={sx.pagination}
          pageCount={sharedStudyRecords?.pageCount || 0}
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          currentPage={sharedStudyRecords?.page || 0}
          totalCount={sharedStudyRecords?.totalCount || 0}
          pageSize={5}
        />
      </If>
    </If>
  );
};

export default History;

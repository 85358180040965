import { AxiosResponse } from 'axios';

export function handleResponse<T>(response: AxiosResponse<T>): T {
  if (response.data) {
    return response.data;
  }

  return response as T;
}

export function handleError(err: any): any {
  if (err.response?.status) {
    const reject = {
      statusCode: err.response?.status,
      message: err.response?.data?.message || err.response?.data,
    };

    return reject;
  }

  if (err.message === 'Network Error') {
    return { statusCode: 503 }; // handle this as a service unavailable
  }

  return { statusCode: 453 }; // custom status code to indicate internal application error
}

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        position: 'fixed', 
        alignSelf: 'flex-end', 
        zIndex: 10
    }
});

export default useSx;

import React, { useEffect, useState } from 'react';

export interface ContentTitleProps {
  title?: string;
}

/**
 * Changes the tile of the window/page
 * @param title: the title to set to the window/page
 * @returns emtpy element
 */
const ContentTitle = ({ title }: ContentTitleProps) => {
  const [currentTitle, setCurrentTitle] = useState('');

  const instanceName = process.env.REACT_APP_TITLE ?? '';
  const pageTitle = title ? `${title} | ${instanceName}` : instanceName;

  useEffect(() => {
    if (currentTitle !== pageTitle) {
      document.title = pageTitle;
      setCurrentTitle(pageTitle);
    }
  }, [pageTitle, currentTitle]);

  return <></>;
};

export default ContentTitle;

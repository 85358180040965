import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        borderRadius: 25,
        padding: '0.2rem 0.5rem',
        width: {xs:'100%', md: '25%'},
        marginTop: {xs: '1.5rem', md: '0'},
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: 'none'
        },
        '::before': {
            borderBottom: 'none'
        },
        '::after': {
            borderBottom: 'none'
        }
    },
});

export default useSx;

async function createFileFromUrl(
  url: string,
  filename: string,
  options?: FilePropertyBag | undefined,
) {
  try {
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], filename, options);
    return file;
  } catch {
    return null;
  }
}

export default createFileFromUrl;

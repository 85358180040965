import React from 'react';
import useProfile from '@core/hooks/useProfile';
import { UserItem } from '@core/types';
import useSendAssignedNotification from '../../hooks/useSendAssignedNotification';
import { WithSendAssignedNotificationProps } from './withSendAssignedNotificationProps';

function withSendAssignedNotification<P extends object>(
  WrappedComponent: React.ComponentType<P & WithSendAssignedNotificationProps>,
): React.FC<P> {
  return function WithSendAssignedNotification(props: P) {
    const { sendAssignedNotification, isLoading: isSendingNotification } = useSendAssignedNotification();
    const { organization } = useProfile();

    const handleSendNotification = async (
      studyInstanceUid: string,
      assignedUser: UserItem | undefined,
    ) => {
      if (
        assignedUser &&
        studyInstanceUid
      ) {
        try {
          const orgId =
            organization?.organizationId || sessionStorage.getItem('organizationId') || '';
          const payload = {
            userUid: assignedUser.userUid,
            organizationId: orgId,
            studyInstanceUid: studyInstanceUid,
            userName: assignedUser.fullName,
            address: assignedUser.emailAddress || '',
            language: 'es_MX',
          };
          await sendAssignedNotification(payload);
        } catch (error) {
          console.error(error);
        }
      }
    };

    return <WrappedComponent {...props} handleSendNotification={handleSendNotification} isSendingNotification={isSendingNotification} />;
  };
}

export default withSendAssignedNotification;

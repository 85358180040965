import { patch } from '@core/api/baseApi';

export interface ChangePasswordRequest {
  userUid?: string;
  oldPassword?: string;
  newPassword?: string;
}

const changePassword = ({
  userUid,
  oldPassword,
  newPassword,
}: ChangePasswordRequest): Promise<any> =>
  patch('/Users/password', {
    userUid,
    oldPassword,
    newPassword,
  });

export default changePassword;

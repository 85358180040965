import React from 'react';
import Box from '@mui/material/Box';
import MuiPagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import getPageInfo from '@core/utils/getPageInfo';
import useFilters from '../../hooks/useFilters';
import useSx from './useSx';

const Pagination = ({
  pageCount,
  page,
  pageSize,
  totalCount,
}: {
  pageCount: number;
  page: number;
  pageSize: number;
  totalCount: number;
}) => {
  const [, setFilter] = useFilters();
  const sx = useSx();
  const message = getPageInfo(page, pageSize, totalCount);

  return (
    <Box display="flex" alignItems="center" gap="10px">
      <Typography display={{ xs: 'none', md: 'inline-block' }} textTransform={'none'}>
        {message}
      </Typography>
      <MuiPagination
        sx={sx.root}
        count={pageCount}
        page={page || 1}
        onChange={(e, value) => {
          setFilter('page', value.toString());
        }}
      />
    </Box>
  );
};

export default Pagination;

import { useState } from 'react';
import useSnackbar from '@core/hooks/useSnackbar';
import { EmailOrPhoneFormRequest, WhatsappPayloadType } from '@core/types';
import shareByEmail from '../api/shareByEmail';
import shareByWhatsapp from '../api/shareByWhatsapp';

const useShareByEmailOrPhone = (studyInstanceUid: string) => {
  const [isSharing, setIsSharing] = useState(false);
  const showSnackbar = useSnackbar();

  const share = async (request: EmailOrPhoneFormRequest ) => {
    setIsSharing(true);

    const payload = {
      email: request.emailOrPhone,
      name: request.name,
      language: request.language,
    }

    try {
      const response = await shareByEmail(studyInstanceUid, payload);
      if (typeof response === 'undefined') {
        throw new Error('Error al compartir')
      }
      showSnackbar({ title: 'Estudio compartido', message: 'Estudio se ha compartido con éxito.', type: 'success' });
    } catch (error) {
      showSnackbar({ title: 'Error al compartir', message: 'Intentelo más tarde', type: 'error' });
      return;
    } finally {
      setIsSharing(false);
    }
  };

  const shareWhatsapp = async (request: EmailOrPhoneFormRequest ) => {
    setIsSharing(true);
 
    const payload: WhatsappPayloadType = {
      ...request,
      phoneNumber: request.emailOrPhone,
    };

    try {
      const response = await shareByWhatsapp(studyInstanceUid, payload);

      if (typeof response === 'undefined' || !response.success) {
        throw new Error('Error al compartir')
      }
      showSnackbar({ title: 'Estudio compartido', message: 'Estudio se ha compartido con éxito.', type: 'success' });
    } catch (error) {
      showSnackbar({ title: 'Error al compartir', message: 'Intentelo más tarde', type: 'error' });
      return;
    } finally {
      setIsSharing(false);
    }
  };

  return { share, shareWhatsapp, isSharing };
};

export default useShareByEmailOrPhone;

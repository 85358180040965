import React, { useRef } from 'react';
import { Formik, Form, ErrorMessage, FormikState } from 'formik';
import { Box, Divider, Typography } from '@mui/material';
import FileInput from '@components/FileInput/FileInput';
import Actions from './Actions';
import { LogoProps } from './LogoProps';
import { validationSchema } from './formValidation';
import useLogoState from './useLogoState';

interface FileInputRef {
  clearFileInput: () => void;
}

const Logo: React.FC<LogoProps> = ({ enabled }) => {
  const fileInputDarkRef = useRef<FileInputRef>(null);
  const fileInputLightRef = useRef<FileInputRef>(null);

  const {
    sx,
    showSnackbar,
    isUploading,
    customLogoLight,
    setCustomLogoLight,
    customLogoDark,
    setCustomLogoDark,
    postOrganizationLogo,
    deleteOrganizationLogo,
    isDeleting
  } = useLogoState();

  interface FormValues {
    logoDark: File | string | undefined;
    logoLight: File | string | undefined;
    mode: 'dark' | 'light';
  }

  const initialValues: FormValues = {
    logoDark: customLogoDark,
    logoLight: customLogoLight,
    mode: 'dark'
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      if (!enabled) {
        throw new Error('Debes tener el paquete "Business" para usar este feature.')
      }

      const formData = new FormData();
  
      if (values.logoDark && values.mode === 'dark' && typeof values.logoDark !== 'string') {
        const blobDark = new Blob([values.logoDark], { type: values.logoDark.type });
        formData.append('file', blobDark);
        formData.append('Length', String(blobDark.size));
        formData.append('ContentType', values.logoDark?.type);
        formData.append('ContentDisposition', 'inline');
        formData.append('Name', values.logoDark?.name);
        formData.append('FileName', `custom-logo-dark.png`);
      }
  
      if (values.logoLight && values.mode === 'light'&& typeof values.logoLight !== 'string') {
        const blobLight = new Blob([values.logoLight], { type: values.logoLight.type });
        formData.append('file', blobLight);
        formData.append('Length', String(blobLight.size));
        formData.append('ContentType', values.logoLight?.type);
        formData.append('ContentDisposition', 'inline');
        formData.append('Name', values.logoLight?.name);
        formData.append('FileName', `custom-logo-light.png`);
      }

      formData.append('mode', values.mode);

      await postOrganizationLogo(formData);

      showSnackbar({
        type: 'success',
        title: 'Logo guardado',
        message: 'El logo se ha guardado correctamente.'
      });
    } catch (error) {
      if (!enabled) {
        showSnackbar({
          title: 'No se pudo guardar',
          message: 'Debes tener el paquete "Business" para usar este feature.'
        });
      } else {
        showSnackbar({
          type: 'error',
          title: 'Ocurrió un error',
          message: 'Error al guardar el logo.'
        });
      }
      console.error("Error al eliminar el archivo", error);
    }
  };

  const handleDelete = async (mode: 'dark' | 'light', resetForm: (nextState?: Partial<FormikState<FormValues>> | undefined) => void) => {
    if (!enabled) {
      showSnackbar({
        title: 'No se pudo borrar',
        message: 'Debes tener el paquete "Business" para usar este feature.'
      });
      return
    }
    deleteOrganizationLogo(mode, {
      onSuccess: () => {
        resetForm({
          values: {
            logoDark: mode === 'dark' ? undefined : customLogoDark,
            logoLight: mode === 'light' ? undefined : customLogoLight,
            mode: mode
          }
        });

        if (mode === 'dark') {
          fileInputDarkRef.current?.clearFileInput();
        } else {
          fileInputLightRef.current?.clearFileInput();
        }
  
        showSnackbar({
          type: 'success',
          title: 'Logo eliminado',
          message: 'El logo se ha removido correctamente.'
        });
      },
      onError: (error) => {
        showSnackbar({
          type: 'error',
          title: 'Ocurrió un error',
          message: 'Error al eliminar el logo.'
        });
        console.error("Error al eliminar el archivo", error);
      }
    });
  };
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => await handleSubmit(values)}
    >
      {({ values, setFieldValue, resetForm, submitForm }) => (
        <Form>
          <Box sx={sx.root}>
            <Box sx={sx.inputsContainer}>
              <Box sx={sx.inputBox}>
                <Typography sx={sx.label}>Logo para modo oscuro</Typography>
                <Box sx={sx.input}>
                  <FileInput
                    ref={fileInputDarkRef}
                    customName="Logo Custom Modo Oscuro"
                    customFile={customLogoDark}
                    onFileSelect={(fileOrEvent: File | React.ChangeEvent<HTMLInputElement>) => {
                      let file: File | null = null;

                      if (fileOrEvent instanceof File) {
                        file = fileOrEvent;
                      } else if (fileOrEvent.target.files && fileOrEvent.target.files.length > 0) {
                        file = fileOrEvent.target.files[0];
                      }
                      setFieldValue('logoDark', file);
                    }}
                    isDisabled={!!customLogoDark}
                  />
                  <ErrorMessage name="logoDark" component="div" className="errorMessage" />
                </Box>
                <Actions
                  isLoading={isUploading && values.mode === 'dark'}
                  isDeleting={isDeleting && values.mode === 'dark'}
                  resetForm={() => {
                    resetForm({ values: { logoLight: undefined, logoDark: undefined, mode: 'dark' } })
                    fileInputDarkRef.current?.clearFileInput();
                  }}
                  isDisabled={!values.logoDark || !!customLogoDark}
                  isDisabledDelete={!customLogoDark}
                  onDelete={() => {
                    setFieldValue('mode', 'dark');
                    handleDelete('dark', resetForm)
                  }}
                  onSubmit={() => {
                    setFieldValue('mode', 'dark');
                    submitForm();
                  }}
                />
              </Box>
              <Divider orientation="vertical" flexItem sx={sx.divider}  />
              <Box sx={sx.inputBox}>
                <Typography sx={sx.label}>Logo para modo claro</Typography>
                <Box sx={sx.input}>
                  <FileInput
                    ref={fileInputLightRef}
                    customName="Logo Custom Modo Claro"
                    customFile={customLogoLight}
                    onFileSelect={(fileOrEvent: File | React.ChangeEvent<HTMLInputElement>) => {
                      let file: File | null = null;

                      if (fileOrEvent instanceof File) {
                        file = fileOrEvent;
                      } else if (fileOrEvent.target.files && fileOrEvent.target.files.length > 0) {
                        file = fileOrEvent.target.files[0];
                      }
                      setFieldValue('logoLight', file);
                    }}
                    isDisabled={!!customLogoLight}
                  />
                  <ErrorMessage name="logoLight" component="div" className="errorMessage" />
                </Box>
                <Actions
                  isLoading={isUploading && values.mode === 'light'}
                  isDeleting={isDeleting && values.mode === 'light'}
                  resetForm={() => {
                    resetForm({ values: { logoLight: undefined, logoDark: undefined, mode: 'dark' } })
                    fileInputLightRef.current?.clearFileInput();
                  }}
                  isDisabled={!values.logoLight || !!customLogoLight}
                  isDisabledDelete={!customLogoLight}
                  onDelete={() => {
                    setFieldValue('mode', 'light');
                    handleDelete('light', resetForm)
                  }}
                  onSubmit={() => {
                    setFieldValue('mode', 'light');
                    submitForm();
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default Logo;

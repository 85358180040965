import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: (theme) => ({
    ...expandDisplayFlex({ a: 'center', j: 'space-between' }),
    gap: '15px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      ...expandDisplayFlex({ a: 'flex-start', d: 'column' }),
      gap: '10px',
    },
  }),
});

export default useSx;

const getDistanceFromTopOfLastElement = (element: HTMLElement) => {
  const childNodes = Array.from(element.childNodes) as Array<HTMLElement>;
  const excludedNodesIds = ['qr-code-link', 'footer-editor'];
  const filteredIds = childNodes.filter((s) => !s.id || (s.id && !excludedNodesIds.includes(s.id)));
  let lastElement = filteredIds[filteredIds.length - 1] as HTMLElement;

  if (!lastElement) {
    return 0;
  }

  let lastElementTop = lastElement?.getBoundingClientRect?.().top;

  while (lastElementTop === undefined && lastElement.previousSibling) {
    lastElement = lastElement.previousSibling as HTMLElement;
    lastElementTop = lastElement?.getBoundingClientRect?.().top;
  }

  // Obtener la posición del último elemento
  const containerTop = element.getBoundingClientRect().top;
  const distanceFromTop = lastElementTop + lastElement.clientHeight - containerTop;

  return distanceFromTop;
};

export default getDistanceFromTopOfLastElement;

import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import logo from '@assets/img/logo-white.png';
import CenterCenterStack from '@components/CenterCenterStack';
import If from '@components/If';
import { OrganizationInfo } from '@core/hooks/useOrganization';
import useUserUid from '@core/hooks/useUserUid';
import { useLoading } from '@core/providers/LoadingAppProvider';
import useUserOrganizations from '../../hooks/useUserOrganizations';
import Loading from './Loading';
import OrganizationList from './OrganizationList';
import useSx from './sx';

export interface OrganizationPickerProps {
  onOrganizationPicked: (organization: OrganizationInfo) => void;
}

const OrganizationSetter = ({ onOrganizationPicked }: OrganizationPickerProps) => {
  const { setLoadingApp } = useLoading();
  const userUid = useUserUid();
  const { organizations, isLoading } = useUserOrganizations(userUid);
  const sx = useSx();

  const handleOrgListClick = (organizationId: string) => {
    onOrganizationPicked({ organizationId, hasMoreOrganizations: true });
  };

  useEffect(() => {
    if (!isLoading && organizations.length === 1) {
      const [{ organizationProductId }] = organizations;
      onOrganizationPicked({ organizationId: organizationProductId, hasMoreOrganizations: false });
    }
  }, [isLoading, onOrganizationPicked, organizations]);

  useEffect(() => {
    if (!isLoading && organizations.length > 1) setLoadingApp(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations, isLoading])

  return (
    <If condition={!isLoading && organizations.length > 1}>
      <CenterCenterStack>
        <Paper sx={sx.root}>
          <Box component="img" src={logo} />
          <Typography variant="h5" component="p">
            Seleccione una organizaci&oacute;n
          </Typography>
          <Typography component="p">para continuar a Evodicom&reg;.</Typography>
          <If condition={!isLoading} fallback={<Loading />}>
            <OrganizationList organizations={organizations} onClick={handleOrgListClick} />
          </If>
        </Paper>
      </CenterCenterStack>
    </If>
  );
};

export default OrganizationSetter;

// custom.theme.ts
import merge from 'lodash.merge';
import { grey } from '@mui/material/colors';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import baseTheme from './base.theme';

const customLight = (customThemeData: any) => {
  const primary = customThemeData?.primary || '#0E70FF'; 
  const primaryDark = customThemeData?.primaryDark || '#1A4488'; 
  const primaryText = customThemeData?.primaryText || 'rgba(0, 14, 37, 0.85)'; 
  const primaryContrast = customThemeData?.primaryContrast || '#ffffff'; 
  const secondary = customThemeData?.secondary || '#1A4488'; 
  const secondaryContrast = customThemeData?.secondaryContrast || '#f7f7f7'; 
  const info = customThemeData?.info || '#5FA2FF'; 
  const positive = customThemeData?.positive || '#149511'; 
  const error = customThemeData?.error || '#A70D0D'; 
  const errorDark = customThemeData?.errorDark || '#870505'; 
  const errorContrast = customThemeData?.errorContrast || '#f7f7f7'; 
  const warning = customThemeData?.warning || '#DC7414'; 

  const brandPrimary = customThemeData?.brandPrimary || '#0E70FF'; 
  const brandSecondary = customThemeData?.brandSecondary || '#1A4488'; 

  const shades = {
    50: customThemeData?.shades?.[50] || '#ffffff',
    100: customThemeData?.shades?.[100] || '#e3e3e3',
    200: customThemeData?.shades?.[200] || '#bfbfbf',
    300: customThemeData?.shades?.[300] || '#adadad',
    400: customThemeData?.shades?.[400] || '#9c9c9c',
    500: customThemeData?.shades?.[500] || '#7d7d7d',
    600: customThemeData?.shades?.[600] || '#636363',
    700: customThemeData?.shades?.[700] || '#474747',
    800: customThemeData?.shades?.[800] || '#262626',
    900: customThemeData?.shades?.[900] || '#000000',
  };

  const myTheme: ThemeOptions = {
    palette: {
      mode: 'light',
      primary: {
        main: primary,
        dark: primaryDark,
        contrastText: primaryContrast,
      },
      secondary: {
        main: secondary,
        contrastText: secondaryContrast,
      },
      text: {
        primary: primaryText,
      },
      success: {
        main: positive,
      },
      error: {
        main: error,
        contrastText: errorContrast
      },
      info: {
        main: info,
      },
      warning: {
        main: warning,
      },
      shade: { ...shades },
      background: {
        default: shades[50],
      },
      action: {
        hover: shades[200],
      },
      brand: {
        main: brandPrimary,
        dark: brandSecondary,
        contrastText: primaryContrast
      }
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            background: shades[50],
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundImage: shades[200],
            borderRight: 'none',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)'
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            height: '36px',
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: shades[50],
            border: `1px solid ${shades[300]}`,
            '&.Mui-focused .MuiSelectInput-notchedOutline, &:hover .MuiSelectInput-notchedOutline':
              {
                border: `1px solid ${shades[500]}`,
              },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            height: '36px',
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: shades[50],
            border: `1px solid ${shades[300]}`,
            '&.Mui-focused .MuiInput-notchedOutline, &:hover .MuiInput-notchedOutline':
              {
                border: `1px solid ${shades[500]}`,
              },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: '36px',
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: shades[50],
            border: `1px solid ${shades[300]}`,
            '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
              {
                border: `1px solid ${shades[300]}`,
              },
          },
        },
      }, 
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: 'rgba(0, 46, 122, 0.9)',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {},
          head: {
            backgroundColor: shades[200],
            color: shades[700],
            fontWeight: 500,
            textTransform: 'uppercase'
          },
          body: {
            backgroundColor: shades[50],
            borderBottom: '1px solid rgba(224, 224, 224, 0.2)',
          }
        },
      },
      MuiButton: {
        styleOverrides: {
          'containedPrimary': {
            background: `linear-gradient(165deg, ${primary}, ${primaryDark})`,
            color: 'white',
            '&:hover': {
              background: `linear-gradient(300deg, ${primary}, ${primaryDark})`,
            },
            '.iconWithSpacing': {
              marginRight: '5px',
            },
            '&.Mui-disabled': {
              background: shades[300],
            }
          },
          'containedSecondary': {
            background: grey[200],
            color: '#0055dd',
            '&:hover': {
              background: grey[300],
            },
            '.iconWithSpacing': {
              marginRight: '5px',
            },
            '&.Mui-disabled': {
              background: grey[200],
              color: grey[500]
            }
          },
          'containedError': {
            background: `linear-gradient(180deg, ${error}, ${errorDark})`,
            color: 'white',
            '&:hover': {
              background: `linear-gradient(0deg, ${error}, ${errorDark})`,
            },
            '.iconWithSpacing': {
              marginRight: '5px',
            },
            '&.Mui-disabled': {
              background: grey[200],
              color: grey[500]
            }
          },
          'outlinedSecondary': {
            border: `1px solid ${secondary}`,
            color: secondary,
            '&:hover': {
              border: `1px solid ${info}`,
              color: info,
            },
            '.iconWithSpacing': {
              marginRight: '5px',
            },
            '&.Mui-disabled': {
              color: grey[500]
            }
          },
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: shades[50], 
            backgroundImage: 'none'
          }
        }
      },
    },
  };

  const newTheme: ThemeOptions = {};
  merge(newTheme, myTheme, baseTheme);

  return createTheme(newTheme);
}

export default customLight;

import { useQuery } from '@tanstack/react-query';
import getAssignedRadiologists from '../api/getAssignedRadiologists';

export const QUERY_KEY = 'ASSIGNED_RADIOLOGISTS_LIST';

const useGetAssignedRadiologists = (studyInstanceUid: string, enabled = false) => {
  const { data: assignedRadiologists, ...rest } = useQuery({
    queryKey: [QUERY_KEY, studyInstanceUid],
    queryFn: () => getAssignedRadiologists(studyInstanceUid),
    staleTime: 1000 * 60 * 60 * 12, // 12 hours
    cacheTime: 1000 * 60 * 60 * 12.5, // 12.5 hours
    retry: false,
    enabled
  });

  return { assignedRadiologists, ...rest };
};

export default useGetAssignedRadiologists;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  paper: {
    gap: '1rem',
    minWidth: '20%',
    paddingTop: '1rem',
    '.TemplateTag-Container': {
      maxHeight: '90vh',
      overflowY: 'auto',
      display: 'inherit',
      flexDirection: 'inherit',
      gap: 'inherit',
      padding: '0 2rem',
    },
  },
});

export default useSx;

import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
    root: {
        ...expandDisplayFlex({a: 'center'}),
        gap: '20px'
    }
});

export default useSx;

function findEditorNode(startNode: Node | undefined): HTMLElement | null {
  let node = startNode;
  while (node) {
    if (node.nodeType === Node.ELEMENT_NODE && (node as HTMLElement).classList.contains('editor')) {
      return node as HTMLElement;
    }
    node = node.parentNode as Node;
  }
  return null;
}
export default findEditorNode;

import { DateOptionsProps } from '../DatePickerWithLabelProps';

function generateNConsecutiveArray(n: number) {
  return Array.from({ length: n }, (_, i) => i + 1);
}

const thisYear = new Date();

export const YEARS_ARRAY: DateOptionsProps[] = [
  {
    label: 'año',
    value: '',
    key: 'YYYY',
  },
  ...generateNConsecutiveArray(120).map((num) => ({
    label: thisYear.getFullYear() - num + 1,
    value: thisYear.getFullYear() - num + 1,
    key: `YYYY-${thisYear.getFullYear() - num + 1}`,
  })),
];

export const MONTHS_ARRAY: DateOptionsProps[] = [
  {
    label: 'mes',
    value: '',
    key: 'MM',
  },
  ...generateNConsecutiveArray(12).map((num) => ({
    label: num,
    value: num - 1,
    key: `MM-${num}`,
  })),
];

export function getDays({
  month,
  year,
}: {
  month: number | '';
  year: number | '';
}): DateOptionsProps[] {
  let daysArray;
  switch (month) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      daysArray = generateNConsecutiveArray(31).map((num) => ({
        label: num,
        value: num,
        key: `DD-${num}`,
      }));
      break;
    case 2:
      daysArray =
        typeof year === 'number' && year % 4 === 0
          ? generateNConsecutiveArray(29).map((num) => ({
              label: num,
              value: num,
              key: `DD-${num}`,
            }))
          : generateNConsecutiveArray(28).map((num) => ({
              label: num,
              value: num,
              key: `DD-${num}`,
            }));
      break;
    default:
      daysArray = generateNConsecutiveArray(30).map((num) => ({
        label: num,
        value: num,
        key: `DD-${num}`,
      }));
  }
  return [
    {
      label: 'día',
      value: '',
      key: 'DD',
    },
    ...daysArray,
  ];
}

import SxDef from '@core/types/SxDef';
import { expandBorder, expandBorderColor, expandDisplayFlex } from '@core/utils/expandShorthandCss';
import setCssSize from '@core/utils/setCssSize';

const useSx = (): SxDef => ({
  root: {
    ...setCssSize(1),
    ...expandBorder('2px dashed'),
    ...expandBorderColor('error.main'),
    ...expandDisplayFlex({ j: 'center', a: 'center', d: 'column' }),
    cursor: 'pointer',
    color: 'error.light',
    p: 5,

    '& .MuiSvgIcon-root': {
      fontSize: '4.25rem',
    },

    '& .MuiTypography-root': {
      mt: 2,
      textAlign: 'center',

      '& span.MuiTypography-root': {
        textDecoration: 'underline',
      },
    },
  },
});

export default useSx;

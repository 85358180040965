function findPageIndex(currentNode: Node, editorDivs: HTMLElement[]): number {
  for (let pageIndex = 0; pageIndex < editorDivs.length; pageIndex++) {
    if (editorDivs[pageIndex] === currentNode) {
      return pageIndex;
    }
  }
  return -1; // Devuelve -1 si no se encuentra la página
}

export default findPageIndex;

import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import useSx from './sx';

const CloseButton = ({ onClick }: { onClick?: VoidFunction }) => {
  const sx = useSx();

  return (
    <IconButton size="large" sx={sx.root} onClick={onClick}>
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton;

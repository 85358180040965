import { post } from '@core/api/baseApi';

export interface GetStudyAuthorizationPayload {
  studyHash: string;
  patientBirthdate: Date;
}

export const getStudyAuthorization = async ({
  studyHash,
  patientBirthdate,
}: GetStudyAuthorizationPayload): Promise<{ token: string }> => {
  return await post(`/studies/shared/${studyHash}`, {
    patientBirthdate: patientBirthdate.toISOString(),
  });
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import If from '@components/If';
import CopyAction from 'app/features/users/components/CopyAction';
import UserInfoDialogProps from './UserInfoDialogProps';


const UserInfoDialog = ({ open, handleClose, user }: UserInfoDialogProps) => {
  const navigate = useNavigate();
  const surname = user?.surname ? ` ${user?.surname}` : '';
  const username = user?.givenName || '' + surname;

  const handleNavigation = () => {
    navigate('/settings/users');
  };

  return (
      <ActionDialog
        open={open}
        onClose={handleClose}
        onCancel={handleClose}
        onConfirm={handleNavigation}
        title='Información de usuario'
        type="info"
        closeText='Crear otro usuario'
        confirmText='Aceptar'
      >
        <Box display="flex" flexDirection="column" gap="15px">
            <Box display="flex" alignItems="center" gap="10px">
                <Typography fontWeight="bold">Nombre: </Typography>
                <Typography>{username} {surname}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="10px">
                <Typography fontWeight="bold">Correo: </Typography>
                <Typography>{user?.emailAddress || ''}</Typography>
            </Box>
            <If condition={!!user?.temporaryPassword}>
              <Box display="flex" alignItems="flex-start" flexDirection="column" gap="10px">
                  <Typography fontWeight="bold">Contraseña: </Typography>                   
                  <CopyAction password={user?.temporaryPassword || ''} />
              </Box>
            </If>
          </Box>
      </ActionDialog>
  );
};

export default UserInfoDialog;

import * as Yup from 'yup';

// This regex validates email addresses, allowing the letter "ñ" in the domain name.
// This is necessary because the Yup .email method does not permit "ñ".
const EMAIL_REGX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\}]+\.)+[a-zA-Z]{2,}))$/;

const validationSchema = Yup.object().shape({
  givenName: Yup.string()
    .min(1, 'Por favor introduzca un nombre')
    .required('Por favor introduzca un nombre'),
  surname: Yup.string()
    .min(1, 'Por favor introduzca un apellido')
    .required('Por favor introduzca un apellido'),
  emailAddress: Yup.string()
    .required('Por favor introduzca una dirección de correo')
    .matches(EMAIL_REGX, 'Por favor introduzca una dirección de correo válida'),
});
export default validationSchema;

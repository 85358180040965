import React from 'react';
import { Box } from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import UserAutoComplete from '@components/UserAutoComplete/UserAutoComplete';
import { SelectUserContentProps } from './SelectUserContentProps';
import useSelectUserContentState from './useSelectUserContentState';

const SelectUserContent = ({ onUserSelect }: SelectUserContentProps) => {
  const { setSelectedUser, navigate } = useSelectUserContentState();

  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <Box display="flex" justifyContent="flex-end">
        <ActionButton
          color="primary"
          variant="contained"
          text={'Crear usuario'}
          onClick={() => navigate('/settings/users/add')}
        />
      </Box>
      <UserAutoComplete
        searchRoles="Radiologist"
        onUserSelect={(value) => {
          if (value) {
            setSelectedUser(value);
            onUserSelect(value.userUid || '');
          } else {
            setSelectedUser(undefined);
            onUserSelect(null);
          }
        }}
        placeholder="Seleccionar médico radiólogo"
      />
    </Box>
  );
};

export default SelectUserContent;

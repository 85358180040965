import React, { useState } from 'react';
import useSetOrganizationTheme from '@core/hooks/useSetOrganizationTheme';
import useSnackbar from '@core/hooks/useSnackbar';
import useSx from './sx';

const useThemeOptionsState = () => {
  const sx = useSx();
  const [onlyDefault, setOnlyDefault] = useState<boolean>(true);
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { postOrganizationTheme, isLoading } = useSetOrganizationTheme();
  const showSnackbar = useSnackbar();

  return {
    sx,
    onlyDefault, 
    setOnlyDefault,
    showPicker, 
    setShowPicker,
    anchorEl, 
    setAnchorEl,
    postOrganizationTheme, 
    isLoading,
    showSnackbar
  };
};

export default useThemeOptionsState;

import React from 'react';
import { PopoverOrigin } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useChangeOrganizations from '@core/hooks/useChangeOrganizations';
import useProfile from '@core/hooks/useProfile';
import { UserOrganizationModel } from '@core/types';
import OrganizationsMenuProps from './OrganizationsMenuProps';
import useSx from './sx';

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const transformOrigin: PopoverOrigin = {
  vertical: 'center',
  horizontal: 'right',
};

const OrganizationsMenu = ({
  anchorElement,
  setAnchorElement,
  onClose,
}: OrganizationsMenuProps) => {
  const { userUid, organization, organizations } = useProfile();
  const changeOrganization = useChangeOrganizations();
  const sx = useSx();

  const onOrganizationChange = (organizationItem: UserOrganizationModel) => {
    changeOrganization({
      userUid,
      organizationProductId: organizationItem.organizationProductId,
      organizations,
    });
    localStorage.removeItem('savedSelectedFilters');
    setAnchorElement(undefined);
    onClose?.();
  };

  return (
    <Menu
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={(e) => {
        setAnchorElement(undefined);
      }}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      sx={sx.root}
    >
      {organizations?.map((organizationItem) => (
        <MenuItem
          onClick={() => {
            onOrganizationChange(organizationItem);
          }}
          sx={{
            backgroundColor: (theme) =>
              organizationItem.organizationProductId === organization?.organizationId
                ? theme.palette.shade?.[500]
                : '',
          }}
          key={organizationItem.organizationProductId}
        >
          {organizationItem.organizationName}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default OrganizationsMenu;

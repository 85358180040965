import { useMutation, useQueryClient } from '@tanstack/react-query';
import { User } from '@core/types/User';
import { QUERY_KEY as GET_USERS_QUERY_KEY } from '../../hooks/useUsers';
import { addUser } from '../api/addUser';

const useAddUser = () => {
  const queryClient = useQueryClient()
  const { isLoading, mutateAsync: asyncAddUser } = useMutation({
    mutationFn: (payload: User) => addUser({ ...payload}),
    mutationKey: ["AddUser"],
    onSuccess: () => {
      queryClient.invalidateQueries([GET_USERS_QUERY_KEY])
    }
 });

  return { isLoading, addUser: asyncAddUser };
};

export default useAddUser;

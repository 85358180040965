import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const skeletons = new Array(4).fill('');

const LoadingIndicator = () => {
  return (
    <Stack sx={{ p: 4, gap: 2 }}>
      {skeletons.map((s, i) => (
        <Skeleton key={i} animation="wave" variant="rounded" height="32px" />
      ))}
    </Stack>
  );
};

export default LoadingIndicator;

import React from 'react';
import { PieChart as RechartsPieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { Box, Typography } from '@mui/material';
import transformToPieChartData from 'app/features/subscription/utils/transformToPieChartData';
import CustomPieLabel from './CustomPieLabel';
import { PieChartComponentProps } from './PieChartComponentProps';

const PieChartComponent = ({ styles, subscriptionDetails }: PieChartComponentProps) => {
  const pieChartData = transformToPieChartData(subscriptionDetails);
  const colors = pieChartData.map((el) => el.color);
  return (
    <Box sx={styles.chartBox}>
      <ResponsiveContainer width="100%" height={200}>
        <RechartsPieChart>
          <Pie
            data={pieChartData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={CustomPieLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            stroke="#01070C"
            strokeWidth={2}
          >
            {pieChartData.map((entry, index) => (
              <Cell key={`cell-${entry.name}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
        </RechartsPieChart>
      </ResponsiveContainer>
      <Box sx={styles.pieChartDetailContainer}>
        <Typography variant="subtitle1" fontWeight={600} gutterBottom>
          Estudios
        </Typography>
        {pieChartData.map((item) => (
          <Box key={item.name} sx={styles.pieChartDetail}>
            <Box display="flex">
              <Box
                sx={{
                  backgroundColor: item.color,
                  ...styles.userChartColorIndicator,
                }}
              />
              <Typography variant="body1">{item.name}</Typography>
            </Box>
            <Box sx={styles.statsBox}>
              <Typography variant="body1" color="white">
                {item.value} / mes
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PieChartComponent;

import React, { useState } from 'react';
import { Box, Button, ButtonGroup, TextField, Typography } from '@mui/material';
import capitalizeText from '@core/utils/capitalizeText';
import macOsKeys from '@core/utils/macOsKeys';
import { VIEWER_HOTKEYS } from 'app/features/study/config/viewer-config';
import useSx from './sx';

type TypeOS = 'Windows' | 'MacOs';

const OS_ARRAY: TypeOS[] = ['Windows', 'MacOs'];

export default function HotkeysDisplay() {
  const [selectedOs, setSelectedOs] = useState<TypeOS>('Windows');
  const sx = useSx();

  return (
    <>
      <Box sx={sx.osSelector}>
        <Typography>Selecciona sistema operativo</Typography>
        <ButtonGroup sx={sx.switchButton}>
          {OS_ARRAY.map((os) => (
            <Button
              onClick={() => setSelectedOs(os)}
              disabled={selectedOs === os}
              variant="contained"
              key={os}
            >
              {os}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      <Box sx={sx.hotkeysContainer}>
        {VIEWER_HOTKEYS.map(({ label, keys }, i) => (
          <Box key={i} sx={sx.hotkey}>
            <Typography>{label}:</Typography>
            <TextField
              value={keys
                .map((str) => (selectedOs === 'MacOs' ? macOsKeys(str) : str))
                .map((str) => capitalizeText(str))
                .join(' + ')}
              sx={sx.keyDisplay}
            />
          </Box>
        ))}
      </Box>
    </>
  );
}

import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
    root: {        
        ...expandDisplayFlex({a: 'center'}),
        alignSelf: 'flex-end',
        gap: '10px',
        width: '100%'
    }
});

export default useSx;
import React, { useEffect, useRef } from 'react';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import PhotoIcon from '@mui/icons-material/Photo';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import useViewportSize from '@core/hooks/useViewportSize';
import { Solicitation } from '@core/types';
import ManagerComponentProps from '../../../types/ManagerComponentProps';
import DragDropHandler from './DragDropHandler';
import SelectedFileDisplay from './SelectedFileDisplay';
import useSolicitationManagerState from './useSolicitationManagerState';

function obtenerExtension(nombreArchivo: string): string {
  return '.' + nombreArchivo.split('.').pop();
}

function convertirAObjeto(file: File): Solicitation {
  return {
    attachmentKey: 0,
    attachmentType: obtenerExtension(file.name),
    blobUri: URL.createObjectURL(file),
    fileName: file.name,
  };
}

const SolicitationManager = ({ studyInstanceUid }: ManagerComponentProps) => {
  const {
    selectedSolicitation,
    setSelectedSolicitation,
    droppedFile,
    setDroppedFile,
    videoRef,
    showVideo,
    setShowVideo,
    error,
    setError,
    isLoading,
    solicitations,
    isUploading,
    uploadSolicitation,
    isDeleting,
    deleteSolicitationAsync,
    showSnackbar,
    sx,
    isAddingNewFile,
    setIsAddingNewFile,
    isUpdating,
    updateSolicitationAsync,
    editedImage,
    setEditedImage,
    croppedImageUrl,
    setCroppedImageUrl,
  } = useSolicitationManagerState(studyInstanceUid || '');

  const mobileDeviceInputRef = useRef<HTMLInputElement>(null);
  const { isLargeViewport } = useViewportSize();

  const isDesktop = !isLargeViewport;

  if (!studyInstanceUid) {
    setError({
      message: 'No podemos adjuntar su solicitud debido a que detectamos un estudio no válido',
      title: '',
      code: 'none',
    });
  }

  const lastSolicitation = solicitations?.[solicitations.length - 1] || null;

  const reset = () => setError(undefined);

  const handleClear = () => setDroppedFile(undefined);

  const handleFileSelected = (file?: File) => {
    setDroppedFile(file);
    setIsAddingNewFile(false);
    reset();
    const transformedToSolicitation = convertirAObjeto(file!);
    setEditedImage(transformedToSolicitation.blobUri);
    setSelectedSolicitation(transformedToSolicitation);
  };

  const handleMobileDeviceFileSelection = (file: File | undefined) => {
    if (isDesktop || !file) {
      return;
    }
    handleFileSelected(file);
  };

  const handleSaveChanges = async () => {
    if (!editedImage || !selectedSolicitation) {
      return;
    }

    try {
      const blob = await fetch(editedImage).then((res) => res.blob());
      const modifiedFile = new File([blob], selectedSolicitation.fileName, {
        type: selectedSolicitation.attachmentType,
      });

      const formData = new FormData();
      formData.append('file', modifiedFile, modifiedFile.name);

      await updateSolicitationAsync({
        formData,
        attachmentKey: selectedSolicitation?.attachmentKey,
      });

      showSnackbar({
        type: 'success',
        title: 'Cambios Guardados',
        message: 'Los cambios en la imagen se han guardado con éxito.',
      });
    } catch (e) {
      console.error('Error saving the changes:', e);
      showSnackbar({
        type: 'error',
        title: 'Error',
        message: 'Hubo un problema al guardar los cambios en la imagen.',
      });
    }
  };

  const handleFileSave = async () => {
    if (!droppedFile) {
      setError({
        message: 'Por favor seleccione un archivo primero',
        title: '',
        code: 'none',
      });

      return;
    }

    if (!!selectedSolicitation && selectedSolicitation.attachmentKey !== 0 && !!editedImage) {
      await handleSaveChanges();
      return;
    }

    const fileNameParts = droppedFile.name.split('.');
    if (fileNameParts.length < 2) {
      setError({
        message: 'El archivo seleccionado no tiene una extensión válida',
        title: '',
        code: 'invalid_file',
      });
      return;
    }

    if (!editedImage || !selectedSolicitation) {
      return;
    }
    try {
      setError(undefined);
      const formData = new FormData();

      const blob = await fetch(editedImage).then((res) => res.blob());
      const modifiedFile = new File([blob], selectedSolicitation.fileName, {
        type: selectedSolicitation.attachmentType,
      });
      formData.append('files', modifiedFile, droppedFile.name);
      await uploadSolicitation(formData);
      showSnackbar({
        type: 'success',
        title: 'Solicitud guardada',
        message: 'La solicitud se ha adjuntado con éxito.',
      });
      setDroppedFile(undefined);
      setSelectedSolicitation(null);
    } catch (err: any) {
      console.log(err);
      showSnackbar({
        type: 'error',
        title: 'Ocurrió un error',
        message: 'La solicitud no se pudo adjuntar.',
      });
    }
  };

  const handleCaptureImage = async () => {
    try {
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      );

      if (isMobileDevice) {
        mobileDeviceInputRef.current?.click();
        return;
      }

      const facingMode = isMobileDevice ? 'environment' : 'user';

      const constraints = {
        video: { facingMode },
      };

      const mediaStream = await navigator.mediaDevices
        .getUserMedia(constraints)
        .catch(() => navigator.mediaDevices.getUserMedia({ video: true }));

      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
        videoRef.current.play();
        setShowVideo(true);
      }
    } catch (err) {
      console.error('Error capturing image:', err);
      showSnackbar({
        type: 'error',
        title: 'Ocurrió un error',
        message: 'Error al capturar la imagen.',
      });
    }
  };

  const capturePhoto = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const canvas = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          if (blob) {
            const imageFile = new File([blob], 'captured_image.jpg', { type: 'image/jpeg' });
            setDroppedFile(imageFile);
            const transformedToSolicitation = convertirAObjeto(imageFile!);
            setEditedImage(transformedToSolicitation.blobUri);
            setSelectedSolicitation(transformedToSolicitation);
            setShowVideo(false);
            setIsAddingNewFile(false);
            if (videoElement.srcObject) {
              const tracks = (videoElement.srcObject as MediaStream).getTracks();
              tracks.forEach((track) => track.stop());
            }
          }
        }, 'image/jpeg');
      }
    }
  };

  const onAddNewFile = () => {
    setSelectedSolicitation(null);
    setIsAddingNewFile(true);
  };

  const onCancel = () => {
    handleClear();
    setShowVideo(false);
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => track.stop());
    }
    setIsAddingNewFile(false);
    const isUploadingImage = selectedSolicitation?.attachmentKey === 0;
    if (isUploadingImage) {
      onAddNewFile();
    }
  };

  const onDelete = async () => {
    try {
      await deleteSolicitationAsync(selectedSolicitation?.attachmentKey);
      setDroppedFile(undefined);
      showSnackbar({
        type: 'success',
        title: 'Solicitud eliminada',
        message: 'La solicitud se borró con éxito.',
      });
      const findNextSolicitation = (solicitations || [])?.findIndex(
        (s) => s.attachmentKey === selectedSolicitation?.attachmentKey,
      ); // Seleccionar la próxima solicitud según las condiciones dadas
      if (findNextSolicitation > 0) {
        setSelectedSolicitation(solicitations?.[findNextSolicitation - 1] || null);
        setEditedImage(solicitations?.[findNextSolicitation - 1]?.blobUri || null);
      } else if (findNextSolicitation === 0) {
        setSelectedSolicitation(solicitations?.[1] || null);
        setEditedImage(solicitations?.[1]?.blobUri || null);
      } else {
        setSelectedSolicitation(solicitations?.[0] || null);
        setEditedImage(solicitations?.[0]?.blobUri || null);
      }
    } catch (err) {
      console.error('Error al eliminar la solicitud:', err);
      showSnackbar({
        type: 'error',
        title: 'Ocurrió un error',
        message: 'Error al eliminar la solicitud.',
      });
    }
  };

  const onSelect = (file: Solicitation) => {
    setCroppedImageUrl(null);
    setSelectedSolicitation(file);
    setEditedImage(file.blobUri);
  };

  useEffect(() => {
    const loadBlobUriAsFile = async () => {
      if (selectedSolicitation && selectedSolicitation.blobUri) {
        try {
          const response = await fetch(selectedSolicitation.blobUri);
          const blob = await response.blob();
          const fileName =
            selectedSolicitation.fileName || `solicitud.${selectedSolicitation.attachmentType}`;
          const file = new File([blob], fileName, {
            type: `image/${selectedSolicitation.attachmentType}`,
          });
          setDroppedFile(file);
        } catch (err) {
          console.error('Error al cargar el archivo:', err);
        }
      }
    };

    loadBlobUriAsFile();
  }, [selectedSolicitation]);

  const isContentAvailable =
    !isAddingNewFile && Array.isArray(solicitations) && solicitations?.length > 0;

  const onSeeSolicitations = () => {
    setIsAddingNewFile(false);
    setSelectedSolicitation(lastSolicitation);
  };

  return (
    <If
      condition={!isLoading}
      fallback={
        <Box sx={sx.loading}>
          <CircularProgress />
        </Box>
      }
    >
      <input
        ref={mobileDeviceInputRef}
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        capture="environment"
        onChange={(e) => handleMobileDeviceFileSelection(e.target.files?.[0])}
      />
      <Stack className="SolicitationManager-root" sx={sx.root}>
        <If
          condition={
            (!droppedFile && (!Array.isArray(solicitations) || solicitations?.length === 0)) ||
            isAddingNewFile
          }
        >
          <>
            <DragDropHandler
              error={error}
              onFileSelected={handleFileSelected}
              onFileSelecting={() => reset()}
              onFileRejected={(err) => setError(err)}
            />
            <Box sx={sx.photographBox}>
              <video
                ref={videoRef}
                autoPlay
                style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '300px',
                  display: !showVideo ? 'none' : '',
                  marginBottom: '1rem',
                }}
              ></video>
              <Box display="flex" justifyContent="space-around" width="100%">
                <If condition={Array.isArray(solicitations) && solicitations?.length > 0}>
                  <ActionButton
                    text="Ver solicitudes"
                    onClick={onSeeSolicitations}
                    variant="contained"
                    color="primary"
                    icon={<PhotoIcon />}
                  />
                </If>
                <If
                  condition={showVideo}
                  fallback={
                    <ActionButton
                      text="Tomar una fotografía"
                      onClick={handleCaptureImage}
                      variant="contained"
                      color="primary"
                      className="SolicitationManager-takePhoto"
                      icon={<PhotoCameraIcon />}
                    />
                  }
                >
                  <ActionButton
                    text="Capturar foto"
                    onClick={capturePhoto}
                    variant="contained"
                    color="primary"
                    icon={<CenterFocusStrongIcon />}
                  />
                </If>
              </Box>
            </Box>
          </>
        </If>
        <If
          condition={
            isContentAvailable ||
            ((!Array.isArray(solicitations) || solicitations?.length === 0) && !!droppedFile)
          }
        >
          <SelectedFileDisplay
            fileInfo={droppedFile!}
            isUploading={isUploading}
            onClear={handleClear}
            isAddingNewFile={!isAddingNewFile}
            setEditedImage={setEditedImage}
            croppedImageUrl={croppedImageUrl}
            setCroppedImageUrl={setCroppedImageUrl}
            solicitations={solicitations}
            onSelect={onSelect}
            selectedSolicitation={selectedSolicitation}
            onAddNewFile={onAddNewFile}
            onAccept={handleFileSave}
            onCancel={onCancel}
            onDelete={onDelete}
            isLoading={isUploading || isUpdating}
            isDeleting={isDeleting}
          />
        </If>
      </Stack>
    </If>
  );
};

export default SolicitationManager;

import React from 'react';
import MuiMenuIcon from '@mui/icons-material/Menu'
import { IconButton } from '@mui/material';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import getSettingsItems from '../../api/getSettingsItems';
import MenuIconProps from './MenuIconProps';
import useSx from './sx';


const MenuIcon = ({ handleMenuClick }: MenuIconProps) => {
  const { hasAdminPermissions, hasOwnerPermissions } = useProfile()
  const allSettingsItems = getSettingsItems()
  
  const settingsItems = allSettingsItems
    .filter((setting) => 
      setting.enabled && setting.sections.dropdown 
      && ((hasAdminPermissions && setting.adminOnly && !setting.ownerOnly) 
      || (!hasAdminPermissions && !setting.adminOnly)
      || (hasOwnerPermissions && setting.ownerOnly))
    )

  const sx = useSx()

  return (
    <If condition={settingsItems.length > 0}>
      {
        handleMenuClick && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleMenuClick}
            edge="start"
            sx={sx.root}
        >
            <MuiMenuIcon sx={sx.icon} />
        </IconButton>
        )
      }
    </If>
  )
}

export default MenuIcon
// name date as in the action of giving a name to a date

export function nameTime(hh: number, mm: number): string {
  let hours = hh;
  hours = hours === 0 ? 12 : hours;
  hours = hours > 12 ? hours - 12 : hours;

  const minutes = mm < 10 ? `0${mm}` : mm;

  return `${hours}:${minutes} ${hh < 12 ? 'AM' : 'PM'}`;
}

export function nameDate(d: string): string {
  const date = new Date(d);
  const today = new Date();
  const yesterday = new Date(Date.now() - 86400000);
  const elevenMonthsAgo = new Date(Date.now() - 2.891e10);

  if (date.toDateString() === today.toDateString()) {
    return `Hoy`;
  }

  if (date.toDateString() === yesterday.toDateString()) {
    return 'Ayer';
  }

  if (date.getTime() <= elevenMonthsAgo.getTime()) {
    return date.toLocaleDateString('es-MX', { month: 'short', day: 'numeric', year: 'numeric' });
  }

  return `${date.toLocaleDateString('es-MX', { month: 'short', day: 'numeric' })}`;
}

export function formatDate(studyDate?: string, options?: { full: boolean }): string {
  if (!studyDate || studyDate.length === 0) {
    return 'No disponible';
  }
  const { full } = options ?? {};
  const date = new Date(studyDate);

  if (full) {
    return date.toLocaleDateString('es-MX', { month: 'short', day: 'numeric', year: 'numeric' });
  }

  if (date.toDateString() === new Date().toDateString()) {
    return nameTime(date.getHours(), date.getMinutes());
  } else {
    return nameDate(studyDate);
  }
}

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    position: 'fixed',
    alignSelf: 'flex-end',
  },
});

export default useSx;

function getNodesPath(currentNode: Node, rootEditor: Element) {
  const nodesPath: number[] = [];

  while (currentNode !== rootEditor) {
    const parentNode = currentNode.parentNode as Element;
    if (!parentNode) {
      // Exit if there is no parentNode
      break;
    }

    const childNodes = Array.from(parentNode.childNodes) as Array<Node>;
    const nodeIndex = childNodes.indexOf(currentNode);

    nodesPath.unshift(nodeIndex);
    currentNode = parentNode;
  }

  return { nodesPath, currentNode };
}

export default getNodesPath;

export default function extractElementFromArray<T>(
  array: T[],
  filterFunction: (elem: T) => boolean,
): [T[], T] {
  return array.reduce(
    (acc, curr) => {
      return filterFunction(curr) ? [acc[0], curr] : [[...acc[0], curr], acc[1]];
    },
    [[] as Array<T>, {} as T],
  );
}

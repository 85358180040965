import { post } from '@core/api/baseApi';
import { AddTagResponse } from '@core/types';

export interface AddStudyTagRequest {
  studyInstanceUid: string;
  tagTitle: string;
}

const addStudyTag = async ({
  studyInstanceUid,
  tagTitle,
}: AddStudyTagRequest): Promise<AddTagResponse> =>
  await post(`/studies/${studyInstanceUid}/tags/${tagTitle}`);

export default addStudyTag;

import { post } from '@core/api/baseApi';
import { AddStudyCommentResponse } from '@core/types';

export type Payload = {
  studyInstanceUid: string;
  commenter: string;
  content: string;
  taggedUsers: string[];
};

export const addStudyComment = (comment: Payload): Promise<AddStudyCommentResponse> =>
  post(`/studies/comments`, comment);

import { put } from '@core/api/baseApi';
import { notificationStatusDTO } from '@core/constants/notificationStatus';
import { CustomErroHandlerResponse, GetNotificationMessageResponse } from '@core/types';

interface NotificationUpdateRequest {
  notificationToUpdate: NotificationUpdateDto[];
}

export type NotificationStatus = (typeof notificationStatusDTO)[keyof typeof notificationStatusDTO];

export interface NotificationUpdateDto {
  notificationMessageId: string;
  isSeen: NotificationStatus;
}
export const updateNotificationsStatus = (
  request: NotificationUpdateRequest,
): Promise<GetNotificationMessageResponse | CustomErroHandlerResponse> =>
  put('/NotificationMessage/status', request);

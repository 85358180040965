import SxDef from '@core/types/SxDef';

const useSx = (label: string | undefined): SxDef => ({
  root: {
    mr: 4,
    alignItems: 'center',
    flexDirection: 'row',
    '& .MuiTypography-root': {
      mt: 2,
      cursor: 'pointer',
    },
  },
  iconButton: {
    height: 40,
    width: 40,
    marginRight: '0.5rem',
    background: label === 'Whatsapp' ? '#25D366' : '',
    ':hover': {
      background: label === 'Whatsapp' ? '#128C7E' : '',
    },
  },
});

export default useSx;

import { get } from '@core/api/baseApi';
import { GetStudyCommentsResponse } from '@core/types';

export const getStudyComments = (
  studyInstanceUid: string,
  filters: {
    page?: string;
    pageSize?: string;
    sortOrder?: string;
  },
): Promise<GetStudyCommentsResponse> =>
  get(`/studies/${studyInstanceUid}/comments`, {
    params: { ...filters },
  });

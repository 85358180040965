import SxDef from '@core/types/SxDef';
import { expandBorder, expandBorderColor, expandDisplayFlex } from '@core/utils/expandShorthandCss';
import setCssSize from '@core/utils/setCssSize';

const useSx = (isDragActive: boolean, isDragReject: boolean): SxDef => ({
  root: (theme) => ({
    ...setCssSize(1),
    ...expandBorder('2px dashed'),
    ...expandBorderColor('secondary.dark'),
    ...expandDisplayFlex({ j: 'center', a: 'center', d: 'column' }),
    cursor: 'pointer',

    transitionProperty: 'background-color, border-color',
    transitionDuration: `${theme.transitions.duration.standard}ms`,

    ...(isDragActive && {
      bgcolor: `rgba(${theme.palette.shade?.[200]} / 0.4)`,
      ...expandBorderColor('text.primary'),
    }),

    ...(isDragReject && {
      color: 'error.light',
      ...expandBorderColor('error.main'),
    }),

    '& .MuiSvgIcon-root': {
      fontSize: '4.25rem',
      transitionProperty: 'height, width, color, transform',
      transitionDuration: `${theme.transitions.duration.short}ms`,
      ...(isDragActive && { fontSize: '5.5rem' }),
      ...(isDragReject && { transform: 'rotate(-15deg)' }),
    },

    '& .MuiTypography-root': {
      mt: 2,

      '& .MuiBox-root': {
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
    },
  }),
});

export default useSx;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: { 
    flexGrow: 1,
  },
  photographBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: '2rem',
    marginBottom: '2rem'
  },
  loading: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100%'
  }
});

export default useSx;

import React from 'react';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { Box } from '@mui/material';
import Section from '@components/Section';
import Logo from './Logo';
import { SystemCustomizationProps } from './SystemCustomizationProps';
import Theme from './Theme';
import useSystemCustomizationState from './useSystemCustomizationState';

const SystemCustomization: React.FC<SystemCustomizationProps> = ({ enabled }) => {
  const { sx } = useSystemCustomizationState();

  return (
    <Box sx={sx.root}>
      <Section
        title="Sube tu logo"
        IconComponent={AddPhotoAlternateIcon}
        description="Sube tu logo personalizado en formato PNG, recomendamos que el logo tenga las
        siguientes dimensiones: 165 pixeles de ancho y 40 pixeles de alto."
      >
        <Logo enabled={enabled} />
      </Section>
      <Section title="Elige el tema" IconComponent={ColorLensIcon} description="">
        <Theme enabled={enabled} />
      </Section>
    </Box>
  );
};

export default SystemCustomization;

import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import useFilters from 'app/features/users/hooks/useFilters';
import useSx from './sx';

const SELECT_PAGE_SIZE_RANGES = ['5', '10', '15', '20'];

const PageSizeSelect = () => {
  const sx = useSx();
  const [filters, setFilter] = useFilters();

  return (
    <Select
      size="small"
      label="Test"
      onChange={(e: any) => setFilter('pageSize', e.target.value)}
      value={filters.pageSize}
      MenuProps={{
        PaperProps: { sx: sx.paper },
      }}
    >
      {SELECT_PAGE_SIZE_RANGES.map((range) => (
        <MenuItem key={range} id={range} value={range}>
          {range}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PageSizeSelect;

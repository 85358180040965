import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
    root: {
        cursor: 'pointer',
        '.container': {
            ...expandDisplayFlex({a: 'center'}),
            gap: '5px'
        },
        '.order-icon': {
            fontSize: '0.8rem'
        }
    }
});
export default useSx;

import React, { useState, PropsWithChildren, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import If from '@components/If';
import useIsAuthenticated from '@core/hooks/useIsAuthenticated';
import useOrganizationSettings from '@core/hooks/useOrganizationSettings';
import { GetStudyResponse } from '@core/types';
import { hideStartLoader } from '@core/utils/startLoader';
import useGetStudyTokenWithoutOrgSecurity from '../../hooks/useGetStudyTokenWithoutOrgSecurity';
import BirthdateForm from './BirthdateForm';
import 'dayjs/locale/es';

const StudyConfirmAuthWrapper = ({
  children,
  studyInfo,
}: PropsWithChildren<{ studyInfo?: GetStudyResponse }>) => {
  const { organizationSettings, isLoading } = useOrganizationSettings();
  const [isCorrectDate, setIsCorrectDate] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  const [searchParams] = useSearchParams();
  const studyHash = searchParams.get('s');

  const isSecurityStudyDeactivated = organizationSettings.StudySecurity === 'False';

  // Inside logic: this is updating query key token
  useGetStudyTokenWithoutOrgSecurity(studyHash!, isSecurityStudyDeactivated && !!studyHash);

  useEffect(() => {
    if (!isSecurityStudyDeactivated && !isLoading) hideStartLoader({ isAuthenticated: true });
  }, [organizationSettings]);

  return (
    <If
      fallback={<BirthdateForm studyInfo={studyInfo} setIsCorrectDate={setIsCorrectDate} />}
      condition={isCorrectDate || isAuthenticated || isSecurityStudyDeactivated}
    >
      {children}
    </If>
  );
};

export default StudyConfirmAuthWrapper;

import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    ...expandDisplayFlex({ a: 'center', j: 'space-between' }),
    flexDirection: { xs: 'column', md: 'row' },
    marginBottom: '1rem',
    button: {
      marginRight: '1rem',
      width: { xs: '100%', md: 'auto' },
    },
    '.buttons-container': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: { xs: 'column', md: 'row' },
      gap: { xs: '10px', md: '0' },
    },
  },
});

export default useSx;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    width: '100%',
    marginTop: '2rem'
  },
});

export default useSx;

import React, { useState } from 'react';
import useSnackbar from '@core/hooks/useSnackbar';
import useSx from './sx';

const useStudyInfoState = () => {
  const sx = useSx();
  const showSnackbar = useSnackbar();

  return {
    showSnackbar,
    sx,
  };
};

export default useStudyInfoState;

import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    ...expandDisplayFlex({d: 'column'}),
    marginTop: '2rem',
    marginBottom: '2rem',
    width: '100%',
    '.errorMessage': {
      color: (theme) => theme.palette.error.main
    },
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  inputBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    width: { xs: '100%', md: '90%' },
  },
  label: {
    color: (theme) => theme.palette.shade?.[900],
  },
  input: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '1rem',
    height: '230px',
    marginBottom: '1rem'
  },
  divider: {
    display: {
      xs: 'none',
      sm: 'block'
    },
    alignSelf: 'stretch',
  }
});

export default useSx;

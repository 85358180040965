import { InteractionRequiredAuthError, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import msalInstance from '@core/auth/msalInstance';

async function getApiAccessToken() {
  const request: SilentRequest | RedirectRequest = {
    scopes: [
      process.env.REACT_APP_ACCESS_TOKEN_SCOPE as string
    ],
  };

  try {
    const { accessToken } = await msalInstance.acquireTokenSilent(request);
    return accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      await msalInstance.acquireTokenRedirect(request);
    }
  }
}

export default getApiAccessToken;

import SxDef from '@core/types/SxDef';

const useSx = ({ showSideReport }: { showSideReport: boolean }): SxDef => ({
  root: {
    '.viewer': {
      ':after': {
        content: "''",
        backgroundColor: '#ccc',
        position: 'absolute',
        right: 0,
        width: '4px',
        height: '100%',
        cursor: 'ew-resize',
        zIndex: '1001',
        top: 0,
        display: showSideReport ? 'block' : 'none',
      },
    },
  },
});

export default useSx;

import React from 'react';
import Box from '@mui/material/Box';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import UserAutoComplete from '@components/UserAutoComplete';
import userItemMapper from '@core/utils/userToUserItemMapper';
import AssignedReferrers from '../AssignedReferrers';
import { FormPropsWithNotification } from './FormProps';
import useFormState from './useFormState';

const Form = ({
  open = false,
  onClose,
  studyInstanceUid,
  handleSendNotification,
  isSendingNotification,
}: FormPropsWithNotification) => {
  const {
    assignReferrerAsync,
    revokeReferralAccess,
    showSnackbar,
    assignedReferrals,
    selectedReferrer,
    setSelectedReferrer,
    selectedReferrerInput,
    setSelectedReferrerInput,
  } = useFormState({ isOpen: open, studyInstanceUid });
  const isSubmittingLoading = isSendingNotification;

  const handleAssignReferrer = async () => {
    const response = await assignReferrerAsync({
      studyInstanceUid: studyInstanceUid || '',
      userUid: selectedReferrerInput?.userUid || '',
    });
    if (response.studyInstanceUid && response.authorizedUsers[0]) {
      const selectedReferrerItem = userItemMapper(selectedReferrerInput);
      await handleSendNotification(studyInstanceUid, selectedReferrerItem);
      showSnackbar({
        message: 'Médico referente asignado con éxito.',
        title: 'Médico asignado',
        type: 'success',
      });
      return;
    }

    showSnackbar({
      message: 'No fue posible asignar al médico referente.',
      title: 'Ocurrió un error',
      type: 'error',
    });
  };

  const handleRevokeAccess = async () => {
    const response = await revokeReferralAccess({
      studyInstanceUid: studyInstanceUid || '',
      userUid: selectedReferrer?.userUid || '',
    });
    if (response.studyInstanceUid) {
      showSnackbar({
        message: 'Acceso revocado al estudio médico.',
        type: 'success',
        title: 'Acceso cancelado',
      });
      return;
    }

    showSnackbar({
      message: 'No se pudo revocar acceso al estudio médico. Por favor, intenta de nuevo.',
      type: 'error',
      title: 'Ocurrió un error',
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <AssignedReferrers
        assignedRefferals={assignedReferrals}
        selectedReferrer={selectedReferrer}
        setSelectedReferrer={setSelectedReferrer}
      />
      <UserAutoComplete
        onUserSelect={(value) => {
          setSelectedReferrerInput(value);
        }}
        searchRoles="Referrer"
        placeholder="Seleccionar médico referente"
      />
      <Box display="flex" justifyContent="flex-end" gap="10px">
        <If condition={selectedReferrer}>
          <ActionButton
            text="Revocar Accesso"
            variant="contained"
            color="error"
            sx={{ marginRight: 'auto' }}
            onClick={handleRevokeAccess}
          />
        </If>
        <ActionButton variant="outlined" color="secondary" onClick={onClose} text="Cancelar" />
        <ActionButton
          text="Asignar Estudio"
          onClick={handleAssignReferrer}
          variant="contained"
          color="primary"
          isLoading={isSubmittingLoading}
        />
      </Box>
    </Box>
  );
};

export default Form;

/* eslint-disable @typescript-eslint/naming-convention */
import { get } from '@core/api/baseApi';
import { AuthToken } from '@core/types/DicomServiceTokenResponse';

const getDicomServiceToken = (
  studyHash?: string,
): Promise<{ success: boolean; token: AuthToken }> => {
  const tokenUrl =
    '/organizations/dicom-service-token' +
    (studyHash && studyHash.length > 0 ? `/${studyHash}` : '');
  return get(tokenUrl);
};

export { getDicomServiceToken };

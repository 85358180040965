import React, { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
import useSx from './sx'

const FormContainer = ({ children }:PropsWithChildren) => {
  
  const sx = useSx()

  return (
    <Box sx={sx.root}>
      {children}
    </Box>
  )
}

export default FormContainer
// useSx.ts
import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  input: {
    display: 'none',
  },
  button: {
    width: 'fit-content',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    gap: '1rem',
    marginTop: '0.5rem',
    padding: '1rem',
    maxWidth: '400px',
    borderRadius: '25px',
    background: 'transparent',
    border: (theme) => `2px solid ${theme.palette.primary.main}`,
  },
  imagePreview: {
    width: 'auto',
    height: '100px',
    maxWidth: '100%',
    borderRadius: 0,
    display: 'block',
    objectFit: 'contain',
  },
});

export default useSx;

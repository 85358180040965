import React, { useEffect } from 'react';
import { showStartLoader } from '@core/utils/startLoader';

const Authenticating = (/* msalContext: IMsalContext */) => {
  useEffect(() => {
    showStartLoader();
  }, []);

  return <></>;
};

export default Authenticating;

import replaceProfessionalInfo from './replaceProfessionalInfo';
import replaceQrCode from './replaceQrCode';
import replaceQrLink from './replaceQrLink';
import replaceSignature from './replaceSignature';

interface ProfessionalData {
  professionalFullName?: string;
  professionalInfo?: string;
  signatureBase64?: string;
  shareStudyQrBase64?: string;
  shareStudyUrl?: string;
}

export default function replacePlaceholders(page: HTMLDivElement, data: ProfessionalData) {
  replaceSignature(page, data.signatureBase64);
  replaceProfessionalInfo(page, data.professionalFullName, data.professionalInfo);
  replaceQrCode(page, data.shareStudyQrBase64);
  replaceQrLink(page, data.shareStudyUrl);
}

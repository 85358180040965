import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useProfile from '@core/hooks/useProfile';
import useWidth from '@core/hooks/useWidth';
import { RoleName } from '@core/types';
import useAddTemplate from '../../add/hooks/useAddTemplate';
import useGetSingleTemplate from '../hooks/useGetSingleTemplate';
import useRemoveTemplate from '../hooks/useRemoveTemplate';
import useUpdateTemplate from '../hooks/useUpdateTemplate';

const useEditTemplateState = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const { template, isFetched: isTemplateFetched } = useGetSingleTemplate(templateId || '');
  const { hasAdminPermissions, userUid, role } = useProfile();
  const { removeTemplate, isUploading: isDeleting } = useRemoveTemplate();
  const { addTemplate, isUploading: isAdding } = useAddTemplate();
  const { updateTemplate, isUploading: isUpdating } = useUpdateTemplate();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const width = useWidth();
  const deviceWidth = width === 'xs' ? 'sm' : width;

  const isUserTemplateOwner = template?.ownerUserUid === userUid;
  const canUserEditTemplate = isUserTemplateOwner;

  useEffect(() => {
    if (role !== RoleName.Radiologist && role !== RoleName.System) {
      navigate('/');
    }
  }, [role, navigate, hasAdminPermissions]);

  return {
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    deviceWidth,
    updateTemplate,
    addTemplate,
    removeTemplate,
    isDeleting,
    isUpdating,
    isAdding,
    templateId,
    template,
    canUserEditTemplate,
    isTemplateFetched,
  };
};

export default useEditTemplateState;

// Definimos una interfaz para la posición del cursor
interface CaretPosition {
  node: Node;
  offset: number;
}

// Función para obtener la posición actual del cursor
function getCaretPosition(element: HTMLElement): CaretPosition | null {
  const selection = window.getSelection();
  if (!selection || selection.rangeCount === 0) return null;

  const range = selection.getRangeAt(0);
  return {
    node: range.startContainer,
    offset: range.startOffset,
  };
}

// Función recursiva para encontrar el primer nodo de texto no vacío
function findFirstTextNode(node: Node): Node | null {
  if (node.nodeType === Node.TEXT_NODE && node.textContent!.trim() !== '') {
    return node;
  }
  for (const child of node.childNodes) {
    const result = findFirstTextNode(child);
    if (result) return result;
  }
  return null;
}

// Función para verificar si el cursor está al inicio del editor
function isCaretAtBeginningOfEditor(editorDiv: HTMLElement): boolean {
  const caretPosition = getCaretPosition(editorDiv);
  if (!caretPosition) return false;

  const firstTextNode = findFirstTextNode(editorDiv);
  if (!firstTextNode) return true; // El editor está vacío

  // Verificar si estamos en el primer nodo de texto y al inicio
  if (caretPosition.node === firstTextNode && caretPosition.offset === 0) {
    return true;
  }

  // Verificar si estamos en un nodo antes del primer nodo de texto
  let currentNode: Node | null = caretPosition.node;
  while (currentNode && currentNode !== editorDiv) {
    if (currentNode.previousSibling) {
      return false; // Hay un nodo hermano anterior, no estamos al inicio
    }
    currentNode = currentNode.parentNode;
  }

  return caretPosition.offset === 0;
}
export default isCaretAtBeginningOfEditor;

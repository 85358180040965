import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    display: { xs: 'block', sm: 'none' },
    padding: '0 20px',
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: '100%',
      backgroundColor: (theme) => theme.palette.shade?.[50],
    },
  },
});

export default useSx;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Payload, addStudyComment } from '../api/addStudyComment';
import { GET_STUDY_COMMENTS_QUERY_KEY } from './useGetStudyComments';

const useAddStudyComment = () => {
  const queryClient = useQueryClient()
  const { isLoading: isAdding, mutateAsync: addStudyCommentAsync } = useMutation({
    mutationFn: (studyCommentId: Payload) => addStudyComment(studyCommentId),
    onSuccess: () => {
      queryClient.invalidateQueries([GET_STUDY_COMMENTS_QUERY_KEY])
    }
  });

  return { isAdding, addStudyCommentAsync };
};

export default useAddStudyComment;

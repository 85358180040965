import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import merge from 'lodash.merge';
import { Tooltip } from '@mui/material';
import MuiListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import If from '@components/If';
import { ListItemProps } from './ListItemProps';
import useSx from './sx';

const ListItem = (props: ListItemProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const sx = useSx();

  const handleNavigate = (key: string) => () => {
    navigate(`/settings/${key}`);
    if (props.handleDrawerToggle) {
      props.handleDrawerToggle();
    }
  };

  const listItemSx =
    location.pathname.split('/')[2] === props.route ? merge(sx.root, sx.activeItem) : sx.root;

  return (
    <MuiListItem
      key={props.key}
      onClick={handleNavigate(props.route)}
      sx={listItemSx}
      disablePadding
    >
      <ListItemButton>
        <If
          condition={!props.collapsed}
          fallback={
            <Tooltip title={props.label}>
              <ListItemIcon sx={sx.icon}>
                <props.icon />
              </ListItemIcon>
            </Tooltip>
          }
        >
          <ListItemIcon sx={sx.icon}>
            <props.icon />
          </ListItemIcon>
          <ListItemText primary={props.label} />
        </If>
      </ListItemButton>
    </MuiListItem>
  );
};

export default ListItem;

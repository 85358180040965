import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => {
  return {
    root: {
      minWidth: '30%',
      maxHeight: { xs: 'auto', md: '400px' },
      overflow: 'auto',
      '.MuiListItem-root': {
        padding: { xs: '0', md: '8px 16px' },
      },
      '::-webkit-scrollbar': {
        width: '4px',
      },
      '::-webkit-scrollbar-track': {
        background: '#D3D3D3',
      },

      '::-webkit-scrollbar-thumb': {
        background: '#D2D2D2',
      },

      '::-webkit-scrollbar-thumb:hover': {
        background: '#D2D2D2',
      },
      '.check-icon': { color: (theme) => theme.palette.success.main },
    },
  };
};

export default useSx;

import { Configuration } from '@azure/msal-browser';

const clientId = process.env.REACT_APP_ADB2C_CLIENT_ID!;
const authority = process.env.REACT_APP_ADB2C_AUTHORITY!;
const redirectUri = process.env.REACT_APP_ADB2C_REDIRECT_URI!;
const knownAuthorities = process.env.REACT_APP_ADB2C_KNOWNAUTHORITIES!;

if (!(clientId && authority && redirectUri && knownAuthorities)) {
  console.error('Invalid authentication configuration');
}

const authConfig: Configuration = {
  auth: {
    clientId,
    authority,
    redirectUri,
    knownAuthorities: [knownAuthorities],
  },
};

export default authConfig;

import accessManager from '@core/utils/productAccessManager';
import useUserUid from './useUserUid';

const useRestart = () => {
  const userUid = useUserUid();

  return () => {
    accessManager.clearUserSession(userUid);

    if (window) {
      window.location.href = '/';
    }
  };
};

export default useRestart;

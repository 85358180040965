import React, { createContext, useState } from 'react';
import { ActionsColor } from '@components/ActionDialog/ActionDialogProps';

export type Prompt = {
  title: string;
  message?: string;
  type: ActionsColor;
};

type Confirm = {
  prompt: Prompt;
  isOpen: boolean;
  proceed: null | ((value: unknown) => void);
  cancel: null | ((value: unknown) => void);
};

const initialState: Confirm = {
  prompt: {
    title: '',
    type: 'warning',
  },
  isOpen: false,
  proceed: null,
  cancel: null,
};

export const ConfirmActionContext = createContext<
  [Confirm, React.Dispatch<React.SetStateAction<Confirm>>]
>([initialState, () => {}]);

export const ConfirmActionProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [confirm, setConfirm] = useState({ ...initialState });

  return (
    <ConfirmActionContext.Provider value={[confirm, setConfirm]}>
      {children}
    </ConfirmActionContext.Provider>
  );
};

import { useState, useRef } from 'react';
import useSx from './sx';

const useFileInputState = () => {
  const sx = useSx();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  return {
    sx,
    fileInputRef,
    preview, 
    setPreview,
    fileName, 
    setFileName
  };
};
export default useFileInputState;

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateOptionsProps, DatePickerWithMobileOverrideProps } from '../DatePickerWithLabelProps';
import { getDays, MONTHS_ARRAY, YEARS_ARRAY } from './arrayGenerators';
import useSx from './sx';

dayjs.extend(utc);

function dateOptions(array: DateOptionsProps[]) {
  return array.map(({ label, value }, i) => {
    return (
      <MenuItem disabled={i === 0} value={value} key={label}>
        {label}
      </MenuItem>
    );
  });
}

export default function DatePickerWithMobileOverride(props: DatePickerWithMobileOverrideProps) {
  const { value, onChange, showPlaceholders = false } = props;
  const [selectedDate, setSelectedDate] = useState<{
    day: number | '';
    month: number | '';
    year: number | '';
  }>({
    day: showPlaceholders ? '' : value?.get('date') || '',
    month: showPlaceholders ? '' : value?.get('month') || '',
    year: showPlaceholders ? '' : value?.get('year') || '',
  });

  useEffect(() => {
    if (
      typeof selectedDate.day === 'number' &&
      typeof selectedDate.month === 'number' &&
      typeof selectedDate.year === 'number' &&
      typeof onChange !== 'undefined'
    ) {
      const d = new Date(selectedDate.year, selectedDate.month, selectedDate.day);
      const day = dayjs.utc(d);

      // @ts-expect-error second parameter isent required
      onChange(day);
    }
  }, [selectedDate]);

  const sx = useSx();

  return (
    <>
      <DatePicker
        {...props}
        sx={sx.datePicker}
        slotProps={{ textField: { size: 'small' } }}
        timezone="UTC"
        className="datepicker"
        maxDate={dayjs.utc(Date.now())}
      />
      <Box sx={sx.datePickerMobile} className="datepicker-mobile">
        <Select
          value={selectedDate.day}
          onChange={(e) => setSelectedDate({ ...selectedDate, day: e.target.value as number })}
          sx={typeof selectedDate.day === 'number' ? sx.selectSmall : sx.selectSmallPlaceholder}
          displayEmpty
        >
          {dateOptions(
            getDays({
              month:
                typeof selectedDate.month === 'number'
                  ? selectedDate.month + 1
                  : selectedDate.month,
              year: selectedDate.year,
            }),
          )}
        </Select>
        <Select
          value={selectedDate.month}
          onChange={(e) => setSelectedDate({ ...selectedDate, month: e.target.value as number })}
          sx={typeof selectedDate.month === 'number' ? sx.selectSmall : sx.selectSmallPlaceholder}
          displayEmpty
        >
          {dateOptions(MONTHS_ARRAY)}
        </Select>
        <Select
          value={selectedDate.year}
          onChange={(e) => setSelectedDate({ ...selectedDate, year: e.target.value as number })}
          sx={typeof selectedDate.year === 'number' ? sx.selectBig : sx.selectBigPlaceholder}
          displayEmpty
        >
          {dateOptions(YEARS_ARRAY)}
        </Select>
      </Box>
    </>
  );
}

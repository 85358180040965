import { publish } from '@core/constants/customEvents';
import { ButtonFactory } from './buttonRegistryFactory';

const addInitialTemplate: ButtonFactory = () => {
  return {
    key: 'initialTemplate',
    unlocalizedText: 'Insert Info',
    iconName: 'ContactInfo',
    onClick: async () => {
      publish('evodicom.report.initialTemplate', undefined);
    },
  };
};

export default addInitialTemplate;

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MainLayout } from '@components/Layout';
import useIsAuthenticated from '@core/hooks/useIsAuthenticated';
import useProfile from '@core/hooks/useProfile';
import useStudyInfo from '@core/hooks/useStudyInfo';
import ThemeProvider from '@core/providers/ThemeProvider';
import { RoleName, ViewerInfoCommand } from '@core/types';
import InfoManager from 'app/features/study/components/InfoManager';
import Viewer from 'app/features/study/components/Viewer';
import PdfViewer from '../components/PdfViewer';
import StudyConfirmAuthWrapper from '../components/StudyConfirmAuthWrapper';

const ReadOnlyViewer = () => {
  const [command, setCommand] = useState<ViewerInfoCommand | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showReport, setShowReport] = useState<boolean>(searchParams.get('report') === 'true');
  const studyId = searchParams.get('s') ?? '';
  const isAuthenticated = useIsAuthenticated();
  const { studyInfo } = useStudyInfo({
    studyInstanceUidOrHash: studyId,
    isSharedStudy: true,
  });
  const navigate = useNavigate();
  const { role } = useProfile();

  const handleLoginClick = () => {
    sessionStorage.setItem('sessionType', 'study');
    navigate('/redirect');
  };

  useEffect(() => {
    if (studyInfo) {
      const studyIds = {
        studyInstanceUid: studyInfo.studyInstanceUid,
        studyHash: studyId,
      };
      sessionStorage.setItem('studyIds', JSON.stringify(studyIds));
    }
  }, [studyInfo]);

  function removeFilter(name: string) {
    const existing: Record<string, string> = {};

    for (const [key, param] of searchParams.entries()) {
      if (key === name) {
        continue;
      }

      existing[key] = param;
    }

    setSearchParams({ ...existing });
  }

  const onInfoManagerClose = () => {
    setCommand(undefined);
    removeFilter('section');
  };
  return (
    <MainLayout onLogin={handleLoginClick}>
      <ThemeProvider orgId={studyInfo?.organizationId ?? ''} studyHash={studyId ?? ''}>
        <StudyConfirmAuthWrapper studyInfo={studyInfo}>
          <Viewer
            studyInstanceUid={studyInfo?.studyInstanceUid ?? ''}
            baseUrl={`/study/view`}
            isAnon={!isAuthenticated}
            isAdministrator={false}
            readOnly={!isAuthenticated || role === RoleName.Patient}
            onCommandClick={({ commandName }) => setCommand(commandName)}
          />
          <InfoManager
            studyInstanceUid={studyId}
            studyInfo={studyInfo}
            readOnly
            command={command}
            onClose={onInfoManagerClose}
          />
          <PdfViewer
            studyHash={searchParams.get('s') || ''}
            show={showReport}
            closeReport={() => setShowReport(false)}
          />
        </StudyConfirmAuthWrapper>
      </ThemeProvider>
    </MainLayout>
  );
};

export default ReadOnlyViewer;

import React, { Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import AppAuthenticationTemplate from '@components/AppAuthenticationTemplate';
import getMenuItems from '@components/Layout/MainLayout/api/getMenuItems';
import useIsAuthenticated from '@core/hooks/useIsAuthenticated';
import { useLoading } from '@core/providers/LoadingAppProvider';
import { hideStartLoader } from '@core/utils/startLoader';
import { MainLayout } from '../components/Layout';

const AppRoot = () => {
  const { loadingApp } = useLoading();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const allMenuItems = getMenuItems();
  const isMenuItemPath = allMenuItems.some(
    (item) => item.route === `/${location.pathname.split('/')[1]}`,
  );

  useEffect(() => {
    if (!loadingApp && isAuthenticated) {
      hideStartLoader({ isAuthenticated: isAuthenticated ?? false });
    }
  }, [loadingApp, isAuthenticated]);

  return (
    <AppAuthenticationTemplate>
      <MainLayout sidebar={isMenuItemPath}>
        <Suspense fallback={<Backdrop open>Cargando</Backdrop>}>
          <Outlet />
        </Suspense>
      </MainLayout>
    </AppAuthenticationTemplate>
  );
};

export default AppRoot;

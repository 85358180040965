import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import Container from '@mui/material/Container';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import TechnicianForm from 'app/features/studies/components/AddTechnicianForm/Form';
import withSendAssignedNotification from 'app/features/studies/components/WithSendAssignedNotification/withSendAssignedNotification';
import useUpdateStudyInformation from 'app/features/studies/hooks/useUpdateStudyInformation';
import useStudyInfo from '../../../../../core/hooks/useStudyInfo';
import ManagerComponentProps from '../../../types/ManagerComponentProps';
import Comments from '../Comments';
import SolicitationManager from '../SolicitationManager';
import LoadingIndicator from './LoadingIndicator';
import NoInfoAvailable from './NoInfoAvailable';
import StudyInfo from './StudyInfo';
import useSx from './sx';

const StudyInformation = ({ studyInstanceUid, readOnly, onClose }: ManagerComponentProps) => {
  const [searchParams] = useSearchParams();
  const studyId = readOnly ? searchParams.get('s') ?? '' : studyInstanceUid;
  const { isLoading, studyInfo, refetch } = useStudyInfo({
    studyInstanceUidOrHash: studyId,
    isSharedStudy: readOnly,
  });
  const { updateInformationAsync, isUploading } = useUpdateStudyInformation();
  const sx = useSx();
  const { role } = useProfile();

  const TechnicianFormWithNotification = withSendAssignedNotification(TechnicianForm);

  return (
    <Container maxWidth="md" sx={sx.root}>
      <If condition={!isLoading} fallback={<LoadingIndicator />}>
        <If condition={!!studyInfo} fallback={<NoInfoAvailable />}>
          <StudyInfo
            key={JSON.stringify(studyInfo)}
            study={studyInfo!}
            role={role}
            refetch={refetch}
            update={updateInformationAsync}
            isUpdating={isUploading}
          />
          <Typography variant="h5" className="title">
            Técnico asignado
          </Typography>
          <TechnicianFormWithNotification studyInstanceUid={studyInstanceUid} role={role} open />
          <Typography variant="h5" className="title">
            Solicitud del estudio
          </Typography>
          <SolicitationManager studyInstanceUid={studyInstanceUid} />
          <Typography variant="h5" className="title">
            Comentarios
          </Typography>
          <Comments studyInstanceUid={studyInstanceUid} open disablePagination />
        </If>
      </If>
    </Container>
  );
};

export default StudyInformation;

import React from 'react';
import Box from '@mui/material/Box';
import getPageStyles from 'app/features/templates/add/utils/getPageStyles';
import PageProps from './PageProps';

const Page = ({ customBackground, pageConfig, content }: PageProps) => {
  return (
    <Box
      sx={{
        ...getPageStyles(pageConfig, customBackground),
      }}
      dangerouslySetInnerHTML={{ __html: content }}
    ></Box>
  );
};

export default Page;

const rotateImage = (img: HTMLImageElement, rotation: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    let width: number, height: number;
    if (rotation === 90 || rotation === 270) {
      width = img.naturalHeight;
      height = img.naturalWidth;
    } else {
      width = img.naturalWidth;
      height = img.naturalHeight;
    }

    canvas.width = width;
    canvas.height = height;

    ctx?.translate(width / 2, height / 2);
    ctx?.rotate((rotation * Math.PI) / 180);
    ctx?.drawImage(img, -img.naturalWidth / 2, -img.naturalHeight / 2);

    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error('Failed to create blob.'));
        } else {
          const rotatedImgUrl = URL.createObjectURL(blob);
          resolve(rotatedImgUrl);
        }
      },
      'image/jpeg',
      1,
    );
  });
};

export default rotateImage;

import { useState, useEffect } from 'react';
import { subscribe, unsubscribe } from '@core/constants/customEvents';
import useOrganizationTemplateImage from '@core/hooks/useOrganizationTemplateImage';
import useOrganizationTemplates from '@core/hooks/useOrganizationTemplates';
import useStudyInfo from '@core/hooks/useStudyInfo';
import blobToBase64 from '@core/utils/createBase64FromBlob';
import useSx from './sx';
import { isGetOrganizationTemplatesResponse } from './typeguards';

const useFormState = (studyId: string) => {
  const sx = useSx();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isTranscribing, setIsTranscribing] = useState<boolean>(false);
  const [templateImageUrl, setTemplateImageUrl] = useState<string>('');

  const [isTemplateImageLoading, setTemplateImageLoading] = useState<boolean>(true);
  const { studyInfo, isLoading: isLoadingShareUrl } = useStudyInfo({
    studyInstanceUidOrHash: studyId,
    retry: false,
  });

  const [templateId, setTemplateId] = useState('');

  const { organizationTemplates } = useOrganizationTemplates();
  const validOrganizationTemplatesResponse =
    isGetOrganizationTemplatesResponse(organizationTemplates);

  const isValidOrganizationTemplateResponseAndHasItems =
    validOrganizationTemplatesResponse && organizationTemplates.items?.length > 0;

  useEffect(() => {
    // Use first template on backend response because it is the default template
    setTemplateId(
      isValidOrganizationTemplateResponseAndHasItems
        ? organizationTemplates.items[0]?.templateId
        : '',
    );
  }, [isValidOrganizationTemplateResponseAndHasItems, organizationTemplates?.items]);

  const skipLoadingTemplateImage =
    validOrganizationTemplatesResponse && (organizationTemplates.items?.length ?? 0) === 0;

  const templateImageResponse = useOrganizationTemplateImage(templateId);

  useEffect(() => {
    const ref = subscribe('evodicom.report.changeTemplate', (data) => {
      setTemplateId(data.templateId);
    });

    return () => {
      unsubscribe('evodicom.report.changeTemplate', ref);
    };
  }, []);

  useEffect(() => {
    const loadImage = async () => {
      if (
        templateImageResponse.status === 'success' &&
        (templateImageResponse.renderedImage?.size ?? 0) > 0
      ) {
        const test = await blobToBase64(templateImageResponse.renderedImage!);
        setTemplateImageUrl(test);
        setTemplateImageLoading(false);
      } else if (skipLoadingTemplateImage || templateImageResponse.status === 'error') {
        setTemplateImageLoading(false);
      }
    };

    loadImage();
  }, [
    templateImageResponse.renderedImage,
    templateImageResponse.status,
    skipLoadingTemplateImage,
    validOrganizationTemplatesResponse,
  ]);

  return {
    sx,
    isLoadingShareUrl,
    templateId,
    templateImageUrl,
    isTemplateImageLoading,
    study: studyInfo,
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    isTranscribing,
    setIsTranscribing,
    organizationTemplates,
  };
};

export default useFormState;

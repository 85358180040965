import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetFoldersResponse } from '@core/types';
import isCustomErroHandlerResponse from '@core/utils/isCustomErrorHandlerResponse';
import { editFolder } from '../api/editFolder';
import navigateToFoldersBasedOnFolderPath from '../utils/navigateToFolderBasedOnFolderPath';
import { GET_FOLDERS_QUERY_KEY } from './useGetFolders';

const useEditFolder = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: editFolderAsync, ...rest } = useMutation({
    mutationFn: (body: {
      templateFolderId: number;
      templateFolderName: string;
      isOrganizationWide: boolean;
      folderPath: number[];
    }) => editFolder(body),
    onMutate: (values) => {
      queryClient.cancelQueries([GET_FOLDERS_QUERY_KEY]);
      const workingQueryKeys = [
        GET_FOLDERS_QUERY_KEY,
        {
          Section: values.isOrganizationWide ? 'Organization' : 'Private',
        },
      ];
      const previousFolders = queryClient.getQueryData<GetFoldersResponse>(workingQueryKeys);
      if (previousFolders) {
        const previousFolderCopy = JSON.parse(JSON.stringify(previousFolders));
        const parentFolderSubItem = navigateToFoldersBasedOnFolderPath(previousFolderCopy.root, [
          ...values.folderPath,
        ]);

        const workingFolder = parentFolderSubItem[`folder-${values.templateFolderId}`];

        if (workingFolder) {
          workingFolder.name = values.templateFolderName;
        }

        queryClient.setQueryData(workingQueryKeys, previousFolderCopy);

        return { previousFolders, workingQueryKeys };
      }
    },
    onError: (data, newProp, context) => {
      const shouldResetTree =
        isCustomErroHandlerResponse(data) && context?.workingQueryKeys && context?.previousFolders;
      if (shouldResetTree) {
        queryClient.setQueryData(context.workingQueryKeys, context.previousFolders);
        return;
      }
      queryClient.invalidateQueries([GET_FOLDERS_QUERY_KEY]);
    },
    onSettled: (data, error, variables, context) => {
      const shouldResetTree =
        isCustomErroHandlerResponse(data) && context?.workingQueryKeys && context?.previousFolders;
      if (shouldResetTree) {
        queryClient.setQueryData(context.workingQueryKeys, context.previousFolders);
        return;
      }
      queryClient.invalidateQueries([GET_FOLDERS_QUERY_KEY]);
    },
  });

  return { ...rest, editFolderAsync };
};

export default useEditFolder;

import { SxProps, Theme } from '@mui/material/styles';
import SxDef from '@core/types/SxDef';
import setCssSize from '@core/utils/setCssSize';

export interface UseSxProps {
  icon?: boolean;
  size?: 'small' | 'medium' | 'large';
  extend?: SxProps<Theme>;
}

const useSx = ({ icon = false, size, extend }: UseSxProps): SxDef => {
  return {
    root: (theme) => ({
      ...(icon && {
        borderRadius: '50%',
        ...setCssSize(theme.spacing(14)),
        minWidth: 'inherit',
        ...(size === 'large' && {
          '& .MuiSvgIcon-root': {
            fontSize: '200%',
          },
        }),
      }),
      ...(extend as object),
    }),
  };
};

export default useSx;

import React from 'react'
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import AssignedReferrersProps from './AssignedReferrersProps';
import useSx from './sx';

const AssignedRefferals = ({assignedRefferals, setSelectedReferrer, selectedReferrer}: AssignedReferrersProps) => {
    const sx = useSx()
    return (
        <Box display="flex" alignItems="center" gap="5px" flexWrap="wrap">
            {
                assignedRefferals?.authorizedUsers.map((referrer) => (
                    <Chip 
                        onClick={() => {
                            if(selectedReferrer?.userUid === referrer?.userUid){
                                setSelectedReferrer(null)
                                return
                            }
                            setSelectedReferrer(referrer)
                        }} 
                        key={referrer.userUid} 
                        label={referrer.fullName}
                        sx={selectedReferrer?.userUid === referrer?.userUid ? sx.chipActive : sx.chip}
                    />
                ))
            }
        </Box>
    )
}

export default AssignedRefferals
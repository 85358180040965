import React, { useState } from 'react';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

const useUploadAudioState = () => {
  const [showModal, setShowModal] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const [transcript, setTranscript] = useState<string>('');
  const [temporaryTranscript, setTemporaryTranscript] = useState<string>('');
  const [speechRecognizer, setSpeechRecognizer] = useState<SpeechSDK.SpeechRecognizer | null>(null);
  const [isFfmpegReady, setIsFfmpegReady] = useState<boolean>(false);
  const [warningDialogOpen, setWarningDialogOpen] = useState<boolean>(false);

  return {
    showModal,
    setShowModal,
    isLoadingFile,
    setIsLoadingFile,
    transcript,
    setTranscript,
    temporaryTranscript,
    setTemporaryTranscript,
    speechRecognizer,
    setSpeechRecognizer,
    isFfmpegReady,
    setIsFfmpegReady,
    warningDialogOpen,
    setWarningDialogOpen,
  };
};

export default useUploadAudioState;

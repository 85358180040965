import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { Paper, Box, Typography, IconButton, SxProps, Theme } from '@mui/material';
import { ThemeDetails, ThemeType } from '@core/types/Theme';
import useSx from './sx';

type EmptyPreviewProps = {
  theme: ThemeDetails;
  currentTheme: ThemeType;
  onEditClick: (event: React.MouseEvent<HTMLElement>) => void;
};

const EmptyPreview: React.FC<EmptyPreviewProps> = ({ theme, currentTheme, onEditClick }) => {
  const sx = useSx(theme, currentTheme);

  const combinedSx = {
    ...sx.paper,
    ...sx.paperEmpty,
  } as SxProps<Theme>;

  return (
    <Box sx={sx.root}>
      <IconButton
          size="small"
          onClick={onEditClick}
          sx={sx.editButton}
        >
          <AddIcon fontSize="small" />
        </IconButton>
      <Paper 
        onClick={onEditClick}
        sx={combinedSx}
      >
        <ColorLensIcon />
      </Paper>
      <Typography>Custom</Typography>
    </Box>
  );
};

export default EmptyPreview;
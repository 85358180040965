import React from 'react';
import { Container } from '@mui/material';
import ContainerCard from '@components/ContainerCard/ContainerCard';
import Header from '../components/Header';
import Mode from '../components/Mode';

const Preferences = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Header />
      <ContainerCard>
        <Mode />
      </ContainerCard>
    </Container>
  );
};

export default Preferences;

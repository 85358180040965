import React from 'react';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import MuiPagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import getPageInfo from '@core/utils/getPageInfo';

const Pagination = ({
  pageCount,
  onPageChange,
  currentPage,
  totalCount,
  pageSize,
  sx,
}: {
  pageCount: number;
  onPageChange: (page: number) => void;
  currentPage: number;
  totalCount: number;
  pageSize: number;
  sx?: SxProps<Theme>;
}) => {
  const message = getPageInfo(currentPage, pageSize, totalCount);

  return (
    <Box sx={sx} display="flex" alignItems="center" gap="10px">
      <Typography display={{ xs: 'none', md: 'inline-block' }} textTransform={'none'}>
        {message}
      </Typography>
      <MuiPagination
        count={pageCount}
        page={currentPage || 1}
        onChange={(e, value) => {
          onPageChange(value);
        }}
      />
    </Box>
  );
};

export default Pagination;

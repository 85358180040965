import React from 'react'
import merge from 'lodash.merge'
import FormLabel from '@mui/material/FormLabel'
import LabelProps from './LabelProps'
import useSx from './sx'

const Label = ({ label, required = false }: LabelProps) => {

    const sx = useSx()

    const conditionalStyles = required ? merge(sx.root, sx.required) : sx.root

    return (
        <FormLabel
            sx={conditionalStyles}
        >
            {label}
        </FormLabel>
    )
}

export default Label
import { useCallback, useContext } from 'react';
import { SnackbarContext, SnackbarType } from '@core/providers/SnackbarProvider';

const useSnackbar = () => {
  const context = useContext(SnackbarContext);

  if (context === null) {
    throw Error(
      'Context useSnackbar is not available. useSnackbar must be used within a child component of SnackbarProvider',
    );
  }

  const showSnackbar = useCallback(
    ({
      message,
      type = 'default',
      title = 'Info',
      autoHideDuration = 5000,
    }: {
      message: string;
      title: string;
      type?: SnackbarType;
      autoHideDuration?: number;
    }) => {
      const { setOpen, setMessage, setType, setTitle, setAutoHideDuration } = context;

      setMessage(message);
      setType(type);
      setTitle(title);
      setOpen(true);
      setAutoHideDuration(autoHideDuration);
    },
    [context],
  );

  return showSnackbar;
};

export default useSnackbar;

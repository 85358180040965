import React from 'react';
import { FormikHelpers } from 'formik';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContainerCard from '@components/ContainerCard/ContainerCard';
import Section from '@components/Section';
import Title from '@components/Title';
import useSnackbar from '@core/hooks/useSnackbar';
import { AddUserResponse, CustomErroHandlerResponse, RoleName } from '@core/types';
import { User } from '@core/types/User';
import isCustomErroHandlerResponse from '@core/utils/isCustomErrorHandlerResponse';
import Form from '../components/Form';
import { InitialValues } from '../components/Form/FormProps';
import FormContainer from '../components/FormContainer';
import UserInfoDialog from '../components/UserInfoDialog';
import useAddUserState from './useAddUserState';

const initialValues: InitialValues = {
  givenName: '',
  surname: '',
  emailAddress: '',
  gender: '',
  phoneNumber: '',
  role: '',
  isAdmin: false,
  permissions: [],
};

const AddUser = () => {
  const {
    isUserInfoDialogOpen,
    setIsUserInfoDialogOpen,
    user,
    setUser,
    isLoading,
    addUser,
    permissions,
    sendNotification,
    fullName,
    organizationId,
    isSendingNotification,
  } = useAddUserState();
  const showSnackbar = useSnackbar();

  const handleSendNotification = async (info: AddUserResponse | User) => {
    try {
      const payload = {
        organizationId: organizationId,
        creatorName: fullName,
        userName: `${info.givenName} ${info.surname}`,
        address: info.emailAddress as string,
        language: 'es_MX',
        temporalPassword: null as string | null,
      };

      if ('temporaryPassword' in info && typeof info.temporaryPassword === 'string') {
        payload.temporalPassword = info.temporaryPassword ?? null;
      }

      const response = await sendNotification(payload);
      if (isCustomErroHandlerResponse(response)) {
        throw new Error('Error al enviar la notificación');
      }
    } catch (error) {
      showSnackbar({
        message: 'Error al enviar la notificación',
        title: 'Ocurrió un error',
        type: 'error',
      });
    }
  };

  const handleSuccess = async (
    response: AddUserResponse,
    payload: User,
    formikHelpers: FormikHelpers<InitialValues>,
  ) => {
    await handleSendNotification(response.temporaryPassword ? response : payload);
    showSnackbar({
      message: 'Usuario guardado con éxito',
      title: 'Usuario creado',
      type: 'success',
    });
    setIsUserInfoDialogOpen(true);
    setUser(response);
    formikHelpers.resetForm();
  };

  const handleError = (response: CustomErroHandlerResponse) => {
    const message =
      response.statusCode === 409
        ? 'El usuario ya existe en esta organización.'
        : 'No fue posible crear el usuario';
    showSnackbar({
      message,
      title: 'Error al crear usuario',
      type: 'error',
    });
  };

  const handleSubmit = async (
    values: InitialValues,
    formikHelpers: FormikHelpers<InitialValues>,
  ) => {
    const permissionRuleIds =
      values.role === RoleName.Referrer || values.isAdmin
        ? []
        : values.permissions?.map((permission) => permission?.permissionRuleId);

    const payload = { ...values, permissionRuleIds, isOwner: false };
    // Delete sensible information from request
    delete payload.permissions;

    const response = await addUser(payload as User);

    if (!isCustomErroHandlerResponse(response)) {
      if (response.userUid) {
        await handleSuccess(response, payload as User, formikHelpers);
        return;
      }
    }

    if (isCustomErroHandlerResponse(response)) {
      handleError(response);
    }
  };

  return (
    <>
      <Title text="Usuario" />
      <ContainerCard>
        <Section title="Nuevo Usuario" IconComponent={PersonAddIcon} description="">
          <FormContainer>
            <Form
              handleSubmit={handleSubmit}
              initialValues={initialValues}
              isLoading={isLoading || isSendingNotification}
              permissionsArray={permissions || []}
            />
          </FormContainer>
        </Section>
      </ContainerCard>
      <UserInfoDialog
        user={user}
        open={isUserInfoDialogOpen}
        handleClose={() => {
          setIsUserInfoDialogOpen(false);
        }}
      />
    </>
  );
};

export default AddUser;

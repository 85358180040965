export default function macOsKeys(key: string) {
  // Here we can customize keys shown for MacOS users
  switch (key) {
    case 'alt':
      return 'option';
    case 'pageup':
      return 'Fn + up';
    case 'pagedown':
      return 'Fn + down';
    default:
      return key;
  }
}

import React from 'react';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import TableProps from './TableProps';
import useSx from './sx';

const Table = ({ users, pageCount, totalCount, setSelectedUser, setModalState }: TableProps) => {
  const sx = useSx()

  return (
    <TableContainer sx={sx.root} component={Paper}>
      <MuiTable className="table" aria-label="simple table">
        <TableHeader />
        <TableBody
          pageCount={pageCount}
          totalCount={totalCount}
          users={users}
          setModalState={setModalState}
          setSelectedUser={setSelectedUser}
        />
      </MuiTable>
    </TableContainer>
  )
}

export default Table
import React from 'react';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import { ActionDialogProps, ActionsColor } from './ActionDialogProps';
import useSx from './sx';

const icons: Record<ActionsColor, JSX.Element> = {
  success: <CheckCircleOutlineOutlinedIcon />,
  info: <InfoOutlinedIcon />,
  warning: <ReportProblemOutlinedIcon />,
  delete: <ErrorOutlineOutlinedIcon />,
};

const ActionDialog: React.FC<ActionDialogProps> = ({
  open,
  message,
  onClose,
  type,
  title,
  onConfirm,
  confirmText,
  closeText,
  children,
  onCancel,
  isLoading,
  withIcon = true,
  hideCloseButton = false,
  sx,
  ...props
}) => {
  const customSx = useSx(type);
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      sx={() => ({
        ...customSx.root,
        ...sx,
      })}
      open={open}
      onClose={onClose}
      aria-labelledby="warning-dialog-title"
      {...props}
    >
      <DialogTitle id="warning-dialog-title" sx={customSx.dialogHeader}>
        <If condition={!hideCloseButton}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={customSx.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </If>
        <If condition={withIcon}>
          <Box sx={customSx.iconContainer}>
            <Box sx={customSx.iconBox}>{icons[type]}</Box>
          </Box>
        </If>
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Typography sx={customSx.title}>{title}</Typography>
        <If condition={!!message}>
          <Typography>{message}</Typography>
        </If>
        <If condition={!!children}>{children}</If>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box sx={customSx.actionsButtonContainer}>
          <If condition={!!onCancel}>
            <ActionButton
              disabled={!!isLoading}
              color="secondary"
              variant="outlined"
              text={closeText || 'Cancelar'}
              onClick={onCancel}
            />
          </If>

          <If condition={!!onConfirm}>
            <ActionButton
              isLoading={!!isLoading}
              color="primary"
              variant="contained"
              text={confirmText || 'Confirmar'}
              onClick={onConfirm}
            />
          </If>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addFolder } from '../api/addFolder';
import { GET_FOLDERS_QUERY_KEY } from './useGetFolders';

const useAddFolder = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: addFolderAsync, ...rest } = useMutation({
    mutationFn: (body: { folderName: string; folderPath: number[]; isOrganizationWide: boolean }) =>
      addFolder(body),
    onSuccess: () => {
      queryClient.invalidateQueries([GET_FOLDERS_QUERY_KEY]);
    },
  });

  return { ...rest, addFolderAsync };
};

export default useAddFolder;

import { RibbonButton } from 'roosterjs-react';

export interface ButtonFactoryProps {
  themeMode: string;
  userInfo: {
    signature?: string;
    fullName?: string;
    professionalInfo?: string;
  };
  studyInfo: {
    shareUrl?: string;
  };
  templates: {
    templateId: string;
    title: string;
  }[];
}

export type ButtonFactory = (props: ButtonFactoryProps) => RibbonButton<string>;

const buttonRegistry: Record<string, ButtonFactory> = {};

export const registerButton = (key: string, factory: ButtonFactory) => {
  buttonRegistry[key] = factory;
};

export const getRegisteredButtons = (props: ButtonFactoryProps) => {
  return Object.values(buttonRegistry).map((factory) => factory(props));
};

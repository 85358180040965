import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import { RoleName } from '@core/types';
import ProtectedRouteProps from './ProtectedRouteProps';

const ProtectedRoute = ({
  children,
  allowedRoles,
  isAdminAllowed,
  isOwnerAllowed,
}: PropsWithChildren<ProtectedRouteProps>) => {
  const { role, hasAdminPermissions, hasOwnerPermissions } = useProfile();
  const isAdminUserAndIsAdminAllowed = hasAdminPermissions && isAdminAllowed;
  const isOwnerUserAndIsOnwerAllowed = hasOwnerPermissions && isOwnerAllowed;
  const isUserRoleAllowed = allowedRoles.includes(role || RoleName.None);
  return (
    <If
      condition={isAdminUserAndIsAdminAllowed || isOwnerUserAndIsOnwerAllowed || isUserRoleAllowed}
      fallback={<Navigate to="/" />}
    >
      {children}
    </If>
  );
};

export default ProtectedRoute;

import SxDef from '@core/types/SxDef';
import { ThemeDetails, ThemeType } from '@core/types/Theme';

const useSx = (themePreviewed: ThemeDetails, currentTheme: ThemeType): SxDef => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '2rem',
    position: 'relative'
  },
  paper: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    backgroundColor: themePreviewed?.primaryColor,
    cursor: 'pointer',
    marginBottom: '1rem',
    border: themePreviewed?.name.includes(currentTheme) ? `2px solid ${themePreviewed?.shades?.[900]}` : ''
  },
  paperEmpty: {
    backgroundColor: '#636363',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  editButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(50%, -50%)',
    backgroundColor: 'transparent',
    zIndex: 2,
    '&:hover': {
      backgroundColor: (theme) => theme.palette.shade?.[300],
    },
  },
});

export default useSx;

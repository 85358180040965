import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GET_TEMPLATES_QUERY_KEY } from '../../hooks/useGetTemplates';
import { removeTemplate } from '../api/removeTemplate';

const useRemoveTemplate = () => {
  const queryClient = useQueryClient();

  const { isLoading: isUploading, mutateAsync: asyncDeleteTemplate } = useMutation({
    mutationFn: (templateId: string) => removeTemplate(templateId),
    mutationKey: ['RemoveTemplate'],
    onSuccess: () => {
      queryClient.invalidateQueries([GET_TEMPLATES_QUERY_KEY]);
    },
  });

  return { isUploading, removeTemplate: asyncDeleteTemplate };
};

export default useRemoveTemplate;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Box from '@mui/material/Box';
import MuiTableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import { RoleName } from '@core/types';
import PaginationRow from './PaginationRow';
import TableBodyProps from './TableBodyProps';
import UserCell from './UserCell';
import useSx from './sx';

const TableBody = ({users, pageCount, totalCount, setModalState, setSelectedUser}: TableBodyProps) => {
  const { role, hasAdminPermissions } = useProfile()
  const navigate = useNavigate()
  const sx = useSx()

  const handleNavigate = (userUid: string | undefined) => {
    if(!userUid) return

    const route = `/settings/users/edit/${userUid}`
    navigate(route)
  }

  return (
      <MuiTableBody sx={sx.root}>
        {users.map((user) => (
          <TableRow
              sx={sx.row}
              key={user.userUid}
          >
              <UserCell user={user} />
              <TableCell className="user-col">{user.emailAddress}</TableCell>
              <If condition={role !== RoleName.Referrer}>
                <TableCell className="user-col">{user.isOwner ? 'Dueño' : user.isAdmin ? 'Administrador' : ''}</TableCell>
              </If>
              <If condition={hasAdminPermissions}>
                <TableCell className="user-col">
                  <Box display="flex" alignItems="center" justifyContent="flex-end" gap="5px">
                    <KeyOutlinedIcon sx={sx.keyIcon} onClick={() => {setSelectedUser(user); setModalState('PASSWORD')}} />
                    <ModeEditOutlineOutlinedIcon onClick={() => {handleNavigate(user.userUid)}} sx={sx.editIcon} />
                    <DeleteOutlineOutlinedIcon onClick={() => {setSelectedUser(user); setModalState('DELETE')}} sx={sx.deleteIcon} />
                  </Box>
                </TableCell>
              </If>
          </TableRow>
        ))}
        <PaginationRow pageCount={pageCount} totalCount={totalCount} />
      </MuiTableBody>
  )
}

export default TableBody
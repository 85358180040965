import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: (theme) => ({
    bgcolor: 'background.paper',
    p: 4,

    [theme.breakpoints.up('sm')]: {
      borderRadius: 1,
    },

    '& .MuiTypography-root': {
      color: 'error.main',
    },
  }),
});

export default useSx;

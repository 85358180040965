import { createTheme, Theme } from '@mui/material/styles';
import { ThemeType, ThemeName, ThemeDetails } from "@core/types/Theme";
import * as Themes from '@styles/themes';
import { customLight, customDark } from '@styles/themes';

export const generateThemeDetailsMap = (customThemeData = null): Record<ThemeType, ThemeDetails> => {
  const themeNames = Object.keys(Themes) as ThemeName[];
  const themeDetails: Partial<Record<ThemeName, ThemeDetails>> = {};
  const baseTheme = createTheme();

  themeNames.forEach(themeName => {
    const themeVariantName = themeName as keyof typeof Themes;
    let themeInstance: Theme;
    
    if (customThemeData && (themeVariantName === 'customLight' || themeVariantName === 'customDark')) {
      themeInstance = themeVariantName === 'customLight' ? customLight(customThemeData) : customDark(customThemeData);
    } else {
      const themeFunctionOrObject = Themes[themeVariantName];
      if (typeof themeFunctionOrObject === 'function') {
        themeInstance = themeFunctionOrObject(baseTheme);
      } else if (themeFunctionOrObject) {
        themeInstance = themeFunctionOrObject;
      } else {
        console.error(`Theme variant ${themeVariantName} is not defined.`);
        return;
      }
    }

    themeDetails[themeName] = {
      name: themeName,
      primaryColor: themeInstance.palette.primary.main,
      primaryTextColor: themeInstance.palette.text.primary,
      primaryContrastColor: themeInstance.palette.primary.contrastText,
      secondaryColor: themeInstance.palette.secondary.main,
      secondaryContrastColor: themeInstance.palette.secondary.contrastText,
      infoColor: themeInstance.palette.info?.main,
      positiveColor: themeInstance.palette.success?.main,
      errorColor: themeInstance.palette.error?.main,
      errorContrastColor: themeInstance.palette.error?.contrastText,
      warningColor: themeInstance.palette.warning?.main,
      brandPrimaryColor: themeInstance.palette.brand?.main,
      brandSecondaryColor: themeInstance.palette.brand?.contrastText,
      shades: {
        50: themeInstance.palette.shade?.[50],
        100: themeInstance.palette.shade?.[100],
        200: themeInstance.palette.shade?.[200],
        300: themeInstance.palette.shade?.[300],
        400: themeInstance.palette.shade?.[400],
        500: themeInstance.palette.shade?.[500],
        600: themeInstance.palette.shade?.[600],
        700: themeInstance.palette.shade?.[700],
        800: themeInstance.palette.shade?.[800],
        900: themeInstance.palette.shade?.[900],
      },
    };
  });

  return themeDetails as Record<ThemeType, ThemeDetails>;
}

export const themeDetailsMap = generateThemeDetailsMap();

import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    '.title': {
      color: 'primary.contrastText',
    },
    '.subtitle': {
      color: 'text.secondary',
      marginTop: '5px',
    },

    '& *:focus-visible': {
      outline: 0,
    },

    '& .MuiDialog-paper': {
      backgroundColor: (theme) => theme.palette.shade?.[200],
      backgroundImage: 'none',
      maxHeight: 1,
      overflow: 'visible',
      mt: { sm: 0 },
    },

    '& .MuiDialogTitle-root': {
      ...expandDisplayFlex({ j: 'space-between', a: 'center' }),
      paddingY: '18px',

      '& .MuiButton-root': { mt: -2, mr: -4 },
    },

    '& .MuiDialogContent-root': {
      paddingTop: 0,
      ...expandDisplayFlex({ d: 'column' }),
    },

    '& .MuiDialog-container': {
      alignItems: { sx: 'center' },
    },
  },
});

export default useSx;

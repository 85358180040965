import React from 'react';
import Box from '@mui/material/Box';
import ActionButton from '@components/Button/ActionButton';
import ActionsProps from './ActionsProps';
import useSx from './sx';

const Actions = ({ isLoading, reset, submit }: ActionsProps) => {
  const sx = useSx();

  return (
    <Box sx={sx.root}>
      <ActionButton color="secondary" variant="outlined" onClick={() => reset()} text="Cancelar" />
      <ActionButton
        color="primary"
        variant="contained"
        isLoading={isLoading}
        text="Guardar"
        onClick={() => submit()}
      />
    </Box>
  );
};

export default Actions;

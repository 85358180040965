import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    ...expandDisplayFlex({}),
    gap: 3,
    marginTop: '2rem'
  }
});

export default useSx;

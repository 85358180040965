import { useState } from 'react';
import useFetchPlans from 'app/features/subscription/hooks/useFetchPlans';
import { SubscriptionsDetails } from '../SubscriptionGeneralProps';
import useSx from './sx';

const useSubscriptionDetailState = (subscriptionDetails: SubscriptionsDetails) => {
  const styles = useSx();
  const [isModalOpen, setModalOpen] = useState(false);
  const { userPlan, usage } = subscriptionDetails;
  const availableValue = userPlan?.settings?.studiesIncluded;
  const usedValue = usage?.totalStudiesCount;
  const adicionalValue = Math.max(0, usedValue - availableValue);
  const { plans } = useFetchPlans();
  const plansWithParsedSettings = plans?.map((plan) => {
    const settings = JSON.parse(plan.settingsJson);
    return {
      ...plan,
      settings,
    };
  });
  const nextPlan = plansWithParsedSettings?.find(item => item.productPlanId === userPlan.nextProductPlanId);

  return {
    styles,
    isModalOpen,
    setModalOpen,
    adicionalValue,
    userPlan,
    usage,
    nextPlan,
  };
};
export default useSubscriptionDetailState;

export default function replaceSignature(page: HTMLDivElement, signatureBase64?: string) {
  const signaturePlaceholders = page.querySelectorAll(
    '.SignaturePlaceholder',
  ) as NodeListOf<HTMLImageElement>;

  signaturePlaceholders.forEach((elem: HTMLImageElement) => {
    if (signatureBase64) {
      elem.src = `data:image/png;base64,${signatureBase64}`;
    } else {
      elem.remove();
    }
  });
}

import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Brightness4OutlinedIcon from '@mui/icons-material/Brightness4Outlined';
import { Box, Divider } from '@mui/material';
import RestrictToRole from '@components/RestrictToRole';
import SectionHeader from '@components/SectionHeader';
import { ThemeContext } from '@core/providers/ThemeProvider';
import { MedicalRoles } from '@core/types';
import isCustomErroHandlerResponse from '@core/utils/isCustomErrorHandlerResponse';
import ModeOptions from './ModeOptions';
import Notifications from './Notifications';
import useModeState from './useModeState';

const Mode: React.FC = () => {
  const {
    sx,
  } = useModeState();

  return (
    <Box sx={sx.root}>
      <SectionHeader title="Aspecto" IconComponent={Brightness4OutlinedIcon} />
      <Divider />
      <ModeOptions />
      <RestrictToRole allowedRoles={MedicalRoles}>
        <Notifications />
      </RestrictToRole>
    </Box>
  );
};

export default Mode;

import merge from 'lodash.merge';
import { grey } from '@mui/material/colors';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import baseTheme from './base.theme';

const primary = '#0E70FF';
const primaryContrast = '#e6e6e6';
const secondary = '#1A4488';
const secondaryContrast = '#f7f7f7';
const info = '#5FA2FF';
const warning = '#DC7414';
const tooltipMain = '#fff3cd';

const error = '#A70D0D';
const errorDark = '#870505';
const errorContrast = '#f7f7f7';

const brandPrimary = primary;
const brandSecondary = secondary;

export const shades = {
  50: '#01070C',
  100: '#060C11',
  200: '#091016',
  300: '#0C1822',
  400: '#162436',
  500: '#1c364d',
  600: '#2e4a63',
  700: '#415263',
  800: '#5b6875',
  900: '#ffffff',
};

export const backgroundDarkColor = shades[50];

const myTheme: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      default: backgroundDarkColor,
    },
    text: {
      primary: '#eeeeee',
    },
    primary: {
      main: primary,
      dark: brandSecondary,
      contrastText: primaryContrast,
    },
    secondary: {
      main: secondary,
      dark: brandSecondary,
      contrastText: secondaryContrast,
    },
    info: {
      main: info,
    },
    warning: {
      main: warning,
    },
    shade: shades,
    tooltip: {
      main: tooltipMain,
    },
    brand: {
      main: brandPrimary,
      contrastText: primaryContrast,
    },
    error: {
      main: error,
      contrastText: errorContrast,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: shades[100],
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: shades[200],
          borderRight: 'none',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: '36px',
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: shades[400],
          border: 'none', 
          '&:focus, &:hover': {
            border: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          height: '36px',
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: shades[400],
          borderColor: 'transparent',
          '&.Mui-focused .MuiInput-notchedOutline, &:hover .MuiInput-notchedOutline': {
            borderColor: 'transparent',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '36px',
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: shades[400],
          borderColor: 'transparent',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
            {
              borderColor: 'transparent',
            },
          '& .MuiOutlinedInput-notchedOutline': 
            {
              border: 'none',
            },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {},
        head: {
          backgroundColor: shades[300],
          color: grey[500],
          fontWeight: 500,
          textTransform: 'uppercase',
        },
        body: {
          backgroundColor: shades[200],
          borderBottom: '1px solid rgba(224, 224, 224, 0.2)',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          backgroundColor: shades[200],
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: `linear-gradient(165deg, ${brandPrimary}, ${brandSecondary})`,
          color: 'white',
          '&:hover': {
            background: `linear-gradient(300deg, ${brandPrimary}, ${brandSecondary})`,
          },
          '.iconWithSpacing': {
            marginRight: '5px',
          },
          '&.Mui-disabled': {
            background: shades[300],
          },
        },
        containedSecondary: {
          background: `linear-gradient(165deg, ${shades[700]}, ${shades[600]})`,
          color: 'white',
          '&:hover': {
            background: `linear-gradient(300deg, ${shades[700]}, ${shades[600]})`,
          },
          '.iconWithSpacing': {
            marginRight: '5px',
          },
          '&.Mui-disabled': {
            background: grey[200],
            color: grey[500],
          },
        },
        containedError: {
          background: `linear-gradient(165deg, ${error}, ${errorDark})`,
          color: 'white',
          '&:hover': {
            background: `linear-gradient(300deg, ${error}, ${errorDark})`,
          },
          '.iconWithSpacing': {
            marginRight: '5px',
          },
          '&.Mui-disabled': {
            background: shades[300]
          },
        },
        outlinedSecondary: {
          border: `1px solid ${shades[900]}`,
          color: shades[900],
          '&:hover': {
            border: `1px solid ${info}`,
            color: info,
            opacity: 0.9,
          },
          '.iconWithSpacing': {
            marginRight: '5px',
          },
          '&.Mui-disabled': {
            color: grey[500],
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: shades[50], 
          backgroundImage: 'none'
        }
      }
    },
  },
};

const newTheme: ThemeOptions = {};
merge(newTheme, myTheme, baseTheme);

const theme = createTheme(newTheme);

export default theme;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import useProfessionalInfoState from './useProfessionalInfoState';

const ProfessionalInfo: React.FC = () => {
  const navigate = useNavigate();
  const {
    showSnackbar,
    sx,
    isSaving,
    setIsSaving,
    isDeleting,
    setIsDeleting,
    postUserSignature,
    deleteUserSignature,
    data,
    refetch,
    info,
    setInfo,
  } = useProfessionalInfoState();

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInfo(event.target.value);
  };

  const handleSave = async () => {
    if (!info) return;

    setIsSaving(true);
    const formData = new FormData();
    formData.append('info', info);

    try {
      await postUserSignature(formData);
      let message;
      if (data && data.professionalInfo) {
        message = 'Su información se actualizó correctamente';
      } else {
        message = 'Su información se guardó correctamente';
      }
      showSnackbar({ message, title: 'Información guardada', type: 'success' });
    } catch (error) {
      console.error(error);
      showSnackbar({
        message: 'Lo sentimos, no pudimos procesar la información. Intente más tarde.',
        title: 'Ocurrió un error',
        type: 'error',
      });
    }
    setIsSaving(false);
    await refetch();
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteUserSignature();
      showSnackbar({
        message: 'Información eliminada correctamente',
        title: 'Información borrada',
        type: 'success',
      });
      setInfo('');
    } catch (error) {
      console.error(error);
      showSnackbar({
        message: 'Error al eliminar la información. Intente nuevamente.',
        title: 'Ocurrió un error',
        type: 'error',
      });
    }
    setIsDeleting(false);
    await refetch();
  };

  const handleCancel = () => {
    if (data && data.professionalInfo) {
      setInfo(data.professionalInfo);
    } else {
      setInfo('');
    }

    navigate('/');
  };

  return (
    <Box sx={sx.wrapper}>
      <Box sx={sx.textarea}>
        <textarea
          placeholder="Título/Especialidad&#10;Certificado&#10;Cédula&#10;Profesional/Universidad&#10;"
          value={info}
          onChange={handleInputChange}
        />
      </Box>
      <Box sx={sx.actionContainer}>
        <Box sx={sx.buttonWrapper}>
          <If condition={!!(info && (!data?.professionalInfo || info !== data?.professionalInfo))}>
            <ActionButton
              color="secondary"
              variant="outlined"
              text="Cancelar"
              onClick={handleCancel}
            />
          </If>
          <ActionButton
            color="error"
            variant="contained"
            text="Eliminar"
            disabled={!data?.professionalInfo}
            onClick={handleDelete}
            isLoading={isDeleting}
          />
          <ActionButton
            color="primary"
            variant="contained"
            text={
              data && data.professionalInfo && info !== data.professionalInfo
                ? 'Actualizar'
                : 'Guardar'
            }
            disabled={!info || info === data?.professionalInfo}
            onClick={handleSave}
            isLoading={isSaving}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfessionalInfo;

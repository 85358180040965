import React from 'react';
import ProtectedRoute from '@components/ProtectedRoute';
import useFetchUserPlan from 'app/features/subscription/hooks/useFetchUserPlan';
import CustomizationLayout from './Layout/CustomizationLayout';
import ReportsCustomization from './ReportsCustomization';
import SystemCustomization from './SystemCustomization';

const Customization: React.FC = () => {
  const { userPlan } = useFetchUserPlan(0);

  const enabled = userPlan?.plan?.productPlanId === 3;

  return (
    <ProtectedRoute allowedRoles={[]} isAdminAllowed isOwnerAllowed>
      <CustomizationLayout enabled={enabled}>
        <ReportsCustomization />
        <SystemCustomization enabled={enabled} />
      </CustomizationLayout>
    </ProtectedRoute>
  );
};

export default Customization;

import { get } from '@core/api/baseApi';
import { GetNotificationMessageResponse } from '@core/types';

export interface GetNotificationsRequest {
  Page?: number;
  Search?: string;
  SortOrder?: string;
  PageSize?: string;
  IsSeen?: string;
}

export const getNotifications = (
  filters: GetNotificationsRequest,
): Promise<GetNotificationMessageResponse> => get('/NotificationMessage', { params: filters });

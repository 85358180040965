import { useMutation } from '@tanstack/react-query';
import postTransferAccount from '@core/api/postTransferAccount';

const useTransferAccount = () => {
  const { isLoading: isUploading, mutateAsync: postAsyncTransferAccount } = useMutation(
    (data: { userUid: string, targetUserUid: string }) => postTransferAccount(data.userUid, data.targetUserUid)
  );

  return { isUploading, postAsyncTransferAccount };
};

export default useTransferAccount;

import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MuiInput from '@mui/material/Input';
import InputProps from './InputProps';
import useSx from './sx';

const Input = ({ sx: sxProp, type, ...props }: InputProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const sx = useSx();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const sxStyles = { ...sx.root, ...sxProp };

    return (
        <Box sx={sx.inputContainer}>
            <MuiInput
                type={type === 'password' && !showPassword ? 'password' : 'text'}
                sx={sxStyles}
                endAdornment={
                    type === 'password' ? (
                        <IconButton
                            sx={sx.passwordIconButton}
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    ) : null
                }
                {...props}
            />
        </Box>
    );
};

export default Input;

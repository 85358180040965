import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import useAddPatientUser from '@core/hooks/useAddPatientUser';
import useIsAuthenticated from '@core/hooks/useIsAuthenticated';
import useLogout from '@core/hooks/useLogout';
import { AuthContext } from '@core/providers/AuthProvider';
import { PatientUser } from '@core/types/User';
import getCurrentUserAccount from '@core/utils/getCurrentUserAccount';
import accessManager from '@core/utils/productAccessManager';
import { showStartLoader } from '@core/utils/startLoader';

const Redirect = () => {
  const appLogout = useLogout();
  const navigate = useNavigate();
  const { addPatientUser } = useAddPatientUser();
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const organizationId = sessionStorage.getItem('organizationId') || '';
  const studyIdsString = sessionStorage.getItem('studyIds') || '{}';
  const studyIds = useMemo(() => JSON.parse(studyIdsString), [studyIdsString]);
  const redirectUrl = studyIds?.studyInstanceUid ? `/studies/${studyIds?.studyInstanceUid}` : '/';
  const account = getCurrentUserAccount();
  const { idTokenClaims } = account ?? {};
  const userUid = idTokenClaims?.sub;
  const givenName = idTokenClaims?.given_name || '';
  const surname = idTokenClaims?.family_name || '';
  const email = idTokenClaims?.emails ? idTokenClaims?.emails[0] : '';
  const phoneNumber = idTokenClaims?.extension_PhoneNumber || '';
  const accessTokenScope = process.env.REACT_APP_ACCESS_TOKEN_SCOPE || '';
  const redirectUri = `${process.env.REACT_APP_ADB2C_REDIRECT_URI}/redirect` || '';
  const authority = process.env.REACT_APP_ADB2C_AUTHORITY_WITH_SIGN_UP || '';
  const authContext = useContext(AuthContext);

  const handleLoginUser = () => {
    authContext.setOrganization({ organizationId, hasMoreOrganizations: true });
    navigate(redirectUrl);
  };

  const handleSignUpUser = async () => {
    try {
      const requestBody: PatientUser = {
        userUid: userUid,
        givenName: givenName as string,
        surname: surname as string,
        emailAddress: email,
        phoneNumber: phoneNumber as string,
        organizationId: organizationId,
        studyHash: studyIds?.studyHash,
      };

      const response = await addPatientUser(requestBody);

      if (response.success) {
        navigate('/');
      } else {
        console.error('Failed to add patient user');
        appLogout();
      }
    } catch (error) {
      console.error('Error during signup:', error);
      appLogout();
    }
  };

  const attemptTokenAcquisition = async () => {
    try {
      const accessToken = await accessManager.acquireProductTokenSilent();
      if (accessToken) {
        handleLoginUser();
      } else {
        handleSignUpUser();
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  useEffect(() => {
    showStartLoader();
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      const loginRequest = {
        scopes: ['openid', 'profile', 'offline_access', accessTokenScope],
        authority,
        redirectUri,
        extraQueryParameters: {
          nonce: 'defaultNonce',
          prompt: 'login',
        },
        responseMode: 'fragment',
        responseType: 'token id_token',
      };

      instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (
      isAuthenticated &&
      userUid &&
      organizationId &&
      (studyIds.studyInstanceUid || studyIds.studyHash)
    )
      attemptTokenAcquisition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, userUid, studyIds, isAuthenticated]);

  return <></>;
};

export default Redirect;

/**
 * Represent the types of sources to handle in the Paste Plugin
 */
// eslint-disable-next-line etc/no-const-enum
export enum CompatibleKnownPasteSourceType {

    WordDesktop,
    ExcelDesktop,
    ExcelOnline,
    PowerPointDesktop,
    GoogleSheets,
    WacComponents,
    Default,
    SingleImage,
}

import React, { useState } from 'react';
import useSnackbar from '@core/hooks/useSnackbar';
import useUpdateOrganizationSettings from '@core/hooks/useUpdateOrganizationSettings';
import { themeDetailsMap } from '@core/utils/generateThemeDetailsMap';
import useSx from './sx';

const useThemeState = () => {
  const sx = useSx();
  const showSnackbar = useSnackbar();
  const [currentThemeDetails, setCurrentThemeDetails] = useState(themeDetailsMap);
  const updateSettings = useUpdateOrganizationSettings();

  return {
    sx,
    showSnackbar,
    currentThemeDetails, 
    setCurrentThemeDetails,
    updateSettings
  };
};

export default useThemeState;

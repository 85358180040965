import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useProfile from '@core/hooks/useProfile'
import { AddUserResponse } from '@core/types'
import useGetPermissions from 'app/features/permissions/get/hooks/useGetPermissions'
import useAddUser from '../hooks/useAddUser'
import useSendNotification from '../hooks/useSendNotification'

const useAddUserState = () => {
  const navigate = useNavigate()
  const { hasAdminPermissions } = useProfile()
  const { addUser, isLoading } = useAddUser()
  const { sendNotification, isLoading: isSendingNotification } = useSendNotification()
  const { fullName, organization } = useProfile()
  const [open, setOpen] = useState(false)
  const [isUserInfoDialogOpen, setIsUserInfoDialogOpen] = useState(false)
  const [user, setUser] = useState<AddUserResponse | undefined>(undefined)
  const { permissions } = useGetPermissions({ SearchTerm: '' })


  useEffect(() => {
    if(!hasAdminPermissions) {
      navigate('/')
    }
  }, [navigate, hasAdminPermissions])

  return {
    open,
    setOpen,
    permissions,
    addUser,
    isUserInfoDialogOpen,
    setIsUserInfoDialogOpen,
    user,
    setUser,
    isLoading,
    sendNotification,
    fullName,
    organizationId: organization?.organizationId,
    isSendingNotification
  }
}

export default useAddUserState
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FilePdf from '@assets/icons/FilePdf';
import If from '@components/If';
import ViewerButton from '@components/ViewerButton';
import formatFileSize from './formatFileSize';
import useSx from './sx';

export interface SelectedFileDisplayProps {
  fileInfo: { name: string; size: number };
  isUploading?: boolean;
  onClear?: VoidFunction;
}

const SelectedFileDisplay = ({ fileInfo, isUploading, onClear }: SelectedFileDisplayProps) => {
  const sx = useSx();
  const { name, size } = fileInfo;

  return (
    <Box className="SelectedFileDisplay-root" sx={sx.root}>
      <Card variant="outlined">
        <CardContent>
          <Avatar variant="rounded">
            <FilePdf />
          </Avatar>
          <Stack className="SelectedFileDisplay-stack">
            <Typography>{name?.toLowerCase()}</Typography>
            <Typography>{formatFileSize(size)}</Typography>
            <If condition={isUploading}>
              <LinearProgress color="info" />
            </If>
          </Stack>
          <ViewerButton icon variant="text" onClick={onClear}>
            <CloseIcon />
          </ViewerButton>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SelectedFileDisplay;

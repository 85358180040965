import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  selectRoot: {
    marginRight: '8px',
    minWidth: 'auto',
    '& .MuiInputBase-root': {
      padding: 0,
      pl: 4,

      '& .MuiInputAdornment-root': {
        pr: 2,
      },
    },
    '& .MuiSelect-select': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      padding: 0,
    },
  },
  paper: {
    '::-webkit-scrollbar': {
      width: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: '#D3D3D3',
    },

    '::-webkit-scrollbar-thumb': {
      background: '#D2D2D2',
    },

    '::-webkit-scrollbar-thumb:hover': {
      background: '#D2D2D2',
    },
  },
});

export default useSx;

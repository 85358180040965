import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    marginTop: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem 0',
  },
  iconButton: {
    border: 'none',
    textAlign: 'center',
    color: (theme) =>
      theme.palette.mode === 'dark' ? theme.palette.shade?.[900] : theme.palette.shade?.[500],
    '& .MuiSvgIcon-root': {
      fontSize: '2.8rem',
    },
    '&:hover': {
      border: 'none',
    },
  },
});

export default useSx;

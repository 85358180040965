import { useQuery } from '@tanstack/react-query';
import { getTemplate } from '../api/getTemplate';

export const QUERY_KEY = 'templateSingle';

const useGetSingleTemplate = (templateId: string) => {
  const { data: template, ...rest } = useQuery({
    queryKey: [QUERY_KEY, templateId],
    queryFn: () => getTemplate(templateId),
    enabled: templateId.length !== 0,
  });

  return {
    template,
    ...rest,
  };
};

export default useGetSingleTemplate;

import { get } from '@core/api/baseApi';

export const getStudyReport = async ({
  studyHash,
}: {
  studyHash: string;
}): Promise<{
  reportsUrls: string[];
}> => {
  return await get(`/studies/${studyHash}/reports`);
};

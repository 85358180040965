function blobToBase64(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(blob);

    reader.onloadend = () => {
      if (reader.result instanceof ArrayBuffer) {
        reject(new Error('Error: ArrayBuffer result encountered'));
      } else {
        resolve(reader.result as string);
      }
      reader.onloadend = null;
      reader.onerror = null;
    };

    reader.onerror = () => {
      reject(reader.error);
      reader.onloadend = null;
      reader.onerror = null;
    };
  });
}

export default blobToBase64;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import ContainerCard from '@components/ContainerCard/ContainerCard';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import useSnackbar from '@core/hooks/useSnackbar';
import { TemplateFile, TemplateFolder } from '@core/types';
import isCustomErroHandlerResponse from '@core/utils/isCustomErrorHandlerResponse';
import FormDialog from '../../add/components/FormDialog';
import { InitialValues } from '../../add/components/FormDialog/FormDialogProps';
import FolderForm from '../../components/FolderForm';
import FormLayout from '../../components/FormLayout';
import FOLDER_ERROR_MESSAGES from '../../constants/folderErrorMessages';
import useAddFolder from '../../hooks/useAddFolder';
import useGetFolders from '../../hooks/useGetFolders';
import navigateToFoldersBasedOnFolderPath from '../../utils/navigateToFolderBasedOnFolderPath';
import useEditTemplateState from './useEditTemplateState';

const createFolderName = (folder: { [key: string]: TemplateFile | TemplateFolder }) => {
  let counter = 1;
  const placeholder = 'Nueva Carpeta';
  let FOLDER_NAME = placeholder;

  const folderNamesArr = Object.values(folder)
    .filter((s) => !s.isFile)
    .map((s) => s.name);

  const uniqueFolderNames = new Set(folderNamesArr);

  while (uniqueFolderNames.has(FOLDER_NAME)) {
    FOLDER_NAME = placeholder + ` ${counter}`;
    counter += 1;
  }

  return FOLDER_NAME;
};

const EditTemplate = () => {
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();
  const {
    updateTemplate,
    addTemplate,
    removeTemplate,
    template,
    canUserEditTemplate,
    setIsDeleteDialogOpen,
    isDeleteDialogOpen,
    isTemplateFetched,
  } = useEditTemplateState();
  const { userUid } = useProfile();
  const [formValues, setFormValues] = useState<InitialValues | null>(null);
  const [formStatus, setFormStatus] = useState<'ADD_NEW' | 'UPDATE' | null>(null);
  const { addFolderAsync } = useAddFolder();

  const filters = {
    Section: canUserEditTemplate && formValues?.organizationWide ? 'Organization' : 'Private',
  };

  //Section is showing the section where the template belongs to, so it should respect
  // the path
  const isPublicTemplateInPublicSection =
    filters.Section === 'Organization' && template?.organizationWide;

  const isPrivateTemplateInPrivateSection =
    filters.Section === 'Private' && !template?.organizationWide;

  const initialFolderPath =
    isPublicTemplateInPublicSection || isPrivateTemplateInPrivateSection
      ? template?.folderPathIds
      : [];

  const { folders } = useGetFolders(filters, !!formStatus);

  const onInitialFormAddNew = async (values: InitialValues) => {
    setFormValues(values);
    setFormStatus('ADD_NEW');
  };

  function closeFolderModal() {
    setFormValues(null);
    setFormStatus(null);
  }

  const onAddNewTemplate = async ({
    folderId,
    templateName,
  }: {
    folderPath: number[];
    folderId: number | undefined;
    templateName: string;
  }) => {
    const addTemplateResponse = await addTemplate({
      templateName,
      templateFolderId: folderId,
      folderName: undefined,
      content: formValues?.content,
      organizationWide: canUserEditTemplate ? formValues?.organizationWide : false,
    });
    if (!addTemplateResponse.success) {
      showSnackbar({
        message: 'La plantilla no ha sido guardada.',
        title: 'Ocurrió un error',
        type: 'error',
      });
      return;
    }
    navigate(`/templates/${addTemplateResponse.template.templateId}`);
    showSnackbar({
      message: 'Plantilla configurada con éxito',
      title: 'Plantilla guardada',
      type: 'success',
    });
    closeFolderModal();
  };

  const onUpdateTemplate = async ({
    folderId,
    templateName,
    content = template!.content,
  }: {
    folderId: number | undefined;
    templateName: string;
    content?: string;
  }) => {
    if (template) {
      let payload = {
        templateFolderId: folderId,
        templateId: template!.templateId,
        organizationWide: template!.organizationWide,
        templateName,
        content,
      };
      if (formValues) {
        payload = {
          ...payload,
          organizationWide: formValues!.organizationWide,
          content: formValues!.content,
        };
      }

      const updateTemplateResponse = await updateTemplate(payload);
      if (!updateTemplateResponse.success) {
        showSnackbar({
          message: 'La plantilla no ha sido actualizada.',
          title: 'Ocurrió un error',
          type: 'error',
        });
        return;
      }
      showSnackbar({
        message: 'Plantilla se actualizó con éxito',
        title: 'Plantilla actualizada',
        type: 'success',
      });
      closeFolderModal();
    }
  };

  const onInitialFormSave = async (values: InitialValues) => {
    if (values.organizationWide === template?.organizationWide) {
      onUpdateTemplate({
        folderId: template!.folderId,
        templateName: template!.templateName,
        content: values.content,
      });
    } else {
      setFormValues(values);
      setFormStatus('UPDATE');
    }
  };

  const onDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  const onDeleteConfirm = async () => {
    const response = await removeTemplate(template?.templateId?.toString() || '');
    if (response.success) {
      showSnackbar({
        message: 'Plantilla eliminada con éxito',
        title: 'Plantilla eliminada',
        type: 'success',
      });
      navigate('/templates');
      return;
    }

    showSnackbar({
      message: 'La plantilla no ha sido eliminada',
      title: 'Ocurrió un error',
      type: 'error',
    });
  };

  const handleAddNewFolder = async (folderPath: number[]) => {
    const workingFolder = navigateToFoldersBasedOnFolderPath(folders?.root || {}, folderPath);

    const folderName = createFolderName(workingFolder);

    //It should be storing according to what is showing
    const isOrganizationWide = filters.Section === 'Organization';

    if (formValues) {
      const response = await addFolderAsync({
        folderName,
        folderPath,
        isOrganizationWide,
      });

      if (isCustomErroHandlerResponse(response)) {
        showSnackbar({
          type: 'error',
          message: FOLDER_ERROR_MESSAGES[response.statusCode] || FOLDER_ERROR_MESSAGES.default,
          title: 'Error',
        });
      }
    }
  };

  const onOverrideTemplate = async (templateProp: TemplateFile, folderId: number | undefined) => {
    if (formValues) {
      if (templateProp.ownerUserUid !== userUid) {
        showSnackbar({
          type: 'error',
          title: 'Error',
          message:
            'No tienes permiso para modificar a esta plantilla. Por favor, selecciona una que te pertenezca.',
        });
        return;
      }
      const updateTemplateResponse = await updateTemplate({
        content: formValues?.content,
        templateId: templateProp.folderHierarchyItemId,
        templateName: templateProp.name,
        organizationWide: !!templateProp.organizationWide,
        templateFolderId: folderId,
      });
      if (!updateTemplateResponse.success) {
        showSnackbar({
          message: 'La plantilla no ha sido actualizada.',
          title: 'Ocurrió un error',
          type: 'error',
        });
        return;
      }
      showSnackbar({
        message: 'Plantilla se actualizó con éxito',
        title: 'Plantilla actualizada',
        type: 'success',
      });
      closeFolderModal();
    }
  };

  const formSubmitStrategies = {
    UPDATE: onUpdateTemplate,
    ADD_NEW: onAddNewTemplate,
  };

  return (
    <FormLayout title="Plantillas" subtitle={template?.templateName || ''}>
      <If condition={isTemplateFetched}>
        <ContainerCard>
          <If condition={!canUserEditTemplate}>
            <Alert severity="warning">
              No cuentas con los permisos para editar esta plantilla.
            </Alert>
          </If>
          <FormDialog
            title="Editar plantilla"
            canUserEditTemplate={canUserEditTemplate || false}
            onSave={canUserEditTemplate ? onInitialFormSave : undefined}
            onAddNew={onInitialFormAddNew}
            onDelete={canUserEditTemplate ? onDelete : undefined}
            initialValues={{
              templateName: template?.templateName || '',
              content: template?.content || '',
              folderName: template?.folderName || '',
              organizationWide: template?.organizationWide || false,
            }}
          />
        </ContainerCard>
      </If>
      <If condition={!!template?.folderPathIds && !!formStatus}>
        <FolderForm
          initialFolderPath={initialFolderPath || []}
          isOpen={!!formStatus}
          onClose={closeFolderModal}
          onTemplateClick={onOverrideTemplate}
          initialValues={{ templateName: formValues?.templateName || '' }}
          foldersAndTemplates={folders?.root || {}}
          handleAddNewFolder={handleAddNewFolder}
          handleOnSave={formSubmitStrategies[formStatus!]}
        />
      </If>
      <ActionDialog
        type="delete"
        title="Confirmar eliminación"
        message="¿Estás seguro de que deseas eliminar esta plantilla? Esta acción no se puede deshacer."
        open={isDeleteDialogOpen}
        onConfirm={onDeleteConfirm}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        onCancel={() => {
          setIsDeleteDialogOpen(false);
        }}
      />
    </FormLayout>
  );
};

export default EditTemplate;

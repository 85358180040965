import React, { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { SxProps, Theme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { UserItem } from '@core/types';
import useUsers from 'app/features/users/hooks/useUsers';
import useSx from './sx';

interface UserAutoCompleteProps {
  onUserSelect: (value: UserItem | null) => void;
  placeholder: string;
  textFieldProps?: TextFieldProps;
  searchRoles: string;
  hideSelectedOption?: boolean;
  adminUsersIncluded?: boolean;
  ownerUserIncluded?: boolean;
  filteredIds?: Set<string>;
}

const UserAutoComplete: React.FC<UserAutoCompleteProps> = ({
  onUserSelect,
  placeholder,
  textFieldProps,
  searchRoles,
  hideSelectedOption = false,
  adminUsersIncluded = true,
  ownerUserIncluded = true,
  filteredIds,
}) => {
  const sx = useSx();
  const [inputValue, setInputValue] = useState('');
  const [selectedUser, setSelectedUser] = useState<UserItem | undefined>(undefined);
  const [filters, setFilters] = useState({
    Role: searchRoles,
    Search: '',
    SortOrder: 'ASC',
    page: '1',
    AdminUsersIncluded: adminUsersIncluded ? 1 : 0,
    OwnerUserIncluded: ownerUserIncluded ? 1 : 0,
  });

  const { users, isLoading } = useUsers({
    filters,
    enabled: true,
  });

  const { sx: outerTextfieldSx, ...restTextFieldProps } = textFieldProps || ({} as TextFieldProps);
  const combinedSxTextfield = { ...sx.textfield, ...outerTextfieldSx } as SxProps<Theme>;

  const handleTemplateChange = (value: string): void => {
    if (value) {
      setFilters((prev) => ({
        ...prev,
        Search: value,
      }));
    }
  };

  const handleChangeDebounced = useMemo(
    () => debounce(handleTemplateChange, 300),
    [filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onInputChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = ($e) => {
    setInputValue($e.target.value); // Update inputValue on input change
    handleChangeDebounced($e.target.value);
  };

  const onUserSelectHandler = (_: React.SyntheticEvent<Element, Event>, user: UserItem | null) => {
    onUserSelect(user);
    if (hideSelectedOption) {
      setSelectedUser(undefined);
      setInputValue(''); // Clear the input when an option is selected
    } else {
      setSelectedUser(user || undefined);
      setInputValue(user?.fullName || ''); // Set input to user's full name when selected
    }
  };

  const filteredUsers = filteredIds
    ? users?.items.filter((u) => !filteredIds.has(u.userUid))
    : users?.items;

  return (
    <Autocomplete
      options={filteredUsers || []}
      renderOption={(props, option) => (
        <li {...props} key={option.userUid}>
          {option.fullName}
        </li>
      )}
      getOptionLabel={(option) => option.fullName}
      isOptionEqualToValue={(option) => option.userUid === selectedUser?.userUid}
      loading={isLoading}
      onChange={onUserSelectHandler}
      inputValue={inputValue} // Conditionally control the selected value to avoid highlighting
      value={hideSelectedOption ? undefined : selectedUser} // Conditionally control the selected value to avoid highlighting
      disableClearable={hideSelectedOption} // Disable the clear (x) button
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={onInputChange}
          fullWidth
          sx={combinedSxTextfield}
          hiddenLabel
          placeholder={placeholder}
          {...restTextFieldProps}
        />
      )}
    />
  );
};

export default UserAutoComplete;

import { RoleName } from '@core/types';

const getMenuItems = () => [
  {
    key: 'profile',
    label: 'Perfil',
    route: '/perfil',
    icon: 'perm_contact_calendar',
    enabled: true,
    roles: {
      [RoleName.Radiologist]: true,
      [RoleName.AccountOwner]: true,
      [RoleName.Administrator]: true,
      [RoleName.Anonymous]: true,
      [RoleName.Referrer]: true,
      [RoleName.Technician]: true,
      [RoleName.Patient]: true,
      [RoleName.System]: true,
      [RoleName.None]: false,
    },
  },
  {
    key: 'preferences',
    label: 'Preferencias',
    route: '/preferences',
    icon: 'tune',
    divider: false,
    enabled: true,
    roles: {
      [RoleName.Radiologist]: true,
      [RoleName.AccountOwner]: true,
      [RoleName.Administrator]: true,
      [RoleName.Anonymous]: true,
      [RoleName.Referrer]: true,
      [RoleName.Technician]: true,
      [RoleName.Patient]: true,
      [RoleName.System]: true,
      [RoleName.None]: false,
    },
  },
  {
    key: 'templates',
    label: 'Plantillas',
    route: '/templates',
    icon: 'article',
    enabled: true,
    subpaths: {
      '/templates/add': true,
      '/templates/edit': true,
    } as Record<string, boolean>,
    roles: {
      [RoleName.Radiologist]: true,
      [RoleName.AccountOwner]: false,
      [RoleName.Administrator]: false,
      [RoleName.Anonymous]: false,
      [RoleName.Referrer]: false,
      [RoleName.Technician]: false,
      [RoleName.Patient]: false,
      [RoleName.System]: true,
      [RoleName.None]: false,
    },
  },
  {
    key: 'users',
    label: 'Usuarios',
    route: '/users',
    icon: 'group',
    enabled: false,
    roles: {
      [RoleName.Radiologist]: false,
      [RoleName.AccountOwner]: false,
      [RoleName.Administrator]: false,
      [RoleName.Anonymous]: false,
      [RoleName.Referrer]: false,
      [RoleName.Technician]: false,
      [RoleName.Patient]: false,
      [RoleName.System]: true,
      [RoleName.None]: false,
    },
  },
];

export default getMenuItems;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    overflow: 'hidden',
    borderRadius: 0,
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    marginTop: '16px',
    '.MuiTableCell-root': {
      textTransform: 'none',
    },
  },
  pagination: { marginLeft: 'auto', marginTop: '16px' },
  emptyRecords: { margin: 'auto' },
  progress: { margin: 'auto' },
});

export default useSx;

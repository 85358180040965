import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const Placeholder = () => (
  <Box display="flex" gap={2} alignItems="center" justifyContent="space-between" padding="4px 16px">
    <Skeleton variant="circular" height="40px" width="40px" sx={{ flexGrow: 0 }} />
    <Skeleton variant="rounded" height="20px" sx={{ flexGrow: 1 }} />
  </Box>
);

const Loading = () => {
  return (
    <Stack gap={1} sx={{ mt: 4 }}>
      <Placeholder />
      <Placeholder />
      <Placeholder />
    </Stack>
  );
};

export default Loading;

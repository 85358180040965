import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import useSx from './sx';

const FullScreenLoader = ({ transparent }: { transparent?: boolean }) => {
  const sx = useSx(transparent);
  return (
    <Box sx={sx.root}>
      <CircularProgress />
    </Box>
  );
};

export default FullScreenLoader;

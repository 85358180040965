import { publish } from '@core/constants/customEvents';
import { ButtonFactory } from './buttonRegistryFactory';

const createAddFooterRibbonButton: ButtonFactory = () => {
  return {
    key: 'buttonQRCode',
    unlocalizedText: 'Insert QR Code',
    iconName: 'InsertSignatureLine',
    onClick: async () => {
      publish('evodicom.report.insertQrCode', undefined);
    },
  };
};

export default createAddFooterRibbonButton;

import addInitialTemplate from 'app/features/reports/add/buttons/addInitialTemplate';
import createAddFooterRibbonButton from 'app/features/reports/add/buttons/createAddFooterRibbonButton';
import recordButton from 'app/features/reports/add/buttons/recordButton';
import translateReport from 'app/features/reports/add/buttons/translateReport';
import uploadAudioButton from 'app/features/reports/add/buttons/uploadAudioButton';
import { registerButton } from './buttonRegistryFactory';
import createTemplatesButton from './createTemplatesRibbonButton';
import lineHeightSelector from './lineHeightSelector';

registerButton('templates', createTemplatesButton);
registerButton('lineHeight', lineHeightSelector);
registerButton('uploadAudio', uploadAudioButton);
registerButton('record', recordButton);
registerButton('translate', translateReport);

registerButton('addFooter', createAddFooterRibbonButton);
registerButton('addInitialTemplate', addInitialTemplate);

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import ActionButton from '@components/Button/ActionButton';
import ActionsProps from './ActionsProps';
import useSx from './sx';

const Actions = ({ isLoading, resetForm }: ActionsProps) => {
  const sx = useSx();
  const navigate = useNavigate();

  return (
    <Box sx={sx.root}>
      <ActionButton
        color="secondary"
        variant="outlined"
        onClick={() => {
          resetForm();
          navigate('/');
        }}
        text="Cancelar"
      />
      <ActionButton
        color="primary"
        variant="contained"
        isLoading={isLoading}
        text="Guardar"
        type="submit"
      />
    </Box>
  );
};

export default Actions;

import { Caret } from '../components/Form/TextEditor/TextEditor';

function navigateToCaretNode(editorDiv: Node, caretPositionProp: Caret | null) {
  if (caretPositionProp === null) {
    return null;
  }

  if (caretPositionProp.nodesPath === null) {
    return null;
  }

  if (caretPositionProp.nodesPath.length === 0) {
    return editorDiv;
  }

  const nodes = [];
  let currentNode = editorDiv;
  for (const childIndex of caretPositionProp.nodesPath) {
    if (childIndex >= currentNode.childNodes.length) {
      return null;
    }
    nodes.push(currentNode.childNodes[childIndex]);
    currentNode = currentNode.childNodes[childIndex];
  }
  return currentNode;
}

export default navigateToCaretNode;

import useMediaQuery from '@mui/material/useMediaQuery';

const useDeviceFeature = () => {
  const isHoverable = useMediaQuery('(hover:hover) and (pointer:fine)');
  const isTouchscreen = !isHoverable;

  return { isHoverable, isTouchscreen };
};

export default useDeviceFeature;

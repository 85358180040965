import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  chartBox: {
    height: '400px',
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  chartContainer: {
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  consumptionContainer: {
    position: 'relative',
    marginBottom: '1rem',
    width: '100%',
    flexWrap: 'wrap',
    height: 'fit-content',
  },
  label: {
    fontSize: 12,
    color: '#fff',
  },
  pieChartDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    marginBottom: 1
  },
  pieChartDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  statsBox: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    padding: '0.25rem 0.5rem',
    backgroundColor: (theme) => theme.palette.primary.main,
    color: 'white',
  },
  statsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 1
  },
  title: {
    display: 'inline-block',
    position: 'relative',
    paddingBottom: '1rem',
    marginBottom: '1rem',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      width: 'auto',
      height: '3px',
      backgroundImage: 'linear-gradient(to right, #002e7a, #0066ff)',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
  },
  userChartColorIndicator: {
    height: 20,
    width: 20,
    borderRadius: '5px',
    marginRight: '5px',
  },
  userIcon: {
    fontSize: 100,
    color: (theme) => theme.palette.primary.main,
  },
});

export default useSx;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GET_TEMPLATES_QUERY_KEY } from '../../hooks/useGetTemplates';
import { updateTemplate } from '../api/updateTemplate';
import { QUERY_KEY as GET_SINGLE_TEMPLATE_QUERY_KEY } from '../hooks/useGetSingleTemplate';

const useUpdateTemplate = () => {
  const queryClient = useQueryClient();

  const { isLoading: isUploading, mutateAsync: asyncUpdateTemplate } = useMutation({
    mutationFn: (payload: {
      templateId: number;
      templateName: string;
      content: string;
      templateFolderId?: number;
      organizationWide: boolean;
    }) => updateTemplate(payload),
    mutationKey: ['TemplateUpdate'],
    onSuccess: () => {
      queryClient.invalidateQueries([GET_TEMPLATES_QUERY_KEY]);
      queryClient.invalidateQueries([GET_SINGLE_TEMPLATE_QUERY_KEY]);
    },
  });

  return { isUploading, updateTemplate: asyncUpdateTemplate };
};

export default useUpdateTemplate;

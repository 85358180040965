import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        backgroundColor: 'transparent',
        width: '90%',
        margin: '1rem auto',
        borderRadius: '12px',
        '.MuiListItemButton-root': {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            color: (theme) => theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.shade?.[500],
            ':hover': {
                backgroundColor: 'transparent',
                color: (theme) => theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.shade?.[300],
            },
            '.MuiListItemIcon-root': {
                minWidth: 0,
                color: (theme) => theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.shade?.[500],
                ':hover': {
                    color: (theme) => theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.shade?.[300],
                }
            },
            '.MuiSvgIcon-root': {
                fontSize: '1.8rem',
            },
            '.MuiListItemText-root': {
                flex: 0,
                '.MuiTypography-root': { fontWeight: 200, fontSize: '0.8rem' },
            }, 
        }
    },
    icon: {
      marginRight: '1rem'
    }
});

export default useSx;

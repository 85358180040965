import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import ColorPicker from '@components/ColorPicker';
import If from '@components/If';
import useCurrentTheme from '@core/hooks/useCurrentTheme';
import { ThemeType } from '@core/types/Theme';
import { darkenHexColor } from '@core/utils/darkenHexColor';
import { generateThemeDetailsMap, themeDetailsMap } from '@core/utils/generateThemeDetailsMap';
import { ThemeOptionsProps } from './ThemeOptionsProps';
import ThemePreview from './ThemePreview';
import EmptyPreview from './ThemePreview/EmptyPreview';
import useThemeOptionsState from './useThemeOptionsState';

const ThemeOptions = ({
  currentThemeDetails,
  setCurrentThemeDetails,
  enabled,
  showPackageSnackbar,
}: ThemeOptionsProps) => {
  const { availableThemes, switchTheme, currentTheme, themeVariant, customThemeData } =
    useCurrentTheme();
  const {
    sx,
    onlyDefault,
    setOnlyDefault,
    showPicker,
    setShowPicker,
    anchorEl,
    setAnchorEl,
    postOrganizationTheme,
    isLoading,
    showSnackbar,
  } = useThemeOptionsState();

  const handleThemeClick = (selectedTheme: ThemeType) => {
    if (!enabled) {
      showPackageSnackbar();
      return;
    }
    if (currentTheme !== selectedTheme) {
      switchTheme(selectedTheme);
    }
  };

  const handleShowPicker = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setShowPicker(true);
  };

  const handleClosePicker = () => {
    setAnchorEl(null);
    setShowPicker(false);
  };

  const handleSaveNewTheme = async (color: string) => {
    try {
      const primaryDark = darkenHexColor(color, 30);

      const payload = {
        primaryColor: color,
        primaryDarkColor: primaryDark,
      };

      await postOrganizationTheme(payload);

      showSnackbar({
        type: 'success',
        title: 'Tema customizado guardado',
        message: 'El tema customizado se ha guardado correctamente.',
      });
    } catch (error) {
      if (!enabled) {
        showSnackbar({
          title: 'No se pudo guardar',
          message: 'Debes tener el paquete "Business" para usar este feature.',
        });
      } else {
        showSnackbar({
          type: 'error',
          title: 'Ocurrió un error',
          message: 'Error al guardar el tema customizado.',
        });
      }
    }
    handleClosePicker();
  };

  useEffect(() => {
    const { light, dark } = customThemeData;
    const customData = themeVariant === 'Light' ? light : dark;

    if (customData) {
      const updatedThemeDetails = generateThemeDetailsMap(customData);
      setCurrentThemeDetails(updatedThemeDetails);
    } else {
      setCurrentThemeDetails(themeDetailsMap);
    }

    if (light && dark) {
      setOnlyDefault(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeVariant, customThemeData]);

  return (
    <Box sx={sx.root}>
      {availableThemes.map((theme) => {
        if (onlyDefault && theme !== 'evodicom') {
          return null;
        }

        const themeKey = `${theme}${themeVariant}` as keyof typeof themeDetailsMap;
        const themeDetails = currentThemeDetails[themeKey];

        if (!themeDetails) {
          console.error(`Theme details for key "${themeKey}" not found.`);
          return null;
        }

        return (
          <>
            <ThemePreview
              key={themeKey}
              theme={themeDetails}
              currentTheme={currentTheme}
              onClick={() => handleThemeClick(theme)}
              onEditClick={handleShowPicker}
            />
            <If condition={showPicker && theme !== 'evodicom'}>
              <ColorPicker
                anchorEl={anchorEl}
                onClose={handleClosePicker}
                onSave={handleSaveNewTheme}
                isLoading={isLoading}
              />
            </If>
          </>
        );
      })}
      <If condition={onlyDefault}>
        <EmptyPreview
          key={'empty'}
          theme={currentThemeDetails[currentTheme]}
          currentTheme={currentTheme}
          onEditClick={handleShowPicker}
        />
        <If condition={showPicker}>
          <ColorPicker
            anchorEl={anchorEl}
            onClose={handleClosePicker}
            onSave={handleSaveNewTheme}
            isLoading={isLoading}
          />
        </If>
      </If>
    </Box>
  );
};

export default ThemeOptions;

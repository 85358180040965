import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: (theme) => ({
    flexGrow: 1,
    ...expandDisplayFlex({ j: 'flex-end', a: 'flex-end' }),

    '& .MuiButton-root': {
      width: 1,
    },

    [theme.breakpoints.up('sm')]: {
      mt: 4,
      width: 1,
      maxWidth: 0.4,
      alignSelf: 'flex-end',

      '& .MuiButton-root:last-of-type': {
        ml: 4,
      },
    },
  }),
});

export default useSx;

import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useSx from './sx';

export interface TitleProps {
  title?: string;
}

const Title = ({ title }: TitleProps) => {
  const sx = useSx();

  return title ? (
    <Grid item sm={3} xs={12} sx={sx.root}>
      <Typography variant="h4">
        {title}
      </Typography>
    </Grid>
  ) : null;
};

export default Title;

import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import ViewerDialog from '@components/ViewerDialog';
import useProfile from '@core/hooks/useProfile';
import FolderView from 'app/features/templates/components/FolderView';
import useGetFolders from 'app/features/templates/hooks/useGetFolders';
import AddTemplateDialogProps from './AddTemplateDialogProps';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(1, 'Por favor introduzca un título')
    .required('Por favor introduzca un título'),
});

const initialValues = {
  title: '',
  folderName: '',
  organizationWide: false,
  templateFolderId: undefined,
};

const AddTemplateDialog = ({ onAccept, onCancel, open, isLoading }: AddTemplateDialogProps) => {
  const { hasAdminPermissions } = useProfile();
  const [filters, setFilters] = useState({
    Section: 'Private',
  });
  const { folders } = useGetFolders(filters);

  useEffect(() => {
    // Resets filters when opening/closing dialog to match UI state
    setFilters({
      Section: 'Private',
    });
  }, [open]);

  return (
    <ViewerDialog open={open} title="Agregar plantilla">
      <Formik validationSchema={validationSchema} onSubmit={onAccept} initialValues={initialValues}>
        {({ values, handleChange, setFieldValue, handleSubmit, errors }) => {
          return (
            <Box
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              gap="18px"
              component="form"
            >
              <TextField
                name="title"
                value={values.title}
                onChange={handleChange}
                placeholder="Titulo"
                error={!!errors.title}
                helperText={errors.title}
              />
              <FolderView
                itemArray={folders?.root || {}}
                initialPath={[]}
                onFolderNavigation={(folder) => {
                  setFieldValue('templateFolderId', folder.folderHierarchyItemId);
                  setFieldValue('folderName', folder.name);
                }}
                smallView
                enableLocalSearch
              />
              <If condition={hasAdminPermissions}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.organizationWide}
                      onChange={(e) => {
                        setFieldValue('organizationWide', e.target.checked);
                        setFilters({
                          Section: e.target.checked ? 'Organization' : 'Private',
                        });
                      }}
                    />
                  }
                  label="Mostrar a toda la organización"
                />
              </If>
              <Box marginLeft="auto" display="flex" alignItems="center" gap="10px">
                <ActionButton
                  isLoading={isLoading}
                  onClick={onCancel}
                  variant="outlined"
                  type="button"
                  color="secondary"
                  text="Cancelar"
                />
                <ActionButton
                  isLoading={isLoading}
                  variant="contained"
                  type="submit"
                  text="Agregar"
                />
              </Box>
            </Box>
          );
        }}
      </Formik>
    </ViewerDialog>
  );
};

export default AddTemplateDialog;

import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ConfirmDialog from '@components/ConfirmDialog/ConfirmDialog';
import msalInstance from '@core/auth/msalInstance';
import ApiProvider from '@core/providers/ApiProvider';
import AuthContextProvider from '@core/providers/AuthProvider';
import LoadingAppProvider from '@core/providers/LoadingAppProvider';
import SnackbarProvider from '@core/providers/SnackbarProvider';
import ThemeProvider from '@core/providers/ThemeProvider';
import './App.css';
import './styles/rooster-overrides.css';
// eslint-disable-next-line import/order
import { ConfirmActionProvider } from '@core/providers/ConfirmActionProvider';
import AppRoutes from 'app/routes/AppRoutes';

const App = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
        <LoadingAppProvider>
          <ApiProvider>
            <AuthContextProvider>
              <ThemeProvider>
                <SnackbarProvider>
                  <ConfirmActionProvider>
                    <AppRoutes />
                    <ConfirmDialog />
                  </ConfirmActionProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </AuthContextProvider>
          </ApiProvider>
        </LoadingAppProvider>
      </LocalizationProvider>
    </MsalProvider>
  );
};

export default App;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  actionsButtonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    padding: '1rem 0',
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    width: 'fit-content',
    alignSelf: 'flex-end',
  },
  containerBox: { display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 },
});

export default useSx;

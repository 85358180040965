import React, { createContext, PropsWithChildren, useState } from 'react';
import { StudyListItemModel } from '@core/types';
import { ModalState } from '../types/ModalState';

export interface LandingProviderProps {
  selectedStudy: StudyListItemModel | undefined | null
  setSelectedStudy: React.Dispatch<React.SetStateAction<StudyListItemModel | undefined | null>>
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>
  modalState: ModalState
}

const defaultLandingContext: LandingProviderProps = {
  selectedStudy: null,
  setSelectedStudy: () => {},
  setModalState: () => {},
  modalState: null
}
export const LandingContext = createContext<LandingProviderProps>(defaultLandingContext);

const LandingProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [selectedStudy, setSelectedStudy] = useState<StudyListItemModel | undefined | null>(null)
  const [modalState, setModalState] = useState<ModalState>(null)

  const value = {
    selectedStudy,
    setSelectedStudy,
    modalState,
    setModalState
  }

  return (
    <LandingContext.Provider value={value}>{children}</LandingContext.Provider>
  )
};

export default LandingProvider;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateOrganizationSettings } from '@core/api/updateOrganzationSettings';
import { ORGANIZATION_SETTINGS_QUERY_KEY } from './useOrganizationSettings';

const useUpdateOrganizationSettings = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateOrganizationSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION_SETTINGS_QUERY_KEY]);
    },
    onError: (error) => {
      console.error('Error al actualizar los ajustes de la organización:', error);
    }
  });

  return mutation;
};

export default useUpdateOrganizationSettings;

import React, { useState } from 'react';
import useProfile from '@core/hooks/useProfile';
import useAddStudyComment from 'app/features/study/hooks/useAddStudyComment';
import useDeleteStudyComment from 'app/features/study/hooks/useDeleteStudyCommment';
import useUpdateStudyComment from 'app/features/study/hooks/useUpdateStudyComment';
import { Comment } from 'app/features/study/types/Comment';

export const initialState = {
  commentId: null,
  studyKey: null,
  commenter: '',
  content: '',
  createdBy: null,
} as Comment;

const useCommentState = () => {
  const { deleteStudyCommentAsync, isDeleting } = useDeleteStudyComment();
  const { isAdding } = useAddStudyComment();
  const { isUpdating } = useUpdateStudyComment();
  const [comment, setComment] = useState({ ...initialState });
  const [page, setPage] = useState('1');
  const { userUid, fullName } = useProfile();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return {
    deleteStudyCommentAsync,
    comment,
    setComment,
    setPage,
    page,
    userUid,
    fullName,
    showDeleteDialog,
    setShowDeleteDialog,
    isDeleting,
    isAdding,
    isUpdating,
  };
};

export default useCommentState;

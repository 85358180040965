import { TemplateFile, TemplateFolder } from '@core/types';

const navigateToFoldersBasedOnFolderPath = (
  initialFolder: {
    [key: string]: TemplateFile | TemplateFolder;
  },
  folderPath: number[],
) => {
  let FOLDER = initialFolder;
  const folderIds = [...folderPath];
  while (folderIds.length > 0) {
    const folderId = folderIds.shift();
    const foundFolder = FOLDER[`folder-${folderId}`];

    if (foundFolder && foundFolder.subItems) {
      FOLDER = foundFolder.subItems;
    } else {
      break;
    }
  }

  return FOLDER;
};

export default navigateToFoldersBasedOnFolderPath;

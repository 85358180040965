import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  chip: {
    backgroundColor: (theme) => theme.palette.shade?.[400],
    ':hover': {
        backgroundColor: (theme) => theme.palette.shade?.[500]
    }
  },
  chipActive: {
    backgroundColor: (theme) => theme.palette.shade?.[200],
    ':hover': {
        backgroundColor: (theme) => theme.palette.shade?.[200]
    }
  }
});

export default useSx;

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SectionHeaderProps } from './SectionHeaderProps';
import useSx from './sx';

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title = 'Default Title',
  IconComponent,
  children,
}) => {
  const sx = useSx();

  return (
    <Box sx={sx.root}>
      <Box display="flex" alignItems="center" gap="10px" marginBottom="0.5rem">
        {IconComponent && <IconComponent className="custom-icon" />}
        <Typography fontSize="1rem">{title}</Typography>
      </Box>
      <Typography fontWeight="light">{children}</Typography>
    </Box>
  );
};

export default SectionHeader;

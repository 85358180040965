import React, { useState } from 'react';
import useSx from './sx';

const useSystemCustomizationState = () => {
    const sx = useSx();
    const [showModal, setShowModal] = useState(false);

    return  {
      sx,
      showModal, 
      setShowModal
    }
}

export default useSystemCustomizationState
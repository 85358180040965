import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    display: 'flex',
    mt: 5,
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'flex-start' },
    flexWrap: 'wrap',
    width: '100%',
    gap: '1rem',
  },
});

export default useSx;

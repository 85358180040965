import React, { useState } from 'react';
import useSnackbar from '@core/hooks/useSnackbar';
import useSx from './sx';

const useShareByEmailState = () => {
  const showSnackbar = useSnackbar();
  const sx = useSx();
  const [shareMethod, setShareMethod] = useState<'email' | 'whatsapp' | ''>('email');
  const [countryCode, setCountryCode] = useState<string>('52');

  return {
    shareMethod,
    setShareMethod,
    countryCode,
    setCountryCode,
    showSnackbar,
    sx,
  };
};

export default useShareByEmailState;

import { useQuery } from '@tanstack/react-query';
import getPermissions from '../api/getPermissions';
import { Filters } from '../types/Filters';

export const GET_PERMISSIONS_QUERY_KEY = 'permissions';

const useGetPermissions = (filters: Filters) => {
  const { data: permissions, ...rest } = useQuery({
    queryKey: [GET_PERMISSIONS_QUERY_KEY, filters],
    queryFn: () => getPermissions(filters),
  });

  return { permissions, ...rest };
};

export default useGetPermissions;

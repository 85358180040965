const createProfessionalInfoParagraphs = (professionalInfo: string) => {
  const professionalInfoItems = professionalInfo ? professionalInfo?.split('\r\n') : [];
  const paragraphs = professionalInfoItems.map((item) => {
    const professionalInfoParagraph = document.createElement('p');
    professionalInfoParagraph.innerText = item;
    professionalInfoParagraph.style.fontSize = '0.8rem';
    professionalInfoParagraph.style.margin = '0';
    return professionalInfoParagraph;
  });

  return paragraphs;
};

export default createProfessionalInfoParagraphs;

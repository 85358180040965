import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getStudyComments } from '../api/getStudyComments';

export const GET_STUDY_COMMENTS_QUERY_KEY = 'StudyComments';

const useGetStudyComments = (
  {
    studyInstanceUid,
    ...filters
  }: { studyInstanceUid: string; page?: string; pageSize?: string; sortOrder?: string },
  enabled: boolean,
) => {
  const { data: studyComments, ...rest } = useQuery({
    queryKey: [GET_STUDY_COMMENTS_QUERY_KEY, filters],
    queryFn: () => getStudyComments(studyInstanceUid, filters),
    enabled,
  });

  return {
    studyComments,
    ...rest,
  };
};

export default useGetStudyComments;

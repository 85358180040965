import DropdownWithLabel from '@components/DrodpdownWithLabel';
import InputWithLabel from '@components/InputWithLabel';
import { RoleName, roleToDisplayName } from '@core/types';
import { genderNameMap } from '@core/types/Gender';
import { Field, FieldKeys } from './FormProps';

export const genderOptions = [
  {
    value: 'Unknown',
    label: genderNameMap.get('Unknown'),
  },
  {
    value: 'Male',
    label: genderNameMap.get('Male'),
  },
  {
    value: 'Female',
    label: genderNameMap.get('Female'),
  },
];

export const roleOptions = [
  { value: RoleName.Referrer, label: roleToDisplayName(RoleName.Referrer) },
  { value: RoleName.Radiologist, label: roleToDisplayName(RoleName.Radiologist) },
  { value: RoleName.Technician, label: roleToDisplayName(RoleName.Technician) },
];

const fields: Record<FieldKeys, Field> = {
  givenName: {
    label: 'Nombre',
    component: InputWithLabel,
    required: true,
  },
  surname: {
    label: 'Apellido',
    component: InputWithLabel,
    required: true,
  },
  emailAddress: {
    label: 'Correo',
    component: InputWithLabel,
    required: true,
  },
  gender: {
    label: 'Género',
    component: DropdownWithLabel,
    required: false,
    options: genderOptions,
  },
  phoneNumber: {
    label: 'Celular',
    component: InputWithLabel,
    required: false,
  },
  role: {
    label: 'Tipo de usuario',
    component: DropdownWithLabel,
    required: true,
    options: roleOptions,
  },
};

export default fields;

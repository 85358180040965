import dayjs from 'dayjs';
import calculateTimeBetweenDates from './calculateTimeBetweenDates';

function calculateElapsedTimeBetweenTwoDates(
  startDateString: string,
  endDateString: string,
): string {
  const startDate = dayjs(startDateString);
  const endDate = dayjs(endDateString);
  const { diffInMonths, diffInWeeks, diffInDays, diffInHours, diffInMinutes } =
    calculateTimeBetweenDates(startDate, endDate);

  if (diffInMonths >= 1) {
    return diffInMonths === 1 ? '1 mes' : `${diffInMonths} meses`;
  } else if (diffInWeeks >= 1) {
    return diffInWeeks === 1 ? '1 semana' : `${diffInWeeks} semanas`;
  } else if (diffInDays >= 1) {
    return diffInDays === 1 ? '1 día' : `${diffInDays} días`;
  } else if (diffInHours >= 1) {
    return diffInHours === 1 ? '1 hora' : `${diffInHours} horas`;
  } else if (diffInMinutes >= 1) {
    return diffInMinutes === 1 ? '1 minuto' : `${diffInMinutes} minutos`;
  } else {
    return 'Unos segundos';
  }
}

export default calculateElapsedTimeBetweenTwoDates;

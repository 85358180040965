import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        backgroundColor: (theme) => theme.palette.shade?.[300],
        color: (theme) => theme.palette.secondary.contrastText
    },
    checkIcon: {color: (theme) => theme.palette.success.main}
});

export default useSx;

import React from 'react';
import { Box, Card, CardMedia, useTheme } from '@mui/material';
import { FilesDisplayProps } from './FilesDisplayProps';

const FilesDisplay = ({ solicitations, onSelect, selectedSolicitation }: FilesDisplayProps) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      marginTop={'1rem'}
      width={'100%'}
      flexWrap="wrap"
    >
      {solicitations?.map((solicitation, index) => (
        <Box key={solicitation.blobUri} marginRight="1.5rem" marginBottom="1rem">
          <Card
            onClick={() => onSelect(solicitation)}
            sx={{
              border:
                solicitation.blobUri === selectedSolicitation?.blobUri
                  ? `2px solid ${theme.palette.primary.main}`
                  : 'none',
              cursor: 'pointer',
              width: 80,
              height: 80,
            }}
          >
            <CardMedia
              component="img"
              image={solicitation.blobUri}
              alt={`Solicitation ${index + 1}`}
              sx={{ width: 80, height: 80 }}
            />
          </Card>
        </Box>
      ))}
    </Box>
  );
};

export default FilesDisplay;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Box from '@mui/material/Box';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import ActionsProps from './ActionsProps';
import useSx from './sx';

const Actions = ({ resetForm, onDelete, isLoading }: ActionsProps) => {
  const navigate = useNavigate();
  const sx = useSx();

  const onCancel = () => {
    resetForm();
    navigate('/settings/users');
  };

  return (
    <Box sx={sx.root}>
      <If condition={!!onDelete}>
        <ActionButton
          isLoading={isLoading}
          color="error"
          onClick={onDelete}
          variant="contained"
          startIcon={<DeleteOutlineOutlinedIcon />}
          text="Remover de la organización"
          sx={{ marginRight: 'auto' }}
        />
      </If>
      <ActionButton
        onClick={onCancel}
        color="secondary"
        variant="outlined"
        text="Cancelar"
        sx={{ marginLeft: 'auto' }}
      />
      <ActionButton isLoading={isLoading} type="submit" variant="contained" text="Guardar" />
    </Box>
  );
};

export default Actions;

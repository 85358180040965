import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    margin: '2rem auto',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    padding: { xs: '0 1rem', md: '0' },
  },
});

export default useSx;

import React from 'react';
import { Document, Page } from 'react-pdf';
import PdfDisplayProps from './PdfDisplayProps';

export default function PdfDisplay({ pdf, totalPages, zoom }: PdfDisplayProps) {
  function calculatePDFWidth() {
    return window.innerWidth < 650 ? window.innerWidth - 50 : 650;
  }

  return (
    <Document file={pdf}>
      {Array.from(new Array(totalPages), (el, index) => (
        <Page
          canvasBackground="white"
          width={(calculatePDFWidth() * zoom) / 100}
          key={`page_${index + 1}`}
          pageNumber={index + 1}
        />
      ))}
    </Document>
  );
}

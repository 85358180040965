import React, { useState } from 'react';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

const useRecordAudioActionState = () => {
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [transcript, setTranscript] = useState<string>('');
  const [temporaryTranscript, setTemporaryTranscript] = useState<string>('');
  const [speechRecognizer, setSpeechRecognizer] = useState<SpeechSDK.SpeechRecognizer | null>(null);
  const [warningDialogOpen, setWarningDialogOpen] = useState<boolean>(false);

  return {
    isRecording,
    setIsRecording,
    transcript,
    setTranscript,
    temporaryTranscript,
    setTemporaryTranscript,
    speechRecognizer,
    setSpeechRecognizer,
    warningDialogOpen,
    setWarningDialogOpen,
  };
};

export default useRecordAudioActionState;

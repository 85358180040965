import { Caret } from '../components/Form/TextEditor/TextEditor';

function caretIsAtEndOfPage(editorDiv: HTMLElement, caretPositionProp: Caret | null) {
  if (caretPositionProp === null) {
    return false;
  }

  if (caretPositionProp.nodesPath === null || caretPositionProp.nodesPath.length === 0) {
    return false;
  }

  let currentNode = editorDiv;
  for (const childIndex of caretPositionProp.nodesPath) {
    if (childIndex >= currentNode.childNodes.length) {
      return null;
    }
    if (childIndex !== currentNode.childNodes.length - 1) {
      const restOfNodes = Array.from(
        { length: currentNode.childNodes.length - childIndex },
        (_, i) => i + childIndex,
        // eslint-disable-next-line
      ).map((index) => currentNode.childNodes[index]);
      // remove empty nodes
      const nonEmptyNodes = restOfNodes.filter((node) => (node as HTMLElement).offsetHeight !== 0);
      return nonEmptyNodes.length === 1;
    }
    currentNode = currentNode.childNodes[childIndex] as HTMLElement;
  }
  return true;
}
export default caretIsAtEndOfPage;

import React from 'react'
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import If from '@components/If';
import AssignedTechnicianProps from './AssignedTechnicianProps';
import useSx from './sx';

const AssignedTechnician = ({assignedTechnician}: AssignedTechnicianProps) => {
    const sx = useSx()

    const userLastName = assignedTechnician?.givenName ? ` ${assignedTechnician.surname}` : ''
    const fullName = `${assignedTechnician?.givenName}${userLastName}`

    return (
        <Box display="flex" alignItems="center" gap="5px" flexWrap="wrap">
            <If condition={!!assignedTechnician}>
                    <Chip 
                        key={assignedTechnician?.userUid} 
                        label={fullName}
                        sx={sx.chip}
                    />
            </If>
        </Box>
    )
}

export default AssignedTechnician
import React, {  DragEvent, FC, useEffect, forwardRef, useImperativeHandle } from 'react';
import { PhotoCamera } from '@mui/icons-material';
import { Box, Typography, Avatar } from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import { FileInputProps } from './FileInputProps';
import useFileInputState from './useFileInputState';

const FileInput = forwardRef(({ onFileSelect, accept, customFile, customName, isDisabled }: FileInputProps, ref) => {
  const { sx, fileInputRef, preview, setPreview, fileName, setFileName } = useFileInputState();

  useEffect(() => {
    if (customFile) {
      setPreview(customFile);
    }
  }, [customFile]);

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFileSelect(event);

    const file = event.target.files && event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
        setFileName(file.name);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
      setFileName(null);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault(); 
  };
  
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const file = files.length > 0 ? files[0] : null;
  
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
        setFileName(file.name);
      };
      reader.readAsDataURL(file);
  
      onFileSelect(file);
    }
  };  

  const clearFileInput = () => {
    setPreview(null);
    setFileName(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useImperativeHandle(ref, () => ({
    clearFileInput
  }));

  return (
    <div>
      <Box sx={sx.input}>
        <input
          accept={accept ? accept : "image/*"}
          id="contained-button-file"
          multiple={false}
          type="file"
          onChange={handleFileInput}
          ref={fileInputRef}
        />
      </Box>
      <Box 
        sx={sx.previewContainer}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <If condition={!!preview}>
          <Avatar src={preview ?? undefined} alt="Selected Image Preview" sx={sx.imagePreview} />
        </If>
        <If condition={!!fileName && !!preview} fallback={<Typography variant="subtitle1">{customName}</Typography>}>
          <Typography variant="subtitle1">{fileName}</Typography>
        </If>
        <Box sx={sx.buttonContainer}>
          <label>
            <ActionButton
              color="primary"
              variant="contained"
              text="Subir archivo"
              icon={<PhotoCamera />}
              sx={sx.button}
              onClick={handleButtonClick}
              disabled={!!isDisabled}
            />
          </label>
        </Box>
      </Box>
    </div>
  );
});

FileInput.displayName = 'FileInput';

export default FileInput;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    '.title': {
      color: 'text.primary'
    },
    '.subtitle': {
      color: 'text.secondary'
    },
    '.MuiSvgIcon-root': {
      color: (theme) => theme.palette.mode === 'dark' ? '#fff' : 'text.primary'
    }
  },
  container: {
    paddingBottom: '2rem'
  }
});

export default useSx;

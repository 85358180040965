import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { Formik, Form, FormikState } from 'formik';
import * as Yup from 'yup';
import { Input } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import CountryCodeDropdown from '@components/CountryCodeDropdown';
import DatePickerWithLabel from '@components/DatePickerWithLabel';
import DropdownWithLabel from '@components/DrodpdownWithLabel';
import If from '@components/If';
import ViewerTextField from '@components/ViewerTextField';
import RegexPatterns from '@core/constants/RegexPatterns';
import useConfirm from '@core/hooks/useConfirm';
import useOrganizationInfo from '@core/hooks/useOrganizationInfo';
import { RoleName } from '@core/types';
import updateStudy from 'app/features/study/api/updateStudy';
import useShareByEmailOrPhone from 'app/features/study/hooks/useShareByEmailOrPhone';
import Actions from '../Actions';
import { StudyInfoProps } from './StudyInfoProps';
import useStudyInfoState from './useStudyInfoState';

function formatDate(studyDate?: string) {
  if (!studyDate || studyDate.length === 0) {
    return 'No Disponible';
  }
  return new Date(studyDate).toLocaleString('es-MX');
}

const validationSchema = Yup.object().shape({
  patientsAge: Yup.string(),
  patientsGivenName: Yup.string(),
  patientsSurname: Yup.string(),
  patientsGender: Yup.string().oneOf(['M', 'F', 'O'], 'El género debe ser M, F u O').max(1),
  patientsEmail: Yup.string(),
  patientsPhoneNumber: Yup.string(),
  studyDescription: Yup.string(),
  referringPhysiciansName: Yup.string(),
});

const StudyInfo = ({ study, role, refetch, update, isUpdating, onClose }: StudyInfoProps) => {
  const {
    studyDate,
    patientsFirstName,
    patientsLastName,
    patientsBirthDate,
    patientsAge,
    patientsGender,
    patientsEmail,
    patientsPhoneNumber,
    studyDescription,
    referringPhysiciansName,
    studyInstanceUid,
    shareUrl,
  } = study;
  const { showSnackbar, sx } = useStudyInfoState();
  const { share, shareWhatsapp, isSharing } = useShareByEmailOrPhone(study.studyInstanceUid);
  const { organizationInfo } = useOrganizationInfo();

  const initialValues = useMemo(() => {
    const phoneNumber = patientsPhoneNumber;

    const matches = phoneNumber?.match(RegexPatterns.PHONE_NUMBER) || null;
    return {
      patientsBirthDate: dayjs(patientsBirthDate) || null,
      patientsGivenName: patientsFirstName || '',
      patientsSurname: patientsLastName || '',
      patientsAge: patientsAge || '',
      patientsGender: patientsGender || '',
      patientsEmail: patientsEmail || '',
      patientsPhoneNumber: matches?.[2] || '',
      studyDescription: studyDescription || '',
      referringPhysiciansName: referringPhysiciansName || '',
      patientsCountryCodePhoneNumber: matches?.[1] || '',
    };
  }, [
    patientsBirthDate,
    patientsFirstName,
    patientsLastName,
    patientsAge,
    patientsGender,
    patientsEmail,
    patientsPhoneNumber,
    studyDescription,
    referringPhysiciansName,
  ]);

  const { isConfirmed } = useConfirm();

  const handleSharingByWhatsAppAndEmail = async (
    phoneNumber: string,
    email: string,
    name: string,
  ) => {
    const confirmed = await isConfirmed({
      title: '¿Deseas compartir el estudio al email y número registrado?',
      message: `Has actualizado el número de teléfono a +${phoneNumber} y email a ${email}. ¿Te gustaría compartir el estudio a este número y correo electrónico?`,
      type: 'info',
    });
    if (confirmed) {
      await share({ emailOrPhone: email, name, language: 'es_MX' });
      await shareWhatsapp({ emailOrPhone: phoneNumber, language: '', name });
    }

    return confirmed;
  };

  const handleShareByEmail = async (email: string, name: string) => {
    const confirmed = await isConfirmed({
      title: '¿Deseas compartir el estudio por correo electrónico?',
      message: `Has actualizado el correo electrónico a ${email}. ¿Te gustaría compartir el estudio por correo electrónico?`,
      type: 'info',
    });
    if (confirmed) {
      await share({ emailOrPhone: email, name, language: 'es_MX' });
    }
    return confirmed;
  };

  const handleShareByPhone = async (phoneNumber: string, name: string) => {
    const confirmed = await isConfirmed({
      title: '¿Deseas compartir el estudio al número registrado?',
      message: `Has actualizado el número de teléfono a +${phoneNumber}. ¿Te gustaría compartir el estudio a este número?`,
      type: 'info',
    });
    if (confirmed) {
      await shareWhatsapp({
        emailOrPhone: phoneNumber,
        language: 'es_MX',
        name,
        organizationPhone: organizationInfo.organizationPhone,
        organizationName: organizationInfo.organizationName,
        shareLink: shareUrl,
      });
    }
    return confirmed;
  };

  const handleShareChangedEmailAndPhoneNumber = async (
    phoneNumber: string,
    email: string,
    name: string,
  ) => {
    const hasChangedPhoneNumber =
      phoneNumber !== patientsPhoneNumber && RegexPatterns.PHONE_NUMBER.test(phoneNumber);
    const hasChangedEmail = email !== initialValues.patientsEmail && email !== '';
    const formattedPhoneNumber = phoneNumber.replace(/\((\d+)\)(\d+)/, '$1$2');

    if (hasChangedPhoneNumber && hasChangedEmail) {
      return await handleSharingByWhatsAppAndEmail(formattedPhoneNumber, email, name);
    } else if (hasChangedEmail) {
      return await handleShareByEmail(email, name);
    } else if (hasChangedPhoneNumber) {
      return await handleShareByPhone(formattedPhoneNumber, name);
    }
  };

  const onSubmit = async (propValues: typeof initialValues) => {
    const values = { ...propValues };
    values.patientsPhoneNumber = `(${values.patientsCountryCodePhoneNumber})${values.patientsPhoneNumber}`;
    const patientsBirthDateFormatted =
      values.patientsBirthDate && values.patientsBirthDate.isValid()
        ? values.patientsBirthDate.toISOString()
        : null;
    const fullName = values.patientsGivenName + ' ' + values.patientsSurname;

    const confirmed = await handleShareChangedEmailAndPhoneNumber(
      values.patientsPhoneNumber,
      values.patientsEmail,
      fullName,
    );

    let successMessage = 'La información del estudio ha sido guardada';

    if (confirmed) {
      successMessage =
        'La información del estudio ha sido guardada exitosamente. Además, se ha compartido a los medios confirmados.';
    }

    const payload = { ...values, studyInstanceUid };
    // Evodicom Service
    const response = await update({ ...payload, patientsBirthDate: patientsBirthDateFormatted });

    // Dicom Service
    await updateStudy(studyInstanceUid, payload);

    refetch();
    if (response.error) {
      showSnackbar({
        message: 'La información no ha sido guardada',
        title: 'Ocurrió un error',
        type: 'error',
      });
    } else {
      showSnackbar({
        message: successMessage,
        title: 'Guardado con éxito',
        type: 'success',
      });
    }
  };

  const handleCancel = (
    resetForm: (
      nextState?:
        | Partial<
            FormikState<{
              patientsBirthDate: dayjs.Dayjs;
              patientsAge: string;
              patientsGender: string;
              patientsEmail: string;
              patientsGivenName: string;
              patientsSurname: string;
              patientsPhoneNumber: string;
              studyDescription: string;
              referringPhysiciansName: string;
              patientsCountryCodePhoneNumber: string;
            }>
          >
        | undefined,
    ) => void,
  ) => {
    resetForm();
    if (onClose) onClose();
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        handleSubmit,
        resetForm,
        setFieldValue,
      }) => (
        <Form>
          <Stack sx={sx.root}>
            <ViewerTextField
              className="viewer-textfield"
              label="Fecha"
              value={formatDate(studyDate)}
              readOnly
            />
            <ViewerTextField
              name="patientsGivenName"
              onChange={handleChange}
              onBlur={handleBlur}
              className="viewer-textfield"
              label="Nombre del paciente"
              value={values.patientsGivenName}
            />
            <ViewerTextField
              name="patientsSurname"
              onChange={handleChange}
              onBlur={handleBlur}
              className="viewer-textfield"
              label="Apellido del paciente"
              value={values.patientsSurname}
            />
            <ViewerTextField
              className="viewer-textfield"
              label="Edad"
              name="patientsAge"
              value={values.patientsAge}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.patientsAge && Boolean(errors.patientsAge)}
              helperText={touched.patientsAge && errors.patientsAge}
              readOnly={
                role !== RoleName.Radiologist &&
                role !== RoleName.Technician &&
                role !== RoleName.System
              }
            />
            <DatePickerWithLabel
              label="Fecha de nacimiento"
              value={values.patientsBirthDate}
              onChange={(value) => {
                setFieldValue('patientsBirthDate', value);
              }}
            />
            <Box className="datepicker-container">
              <InputLabel>Número de teléfono</InputLabel>
              <Box className="phone-number">
                <CountryCodeDropdown
                  name="patientsCountryCodePhoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{ width: '15%' }}
                  label="Codígo"
                  value={values.patientsCountryCodePhoneNumber}
                />
                <Input
                  name="patientsPhoneNumber"
                  placeholder="Numero"
                  value={values.patientsPhoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{ flex: 1 }}
                />
              </Box>
            </Box>
            <DropdownWithLabel
              label="Género"
              className="gender-dropdown"
              name="patientsGender"
              value={values.patientsGender}
              onChange={handleChange}
              error={touched.patientsGender && Boolean(errors.patientsGender)}
              inputProps={{ readOnly: role === RoleName.Referrer || role === RoleName.Patient }}
              options={[
                { label: 'Sin especificar', value: 'O' },
                { label: 'Femenino', value: 'F' },
                { label: 'Masculino', value: 'M' },
              ]}
              variant="outlined"
            />
            <ViewerTextField
              className="viewer-textfield"
              label="Email"
              name="patientsEmail"
              value={values.patientsEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.patientsEmail && Boolean(errors.patientsEmail)}
              helperText={touched.patientsEmail && errors.patientsEmail}
              readOnly={role === RoleName.Referrer || role === RoleName.Patient}
            />
            <ViewerTextField
              className="viewer-textfield"
              label="Descripción del Estudio"
              name="studyDescription"
              value={values.studyDescription}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.studyDescription && Boolean(errors.studyDescription)}
              helperText={touched.studyDescription && errors.studyDescription}
              readOnly={role === RoleName.Referrer || role === RoleName.Patient}
            />
            <ViewerTextField
              className="viewer-textfield"
              label="Doctor:"
              name="referringPhysiciansName"
              value={values.referringPhysiciansName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.referringPhysiciansName && Boolean(errors.referringPhysiciansName)}
              helperText={touched.referringPhysiciansName && errors.referringPhysiciansName}
              readOnly={role === RoleName.Referrer || role === RoleName.Patient}
            />
            <If
              condition={
                role === RoleName.Radiologist ||
                role === RoleName.Technician ||
                role === RoleName.System
              }
            >
              <Actions
                onAccept={handleSubmit}
                onCancel={() => handleCancel(resetForm)}
                isLoading={isUpdating || isSharing}
              />
            </If>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default StudyInfo;

import React, { createContext, PropsWithChildren, useState, useMemo } from 'react';
import { AlertColor } from '@mui/material/Alert';
import CustomSnackbar from '@components/CustomSnackbar';

export type SnackbarType = AlertColor | 'default';

export interface ISnackbarContext {
  setOpen: (open: boolean) => void;
  setMessage: (message: string) => void;
  setType: (type: SnackbarType) => void;
  setTitle: (title: string) => void;
  setAutoHideDuration: (duration: number) => void;
}

const defaultSnackbarContext: ISnackbarContext = {} as any;
export const SnackbarContext = createContext<ISnackbarContext>(defaultSnackbarContext);

const SnackbarProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<SnackbarType>('default');
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [autoHideDuration, setAutoHideDuration] = useState(5000);

  const handleClose = () => {
    setOpen(false);
  };

  const value = useMemo(
    () => ({ setOpen, setMessage, setType, setTitle, setAutoHideDuration }),
    [setOpen, setMessage, setType, setTitle, setAutoHideDuration],
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {type === 'default' ? (
        <CustomSnackbar
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={handleClose}
          message={message}
          title={''}
          type={'info'}
        />
      ) : (
        <CustomSnackbar
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={handleClose}
          message={message}
          type={type}
          title={title}
        />
      )}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;

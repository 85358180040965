import React from 'react';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import useDeleteOrganizationTemplates from '@core/hooks/useDeleteOrganizationTemplates';
import { OrganizationTemplateModel } from '@core/types/OrganizationTemplate';

const DeleteDialog = ({
  onClose,
  isOpen,
  template,
}: {
  onClose: VoidFunction;
  isOpen: boolean;
  template: OrganizationTemplateModel;
}) => {
  const { deleteOrganizationTemplateAsync, isLoading: isDeleting } =
    useDeleteOrganizationTemplates();

  const onConfirm = () => {
    deleteOrganizationTemplateAsync(template.templateId);
    onClose();
  };

  const descriptionWithTitle = template.title !== '' ? ` con el título: ${template.title}` : '';
  const description = `Se eliminará el membrete seleccionado${descriptionWithTitle}. ¿Deseas continuar?`;

  return (
    <ActionDialog
      onConfirm={onConfirm}
      onClose={onClose}
      message={description}
      isLoading={isDeleting}
      title="Eliminar documento"
      open={isOpen}
      onCancel={onClose}
      type="delete"
    />
  );
};

export default DeleteDialog;

import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
    root: {        
        borderBottom: 1,
        borderColor: 'divider',
        '.MuiTabs-flexContainer':{
            ...expandDisplayFlex({a: 'center'}),
        },
        width: '100%',
        marginY: '1rem',
        '.user-tab': {
            textTransform: 'none'
        },
        '.MuiTabs-scroller': {
            overflow: 'auto !important'
        }
    }
});

export default useSx;

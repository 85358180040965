const toolbarModule = (generatedReportIds = [], reportsCreatedByUser = []) => ({
  defaultContext: 'VIEWER', // we depend on cornerstone which is on by default
  definitions: [
    {
      id: 'Detail',
      label: 'Detalle',
      icon: 'detail',
      isevodicom: true,
      display: ({ role, isAnon }) => {
        return role !== 'Referrer' && role !== 'Patient' && !isAnon;
      },
      buttons: [
        {
          id: 'referrer',
          label: 'Referente',
          icon: 'referrer',
          type: 'command',
          commandName: 'fireEvent',
          commandOptions: { commandName: 'referrer' },
          isevodicom: true,
          display: ({ isAnon, role }) => role !== 'Patient' && !isAnon,
        },
        {
          id: 'radiologist',
          label: 'Radiólogo',
          icon: 'radiologist',
          type: 'command',
          commandName: 'fireEvent',
          commandOptions: { commandName: 'radiologist' },
          isevodicom: true,
          display: ({ isAnon, role }) => role !== 'Patient' && !isAnon,
        },
        {
          id: 'technician',
          label: 'Técnico',
          icon: 'technician',
          type: 'command',
          commandName: 'fireEvent',
          commandOptions: { commandName: 'technician' },
          isevodicom: true,
          display: ({ isAnon, role }) => role !== 'Patient' && !isAnon,
        },
        {
          id: 'urgency',
          label: 'Prioridad',
          icon: 'priority',
          type: 'command',
          commandName: 'fireEvent',
          commandOptions: { commandName: 'urgency' },
          isevodicom: true,
          display: ({ isAnon, role }) => role !== 'Patient' && !isAnon,
        },
        {
          id: 'notes',
          label: 'Comentarios',
          icon: 'note',
          type: 'command',
          commandName: 'fireEvent',
          commandOptions: { commandName: 'comments' },
          isevodicom: true,
          display: ({ isAnon, role }) => role !== 'Patient' && !isAnon,
        },
        {
          id: 'tags',
          label: 'Etiquetas',
          icon: 'tags',
          type: 'command',
          commandName: 'fireEvent',
          commandOptions: { commandName: 'tags' },
          isevodicom: true,
          display: ({ isAnon, role }) => role !== 'Patient' && !isAnon,
        },
        {
          id: 'upload-request',
          label: 'Solicitud',
          icon: 'solicitation',
          //
          type: 'command',
          commandName: 'fireEvent',
          commandOptions: { commandName: 'upload-request' },
          isevodicom: true,
          display: ({ isAnon, role }) => role !== 'Patient' && !isAnon,
        },
        {
          id: 'metrics',
          label: 'Métricas',
          icon: 'monitoring',
          //
          type: 'command',
          commandName: 'fireEvent',
          commandOptions: { commandName: 'metrics' },
          isevodicom: true,
          display: ({ isAnon, role }) => role !== 'Patient' && !isAnon,
        },
      ],
    },
    {
      id: 'Reports',
      label: 'Reporte',
      icon: 'inline-edit',
      isevodicom: true,
      display: ({ role, isAnon }) => {
        return role !== 'Referrer' && role !== 'Patient' && !isAnon;
      },
      buttons: [
        {
          id: 'edit-report',
          label: 'Editar Reporte',
          icon: 'inline-edit',
          type: 'command',
          commandName: 'fireEvent',
          commandOptions: { commandName: 'edit-report' },
          isevodicom: true,
          display: ({ studies, activeViewport }) => {
            if (
              !studies ||
              studies.length === 0 ||
              !activeViewport ||
              !activeViewport.SeriesInstanceUID ||
              activeViewport.Modality !== 'DOC'
            ) {
              return false;
            }

            const activeSeriesId = activeViewport.SeriesInstanceUID;
            const study = studies[0];
            const activeSeries = study.series.filter(
              (s) => s.SeriesInstanceUID === activeSeriesId,
            )[0];
            if (!activeSeries) {
              return false;
            }

            const sopInstanceId = activeViewport.SOPInstanceUID;
            // Report isent attached and is created by user
            return (
              generatedReportIds.includes(sopInstanceId) &&
              reportsCreatedByUser.includes(sopInstanceId)
            );
          },
        },
        {
          id: 'report',
          label: 'Nueva pestaña',
          icon: 'window',
          type: 'command',
          commandName: 'fireEvent',
          commandOptions: { commandName: 'report' },
          isevodicom: true,
          display: ({ isAnon, role }) => (role === 'Radiologist' || role === 'System') && !isAnon,
        },
        {
          id: 'side-report',
          label: 'Visualización simultanea',
          icon: 'side-window',
          type: 'command',
          commandName: 'fireEvent',
          commandOptions: { commandName: 'side-report' },
          isevodicom: true,
          display: ({ isAnon, role }) => (role === 'Radiologist' || role === 'System') && !isAnon,
        },
        {
          id: 'upload',
          label: 'Adjuntar',
          icon: 'attach-file',
          //
          type: 'command',
          commandName: 'fireEvent',
          commandOptions: { commandName: 'upload' },
          isevodicom: true,
          display: ({ isAnon, role }) => role !== 'Patient' && !isAnon,
        },
      ],
    },
    {
      id: 'printPhoto',
      label: 'Captura',
      icon: 'snapshot',
      type: 'command',
      commandName: 'printPhoto',
      commandOptions: { commandName: 'printPhoto' },
      isevodicom: true,
      display: ({ activeViewport }) => activeViewport && activeViewport.Modality !== 'DOC',
    },
    {
      id: 'info',
      label: 'Información',
      icon: 'fact-check',
      //
      type: 'command',
      commandName: 'fireEvent',
      commandOptions: { commandName: 'info' },
      isevodicom: true,
      display: ({ isAnon, role }) => role !== 'Referrer' && role !== 'Patient' && !isAnon,
    },
    {
      id: 'print',
      label: 'Imprimir',
      icon: 'print',
      isevodicom: true,
      display: ({ activeViewport }) => activeViewport && activeViewport.Modality === 'DOC',
      buttons: [
        {
          id: 'download-report',
          label: 'Descargar PDF',
          commandName: 'downloadReport',
          icon: 'download',
          type: 'command',
          isevodicom: true,
        },
        {
          id: 'print',
          label: 'Imprimir',
          commandName: 'printReport',
          icon: 'print',
          type: 'command',
          isevodicom: true,
        },
        {
          id: 'print-without-template',
          label: 'Imprimir sin templete',
          commandName: 'printReportWithoutTemplate',
          icon: 'print',
          isevodicom: true,
          type: 'command',
          display: ({ studies, activeViewport }) => {
            if (
              !studies ||
              studies.length === 0 ||
              !activeViewport ||
              !activeViewport.SeriesInstanceUID ||
              activeViewport.Modality !== 'DOC'
            ) {
              return false;
            }

            const activeSeriesId = activeViewport.SeriesInstanceUID;
            const study = studies[0];
            const activeSeries = study.series.filter(
              (s) => s.SeriesInstanceUID === activeSeriesId,
            )[0];
            if (!activeSeries) {
              return false;
            }

            const sopInstanceId = activeViewport.SOPInstanceUID;
            return (generatedReportIds ?? []).includes(sopInstanceId);
          },
        },
      ],
    },
    {
      id: 'share',
      label: 'Compartir',
      icon: 'share',
      //
      type: 'command',
      commandName: 'fireEvent',
      commandOptions: { commandName: 'share' },
      isevodicom: true,
      display: ({ isAnon, role }) => role !== 'Referrer' && role !== 'Patient' && !isAnon,
    },
    {
      id: 'hotkeys',
      label: 'Atajos',
      icon: 'keyboard',
      //
      type: 'command',
      commandName: 'fireEvent',
      commandOptions: { commandName: 'hotkeys' },
      isevodicom: true,
      display: ({ activeViewport, role }) =>
        role === 'Radiologist' && activeViewport && activeViewport.Modality !== 'DOC',
    },
  ],
});

export default toolbarModule;

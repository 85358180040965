import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useProfile from '@core/hooks/useProfile'
import { AuthContext } from '@core/providers/AuthProvider'
import { RoleName, UserItem } from '@core/types'
import capitalizeText from '@core/utils/capitalizeText'
import useSendNotification from '../add/hooks/useSendNotification'
import useFilters from '../hooks/useFilters'
import useUsers from '../hooks/useUsers'

export type UsersModalState = "PASSWORD" | "DELETE" | "PASSWORD_CONFIRM" | null

const useUsersState = () => {
    const { role } = useProfile()
    const navigate  = useNavigate()
    const [ filters ] = useFilters()
    const { users, pageCount, ...rest } = useUsers({
      enabled: true,
      filters
    })
    const { sendNotification, isLoading: isSendingNotification } = useSendNotification()
    const { organization } = useContext(AuthContext);

    const [selectedUser, setSelectedUser] = useState<UserItem | undefined>(undefined)
    const [modalState, setModalState] = useState<UsersModalState>(null)

    useEffect(() => {
        const capitalizedRole = capitalizeText(role)
        if(capitalizedRole === RoleName.Anonymous || capitalizedRole === RoleName.Referrer){
            navigate('/')
        }
    }, [role, navigate])


    return {
        users,
        pageCount,
        filters,
        selectedUser,
        setSelectedUser,
        modalState,
        setModalState,
        sendNotification, 
        isSendingNotification,
        organizationId: organization?.organizationId,
        ...rest
    }
}

export default useUsersState
import { Theme, alpha } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import SxDef from '@core/types/SxDef';

const useSx = (type: AlertColor): SxDef => {
  const getColor = (theme: Theme) => {
    switch (type) {
      case 'error':
        return theme.palette.error.main;
      case 'info':
        return theme.palette.info.main;
      case 'warning':
        return theme.palette.warning.main;
      case 'success':
        return theme.palette.success.main;
      default:
        return theme.palette.info.main;
    }
  };

  return {
    root: {
      '& .MuiDialog-paper': {
        backgroundColor: (theme) => theme.palette.shade?.[50],
        backgroundImage: 'none',
      },
      '.content': { textAlign: 'center' },
    },
    actionsButtonContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '100%',
      padding: '1rem 0',
    },
    dialogHeader: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontWeight: 600,
      textAlign: 'center',
      marginBottom: '1rem',
    },
    closeButton: {
      width: 'fit-content',
      alignSelf: 'flex-end',
    },
    iconContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconBox: {
      height: 60,
      width: 60,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      color: (theme) => getColor(theme),
      backgroundColor: (theme) => alpha(getColor(theme), 0.3),
      '.MuiSvgIcon-root': {
        fontSize: '3rem',
      },
    },
  };
};

export default useSx;

import React, { useState, useEffect, useRef } from 'react';
import useCurrentTheme from '@core/hooks/useCurrentTheme';
import { ThemeVariant } from '@core/types/Theme';
import useSx from './sx';

const useModeOptionsState = () => {
  const sx = useSx();

  return { sx };
};

export default useModeOptionsState;

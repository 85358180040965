import axios from 'axios';

const postTranslate = async (text: string) => {
  const endpoint = "https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&to=en&from=es";
  try {
    const response = await axios.post(endpoint, [{ Text: text }], {
      headers: {
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_TRANSLATOR_KEY || '',
        'Ocp-Apim-Subscription-Region': process.env.REACT_APP_TRANSLATOR_REGION || '',
        'Content-Type': 'application/json',
      },
    });
    return response.data[0].translations[0].text;
  } catch (error) {
    console.error('Error translating text:', error);
  }
};

export default postTranslate;

import { useQuery } from '@tanstack/react-query';
import getTags from '../api/getTags';

const QUERY_KEY = 'tagList';

const useTags = (q?: string) => {
  const { data: tagList, ...rest } = useQuery({
    queryKey: [QUERY_KEY, q],
    queryFn: () => getTags(q),
    enabled: !!q,
  });

  return { tagList, ...rest };
};

export default useTags;

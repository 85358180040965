/**
 * Type of all possible regions. Currently we only support region of Table
 */
// eslint-disable-next-line etc/no-const-enum
export const enum RegionType {
    /**
     * Region split by Table
     */
    Table,
}

import React, { createContext, useState, useMemo, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FullScreenLoader from '@components/FullScreenLoader';
import useDicomServiceToken from '@core/hooks/useDicomServiceToken';
import useGetUserPreferences from '@core/hooks/useGetUserPreferences';
import useOrganizationSettings from '@core/hooks/useOrganizationSettings';
import useProfile from '@core/hooks/useProfile';
import useSetUserSettings from '@core/hooks/useSetUserSettings';
import { ThemeType, IThemeContext, ThemeVariant } from '@core/types/Theme';
import getApiAccessToken from '@core/utils/getApiAccessToken';
import getProductAccessToken from '@core/utils/getProductAccessToken';
import { getThemeNames } from '@core/utils/getThemeNames';
import { evodicomLight, evodicomDark, customLight, customDark } from '@styles/themes';
import { AuthContext } from './AuthProvider';
import { useLoading } from './LoadingAppProvider';

// Getting available theme names
const availableThemes: ThemeType[] = getThemeNames();

// Default context values for the theme
const defaultThemeContext: IThemeContext = {
  currentTheme: 'evodicom',
  themeVariant: 'Dark',
  availableThemes: availableThemes,
  combinedThemeName: 'evodicomDark',
  switchTheme: () => {},
  toggleThemeVariant: () => {},
  customThemeData: { light: null, dark: null },
  saveThemeVariant: (variant: ThemeVariant) => Promise.resolve(undefined),
  storedThemeVariant: undefined,
  initialTheme: 'custom',
  organizationSettings: null,
  storedSettings: {},
  dicomServiceTokenSettings: null,
};

// Creating ThemeContext using React Context API
export const ThemeContext = createContext<IThemeContext>(defaultThemeContext);

// ThemeProvider component
const ThemeProvider: React.FC<{
  children: React.ReactNode;
  orgId?: string;
  studyHash?: string;
}> = ({ children, orgId, studyHash }) => {
  const { setLoadingApp } = useLoading();
  // State and custom hooks for theme management
  const {
    userPreferences,
    isLoading: isLoadingUserPreferences,
    refetch: refetchPreferences,
  } = useGetUserPreferences();
  const { userUid } = useProfile();
  const {
    organizationSettings,
    isLoading: orgSettingsLoading,
    refetch,
  } = useOrganizationSettings(orgId);
  const { dicomServiceTokenSettings } = useDicomServiceToken(orgId, studyHash);
  const { setUserSettings } = useSetUserSettings();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const osMode = prefersDarkMode ? 'Dark' : 'Light';
  const defaultMode = 'Dark';

  // Parsing stored user preferences and settings
  const storedSettings = userPreferences?.settings
    ? JSON.parse(userPreferences?.settings || '{}')
    : {};

  const storedTheme = (organizationSettings?.SelectedTheme || 'evodicom') as ThemeType;

  // State initialization
  const initialTheme = storedTheme ?? 'evodicom';
  const [storedThemeVariant, setStoredThemeVariant] = useState<ThemeVariant | undefined>(
    storedSettings?.theme,
  );

  const [currentTheme, setCurrentTheme] = useState<ThemeType>(initialTheme);
  const [themeVariant, setThemeVariant] = useState<ThemeVariant>(storedThemeVariant ?? defaultMode);
  const [selectedThemeVariant, setSelectedThemeVariant] = useState<ThemeVariant | undefined>(
    undefined,
  );
  const { organization } = useContext(AuthContext);
  const [storedVariant, setStoredVariant] = useState<ThemeVariant | undefined>(undefined);
  const [theme, setTheme] = useState(evodicomDark);
  const [customThemeData, setCustomThemeData] = useState({ light: null, dark: null });
  const [loading, setLoading] = useState(true);
  const [apiToken, setApiToken] = useState<string | null>(null);
  const [productApiToken, setProductApiToken] = useState<string | null>(null);
  const [firstLoadTheme, setFirstLoadTheme] = useState<boolean>(true);

  // Function to update the theme
  function updateTheme(
    themeName: string,
    variant: ThemeVariant,
    lightData: any,
    darkData: any,
    firstLoad = false,
  ) {
    // Handling custom theme logic
    const isCustomTheme = themeName === 'custom';
    const isOS = variant === 'OS';
    const themeData = variant === 'Light' || (isOS && osMode === 'Light') ? lightData : darkData;
    const newTheme =
      isCustomTheme && themeData
        ? variant === 'Light' || (isOS && osMode === 'Light')
          ? customLight(themeData)
          : customDark(themeData)
        : variant === 'Light' || (isOS && osMode === 'Light')
        ? evodicomLight
        : evodicomDark;
    setCustomThemeData({ light: lightData, dark: darkData });
    setTheme(newTheme);
    setLoadingApp(false);
  }

  // Function to fetch tokens for API access
  const fetchTokens = async () => {
    const accessToken = await getApiAccessToken();
    const productAccessToken = await getProductAccessToken();

    if (accessToken && productAccessToken) {
      setApiToken(accessToken);
      setProductApiToken(productAccessToken);
    }
  };

  // Function to switch themes
  const switchTheme = (newThemeName: ThemeType) => {
    setCurrentTheme(newThemeName);
    updateTheme(newThemeName, themeVariant, customThemeData.light, customThemeData.dark);
  };

  // Function to save the current theme variant
  const saveThemeVariant = async (variant: ThemeVariant) => {
    const newTheme = variant;
    const newSettings = { ...storedSettings, theme: newTheme };
    return await setUserSettings(JSON.stringify(newSettings));
  };

  // Function to toggle theme variants (Light/Dark)
  const toggleThemeVariant = async (changedThemeVariant: ThemeVariant) => {
    setSelectedThemeVariant(changedThemeVariant);
    const newVariant = changedThemeVariant
      ? changedThemeVariant
      : themeVariant === 'Light'
      ? 'Dark'
      : 'Light';
    const checkedVariant = newVariant === 'OS' ? osMode : newVariant;
    setThemeVariant(checkedVariant);
    if (['Light', 'Dark'].includes(newVariant)) {
      updateTheme(currentTheme, newVariant, customThemeData.light, customThemeData.dark);
      await saveThemeVariant(newVariant);
    } else if (newVariant === 'OS') {
      const newTheme = osMode;
      updateTheme(currentTheme, newVariant, customThemeData.light, customThemeData.dark);
      await saveThemeVariant(newVariant);
    }
  };

  // Effect for setting theme variant based on stored settings or default mode
  useEffect(() => {
    if (!isLoadingUserPreferences && storedThemeVariant) {
      setSelectedThemeVariant(storedThemeVariant);
      setStoredVariant(storedThemeVariant);
    }
  }, [storedThemeVariant, storedSettings, isLoadingUserPreferences, selectedThemeVariant]);

  // Effect for fetching API tokens when user ID is available
  useEffect(() => {
    if (userUid) {
      fetchTokens();
    }
  }, [userUid, organization.organizationId]);

  // Effect for fetching custom theme data when tokens are available
  useEffect(() => {
    if (!orgSettingsLoading && organizationSettings && apiToken && productApiToken) {
      let customThemeDataLight = null;
      let customThemeDataDark = null;
      Object.entries(organizationSettings).forEach(([key, value]) => {
        try {
          const notJsonValueKeys = ['SelectedTheme', 'DicomWebServer', 'StudySecurity'];
          const themeArray =
            typeof value === 'string' &&
            !notJsonValueKeys.includes(key) &&
            !key.includes('custom-logo')
              ? JSON.parse(value)
              : [];
          if (Array.isArray(themeArray)) {
            themeArray.forEach((themeSettings) => {
              if (themeSettings.variant?.toLowerCase() === 'light') {
                customThemeDataLight = themeSettings;
              } else if (themeSettings.variant?.toLowerCase() === 'dark') {
                customThemeDataDark = themeSettings;
              }
            });
          }
        } catch (e) {
          console.log(`Error parsing value for key ${key}: ${e}`);
        }
      });
      sessionStorage.setItem('serverUrl', organizationSettings.DicomWebServer as string);
      updateTheme(currentTheme, themeVariant, customThemeDataLight, customThemeDataDark, true);
    }
    const tokens = !!apiToken && !!productApiToken;
    setLoading(!tokens ? false : orgSettingsLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orgSettingsLoading,
    organizationSettings,
    currentTheme,
    themeVariant,
    apiToken,
    productApiToken,
  ]);

  useEffect(() => {
    if (storedTheme !== currentTheme) setCurrentTheme(storedTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedTheme]);

  useEffect(() => {
    const updatedSettings = userPreferences?.settings
      ? JSON.parse(userPreferences?.settings || '{}')
      : {};

    setStoredThemeVariant(updatedSettings?.theme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences?.settings]);

  useEffect(() => {
    if (selectedThemeVariant && firstLoadTheme) {
      setFirstLoadTheme(false);
      setThemeVariant(selectedThemeVariant);
    }
  }, [selectedThemeVariant, firstLoadTheme]);

  // Effect for fetching custom theme data when tokens are available
  useEffect(() => {
    if (apiToken && productApiToken) {
      refetchPreferences();
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiToken, productApiToken]);

  // Constructing the combined theme name
  const combinedThemeName = `${currentTheme}${themeVariant}`;

  // Creating context value to be passed to the provider
  const contextValue = useMemo(
    () => ({
      currentTheme,
      themeVariant,
      combinedThemeName,
      switchTheme,
      toggleThemeVariant,
      availableThemes,
      customThemeData,
      saveThemeVariant,
      storedThemeVariant,
      initialTheme,
      organizationSettings,
      storedSettings,
      dicomServiceTokenSettings,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentTheme,
      themeVariant,
      combinedThemeName,
      customThemeData,
      storedThemeVariant,
      selectedThemeVariant,
      initialTheme,
      organizationSettings,
      storedSettings,
      dicomServiceTokenSettings,
    ],
  );

  // Returning the ThemeProvider with context and MUI ThemeProvider
  return (
    <ThemeContext.Provider value={contextValue}>
      {loading ? (
        <FullScreenLoader />
      ) : (
        <MuiThemeProvider theme={createTheme(theme)}>
          <CssBaseline />
          {children}
        </MuiThemeProvider>
      )}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;

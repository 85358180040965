import { useMutation, useQueryClient } from '@tanstack/react-query';
import { STUDY_QUERY_KEY } from '@core/hooks/useStudyInfo';
import { GetStudiesResponse, GetStudyResponse } from '@core/types';
import assignTechnician from '../api/assignTechnician';
import { GET_TECHNICIAN_QUERY_KEY } from './useGetStudyAssignedTechnician';
import { QUERY_KEY as STUDIES_QUERY_KEY } from './useStudies';

const useAssignTechnician = () => {

  const queryClient = useQueryClient()

  const { isLoading: isUploading, mutateAsync: assignTechnicianAsync } = useMutation({
    mutationFn: ({studyInstanceUid, userUid}:{ studyInstanceUid: string, userUid: string}) => assignTechnician(studyInstanceUid, userUid),
    onSuccess: (updated) => {

      queryClient.invalidateQueries([GET_TECHNICIAN_QUERY_KEY])

      queryClient.setQueryData<GetStudyResponse>(
        [STUDY_QUERY_KEY, updated.studyModel.studyInstanceUid],
        (existingStudy) => {
          if (existingStudy) {
            existingStudy.technicianId = updated.studyModel.technicianId;
          }

          return existingStudy;
        },
      );

      queryClient.setQueryData<GetStudiesResponse>(
          [STUDIES_QUERY_KEY, updated.studyModel.studyInstanceUid],
          (existingStudies) => {
            if (existingStudies && (existingStudies?.items || []).length > 0) {
              
              const updatedStudies = existingStudies?.items.map((study) => {
                  if(study.studyInstanceUid === updated.studyModel.studyInstanceUid){
                      study.technicianId = updated.studyModel.technicianId
                  }

                  return study;
              })
              
              existingStudies.items = updatedStudies;

              return existingStudies
            }
  
            return existingStudies;
          },
      )
    },
    }
  );

  return { isUploading, assignTechnicianAsync };
};

export default useAssignTechnician;
import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { handleError, handleResponse } from '../../utils/responseHandler';
import setHeaders from './setHeadersRequestInterceptor';

/**
 * Configures the REST API methods for a particular base URL.
 * @param baseUrl The REST base URL
 */
const configureRestApi = (baseUrl: string, setHeadersInterceptor?: (value: InternalAxiosRequestConfig<any>) => InternalAxiosRequestConfig<any>) => {
  const axiosInstance = axios.create({ baseURL: baseUrl });

  axiosInstance.interceptors.request.use(setHeadersInterceptor ?? setHeaders);

  const get = async <TResponse>(
    endpoint: string,
    config?: AxiosRequestConfig,
  ): Promise<TResponse> => {
    const response = await axiosInstance.get<TResponse>(`${endpoint}`, config);
    return handleResponse(response);
  };

  const post = async <TResponse, TModel>(
    endpoint: string,
    body?: TModel,
    config?: AxiosRequestConfig,
  ): Promise<TResponse> => {
    try {
      const response = await axiosInstance.post<TResponse>(endpoint, body, config);
      return handleResponse(response);
    } catch (err) {
      return handleError(err);
    }
  };

  const put = async <TResponse, TModel>(
    endpoint: string,
    body: TModel,
    config?: AxiosRequestConfig,
  ): Promise<TResponse> => {
    try {
      const response = await axiosInstance.put<TResponse>(endpoint, body, config);
      return handleResponse(response);
    } catch (err) {
      return handleError(err);
    }
  };

  const patch = async <TResponse, TModel>(
    endpoint: string,
    body?: TModel,
    config?: AxiosRequestConfig,
  ): Promise<TResponse> => {
    try {
      const response = await axiosInstance.patch<TResponse>(endpoint, body, config);
      return handleResponse(response);
    } catch (err) {
      return handleError(err);
    }
  };

  const remove = async <TResponse>(
    endpoint: string,
    config?: AxiosRequestConfig,
  ): Promise<TResponse> => {
    try {
      const response = await axiosInstance.delete<TResponse>(`${endpoint}`, config);
      return handleResponse(response);
    } catch (err) {
      return handleError(err);
    }
  };

  return { get, post, put, patch, remove };
};

export default configureRestApi;

import React from 'react';
import { Download, Print } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Tooltip, Typography } from '@mui/material';
import ActionButtonsProps from './ActionButtonsProps';
import useSx from './sx';

export default function ActionButtons({
  zoomFunctions,
  closeReport,
  handleDownload,
  handlePrint,
}: ActionButtonsProps) {
  const { zoom, zoomIn, zoomOut } = zoomFunctions;
  const sx = useSx();

  return (
    <Box sx={sx.footerBar}>
      <ButtonGroup variant="contained" sx={sx.zoomContainer}>
        <Button onClick={zoomOut} disabled={zoom <= 50} sx={sx.zoomButtonL}>
          -
        </Button>
        <Typography sx={sx.zoomText}>{zoom}%</Typography>
        <Button onClick={zoomIn} disabled={zoom >= 200} sx={sx.zoomButtonR}>
          +
        </Button>
      </ButtonGroup>

      <Button onClick={closeReport} variant="contained">
        Ver imágenes
      </Button>

      <Tooltip title="Descargar">
        <Button onClick={handleDownload} variant="contained">
          <Download />
        </Button>
      </Tooltip>

      <Tooltip title="Imprimir">
        <Button variant="contained" onClick={handlePrint}>
          <Print />
        </Button>
      </Tooltip>
    </Box>
  );
}

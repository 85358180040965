import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  navButton: {
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    zIndex: '100',
    bottom: 0,
    height: '100%',
    '& .MuiButton-root': {
      padding: 0,
      height: '60px',
      width: '60px',
      borderRadius: '50%',
      '@media (max-width: 550px)': {
        display: 'none',
      },
    },
  },
});

export default useSx;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteStudyComment } from '../api/deleteStudyComment';
import { GET_STUDY_COMMENTS_QUERY_KEY } from './useGetStudyComments';

const useDeleteStudyComment = () => {
  const queryClient = useQueryClient()
  const { isLoading: isDeleting, mutateAsync: deleteStudyCommentAsync } = useMutation({
    mutationFn: (studyCommentId: string) => deleteStudyComment(studyCommentId),
    onSuccess: () => {
      queryClient.invalidateQueries([GET_STUDY_COMMENTS_QUERY_KEY])
    }
  });

  return { isDeleting, deleteStudyCommentAsync };
};

export default useDeleteStudyComment;

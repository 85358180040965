/* eslint-disable @typescript-eslint/naming-convention */
import { get } from '@core/api/baseApi';

const getOrganizationTemplateImage = async (templateId: string): Promise<Blob> => {
  const renderedImage = await get<Blob>(`/organizations/templates/${templateId}/image`, {
    responseType: 'blob'
  });

  return renderedImage
}

export { getOrganizationTemplateImage };

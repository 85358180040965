import findLastTextNode from './findLastTextNode';

function setCaretAtEndOfText(node: Node) {
  // Traverse the DOM tree to find the last text node
  const lastTextNode = findLastTextNode(node);

  if (lastTextNode) {
    const range = document.createRange();
    range.setStart(lastTextNode, lastTextNode.textContent?.length ?? 0);
    range.collapse(true);

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}

export default setCaretAtEndOfText;

import SxDef from '@core/types/SxDef';

const useSx = (readOnly?: boolean): SxDef => ({
  root: (theme) => ({
    '& .EvoTagControl-input': { 
      width: 1, 
      display: 'flex', 
      paddingX: readOnly ? 6 : 0, 
      alignItems: 'center',
    },
    '& .EvoTagControl-inputAdornment': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      mr: 2,

      '& p': {
        ...theme.typography.body1,
        color: 'text.secondary',
      },
    },
  }),
  autocomplete: {
    '.MuiInputBase-root': {
      paddingY: '0',
      paddingLeft: '8px'
    }
  }

});

export default useSx;

import React from 'react';
import MuiList from '@mui/material/List';
import If from '@components/If';
import getMenuItems from '@components/Layout/MainLayout/api/getMenuItems';
import getSettingsItems from '@components/Layout/MainLayout/api/getSettingsItems';
import useProfile from '@core/hooks/useProfile';
import useWidth from '@core/hooks/useWidth';
import { RoleName } from '@core/types';
import BackButton from './BackButton';
import ListItem from './ListItem';
import { CombinedItem, MenuItem, SettingItem } from './ListItem/ListItemProps';
import MenuItemListItem from './ListItem/MenuListItem';
import { ListProps } from './ListProps';
import useSx from './sx';
import isSettingItem from './typeguard';

const RenderItem = ({
  profile,
  el,
  handleDrawerToggle,
  collapsed,
}: {
  profile: boolean | undefined;
  el: CombinedItem;
  handleDrawerToggle: (() => void | undefined) | undefined;
  collapsed: boolean | undefined;
}) => {
  const { key, ...listProps } = el;

  if (profile) {
    return (
      <If
        condition={isSettingItem(el)}
        fallback={
          <MenuItemListItem
            {...(listProps as MenuItem)}
            // key={el.key}
            handleDrawerToggle={handleDrawerToggle}
            collapsed={collapsed}
          />
        }
      >
        <ListItem
          {...(listProps as SettingItem)}
          // key={el.key}
          handleDrawerToggle={handleDrawerToggle}
          collapsed={collapsed}
        />
      </If>
    );
  }

  return (
    <ListItem
      {...(listProps as SettingItem)}
      handleDrawerToggle={handleDrawerToggle}
      collapsed={collapsed}
    />
  );
};

const List = ({ handleDrawerToggle, profile, sidebarCollapsed }: ListProps) => {
  const sx = useSx();
  const width = useWidth();
  const { hasAdminPermissions, hasOwnerPermissions, role, hasMoreOrganizations } = useProfile();

  const allSettingsItems: SettingItem[] = getSettingsItems();
  const allMenuItems: MenuItem[] = getMenuItems();

  const items: CombinedItem[] =
    profile && width !== 'xs'
      ? allMenuItems?.filter((item) => item.key !== 'exit')
      : allSettingsItems;

  const filteredItems = items.filter((item: CombinedItem) => {
    if (isSettingItem(item)) {
      // Filter logic for SettingItem

      const isAdminOnlyAccessible = hasAdminPermissions && item.adminOnly && !item.ownerOnly;
      const isNonAdminAccessible = !hasAdminPermissions && !item.adminOnly;
      const isOwnerAccessible = hasOwnerPermissions && item.ownerOnly;

      const isAccessible = isAdminOnlyAccessible || isNonAdminAccessible || isOwnerAccessible;

      return item.enabled && item.sections.aside && isAccessible;
    } else {
      // Filter logic for MenuItem, checking role access
      // Desglosar las condiciones en variables descriptivas
      const isRoleValid = !!role;
      const isRoleEnabled = item.roles[role || RoleName.Anonymous] || hasAdminPermissions;
      const isItemEnabled = item.enabled && isRoleValid && isRoleEnabled;

      const isNotSwitchItem = item.key !== 'switch';
      const isSwitchWithOrganizations = hasMoreOrganizations && item.key === 'switch';
      const isOrganizationsEnabled = isNotSwitchItem || isSwitchWithOrganizations;

      return isItemEnabled && isOrganizationsEnabled;
    }
  });

  return (
    <div>
      <MuiList sx={sx.root}>
        <BackButton collapsed={!!sidebarCollapsed} handleDrawerToggle={handleDrawerToggle} />
        {filteredItems.map((item) => (
          <RenderItem
            el={item}
            handleDrawerToggle={handleDrawerToggle}
            key={item.key}
            profile={profile}
            collapsed={!!sidebarCollapsed}
          />
        ))}
      </MuiList>
    </div>
  );
};

export default List;

import { CustomError } from '@core/types';
import errorMessages from '../errorMessages';

const maxNameLength = 50;
const maxFileSize = 20971520; // 20mb
const acceptType = 'application/pdf';

const validate: <T extends File>(file: T) => CustomError | CustomError[] | null = (file: File) => {
  if (file.name?.length > maxNameLength) {
    return errorMessages.fileNameTooLarge;
  }

  if (file.size > maxFileSize) {
    return errorMessages.fileTooLarge;
  }

  if (file.size <= 0) {
    return errorMessages.fileTooSmall;
  }

  if (file.type !== acceptType) {
    return errorMessages.invalidDataType;
  }

  return null;
};

export default validate;

import { PageConfig } from '../components/Form/TextEditor/TextEditor';

function applyEditorDivStyle(
  contentDiv: HTMLDivElement,
  pageConfig: PageConfig,
  backgroundUrl: string,
) {
  const pageDiv = contentDiv.parentNode! as HTMLElement;
  // <body/> mock
  pageDiv.style.width = `${pageConfig.size.width}px`;
  pageDiv.style.height = `${pageConfig.size.height}px`;
  pageDiv.style.padding = `${pageConfig.padding.top}px ${pageConfig.padding.right}px ${pageConfig.padding.bottom}px ${pageConfig.padding.left}px `;
  pageDiv.style.fontFamily = pageConfig.fontFamily;
  pageDiv.style.fontSize = `${pageConfig.fontSize}px`;
  pageDiv.style.lineHeight = `${pageConfig.lineHeight}`;
  if (backgroundUrl && backgroundUrl.length > 0) {
    pageDiv.style.backgroundImage = `url(${backgroundUrl})`;
  } else {
    pageDiv.style.backgroundColor = 'white'; // assuming a page has to be white
  }

  // <main/> mock
  contentDiv.style.width = `100%`;
  contentDiv.style.height = `100%`;
}

export default applyEditorDivStyle;

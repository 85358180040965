import React, { useMemo } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import HeaderProps from './HeaderProps';
import Logo from './Logo';
import MenuIcon from './MenuIcon';
import Notifications from './Notifications';
import Profile from './Profile';
import Settings from './Settings';
import useHeaderState from './useHeaderState';

const Header = ({ handleMenuClick, onLogin }: HeaderProps) => {
  const { deviceWidth, sx, userFullName, profilePhotoSrc } = useHeaderState();
  const organizationId = sessionStorage.getItem('organizationId') || '';
  const studyIdsString = sessionStorage.getItem('studyIds') || '{}';
  const studyIds = useMemo(() => JSON.parse(studyIdsString), [studyIdsString]);
  return (
    <AppBar position="sticky" sx={sx.root}>
      <Container sx={sx.headerContainer} maxWidth={false} disableGutters>
        <Toolbar>
          {/*MenuIcon for settings menu in mobile*/}
          <MenuIcon handleMenuClick={handleMenuClick} />
          <Box sx={sx.logoContainer}>
            <Logo />
          </Box>
          <AuthenticatedTemplate>
            <Box className="profile-container">
              <Notifications />
              <If condition={deviceWidth !== 'xs'}>
                <Settings />
              </If>
              <Profile userFullName={userFullName} profilePhotoSrc={profilePhotoSrc} />
            </Box>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <If condition={studyIds?.studyInstanceUid && studyIds?.studyHash && organizationId}>
              <ActionButton
                text="Inicia Sesión"
                color="primary"
                variant="contained"
                onClick={onLogin}
                sx={sx.loginButton}
              />
            </If>
          </UnauthenticatedTemplate>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;

import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Box from '@mui/material/Box';
import If from '@components/If';
import useLogoState from './useLogoState';

const Logo = () => {
  const { sx, logo } = useLogoState();

  const logoComponent = (
    <If condition={!!logo}>
      <Box component="img" src={logo as string} sx={sx.logo} />
    </If>
  );

  return (
    <Box sx={sx.root}>
      <AuthenticatedTemplate>
        <a href="/">{logoComponent}</a>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>{logoComponent}</UnauthenticatedTemplate>
    </Box>
  );
};

export default Logo;

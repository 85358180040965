import React from 'react';
import { Box } from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import UserAutoComplete from '@components/UserAutoComplete';
import userItemMapper from '@core/utils/userToUserItemMapper';
import AssignedRadiologists from '../AssignedRadiologists';
import { FormPropsWithNotification } from './FormProps';
import useFormState from './useFormState';

const Form = ({
  open = false,
  onClose,
  studyInstanceUid,
  handleSendNotification,
  isSendingNotification,
}: FormPropsWithNotification) => {
  const {
    assignRadiologistAsync,
    showSnackbar,
    revokeRadiologistAccess,
    selectedRadiologist,
    assignedRadiologists,
    setSelectedRadiologist,
    setSelectedRadiologistInput,
    selectedRadiologistInput,
  } = useFormState({ isOpen: open, studyInstanceUid });
  const isSubmittingLoading = isSendingNotification;

  const handleAssignRadiologist = async () => {
    if (!!selectedRadiologistInput) {
      const response = await assignRadiologistAsync({
        studyInstanceUid: studyInstanceUid || '',
        userUid: selectedRadiologistInput?.userUid || '',
      });
      if (!response.success) {
        showSnackbar({
          message: response.error || 'No fue posible asignar al médico radiólogo.',
          title: 'Ocurrió un error',
          type: 'error',
        });
        return;
      }

      const userItem = userItemMapper(selectedRadiologistInput);

      await handleSendNotification(studyInstanceUid, userItem);
      showSnackbar({
        message: 'Médico radiólogo asignado con éxito.',
        title: 'Médico asignado',
        type: 'success',
      });
    }
  };

  const handleRevokeAccess = async () => {
    const response = await revokeRadiologistAccess({
      studyInstanceUid: studyInstanceUid || '',
      userUid: selectedRadiologist?.userUid || '',
    });
    if (response.studyInstanceUid) {
      showSnackbar({
        message: 'Acceso revocado al estudio médico.',
        type: 'success',
        title: 'Acceso cancelado',
      });
      return;
    }

    showSnackbar({
      message: 'No se pudo revocar acceso al estudio médico. Por favor, intenta de nuevo.',
      type: 'error',
      title: 'Ocurrió un error',
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <AssignedRadiologists
        assignedRadiologist={assignedRadiologists?.authorizedUser}
        setSelectedRadiologist={setSelectedRadiologist}
        selectedRadiologist={selectedRadiologist}
      />
      <UserAutoComplete
        searchRoles="Radiologist"
        onUserSelect={(value) => {
          setSelectedRadiologistInput(value);
        }}
        placeholder="Seleccionar médico radiólogo"
      />
      <Box display="flex" gap="10px" justifyContent="flex-end">
        <If condition={selectedRadiologist}>
          <ActionButton
            text="Revocar Accesso"
            variant="contained"
            color="error"
            sx={{ marginRight: 'auto' }}
            onClick={handleRevokeAccess}
          />
        </If>
        <ActionButton text="Cancelar" variant="outlined" color="secondary" onClick={onClose} />
        <ActionButton
          text="Asignar Estudio"
          color="primary"
          variant="contained"
          onClick={handleAssignRadiologist}
          isLoading={isSubmittingLoading}
        />
      </Box>
    </Box>
  );
};

export default Form;

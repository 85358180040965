import React from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Drawer, Box } from '@mui/material';
import IconButton from '@components/Button/IconButton';
import If from '@components/If';
import List from '../List';
import { LargeViewDrawerProps } from './LargeViewDrawerProps';
import useSx from './sx';

const LargeViewDrawer = ({
  profile,
  sidebarCollapsed,
  handleSidebarCollapseToggle,
}: LargeViewDrawerProps) => {
  const sx = useSx(!!sidebarCollapsed);

  return (
    <Drawer variant="permanent" sx={sx.root} open>
      <List profile={profile} sidebarCollapsed={sidebarCollapsed} />
      <Box sx={sx.iconContainer}>
        <IconButton
          id={sidebarCollapsed ? 'left-drawer-open-button' : 'left-drawer-close-button'}
          sx={sx.iconButton}
          tooltip={sidebarCollapsed ? 'Mostrar' : 'Esconder'}
          onClick={handleSidebarCollapseToggle}
          color="secondary"
          variant="outlined"
        >
          <If condition={sidebarCollapsed} fallback={<NavigateBeforeIcon />}>
            <NavigateNextIcon />
          </If>
        </IconButton>
      </Box>
    </Drawer>
  );
};

export default LargeViewDrawer;

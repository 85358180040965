import { put } from '@core/api/baseApi';
import { UpdateTemplateResponse } from '@core/types';

export const updateTemplate = (payload: {
  templateId: number;
  templateName: string;
  content: string;
  templateFolderId?: number;
  organizationWide: boolean;
}): Promise<UpdateTemplateResponse> => put('/ReportTemplates', payload);

import React from 'react';
import useViewportSize from '@core/hooks/useViewportSize';
import LargeViewMenu from './LargeViewMenu';
import ProfileMenuProps from './ProfileMenuProps';
import SmallViewMenu from './SmallViewMenu';

const ProfileMenu = ({ anchorEl, onMenuItemClick, onClose }: ProfileMenuProps) => {
  const { isMediumViewport } = useViewportSize();
  const MenuComponent = isMediumViewport ? SmallViewMenu : LargeViewMenu;

  return <MenuComponent anchorEl={anchorEl} onMenuItemClick={onMenuItemClick} onClose={onClose} />;
};

export default ProfileMenu;

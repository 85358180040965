import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        width: { xs: '100%', sm: '90%' },
        marginTop: '2rem',
        '.personal-information-icon': {fontSize: '1.2rem'},
        '.custom-icon': {
            color: (theme) => theme.palette.primary.main
        }
    }, 
});

export default useSx;

import { useMutation } from '@tanstack/react-query';
import postTranslate from '@core/api/postTranslate';

const useTranslator = () => {
  const { isLoading, mutateAsync: postTranslateAsync } = useMutation({
    mutationFn: (text: string) => postTranslate(text),
  });

  return { isLoading, postTranslateAsync };
};

export default useTranslator;

import React from 'react';
import If from '@components/If';
import DrawerProps from './DrawerProps';
import LargeViewDrawer from './LargeViewDrawer';
import SmallViewDrawer from './SmallViewDrawer';

const Drawer = ({ handleDrawerToggle, handleSidebarCollapseToggle, isOpen, profile, sidebarCollapsed }: DrawerProps) => {
    return (
        <>
            <SmallViewDrawer isOpen={isOpen} handleDrawerToggle={handleDrawerToggle} profile />
            <LargeViewDrawer profile={profile} sidebarCollapsed={sidebarCollapsed} handleSidebarCollapseToggle={handleSidebarCollapseToggle} />
        </>
    )
}

export default Drawer
import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import useSx from './sx';

export type ViewerInputProps = TextFieldProps & { readOnly?: boolean };

const ViewerTextField = ({ label, readOnly, ...props }: ViewerInputProps) => {
  const sx = useSx()
  return (
    <TextField
      sx={sx.root}
      fullWidth
      InputProps={{
        startAdornment: label && <InputAdornment position="start">{label}</InputAdornment>,
        readOnly,
      }}
      {...props}
    />
  );
};

export default ViewerTextField;

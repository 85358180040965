import SxDef from '@core/types/SxDef';
import { ThemeVariant } from '@core/types/Theme';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (mode: ThemeVariant, previewMode: ThemeVariant | undefined): SxDef => ({
  root: {
    ...expandDisplayFlex({j: 'center', a: 'center', d: 'column'}),
    marginRight: '3rem',
  },
  iconButton: {
    marginBottom: '1rem',
    color: (theme) =>
    (previewMode === mode || (previewMode === undefined && mode === 'Dark'))
        ? 'white'
        : theme.palette.primary.main,
  },
});

export default useSx;

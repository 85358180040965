import React from 'react'
import Typography from '@mui/material/Typography'
import { TitleProps } from './TitleProps'
import useSx from './sx'


const Title = ({ text }: TitleProps) => {

    const sx = useSx()

    return (
        <Typography sx={sx.root} variant="h4">{text}</Typography>
    )
}

export default Title
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Title from '@components/Title';
import useSnackbar from '@core/hooks/useSnackbar';
import { TemplateFile } from '@core/types';
import isCustomErroHandlerResponse from '@core/utils/isCustomErrorHandlerResponse';
import Actions from '../components/Actions';
import FolderView from '../components/FolderView';
import FOLDER_ERROR_MESSAGES from '../constants/folderErrorMessages';
import useEditFolder from '../hooks/useEditFolder';
import useTemplatesState from './useTemplatesState';

const Templates = () => {
  const { folders, deviceWidth, filters } = useTemplatesState();
  const { editFolderAsync } = useEditFolder();
  const showSnackbar = useSnackbar();

  const navigate = useNavigate();

  const handleTemplateClick = (template: TemplateFile) => {
    navigate(`/templates/${template.folderHierarchyItemId}`);
  };

  return (
    <Container sx={{ margin: 0 }} maxWidth={deviceWidth} disableGutters>
      <Title text="Plantillas" />
      <Actions />
      <FolderView
        onEditFolder={async (templateFolderName, folder, folderPath) => {
          const response = await editFolderAsync({
            templateFolderId: folder.folderHierarchyItemId,
            templateFolderName,
            isOrganizationWide: filters.Section === 'Organization',
            folderPath,
          });
          if (isCustomErroHandlerResponse(response)) {
            showSnackbar({
              type: 'error',
              title: 'Error',
              message: FOLDER_ERROR_MESSAGES[response.statusCode] || FOLDER_ERROR_MESSAGES.status,
            });
          }
        }}
        itemArray={folders?.root || {}}
        onTemplateClick={handleTemplateClick}
      />
    </Container>
  );
};

export default Templates;

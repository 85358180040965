import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  row: {
    '& .MuiTableCell-root, & th': {
      fontSize: '0.8rem',
      padding: '16px',
    },
    '&:last-child .MuiTableCell-root, & th': {
      borderBottom: 'none !important',
    },
    '.user-col': {
      textTransform: 'none',
    },
    ':hover': {
      '& td': {
        background: (theme) => theme.palette.shade?.[100],
      },
    },
  },
  root: {
    '.MuiSvgIcon-root': {
      cursor: 'pointer',
      color: (theme) => theme.palette.brand.main,
    },
  },
  keyIcon: { transform: 'rotate(135deg)' },
  deleteIcon: { color: (theme) => theme.palette.error.main },
});

export default useSx;

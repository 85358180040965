import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    '& .MuiPaper-root': { 
      width: { xs: '95vw', md: '50vw' },
      maxWidth: '100%',
      margin: 0 
    },
    '& .MuiDialogContent-root': {
      padding: {
        xs: '16px 0px',
        sm: '16px 24px'
      }
    }
  },
  actionsButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    width: '100%',
    padding: '1rem'
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'column'
  },
  closeButton: {
    width: 'fit-content',
    alignSelf: 'flex-end'
  },
  link: {
    margin: '0 5px'
  },
});

export default useSx;

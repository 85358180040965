import { useQuery } from '@tanstack/react-query';
import { getTemplates } from '../api/getTemplates';
import { SearchParams } from './useFilters';

export const GET_TEMPLATES_QUERY_KEY = 'templateList';

const useGetTemplates = (filters: SearchParams) => {
  const { data: templates, ...rest } = useQuery({
    queryKey: [GET_TEMPLATES_QUERY_KEY, filters],
    queryFn: () => getTemplates(filters),
  });

  return {
    templates,
    ...rest,
  };
};

export default useGetTemplates;

import React, { useEffect } from 'react';
import { subscribe, unsubscribe } from '@core/constants/customEvents';
import useSnackbar from '@core/hooks/useSnackbar';
import { FOOTER_ELEMENT_SELECTOR, QRCODE_ELEMENT_SELECTOR } from '../constants/reportElements';
import useGetReportConclusion from './useGetReportConclusion';

const useGetConclusionFuncionality = ({ editorDivs }: { editorDivs: HTMLElement[] }) => {
  //Start
  const showSnackbar = useSnackbar();
  const { getReportConclusionAsync, isGettinConclusion } = useGetReportConclusion();
  useEffect(() => {
    const makeConclusionFuncRef = subscribe('evodicom.report.makeConclusion', async () => {
      const reportContents = editorDivs.map((editor) => {
        const clonedNode = editor.cloneNode(true) as HTMLElement;
        const qrcode = clonedNode.querySelector(QRCODE_ELEMENT_SELECTOR);
        const footer = clonedNode.querySelector(FOOTER_ELEMENT_SELECTOR);
        if (qrcode) {
          qrcode.remove();
        }

        if (footer) {
          footer.remove();
        }

        return clonedNode.textContent;
      });

      if (reportContents.join('').length > 0) {
        const response = await getReportConclusionAsync(reportContents.join(' '));
        const lastEditorDiv = editorDivs.at(-1);

        const conclusionChild = document.createElement('div');
        conclusionChild.innerHTML = `Conclusión: <br> ${response.result.content}`;
        lastEditorDiv?.appendChild(conclusionChild);
      } else {
        showSnackbar({
          type: 'error',
          title: 'Error',
          message:
            'La información proporcionada es insuficiente para generar una conclusión. Por favor, añada más detalles sobre el estudio',
        });
      }
    });

    return () => {
      unsubscribe('evodicom.report.makeConclusion', makeConclusionFuncRef);
    };
  }, []);

  return { isGettinConclusion };
};

export default useGetConclusionFuncionality;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteOrganizationTemplate } from '@core/api/deleteOrganizationTemplates';
import { ORGANIZATION_TEMPLATE_QUERY_KEY } from './useOrganizationTemplate';
import { ORGANIZATION_TEMPLATE_IMAGE_QUERY_KEY } from './useOrganizationTemplateImage';
import { ORGANIZATION_TEMPLATES_QUERY_KEY } from './useOrganizationTemplates';

const useDeleteOrganizationTemplates = () => {
  const queryClient = useQueryClient();

  const {
    mutate: deleteOrganizationTemplateAsync,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useMutation(deleteOrganizationTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries([ORGANIZATION_TEMPLATES_QUERY_KEY]);
      queryClient.invalidateQueries([ORGANIZATION_TEMPLATE_QUERY_KEY]);
      queryClient.invalidateQueries([ORGANIZATION_TEMPLATE_IMAGE_QUERY_KEY]);
    },
    onError: (e) => {
      console.error('Error al eliminar el template de la organización:', e);
    },
  });

  return { deleteOrganizationTemplateAsync, isLoading, isError, isSuccess, error };
};

export default useDeleteOrganizationTemplates;

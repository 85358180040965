import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import IconButton from '@components/Button/IconButton';
import useSx from './sx';

type Props = {
  isOpen: boolean;
  onClick?: () => void;
};

const ToggleDrawerButton = ({ isOpen, onClick }: Props) => {
  const sx = useSx();

  const tooltipText = isOpen ? 'Esconder' : 'Mostrar';

  return (
    <Box sx={sx.root}>
      <IconButton
        sx={sx.iconButton}
        tooltip={tooltipText}
        onClick={onClick}
        color="secondary"
        variant="outlined"
        aria-label={tooltipText}
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

export default ToggleDrawerButton;

import { useMutation, useQuery } from '@tanstack/react-query';
import { postSignature, getSignature, putSignature, removeSignature } from '../api/signature';

const POST_SIGNATURE = ['postSignature'];
const GET_SIGNATURE = 'getSignature';
const PUT_SIGNATURE = ['putSignature'];
const DELETE_SIGNATURE = ['deleteSignature'];

const usePostSignature = () => {
  const { mutateAsync: postUserSignature, ...rest } = useMutation({
    mutationKey: POST_SIGNATURE,
    mutationFn: postSignature
  });

  return { postUserSignature, ...rest };
};

const useGetSignature = () => {
  return useQuery([GET_SIGNATURE], getSignature, {
    retry: false,
  });
};

const usePutSignature = () => {
  const { mutateAsync: putUserSignature, ...rest } = useMutation({
    mutationKey: PUT_SIGNATURE,
    mutationFn: putSignature
  });

  return { putUserSignature, ...rest };
};

const useDeleteSignature = (deleteSignature: boolean, deleteProfessionalInfo: boolean) => {
  const { mutateAsync: deleteUserSignature, ...rest } = useMutation({
    mutationKey: DELETE_SIGNATURE,
    mutationFn: () => removeSignature(deleteSignature, deleteProfessionalInfo)
  });

  return { deleteUserSignature, ...rest };
};

export { usePostSignature, useGetSignature, usePutSignature, useDeleteSignature };

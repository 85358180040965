import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import { NotificationUpdateDto } from '@core/api/updateNotificationsStatus';
import useUpdateNotificationsStatus from '@core/hooks/useUpdateNotificationStatus';
import { NotificationMessage } from '@core/types';
import useFilters from '../../hooks/useFilters';
import ActionsProps from './ActionsProps';
import useSx from './sx';

const getShowButtonsState = (notificationSelected: Map<string, NotificationMessage>) => {
  const buttonsMap = {
    readButton: false,
    unreadButton: false,
  };
  notificationSelected.forEach((notificationMessage) => {
    if (notificationMessage.isSeen) {
      buttonsMap.unreadButton = true;
    }

    if (!notificationMessage.isSeen) {
      buttonsMap.readButton = true;
    }
  });

  return buttonsMap;
};

const modifyTextWhenThereAreMultipleNotifications = (
  singleValueTextCase: string,
  multipleValuesTextCase: string,
  condition: boolean,
) => {
  return condition ? multipleValuesTextCase : singleValueTextCase;
};

const Actions = ({
  selectedNotificationsIds,
  setSelectedNotificationsIds,
  notifications,
}: ActionsProps) => {
  const sx = useSx();
  const { mutateAsync: updateNotificationsAsync, isLoading } = useUpdateNotificationsStatus();
  const [filters, setFilters, removeFilter] = useFilters();

  const onSelectAllNotifications = () => {
    const notificationSelectedTemp = new Map(selectedNotificationsIds);
    notifications.forEach((notification) => {
      if (!selectedNotificationsIds.get(notification.notificationMessageId)) {
        notificationSelectedTemp.set(notification.notificationMessageId, notification);
      }
    });

    setSelectedNotificationsIds(notificationSelectedTemp);
  };

  const onClickSeenStatus = (isSeen: boolean) => async () => {
    const notificationsToBeUpdated: NotificationUpdateDto[] = [];
    selectedNotificationsIds.forEach((_, notificationId) => {
      notificationsToBeUpdated.push({
        notificationMessageId: notificationId,
        isSeen,
      });
    });

    await updateNotificationsAsync({ notificationToUpdate: notificationsToBeUpdated });

    setSelectedNotificationsIds(new Map());
  };

  const onStatusSelectChange = (e: SelectChangeEvent<string>) => {
    if (e.target.value === 'All') {
      removeFilter('Status');
    } else {
      setFilters('Status', e.target.value);
    }
  };

  const showButtons = useMemo(() => {
    return getShowButtonsState(selectedNotificationsIds);
  }, [selectedNotificationsIds]);

  return (
    <Box sx={sx.root}>
      <Box display="flex" flexDirection="inherit" alignItems="inherit" gap="inherit">
        <Select value={filters.Status || 'All'} label="Todos" onChange={onStatusSelectChange}>
          <MenuItem value={'All'}>Todos</MenuItem>
          <MenuItem value={'1'}>Visto</MenuItem>
          <MenuItem value={'0'}>No Visto</MenuItem>
        </Select>
      </Box>
      <Box display="flex" flexDirection="inherit" alignItems="inherit" gap="inherit">
        <ActionButton
          isLoading={isLoading}
          text="Seleccionar todos"
          onClick={onSelectAllNotifications}
        />
        <If condition={showButtons.readButton}>
          <ActionButton
            text={modifyTextWhenThereAreMultipleNotifications(
              'Marcar como leído',
              'Marcar como leídos',
              selectedNotificationsIds.size > 1,
            )}
            isLoading={isLoading}
            onClick={onClickSeenStatus(true)}
          />
        </If>
        <If condition={showButtons.unreadButton}>
          <ActionButton
            text={modifyTextWhenThereAreMultipleNotifications(
              'Marcar como no leído',
              'Marcar como no leídos',
              selectedNotificationsIds.size > 1,
            )}
            isLoading={isLoading}
            onClick={onClickSeenStatus(false)}
          />
        </If>
      </Box>
    </Box>
  );
};

export default Actions;

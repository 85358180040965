import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: (theme) => ({
    flexGrow: 1,
    ...expandDisplayFlex({ d: 'column' }),

    [theme.breakpoints.down('xl')]: {
      px: 8,
    },

    [theme.breakpoints.down('sm')]: {
      px: 0,
    },

    '& .MuiGrid-container': {
      py: 1,
      px: 3,
      bgcolor: 'background.paper',

      [theme.breakpoints.up('sm')]: {
        p: 8,
        bgcolor: 'initial',
      },
    },

    '& .EvoContentLayoutMain-root': {
      flex: '1 0 auto',
      padding: 'auto 0'
    },
  }),
  title: {
    paddingTop: '2rem'
  },
  subtitle: {
    padding: '2rem 0 1rem 0'
  },
  divider: {
    marginBottom: '1rem'
  },
});

export default useSx;

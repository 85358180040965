import { SubscriptionsDetails } from '../components/Subscription/SubscriptionGeneralProps';

const transformToPieChartData = (details: SubscriptionsDetails) => {
  const { usage, userPlan } = details || {};
  const { totalStudiesCount = 0 } = usage || {};
  const { settings } = userPlan || {};
  const { studiesIncluded = 0 } = settings || {};

  const usedStudies = totalStudiesCount;
  const availableStudies = Math.max(studiesIncluded - usedStudies, 0);
  const additionalStudies = Math.max(usedStudies - studiesIncluded, 0);

  return [
    {
      name: 'Disponible',
      value: availableStudies,
      total: availableStudies,
      color: '#149511',
    },
    {
      name: 'Utilizado',
      value: usedStudies,
      total: usedStudies,
      color: '#0E70FF',
    },
    {
      name: 'Adicional',
      value: additionalStudies,
      total: additionalStudies,
      color: '#d92626',
    },
  ];
};

export default transformToPieChartData;

import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    ...expandDisplayFlex({ a: 'center' }),
    gap: '10px',
    '.Label-Root': {
      paddingLeft: '24px',
      width: '25%',
    },
    '.TextField-Root': {
      flex: 1,
    },
  },
});

export default useSx;

import { CustomError } from '@core/types';
import { UPLOAD_REPORT_API_ERROR_CODE } from '../../../api/addReport';
import errorMessages from './errorMessages';

const processStatusError = (err: any, setError: (err: CustomError) => void) => {
  switch (err.statusCode) {
    case 400: {
      switch (err.message) {
        case UPLOAD_REPORT_API_ERROR_CODE.duplicateReport:
          setError(errorMessages.duplicateFile);
          break;
        case UPLOAD_REPORT_API_ERROR_CODE.emptyReport:
          setError(errorMessages.emptyFile);
          break;
        case UPLOAD_REPORT_API_ERROR_CODE.invalidDataType:
          setError(errorMessages.invalidDataType);
          break;
        default:
          setError(errorMessages.generalError);
          break;
      }
      break;
    }
    case 401:
      // TODO: handle Unauth
      break;
    case 503:
      setError(errorMessages.serviceUnavailable);
      break;
    case 500:
    default:
      setError(errorMessages.generalError);
      break;
  }
};

export default processStatusError;

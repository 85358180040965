import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    position: 'fixed',
    inset: '64px 0 0 0',
    background: '#000000a0',
    zIndex: '100',
    overflow: 'auto',
    '& .react-pdf__Document': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: 'calc(100vh - 64px)',
      justifyContent: 'center',
      gap: '3rem',
      padding: '1rem 0 4rem 0',
    },

    colorScheme: 'dark',
  },
});

export default useSx;

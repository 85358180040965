import React from 'react';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { Tooltip } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import If from '@components/If';
import { BackButtonProps } from './BackButtonProps';
import useSx from './sx';

const BackButton = ({ collapsed, handleDrawerToggle }: BackButtonProps) => {
  const navigate = useNavigate();

  const sx = useSx();

  const handleNavigate = () => {
    navigate(`/`);
    if (handleDrawerToggle) {
      handleDrawerToggle();
    }
  };

  return (
    <ListItem onClick={handleNavigate} sx={sx.root} disablePadding>
      <ListItemButton>
        <If
          condition={!collapsed}
          fallback={
            <Tooltip title="Inicio">
              <ListItemIcon sx={sx.icon}>
                <HomeIcon />
              </ListItemIcon>
            </Tooltip>
          }
        >
          <ListItemIcon sx={sx.icon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </If>
      </ListItemButton>
    </ListItem>
  );
};

export default BackButton;

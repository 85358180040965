import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  tableCell: { display: 'flex', flexDirection: 'row', gap: '.5rem' },
  deleteIcon: {
    cursor: 'pointer',
    alignSelf: 'center',
    marginLeft: 'auto',
    color: (theme) => theme.palette.error.main,
  },
});

export default useSx;

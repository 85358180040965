import { useMutation } from '@tanstack/react-query';
import { addReport } from '../api/addReport';

const useUploadReport = (studyInstanceUid: string) => {
  const { isLoading: isUploading, mutateAsync: uploadReport } = useMutation((report: any) =>
    addReport(report, studyInstanceUid),
  );

  return { isUploading, uploadReport };
};

export default useUploadReport;

function getPageInfo(actualPage: number, itemsPorPagina: number, totalItems: number) {
  const inferiorLimit = (actualPage - 1) * itemsPorPagina + 1;
  const superiorLimit = Math.min(actualPage * itemsPorPagina, totalItems);

  const adjustedSuperiorLimit = inferiorLimit > totalItems ? totalItems : superiorLimit;

  const message = `Mostrando del ${inferiorLimit} al ${adjustedSuperiorLimit} de ${totalItems} elementos`;

  return message;
}

export default getPageInfo;

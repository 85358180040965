import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UsageResponse } from '@core/types';
import { getUsage } from '../api/getUsage';

const QUERY_KEY = 'usage';

/* eslint-disable @typescript-eslint/indent */
export type UseFetchPlansResult = Omit<
  UseQueryResult<UsageResponse, any>,
  'data'
>
/* eslint-enable @typescript-eslint/indent */

const useFetchUsage = (): any => {
  const { data: usage = {}, ...rest } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => getUsage(),
    staleTime: 1000 * 60 * 60 * 12, // 12 hours
    cacheTime: 1000 * 60 * 60 * 12.5, // 12.5 hours
  });

  return { usage, ...rest };
};

export default useFetchUsage;

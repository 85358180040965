import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import If from '@components/If';
import useStudyInfo from '@core/hooks/useStudyInfo';
import { CustomError, EmailFormRequest } from '@core/types';
import useShareByEmail from 'app/features/study/hooks/useShareByEmail';
import useAttachReport from '../../../hooks/useAttachReport';
import ManagerComponentProps from '../../../types/ManagerComponentProps';
import Actions from './Actions';
import DragDropHandler from './DragDropHandler';
import SelectedFileDisplay from './SelectedFileDisplay';
import processStatusError from './processStatusCode';

function informUploadSuccess() {
  const uploadedEvent = new CustomEvent('evodicom.report.upload');
  document.dispatchEvent(uploadedEvent);
}

const ReportManager = ({ studyInstanceUid, onClose }: ManagerComponentProps) => {
  const { share, isSharing } = useShareByEmail(studyInstanceUid);
  const { studyInfo } = useStudyInfo({ studyInstanceUidOrHash: studyInstanceUid });
  const [droppedFile, setDroppedFile] = useState<File | undefined>();
  const [error, setError] = useState<CustomError | undefined>();
  const { isUploading, uploadReport } = useAttachReport(studyInstanceUid ?? '');

  if (!studyInstanceUid) {
    setError({
      message: 'No podemos adjuntar su reporte debido a que detectamos un estudio no válido',
      title: '',
      code: 'none',
    });
  }

  const reset = () => setError(undefined);

  const handleClear = () => setDroppedFile(undefined);

  const handleFileSelected = (file?: File) => {
    setDroppedFile(file);
    reset();
  };

  const handleShareByEmail = async () => {
    try {
      const req = {
        body: '',
        email: studyInfo?.patientsEmail,
        name: studyInfo?.patientsFullName,
      };

      await share(req as EmailFormRequest);
    } catch (e: any) {
      console.error(e);
    }
  };

  const handleFileSave = async () => {
    if (!droppedFile) {
      setError({
        message: 'Por favor seleccione un archivo primero',
        title: '',
        code: 'none',
      });

      return;
    }

    try {
      setError(undefined);
      const uploadReportResponse = await uploadReport(droppedFile);
      if (uploadReportResponse.success) {
        await handleShareByEmail();
        informUploadSuccess();
        onClose?.();
      }
    } catch (err: any) {
      processStatusError(err, setError);
    }
  };

  return (
    <Stack className="ReportManager-root" sx={{ flexGrow: 1 }}>
      <DragDropHandler
        error={error}
        onFileSelected={handleFileSelected}
        onFileSelecting={() => reset()}
        onFileRejected={(err) => setError(err)}
      />
      <If condition={!!droppedFile}>
        <SelectedFileDisplay
          fileInfo={droppedFile!}
          isUploading={isUploading || isSharing}
          onClear={handleClear}
        />
      </If>
      <Actions
        onAccept={handleFileSave}
        onCancel={onClose}
        disableAccept={!droppedFile || isUploading || isSharing}
      />
    </Stack>
  );
};

export default ReportManager;

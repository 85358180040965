import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    display: {xs: 'flex', sm: 'none'},
    alignItems: 'center',
    mx: '1rem',
    height: 'fit-content',
  },
  icon: {
    fontSize: '2rem',
    color: (theme) => theme.palette.shade?.[900],
  }
});

export default useSx;

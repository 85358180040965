import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Paper, Box, Typography, IconButton } from '@mui/material';
import If from '@components/If';
import { ThemeDetails, ThemeType } from '@core/types/Theme';
import useSx from './sx';

type ThemePreviewProps = {
  theme: ThemeDetails;
  currentTheme: ThemeType;
  onClick: () => void;
  onEditClick: (event: React.MouseEvent<HTMLElement>) => void;
};

const ThemePreview: React.FC<ThemePreviewProps> = ({ theme, currentTheme, onClick, onEditClick }) => {
  const sx = useSx(theme, currentTheme);

  const parseThemeName = (themeName: string | undefined) => {
    if (themeName?.includes('custom')) {
      return 'Custom'
    } else {
      return 'Evodicom'
    }
  }

  return (
    <Box sx={sx.root}>
      <If condition={!theme?.name?.includes('evodicom')}>
        <IconButton
          size="small"
          onClick={onEditClick}
          sx={sx.editButton}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </If>
      <Paper 
        onClick={onClick}
        sx={sx.paper}
      />
      <Typography>{parseThemeName(theme?.name)}</Typography>
    </Box>
  );
};

export default ThemePreview;
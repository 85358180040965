import { useMutation } from '@tanstack/react-query';
import { attachReport } from '../api/attachReport';

const useAttachReport = (studyInstanceUid: string) => {
  const { isLoading: isUploading, mutateAsync: uploadReport } = useMutation({
    mutationFn: (file: File) => attachReport(file, studyInstanceUid),
  });

  return { isUploading, uploadReport };
};

export default useAttachReport;

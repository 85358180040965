import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  foldersView: {
    background: (theme) => theme.palette.shade?.[200],
    borderRadius: '8px',
    overflow: 'hidden',
    marginBottom: {
      xs: '100px',
      md: 0,
    },
  },
  foldersViewSmall: {
    height: {
      xs: 'unset',
      md: '400px',
    },
    background: (theme) => theme.palette.shade?.[50],
    borderRadius: '8px',
    overflowY: 'auto',
    marginBottom: {
      xs: '100px',
      md: 0,
    },
  },
  rowContent: {
    display: {
      xs: 'grid',
      md: 'flex',
    },
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    alignItems: 'center',
    minHeight: '55px',
    padding: '1rem',
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    gap: '.5rem 1rem',
    cursor: 'pointer',
    '&:hover': {
      background: (theme) => theme.palette.shade?.[100],
    },
    gridTemplateColumns: 'auto 1fr auto',
  },
  templateName: {
    flex: 1,
    gridColumn: '2',
  },
  commandsContainer: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: { xs: 'auto 150px', md: 'auto 200px' },
    gap: '1rem',
  },
  searchInput: {
    width: '100%',
  },
});

export default useSx;

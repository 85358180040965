import { get } from '@core/api/baseApi';
import { GetSharedStudyRecords } from '@core/types';

const getSharedStudyRecords = async (
  studyInstanceUid: string,
  params: { pageSize: number; page: number },
): Promise<GetSharedStudyRecords> =>
  await get(`/Studies/shared-records/${studyInstanceUid}`, { params });

export default getSharedStudyRecords;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: (theme) => ({
    p: 12,
    width: '95%',

    '& img': {
      ml: '-5px',
      height: '40px',
      width: 'auto',
    },

    '& p.MuiTypography-h5': {
      mt: 2,
      fontWeight: 500,
    },

    '& ul': {
      mt: 4,
    },

    [theme.breakpoints.up('xs')]: {
      maxWidth: '450px',
    },
  }),
});

export default useSx;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  list: {
    zIndex: '9001 !important',
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'space-between !important',
  },
  templateDropdown: {
    '& .MuiInputLabel-root': {
      top: '-7px',
    },
    '& .MuiOutlinedInput-root': {
      paddingY: 0,
    },
  },
  category: {
    textAlign: 'left',
    fontSize: '0.8rem',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontStyle: 'italic',
    paddingRight: '10px',
  },
});

export default useSx;

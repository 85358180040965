import React from 'react';

const useSettingsLayoutState = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = React.useState<boolean>(true);

  return { mobileOpen, setMobileOpen, sidebarCollapsed, setSidebarCollapsed };
};

export default useSettingsLayoutState;

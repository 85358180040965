import { Dayjs } from 'dayjs';
import { post } from '@core/api/dicomApi';

interface StudyData {
  patientsGivenName?: string;
  patientsSurname?: string;
  patientsBirthDate?: Dayjs;
  patientsAge?: string;
  patientsGender?: string;
  studyDescription?: string;
  referringPhysiciansName?: string;
}

const updateStudy = async (studyInstanceUid: string, payload: StudyData): Promise<void> => {
  const formatedDate = payload?.patientsBirthDate?.isValid()
    ? payload.patientsBirthDate.format('YYYYMMDD')
    : undefined;

  const formattedDicomData = {
    '00100010': {
      vr: 'PN',
      Value: [
        {
          Alphabetic: `${payload.patientsGivenName} ${payload.patientsSurname}`,
        },
      ],
    },
    '00101010': {
      vr: 'AS',
      Value: [payload.patientsAge],
    },
    '00100040': {
      vr: 'CS',
      Value: [payload.patientsGender],
    },
    '00081030': {
      vr: 'LO',
      Value: [payload.studyDescription],
    },
    '00080090': {
      vr: 'PN',
      Value: [
        {
          Alphabetic: payload.referringPhysiciansName,
        },
      ],
    },
    '00100030': {
      vr: 'DA',
      Value: [formatedDate],
    },
  };

  await post(`/studies/$bulkUpdate`, {
    studyInstanceUids: [studyInstanceUid],
    changeDataset: formattedDicomData,
  });
};
export default updateStudy;

import React from 'react';
import { Box, Pagination } from '@mui/material';
import If from '@components/If';
import useSnackbar from '@core/hooks/useSnackbar';
import useGetStudyComments from 'app/features/study/hooks/useGetStudyComments';
import CommentForm from './CommentForm';
import CommentList from './CommentList';
import DeleteCommentDialog from './DeleteCommentDialog';
import useSx from './sx';
import useCommentState, { initialState } from './useCommentState';

const Comments = ({ studyInstanceUid, open, onClose, disablePagination = false }: any) => {
  const sx = useSx();
  const showSnackbar = useSnackbar();
  const {
    deleteStudyCommentAsync,
    comment,
    setComment,
    setPage,
    userUid,
    page,
    setShowDeleteDialog,
    showDeleteDialog,
    isDeleting,
    isAdding,
    isUpdating,
  } = useCommentState();

  const commentsParams = disablePagination
    ? { studyInstanceUid }
    : {
        studyInstanceUid,
        page,
        pageSize: '5',
        sortOrder: 'DESC',
      };

  const { studyComments, isLoading: isCommentsLoading } = useGetStudyComments(commentsParams, open);

  const onStudyCommentDelete = async () => {
    if (comment.commentId) {
      const response = await deleteStudyCommentAsync(comment.commentId);
      const message = !response.success
        ? 'No fue posible eliminar el comentario.'
        : 'El comentario ha sido eliminado con éxito.';

      const title = !response.success ? 'Ocurrio un error' : 'Comentario elminado';
      showSnackbar({
        type: response.success ? 'success' : 'error',
        message,
        title,
      });

      if (response.success) {
        setComment({ ...initialState });
        setShowDeleteDialog(false);
      }
    }
  };

  const disabledActions = isUpdating || isAdding || isDeleting;

  return (
    <>
      <Box display="flex" flexDirection="column" gap="10px" width="100%">
        <CommentForm
          setComment={setComment}
          onClose={onClose}
          comment={comment}
          disabledActions={disabledActions}
          studyInstanceUid={studyInstanceUid}
        />
        <CommentList
          studyComments={studyComments?.items}
          setComment={setComment}
          isLoading={isCommentsLoading}
          userUid={userUid}
          disabledActions={disabledActions}
          onDelete={(commentProp) => {
            setComment(commentProp);
            setShowDeleteDialog(true);
          }}
        />
        <If condition={(studyComments?.pageCount || 0) > 1 && !isCommentsLoading}>
          <Pagination
            sx={sx.pagination}
            count={studyComments?.pageCount}
            color="primary"
            page={studyComments?.page || 1}
            onChange={(_, selectedPage) => {
              setPage(selectedPage.toString());
            }}
          />
        </If>
      </Box>
      <DeleteCommentDialog
        open={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
        }}
        onDelete={onStudyCommentDelete}
      />
    </>
  );
};

export default Comments;

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import { Box } from '@mui/material';
import Section from '@components/Section';
import AddTemplateAction from './AddTemplateAction/AddTemplateAction';
import ReportTemplates from './ReportTemplates';
import useReportsCustomizationState from './useReportsCustomizationState';

const ReportsCustomization: React.FC = () => {
  const { sx } = useReportsCustomizationState();

  return (
    <Box>
      <Section title="Personalizar presentación" IconComponent={DescriptionIcon}>
        <Box sx={sx.root}>
          <AddTemplateAction />
          <ReportTemplates />
        </Box>
      </Section>
    </Box>
  );
};

export default ReportsCustomization;

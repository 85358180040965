import { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const useViewportSize = () => {
  // Standard MUI Breakpoints
  const isXSmallViewport = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs')); // <= 0px (mainly for reference)
  const isSmallViewport = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm')); // <= 600px
  const isMediumViewport = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md')); // <= 960px
  const isLargeViewport = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg')); // <= 1280px
  const isXLargeViewport = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xl')); // <= 1920px

  // Up Breakpoints (Starting from a particular breakpoint and up)
  const isUpMedium = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md')); // >= 960px
  const isUpLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg')); // >= 1280px
  const isUpXLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl')); // >= 1920px

  return {
    isXSmallViewport,
    isSmallViewport,
    isMediumViewport,
    isLargeViewport,
    isXLargeViewport,
    isUpMedium,
    isUpLarge,
    isUpXLarge,
  };
};

export default useViewportSize;

import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import If from '@components/If';
import UserAvatar from '@components/UserAvatar';
import { CommentProps } from './CommentProps';

const Comment = ({ setComment, userUid, onDelete, comment, disabledActions }: CommentProps) => {
  const hasBeingEdited = comment.createdDate !== comment.modifiedDate;

  const editedText = hasBeingEdited ? '(editado)' : '';

  const secondaryText =
    new Date(comment.modifiedDate).toLocaleDateString('en-GB') + ' ' + editedText;

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <UserAvatar
          alt={comment.commenter}
          userProfilePhoto={comment.profilePhoto}
          userFullName={comment.commenter}
        />
      </ListItemAvatar>
      <Box flex="1">
        <div dangerouslySetInnerHTML={{ __html: comment.content }}></div>
        <Box display="flex" alignItems="center" justifyContent="space-between" gap="10px">
          <Box>
            <Typography component="span" variant="body2">
              {comment.commenter}{' '}
            </Typography>
            <Typography component="span" variant="body2">
              {secondaryText}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap="10px">
            <If condition={userUid === comment.createdBy && !disabledActions}>
              <IconButton
                onClick={() => {
                  setComment(comment);
                }}
                edge="end"
                aria-label="comments"
              >
                <EditIcon />
              </IconButton>
            </If>
            <If condition={userUid === comment.createdBy && !disabledActions}>
              <IconButton
                onClick={() => {
                  onDelete(comment);
                }}
                edge="end"
                aria-label="comments"
              >
                <DeleteIcon sx={{ color: (theme) => theme.palette.error.main }} />
              </IconButton>
            </If>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};

export default Comment;

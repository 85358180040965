import React, { useEffect, useState } from 'react';
import ViewerDialog from '@components/ViewerDialog';
import useOrganizationTemplate from '@core/hooks/useOrganizationTemplate';
import useSnackbar from '@core/hooks/useSnackbar';
import { OrganizationTemplateModel } from '@core/types/OrganizationTemplate';
import createFileFromUrl from '@core/utils/createFileFromUrl';
import PDFPreview from '../../PDFPreview';
import useSx from './sx';

const Viewer = ({
  template,
  open,
  onClose,
}: {
  template: OrganizationTemplateModel;
  open: boolean;
  onClose: VoidFunction;
}) => {
  const sx = useSx();
  const viewerTitle = template.title || 'Sin titulo';
  const [file, setFile] = useState<File | null>(null);
  const showSnackbar = useSnackbar();

  const { organizationTemplate } = useOrganizationTemplate(template.templateId);

  const onLoadError = () => {
    showSnackbar({
      type: 'error',
      title: 'Error',
      message: 'No se pudo cargar el documento.',
    });
  };

  useEffect(() => {
    const updateFile = async () => {
      if (organizationTemplate?.templateUri) {
        const tempFile = await createFileFromUrl(
          organizationTemplate?.templateUri || '',
          template.fileName,
          {
            type: 'image/jpeg',
          },
        );
        setFile(tempFile);
      }
    };
    updateFile();
  }, [organizationTemplate?.templateUri, template.fileName]);

  return (
    <ViewerDialog title={viewerTitle} sx={sx.root} onClose={onClose} open={open}>
      <PDFPreview height={500} file={file} onSuccess={() => {}} onError={onLoadError} />
    </ViewerDialog>
  );
};

export default Viewer;

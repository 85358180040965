import { post } from '@core/api/baseApi';

export const UPLOAD_REPORT_API_ERROR_CODE = {
  duplicateReport: 'reporte_duplicado',
  emptyReport: 'archivo_vacio',
  invalidId: 'studyinstanceuid_invalido',
  invalidDataType: 'formato_invalido',
};

export const attachReport = async (
  file: File,
  studyInstanceUid: string,
): Promise<{ success: boolean }> => {
  const payload = new FormData();
  payload.append('file', file);

  return await post(`/studies/${studyInstanceUid}/attach-report`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

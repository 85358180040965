import { Prompt } from '@core/providers/ConfirmActionProvider';
import deleteReportFromEvodicomService from '../../api/deleteReportFromEvodicomService';
import deleteSeries from '../../api/deleteSeries';
import deleteStudyFromDicomService from '../../api/deleteStudy';
import deleteStudyFromService from '../../api/deleteStudyFromService';

const deleteSeriesHandler = async (
  displaySet: {
    StudyInstanceUID: string;
    SeriesInstanceUID: string;
    Modality: string;
    SOPInstanceUID: string;
  },
  existingSeriesCount: number,
  reportError: (message: string) => void,
  confirm: (value: Prompt) => Promise<boolean>,
): Promise<boolean> => {
  const onlyDeleteSeries = existingSeriesCount > 1;
  const {
    StudyInstanceUID: studyUid,
    SeriesInstanceUID: seriesUid,
    Modality,
    SOPInstanceUID,
  } = displaySet;

  const title = onlyDeleteSeries
    ? '¿Estás seguro que deseas borrar la serie?'
    : 'Al borrar esta serie se borrará todo el estudio, ¿estás seguro que deseas borrar el estudio y la serie?';

  const shouldDeleteSeries = await confirm({
    type: 'warning',
    title,
  });

  if (!shouldDeleteSeries) {
    return false;
  }

  try {
    // DicomService
    await deleteSeries(studyUid, seriesUid);

    if (Modality === 'DOC') {
      await deleteReportFromEvodicomService(studyUid, SOPInstanceUID);
    }

    if (!onlyDeleteSeries) {
      await deleteStudyFromDicomService(studyUid);
      await deleteStudyFromService(studyUid);
    }
  } catch (exception) {
    reportError('Hubo un error al borrar la serie. Favor de intentar de nuevo.');
    console.error(`Error while deleting the series: ${seriesUid}`);
    console.error(exception);
    return false;
  }

  return true;
};

export default deleteSeriesHandler;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        flex: 1,
        borderRadius: 25,
        width: {xs: '100%', md: '80%'},
        maxWidth: {xs: '100%', md: '80%'},
        border: 'none',
        '.MuiSelect-select': {
            paddingY: '9px',
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: 'none'
        },
        '::before': {
            borderBottom: 'none'
        },
        '::after': {
            borderBottom: 'none'
        }
    }
});

export default useSx;

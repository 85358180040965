import { useMutation } from '@tanstack/react-query';
import changePassword, { ChangePasswordRequest } from '../api/changePassword';

const CHANGE_PASSWORD_MUTATION_KEY = ['userChangePassword'];

const useChangePassword = () => {
  const { mutateAsync: changeUserPassword, ...rest } = useMutation({
    mutationKey: CHANGE_PASSWORD_MUTATION_KEY,
    mutationFn: (requestData: ChangePasswordRequest) => changePassword(requestData)
  });

  return { changeUserPassword, ...rest };
};

export default useChangePassword;
import { useCallback, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import accessManager, { OrganizationInfo } from '@core/utils/productAccessManager';
import useUserUid from './useUserUid';

export type { OrganizationInfo } from '@core/utils/productAccessManager';

export type UseOrganizationId = {
  organization: OrganizationInfo;
  setOrganization: (newOrg: OrganizationInfo) => void;
  isAcquiring: boolean;
  isError: boolean;
  error: any;
};

let isFetching = false; // internal flag to orchestrate multiple fetching operations

const useOrganization = (): UseOrganizationId => {
  const userUid = useUserUid();
  const [organization, setOrg] = useState(() => accessManager.getOrganization(userUid));
  // set acquiring to true to indicate an immediate acquiring process of product token
  const [isAcquiring, setIsAcquiring] = useState(true);
  const [error, setError] = useState<any>();
  const { instance } = useMsal();

  useEffect(() => {
    setOrg(accessManager.getOrganization(userUid));
  }, [userUid]);

  useEffect(() => {
    async function getToken() {
      if (organization && !isFetching) {
        setIsAcquiring(true);
        isFetching = true;

        try {
          const accessToken = await accessManager.acquireProductTokenSilent();
          if (!accessToken) {
            accessManager.clearUserSession(userUid);
            instance.logoutRedirect();
          }
        } catch (err: any) {
          console.error(err);
          setError(err);
        } finally {
          setIsAcquiring(false);
          isFetching = false;
        }
      }
    }

    getToken();
  }, [organization, userUid, instance]);

  const setOrganization = useCallback(
    (newOrg: OrganizationInfo) => {
      accessManager.removeOrganization(userUid);
      accessManager.setOrganization(newOrg, userUid);
      setOrg(newOrg);
      setIsAcquiring(true);
    },
    [userUid],
  );

  return {
    error,
    isError: !error,
    isAcquiring,
    organization: organization ?? {},
    setOrganization,
  };
};

export default useOrganization;

import dayjs from 'dayjs';

const calculateTimeBetweenDates = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  const diffInYears = endDate.diff(startDate, 'years');

  const diffInMonths = endDate.diff(startDate, 'month');
  const diffInWeeks = endDate.diff(startDate, 'week');
  const diffInDays = endDate.diff(startDate, 'day');
  const diffInHours = endDate.diff(startDate, 'hour');
  const diffInMinutes = endDate.diff(startDate, 'minute');

  return { diffInMonths, diffInWeeks, diffInDays, diffInHours, diffInMinutes, diffInYears };
};

export default calculateTimeBetweenDates;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    '& .MuiPaper-root': {
      backgroundColor: (theme) => theme.palette.shade?.[50],
      backgroundImage: 'none',
    },
    '& .MuiMenu-list': {
      minWidth: '14rem',
      maxWidth: '18rem',
      marginBottom: '1px',
    },
    '& .ListItemTitle': { whiteSpace: 'normal', '& span': { fontWeight: '900' } },
    '& .ListItemSubtitle': { whiteSpace: 'normal' },
    '& .ListItemDate': { '& span': { fontSize: '0.7rem' } },
    '& .ListItemCloseIcon': { position: 'absolute', top: '5px', right: '5px' },
    '& .MenuItem': { posiiton: 'relative', '&:hover': { borderRadius: '10px' } },
  },
});

export default useSx;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => {
  return {
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      gap: '20px',
      '.textfield': { '.MuiInputBase-root': { height: 'auto', textarea: { padding: '15px 0' } } },
      '.RecordingButton': { marginLeft: 'auto' },
      '.circular-progress': {
        position: 'absolute',
        left: '50%',
        height: '30px !important',
        width: '30px !important',
      },
    },
  };
};

export default useSx;

import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '../Drawer';
import Header from './Header';
import { MainLayoutProps } from './MainLayoutProps';
import useSx from './sx';
import useMainLayoutState from './useMainLayoutState';

const MainLayout = ({ children, sidebar, onLogin }: MainLayoutProps) => {
  const { mobileOpen, setMobileOpen, sidebarCollapsed, setSidebarCollapsed } = useMainLayoutState();
  const sx = useSx(!!sidebar, sidebarCollapsed);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSidebarCollapseToggle = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <Box sx={sx.root}>
      <Header handleMenuClick={handleDrawerToggle} sidebar={sidebar} onLogin={onLogin} />
      <Box className="main-drawer-container">
        <Drawer
          handleDrawerToggle={handleDrawerToggle}
          isOpen={mobileOpen}
          profile={sidebar}
          handleSidebarCollapseToggle={handleSidebarCollapseToggle}
          sidebarCollapsed={sidebarCollapsed}
        />
      </Box>
      <Box className={sidebar ? 'main-container' : ''}>{children}</Box>
    </Box>
  );
};

export default MainLayout;

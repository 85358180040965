import React from 'react';
import LinkIcon from '@mui/icons-material/Link';
import Box from '@mui/material/Box';
import If from '@components/If';
import useSnackbar from '@core/hooks/useSnackbar';
import { GetStudyResponse } from '@core/types/ApiResponses';
import ShareCode from './ShareCode';
import ShareLink from './ShareLink';
import useSx from './sx';

export interface ShareLinksProps {
  studyInfo: GetStudyResponse | undefined;
  isLoading: boolean;
}

const ShareLinks = ({ studyInfo, isLoading }: ShareLinksProps) => {
  const showSnackbar = useSnackbar();

  const sx = useSx();
  const { shareUrl } = studyInfo ?? {};

  const handleCopyLinkClick = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl || '');
      showSnackbar({
        title: 'Copiado',
        message: 'Enlace copiado al portapapeles',
      });
    } catch (error) {
      showSnackbar({
        title: 'Ocurrió un error',
        message: 'No pudimos copiar el enlace al portapeles. Verifique el permiso del navegador.',
        type: 'error',
      });
    }
  };

  return (
    <If condition={!isLoading}>
      <Box sx={sx.root}>
        <Box sx={sx.qr}>
          <ShareCode shareUrl={shareUrl} />
        </Box>
        <Box className="ShareLinks-linkButtons">
          <ShareLink label="Copiar enlace" onClick={handleCopyLinkClick}>
            <LinkIcon />
          </ShareLink>
        </Box>  
      </Box>
    </If>
  );
};

export default ShareLinks;

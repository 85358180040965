const QrPlaceholderBase64 = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDguNTkgMTAyLjQ4Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjOTU5NDk0OwogICAgICAgIGZvbnQtZmFtaWx5OiBSb2JvdG8tUmVndWxhciwgUm9ib3RvOwogICAgICAgIGZvbnQtc2l6ZTogMTlweDsKICAgICAgfQoKICAgICAgLmNscy0yIHsKICAgICAgICBmaWxsOiBub25lOwogICAgICAgIHN0cm9rZTogIzZiNmQ3MDsKICAgICAgICBzdHJva2UtbWl0ZXJsaW1pdDogMTA7CiAgICAgICAgc3Ryb2tlLXdpZHRoOiAycHg7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxyZWN0IGNsYXNzPSJjbHMtMiIgeD0iMSIgeT0iMSIgd2lkdGg9IjEwNi41OSIgaGVpZ2h0PSIxMDAuNDgiIHJ4PSIxMy41NyIgcnk9IjEzLjU3Ii8+CiAgPHRleHQgY2xhc3M9ImNscy0xIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMy45NyA0OC45KSI+PHRzcGFuIHg9IjAiIHk9IjAiPkPDs2RpZ28gPC90c3Bhbj48dHNwYW4geD0iMTkuOTgiIHk9IjIyLjgiPlFSPC90c3Bhbj48L3RleHQ+Cjwvc3ZnPg==`;

const SignaturePlaceholderBase64 = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDguNTkgMTAyLjQ4Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjOTU5NDk0OwogICAgICAgIGZvbnQtZmFtaWx5OiBSb2JvdG8tUmVndWxhciwgUm9ib3RvOwogICAgICAgIGZvbnQtc2l6ZTogMTlweDsKICAgICAgfQoKICAgICAgLmNscy0yIHsKICAgICAgICBmaWxsOiBub25lOwogICAgICAgIHN0cm9rZTogIzZiNmQ3MDsKICAgICAgICBzdHJva2UtbWl0ZXJsaW1pdDogMTA7CiAgICAgICAgc3Ryb2tlLXdpZHRoOiAycHg7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxyZWN0IGNsYXNzPSJjbHMtMiIgeD0iMSIgeT0iMSIgd2lkdGg9IjEwNi41OSIgaGVpZ2h0PSIxMDAuNDgiIHJ4PSIxMy41NyIgcnk9IjEzLjU3Ii8+CiAgPHRleHQgY2xhc3M9ImNscy0xIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS4zMyA1Ni45KSI+PHRzcGFuIHg9IjAiIHk9IjAiPkZpcm1hPC90c3Bhbj48L3RleHQ+Cjwvc3ZnPg==`;

const ProfessionalInfoPlaceholderBase64 = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNDIgODAiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICM5NTk0OTQ7CiAgICAgICAgZm9udC1mYW1pbHk6IFJvYm90by1SZWd1bGFyLCBSb2JvdG87CiAgICAgICAgZm9udC1zaXplOiAxOXB4OwogICAgICB9CgogICAgICAuY2xzLTIgewogICAgICAgIGZpbGw6IG5vbmU7CiAgICAgICAgc3Ryb2tlOiAjNmI2ZDcwOwogICAgICAgIHN0cm9rZS1taXRlcmxpbWl0OiAxMDsKICAgICAgICBzdHJva2Utd2lkdGg6IDJweDsKICAgICAgfQoKICAgICAgLmNscy0zIHsKICAgICAgICBsZXR0ZXItc3BhY2luZzogMGVtOwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8cmVjdCBjbGFzcz0iY2xzLTIiIHg9IjEiIHk9IjEiIHdpZHRoPSIxNDAiIGhlaWdodD0iNzgiIHJ4PSIxMy41NyIgcnk9IjEzLjU3Ii8+CiAgPHRleHQgY2xhc3M9ImNscy0xIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxOC4xNyAzNi45MikiPjx0c3BhbiB4PSIwIiB5PSIwIj5JbmZvcm1hY2nDs24gPC90c3Bhbj48dHNwYW4geD0iMCIgeT0iMjIuOCI+UDwvdHNwYW4+PHRzcGFuIGNsYXNzPSJjbHMtMyIgeD0iMTEuOTkiIHk9IjIyLjgiPnI8L3RzcGFuPjx0c3BhbiB4PSIxOC4yMyIgeT0iMjIuOCI+b2Zlc2lvbmFsPC90c3Bhbj48L3RleHQ+Cjwvc3ZnPg==`;

function qrPlaceholder() {
  const div = document.createElement('div');
  const a = document.createElement('a');
  const img = document.createElement('img');
  img.className = 'QrPlaceholder';
  img.src = QrPlaceholderBase64;
  img.style.width = '100px';
  img.style.height = '100px';

  a.className = 'QrPlaceholderLink';
  a.href = process.env.REACT_APP_ADB2C_REDIRECT_URI!;
  a.appendChild(img);
  div.appendChild(a);
  return div;
}

function signaturePlaceholder() {
  const div = document.createElement('div');
  const img = document.createElement('img');
  img.className = 'SignaturePlaceholder';
  img.src = SignaturePlaceholderBase64;
  img.style.height = '70px';
  img.style.objectFit = 'contain';
  div.appendChild(img);
  return div;
}

function professionalInfoPlaceholder() {
  const div = document.createElement('div');
  const img = document.createElement('img');
  img.className = 'ProfessionalInfoPlaceholder';
  img.src = ProfessionalInfoPlaceholderBase64;
  img.style.width = '120px';
  img.style.height = '70px';
  div.appendChild(img);
  return div;
}

export { qrPlaceholder, signaturePlaceholder, professionalInfoPlaceholder };

import { useMutation, useQueryClient } from '@tanstack/react-query';
import revokeRadiologistStudyAccess from '../api/revokeRadiologistStudyAccess';
import { QUERY_KEY as ASSIGNED_RADIOLOGISTS_LIST } from './useGetAssignedRadiologists';

export const QUERY_KEY = 'REVOKE_RADIOLOGIST';

const useRevokeRadiologistStudyAccess = () => {
    const queryClient = useQueryClient()
    const {  isLoading: isUploading, mutateAsync: revokeRadiologistAccess } = useMutation({
        mutationFn: ({studyInstanceUid, userUid}:{ studyInstanceUid: string, userUid: string}) => revokeRadiologistStudyAccess(studyInstanceUid, userUid),
        onSuccess: () => {
            queryClient.invalidateQueries([ASSIGNED_RADIOLOGISTS_LIST])
        }
    });

  return { revokeRadiologistAccess, isUploading };
};

export default useRevokeRadiologistStudyAccess;

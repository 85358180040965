import { post } from '@core/api/baseApi';

export const addFolder = (body: {
  folderName: string;
  folderPath: number[];
  isOrganizationWide: boolean;
}): Promise<{
  templateFolderId: string;
  templateFolderName: string;
  templateFolderParentId: string;
}> => {
  const formattedPayload = {
    folderName: body.folderName,
    parentFolder: body.folderPath.at(-1),
    isOrganizationWide: body.isOrganizationWide,
  };

  return post('/ReportTemplates/folder', formattedPayload);
};

import { put } from '@core/api/baseApi';
import { AddStudyCommentResponse } from '@core/types';

export type Payload = {
  studyInstanceUid: string;
  commenter: string;
  content: string;
  commentId: string;
};

export const updateStudyComment = ({
  commentId,
  ...comment
}: Payload): Promise<AddStudyCommentResponse> => put(`/studies/${commentId}/comments`, comment);

import React from 'react'
import Select from '@mui/material/Select'
import DropdownProps from './DropdownProps'
import useSx from './sx'

const Dropdown = ({sx: sxProp, ...props}: DropdownProps) => {
    const sx = useSx()

    const sxStyles = {...sx.root, ...sxProp}

    return (
        <Select
            sx={sxStyles} 
            {...props}
        />
    )
}

export default Dropdown
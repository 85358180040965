import React from 'react';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import useConfirm from '@core/hooks/useConfirm';

const ConfirmDialog = () => {
  const { prompt, isOpen = false, proceed, cancel } = useConfirm();

  return (
    <ActionDialog
      {...prompt}
      open={isOpen}
      onConfirm={proceed || (() => {})}
      onCancel={cancel || (() => {})}
      onClose={cancel || (() => {})}
    />
  );
};

export default ConfirmDialog;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    mb: 1,
    mt: 2,
    '.title-container': {
      display: 'flex',
      gap: (themeProp) => themeProp.spacing(2),
    },
  },
  children: { mt: 2 },
});

export default useSx;

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { GetPlanResponse } from '@core/types';
import { getPlans } from '../api/changeSuscription';

const QUERY_KEY = 'plans';

/* eslint-disable @typescript-eslint/indent */
export type UseFetchPlansResult = Omit<
  UseQueryResult<GetPlanResponse[], any>,
  'data'
> & { plans: GetPlanResponse[] };
/* eslint-enable @typescript-eslint/indent */

const useFetchPlans = (): UseFetchPlansResult => {
  const { data: plans = [], ...rest } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => getPlans(),
    staleTime: 1000 * 60 * 60 * 12, // 12 hours
    cacheTime: 1000 * 60 * 60 * 12.5, // 12.5 hours
  });

  return { plans, ...rest };
};

export default useFetchPlans;

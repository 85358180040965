import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    padding: 0,
    '.MuiPaper-root': {
      padding: 0,
    },
    '.table': {
      minWidth: '100%',
      marginBottom: 0,
    },
    marginBottom: '0',
    marginTop: '1rem',
  },
});

export default useSx;

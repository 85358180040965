import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    gap: '20px',
    '& .MuiBox-root': {
      alignItems: {
        xs: 'center',
        md: 'flex-start',
      },
    },
    '& .MuiFormLabel-root ': {
      minWidth: {
        xs: 'unset',
        md: '30%',
      },
    },
    '& .datepicker-container': {
      ...expandDisplayFlex({ a: 'center' }),
      gap: '5px',
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      '& > .MuiFormLabel-root ': { marginRight: '8px', top: '0' },
      '& .datepicker': {
        flex: 1,
        width: {
          xs: '100%',
          md: 'unset',
        },
      },
      '& .datepicker-mobile': {
        width: '100%',
      },
    },
    '& .phone-number': {
      display: 'grid',
      gridTemplateColumns: '30% 1fr',
      gap: '20px',
      flex: '1',
      width: {
        xs: '100%',
        md: 'unset',
      },
      '& > *': {
        width: '100%',
      },
    },
    '& .viewer-textfield': {
      '& .MuiInputBase-root': {
        pl: 0,
        backgroundColor: 'transparent',
        height: 'auto',
        borderRadius: '0',
        gap: '5px',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },

        '& .MuiInputAdornment-root ': {
          minWidth: '30%',
          height: 'unset',
          justifyContent: {
            xs: 'center',
            md: 'unset',
          },
        },

        '& .MuiInputBase-input': {
          backgroundColor: (theme) => theme.palette.shade?.[400],
          padding: '10px',
          borderRadius: '20px',
          flex: 1,
          width: {
            xs: 'calc(100% - 20px)',
            md: '100%',
          },
        },
        fieldset: {
          borderBottomColor: 'rgb(49, 67, 84) !important',
          borderWidth: '0px 0px 1px',
        },

        ':hover': {
          fieldset: {
            borderBottomColor: 'rgb(49, 67, 84)',
          },
        },
      },
    },
  },
});

export default useSx;

import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Drawer from '../Drawer';
import Header from '../MainLayout/Header';
import useSx from './sx';
import useSettingsLayoutState from './useSettingsLayoutState';

const SettingsLayout = ({ children }: PropsWithChildren<unknown>) => {
  const { mobileOpen, setMobileOpen, sidebarCollapsed, setSidebarCollapsed } =
    useSettingsLayoutState();
  const sx = useSx(sidebarCollapsed);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSidebarCollapseToggle = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <Box sx={sx.root}>
      <Header handleMenuClick={handleDrawerToggle} sidebar />
      <Box className="container">
        <Box className="drawer-container">
          <Drawer
            handleDrawerToggle={handleDrawerToggle}
            isOpen={mobileOpen}
            handleSidebarCollapseToggle={handleSidebarCollapseToggle}
            sidebarCollapsed={sidebarCollapsed}
          />
        </Box>
        <Box className="main-container">
          <Box className="content-container">{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsLayout;

import { Theme } from '@mui/material';
import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const autofillStyle = (theme: Theme) => ({
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.shade?.[400]} inset`,
    '-webkit-text-fill-color': theme.palette.text.primary,
    transition: 'background-color 5000s ease-in-out 0s',
});

const useSx = (): SxDef => ({
    root: (theme) => ({
        ...expandDisplayFlex({a: 'center', j: 'space-between'}),
        gap: '15px', 
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            ...expandDisplayFlex({a: 'flex-start', d: 'column'}),
            gap: '10px' 
        },
        '& .MuiInputBase-input': {
            '&:-webkit-autofill': autofillStyle(theme),
            '&:-moz-autofill': autofillStyle(theme),
            '&:-o-autofill': autofillStyle(theme),
            '&:-ms-autofill': autofillStyle(theme),
          },
    })
});

export default useSx;

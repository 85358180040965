import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: (theme) => ({
    display: 'flex',
    p: theme.spacing(4, 3),

    '& .MuiAvatar-root': {
      bgcolor: 'secondary.main',
      color: 'primary.contrastText',

      '&.MuiAvatar-rounded': {
        borderRadius: 0.5,
      },
    },

    '& .MuiBox-root': {
      pl: 2,
    },
    '.organization-title': { color: theme.palette.shade?.[700] },
  }),
});

export default useSx;

import React from 'react'
import { useQuery } from '@tanstack/react-query';
import { getSolicitations } from '../api/getSolicitations';

export const GET_SOLICITATION_QUERY_KEY = "Solicitations"

const useSolicitations = (studyInstanceUid:string) => {
    const { data: solicitations, isLoading, ...rest } = useQuery({
        queryKey: [GET_SOLICITATION_QUERY_KEY, studyInstanceUid],
        queryFn: () => getSolicitations(studyInstanceUid),
    });

    return {
      solicitations: solicitations?.attachments,
      isLoading,
      ...rest
    }
}

export default useSolicitations;

import * as roosterjs from 'roosterjs';
import { getFormatState } from 'roosterjs-editor-api';
import { getAllowedFonts, isAllowedFont } from '@core/utils/fonts';

function coerceValidFont(node: Node, validFont: string) {
  if (node.nodeType !== Node.ELEMENT_NODE) {
    return;
  }
  const htmlNode = node as HTMLElement;
  if (htmlNode.style.fontFamily && !isAllowedFont(htmlNode.style.fontFamily)) {
    htmlNode.style.fontFamily = validFont;
  }
  for (const childNode of node.childNodes) {
    coerceValidFont(childNode, validFont);
  }
}

function checkForValidFont(changes: MutationRecord[], editor: roosterjs.Editor) {
  const currentFont =
    getFormatState(editor).fontName ?? // get the current font state from editor
    editor.getDefaultFormat().fontFamily ?? // or the default format for the editor
    (getAllowedFonts().next().value as string); // or just the first font from the allowed fonts
  for (const change of changes) {
    if (change.type === 'childList' && change.addedNodes.length > 0) {
      for (const addedNode of change.addedNodes) {
        coerceValidFont(addedNode, currentFont);
      }
    }
  }
}

export default checkForValidFont;

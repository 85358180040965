import React from 'react';
import Brightness2OutlinedIcon from '@mui/icons-material/Brightness2Outlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { Box, Typography } from '@mui/material';
import IconButton from '@components/Button/IconButton';
import useCurrentTheme from '@core/hooks/useCurrentTheme';
import ModePreviewProps from './ModePreviewProps';
import useSx from './sx';

const ModePreview = ({ mode }: ModePreviewProps) => {
  const { toggleThemeVariant, storedThemeVariant } = useCurrentTheme()
  const sx = useSx(mode, storedThemeVariant)

  const onClick = () => {
    toggleThemeVariant(mode)
  }

  const handleIcon = (variant: string) => {
    if (variant === 'Dark') {
        return <Brightness2OutlinedIcon />
    } else {
      return <LightModeOutlinedIcon />
    }
  }

  return (
    <Box sx={sx.root}>
      <IconButton 
        tooltip=""
        variant={(storedThemeVariant === mode || (storedThemeVariant === undefined && mode === 'Dark')) ? "contained" : "outlined"}
        onClick={onClick}
        sx={sx.iconButton}
      >
        {handleIcon(mode)}
      </IconButton>
      <Typography>{mode === 'Dark' ? 'Oscuro' : 'Claro'}</Typography>
    </Box>
  )
}

export default ModePreview
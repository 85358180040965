import React from 'react';
import { Box } from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import UserAutoComplete from '@components/UserAutoComplete';
import { RoleName } from '@core/types';
import userItemMapper from '@core/utils/userToUserItemMapper';
import AssignedTechnician from '../AssignedTechnician';
import { FormPropsWithNotification } from './FormProps';
import useFormState from './useFormState';

const Form = ({
  open = false,
  onClose,
  role,
  studyInstanceUid,
  studyInfo,
  handleSendNotification,
  isSendingNotification,
}: FormPropsWithNotification) => {
  const {
    assignTechnicianAsync,
    selectedTechnicianInput,
    setSelectedTechnicianInput,
    selectedTechnician,
    refetch,
    showSnackbar,
  } = useFormState({ isOpen: open, studyInstanceUid, studyInfo });
  const isSubmittingLoading = isSendingNotification;

  const handleAssignTechnician = async () => {
    const response = await assignTechnicianAsync({
      studyInstanceUid: studyInstanceUid || '',
      userUid: selectedTechnicianInput?.userUid || '',
    });
    if (!response.success) {
      showSnackbar({
        message: response.error || 'No fue posible asignar al técnico radiólogo.',
        title: 'Ocurrió un error',
        type: 'error',
      });
      return;
    }

    const selectedTechnicianItem = userItemMapper(selectedTechnicianInput);
    await handleSendNotification(studyInstanceUid, selectedTechnicianItem);

    refetch();
    showSnackbar({
      message: 'Técnico radiólogo asignado con éxito.',
      title: 'Técnico asignado',
      type: 'success',
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <AssignedTechnician assignedTechnician={selectedTechnician} />
      <If condition={!role || role !== RoleName.Patient}>
        <UserAutoComplete
          searchRoles="Technician"
          onUserSelect={setSelectedTechnicianInput}
          placeholder="Seleccionar técnico radiólogo"
        />
        <Box display="flex" gap="10px" justifyContent="flex-end">
          <If condition={!!onClose}>
            <ActionButton text="Cancelar" variant="outlined" color="secondary" onClick={onClose} />
          </If>
          <ActionButton
            text="Asignar Estudio"
            color="primary"
            variant="contained"
            onClick={handleAssignTechnician}
            isLoading={isSubmittingLoading}
          />
        </Box>
      </If>
    </Box>
  );
};

export default Form;

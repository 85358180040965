import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (sidebarCollapsed: boolean): SxDef => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        '.container': {
            display: 'flex',
        },
        '.drawer-container': { 
            height: 'calc(100% - 64px)',
            position: 'fixed',
            top: '64px',
            width: {
                xs: '0',
                sm: sidebarCollapsed ? '70px' : '200px'
            },
            transition: 'width 0.3s',
            backgroundColor: (theme) => theme.palette.primary.main 
        },
        '.main-container': {
            marginLeft: {xs: 0, sm: sidebarCollapsed ? '70px' : '200px'},
            transition: 'margin-left 0.3s', 
            flex: 1,  
            ...expandDisplayFlex({ d: 'column' })
        },
        '.content-container': {
            boxSizing: 'border-box',
            padding: {
                xs: '1rem',
                sm: '2rem'
            },
            maxWidth: '100vw',
            width: '100%',
            flex: 1,
            ...expandDisplayFlex({ d: 'column' }),
            '@media (min-width:1921px)': {
                maxWidth: '1536px',
                margin: '0 auto',
            },
        }
    }
});
export default useSx;

/* eslint-disable @typescript-eslint/ban-ts-comment */

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const replaceTemplateTags = (
  templateTextProp: string,
  variablesProp: Record<string, string>,
  getDicomValue: (dicomKey: string) => string,
) => {
  const wrapperHTML = document.createElement('div');
  wrapperHTML.innerHTML = templateTextProp;

  const nodes: Array<Element> = [];

  Object.keys(variablesProp).forEach((key) => {
    const sanitizedKey = escapeRegExp(key);
    const regex = new RegExp('{{\\s*' + sanitizedKey + '\\s*}}', 'g');
    const value = (variablesProp[key] || '').trim(); //
    if (value) {
      const newText = wrapperHTML.innerHTML.replace(regex, value);
      wrapperHTML.innerHTML = newText;
    }
  });

  Object.keys(variablesProp).forEach((key) => {
    if (typeof getDicomValue(key) === 'string') {
      const sanitizedKey = escapeRegExp(key);
      const selectedNode = wrapperHTML.querySelectorAll(`.${sanitizedKey}`);
      nodes.push(...selectedNode);
    }
  });

  nodes.forEach((node) => {
    if (node) {
      //@ts-ignore
      node.style.background = 'none';
      const keyIdentifier = node.className;
      if (!!variablesProp[keyIdentifier]) {
        node.innerHTML = variablesProp[keyIdentifier];
        node.className = '';
      } else {
        //@ts-ignore
        node.style.background = 'yellow';
      }
    }
  });

  return wrapperHTML.innerHTML;
};

export default replaceTemplateTags;

import { useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { AuthContext } from '@core/providers/AuthProvider';
import { UserOrganizationModel } from '@core/types';
import accessManager from '@core/utils/productAccessManager';

const useChangeOrganizations = () => {
  const { setOrganization } = useContext(AuthContext);

  const queryClient = useQueryClient();

  return ({
    userUid,
    organizationProductId,
    organizations,
  }: {
    userUid?: string;
    organizationProductId: string;
    organizations?: UserOrganizationModel[];
  }) => {
    setOrganization({
      organizationId: organizationProductId,
      hasMoreOrganizations: organizations && organizations?.length > 1,
    });
    queryClient.clear();
  };
};

export default useChangeOrganizations;

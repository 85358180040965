import { useQuery } from '@tanstack/react-query';
import getSharedStudyRecords from '../api/getSharedStudyRecords';

export const GET_SHARED_STUDY_RECORDS_QUERY_KEY = 'SharedStudyRecords';

const useGetSharedStudyRecords = (
  studyInstanceUid: string,
  filters: { pageSize: number; page: number },
  enabled: boolean,
) => {
  const { data: sharedStudyRecords, ...rest } = useQuery({
    queryKey: [
      GET_SHARED_STUDY_RECORDS_QUERY_KEY,
      studyInstanceUid,
      filters.page,
      filters.pageSize,
    ],
    queryFn: () => getSharedStudyRecords(studyInstanceUid, filters),
    enabled,
    retry: false,
  });

  return { ...rest, sharedStudyRecords };
};

export default useGetSharedStudyRecords;

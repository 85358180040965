import React from 'react';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FolderIcon from '@mui/icons-material/Folder';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import useFilters from 'app/features/templates/hooks/useFilters';
import useSx from './sx';

const SECTIONS = [
  { label: 'Recents', text: 'Recientes', icon: WatchLaterIcon },
  { label: 'Organization', text: 'Organización', icon: ApartmentIcon },
  { label: 'Private', text: 'Privado', icon: FolderIcon },
];

const Tabs = () => {
  const [{ Section }, setFilters] = useFilters();
  const sx = useSx();

  const handleRoleChange = (e: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setFilters('Section', newValue);
  };

  return (
    <Box>
      <MuiTabs textColor="primary" onChange={handleRoleChange} value={Section} sx={sx.root}>
        {SECTIONS.map(({ label, text, icon: Icon }) => (
          <Tab
            value={label}
            label={
              <>
                <Icon />
                <Typography>{text}</Typography>
              </>
            }
            key={label}
            sx={sx.tab}
          />
        ))}
      </MuiTabs>
    </Box>
  );
};

export default Tabs;

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import SoftLoadingProps from './SotftLoadingProps';
import useSx from './sx';

const SoftLoading = ({ isOpen }: SoftLoadingProps) => {
  const sx = useSx();
  return (
    <Dialog onClose={() => {}} PaperProps={{ sx: sx.paper }} open={isOpen}>
      <CircularProgress />
    </Dialog>
  );
};

export default SoftLoading;

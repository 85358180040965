import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
    root: {
        ...expandDisplayFlex({d: 'column'}),
        width: { xs: '100%', md: '90%' },
        gap: '20px',
        marginTop: '2rem'
    },
    formContainer: {
        marginTop: '2rem',
        marginBottom: '2rem'
    }
});

export default useSx;

// custom.dark.theme.ts
import merge from 'lodash.merge';
import { grey } from '@mui/material/colors';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import baseTheme from './base.theme';

// Async function to update theme
const customDark = (customThemeData: any) => {
  const primary = customThemeData?.primary || '#0E70FF';
  const primaryDark = customThemeData?.primaryDark || '#002e7a';
  const primaryContrast = customThemeData?.primaryContrast || '#e6e6e6';
  const secondary = customThemeData?.secondary || '#1A4488';
  const secondaryContrast = customThemeData?.secondaryContrast || '#f7f7f7';
  const info = customThemeData?.info || '#5FA2FF';
  const positive = customThemeData?.positive || '#6ab78a';
  const error = customThemeData?.error || '#A70D0D';
  const errorDark = '#870505';
  const errorContrast = customThemeData?.errorContrast || '#f7f7f7';
  const warning = customThemeData?.warning || '#DC7414';

  const brandPrimary = customThemeData?.brandPrimary || '#0E70FF';
  const brandSecondary = customThemeData?.brandSecondary || '#1A4488';

  const shades = {
    50: customThemeData?.shades?.[50] || '#01070C',
    100: customThemeData?.shades?.[100] || '#060C11',
    200: customThemeData?.shades?.[200] || '#091016',
    300: customThemeData?.shades?.[300] || '#0C1822',
    400: customThemeData?.shades?.[400] || '#162436',
    500: customThemeData?.shades?.[500] || '#1c364d',
    600: customThemeData?.shades?.[600] || '#2e4a63',
    700: customThemeData?.shades?.[700] || '#415263',
    800: customThemeData?.shades?.[800] || '#5b6875',
    900: customThemeData?.shades?.[900] || '#ffffff',
  };

  const myTheme: ThemeOptions = {
    palette: {
      mode: 'dark',
      primary: {
        main: primary,
        dark: primaryDark,
        contrastText: primaryContrast,
      },
      secondary: {
        main: secondary,
        contrastText: secondaryContrast,
      },
      text: {
        primary: primaryContrast,
      },
      success: {
        main: positive,
      },
      error: {
        main: error,
        contrastText: errorContrast,
      },
      info: {
        main: info,
      },
      warning: {
        main: warning,
      },
      shade: { ...shades },
      background: {
        default: shades[50],
      },
      action: {
        hover: shades[200],
      },
      brand: {
        main: brandPrimary,
        dark: brandSecondary,
        contrastText: primaryContrast,
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            background: shades[100],
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundImage: shades[200],
            borderRight: 'none'
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            height: '36px',
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: shades[400],
            border: 'none', 
            '&:focus, &:hover': {
              border: 'none',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          },
        },
      },      
      MuiInput: {
        styleOverrides: {
          root: {
            height: '36px',
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: shades[400],
            borderColor: 'transparent',
            '&.Mui-focused .MuiInput-notchedOutline, &:hover .MuiInput-notchedOutline': {
              borderColor: 'transparent',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: '36px',
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: shades[400],
            borderColor: 'transparent',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
              {
                borderColor: 'transparent',
              },
            '& .MuiOutlinedInput-notchedOutline': 
              {
                border: 'none',
              },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
        },
      },   
      MuiTableCell: {
        styleOverrides: {
          root: {},
          head: {
            backgroundColor: shades[300],
            color: grey[500],
            fontWeight: 500,
            textTransform: 'uppercase'
          },
          body: {
            backgroundColor: shades[200],
            borderBottom: '1px solid rgba(224, 224, 224, 0.2)',
          }
        },
      },
      MuiButton: {
        styleOverrides: {
          'containedPrimary': {
            background: `linear-gradient(165deg, ${primary}, ${primaryDark})`,
            color: 'white',
            '&:hover': {
              background: `linear-gradient(300deg, ${primary}, ${primaryDark})`,
            },
            '.iconWithSpacing': {
              marginRight: '5px',
            },
            '&.Mui-disabled': {
              background: shades[300],
            }
          },
          'containedSecondary': {
            background: `linear-gradient(165deg, ${shades[700]}, ${shades[600]})`,
            color: 'white',
            '&:hover': {
              background: `linear-gradient(300deg, ${shades[700]}, ${shades[600]})`,
            },
            '.iconWithSpacing': {
              marginRight: '5px',
            },
            '&.Mui-disabled': {
              background: grey[200],
              color: grey[500]
            }
          },
          'containedError': {
            background: `linear-gradient(165deg, ${error}, ${errorDark})`,
            color: 'white',
            '&:hover': {
              background: `linear-gradient(300deg, ${error}, ${errorDark})`,
            },
            '.iconWithSpacing': {
              marginRight: '5px',
            },
            '&.Mui-disabled': {
              background: shades[300],
            }
          },
          'outlinedSecondary': {
            border: `1px solid ${shades[900]}`,
            color: shades[900],
            '&:hover': {
              border: `1px solid ${info}`,
              color: info,
              opacity: 0.9,
            },
            '.iconWithSpacing': {
              marginRight: '5px',
            },
            '&.Mui-disabled': {
              color: grey[500]
            }
          },
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: shades[50], 
            backgroundImage: 'none'
          }
        }
      },
    },
  };

  const newTheme: ThemeOptions = {};
  merge(newTheme, myTheme, baseTheme);

  return createTheme(newTheme);
}

export default customDark;

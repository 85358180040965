import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        position: 'relative',
        marginTop: '1.5rem'
    }
});

export default useSx;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: (theme) => ({
    '.MuiInputBase-root': {
      paddingY: '12px',
      height: 'auto',
    },
    fieldset: {},
    '#editor-div': {
      backgroundColor: `${theme.palette.shade?.[400]} !important`,
      minHeight: '150px',
      borderRadius: '20px',
      overflow: 'hidden',
      padding: '1rem',
      color: `${theme.palette.primary.contrastText} !important`,
    },
    '.rooster-container': {
      width: '100%',
    },
  }),
});

export default useSx;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import setOrganizationTemplates from '@core/api/setOrganizationTemplates';
import { ORGANIZATION_TEMPLATE_QUERY_KEY } from './useOrganizationTemplate';
import { ORGANIZATION_TEMPLATE_IMAGE_QUERY_KEY } from './useOrganizationTemplateImage';
import { ORGANIZATION_TEMPLATES_QUERY_KEY } from './useOrganizationTemplates';

const useSetOrganizationTemplates = () => {
  const queryClient = useQueryClient();
  const { isLoading: isUploading, mutateAsync: postOrganizationTemplates } = useMutation({
    mutationFn: (payload: FormData) => setOrganizationTemplates(payload),
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        ORGANIZATION_TEMPLATE_QUERY_KEY,
        data.organizationTemplate?.templateId,
      ]);
      queryClient.invalidateQueries([ORGANIZATION_TEMPLATES_QUERY_KEY]);
      queryClient.invalidateQueries([ORGANIZATION_TEMPLATE_IMAGE_QUERY_KEY]);
    },
  });

  return { isUploading, postOrganizationTemplates };
};

export default useSetOrganizationTemplates;

import React from 'react';
import BadgeIcon from '@mui/icons-material/Badge';
import DrawIcon from '@mui/icons-material/Draw';
import KeyIcon from '@mui/icons-material/Key';
import Container from '@mui/material/Container';
import ContainerCard from '@components/ContainerCard/ContainerCard';
import If from '@components/If';
import Section from '@components/Section';
import useProfile from '@core/hooks/useProfile';
import { RoleName } from '@core/types';
import Form from '../components/Form';
import Header from '../components/Header';
import PasswordForm from '../components/PasswordForm';
import ProfessionalInfo from '../components/ProfessionalInfo';
import ProfilePhotoForm from '../components/ProfilePhotoForm';
import Signature from '../components/Signature';

const EditProfile = () => {
  const { role } = useProfile();

  return (
    <Container maxWidth={false} disableGutters>
      <Header />
      <ContainerCard>
        <Section
          title="Foto de perfil"
          IconComponent={BadgeIcon}
          description={
            role !== RoleName.Patient
              ? 'Personaliza tu identificación Profesional'
              : 'Personaliza tu información personal'
          }
        >
          <ProfilePhotoForm />
        </Section>
        <Section
          title="Información personal"
          IconComponent={BadgeIcon}
          description="Información personal y opciones para gestionarla. Puedes hacer que parte de esta información, como tus detalles de contacto, esté visible para otros usuarios."
        >
          <Form />
        </Section>
        <Section
          title="Cambiar contraseña"
          IconComponent={KeyIcon}
          description="Es necesario establecer una contraseña segura. Con un mínimo de 8 caracteres, pero no incluya palabras ni nombres comunes y combine letras mayúsculas, minúsculas, números y símbolos."
        >
          <PasswordForm />
        </Section>
        <If condition={role === RoleName.Radiologist || role === RoleName.System}>
          <>
            <Section
              title="Firma digital"
              IconComponent={DrawIcon}
              description="Es necesario contar con una firma digital para realizar los reportes digitales. Adjunta una imagen con tu firma o dibujala en el recuadro."
            >
              <Signature />
            </Section>
            <Section
              title="Información profesional"
              IconComponent={DrawIcon}
              description="El texto guardado en este apartado aparecerá automáticamente por debajo de la firma cuando usted guarde un reporte digital."
            >
              <ProfessionalInfo />
            </Section>
          </>
        </If>
      </ContainerCard>
    </Container>
  );
};

export default EditProfile;

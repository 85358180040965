import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    borderBottom: { xs: 'none', md: 1 },
    borderColor: { md: 'divider' },
    width: '100%',
    marginY: '1rem',
    position: {
      xs: 'fixed',
      md: 'unset',
    },
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    background: (theme) => theme.palette.shade?.[50],
    margin: 0,
    height: {
      xs: '100px',
      md: 'unset',
    },
    '& .MuiTabs-flexContainer': {
      height: '100%',
    },
  },
  tab: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    flex: {
      xs: 1,
      md: 'unset',
    },
    gap: '.5rem',
    textTransform: 'capitalize',
  },
});

export default useSx;

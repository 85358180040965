import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AuthContext } from '@core/providers/AuthProvider';
import getUsers from '../api/getUsers';
import Filters from '../types/Filters';

export const QUERY_KEY = 'users';

export interface UseUsersOptions {
  filters?: Filters;
  enabled?: boolean;
}

const useUsers = ({ filters, enabled = true }: UseUsersOptions) => {
  const { organization } = useContext(AuthContext);
  const { data: users, ...rest } = useQuery({
    queryKey: [QUERY_KEY, filters, organization],
    queryFn: () => getUsers({ ...filters, page: Number(filters?.page || 1) }),
    staleTime: 1000 * 60 * 60 * 12, // 12 hours
    cacheTime: 1000 * 60 * 60 * 12.5, // 12.5 hours
    enabled: enabled,
  });

  return { ...rest, pageCount: users?.pageCount ?? 0, users };
};

export default useUsers;

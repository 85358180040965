function isEmptyElement(element: HTMLElement): boolean {
  if (element.nodeType === Node.TEXT_NODE) {
    return element.nodeValue?.trim() === '';
  }

  if (element.childNodes.length === 0) {
    return true;
  }

  // Check if all children are empty
  for (let i = 0; i < element.childNodes.length; i++) {
    const child = element.childNodes[i] as HTMLElement;
    if (!isEmptyElement(child)) {
      return false;
    }
  }
  return true;
}

function removeEmptyLeadingNodes(node: HTMLElement) {
  if (node.nodeType === Node.TEXT_NODE) {
    // Cannot trim a text node
    return;
  }

  if (node.childNodes.length === 0) {
    // Cannot trim an empty node
    return;
  }

  while (node.firstChild) {
    const firstChild = node.firstChild as HTMLElement;

    // Trim empty text nodes and empty elements
    if (firstChild.nodeType === Node.TEXT_NODE && firstChild.nodeValue?.trim() === '') {
      node.removeChild(firstChild);
    } else if (firstChild.nodeType === Node.ELEMENT_NODE) {
      if (isEmptyElement(firstChild)) {
        node.removeChild(firstChild);
      } else {
        // Content was found, stop trimming
        break;
      }
    }
  }
}

export default removeEmptyLeadingNodes;

import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { DisclaimerProps } from './DisclaimerProps'
import useSx from './sx'

const Disclaimer: React.FC<DisclaimerProps> = ({
  children
}) => {

  const sx = useSx();

  return (
    <Box sx={sx.root}>
        <Typography fontWeight="light">
          {children}
        </Typography>
    </Box>
  )
}

export default Disclaimer;
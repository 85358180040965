import { alpha } from '@mui/material';
import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => {
  return {
    paper: {
      backgroundColor: (theme) => theme.palette.shade?.[50],
      backgroundImage: 'none'
    },
    actionsButtonContainer: {
      display: 'flex',
      gap: '10px',
      width: '100%',
      padding: '1rem 0',
      justifyContent: 'center'
    },
    dialogHeader: {
      display: 'flex',
      flexDirection: 'column'
    },
    title: {
      fontWeight: 600,
      textAlign: 'center',
      marginBottom: '1rem'
    },
    closeButton: {
      width: 'fit-content',
      alignSelf: 'flex-end'
    },
    iconContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    iconBox: {
      height: 60,
      width: 60,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      color: (theme) => theme.palette.error.main,
      backgroundColor: (theme) => alpha(theme.palette.error.main, 0.3),
      '.MuiSvgIcon-root': {
        fontSize: '3rem',
      },
    }
  };
};

export default useSx;

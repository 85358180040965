import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        color: (theme) => theme.palette.shade?.[900],
        rotate: '90deg'
    }
});

export default useSx;

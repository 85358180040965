import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputWithLabelProps from './InputWithLabelProps';
import useSx from './sx';

const InputWithLabel: React.FC<InputWithLabelProps> = ({ label, name, value }) => {
  const sx = useSx();

  return (
    <Box sx={sx.root}>
      <Typography className="Label-Root" component="label" htmlFor={name}>
        {label}
      </Typography>
      <TextField className="TextField-Root" name={name} value={value} inputProps={{ id: name }} />
    </Box>
  );
};
export default InputWithLabel;

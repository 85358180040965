import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import { RoleName } from '@core/types';
import useFilters from 'app/features/users/hooks/useFilters';
import useSx from './sx';

const Tabs = () => {
  const [{ Role }, setFilters] = useFilters();
  const sx = useSx();

  const handleRoleChange = (e: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setFilters('Role', newValue);
  };

  return (
    <Box sx={sx.root}>
      <MuiTabs textColor="primary" onChange={handleRoleChange} value={Role}>
        <Tab className="user-tab" value={RoleName.Radiologist} label="Médico Radiólogo" />
        <Tab className="user-tab" value={RoleName.Referrer} label="Médico Referente" />
        <Tab className="user-tab" value={RoleName.Technician} label="Técnico" />
      </MuiTabs>
    </Box>
  );
};

export default Tabs;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateNotificationsStatus } from '@core/api/updateNotificationsStatus';
import { GET_NOTIFICATIONS_QUERY_KEY } from './useGetNotifications';

const useUpdateNotificationsStatus = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateNotificationsStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_NOTIFICATIONS_QUERY_KEY]);
    },
    onError: (error) => {
      console.error('Error al actualizar las notificaciones:', error);
    },
  });

  return mutation;
};

export default useUpdateNotificationsStatus;

import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    ...expandDisplayFlex({d: 'column'}),
    marginTop: '2rem',
    marginBottom: '2rem',
    width: '100%',
  }
});

export default useSx;

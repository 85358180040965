import { useContext } from 'react';
import { ActionDialogProps } from '@components/ActionDialog/ActionDialogProps';
import { ConfirmActionContext } from '../providers/ConfirmActionProvider';

const useConfirm = () => {
  const [confirm, setConfirm] = useContext(ConfirmActionContext);

  const isConfirmed = (
    prompt: Omit<ActionDialogProps, 'open' | 'onClose' | 'onCancel' | 'onConfirm'>,
  ) => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        prompt,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
      });
    });
    return promise.then(
      () => {
        setConfirm({ ...confirm, isOpen: false });
        return true;
      },
      () => {
        setConfirm({ ...confirm, isOpen: false });
        return false;
      },
    );
  };
  return {
    ...confirm,
    isConfirmed,
  };
};

export default useConfirm;

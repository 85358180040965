import { DicomServiceTokenResponse } from '@core/types/DicomServiceTokenResponse';

const USER_FOUND = 'redux-oidc/USER_FOUND'

declare global {
    interface Window { store: any }
}

const setDicomServiceAccessToken = (dicomServiceToken: DicomServiceTokenResponse) => {
    // this is a hack as we know that it is using redux-oidc in order to set the access token property
    // we also know that OHIF viewer sets the store as a property of window
    if (!dicomServiceToken || !dicomServiceToken.token) {
        return
    }

    window.store.dispatch({
        type: USER_FOUND,
        payload: {
            access_token: dicomServiceToken.token.token
        }
    })
}

export { setDicomServiceAccessToken }
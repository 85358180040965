import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: (theme) => ({
    p: theme.spacing(4, 4, 0, 4),
    fontWeight: 'bold',
  }),
  iconButton: {
    color: 'white',
    width: '50px',
    minWidth: 0,
    height: '36px',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'center',
  },
  loader: {
    color: (theme) => theme.palette.primary.contrastText
  }
});

export default useSx;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        position: 'relative',
        width: {xs: 'auto', md: '100px'}
    },
    required: {
        ':after': {
            position: 'absolute',
            top: '-4px',
            content: '"*"',
            color: 'red'
        }
    }
});

export default useSx;

import { useMutation } from '@tanstack/react-query';
import resetPassword, { ResetPasswordRequest } from '../api/resetUserPassword';

const RESET_PASSWORD_MUTATION_KEY = ['userResetPassword'];

const useResetPassword = () => {
  const { mutateAsync: resetPasswordAsync, ...rest } = useMutation({
    mutationKey: RESET_PASSWORD_MUTATION_KEY,
    mutationFn: (requestData: ResetPasswordRequest) => resetPassword(requestData)
  });

  return { resetPasswordAsync, ...rest };
};

export default useResetPassword;
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CloseButtonProps from './CloseButtonProps';
import useSx from './sx';

const CloseButton = ({ handleDrawerToggle }: CloseButtonProps) => {
    const sx = useSx()

    return (
        <IconButton onClick={handleDrawerToggle} sx={sx.root} size="large">
            <CloseIcon />
        </IconButton>
    )
}

export default CloseButton
import React from 'react';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import If from '@components/If';
import SettingsMenuProps from '../SettingsMenuProps';
import useSx from './sx';

const LargeViewMenu = ({
  anchorEl,
  onMenuItemClick,
  onClose,
  settingsItems,
}: SettingsMenuProps) => {
  const sx = useSx();
  const handleItemClick = (route: string) => onMenuItemClick?.(route);

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      sx={sx.root}
    >
      {/* this div prevents a React error about MenuItems not accepting a Fragment */}
      <div>
        {settingsItems.map((m, index) => (
          <React.Fragment key={m.key}>
            <If condition={index !== 0}>
              <Divider />
            </If>
            <MenuItem onClick={() => handleItemClick(m.route)}>
              <ListItemIcon><m.icon /></ListItemIcon>
              <ListItemText>{m.label}</ListItemText>
            </MenuItem>
          </React.Fragment>
        ))}
      </div>
    </Menu>
  );
};

export default LargeViewMenu;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserItem } from '@core/types';
const useSelectUserContentState = () => {
  const navigate = useNavigate();

  const [selectedUser, setSelectedUser] = useState<UserItem | undefined>(undefined);

  return {
    selectedUser,
    setSelectedUser,
    navigate,
  };
};

export default useSelectUserContentState;

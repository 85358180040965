import React, { useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { PhotoCamera } from '@mui/icons-material';
import { Box } from '@mui/material';
import ActionButton from '@components/Button/ActionButton';
import IconButton from '@components/Button/IconButton';
import If from '@components/If';
import useSignatureState from './useSignatureState';

const Signature: React.FC = () => {
  const {
    showSnackbar,
    userUid,
    sx,
    sigCanvasRef,
    containerRef,
    isSigned,
    setIsSigned,
    signatureFile,
    setSignatureFile,
    postUserSignature,
    deleteUserSignature,
    isSaving,
    setIsSaving,
    isDeleting,
    setIsDeleting,
    data,
    isSuccess,
    refetch,
    hasSignatureSaved,
  } = useSignatureState();

  const drawImageOnCanvas = (base64String: string | ArrayBuffer | null) => {
    const canvas = sigCanvasRef.current?.getCanvas();
    if (canvas && base64String) {
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        console.error('Canvas context is not available');
        return;
      }
      const img = new Image();
      img.onload = () => {
        const imgAspectRatio = img.width / img.height;

        let imgHeight = canvas.height;
        let imgWidth = imgHeight * imgAspectRatio;

        if (imgWidth > canvas.width) {
          imgWidth = canvas.width;
          imgHeight = imgWidth / imgAspectRatio;
        }

        const x = (canvas.width - imgWidth) / 2;
        const y = (canvas.height - imgHeight) / 2;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, x, y, imgWidth, imgHeight);
      };
      img.onerror = (e) => {
        console.log('Image load error:', e);
      };
      img.src = `${base64String}`;
    }
  };

  const drawApiImage = () => {
    if (isSuccess && data) {
      let base64String = data.signature;
      if (!base64String?.startsWith('data:image/png;base64,')) {
        base64String = 'data:image/png;base64,' + base64String;
      }
      drawImageOnCanvas(base64String);
    }
  };

  const handleSave = async () => {
    if (!signatureFile) return;
    if (data && data.file) {
      showSnackbar({
        message: 'Ya tienes una firma guardada, debes eliminarla para poder ',
        title: 'Atención',
        type: 'warning',
      });
      return;
    }
    setIsSaving(true);
    const formData = new FormData();
    if (typeof signatureFile === 'string') {
      const response = await fetch(signatureFile);
      const blob = await response.blob();
      formData.append('file', blob);
      formData.append('Length', String(blob.size));
    }
    formData.append('ContentType', 'image/png');
    formData.append('ContentDisposition', 'inline');
    formData.append('Name', `signature-${userUid}`);
    formData.append('FileName', `signature-${userUid}.png`);

    try {
      await postUserSignature(formData);
      showSnackbar({
        message: 'Su firma se guardo correctamente',
        title: 'Firma actualizada',
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      showSnackbar({
        message: 'Lo sentimos, no pudimos guardar la firma. Intente mas tarde.',
        title: 'Ocurrió un error',
        type: 'error',
      });
    }
    setIsSaving(false);
    await refetch();
    setIsSigned(false);
  };

  const handleClear = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
      setIsSigned(false);
      drawApiImage();
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteUserSignature();

      if (sigCanvasRef.current) {
        sigCanvasRef.current.clear();
        setIsSigned(false);
        setSignatureFile(null);
      }

      showSnackbar({
        message: 'La firma ha sido eliminada correctamente.',
        title: 'Firma eliminada',
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      showSnackbar({
        message: 'Lo sentimos, no se pudo eliminar la firma. Intente más tarde.',
        title: 'Ocurrió un error',
        type: 'error',
      });
    }
    setIsDeleting(false);
    await refetch();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const maxSize = 256 * 1024;

      if (file.size > maxSize) {
        showSnackbar({
          message: 'El archivo es demasiado grande. Debe ser menor a 256KB.',
          title: 'Atención',
          type: 'warning',
        });
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setSignatureFile(base64String);
        drawImageOnCanvas(base64String);
        setIsSigned(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const setSignatureAsFile = async () => {
    if (sigCanvasRef.current) {
      const signatureDataURL = sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
      setSignatureFile(signatureDataURL);
    }
  };

  const setCanvasDimensions = () => {
    if (sigCanvasRef.current && containerRef.current) {
      const canvas = sigCanvasRef.current.getCanvas();
      const containerWidth = containerRef.current.offsetWidth;
      const padding = 32;

      canvas.width = containerWidth - padding;
      canvas.height = 200;
    }
  };

  const handleButtonClick = () => {
    if (!hasSignatureSaved) {
      document.getElementById('icon-button-file')?.click();
    }
  };

  useEffect(() => {
    drawApiImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  useEffect(() => {
    setCanvasDimensions();
    window.addEventListener('resize', setCanvasDimensions);
    return () => {
      window.removeEventListener('resize', setCanvasDimensions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={sx.wrapper}>
      <Box sx={sx.signatureContainer} ref={containerRef}>
        <SignatureCanvas
          ref={(ref) => (sigCanvasRef.current = ref)}
          penColor="black"
          canvasProps={{
            className: 'signature-canvas',
          }}
          onEnd={() => {
            setIsSigned(true);
            setSignatureAsFile();
          }}
        />
      </Box>

      <Box sx={sx.actionContainer}>
        <input
          type="file"
          accept="image/png, image/jpeg, image/jpg, image/svg+xml"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="icon-button-file"
        />
        <IconButton
          tooltip={'Subir archivo'}
          color="primary"
          variant="contained"
          aria-label="upload picture"
          component="span"
          disabled={hasSignatureSaved}
          onClick={handleButtonClick}
        >
          <PhotoCamera />
        </IconButton>
        <Box sx={sx.buttonWrapper}>
          <If condition={isSigned}>
            <ActionButton
              color="secondary"
              variant="outlined"
              text="Restaurar"
              onClick={handleClear}
            />
          </If>
          <ActionButton
            color="error"
            variant="contained"
            text="Eliminar"
            onClick={handleDelete}
            disabled={!hasSignatureSaved}
            isLoading={isDeleting}
          />
          <ActionButton
            color="primary"
            variant="contained"
            text="Guardar"
            onClick={handleSave}
            disabled={!isSigned && hasSignatureSaved}
            isLoading={isSaving}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Signature;

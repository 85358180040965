export default function replaceQrLink(page: HTMLDivElement, shareStudyUrl?: string) {
  const qrLinkPlaceholders = page.querySelectorAll(
    '.QrPlaceholderLink',
  ) as NodeListOf<HTMLAnchorElement>;

  qrLinkPlaceholders.forEach((elem: HTMLAnchorElement) => {
    if (shareStudyUrl) {
      elem.href = shareStudyUrl;
    }
  });
}

import React, { useEffect, useState } from 'react';
import {v4 as uuidv4} from 'uuid';
import useDeleteOrganizationLogo from '@core/hooks/useDeleteOrganizationLogo';
import useOrganizationSettings from '@core/hooks/useOrganizationSettings';
import useSetOrganizationLogo from '@core/hooks/useSetOrganizationLogo';
import useSnackbar from '@core/hooks/useSnackbar';
import useSx from './sx';

const useLogoState = () => {
  const { organizationSettings } = useOrganizationSettings();
  const { postOrganizationLogo, isUploading } = useSetOrganizationLogo();
  const { mutate: deleteOrganizationLogo, isLoading: isDeleting } = useDeleteOrganizationLogo();
  const sx = useSx();
  const showSnackbar = useSnackbar();
  const [customLogoLight, setCustomLogoLight] = useState<string | undefined>(undefined);
  const [customLogoDark, setCustomLogoDark] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (organizationSettings) {
      const logoDarkUrl = organizationSettings['custom-logo-dark'];
      const logoLightUrl = organizationSettings['custom-logo-light'];

      if (typeof logoDarkUrl === 'string') {
        setCustomLogoDark(`${logoDarkUrl}?v=${uuidv4()}`);
      } else if (typeof logoDarkUrl === 'undefined') {
        setCustomLogoDark(logoDarkUrl);
      }

      if (typeof logoLightUrl === 'string') {
        setCustomLogoLight(`${logoLightUrl}?v=${uuidv4()}`);
      } else if (typeof logoLightUrl === 'undefined') {
        setCustomLogoLight(logoLightUrl);
      }
    }
  }, [organizationSettings]);

  return {
    sx,
    showSnackbar,
    isUploading,
    customLogoLight,
    setCustomLogoLight,
    customLogoDark,
    setCustomLogoDark,
    organizationSettings,
    postOrganizationLogo,
    deleteOrganizationLogo,
    isDeleting
  };
};

export default useLogoState;

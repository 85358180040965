import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => {
  return {
    textfield: {
      '.MuiInputBase-root': {
        paddingY: '0',
        paddingLeft: '8px',
      },
    },
  };
};

export default useSx;

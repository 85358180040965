import { useQuery } from '@tanstack/react-query';
import getSharedStudy from '@core/api/getSharedStudy';
import getStudyInfo from '../../features/study/api/getStudyInfo';

export const STUDY_QUERY_KEY = 'study';

const useStudyInfo = ({
  studyInstanceUidOrHash,
  isSharedStudy = false,
  retry = 3, // default react query
}: {
  studyInstanceUidOrHash?: string;
  isSharedStudy?: boolean;
  retry?: number | boolean;
}) => {
  const { data: studyInfo, ...rest } = useQuery({
    queryKey: [STUDY_QUERY_KEY, studyInstanceUidOrHash],
    queryFn: () =>
      isSharedStudy
        ? getSharedStudy(studyInstanceUidOrHash!)
        : getStudyInfo(studyInstanceUidOrHash),
    enabled: !!studyInstanceUidOrHash,
    staleTime: 1000 * 60 * 10,
    retry,
  });

  return { studyInfo, ...rest };
};

export default useStudyInfo;

import { TemplateFile, TemplateFolder } from '@core/types';

export default function findTemplateOnFolder(
  root: {
    [key: string]: TemplateFolder | TemplateFile;
  },
  indexArray: number[],
  templateName: string,
) {
  let currFolder = root;
  const array = [...indexArray];

  while (indexArray.length > 0) {
    const index = array.shift();
    const foundFolder = currFolder[`folder-${index}`];

    if (foundFolder && foundFolder.subItems) {
      currFolder = foundFolder.subItems;
    } else {
      break;
    }
  }

  return Object.values(currFolder).find((node) => node.name === templateName && node.isFile) as
    | TemplateFile
    | undefined;
}

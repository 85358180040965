import React from 'react';
import TableCell from '@mui/material/TableCell';
import { UserItem } from '@core/types';
import useSx from './sx';

const UserCell = ({user }: { user: UserItem }) => {
    const lastName = user.surname ? `${user.surname}, ` : ''
    const fullName = lastName + user.givenName
    const sx = useSx()
    return (
        <TableCell sx={sx.root}>
            {fullName}
        </TableCell>
    )
}

export default UserCell
import React from 'react';
import { createBrowserRouter, RouterProvider, useRouteError } from 'react-router-dom';
import CenterCenterStack from '@components/CenterCenterStack';
import { lazyImport } from '@core/utils/lazyImport';
import { hideStartLoader } from '@core/utils/startLoader';
import Customization from 'app/features/customization/Customization';
import ErrorPage from 'app/features/error/ErrorPage';
import { Notifications } from 'app/features/notifications/get';
import Preferences from 'app/features/preferences/routes/Preferences';
import Redirect from 'app/features/redirect';
import { AddReport } from 'app/features/reports/add/routes/AddReport';
import { Study } from 'app/features/study';
import Subscription from 'app/features/subscription/routes/Subscription';
import { Templates } from 'app/features/templates';
import AddTemplate from 'app/features/templates/add/routes';
import EditTemplate from 'app/features/templates/edit/routes';
import { Users } from 'app/features/users';
import { AddUser } from 'app/features/users/add';
import EditProfile from 'app/features/users/edit/routes/EditProfile';
import { EditUser } from 'app/features/users/edit-user';
import { ReadOnlyViewer } from '../features/read-only-study';
import AppRoot from './AppRoot';
import SettingsRoot from './SettingsRoot';

const { Landing } = lazyImport(() => import('../features/studies'), 'Landing');
const { GetPermissions } = lazyImport(
  () => import('../features/permissions/get'),
  'GetPermissions',
);
const { AddPermission } = lazyImport(() => import('../features/permissions/add'), 'AddPermission');
const { EditPermission } = lazyImport(
  () => import('../features/permissions/edit'),
  'EditPermission',
);
const { GetReports } = lazyImport(() => import('../features/reports/get'), 'GetReports');
const { Security } = lazyImport(() => import('../features/security/index'), 'Security');

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Landing />,
      },
      {
        path: 'studies/:studyInstanceUid',
        element: <Study />,
      },
      {
        path: 'perfil',
        element: <EditProfile />,
      },
      {
        path: 'preferences',
        element: <Preferences />,
      },
      {
        path: 'reports',
        element: <AddReport />,
      },
      {
        path: 'templates',
        element: <Templates />,
      },
      {
        path: 'templates/add',
        element: <AddTemplate />,
      },
      {
        path: 'templates/:templateId',
        element: <EditTemplate />,
      },
      {
        path: 'notifications',
        element: <Notifications />,
      },
    ],
  },
  {
    path: '/study/view',
    element: <ReadOnlyViewer />,
    errorElement: <ErrorPage notLogged />,
  },
  {
    path: '/redirect',
    element: <Redirect />,
    errorElement: <ErrorPage notLogged />,
  },
  {
    path: '/settings',
    element: <SettingsRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'users/add',
        element: <AddUser />,
      },
      {
        path: 'users/edit/:userUid',
        element: <EditUser />,
      },
      // These React routes are currently being commented out as they are just a mockup for now.
      // They will be enabled once the corresponding services for the full feature integration are in place.
      {
        path: 'reports',
        element: <GetReports />,
      },
      {
        path: 'permissions',
        element: <GetPermissions />,
      },
      {
        path: 'permissions/add',
        element: <AddPermission />,
      },
      {
        path: 'permissions/edit/:permissionId',
        element: <EditPermission />,
      },
      {
        path: 'security',
        element: <Security />,
      },
      {
        path: 'subscription',
        element: <Subscription />,
      },
      // {
      //   path: 'account',
      //   element: <p>Cuenta</p>,
      // },
      /* TODO: This section of code has been temporarily commented out as the feature it implements is being hidden for the time being. The decision to disable this feature comes as we are currently reassessing and clarifying our billing process. We intend to revisit and potentially re-enable this functionality once we have a more definitive understanding of the billing mechanisms and how they should be accurately implemented within our system. This measure ensures that we maintain the integrity and reliability of our billing operations while we make necessary adjustments.
      {
        path: 'subscription/change-subscription',
        element: <ChangeSubscription />,
      }, 
      */
      // {
      //   path: 'groups',
      //   element: <p>Grupos</p>,
      // },
      {
        path: 'edit',
        element: <Customization />,
      },
      // {
      //   path: 'support',
      //   element: <p>Soporte & Contacto</p>,
      // },
    ],
  },
]);

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

export default AppRoutes;

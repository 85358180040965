import { put } from '@core/api/baseApi';
import { PostOrganizationTemplateResponse } from '@core/types/ApiResponses';

const updateOrganizationTemplate = (
  templateId: string,
  formData: FormData,
): Promise<PostOrganizationTemplateResponse> =>
  put(`/organizations/templates/${templateId}`, formData);

export default updateOrganizationTemplate;

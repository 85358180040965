import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getReportsCreatedByUser } from '../api/getReportsCreatedByUser';

export const GET_STUDY_REPORTS_CREATED_BY_USER_QUERY_KEY = 'GetReportsCreatedByUser';

const useGetReportsCreatedByUser = (studyInstanceUid: string) => {
  const { data: reportIds, ...rest } = useQuery({
    queryKey: [GET_STUDY_REPORTS_CREATED_BY_USER_QUERY_KEY],
    queryFn: () => getReportsCreatedByUser(studyInstanceUid),
    enabled: !!studyInstanceUid,
  });

  return {
    reportIds,
    ...rest,
  };
};

export default useGetReportsCreatedByUser;
import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: (theme) => ({ 
    padding: '1rem', 
    backgroundColor: theme.palette.shade?.[100],
    borderRadius: '8px', 
    boxShadow: 3 
  }),
  actionBox: {
    marginTop: '1rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around'
  }
});

export default useSx;

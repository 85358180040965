import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  scroll: {
    height: '750px',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: '#777777',
    },

    '::-webkit-scrollbar-thumb': {
      background: '#555555',
    },

    '::-webkit-scrollbar-thumb:hover': {
      background: '#444444',
    },
  },
  root: {
    zoom: 0.7,
    transformOrigin: 'top center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
  loadingContainer: {
    backgroundColor: 'black',
    opacity: 0.5,
    width: '816px',
    height: '100%',
    paddingTop: '150px',
    paddingRight: '50px',
    paddingBottom: '80px',
    paddingLeft: '50px',
    position: 'absolute',
  },
  loading: {
    width: '30px',
    height: '30px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'primary.main',
  },
});

export default useSx;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { Box } from '@mui/material';
import If from '@components/If';
import { hideStartLoader } from '@core/utils/startLoader';
import useGetStudyReport from '../../hooks/useGetStudyReport';
import ActionButtons from './ActionButtons';
import NavButtons from './NavButtons';
import PdfDisplay from './PdfDisplay';
import useSx from './sx';

export default function PdfViewer({
  studyHash,
  show,
  closeReport,
}: {
  studyHash: string;
  show: boolean;
  closeReport: () => void;
}) {
  const { reportsUrls, isLoading } = useGetStudyReport(studyHash);
  const [reportIndex, setReportsIndex] = useState<number>(0);
  const [reports, setReports] = useState<
    {
      blob: Blob;
      formatedUrl: string;
      totalPages: number;
      pdfName: string;
    }[]
  >([]);
  const [zoom, setZoom] = useState(100);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [startX, setStartX] = useState(0);

  const sx = useSx();

  function changeSelectedReport(action: 'PREVIOUS' | 'NEXT') {
    if (action === 'NEXT' && reportIndex < reports.length - 1) {
      setReportsIndex(reportIndex + 1);
    }
    if (action === 'PREVIOUS' && reportIndex > 0) {
      setReportsIndex(reportIndex - 1);
    }
  }

  function changeZoom(value: number) {
    const newZoom = zoom + value;
    if (50 <= newZoom && newZoom <= 200) setZoom(newZoom);
  }

  function handleDownload() {
    const url = reports[reportIndex].formatedUrl;
    const name = reports[reportIndex].pdfName;
    if (url.length) {
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  function handlePrint() {
    const url = reports[reportIndex].formatedUrl;
    if (url.length) {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      iframe.onload = () => {
        iframe.contentWindow!.focus();
        iframe.contentWindow!.print();
      };

      window.onfocus = () => {
        setTimeout(() => {
          document.body.removeChild(iframe);
          window.onfocus = null;
        }, 100);
      };
    }
  }

  useEffect(() => {
    if (isLoading) return;
    if (!reportsUrls.length) return closeReport();
    hideStartLoader({});

    async function processReportData() {
      const promiseArray = reportsUrls.map((url) =>
        fetch(url)
          .then(async (r) => {
            const disposition = r.headers.get('Content-Disposition');
            let pdfName = 'Reporte.pdf';

            if (disposition && disposition.includes('filename=')) {
              // Extract filename from Content-Disposition
              const fileNameMatch = disposition.match(/filename="(.+)"/);
              if (fileNameMatch!.length > 1) {
                pdfName = fileNameMatch![1];
              }
            }
            const blob = await r.blob();
            return {
              pdfName,
              blob,
            };
          })
          .then(async ({ blob, pdfName }) => {
            const formatedUrl = URL.createObjectURL(blob);
            const pdfFile = await pdfjs.getDocument(formatedUrl).promise;

            const totalPages = pdfFile.numPages;
            return {
              blob,
              formatedUrl,
              totalPages,
              pdfName,
            };
          }),
      );

      Promise.all(promiseArray).then(setReports);
    }
    processReportData();
  }, [isLoading]);

  // START of swipe Logic
  const handleTouchStart = useCallback((e: TouchEvent) => {
    if (wrapperRef.current && e.target && !wrapperRef.current.contains(e.target as Node)) {
      return;
    }
    setStartX(e.touches[0].clientX);
  }, []);

  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
        return;
      }
      const endX = e.changedTouches[0].clientX;
      const deltaX = endX - startX;

      if (deltaX < -60) changeSelectedReport('NEXT');
      if (deltaX > 60) changeSelectedReport('PREVIOUS');
    },
    [startX],
  );

  useEffect(() => {
    if (show) {
      window.addEventListener('touchstart', handleTouchStart);
      window.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleTouchStart, handleTouchEnd, show]);
  // END of swipe Logic

  return (
    <If condition={show && !!reports.length}>
      <Box sx={sx.root} ref={wrapperRef}>
        <PdfDisplay
          pdf={reports[reportIndex]?.blob}
          totalPages={reports[reportIndex]?.totalPages}
          zoom={zoom}
        />
        <NavButtons
          showNext={reportIndex !== reportsUrls.length - 1}
          showPrevious={reportIndex !== 0}
          changeSelectedReport={changeSelectedReport}
        />
        <ActionButtons
          zoomFunctions={{
            zoom,
            zoomIn: () => changeZoom(10),
            zoomOut: () => changeZoom(-10),
          }}
          closeReport={closeReport}
          handleDownload={handleDownload}
          handlePrint={handlePrint}
        />
      </Box>
    </If>
  );
}

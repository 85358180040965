import React, { useState } from 'react';
import Box from '@mui/material/Box';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import If from '@components/If';
import useOrganizationTemplates from '@core/hooks/useOrganizationTemplates';
import useUpdateDefaultOrganizationTemplate from '@core/hooks/useUpdateDefaultOrganizationTemplate';
import { OrganizationTemplateModel } from '@core/types/OrganizationTemplate';
import DeleteDialog from './DeleteDialog';
import ReplaceDialog from './ReplaceDialog';
import TemplateCard from './TemplateCard/TemplateCard';
import Viewer from './Viewer';

type Callback = () => void;

const ReportTemplates = () => {
  const { organizationTemplates } = useOrganizationTemplates();
  const [selectedTemplate, setSelectedTemplate] = useState<OrganizationTemplateModel | null>(null);
  const [modalState, setModalState] = useState<null | string>(null);
  const { updateDefaultOrganizationTemplateAsync, isUpdating } =
    useUpdateDefaultOrganizationTemplate(selectedTemplate?.templateId || '');

  const onChangeTemplateClick: Callback = () => {};
  const onViewClick: Callback = () => {};
  const onDeleteClick: Callback = () => {};
  const onMarkDefaultTemplate = async () => {
    await updateDefaultOrganizationTemplateAsync();
  };

  const onClickAction =
    (cb: Callback, modalStateProp: string | null) => (template: OrganizationTemplateModel) => {
      setSelectedTemplate(template);
      setModalState(modalStateProp);
      cb();
    };

  const onClose = () => {
    setSelectedTemplate(null);
    setModalState(null);
  };

  return (
    <>
      <Box
        width="100%"
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(400px, 1fr) )"
        gap="40px"
      >
        {organizationTemplates?.items.map((template) => (
          <TemplateCard
            key={template.templateId}
            template={template}
            onChangeTemplateClick={onClickAction(onChangeTemplateClick, 'REPLACE')}
            onViewClick={onClickAction(onViewClick, 'VIEW')}
            onDeleteClick={onClickAction(onDeleteClick, 'DELETE')}
            onMarkDefault={onClickAction(onMarkDefaultTemplate, null)}
            disabledActions={isUpdating}
          />
        ))}
      </Box>
      <If condition={!!selectedTemplate}>
        <Viewer template={selectedTemplate!} onClose={onClose} open={modalState === 'VIEW'} />
        <ReplaceDialog
          template={selectedTemplate!}
          onClose={onClose}
          open={modalState === 'REPLACE'}
        />
        <DeleteDialog
          onClose={onClose}
          isOpen={modalState === 'DELETE'}
          template={selectedTemplate!}
        />
      </If>
    </>
  );
};

export default ReportTemplates;

import { useMutation } from '@tanstack/react-query';
import getReportConclusion from '../api/getReportConclusion';

const useGetReportConclusion = () => {
  const { isLoading: isGettinConclusion, mutateAsync: getReportConclusionAsync } = useMutation({
    mutationFn: (reportContent: string) => getReportConclusion(reportContent),
  });

  return { isGettinConclusion, getReportConclusionAsync };
};

export default useGetReportConclusion;

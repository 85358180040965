import React from 'react';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import IconButton from '@components/Button/IconButton';
import If from '@components/If';
import TemplateTagVariableFormProps from './TemplateTagVariableFormProps';
import useSx from './sx';

const TemplateTagVariableForm = ({
  variables,
  setVariables,
  selectedVariable,
  isRecording,
  onStartRecording,
  inputRef,
  isLoading = false,
}: TemplateTagVariableFormProps) => {
  const sx = useSx();

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVariables((prev) => ({ ...prev, [selectedVariable]: e.target.value }));
  };

  return (
    <Box sx={sx.root}>
      <TextField
        className="textfield"
        multiline
        inputRef={inputRef}
        disabled={!selectedVariable || isLoading}
        value={variables[selectedVariable] || ''}
        rows={8}
        onChange={onInputChange}
        placeholder="Contenido para variable"
        InputProps={{
          endAdornment: (
            <If condition={isLoading}>
              <CircularProgress className="circular-progress" />
            </If>
          ),
        }}
      />
      <IconButton
        tooltip="Usar audio"
        variant="contained"
        color={isRecording ? 'error' : 'primary'}
        tooltipDirection="top"
        disabled={!selectedVariable}
        onClick={onStartRecording}
        isLoading={isLoading}
        className="RecordingButton"
      >
        <If condition={!!isRecording} fallback={<MicIcon />}>
          <MicOffIcon />
        </If>
      </IconButton>
    </Box>
  );
};

export default TemplateTagVariableForm;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateSolicitation } from '../api/updateSolicitation';
import { GET_SOLICITATION_QUERY_KEY } from './useSolicitations';

const useUpdateStudySolicitation = (studyInstanceUid: string) => {
  const queryClient = useQueryClient();

  const { isLoading: isUpdating, mutateAsync: updateSolicitationAsync } = useMutation(
    ({ formData, attachmentKey }: { formData: FormData; attachmentKey: number | undefined }) =>
      updateSolicitation(formData, studyInstanceUid, attachmentKey),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SOLICITATION_QUERY_KEY]);
      },
    },
  );

  return { isUpdating, updateSolicitationAsync };
};

export default useUpdateStudySolicitation;

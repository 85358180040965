import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: (theme) => ({
    height: 1,
    ...expandDisplayFlex({ d: 'column' }),

    '& .MuiPaper-root': {
      bgcolor: 'transparent',
    },

    [theme.breakpoints.down('sm')]: {
      width: `calc(100% + ${theme.spacing(6 * 2)})`, // 6 spaces times 2 for left and right margin (mx)
      mx: -6,
    },
  }),
});

export default useSx;

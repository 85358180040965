import React from 'react'
import Drawer from '@mui/material/Drawer';
import List from '../List';
import CloseButton from './CloseButton';
import SmallViewDrawerProps from './SmallViewDrawerProps';
import useSx from './sx';

const SmallViewDrawer = ({ handleDrawerToggle, isOpen, profile }: SmallViewDrawerProps) => {
  const container = window !== undefined ? () => window.document.body : undefined;
  const sx = useSx()

  return (
    <Drawer
      container={container}
      variant="temporary"
      open={isOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
          keepMounted: true,
      }}
      sx={sx.root}
  >
    <CloseButton handleDrawerToggle={handleDrawerToggle} />
    <List handleDrawerToggle={handleDrawerToggle} profile={profile} />
  </Drawer>
  )
}

export default SmallViewDrawer
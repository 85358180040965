import { post } from '@core/api/baseApi';

export const UPLOAD_REPORT_API_ERROR_CODE = {
  duplicateReport: 'reporte_duplicado',
  emptyReport: 'archivo_vacio',
  invalidId: 'studyinstanceuid_invalido',
  invalidDataType: 'formato_invalido',
};

export const addReport = async (
  report: any,
  studyInstanceUid: string,
): Promise<{ success: boolean }> => {
  try {
    const response = (await post(`/studies/${studyInstanceUid}/reports`, report)) as {
      message?: string;
    };

    return { success: !response.message };
  } catch (err) {
    return { success: false };
  }
};

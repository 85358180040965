import { UserItem } from '@core/types';
import { User } from '@core/types/User';

const userItemMapper = (user: User): UserItem => ({
  fullName: user.givenName + ' ' + user.surname,
  emailAddress: user.emailAddress || '',
  fullNameInverse: user.surname + ' ' + user.givenName,
  lastLoginDate: '',
  userUid: user.userUid || '',
});

export default userItemMapper;

import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import ActionButton from '@components/Button/ActionButton';
import { ColorPickerProps } from './ColorPickerProps';
import useSx from './sx';

const ColorPicker: React.FC<ColorPickerProps> = ({ onClose, onSave, anchorEl, isLoading }) => {
  const [color, setColor] = useState<string>('#aabbcc');
  const sx = useSx();
  const open = Boolean(anchorEl);
  
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={sx.root}>
        <HexColorPicker color={color} onChange={setColor} />
        <Box sx={sx.actionBox}>
          <ActionButton 
            color="secondary"
            variant="outlined"
            text="Cancelar"
            onClick={onClose}
          />
          <ActionButton 
            text="Guardar"
            color="primary"
            variant="contained"
            isLoading={isLoading}
            onClick={() => onSave(color)}
          />
        </Box>
      </Box>
    </Popover>
  );
};

export default ColorPicker;

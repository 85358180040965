import React, { PropsWithChildren, useContext } from 'react';
import If from '@components/If';
import useOrganization from '@core/hooks/useOrganization';
import { AuthContext } from '@core/providers/AuthProvider';
import OrganizationPicker from './OrganizationPicker';

const OrganizationSetter = ({ children }: PropsWithChildren<unknown>) => {
  const { organization, setOrganization, isAcquiring: isGettingToken } = useContext(AuthContext);
  const hasOrg = !!organization?.organizationId;

  return (
    <If
      condition={hasOrg}
      fallback={<OrganizationPicker onOrganizationPicked={(info) => setOrganization(info)} />}
    >
      <If condition={!isGettingToken}>{children}</If>
    </If>
  );
};

export default OrganizationSetter;

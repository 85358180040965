import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import UserAvatar from '@components/UserAvatar';
import { UserItem } from '@core/types';
import useSx from './sx';

const UserTagSelect = ({
  users,
  editorContainerDiv,
  handleUserSelection,
}: {
  users: UserItem[];
  editorContainerDiv: React.MutableRefObject<HTMLDivElement | null>;
  handleUserSelection: (user: UserItem) => void;
}) => {
  const sx = useSx();
  return (
    <Box
      sx={sx.root}
      position="absolute"
      left={editorContainerDiv.current?.offsetLeft}
      zIndex={1}
      padding="20px"
      maxHeight="300px"
      overflow="auto"
      width={editorContainerDiv.current?.offsetWidth || undefined}
    >
      {users?.map((user, index) => (
        <Box
          className="user-container"
          onClick={() => {
            handleUserSelection(user);
          }}
          key={`${user.userUid}-${index}`}
        >
          <Box display="flex" alignItems="center" gap="10px">
            <UserAvatar userFullName={user.fullName} userProfilePhoto={user.profilePhoto} />
            <Typography flex={1}>{user.fullName}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default UserTagSelect;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    '& a': {
      display: 'flex',
    },

    height: '64px',
    width: { xs: 'fit-content', sm: '200px' },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    px: '1rem'
  },
  logo: {
    maxHeight: { xs: 30, sm: 40 },
    width: 'auto',
    maxWidth: '100%',
    height: 'auto',
  }
});

export default useSx;

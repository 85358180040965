import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'roosterjs';
import { Rooster } from 'roosterjs-react';
import { Box, useTheme } from '@mui/material';
import If from '@components/If';
import useSnackbar from '@core/hooks/useSnackbar';
import { UserItem } from '@core/types';
import useUsers from 'app/features/users/hooks/useUsers';
import UserTagSelect from '../UserTagSelect';
import CommentInputProps from './CommentInputProps';
import UpdateTagsPlugin from './UpdateTagsPlugin';

const replaceBetween = function (str: string, start: number, end: number, what: string) {
  return str.substring(0, start) + what + str.substring(end);
};

//added b at start if because some ids starts with a digit an this is not a valid format in querySelector
//reference: https://stackoverflow.com/questions/37270787/uncaught-syntaxerror-failed-to-execute-queryselector-on-document
const createTextSpan = (user: UserItem) =>
  `<span data-image="${
    user.profilePhoto || ''
  }" class="tag" style="background-color:#011c52;" id="b${user.userUid}">${
    user.fullName
  }</span>&nbsp;`;

const CommentInput = ({ editor, setComment, comment, tags, setTags }: CommentInputProps) => {
  const theme = useTheme();
  const node = useRef<Node | null>(null);
  const cursorPosition = useRef<number | null>(null);
  const editorContainerDiv = useRef<HTMLDivElement | null>(null);

  const showSnackbar = useSnackbar();

  const [nameFilter, setNameFilter] = useState('');
  const [isTagging, setIsTagging] = useState(false);

  const updateContentPlugin = useRef(
    new UpdateTagsPlugin({
      handleInputChange: (html, isTaggingProp, nodeProp, cursorPositionProp) => {
        setComment((prev) => ({ ...prev, content: html }));
        setIsTagging(isTaggingProp);
        node.current = nodeProp || null;
        cursorPosition.current = cursorPositionProp || null;
      },
    }),
  );

  const { users } = useUsers({
    filters: {
      Role: 'Radiologist,Technician',
      Search: isTagging ? nameFilter : '',
    },
    enabled: isTagging,
  });

  useEffect(() => {
    if (cursorPosition.current) {
      const searchText = node.current?.textContent?.substring(
        cursorPosition.current,
        editor.current?.getFocusedPosition()?.offset,
      );

      setNameFilter(searchText || '');
    }
  }, [isTagging, comment.content]);

  const onUserSelection = (user: UserItem) => {
    if (tags[user.userUid]) {
      showSnackbar({
        type: 'error',
        message:
          'El usuario que intenta etiquetar ya está etiquetado. Por favor, elimine la etiqueta y reubíquela o agréguela como texto plano.',
        title: 'Error',
      });

      return;
    }

    if (isTagging && cursorPosition.current && node.current) {
      const spanParent = document.createElement('span');
      const textInserted = createTextSpan(user);
      const offset = editor?.current?.getFocusedPosition();

      const textToBeReplaced = node.current.textContent;
      const newText = replaceBetween(
        textToBeReplaced || '',
        cursorPosition.current - 1,
        offset?.offset || 0,
        textInserted,
      );

      spanParent.innerHTML = newText;

      node.current.parentNode?.replaceChild(spanParent, node.current);

      setTags((prev) => ({ ...prev, [user.userUid]: user }));
      setIsTagging(false);
    }
  };
  

  const checkUserTags = () => {
    const newTags = {...tags}
    Object.values(newTags).forEach((tag) => {
      const contentDiv = document.createElement('div');
      contentDiv.innerHTML = editor.current?.getContent() || '';
      const documentTag = contentDiv.querySelector(`#b${tag.userUid}`);

      if (documentTag instanceof HTMLElement) {
        if (documentTag.innerText !== tag.fullName) {
          documentTag.style.background = 'none';
          documentTag.id = '';
          documentTag.className = '';
          documentTag.dataset.image = '';
          delete newTags[tag.userUid];
          setTags(newTags);
          editor.current?.setContent(contentDiv.innerHTML);
        }
      } else {
        delete newTags[tag.userUid];
        setTags(newTags);
        editor.current?.setContent(contentDiv.innerHTML);
      }
    });
  };

  return (
    <Box ref={editorContainerDiv} className="rooster-container">
      <Rooster
        plugins={[updateContentPlugin.current]}
        defaultFormat={{
          textColor: theme.palette.primary.contrastText,
        }}
        onBlur={checkUserTags}
        editorCreator={(div, options) => {
          editor.current = new Editor(div, options);
          div.id = 'editor-div';
          return editor.current;
        }}
      />
      <If condition={isTagging}>
        <UserTagSelect
          users={users?.items || []}
          editorContainerDiv={editorContainerDiv}
          handleUserSelection={onUserSelection}
        />
      </If>
    </Box>
  );
};

export default CommentInput;

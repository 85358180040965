function calculateElapsedTime(dateString: string): string {
  const initialDate = new Date(dateString);
  const currentDate = new Date();
  const timeDifferenceInMillis: number = currentDate.getTime() - initialDate.getTime();
  const millisInSecond = 1000;
  const millisInMinute: number = millisInSecond * 60;
  const millisInHour: number = millisInMinute * 60;
  const millisInDay: number = millisInHour * 24;
  const millisInWeek: number = millisInDay * 7;
  const millisInMonth: number = millisInDay * 30; // Approximately

  const minutesElapsed: number = Math.floor(timeDifferenceInMillis / millisInMinute);
  const hoursElapsed: number = Math.floor(timeDifferenceInMillis / millisInHour);
  const daysElapsed: number = Math.floor(timeDifferenceInMillis / millisInDay);
  const weeksElapsed: number = Math.floor(timeDifferenceInMillis / millisInWeek);
  const monthsElapsed: number = Math.floor(timeDifferenceInMillis / millisInMonth);

  if (monthsElapsed > 0) {
    if (monthsElapsed === 1) {
      return 'Hace 1 mes';
    } else {
      return `Hace ${monthsElapsed} meses`;
    }
  } else if (weeksElapsed > 0) {
    if (weeksElapsed === 1) {
      return 'Hace 1 semana';
    } else {
      return `Hace ${weeksElapsed} semanas`;
    }
  } else if (daysElapsed > 0) {
    if (daysElapsed === 1) {
      return 'Hace 1 día';
    } else {
      return `Hace ${daysElapsed} días`;
    }
  } else if (hoursElapsed > 0) {
    if (hoursElapsed === 1) {
      return 'Hace 1 hora';
    } else {
      return `Hace ${hoursElapsed} horas`;
    }
  } else if (minutesElapsed > 0) {
    if (minutesElapsed === 1) {
      return 'Hace 1 minuto';
    } else {
      return `Hace ${minutesElapsed} minutos`;
    }
  } else {
    return 'Hace unos segundos';
  }
}

export default calculateElapsedTime;

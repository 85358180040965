import React, { PropsWithChildren } from 'react';
import { SxProps, Theme } from '@mui/material';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import useSx from '../SmallViewMenu/sx';

const MenuItemIcon = ({
  children,
  sx = {},
}: PropsWithChildren<{ sx?: SxProps<Theme> | undefined }>) => {
  const customSx = useSx();
  return (
    <ListItemIcon sx={sx}>
      <Icon sx={customSx.icon}>{children}</Icon>
    </ListItemIcon>
  );
};

export default MenuItemIcon;

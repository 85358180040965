import { IEditor, EditorPlugin, PluginEvent } from 'roosterjs';

const supportedEvents = [10, 3, 22, 0];
class UpdateTagsPlugin implements EditorPlugin {
  private editor: IEditor | null | undefined;

  private handleInputChange: (
    values: any,
    isTagging: boolean,
    node: Node | undefined,
    cursorPosition: number | undefined,
  ) => void;

  private isTagging: boolean;

  private taggedNode: Node | undefined;

  private cursorPosition: number | undefined;

  constructor({
    handleInputChange,
  }: {
    handleInputChange: (
      values: any,
      isTagging: boolean,
      node: Node | undefined,
      cursorPosition: number | undefined,
    ) => void;
  }) {
    this.handleInputChange = handleInputChange;
    this.isTagging = false;
  }

  getName() {
    return 'UpdateTagsPlugin';
  }

  initialize(editor: IEditor) {
    this.editor = editor;
  }

  dispose() {
    this.editor = null;
  }

  onPluginEvent(event: PluginEvent) {
    if (supportedEvents.includes(event.eventType)) {
      if (event.eventType === 10) {
        event.sanitizingOption.additionalAllowedAttributes = ['white-space'];
        event.sanitizingOption.cssStyleCallbacks['background-color'] = () => false;
        event.sanitizingOption.cssStyleCallbacks['font-family'] = () => false;
        event.sanitizingOption.cssStyleCallbacks.position = () => false;
        event.sanitizingOption.cssStyleCallbacks.overflow = () => false;
        event.sanitizingOption.cssStyleCallbacks.color = () => false;
        event.sanitizingOption.cssStyleCallbacks['white-space'] = () => false;
      }
      const node = this.editor?.getFocusedPosition();
      const getNode = node?.node;

      if (!node?.node.isSameNode(this.taggedNode || null)) {
        this.isTagging = false;
        this.taggedNode = undefined;
        this.cursorPosition = undefined;
      } else {
        if (this.isTagging && (this.cursorPosition || -1) > node.offset) {
          this.isTagging = false;
          this.taggedNode = undefined;
          this.cursorPosition = undefined;
        }
      }

      if (event.eventType === 3) {
        if (event.rawEvent.data === '@') {
          this.isTagging = true;
          this.cursorPosition = node?.offset;
          this.taggedNode = getNode;
        }

        if (this.isTagging && event.rawEvent.data === ' ') {
          this.isTagging = false;
          this.taggedNode = undefined;
          this.cursorPosition = undefined;
        }
      }
      this.handleInputChange(
        this.editor?.getContent(),
        this.isTagging,
        this.taggedNode,
        this.cursorPosition,
      );
    }
  }

  setContent(value: string) {
    this.editor?.setContent(value);
  }
}

export default UpdateTagsPlugin;

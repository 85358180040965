import React, { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import If from '@components/If';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import useSx from './sx';
import useSettingsState from './useSettingsState';

const Settings = () => {
  const { settingsItems, setAnchor, anchor } = useSettingsState();
  const sx = useSx();

  const navigate = useNavigate();

  const handleSettingsClick = (e: SyntheticEvent) => {
    setAnchor(e.currentTarget);
  };

  const handleMenuItemClick = async (route: string) => {
    navigate(`/settings/${route}`);

    setAnchor(undefined);
  };

  return (
    <If condition={settingsItems.length > 0}>
      <Tooltip title="Configuraciones">
        <IconButton sx={sx.root} onClick={handleSettingsClick}>
          <SettingsOutlinedIcon />
        </IconButton>
      </Tooltip>
      <SettingsMenu
        anchorEl={anchor}
        onMenuItemClick={handleMenuItemClick}
        onClose={() => setAnchor(undefined)}
        settingsItems={settingsItems}
      />
    </If>
  );
};

export default Settings;

import { patch } from '@core/api/baseApi';
import { UpdateAssignTechnicianResponse } from '@core/types';

const assignTechnician = async (studyInstanceUid: string, userUid: string): Promise<UpdateAssignTechnicianResponse> => 
    await patch(`/Studies/${studyInstanceUid}/technician`, {
        userUid: userUid,
        studyInstanceUid
    });

export default assignTechnician;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '10px',
        alignItems: 'center',
        marginTop: '2rem',
    }
});

export default useSx;

import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Box, Tooltip, Typography } from '@mui/material';
import If from '@components/If';
import { TemplateFile, TemplateFolder } from '@core/types';
import FolderInput from '../FolderInput';
import useSx from './sx';

type Props = {
  folderItem: TemplateFolder | TemplateFile;
  onTemplateClick: (templateFile: TemplateFile) => void;
  onFolderClick: (templateFolder: TemplateFolder) => void;
  onFolderEdit: (newTemplateFolderName: string, folder: TemplateFolder) => void;
};

const FolderItem = ({ folderItem, onTemplateClick, onFolderClick, onFolderEdit }: Props) => {
  const [selectedItem, setSelectedItem] = useState<TemplateFolder | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const clickTimeout = useRef<number | undefined>(undefined);
  const sx = useSx();

  useEffect(() => {
    if (selectedItem && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [selectedItem]);

  const handleTemplateClick = (item: TemplateFolder | TemplateFile) => {
    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
    }

    clickTimeout.current = window.setTimeout(() => {
      const selectedFunc = item.isFile ? onTemplateClick : onFolderClick;
      selectedFunc(item as never);
      clickTimeout.current = undefined;
    }, 250);
  };

  const handleDoubleClick = (item: TemplateFolder | TemplateFile) => {
    window.clearTimeout(clickTimeout.current);
    clickTimeout.current = undefined;

    if (item.isFile) return;

    setSelectedItem(item as TemplateFolder);
  };

  const isEditingFolder =
    !folderItem.isFile && folderItem.folderHierarchyItemId === selectedItem?.folderHierarchyItemId;

  const itemDate = dayjs.utc(folderItem.modifiedDate).local().format('DD/MM/YYYY h:mm A');

  return (
    <Box
      sx={sx.root}
      onDoubleClick={() => {
        handleDoubleClick(folderItem);
      }}
      onClick={() => {
        handleTemplateClick(folderItem);
      }}
    >
      <If condition={folderItem.isFile} fallback={<FolderIcon sx={sx.coloredIcon} />}>
        <InsertDriveFileIcon sx={sx.Icon} />
      </If>
      <If
        condition={isEditingFolder}
        fallback={<Typography sx={sx.templateName}>{folderItem.name}</Typography>}
      >
        <FolderInput
          inputRef={inputRef}
          onNewFolderNameAccepted={(newFolderName) => {
            if (!!selectedItem && newFolderName.length > 0) {
              onFolderEdit(newFolderName, folderItem as TemplateFolder);
            }
            setSelectedItem(null);
            inputRef.current = null;
          }}
          sx={sx.templateName}
        />
      </If>
      <If condition={folderItem.organizationWide}>
        <Tooltip title="Organizacíon">
          <ApartmentIcon sx={sx.organizationIcon} />
        </Tooltip>
      </If>
      <Typography sx={sx.templateDate}>{itemDate}</Typography>
    </Box>
  );
};

export default FolderItem;

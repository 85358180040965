import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Payload, updateStudyComment } from '../api/updateStudyComment';
import { GET_STUDY_COMMENTS_QUERY_KEY } from './useGetStudyComments';

const useUpdateStudyComment = () => {
  const queryClient = useQueryClient()
  const { isLoading: isUpdating, mutateAsync: updateStudyCommentAsync } = useMutation({
    mutationFn: (studyComment: Payload) => updateStudyComment(studyComment),
    onSuccess: () => {
      queryClient.invalidateQueries([GET_STUDY_COMMENTS_QUERY_KEY])
    }
  });

  return { isUpdating, updateStudyCommentAsync };
};

export default useUpdateStudyComment;

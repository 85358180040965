import { useQuery, UseQueryResult } from '@tanstack/react-query';
import useProfile from '@core/hooks/useProfile';
import { UserOrganizationModel } from '@core/types';
import getUserOrganizations from '../api/getUserOrganizations';

/* eslint-disable @typescript-eslint/indent */
export type UseUserOrganizationsResult = Omit<
  UseQueryResult<UserOrganizationModel[], any>,
  'data'
> & { organizations: UserOrganizationModel[] };
/* eslint-enable @typescript-eslint/indent */

const QUERY_KEY = 'userOrganizations';

const useUserOrganizations = (userUid = ''): UseUserOrganizationsResult => {
  const { data: organizations = [], ...rest } = useQuery({
    queryFn: () => getUserOrganizations(),
    queryKey: [QUERY_KEY, userUid],
    staleTime: 1000 * 60 * 60 * 12,
    cacheTime: 1000 * 60 * 60 * 12.5,
  });

  return { organizations, ...rest };
};

export default useUserOrganizations;

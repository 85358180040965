import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        display: 'flex',
        alignSelf: 'flex-end',
        gap: '10px',
        alignItems: 'center'
    }
});

export default useSx;

import React from 'react';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { UserOrganizationModel } from '@core/types';
import capitalizeText from '@core/utils/capitalizeText';

export interface OrganizationListProps {
  organizations: UserOrganizationModel[];
  onClick: (organizationId: string) => void;
}

const OrganizationList = ({ organizations, onClick }: OrganizationListProps) => {
  return (
    <List>
      {organizations.map(({ organizationProductId, organizationName }) => (
        <ListItemButton key={organizationProductId} onClick={() => onClick(organizationProductId)}>
          <ListItemAvatar>
            <Avatar>
              <ApartmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={capitalizeText(organizationName)} />
        </ListItemButton>
      ))}
    </List>
  );
};

export default OrganizationList;

import React from 'react';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import If from '@components/If';
import useSx from './sx';

interface NavButtonsProps {
  showPrevious: boolean;
  showNext: boolean;
  changeSelectedReport: (p: 'PREVIOUS' | 'NEXT') => void;
}

export default function NavButtons({
  showPrevious,
  showNext,
  changeSelectedReport,
}: NavButtonsProps) {
  const sx = useSx();

  return (
    <>
      <If condition={showPrevious}>
        <Box sx={sx.navButton} left={40}>
          <Button onClick={() => changeSelectedReport('PREVIOUS')} variant="contained">
            <ArrowBack />
          </Button>
        </Box>
      </If>
      <If condition={showNext}>
        <Box sx={sx.navButton} right={40}>
          <Button onClick={() => changeSelectedReport('NEXT')} variant="contained">
            <ArrowForward />
          </Button>
        </Box>
      </If>
    </>
  );
}

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  drawerOverrides: {
    '& .MuiDrawer-paper': {
      padding: '0 1rem 1rem 1rem',
      width: {
        xs: '100vw',
        md: '650px',
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    top: '0',
    zIndex: '100',
    padding: '1.5rem 0',
    backgroundColor: (theme) => theme.palette.shade?.[50],
  },
});

export default useSx;

import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    '& .HeaderTools-banner': {
      flexGrow: 1,
      px: 8,
    },
    '& .MuiToolbar-root': {
      px: 0,
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiAlert-root': {
      bgcolor: 'tooltip.main',
    },
    '.profile-container': {
      ...expandDisplayFlex({ a: 'center' }),
      gap: '12px',
    },
    height: '64px',
  },
  headerContainer: {
    margin: 0,
    width: '100%',
    padding: 0,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  loginButton: {
    marginRight: '1.5rem',
  },
});

export default useSx;

import React from 'react';
import Box from '@mui/material/Box';
import { ThemeVariant } from '@core/types/Theme';
import ModeCancel from './ModeCancel';
import ModePreview from './ModePreview';
import useModeOptionsState from './useModeOptionsState';

const ModeOptions = () => {
  const { sx } = useModeOptionsState();

  return (
    <Box sx={sx.root}>
      <ModePreview
        mode="Dark"
      />
      <ModePreview
        mode="Light"
      />
      <ModeCancel />
    </Box>
  );
};

export default ModeOptions;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { STUDY_QUERY_KEY } from '@core/hooks/useStudyInfo';
import { GetStudyResponse } from '@core/types';
import updateStudyPriority from '../api/updateStudyPriority';
import { QUERY_KEY as studies } from './useStudies';

const useUpdatePriority = () => {
  const queryClient = useQueryClient()
  const { isLoading: isUploading, mutateAsync: updateStudyPriorityAsync } = useMutation({
    mutationFn: ({studyInstanceUid, priority}:{ studyInstanceUid: string, priority: boolean}) => updateStudyPriority(studyInstanceUid, priority),
    onSuccess: (_, variables) => {
        queryClient.invalidateQueries([studies])

        queryClient.setQueryData<GetStudyResponse>(
          [STUDY_QUERY_KEY, variables.studyInstanceUid],
          (existingStudy) => {
            if (existingStudy) {
              existingStudy.priority = variables.priority;
            }
      
            return existingStudy;
          },
        );
      }
    }
  );

  return { isUploading, updateStudyPriorityAsync };
};

export default useUpdatePriority;

import React from 'react';
import useViewportSize from '@core/hooks/useViewportSize';
import LargeViewMenu from './LargeViewMenu';
import NotificationsMenuProps from './NotificationsMenuProps';
import SmallViewMenu from './SmallViewMenu';

const NotificationsMenu = ({
  anchorEl,
  onMenuItemClick,
  onClose,
  notifications,
  handleCancelNotification,
}: NotificationsMenuProps) => {
  const { isSmallViewport } = useViewportSize();
  const MenuComponent = isSmallViewport ? SmallViewMenu : LargeViewMenu;

  return (
    <MenuComponent
      anchorEl={anchorEl}
      onMenuItemClick={onMenuItemClick}
      onClose={onClose}
      notifications={notifications}
      handleCancelNotification={handleCancelNotification}
    />
  );
};

export default NotificationsMenu;

import React from 'react';
import { Formik, FormikState } from 'formik';
import Box from '@mui/material/Box';
import InputWithLabel from '@components/InputWithLabel';
import { ChangePasswordRequest } from 'app/features/users/api/changePassword';
import { validationSchema } from 'app/features/users/utils/passwordValidation';
import Actions from './Actions';
import inputs from './inputValues';
import usePasswordFormState from './usePasswordFormState';

interface FormValues {
  newPassword: string;
  confirmPassword: string;
}

const initialValues: FormValues = {
  newPassword: '',
  confirmPassword: '',
};

const PasswordForm = () => {
  const { sx, showSnackbar, changeUserPassword, isLoading, userUid } =
    usePasswordFormState();

  const handleSubmitChangePassword = async (
    values: FormValues,
    formikHelpers: {
      setValues: (values: FormValues) => void;
      resetForm: (nextState?: Partial<FormikState<FormValues>>) => void;
    },
  ) => {
    try {
      if (values.newPassword !== values.confirmPassword) {
        showSnackbar({
          message: 'La nueva contraseña y la confirmación no coinciden.',
          title: 'Atención',
          type: 'warning',
        });
        return;
      }

      const payload: ChangePasswordRequest = {
        oldPassword: '',
        newPassword: values.newPassword,
        userUid: userUid,
      };

      const result = await changeUserPassword(payload);
      if (result.success) {
        showSnackbar({
          message: 'Contraseña cambiada con éxito.',
          title: 'Contraseña actualizada',
          type: 'success',
        });
        formikHelpers.resetForm({ values: initialValues });
      }
    } catch (error) {
      console.error('Error al cambiar la contraseña:', error);
      showSnackbar({
        message: 'Hubo un error al cambiar la contraseña. Inténtalo de nuevo.',
        title: 'Ocurrió un error',
        type: 'error',
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, formikHelpers) => handleSubmitChangePassword(values, formikHelpers)}
    >
      {({ values, handleSubmit, handleChange, errors, touched, resetForm }) => {
        return (
          <Box sx={sx.formContainer}>
            <Box onSubmit={handleSubmit} component="form" sx={sx.root}>
              {(Object.keys(values) as Array<keyof typeof values>).map((input) => (
                <InputWithLabel
                  key={input}
                  label={inputs[input].label}
                  name={input}
                  onChange={handleChange}
                  value={values[input]}
                  placeholder={inputs[input].placeholder}
                  required={!!inputs[input].required}
                  error={!!errors[input] && touched[input]}
                  helperText={touched[input] ? errors[input] : undefined}
                  type="password"
                />
              ))}
              <Actions isLoading={isLoading} resetForm={resetForm} />
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
};

export default PasswordForm;

import * as React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import MuiTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import If from '@components/If';
import { nameDate, nameTime } from '@core/utils/formatDate';
import getTitleByNotificationCategory from '@core/utils/getTitleByNotificationCategory';
import PaginationRow from './PaginationRow';
import TableProps from './TableProps';
import useSx from './sx';

const Table = ({
  notifications,
  onTableCellCheck,
  selectedNotificationsIds,
  pageCount,
  totalCount,
  onNotificationClick,
}: TableProps) => {
  const sx = useSx();
  const getNotificationClassname = (isSeen: boolean) => {
    switch (isSeen) {
      case false:
        return ' table-row table-status-unseen';
      default:
        return 'table-row';
    }
  };
  return (
    <TableContainer sx={sx.root} component={Paper}>
      <MuiTable aria-label="notifications table">
        <MuiTableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell className="header-text">Categoría</TableCell>
            <TableCell className="header-text">Descripción</TableCell>
            <TableCell className="header-text">Fecha</TableCell>
          </TableRow>
        </MuiTableHead>
        <TableBody>
          {notifications.map((notification) => (
            <TableRow
              onClick={(e) => {
                e.stopPropagation();
                onNotificationClick(notification);
              }}
              key={notification.notificationMessageId}
              className={getNotificationClassname(notification.isSeen)}
            >
              <TableCell align="right" className="icon-seen-col">
                <If condition={!notification.isSeen}>
                  <FiberManualRecordIcon fontSize="inherit" color="primary" />
                </If>
              </TableCell>
              <TableCell align="center">
                <Checkbox
                  onChange={(e, checked) => {
                    onTableCellCheck(notification, checked);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  checked={!!selectedNotificationsIds.get(notification.notificationMessageId)}
                  aria-label="Seleccionar esta notificación"
                />
              </TableCell>
              <TableCell>
                {getTitleByNotificationCategory(notification.notificationMessageCategoryId)}
              </TableCell>
              <TableCell>{JSON.parse(notification.metadata).description}</TableCell>
              <TableCell>
                {nameDate(notification.createdDate)} a las{' '}
                {nameTime(
                  new Date(notification.createdDate).getHours(),
                  new Date(notification.createdDate).getMinutes(),
                )}
              </TableCell>
            </TableRow>
          ))}
          <PaginationRow totalCount={totalCount} pageCount={pageCount} />
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;

import { post } from '@core/api/baseApi';
import { CustomErroHandlerResponse, EmailFormRequest, EmailOrPhoneFormRequest } from '@core/types';

const shareByEmail = async (
  studyInstanceUid: string,
  shareInfo: EmailFormRequest | EmailOrPhoneFormRequest,
): Promise<CustomErroHandlerResponse | void> =>
  await post(`/studies/${studyInstanceUid}/share-by-email`, shareInfo);

export default shareByEmail;

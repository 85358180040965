import { useQuery } from '@tanstack/react-query';
import getAssignedReferrals from '../api/getAssignedReferrals';

export const QUERY_KEY = 'ASSIGNED_REFERRALS_LIST';

const useGetAssignedReferrals = (studyInstanceUid: string, enabled = false) => {
  const { data: assignedReferrals, ...rest } = useQuery({
    queryKey: [QUERY_KEY, studyInstanceUid],
    queryFn: () => getAssignedReferrals(studyInstanceUid),
    staleTime: 1000 * 60 * 60 * 12, // 12 hours
    cacheTime: 1000 * 60 * 60 * 12.5, // 12.5 hours
    retry: false,
    enabled
  });

  return { assignedReferrals, ...rest };
};

export default useGetAssignedReferrals;

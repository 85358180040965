import React from 'react';
import If from '@components/If';
import ViewerDialog from '@components/ViewerDialog';
import ViewerDrawer from '@components/ViewerDrawer';
import { GetStudyResponse } from '@core/types';
import ViewerInfoCommand from '@core/types/ViewerInfoCommand';
import buildCommand from './buildCommand';

export interface InfoManagerProps {
  studyInstanceUid?: string;
  readOnly?: boolean;
  command?: ViewerInfoCommand;
  studyInfo: GetStudyResponse | undefined;
  onClose?: VoidFunction;
}

const InfoManager = ({
  studyInstanceUid,
  command,
  onClose,
  readOnly = false,
  studyInfo,
}: InfoManagerProps) => {
  const { title, subtitle, component: CommandComponent } = buildCommand(command, readOnly);

  if (command === 'info') {
    return (
      <ViewerDrawer open={!!command} onClose={onClose} title={title} subtitle={subtitle}>
        <If condition={!!studyInstanceUid}>
          <CommandComponent
            studyInstanceUid={studyInstanceUid!}
            onClose={onClose}
            readOnly={readOnly}
            open={!!command}
            studyInfo={studyInfo}
          />
        </If>
      </ViewerDrawer>
    );
  }

  return (
    <ViewerDialog open={!!command} onClose={onClose} title={title} subtitle={subtitle}>
      <If condition={!!studyInstanceUid}>
        <CommandComponent
          studyInstanceUid={studyInstanceUid!}
          onClose={onClose}
          readOnly={readOnly}
          open={!!command}
          studyInfo={studyInfo}
        />
      </If>
    </ViewerDialog>
  );
};

export default InfoManager;

import React, { useEffect, useState } from 'react';
import If from '@components/If';
import useOrganizationTemplate from '@core/hooks/useOrganizationTemplate';
import useUpdateOrganizationTemplate from '@core/hooks/useUpdateOrganizationTemplate';
import { OrganizationTemplateModel } from '@core/types/OrganizationTemplate';
import AddPdfTemplateForm from '../../AddPdfTemplateForm';

async function createFile(url: string, filename: string) {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = {
    type: 'image/jpeg',
  };
  const file = new File([data], filename, metadata);
  return file;
}

const ReplaceDialog = ({
  template,
  open,
  onClose,
}: {
  template: OrganizationTemplateModel;
  open: boolean;
  onClose: VoidFunction;
}) => {
  const [initialValues, setInitialValues] = useState<{ file: File | null; title: string }>({
    file: null,
    title: template.title,
  });

  const { organizationTemplate } = useOrganizationTemplate(template.templateId);
  const { updateOrganizationTemplateAsync, isUpdating } = useUpdateOrganizationTemplate(
    template.templateId,
  );

  const handleSubmit = async (values: { file: File | null; title: string }) => {
    const formData = new FormData();
    formData.append('file', values.file!, values.file?.name || '');
    formData.append('title', values.title);

    await updateOrganizationTemplateAsync(formData);
    onClose();
  };

  useEffect(() => {
    const updateFile = async () => {
      if (organizationTemplate?.templateUri) {
        const file = await createFile(organizationTemplate?.templateUri || '', template.fileName);
        setInitialValues((prev) => ({ ...prev, file }));
      }
    };
    updateFile();
  }, [organizationTemplate?.templateUri, template.fileName]);

  return (
    <If condition={!!initialValues.file}>
      <AddPdfTemplateForm
        handleSubmit={handleSubmit}
        open={open}
        onClose={onClose}
        initialValues={initialValues}
        disabledActions={isUpdating}
      />
    </If>
  );
};

export default ReplaceDialog;

import SxDef from '@core/types/SxDef';

const useSx = (transparent: boolean | undefined): SxDef => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh', 
    display:"flex", 
    justifyContent: "center", 
    alignItems: "center",
    backgroundColor: !transparent ? '#01070C' : 'transparent'
  },
});

export default useSx;

import React from 'react'
import Box from '@mui/material/Box'
import Title from '@components/Title';
import useSx from './sx';

const Header = () => {
    const sx = useSx()

    return (
        <Box sx={sx.root}>
            <Title text='Preferencias' />
        </Box>
    )
}

export default Header
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteOrganizationLogo } from '@core/api/deleteOrganizationLogo';
import { ORGANIZATION_SETTINGS_QUERY_KEY } from './useOrganizationSettings';

const useDeleteOrganizationLogo = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    deleteOrganizationLogo,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ORGANIZATION_SETTINGS_QUERY_KEY]);
      },
      onError: (e) => {
        console.error('Error al eliminar el logo de la organización:', e);
      }
    }
  );

  return { mutate, isLoading, isError, isSuccess, error };
};

export default useDeleteOrganizationLogo;

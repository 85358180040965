import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: {
        marginTop: '1rem',
        marginBottom: '1rem',
        '.custom-icon': {
            color: (theme) => theme.palette.primary.main
        }
    },
});

export default useSx;

import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (sidebar: boolean, sidebarCollapsed: boolean): SxDef => ({
  root: {
    '.main-container': {
      boxSizing: 'border-box',
      maxWidth: '100vw',
      marginLeft: { xs: 0, sm: sidebarCollapsed ? '70px' : '200px' },
      padding: {
        xs: '1rem',
        sm: '2rem',
      },
      flex: 1,
      ...expandDisplayFlex({ d: 'column' }),
      transition: 'margin-left 0.3s',
      '@media (min-width:1921px)': {
        maxWidth: '1536px',
        margin: '0 auto',
      },
    },
    '.main-drawer-container': {
      display: { xs: 'block', sm: sidebar ? 'block' : 'none' },
      height: 'calc(100% - 64px)',
      position: 'fixed',
      top: '64px',
      width: {
        xs: '0',
        sm: sidebarCollapsed ? '70px' : '200px',
      },
      transition: 'width 0.3s',
      backgroundColor: (theme) => theme.palette.primary.main,
    },
  },
});
export default useSx;

import React, { PropsWithChildren } from 'react';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import { RoleName } from '@core/types';
import RestrictToRoleProps from './RestrictToRoleProps';

const RestrictToRole = ({
  allowedRoles,
  isAdminAllowed = false,
  isOwnerAllowed = false,
  children,
}: PropsWithChildren<RestrictToRoleProps>) => {
  const { role, hasAdminPermissions, hasOwnerPermissions } = useProfile();
  const isAdminUserAndIsAdminAllowed = hasAdminPermissions && isAdminAllowed;
  const isOwnerUserAndIsOnwerAllowed = hasOwnerPermissions && isOwnerAllowed;
  const isUserRoleAllowed = allowedRoles.includes(role || RoleName.None);

  return (
    <If
      condition={isUserRoleAllowed || isOwnerUserAndIsOnwerAllowed || isAdminUserAndIsAdminAllowed}
    >
      {children}
    </If>
  );
};

export default RestrictToRole;

import { publish } from '@core/constants/customEvents';
import { ButtonFactory } from './buttonRegistryFactory';

const uploadAudioButton: ButtonFactory = () => {
  return {
    key: 'uploadAudioButton',
    unlocalizedText: 'Upload Audio',
    iconName: 'OpenFile',
    onClick: async () => {
      publish('evodicom.report.audioUpload', undefined);
    },
  };
};

export default uploadAudioButton;

import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import useFilters from 'app/features/notifications/get/hooks/useFilters';
import useSx from './sx';

const SELECT_PAGE_SIZE_RANGES = ['5', '10', '15', '20'];

const PageSizeSelect = () => {
  const sx = useSx();
  const [filters, setFilter] = useFilters();

  return (
    <Select
      size="small"
      label="Test"
      onChange={(e: any) => setFilter('PageSize', e.target.value)}
      value={filters.PageSize}
      MenuProps={{
        PaperProps: { sx: sx.paper },
      }}
      sx={{ '.MuiSvgIcon-root': { color: (theme) => theme.palette.primary.main } }}
    >
      {SELECT_PAGE_SIZE_RANGES.map((range) => (
        <MenuItem key={range} id={range} value={range}>
          {range}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PageSizeSelect;

import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, TextField } from '@mui/material';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import ActionButton from '@components/Button/ActionButton';
import ViewerDialog from '@components/ViewerDialog';
import useProfile from '@core/hooks/useProfile';
import { TemplateFile } from '@core/types';
import FolderView, { findTemplateOnFolder } from '../../components/FolderView';
import FolderFormProps from './FolderFormProps';
import useSx from './sx';

const INITIAL_MODAL: {
  open: boolean;
  payload: TemplateFile | null;
} = {
  open: false,
  payload: null,
};

const validationSchema = Yup.object().shape({
  templateName: Yup.string()
    .min(1, 'Por favor introduzca un título')
    .required('Por favor introduzca un título'),
});

const FolderForm = ({
  isOpen,
  initialFolderPath,
  onTemplateClick,
  onEditFolder,
  onClose,
  initialValues,
  foldersAndTemplates,
  handleAddNewFolder,
  handleOnSave,
}: FolderFormProps) => {
  const { userUid } = useProfile();
  const [confirmModal, setConfirmModal] = useState(INITIAL_MODAL);
  const [folderPath, setFolderPath] = useState(initialFolderPath);
  const sx = useSx();

  const userOwnsTemplate = confirmModal.payload?.ownerUserUid === userUid;

  const onSaveMediator = (values: { templateName: string }) => {
    const payload = {
      templateName: values.templateName,
      folderId: folderPath.at(-1),
      folderPath,
    };

    const template = findTemplateOnFolder(foldersAndTemplates, folderPath, values.templateName);

    if (template) setConfirmModal({ open: true, payload: template });
    else handleOnSave(payload);
  };

  const onFileClickMediator = (template: TemplateFile) => {
    setConfirmModal({ open: true, payload: template });
  };

  return (
    <ViewerDialog open={isOpen} onClose={onClose} title="Guardar como">
      <Formik
        initialValues={{ templateName: initialValues.templateName }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSaveMediator}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit, handleChange, errors }) => (
          <Box onSubmit={handleSubmit} component="form" sx={sx.card}>
            <TextField
              disabled={false}
              placeholder="Título"
              name="templateName"
              value={values.templateName}
              onChange={handleChange}
              error={!!errors.templateName}
              fullWidth
              helperText={errors.templateName}
              variant="standard"
              sx={sx.input}
            />
            <FolderView
              itemArray={foldersAndTemplates}
              initialPath={folderPath}
              onFolderNavigation={(folder, folderPathProp) => {
                setFolderPath(folderPathProp);
              }}
              onEditFolder={onEditFolder}
              onTemplateClick={onFileClickMediator}
              smallView
              enableLocalSearch
            />
            <Box sx={sx.footer}>
              <ActionButton
                text="Nueva carpeta"
                variant="outlined"
                color="secondary"
                onClick={() => handleAddNewFolder(folderPath)}
                sx={{ marginRight: 'auto' }}
              />

              <ActionButton
                text="Cancelar"
                variant="outlined"
                color="secondary"
                onClick={onClose}
              />
              <ActionButton text="Guardar" variant="contained" color="primary" type="submit" />
            </Box>
          </Box>
        )}
      </Formik>
      <ActionDialog
        open={confirmModal.open}
        onClose={() => setConfirmModal(INITIAL_MODAL)}
        onCancel={() => setConfirmModal(INITIAL_MODAL)}
        onConfirm={async () => {
          if (confirmModal.payload) {
            if (userOwnsTemplate) onTemplateClick?.(confirmModal.payload, folderPath.at(-1));
            else
              handleOnSave({
                templateName: confirmModal.payload.name + ' (copia)',
                folderId: folderPath.at(-1),
                folderPath,
              });
          }
          setConfirmModal(INITIAL_MODAL);
        }}
        title={
          userOwnsTemplate
            ? 'Este archivo ya existe, ¿desea reemplazarlo?'
            : '¿Desea hacer una copia de este archivo?'
        }
        type="info"
        closeText="Cancelar"
        confirmText={userOwnsTemplate ? 'Reemplazar' : 'Copiar'}
      />
    </ViewerDialog>
  );
};
export default FolderForm;

import React, { PropsWithChildren, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider'
import LargeView from '@components/Views/LargeView';
import WindowTitle from '../../WindowTitle';
import Subtitle from './Subtitle';
import Title from './Title';
import useSx from './sx';

export interface ContentLayoutProps {
  title?: string;
  subtitle?: ReactNode;
}

const ContentLayout = ({ title, subtitle, children }: PropsWithChildren<ContentLayoutProps>) => {
  const sx = useSx();

  return (
    <Box className="EvoContentLayout-root" component="main" sx={sx.root}>
      <WindowTitle title={title} />

      {/* Subheader */}
      <Container
        className="EvoContentLayoutSubheader-root"
        maxWidth="xl"
        disableGutters
      >
        <Box sx={sx.title}>
          <LargeView>
            <Title title={title} />
          </LargeView>  
        </Box>
        <Box sx={sx.subtitle}>
          <Subtitle subtitle={subtitle} />
        </Box>
        <Divider sx={sx.divider} />
      </Container>

      {/* Main content */}
      <Container className="EvoContentLayoutMain-root" maxWidth="xl" disableGutters>
        {children}
      </Container>
    </Box>
  );
};

export default ContentLayout;

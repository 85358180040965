import React, { useState, useEffect } from 'react';
import useProfile from '@core/hooks/useProfile';
import useSnackbar from '@core/hooks/useSnackbar';
import { useDeleteSignature, useGetSignature, usePostSignature } from '../../hooks/useSignature';
import useSx from './sx';

const useProfessionalInfoState = () => {
  const showSnackbar = useSnackbar();
  const { userUid } = useProfile();
  const sx = useSx();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [info, setInfo] = useState<string>('');
  const { postUserSignature } = usePostSignature();
  const { deleteUserSignature } = useDeleteSignature(false, true);
  const { data, isSuccess, refetch } = useGetSignature();

  useEffect(() => {
    if (data && data.professionalInfo && data.professionalInfo !== '') {
      setInfo(data.professionalInfo);
    }
  }, [data, data?.professionalInfo]);

  return {
    showSnackbar,
    userUid,
    sx,
    isSaving,
    setIsSaving,
    isDeleting,
    setIsDeleting,
    postUserSignature,
    deleteUserSignature,
    data,
    isSuccess,
    refetch,
    info, 
    setInfo
  };
};
export default useProfessionalInfoState;

import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { getGeneratedReports } from '@core/api/getGeneratedReports';

export type UseGeneratedReportIdsResult = Omit<UseQueryResult<any, any>, 'data'> & {
  generatedReportIds: Array<string>;
};

export const GENERATED_REPORTS_QUERY_KEY = 'GeneratedReportIds';

const useGeneratedReports = (studyId: string): UseGeneratedReportIdsResult => {
  const isEnabled = Boolean(studyId);
  const { data: generatedReportIds, ...rest } = useQuery({
    queryFn: () => getGeneratedReports(studyId),
    queryKey: [GENERATED_REPORTS_QUERY_KEY],
    staleTime: 1000 * 60 * 10, // 10 minutes
    cacheTime: 1000 * 60 * 10, // 10 minute
    enabled: isEnabled,
  });

  return { generatedReportIds: generatedReportIds || [], ...rest };
};

export default useGeneratedReports;

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getOrganizationTemplates } from '@core/api/getOrganizationTemplates';
import { GetOrganizationTemplatesResponse } from '@core/types/ApiResponses';

export type UseOrganizationSettingsResult = Omit<UseQueryResult<any, any>, 'data'> & {
  organizationTemplates: GetOrganizationTemplatesResponse | undefined;
};

export const ORGANIZATION_TEMPLATES_QUERY_KEY = 'OrganizationTemplates';

const useOrganizationTemplates = (): UseOrganizationSettingsResult => {
  const { data: organizationTemplates, ...rest } = useQuery({
    queryFn: getOrganizationTemplates,
    queryKey: [ORGANIZATION_TEMPLATES_QUERY_KEY],
    staleTime: 1000 * 60 * 60 * 12,
    cacheTime: 1000 * 60 * 60 * 12.5,
  });

  return { organizationTemplates, ...rest };
};

export default useOrganizationTemplates;

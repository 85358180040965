import React from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import PermissionsTableProps from './PermissionsTableProps';
import useSx from './sx';

const TABLE_ROWS = ['Regla', 'Sucursales', 'Modalidades'];

export default function PermissionTable({
  permissionArray,
  removePermission,
}: PermissionsTableProps) {
  const sx = useSx();

  return (
    <TableContainer component={Paper}>
      {/* This style change needs to be applied over the class that we are allowed to modify with 'sx'. */}
      <Table className="table" aria-label="simple table" style={{ tableLayout: 'auto' }}>
        <TableHead>
          <TableRow>
            {TABLE_ROWS.map((title) => (
              <TableCell key={title}>{title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {permissionArray.map((permission) => (
            <TableRow key={permission?.permissionRuleId}>
              <TableCell>{permission?.name}</TableCell>
              <TableCell>
                <Box sx={sx.tableCell}>
                  {permission?.applicableBranches.map((branch) => (
                    <Chip key={branch.id} label={branch.name} />
                  ))}
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={sx.tableCell}>
                  {permission?.applicableModalities.map((modalidad) => (
                    <Chip key={modalidad} label={modalidad} />
                  ))}
                  <DeleteOutlineOutlinedIcon
                    onClick={() => removePermission(permission?.permissionRuleId)}
                    sx={sx.deleteIcon}
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select, { BaseSelectProps } from '@mui/material/Select';
import countryCodes from '@core/constants/countryCodes';
import useSx from './sx';

const CountryCodeDropdown = ({ sx, ...selectProps }: BaseSelectProps) => {
  const customSx = useSx();

  const menuProps: Omit<MenuProps, 'open'> = {
    PaperProps: {
      style: {
        maxHeight: 300,
        maxWidth: 120,
      },
      sx: customSx.paper,
    },
  };

  const combinedSx = { ...customSx.selectRoot, ...sx };

  return (
    <FormControl sx={combinedSx}>
      <InputLabel className="CountryCodeLabel" id="country-code-label">
        {selectProps.label}
      </InputLabel>
      <Select
        labelId="country-code-label"
        id="country-code-select"
        className="CountryCodeSelect"
        label={selectProps.label}
        size="small"
        MenuProps={menuProps}
        {...selectProps}
      >
        {countryCodes.map((country) => (
          <MenuItem className="CountryCodeMenuItem" key={country.code} value={country.dial_code}>
            <img
              className="CountryCodeImage"
              src={`https://flagsapi.com/${country.code}/flat/16.png`}
              alt={country.name}
            />
            +{country.dial_code}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CountryCodeDropdown;

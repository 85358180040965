type EventDataMap = {
  'evodicom.report.changeTemplate': { templateId: string };
  'evodicom.report.makeConclusion': undefined;
  'evodicom.report.audioUpload': undefined;
  'evodicom.report.record': undefined;
  'evodicom.report.translate': undefined;
  'evodicom.report.initialTemplate': undefined;
  'evodicom.report.insertQrCode': undefined;

  // Agrega más eventos según sea necesario
};

type RegisteredEvents = keyof EventDataMap;

function subscribe<E extends RegisteredEvents>(
  eventName: E,
  listener: (data: EventDataMap[E]) => void,
) {
  const eventRef: EventListener = (event: Event) => {
    listener((event as CustomEvent).detail);
  };

  document.addEventListener(eventName, eventRef);

  return eventRef;
}

function unsubscribe<E extends RegisteredEvents>(eventName: E, listener: EventListener) {
  document.removeEventListener(eventName, listener);
}

function publish<E extends RegisteredEvents>(eventName: E, data: EventDataMap[E]) {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

export { publish, subscribe, unsubscribe };

import { useMutation, useQueryClient } from '@tanstack/react-query';
import revokeReferralStudyAccess from '../api/revokeReferralStudyAccess';
import { QUERY_KEY as ASSIGNED_REFERRALS_LIST } from './useGetAssignedReferrals';

export const QUERY_KEY = 'REVOKE_RADIOLOGIST';

const useRevokeReferralStudyAccess = () => {
    const queryClient = useQueryClient()
    const {  isLoading: isUploading, mutateAsync: revokeReferralAccess } = useMutation({
        mutationFn: ({studyInstanceUid, userUid}:{ studyInstanceUid: string, userUid: string}) => revokeReferralStudyAccess(studyInstanceUid, userUid),
        onSuccess: () => {
            queryClient.invalidateQueries([ASSIGNED_REFERRALS_LIST])
        }
    });

  return { revokeReferralAccess, isUploading };
};

export default useRevokeReferralStudyAccess;

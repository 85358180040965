import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import { RoleName } from '@core/types';
import NameCell from './NameCell';
import useSx from './sx';

const TableHeader = () => {
    const sx = useSx()
    const { role, hasAdminPermissions } = useProfile()
    return (
        <TableHead>
            <TableRow
                sx={sx.root}
            >
                <NameCell />
                <TableCell>Correo</TableCell>
                <If condition={role !== RoleName.Referrer}>
                    <TableCell>Administrador</TableCell>
                </If>
                <If condition={hasAdminPermissions}>
                    <TableCell></TableCell>
                </If>
            </TableRow>
        </TableHead>
    )
}

export default TableHeader
import { useQuery } from '@tanstack/react-query';
import { getStudyReport } from '../api/getStudyReport';

const GET_STUDY_REPORTS = 'GetStudyReports';

const useGetStudyReport = (studyHash: string) => {
  const { data, ...rest } = useQuery({
    queryFn: () => getStudyReport({ studyHash }),
    queryKey: [GET_STUDY_REPORTS],
    staleTime: 1000 * 60,
  });

  const reportsUrls = data?.reportsUrls || [];
  return { reportsUrls, ...rest };
};

export default useGetStudyReport;

import React from 'react';
import { Link, useRouteError } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import NotFoundIcon from '@assets/icons/NotFoundIcon';
import If from '@components/If';
import { MainLayout } from '@components/Layout';
import { hideStartLoader } from '@core/utils/startLoader';
import useSx from './sx';

export default function ErrorPage({ notLogged = false }: { notLogged?: boolean }) {
  const sx = useSx();
  const error = useRouteError();
  hideStartLoader({});

  console.log('error', error);

  return (
    <MainLayout>
      <Box sx={sx.root}>
        <NotFoundIcon sx={sx.errorIcon} />
        <If condition={!notLogged}>
          <Link to="/">Regresar</Link>
        </If>
        <Typography sx={sx.text} fontSize="16px">
          ¡UPS! algo salió mal...
        </Typography>
        <Typography sx={sx.text} fontSize="14px">
          Lo sentimos, pacece que el estudio que estabas buscando no se encuentra disponible en este
          momento.
          <br />
          Intenta recargar la página o volver en unos momentos.
        </Typography>
      </Box>
    </MainLayout>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import WindowTitle from '@components/WindowTitle';
import useProfile from '@core/hooks/useProfile';
import useStudyInfo from '@core/hooks/useStudyInfo';
import ThemeProvider from '@core/providers/ThemeProvider';
import { RoleName, ViewerInfoCommand } from '@core/types';
import capitalizeText from '@core/utils/capitalizeText';
import InfoManager from '../components/InfoManager';
import Viewer from '../components/Viewer';

const Study = () => {
  const { studyInstanceUid } = useParams() as { studyInstanceUid: string };
  const [searchParams, setSearchParams] = useSearchParams();

  const { studyInfo } = useStudyInfo({
    studyInstanceUidOrHash: studyInstanceUid,
  });
  const { role, hasAdminPermissions } = useProfile();
  const [command, setCommand] = useState<ViewerInfoCommand | undefined>();

  useEffect(() => {
    sessionStorage.removeItem('sessionType');
    sessionStorage.removeItem('studyIds');
  }, []);

  function removeFilter(name: string) {
    const existing: Record<string, string> = {};

    for (const [key, param] of searchParams.entries()) {
      if (key === name) {
        continue;
      }

      existing[key] = param;
    }

    setSearchParams({ ...existing });
  }

  const onInfoManagerClose = () => {
    setCommand(undefined);
    removeFilter('section');
  };

  return (
    <>
      <WindowTitle title={capitalizeText(studyInfo?.patientsFullName || '')} />
      <ThemeProvider>
        <Viewer
          studyInstanceUid={studyInstanceUid}
          readOnly={role === RoleName.Patient}
          isAnon={false}
          isAdministrator={hasAdminPermissions ?? false}
          baseUrl={`/studies/${studyInstanceUid}`}
          onCommandClick={({ commandName }) => setCommand(commandName)}
        />
        <InfoManager
          studyInstanceUid={studyInstanceUid}
          command={command}
          studyInfo={studyInfo}
          readOnly={role === RoleName.Patient}
          onClose={onInfoManagerClose}
        />
      </ThemeProvider>
    </>
  );
};

export default Study;

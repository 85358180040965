import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import If from '@components/If';
import Label from '@components/InputWithLabel/Label';
import DatePickerWithLabelProps from './DatePickerWithLabelProps';
import DatePickerWithMobileOverride from './DatePickerWithMobileOverride';
import useSx from './sx';

const DatePickerWithLabel = ({
  label,
  required,
  error,
  helperText,
  ...datePickerProps
}: DatePickerWithLabelProps) => {
  const sx = useSx();

  return (
    <>
      <Box sx={sx.root} className="datepicker-container">
        <Label label={label} required={required} />
        <DatePickerWithMobileOverride label="" {...datePickerProps} />
      </Box>
      <If condition={error}>
        <Typography marginLeft={{ xs: '0', md: 'auto' }} color="red" lineHeight="0">
          {helperText}
        </Typography>
      </If>
    </>
  );
};

export default DatePickerWithLabel;

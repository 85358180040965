import React from 'react';
import { Button, ButtonProps, CircularProgress, SxProps, Theme, Tooltip } from '@mui/material';
import If from '@components/If';
import useSx from './sx';

interface IconButtonProps extends ButtonProps {
  tooltip: string;
  sx?: SxProps<Theme>;
  tooltipDirection?: 'bottom' | 'left' | 'right' | 'top';
  disabled?: boolean;
  isLoading?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({ tooltip, tooltipDirection = 'bottom', sx: customSx, disabled, variant, color, isLoading, ...props }) => {
  const styles = useSx();

  const combinedSx = {
    ...styles.iconButton,
    ...customSx,
  } as SxProps<Theme>;

  return (
    <Tooltip title={tooltip} placement={tooltipDirection}>
      <Button {...props} sx={combinedSx} disabled={disabled} variant={variant || "contained"} color={color || "primary"}>
        <If condition={!isLoading} fallback={<CircularProgress sx={styles.loader} size={18} />}>
          {props.children}
        </If>
      </Button>
    </Tooltip>
  );
};

export default IconButton;

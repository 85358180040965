import React, { useCallback, useEffect } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import ActionDialog from '@components/ActionDialog/ActionDialog';
import { subscribe, unsubscribe } from '@core/constants/customEvents';
import ModalUploadAudioFile from './ModalUploadAudioFile/ModalUploadAudioFile';
import { UploadAudioActionProps } from './UploadAudioActionProps';
import useUploadAudioActionState from './useUploadAudioActionState';

const ffmpeg = new FFmpeg();

const SPEECH_KEY = process.env.REACT_APP_SPEECH_KEY || '';
const SPEECH_REGION = process.env.REACT_APP_SPEECH_REGION || '';

const UploadAudioAction = ({
  onUpdateTextEditor,
  isTranscribing,
  setIsTranscribing,
}: UploadAudioActionProps) => {
  const {
    showModal,
    setShowModal,
    isLoadingFile,
    setIsLoadingFile,
    transcript,
    setTranscript,
    temporaryTranscript,
    setTemporaryTranscript,
    speechRecognizer,
    setSpeechRecognizer,
    isFfmpegReady,
    setIsFfmpegReady,
    warningDialogOpen,
    setWarningDialogOpen,
  } = useUploadAudioActionState();

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => setShowModal(false);

  const createSpeechRecognizer = (audioConfig: SpeechSDK.AudioConfig, type: 'audio' | 'speech') => {
    try {
      const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
      speechConfig.speechRecognitionLanguage = 'es-ES';
      const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
      if (type === 'speech') setSpeechRecognizer(recognizer);
      return recognizer;
    } catch (error) {
      console.error('Error al crear el speech recognizer' + error);
    }
  };

  const loadFFmpeg = async (retryCount = 10, delay = 500) => {
    if (!isFfmpegReady) {
      try {
        await ffmpeg.load();
        setIsFfmpegReady(true);
      } catch (error) {
        if (retryCount > 0) {
          setTimeout(() => loadFFmpeg(retryCount - 1, delay), delay);
        } else {
          console.error('Failed to load FFmpeg after several attempts.');
        }
      }
    }
  };

  async function convertFileToWav(file: File) {
    try {
      const arrayBuffer = await file.arrayBuffer();
      await ffmpeg.writeFile(file.name, new Uint8Array(arrayBuffer));
      await ffmpeg.exec(['-i', file.name, '-ar', '16000', '-ac', '1', `${file.name}.wav`]);

      const wavData = await ffmpeg.readFile(`${file.name}.wav`);
      const blob = new Blob([wavData], { type: 'audio/wav' });
      return new File([blob], `${file.name}.wav`, { type: 'audio/wav' });
    } catch (error) {
      console.error(error);
    }
  }

  const recognizeSpeech = async (wavFile: File) => {
    try {
      const audioConfig = SpeechSDK.AudioConfig.fromWavFileInput(wavFile);
      const newRecognizer = createSpeechRecognizer(audioConfig, 'audio');

      if (!newRecognizer) {
        throw new Error('Speech recognizer is not available');
      }

      let finalTranscript = '';

      newRecognizer.startContinuousRecognitionAsync();

      newRecognizer.recognizing = (sender, event) => {
        setIsTranscribing(true);
        setTemporaryTranscript(event.result.text);
      };

      newRecognizer.recognized = (sender, event) => {
        if (event.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
          finalTranscript += ` ${event.result.text}`.trim();
          setTranscript(finalTranscript);
          setIsTranscribing(false);
        }
      };

      newRecognizer.sessionStopped = async (sender, event) => {
        setIsLoadingFile(false);
        setIsTranscribing(false);
      };
    } catch (error) {
      setIsLoadingFile(false);
      console.error(error);
    }
  };

  const handleUploadAudio = useCallback(
    async (file: File | null) => {
      if (!file || !isFfmpegReady) return;
      setIsLoadingFile(true);
      setTranscript('');
      setTemporaryTranscript('');
      let processedFile = file;

      try {
        await loadFFmpeg();
        // Check if the file is already a WAV file by examining its type or name.
        if (file && !file.type.includes('wav') && !file.name.endsWith('.wav')) {
          processedFile = (await convertFileToWav(file)) || file;
        }
        await recognizeSpeech(processedFile);
      } catch (error) {
        console.error('Error during audio processing', error);
      } finally {
        closeModal();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [isFfmpegReady, closeModal, convertFileToWav, recognizeSpeech],
  );

  const handleShowWarningModal = useCallback(() => {
    setWarningDialogOpen(true);
  }, []);

  const handleOnConfirmWarningModal = () => {
    openModal();
    setWarningDialogOpen(false);
  };

  useEffect(() => {
    loadFFmpeg();
  }, []);

  useEffect(() => {
    if (!speechRecognizer) {
      const speechAudioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
      createSpeechRecognizer(speechAudioConfig, 'speech');
    }
  }, []);

  useEffect(() => {
    if (isTranscribing) {
      onUpdateTextEditor(`${transcript} ${temporaryTranscript}`);
    } else {
      onUpdateTextEditor(`${transcript}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcript, temporaryTranscript, isTranscribing]);

  useEffect(() => {
    subscribe('evodicom.report.audioUpload', handleShowWarningModal);

    return () => {
      unsubscribe('evodicom.report.audioUpload', handleShowWarningModal);
    };
  }, [handleShowWarningModal]);

  return (
    <>
      <ActionDialog
        open={warningDialogOpen}
        message="Al grabar o subir un audio, en algunos casos se eliminará el contenido actual y se actualizará con la transcripción del audio."
        title="Actualizar Reporte"
        onClose={() => {
          setWarningDialogOpen(false);
        }}
        onConfirm={() => {
          handleOnConfirmWarningModal();
        }}
        type="warning"
      />
      <ModalUploadAudioFile
        open={showModal}
        onConfirm={handleUploadAudio}
        isLoadingFile={isLoadingFile}
        onCancel={closeModal}
      />
    </>
  );
};

export default UploadAudioAction;

import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import If from '@components/If';
import { NotificationMessage } from '@core/types';
import calculateElapsedTime from '@core/utils/calculateElapsedTime';
import getTitleByNotificationCategory from '@core/utils/getTitleByNotificationCategory';
import NotificationsMenuProps from '../NotificationsMenuProps';
import useSx from './sx';

const LargeViewMenu = ({
  anchorEl,
  onMenuItemClick,
  onClose,
  notifications,
  handleCancelNotification,
}: NotificationsMenuProps) => {
  const sx = useSx();
  const handleItemClick = (notificationMessage?: NotificationMessage) =>
    onMenuItemClick?.(notificationMessage);

  const existsUnseenNotifications = notifications.length > 0;

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      sx={sx.root}
    >
      {/* this div prevents a React error about MenuItems not accepting a Fragment */}
      <div>
        <If
          condition={existsUnseenNotifications}
          fallback={
            <React.Fragment>
              <MenuItem onClick={() => {}}>
                <Box>
                  <ListItemText className="ListItemSubtitle">
                    No hay notificaciones pendientes.
                  </ListItemText>
                </Box>
              </MenuItem>
              <Divider />
            </React.Fragment>
          }
        >
          {notifications.map((notification) => {
            const notificationMetadata: any = JSON.parse(notification.metadata);
            return (
              <React.Fragment key={notification.notificationMessageId}>
                <MenuItem
                  className="MenuItem"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleItemClick(notification);
                  }}
                >
                  <CloseIcon
                    className="ListItemCloseIcon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCancelNotification?.(notification);
                    }}
                  />
                  <Box>
                    <ListItemText className="ListItemTitle">
                      {getTitleByNotificationCategory(notification.notificationMessageCategoryId)}
                    </ListItemText>
                    <ListItemText className="ListItemSubtitle">
                      {notificationMetadata.description}
                    </ListItemText>
                    <ListItemText className="ListItemDate">
                      {calculateElapsedTime(notification.createdDate)}
                    </ListItemText>
                  </Box>
                </MenuItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </If>
        <MenuItem onClick={() => handleItemClick(undefined)}>
          <Box>
            <ListItemText className="ListItemTitle">Más notificaciones</ListItemText>
          </Box>
        </MenuItem>
      </div>
    </Menu>
  );
};

export default LargeViewMenu;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: { xs: '100%', md: '90%' },
  },
  signatureContainer: {
    width: '100%',
    padding: '1rem',
    border: (theme) => `1px solid ${theme.palette.primary.main}`,
    background: (theme) => theme.palette.mode === 'dark' ? theme.palette.shade?.[600] : 'white',
    borderRadius: '4px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    marginTop: '2rem'
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '1rem',
    marginBottom: '3rem'
  },
  canvas: {
    width: '100%',
    height: '200px',
    border: '1px solid black',
  },
  buttonWrapper: {
    display: 'flex',
    gap: '1rem',
  }
});

export default useSx;

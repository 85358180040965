import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  footerBar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    background: 'linear-gradient(to top, black, transparent)',
    zIndex: '100',
  },
  zoomContainer: {
    display: { xs: 'none', md: 'flex' },
    alignItems: 'center',
    position: 'relative',
    '& .MuiButtonBase-root': {
      border: 'none',
    },
  },
  zoomText: {
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
    pointerEvents: 'none',
    zIndex: '101',
    userSelect: 'none',
  },
  zoomButtonL: { paddingRight: '2.5rem' },
  zoomButtonR: { paddingLeft: '2.5rem' },
});

export default useSx;

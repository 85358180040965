import React from 'react';
import { useNavigate } from 'react-router-dom';
import { notificationStatusDTO } from '@core/constants/notificationStatus';
import { NotificationMessage } from '@core/types';
import useUpdateNotificationsStatus from './useUpdateNotificationStatus';

const createRoute = (notification: NotificationMessage) => {
  switch (notification.notificationMessageCategoryId) {
    case 2:
      return `/studies/${JSON.parse(notification.metadata).studyInstanceUid}?section=comments`;
    default:
      return `/studies/${JSON.parse(notification.metadata).studyInstanceUid}`;
  }
};

const useNotificationClick = () => {
  const navigate = useNavigate();
  const { mutateAsync: updateNotificationsAsync } = useUpdateNotificationsStatus();
  const onNotificationClick = async (notification: NotificationMessage) => {
    const notificationToUpdate = [
      {
        notificationMessageId: notification.notificationMessageId,
        isSeen: notificationStatusDTO.seen,
      },
    ];
    await updateNotificationsAsync({
      notificationToUpdate,
    });

    const route = createRoute(notification);

    navigate(route);
    window.location.reload();
  };

  return onNotificationClick;
};

export default useNotificationClick;

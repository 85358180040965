import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    position: 'relative',
    height: '300px',
  },
});

export default useSx;

import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
    root: (theme) => ( {
        position: 'relative',
        width: 'max-content',
        paddingBottom: '0.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem'
        },
    })
});

export default useSx;

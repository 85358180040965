import React, { ComponentType } from 'react';
import Alert from '@mui/material/Alert';
import { ObjectStatus } from '@core/types';

type ObjectStatusMessageMap = {
  [P in ObjectStatus]?: ComponentType;
};

const GracePeriodAlert = () => (
  <Alert variant="filled" severity="warning">
    <strong>Pr&oacute;xima suspensi&oacute;n de cuenta</strong>
    <span>
      — Su cuenta muestra un atraso en sus pagos por lo que ser&aacute; suspendida
      pr&oacute;ximamente.
    </span>
  </Alert>
);

const productStatusBannerMap: ObjectStatusMessageMap = {
  GracePeriod: GracePeriodAlert,
  Trial: () => <></>,
  None: () => <></>,
  Active: () => <></>,
  Suspended: () => <></>,
};

export default productStatusBannerMap;

import React, { useRef, useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import useProfile from '@core/hooks/useProfile';
import useSnackbar from '@core/hooks/useSnackbar';
import { usePostSignature, useGetSignature, useDeleteSignature } from '../../hooks/useSignature';
import useSx from './sx';

const useSignatureState = () => {
  const showSnackbar = useSnackbar();
  const { userUid } = useProfile();
  const sigCanvasRef = useRef<SignatureCanvas | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [signatureFile, setSignatureFile] = useState<string | ArrayBuffer | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isSigned, setIsSigned] = useState<boolean>(false);
  const [hasSignatureSaved, setHasSignatureSaved] = useState<boolean>(false);
  const sx = useSx();
  const { postUserSignature } = usePostSignature();
  const { deleteUserSignature } = useDeleteSignature(true, false);
  const { data, isSuccess, refetch } = useGetSignature();

  useEffect(() => {
    if (data && data.signature && isSuccess) {
      setHasSignatureSaved(true)
    } else {
      setHasSignatureSaved(false)
    }
  }, [data, isSuccess]);

  return {
    showSnackbar,
    userUid,
    sx,
    sigCanvasRef,
    containerRef,
    isSigned,
    setIsSigned,
    signatureFile,
    setSignatureFile,
    postUserSignature,
    deleteUserSignature,
    isSaving,
    setIsSaving,
    isDeleting,
    setIsDeleting,
    data, 
    isSuccess, 
    refetch,
    hasSignatureSaved, 
    setHasSignatureSaved
  };
};
export default useSignatureState;

import { FilterOptionsState } from '@mui/material/useAutocomplete';
import ignoreCaseAndWhiteSpaceEqual from '@core/utils/ignoreCaseAndWhiteSpaceEqual';

export const ADD_ITEM_TOKEN = 'Agregar: ';

const filterOptions = (options: string[], state: FilterOptionsState<string>) => {
  // this check is necessary because sometime the component
  // calls this function with a different options array than expected.
  // if (typeof options?.[0] !== 'string') {
  //   return options;
  // }

  const { inputValue } = state;
  const notExists = options.every((o) => !ignoreCaseAndWhiteSpaceEqual(inputValue, o));
  let retVal = [...options];

  if (inputValue !== '' && notExists) {
    retVal = [`${ADD_ITEM_TOKEN}"${inputValue}"`, ...retVal];
  }

  return retVal;
};

export default filterOptions;

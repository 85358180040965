import { useEffect, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getDicomServiceToken } from '@core/api/getDicomServiceToken';
import { setDicomServiceAccessToken } from '@core/api/setDicomServiceAccessToken';
import { AuthContext } from '@core/providers/AuthProvider';

export const DICOM_SERVICE_SETTINGS_QUERY = 'DicomServiceSettings';

const useDicomServiceToken = (orgId?: string, studyHash?: string) => {
  const { organization } = useContext(AuthContext);

  useEffect(() => {
    const newOrganizationId = organization?.organizationId || orgId;

    if (newOrganizationId !== undefined) {
      sessionStorage.setItem('organizationId', newOrganizationId);
    }
  }, [organization?.organizationId, orgId]);

  const organizationId =
    organization?.organizationId || orgId || sessionStorage.getItem('organizationId') || undefined;

  const { data: dicomServiceTokenSettings, ...rest } = useQuery({
    queryFn: () => getDicomServiceToken(studyHash),
    queryKey: [DICOM_SERVICE_SETTINGS_QUERY, organizationId],
    enabled: organizationId !== undefined,
    staleTime: 1000 * 60,
    retry: 3,
    retryDelay: 1000,
    refetchOnWindowFocus: false,
    refetchInterval: (tokenSettings): number | false => {
      if (!tokenSettings || !tokenSettings.token.expiresOn) {
        return false;
      }

      const expiresOn = new Date(tokenSettings.token.expiresOn).getTime();
      const now = new Date().getTime();
      const timeout = expiresOn - now;
      if (timeout <= 0) {
        // expired so try to refresh it immediately
        return 1;
      }
      return timeout;
    },
  });

  useEffect(() => {
    if (!dicomServiceTokenSettings) {
      return;
    }

    setDicomServiceAccessToken(dicomServiceTokenSettings);
  }, [dicomServiceTokenSettings]);

  return { dicomServiceTokenSettings, ...rest };
};

export default useDicomServiceToken;

import { useContext } from 'react';
import useUserOrganizations from '@components/AppAuthenticationTemplate/hooks/useUserOrganizations';
import useGetUser from '@core/hooks/useGetUser';
import { AuthContext } from '@core/providers/AuthProvider';
import { ObjectStatus, ProfileInfo } from '@core/types';
import getCurrentUserAccount from '@core/utils/getCurrentUserAccount';
import accessManager from '@core/utils/productAccessManager';

const useProfile = (): ProfileInfo => {
  const account = getCurrentUserAccount();
  const { idTokenClaims } = account ?? {};
  const userUid = idTokenClaims?.sub;
  const claims = accessManager.getProductClaims(userUid);
  const { organization: orgInfo } = useContext(AuthContext);
  const { organizations } = useUserOrganizations(userUid);
  const { user: userProfileInfo, refetch: refetchUserProfileInfo } = useGetUser(
    userUid,
    orgInfo?.organizationId,
    !!userUid && !!orgInfo?.organizationId,
  );
  const hasAdminPermissions = claims?.adminUser === 'True' || claims?.ownerUser === 'True' || claims?.role === 'System';
  const hasOwnerPermissions = claims?.ownerUser === 'True' || claims?.role === 'System';

  const userLastName = userProfileInfo.surname ? ` ${userProfileInfo.surname}` : '';
  const fullName = `${userProfileInfo.givenName}${userLastName}`;

  const currentUserOrganization = organizations.find(
    (organizationItem) => organizationItem.organizationProductId === orgInfo?.organizationId,
  );

  let retVal: ProfileInfo = {};

  if (account) {
    retVal = {
      userUid: userUid,
      fullName,
      role: claims?.role,
      productStatus: claims?.productStatus as ObjectStatus,
      hasMoreOrganizations: orgInfo?.hasMoreOrganizations,
      userProfileInfo,
      refetchUserProfileInfo,
      hasAdminPermissions,
      hasOwnerPermissions,
      organization: orgInfo,
      currentUserOrganization,
      organizations,
    };
  }

  return retVal;
};

export default useProfile;

import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    ...expandDisplayFlex({ d: 'column' }),
    gap: '20px',
    width: '100%',
  },
  autocomplete: {
    '.MuiInputBase-root': {
      height: 'unset',
      paddingY: '0',
      paddingLeft: '8px',
    },
  },
  control: {
    marginLeft: '0',
    flexDirection: 'row',
  },
  permissions: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    gap: {
      xs: '20px',
      md: 0,
    },
    justifyContent: 'space-between',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  button: {
    paddingX: '2rem',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
  },
});

export default useSx;

import { safeInstanceOf, setListItemStyle } from 'roosterjs-editor-dom';
import {
  ChangeSource,
  PositionType,
  SelectionRangeTypes,
  IEditor,
  NodePosition,
} from 'roosterjs-editor-types';

// This file contains a copy of the implementation from roostedjs: https://github.com/microsoft/roosterjs/blob/v8.59.0/packages/roosterjs-editor-api/lib/utils/applyListItemWrap.ts
// Functions declared here are required to edit styles from selected report lines.

function formatUndoSnapshot(
  editor: IEditor,
  callback?: (start: NodePosition | null, end: NodePosition | null) => any,
  apiName?: string,
) {
  editor.addUndoSnapshot(
    callback,
    // @ts-expect-error Expect errors when copying from another project.
    ChangeSource.Format,
    undefined /* canUndoByBackspace */,
    apiName && apiName !== ''
      ? {
          formatApiName: apiName,
        }
      : undefined,
  );
}
function applyInlineStyle(
  editor: IEditor,
  callback: (element: HTMLElement, isInnerNode?: boolean) => any,
  apiName: string,
) {
  editor.focus();
  const selection = editor.getSelectionRangeEx();
  formatUndoSnapshot(
    editor,
    () => {
      let firstNode: Node | undefined;
      let lastNode: Node | undefined;
      selection.ranges.forEach((range) => {
        const contentTraverser = editor.getSelectionTraverser(range);
        if (!contentTraverser) {
          return;
        }
        let blockElement = contentTraverser && contentTraverser.currentBlockElement;
        while (blockElement) {
          const nextInlineElement = contentTraverser.getNextBlockElement();
          callback(blockElement.collapseToSingleElement(), false);
          blockElement = nextInlineElement;
        }
      });
      // @ts-expect-error Expect errors when copying from another project.
      if (firstNode && lastNode && selection.type === SelectionRangeTypes.Normal) {
        // @ts-expect-error Expect errors when copying from another project.
        editor.select(firstNode, PositionType.Before, lastNode, PositionType.After);
      }
    },
    apiName,
  );
}

export default function applyListItemStyleWrap(
  editor: IEditor,
  styleName: string,
  formatCallback: (element: HTMLElement, isInnerNode?: boolean) => any,
  apiName: string,
) {
  const parentNodes: Node[] = [];
  applyInlineStyle(
    editor,
    (element, isInnerNode) => {
      formatCallback(element, isInnerNode);

      const parent = editor.getElementAtCursor('LI', element);
      if (parent && parentNodes.indexOf(parent) === -1) {
        parentNodes.push(parent);
      }
    },
    apiName,
  );

  parentNodes.forEach((node) => {
    if (safeInstanceOf(node, 'HTMLLIElement')) {
      setListItemStyle(node, [styleName]);
    }
  });
}

import { get, patch } from '@core/api/baseApi';
import { GetPlanResponse, GetUserPlanResponse, PatchUserPlanResponse } from '@core/types';

export const changeSubscription = (
  planId: number,
  effectiveImmediately: boolean,
  renewalPeriod: string,
  organizationId: string
): Promise<PatchUserPlanResponse>  => {
  return patch('/Plans/my-plan', { effectiveImmediately, renewalPeriod, organizationId, productPlanId: planId  });
};

export const getPlans = (): Promise<GetPlanResponse[]> => get('/Plans');

export const getUserPlan = async (): Promise<GetUserPlanResponse> => {
  const response: GetUserPlanResponse = await get('/Plans/my-plan');
  if (!response || !response.plan) {
    throw new Error('Unexpected response shape');
  }

  return response;
};
